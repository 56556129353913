import { AppType, APP_TYPE } from "../app.type";
import { uploadFile, deleteFile, getAttachments } from "../api.service";
import { FileServiceType } from "./file.service.type";
import { Inject, Injectable } from "@angular/core";
import { BehaviorSubject } from "rxjs";
import {
  Attachment,
  AttachmentsInfo,
} from "../../../../shared/models/attachment";
import { getToken } from "../auth/token";
import { StringUtils } from "../../../../shared/utils/string.utils";
import { EncodingUtils } from "../../../../shared/utils/encoding.utils";

export const BILD_IMAGE = "Bild.jpg";
@Injectable()
export class FileService implements FileServiceType {
  loadedImageCount = 0;
  loadedImageSubject = new BehaviorSubject<number>(this.loadedImageCount);
  attachmentsInfo: BehaviorSubject<{
    attachedFiles: any;
    docId: string;
  }> = new BehaviorSubject({ attachedFiles: {}, docId: "" });
  commentAttachments: { name: string; id: string }[] = [];
  solutionAttachments: { name: string; id: string }[] = [];
  acceptedFileFormats = [
    "png",
    "jpg",
    "jpeg",
    "svg",
    "gif",
    "pdf",
    "rtf",
    "txt",
    "xml",
    "xls",
    "xlsx",
    "doc",
    "docx",
    "ppt",
    "pptx",
    "odp",
    "msg",
    "xlsm",
    "xsd",
  ];

  constructor(@Inject(APP_TYPE) private app: AppType) {}

  async getAttachmentsByDocType(
    id: string,
    type = this.app.type,
    customer = this.app.customer
  ): Promise<Attachment> {
    const filePrefix = [customer, type, id].join("/");
    const { attachments } = await getAttachments(filePrefix);

    return attachments;
  }

  async updateDocInfo(
    attachmentInfo: AttachmentsInfo,
    type: string,
    id: string
  ) {
    //update the _attachments, attachmentHistory and _rev properties on the thread/post model with the latest changes
    this.updateCurrentModel(attachmentInfo, type, id);

    //save the latest changes into attachmentsInfo so that the user can see the changes in real time
    const { attachments } = attachmentInfo;
    const attachmentsInfo = {
      attachedFiles: attachments,
      docId: id,
    };
    this.attachmentsInfo.next(attachmentsInfo);
  }

  getUrl(file: string | null) {
    if (file == null) {
      return "";
    }
    if (file.includes("profile")) {
      const user = file.split("/")[2];
      return (
        `/api/profile/${user}/image` +
        "?cacheBuster=" +
        this.app.state.cacheBuster
      );
    }
    return (
      `/api/files/${file}` +
      `?token=${getToken()}` +
      "&cacheBuster=" +
      this.app.state.cacheBuster
    );
  }

  exists(doc: any, fileName = BILD_IMAGE) {
    const docId = this.attachmentsInfo.value.docId;
    const name = Object.keys(this.attachmentsInfo.value.attachedFiles);

    if (doc == null || doc._attachments == null) {
      //the very first attachment was just uploaded on a doc
      if (docId == this.app.thread.id && name.length > 0) {
        return true;
      }
      //there are no _attachments on the doc
      return false;
    }

    if (this.attachmentsInfo.value.attachedFiles.length == 0) {
      return false;
    }
    const files = Object.keys(doc._attachments);
    return new Set(files).has(fileName);
  }

  async upload(file: any, fileLink: string, fromPcn?: boolean) {
    let fileLinkCopy = fileLink.split("/");
    let name: string = file.name;

    //check file name
    if (StringUtils.containsSpecialCharacter(name)) {
      this.app.state.hasError = true;
      this.app.state.errorText = this.app.text.app.fileNameNotAccepted;
      return;
    }

    if (this.isFileTooLarge(file)) {
      this.app.state.hasError = true;
      this.app.state.errorText = this.app.text.app.fileTooLargeError;
      return;
    }

    if (this.fileFormatAccepted(file)) {
      this.app.state.hasError = false;
      this.app.state.errorText = "";
      /** if the name of the attachment exists already in the set of the attachments from the case/post/sol
       * it has to be changed so that it can be identified in the list of the attachments
       * in order to be able to show / hide add tag / delete buttons
       */
      if (
        fileLinkCopy[3].includes("Bild.jpg") ||
        fileLinkCopy[3].includes("user.jpg")
      ) {
        name = fileLinkCopy[3];
      } else {
        name = this.changeNameIfExists(file.name);
      }

      /** created a copy of the file so that we can set the new name on the object */
      const fileCopy = new File([file], name, { type: file.type });

      /** created a copy of the file link so that we add the new name to the link */
      let link =
        fileLinkCopy[0] +
        "/" +
        fileLinkCopy[1] +
        ["/"] +
        fileLinkCopy[2] +
        "/" +
        name;

      /** the upload has to take place with the fileCopy and the newly generated link */
      // imported pcn-generator files comes allways as a Blob
      let attachmentsInfo: any = {};
      if (file.attachment instanceof Blob) {
        attachmentsInfo = await uploadFile(file, link, fromPcn);
      } else {
        attachmentsInfo = await uploadFile(fileCopy, link, fromPcn);
      }

      const [, type, id] = fileLink.split("/");
      await this.updateDocInfo(attachmentsInfo, type, EncodingUtils.decodeBase64(id));
      this.app.state.next({ cacheBuster: Math.random() });
      // if (type === "thread") {
      //   //get the thread to see the updated update_time
      //   //TODO: create api to get only the needed info from thead (update_time and update_user)
      //   await this.app.thread.getThread(EncodingUtils.decodeBase64(id));
      // }
    } else {
      this.app.hasError = true;
      this.app.errorText = this.app.text.app.fileFormatNotAccpeted;
    }
  }

  changeNameIfExists(name: string) {
    let splitName = name.split(".");
    const fileType = splitName[splitName.length - 1];
    const fileName = name.substr(0, name.length - (fileType.length + 1));
    let i = 0;
    while (
      this.app.attachments.allAttachments.find((att) =>
        att.name.includes(name)
      ) != null
    ) {
      i = i + 1;
      name = `${fileName}_${i}.${fileType}`;
    }
    return name;
  }

  async delete(fileLink: string) {
    const result = await deleteFile(fileLink);
    const [, type, id] = fileLink.split("/");
    await this.updateDocInfo(result, type, EncodingUtils.decodeBase64(id));
    if (type === "thread") {
      //get the thread to see the updated last_edited time
      await this.app.thread.getThread(EncodingUtils.decodeBase64(id));
    }
    if (type === "ocComment") {
      //get the comments to see the updated last_edited time
      await this.app.OCComment.ocCommentsByPostId();
    }
  }

  isFileTooLarge(file: File): boolean {
    // if file size is > 100mb(104857600 bytes) => error else make the upload
    if (file.size > 104857600) {
      return true;
    } else {
      return false;
    }
  }

  fileFormatAccepted(file: File) {
    let extenssion = file.name.split(".").pop();
    if (extenssion == null) {
      return false;
    }
    const fileExtenssion = extenssion.toLowerCase();
    if (this.acceptedFileFormats.includes(fileExtenssion)) {
      return true;
    } else {
      return false;
    }
  }

  private updateCurrentModel(
    attachmentInfo: AttachmentsInfo,
    type: string,
    id: string
  ) {
    const { attachments, attachmentsHistoryInfo, _rev } = attachmentInfo;
    if (type === "thread") {
      this.app.thread.thread["thread._attachments"] = attachments;
      this.app.thread.thread["thread.attachmentHistory"] =
        attachmentsHistoryInfo;
      this.app.thread.thread["thread._rev"] = _rev;
    }

    if (type === "post") {
      this.app.post.comments.forEach((comment) => {
        if (comment._id === id) {
          comment._attachments = attachments;
          comment.attachmentHistory = attachmentsHistoryInfo;
          comment._rev = _rev;
        }
      });

      this.app.post.solutions.forEach((solution) => {
        if (solution._id === id) {
          solution._attachments = attachments;
          solution.attachmentHistory = attachmentsHistoryInfo;
          solution._rev = _rev;
        }
      });
      this.app.post.tasks.forEach((task) => {
        if (task._id === id) {
          task._attachments = attachments;
          task.attachmentHistory = attachmentsHistoryInfo;
          task._rev = _rev;

          this.app.panel.selectedItemModel["post._attachments"] = attachments;
          this.app.panel.selectedItemModel["post.attachmentHistory"] =
            attachmentsHistoryInfo;
          this.app.panel.selectedItemModel["post._rev"] = _rev;
        }
      });
    }

    if (type === "profile") {
      this.app.profile.profile._attachments = attachments;
      this.app.profile.profile._rev = _rev;
    }

    if (type === "manufacturer") {
      this.app.manufacturer.currentManufacturer["manufacturer._attachments"] =
        attachments;
      this.app.manufacturer.currentManufacturer[
        "manufacturer.attachmentHistory"
      ] = attachmentsHistoryInfo;
      this.app.manufacturer.currentManufacturer["manufacturer._rev"] = _rev;
    }

    if (type === "part") {
      this.app.part.part["part._attachments"] = attachments;
      this.app.part.part["part.attachmentHistory"] = attachmentsHistoryInfo;
      this.app.part.part["part._rev"] = _rev;
    }

    if (type === "ocPost") {
      this.app.OCPost.selectedPost._attachments = attachments;
      this.app.OCPost.selectedPost.attachmentHistory = attachmentsHistoryInfo;
      this.app.OCPost.selectedPost._rev = _rev;
    }
    if (type === "ocComment") {
      this.app.OCComment.comments.forEach((comment: any) => {
        comment._attachments = attachments;
        comment.attachmentHistory = attachmentsHistoryInfo;
        comment._rev = _rev;
      });
    }
  }

  downloadXmlFile(xmlContent: any, fileName: string) {
    const blob = new Blob([xmlContent], { type: "application/xml" });
    const link = document.createElement("a");
    link.download = fileName;
    link.href = URL.createObjectURL(blob);
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  }
}
