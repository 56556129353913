<div
  *ngIf="!isDefaultTypeAhead"
  [ngClass]="{ 'p-1': !app.thread.isEditMode() }"
>
  <app-override-mpnFields #overrideMpnFieldsModal></app-override-mpnFields>

  <!-- the suggestions are displayed currently only for the crtNumber and the partNumber -->
  <div *ngIf="app.unlockedId != null && app.model[field] === ''">
    <span
      class="badge badge-light"
      *ngFor="let id of app.manufacturer.getListOfSuggestions(field)"
      ><a (click)="selectValue(id)" class="pointer dark-text">
        {{ id }} &nbsp;
      </a></span
    >
  </div>

  <!-- value displayed in read mode -->
  <span
    *ngIf="
      (this.app.unlockedId == null &&
        !app.field.isHyperLink(field, docModel[field])) ||
      (!app.post.isReadMode && !app.field.isHyperLink(field, docModel[field]))
    "
    >{{ docModel[field] }}</span
  >
  <a
    class="lineHeight-28"
    [ngClass]="{ link: app.field.isHyperLink(field, docModel[field]) }"
    *ngIf="app.field.isHyperLink(field, docModel[field])"
    (click)="app.routing.openInNewTab(null, field, docModel[field])"
    >{{ docModel[field] }}</a
  >

  <!-- displayed in edit mode -->
  <div
    *ngIf="
      app.unlockedId != null &&
      app.fieldId.thread.cageCode === field &&
      app.field.manufacturerCodes.length > 1
    "
  >
    <span
      class="badge badge-light"
      *ngFor="let code of app.field.manufacturerCodes"
      ><a
        (click)="app.field.setManufacturerCode(code)"
        class="pointer dark-text sm-text"
      >
        {{ code }} &nbsp;
      </a></span
    >
  </div>
  <div
    class="input-group typeAhead"
    *ngIf="app.unlockedId != null && app.post.isReadMode"
  >
    <button
      class="btn btn-sm selected"
      *ngIf="docModel[field] !== ''"
      type="button"
      (click)="clearContent(field)"
    >
      {{ docModel[field] }}
      <i class="fa fa-times app-subtle"></i>
    </button>
    <input
      id="typeahead-basic"
      type="text"
      class="form-control inputStyle"
      *ngIf="!selected && docModel[field] == ''"
      [(ngModel)]="app.typeAheadBasic.model[field]"
      (focusout)="saveInput(app.typeAheadBasic.model[field])"
      [maxLength]="maxLength"
      [placeholder]="placeholder"
      (keyup)="onKeyUp($event)"
      (keydown.Enter)="onEnter()"
    />
    <span
      class="invalidspan mx-1"
      *ngIf="
        app.field.isRequired(field) &&
        !app.field.isValid(field, docModel[field])
      "
      ><i
        class="fal fa-exclamation-circle"
        tooltipClass="customTooltip"
        [ngbTooltip]="app.text.field.requiredIcon"
      ></i
    ></span>
  </div>

  <div
    *ngIf="showDropdownList"
    class="optionList"
    [ngClass]="{ listTop: displayOptionsTop, listBottom: !displayOptionsTop }"
  >
    <ul>
      <li
        *ngFor="let item of ids"
        class="dropdown-item pointer"
        (click)="selectValue(item)"
      >
        {{ item }}
      </li>
    </ul>
  </div>
</div>
<div class="input-group" *ngIf="isDefaultTypeAhead">
  <div
    class="col-sm-12 small text-danger"
    *ngIf="isInvalid(app.typeAheadBasic.model[field])"
  >
    {{ app.text.app.fieldInvalid }}
  </div>
  <button
    class="btn btn-sm selectedValue"
    [ngClass]="{
      selectedValueRM: app.type === 'rm.search',
      typeaheadFilters: app.type === 'thread'
    }"
    *ngIf="isSelected(field)"
    type="button"
    [disabled]="disabled()"
    (click)="clearContent(field)"
  >
    {{ selectedValue }}
    <i class="fa fa-times app-subtle"></i>
  </button>
  <input
    [ngClass]="{ typeaheadFilters: app.type === 'thread' }"
    id="{{ field }}"
    type="text"
    class="form-control inputStyle sortOptions"
    [(ngModel)]="app.typeAheadBasic.model[field]"
    *ngIf="!isSelected(field)"
    [placeholder]="placeholder"
    (keyup)="onKeyUp($event)"
    (focusout)="resetInput()"
    (keydown.Enter)="onEnter()"
  />
  <span
    class="invalidspan"
    *ngIf="
      app.field.isRequired(field) &&
      !app.field.isValid(field, app.typeAheadBasic.model[field])
    "
    ><i
      class="fal fa-exclamation-circle"
      tooltipClass="customTooltip"
      [ngbTooltip]="app.text.field.requiredIcon"
    ></i
  ></span>
  <button
    class="btn searchButton"
    type="button"
    *ngIf="
      app.type === 'rm.search' &&
      app.RMSearch.filterMode !== 'part.partNumber' &&
      app.RMSearch.filterMode !== 'manufacturer.manufacturerPartNumber' &&
      !isSelected(field)
    "
    (click)="
      app.RMSearch.model = app.typeAheadBasic.model;
      app.RMSearch.getRMSearchResults()
    "
  >
    <i class="fa fa-search"></i>
    <span>&nbsp;{{ app.text.app.search }}</span>
  </button>
  <div
    *ngIf="showDropdownList"
    class="optionList dropdown-menu listBottom"
    aria-labelledby="sortOptions"
    [ngClass]="{ responsibleList: isInvalid(app.typeAheadBasic.model[field]) }"
  >
    <ul>
      <li
        *ngFor="let item of ids; let i = index"
        id="id"
        class="dropdown-item"
        [class.hovering]="selectedItemIndex === i"
        (mousedown)="selectValue(item)"
      >
        {{ item }}
      </li>
    </ul>
  </div>
</div>
