export class Responsibles {
  [key: string]: any;
  bavFv?: string = "";
  bavFvEmail?: string = "";
  bavFvVertreter?: string = "";
  bavFvVertreterEmail?: string = "";
  mawiFv?: string = "";
  mawiFvEmail?: string = "";
  mawiFvVertreter?: string = "";
  mawiFvVertreterEmail?: string = "";
  bavRegio?: string = "";
  bavRegioEmail?: string = "";
  bavRegioVertreter?: string = "";
  bavRegioVertreterEmail?: string = "";
  mawiRegio?: string = "";
  mawiRegioEmail?: string = "";
  mawiRegioVertreter?: string = "";
  mawiRegioVertreterEmail?: string = "";
  bavCargo?: string = "";
  bavCargoEmail?: string = "";
  bavCargoVertreter?: string = "";
  bavCargoVertreterEmail?: string = "";
  mawiCargo?: string = "";
  mawiCargoEmail?: string = "";
  mawiCargoVertreter?: string = "";
  mawiCargoVertreterEmail?: string = "";
  bavFzi?: string = "";
  bavFziEmail?: string = "";
  bavFziVertreter?: string = "";
  bavFziVertreterEmail?: string = "";
  mawiFzi?: string = "";
  mawiFziEmail?: string = "";
  mawiFziVertreter?: string = "";
  mawiFziVertreterEmail?: string = "";
  bavNetz?: string = "";
  bavNetzEmail?: string = "";
  bavNetzVertreter?: string = "";
  bavNetzVertreterEmail?: string = "";
  mawiNetz?: string = "";
  mawiNetzEmail?: string = "";
  mawiNetzVertreter?: string = "";
  mawiNetzVertreterEmail?: string = "";
}
