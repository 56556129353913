import { Component, ViewChild } from "@angular/core";
import { getApp } from "../app";
import { Subscription } from "rxjs";
import { Customer } from "../../../../shared/types/customers";
import { Impact } from "../../../../shared/models/impact";
import {
  getPredefinedTasksByStatus,
  createTasks,
  getImpacts,
} from "../api.service";
import { Post } from "../../../../shared/models/post";
import { GREEN, RED } from "../impact/wf-automation/wf-automation.component";
import { UserOptions } from "../../../../shared/models/user";

export class vehicleNameModel {
  [key: string]: string;
}
@Component({
  selector: "app-impacts",
  templateUrl: "./impacts.component.html",
  styleUrls: ["./impacts.component.scss"],
})
export class ImpactsComponent {
  @ViewChild("fileInput", { static: true }) fileInput: any;
  impacts: any[] = [];
  ids: string[] = [];
  modeSubscription: Subscription = new Subscription();
  importSubscription: Subscription = new Subscription();
  selectedFilters: { columnName: string; value: string }[] = [];
  showAddImpactBtn: boolean = false;
  showImportImpactBtn: boolean = false;
  vehicleNameModel: vehicleNameModel = {};
  threadId: string = "";
  columns: string[] = [];
  columnsShort: string[] = [];
  red = RED;
  green = GREEN;

  app = getApp((app) => {
    this.app = app;
  });

  constructor() {}

  async ngOnInit() {
    let omfNumber = this.app.thread.thread[this.app.fieldId.thread.omfNumber];
    this.threadId = this.app.thread.thread["thread._id"];
    this.modeSubscription = this.app.impact.newImpactSubject.subscribe(() => {
      if (this.app.thread.id !== undefined && this.app.thread.id !== null) {
        this.impacts = this.app.impacts.impacts;
        this.getColumns();
      }
    });
    this.importSubscription = this.app.impacts.newImpactsAdded.subscribe(
      async (value) => {
        if (value) {
          if (this.app.thread.id !== undefined && this.app.thread.id !== null) {
            this.impacts = await this.app.impacts.getImpacts(omfNumber);
            this.app.impact.newImpactSubject.next(true);
          }
          if (this.app.customers.expectCurrent === Customer.KNDS) {
            this.app.post.projectNames = this.impacts
              .filter((o: any) => o.projectNumber != undefined)
              .map((x: any) => x.projectNumber);
            this.app.post.projectNames = [
              ...new Set(this.app.post.projectNames),
            ];
          }
        }
      }
    );
    this.app.type = "impact";

    this.showAddImpactButton();
    this.showImportImpactButton();
  }

  getColumns() {
    let status = this.app.thread.thread["thread.omfStatus"];
    this.columns = this.app.list.impact.columns;
    this.columnsShort = this.columns.map(
      (column: string) => column.split(".")[1]
    );
    let index = this.columnsShort.findIndex(
      (column: string) => column === "actions"
    );
    if (index !== -1) {
      this.columnsShort.splice(index, 1);
    }
    if (!this.app.permission.impact.canAddImpactToCase) {
      this.columns.splice(
        this.columns.findIndex((column: string) => column === "impact.actions"),
        1
      );
    }
    if (status === "90") {
      this.columns = this.app.list.impact.columns90;
    }
  }

  //do not show add impact sign if comming from tree or user doesn't have permission to add impact
  showAddImpactButton() {
    if (this.app.view === "thread" && this.app.thread.isNew) {
      this.showAddImpactBtn = false;
    }
    if (
      !this.app.thread.isNew &&
      this.app.permission.impact.canAddImpactToCase
    ) {
      this.showAddImpactBtn = true;
    }
  }

  showImportImpactButton() {
    switch (this.app.customers.expectCurrent) {
      case Customer.KNDS:
        this.showImportImpactBtn =
          this.app.thread.thread["thread.omfStatus"] !== "vorerfasst";
        break;
      default:
        this.showImportImpactBtn = false;
    }
  }

  removeItem(impact: Impact) {
    let index = this.impacts.findIndex((item) => item._id === impact._id);
    this.impacts.splice(index, 1);
  }

  manualAddVisibile() {
    switch (this.app.customers.expectCurrent) {
      case Customer.BVG:
        return true;
      case Customer.DB:
        if (this.app.permission.impact.canAddImpactToCase) {
          return true;
        } else {
          return false;
        }
      default:
        return false;
    }
  }

  navigateToImpact(impact: string) {
    if (this.app.state.omfNumber == null) {
      return;
    }
    this.app.impacts.getImpacts(this.app.state.omfNumber);
    this.app.routing.navigateImpact(this.threadId, impact);
  }
  async upload() {
    let files: FileList = this.fileInput.nativeElement.files;
    this.app.import.type = "impact";
    await this.app.importXlsx(files);
    await this.app.import.prepareDocs();
    if (this.fileInput) {
      this.fileInput.nativeElement.value = "";
    }
    if (this.app.customers.expectCurrent === Customer.KNDS) {
      this.generateTasks();
    }
  }

  async generateTasks() {
    if (this.app.state.omfNumber == null) {
      return;
    }
    let impacts = await getImpacts(
      this.app.customers.expectCurrent,
      this.app.state.omfNumber
    );
    this.app.spinner.showSpinner();
    let projectResponsibles = [
      ...new Set(impacts.map((x: any) => x.projectResponsible)),
    ];
    let taskResponsibles = this.app.post.tasks.map(
      (x: any) => x.taskResponsible
    );
    let responsibleWithoutTask = projectResponsibles.filter(
      (x: any) => !taskResponsibles.includes(x)
    );

    // we have to use as description for automatically generated tasks by import, the same description as in predefined tasks
    // we only use this functionality to create tasks to KM and we know that these tasks can also be defined using the predefined task in the "Precheck" status
    let predefinedTasksForKM = await getPredefinedTasksByStatus("vorerfasst");

    let tasks: Partial<Post>[] = [];
    if (responsibleWithoutTask.length > 0) {
      responsibleWithoutTask.forEach(async (responsible: string) => {
        let taskInfo = {} as Partial<Post>;
        // find out which department the user is responsible for
        let department = this.app.users.userRoles
          .filter((user: UserOptions) => user.name === responsible)
          .map((x: any) => x.businessArea)
          .join("");

        // find out what task description is for the department where the user is responsible
        taskInfo.taskDescription = predefinedTasksForKM
          .filter((task: any) => task.department === department)
          .map((x: any) => x.descriptionDE)
          .join("");

        taskInfo.taskResponsible = responsible;

        let projectName = this.app.users.userRoles
          .filter((user: UserOptions) => user.name === responsible)
          .map((x: any) => x.projectNumber)
          .join("");
        taskInfo.projectNumber = projectName;
        taskInfo.loggedInUser = this.app.users.currentUser.name;
        tasks.push(taskInfo);
      });

      const threadId = this.app.thread.cleanModel["thread._id"];
      await createTasks(this.app.customers.expectCurrent, threadId, tasks);

      this.app.unlockedId = null;
      this.app.thread.getThread(threadId);
      this.app.post.getThreadPosts(
        this.app.customers.expectCurrent,
        this.app.state.omfNumber
      );
    }
    this.app.spinner.hideSpinner();
  }

  ngOnDestroy(): void {
    if (this.modeSubscription) {
      this.modeSubscription.unsubscribe();
    }
    if (this.importSubscription) {
      this.importSubscription.unsubscribe();
    }
  }
}
