import { Component, ViewChild, Input, Output } from "@angular/core";
import { Subject } from "rxjs";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { getApp } from "../app";
import { Train } from "../../../../shared/models/train";
import { doc2Model } from "../api.service";
import { ActivatedRoute } from "@angular/router";

@Component({
  selector: "app-trains-modal",
  templateUrl: "./trains-modal.component.html",
  styleUrls: ["./trains-modal.component.scss"],
})
export class TrainsModalComponent {
  @Input() modalTitle = "";

  @ViewChild("content", { static: false }) content: any;
  @Output() ok = new Subject<any>();

  app = getApp((app) => {
    this.app = app;
  });

  fields: string[] = this.app.list.train.trains;

  currentTrain = {} as Train;

  constructor(private modalService: NgbModal, private route: ActivatedRoute) {}

  ngOnInit() {
    if (this.fields.find((x) => x === "impact.actions")) {
      this.fields.splice(this.fields.length - 1, 1);
    }

    //get list of users to be displayed in the train responsible dropdown
    this.app.users.getUsersWithRoles();
  }

  async open(train: Train) {
    this.route.params.subscribe((params) => {
      this.app.train.threadId = params.threadId;
    });
    if (train == null) {
      this.app.train.currentModel = doc2Model("train", new Train()) as Train;
    } else {
      this.app.train.currentModel = doc2Model("train", train) as Train;
    }

    try {
      const result = await this.modalService.open(this.content, {
        windowClass: "mediumModal",
      }).result;

      this.ok.next(result);
    } catch (err) {
      // NOP
    }
  }

  modelHasChanges() {
    if (
      this.currentTrain.trainName !== this.app.model["train.trainName"] ||
      this.currentTrain.trainResponsible !==
        this.app.model["train.trainResponsible"]
    ) {
      return true;
    }

    return false;
  }

  showSaveButton() {
    const currentModel = doc2Model("train", this.app.train.train);
    if (
      this.app.field.getInvalidFields("train", currentModel).length < 1 &&
      this.modelHasChanges()
    ) {
      return true;
    }
    if (
      this.app.field.getInvalidFields("train", this.app.train.currentModel)
        .length < 1
    ) {
      return true;
    } else {
      return false;
    }
  }
  closeModal() {
    this.modalService.dismissAll();
  }
}
