<input
  #fileInput
  type="file"
  accept=".xlsx"
  style="display: none"
  (change)="upload()"
  multiple
/>
<app-modal
  #discardSapModal
  [modalMessage]="app.text.internalItem.discard"
  [modalTitle]="app.text.thread.modalTitle"
  (ok)="clearItem()"
></app-modal>
<app-modal
  #deleteSapModal
  [modalMessage]="app.text.internalItem.deleteInternalItem"
  [modalTitle]="app.text.thread.modalTitle"
  (ok)="app.internalItems.deleteInternalItem()"
></app-modal>
<app-paginator
  [length]="app.internalItems.itemIds.length"
  [index]="app.paginator.focus"
  (focus)="app.paginator.focus = $event"
></app-paginator>
<div class="$event.stopPropagation()">
  <div class="tableFixHead">
    <table class="table table-striped sap-data-table">
      <thead>
        <tr>
          <th *ngFor="let field of columns" class="p-1">
            {{ app.field.getLabel(field) }}
          </th>
        </tr>
      </thead>
      <tbody>
        <tr
          *ngFor="
            let item of app.paginator.getPage(app.internalItems.items);
            let i = index
          "
        >
          <td *ngFor="let column of cleanColumns" class="p-1 text-center">
            <div [ngSwitch]="app.table.getColumnTypeDisplay(column)">
              <div *ngSwitchCase="'normal'" class="min-width">
                <input
                  type="text"
                  class="form-control"
                  [ngClass]="{ focus: focusInput }"
                  [(ngModel)]="item.part[column]"
                  [disabled]="!item.isEditable"
                />
              </div>

              <div *ngSwitchCase="'navigateAction'" class="min-width">
                <input
                  type="text"
                  class="form-control"
                  [ngClass]="{ focus: focusInput }"
                  [(ngModel)]="item.part[column]"
                  [disabled]="!item.isEditable"
                />
              </div>
              <div *ngSwitchCase="'checkBox'">
                <input
                  class="form-check-input entryCheckBox"
                  type="checkbox"
                  [disabled]="!item.isEditable"
                  value=""
                  id="flexCheckDefault"
                  [(ngModel)]="item.part.entryCheckbox"
                />
              </div>
              <div *ngSwitchCase="'actions'">
                <div
                  class="btn-group btn-group-sm actions-buttons"
                  role="group"
                >
                  <button
                    class="btn pe-1"
                    *ngIf="!item.isEditable"
                    (click)="setEditable(item, index)"
                  >
                    <i
                      class="fa fa-edit"
                      (click)="setEditable(item, index)"
                    ></i>
                  </button>

                  <button
                    *ngIf="item.isEditable"
                    class="btn pe-1"
                    [disabled]="isDisabled(item)"
                    (click)="app.internalItems.save(item, index)"
                  >
                    <i class="fa fa-check"></i>
                  </button>
                  <button
                    class="btn pe-1"
                    *ngIf="item.isEditable"
                    (click)="
                      app.internalItems.currentItem = item;
                      discardSapModal.open()
                    "
                  >
                    <i class="fa fa-times"></i>
                  </button>
                  <button
                    class="btn"
                    *ngIf="!item.isEditable"
                    (click)="
                      app.internalItems.currentItem = item;
                      deleteSapModal.open()
                    "
                  >
                    <i class="fa fa-trash"></i>
                  </button>
                </div>
              </div>
            </div>
          </td>
        </tr>
        <tr *ngIf="app.internalItems.addNewSapDataItem">
          <td *ngFor="let column of cleanColumns" class="p-1 text-center">
            <div [ngSwitch]="app.table.getColumnTypeDisplay(column)">
              <div *ngSwitchCase="'normal'">
                <input
                  type="text"
                  class="form-control"
                  [(ngModel)]="item.part[column]"
                />
              </div>
              <div *ngSwitchCase="'navigateAction'">
                <input
                  type="text"
                  class="form-control"
                  [(ngModel)]="item.part[column]"
                />
              </div>
              <div *ngSwitchCase="'checkBox'">
                <input
                  class="form-check-input"
                  type="checkbox"
                  value=""
                  id="flexCheckDefault"
                  [(ngModel)]="item.part.entryCheckbox"
                />
              </div>
              <div *ngSwitchCase="'actions'">
                <div class="btn-group btn-group-sm action-buttons" role="group">
                  <button
                    type="button"
                    class="btn pe-1"
                    *ngIf="app.permission.impact.canAddImpactToCase"
                    [disabled]="!item.part.partNumber"
                    (click)="app.internalItems.save(item)"
                  >
                    <i class="fa fa-check"></i>
                  </button>
                  <button
                    type="button"
                    class="btn pe-1"
                    [disabled]="!item.part.partNumber"
                    (click)="
                      app.internalItems.currentItem = item;
                      discardSapModal.open()
                    "
                  >
                    <i class="fa fa-times"></i>
                  </button>
                </div>
              </div>
            </div>
          </td>
        </tr>
        <tr>
          <td
            colspan="99"
            class="text-center"
            *ngIf="app.internalItems.itemExists"
          >
            <div class="error-notification" style="cursor: pointer">
              <div class="p-1">
                <i class="fas fa-exclamation-triangle"></i>
                {{ app.text.internalItem.itemExists }}
                <div
                  class="close-btn pull-right"
                  (click)="app.hasError = false"
                >
                  <i class="fa fa-times"></i>
                </div>
              </div>
            </div>
          </td>
          <td
            colspan="99"
            class="text-center"
            *ngIf="app.internalItems.hasSuccess"
          >
            <div class="success success-notification" style="cursor: pointer">
              <div class="p-1">
                <i class="fa fa-check"></i>
                {{ this.app.text.internalItem.itemSavedSuccesfully }}
                <div
                  class="close-btn pull-right"
                  (click)="app.hasSuccess = false"
                >
                  <i class="fa fa-times"></i>
                </div>
              </div>
            </div>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</div>
<app-paginator
  [length]="app.internalItems.itemIds.length"
  [index]="app.paginator.focus"
  (focus)="app.paginator.focus = $event"
></app-paginator>
<div class="bottom-buttons">
  <button
    class="btn"
    (click)="fileInput.click()"
    *ngIf="app.permission.impact.canAddImpactToCase"
    [title]="app.text.import.upload"
  >
    <i class="fa fa-upload"></i>
  </button>
  <button
    class="btn btn-light pull-right"
    *ngIf="app.permission.impact.canAddImpactToCase"
    (click)="deleteItem(); app.internalItems.addNewItem()"
  >
    New
  </button>
</div>
