import { ComponentSettings } from "../settings/settings";

const settings = {
  permission: {},
  text: {},
  list: {},
  url: {},
  field: {},
};

export const parameter: ComponentSettings<typeof settings> = settings;
