import { Customer } from "../types/customers";

export const RM_CLIENT = "rm-client";
export const NON_RM_CLIENT = "non-rm-client";

export const RM_CLIENTS = [
  Customer.KOMAX,
  Customer.SCHIEBEL,
  Customer.CAE,
  Customer.CEOTRONICS,
  Customer.AIRBUSDS,
  Customer.SICK,
  Customer.MAQUETGMBH,
  Customer.MAQUETCARDIO,
  Customer.IAV,
  Customer.HERRMANNULTRASCHALL,
  Customer.SSI,
  Customer.LRE,
  Customer.KVB,
  Customer.RUAGCH,
  Customer.KATEK,
  Customer.KATEKMEMM,
  Customer.KATEKMAUER,
  Customer.KATEKLEIP,
  Customer.KATEKGRASS,
  Customer.ESYSTEMS,
  Customer.EMZ,
  Customer.TRIAL1,
  Customer.TRIAL2,
  Customer.TRIAL3,
  Customer.TRIAL4,
  Customer.MTU,
  Customer.NS,
  Customer.MRCE,
  Customer.DEMO,
  Customer.DEMOZ2DATA,
  Customer.ATLAS,
  Customer.KNDS,
  Customer.ROSEN,
  Customer.TAE,
  Customer.KARLSTORZ,
  Customer.TSK,
  Customer.XLIVE,
  Customer.STADLER,
  Customer.BEFLEX,
  Customer.AUTOFLUG,
  Customer.SEW,
  Customer.PLASTICOMNIUM,
  Customer.RHEINMETALL,
  Customer.RHEINMETALLAIRDEFENCE,
  Customer.BELIMO,
  Customer.PILZ,
  Customer.RADWELL,
  Customer.DUOMETRIC,
  Customer.HAGENUK,
];

/** check if current customer is rm/ non-rm  */
export function checkIfRMCustomer(customer: string) {
  if (RM_CLIENTS.includes(customer)) {
    return RM_CLIENT;
  }

  return NON_RM_CLIENT;
  /** bvg, abc, rheinbahn, db */
}
