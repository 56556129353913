<div class="row py-3" *ngIf="app.RM.fromRMSearch">
  <div class="col-8">
    <button
      class="btn btn-sm"
      (click)="switchView('tree')"
      [ngClass]="{ 'btn-default-blue': app.RM.mode === 'tree' }"
    >
      <i class="fas fa-sitemap"></i>&nbsp;&nbsp;{{ app.text.RM.treeView }}
    </button>
    <button
      class="btn btn-sm"
      (click)="switchView('all-manufacturers')"
      [ngClass]="{
        'btn-default-blue': app.RM.mode === 'all-manufacturers'
      }"
    >
      <i class="fal fa-table"></i>&nbsp;&nbsp;{{ app.text.RM.table }}
    </button>
    <button
      class="btn btn-sm"
      (click)="switchView('matched')"
      [ngClass]="{ 'btn-default-blue': app.RM.mode === 'matched' }"
    >
      <i class="far fa-link"></i>&nbsp;&nbsp;{{ app.text.RM.matched }}
    </button>
    <button
      class="btn btn-sm"
      (click)="switchView('unmatched')"
      [ngClass]="{
        'btn-default-blue': app.RM.mode === 'unmatched'
      }"
    >
      <i class="far fa-unlink"></i>&nbsp;&nbsp;{{ app.text.RM.unmatched }}
    </button>
    <button
      class="btn btn-sm"
      (click)="switchView('manual')"
      [ngClass]="{ 'btn-default-blue': app.RM.mode === 'manual' }"
    >
      <i class="fa fa-user-circle"></i>&nbsp;&nbsp;{{ app.text.RM.manual }}
    </button>
    <button
      class="btn btn-sm"
      (click)="switchView('manualAssignment')"
      [ngClass]="{
        'btn-default-blue': app.RM.mode === 'manualAssignment'
      }"
    >
      <i class="fas fa-user-edit"></i>&nbsp;&nbsp;{{
        app.text.matchingManufacturer.edited
      }}
    </button>
    <button
      class="btn btn-sm"
      (click)="switchView('override')"
      [ngClass]="{
        'btn-default-blue': app.RM.mode === 'override'
      }"
    >
      <i class="far fa-hand-paper"></i>&nbsp;&nbsp;{{ app.text.RM.override }}
    </button>
  </div>

  <div class="col-4">
    <div class="d-flex justify-content-end">
      <div>
        <button
          class="btn btn-sm btn-clean"
          *ngIf="app.RM.mode !== 'tree' && app.permission.RM.canExport"
          (click)="app.RM.export('manufacturer')"
          [ngbTooltip]="app.text.home.export"
        >
          <i class="fa fa-download"></i>&nbsp;
          {{ app.text.manufacturer.export }}
        </button>
        <button
          class="btn btn-sm btn-clean"
          *ngIf="app.RM.mode !== 'tree'"
          (click)="app.RM.export('manufacturer', 'substances')"
          [ngbTooltip]="app.text.RM.exportReach"
        >
          <i class="fas fa-flask"></i>&nbsp;
          {{ app.text.RM.exportReach }}
        </button>
      </div>

      <div *ngIf="app.type === 'part' && app.RM.mode === 'tree'">
        <button
          class="btn btn-sm btn-default-blue"
          data-toggle="collapse"
          data-target="#collapseFilter"
          (click)="app.RM.filterOpen = !app.RM.filterOpen"
          [ngClass]="{ btnRMFilterOpen: app.RM.filterOpen }"
        >
          <i class="fas fa-filter"></i>
          {{ app.text.home.filters }}
          <i *ngIf="!app.RM.filterOpen" class="fal fa-angle-right"></i>
          <i *ngIf="app.RM.filterOpen" class="far fa-times ml-1"></i>
        </button>
      </div>
    </div>
  </div>
</div>
<div *ngIf="app.RM.fromRMSearch">
  <div class="descriptionList">
    <div class="flex">
      <b>{{ app.field.getLabel(app.fieldId.part.partNumber) }}: &nbsp;</b>
      <span>{{ app.filterTree.highestLevel.partNumber }}</span>
    </div>
    <div class="flex">
      <b> {{ app.field.getLabel(app.fieldId.part.description) }}: &nbsp;</b>
      <span> {{ app.filterTree.highestLevel.description }} </span>
    </div>
  </div>
</div>

<div class="load" *ngIf="app.tree.loadingTreeRows">
  <div class="loader"></div>
  <div class="load-message">{{ app.text.app.loadingTreeRows }}</div>
</div>
<div [ngSwitch]="app.RM.mode">
  <div *ngSwitchDefault>
    <app-manufacturers-filter-table></app-manufacturers-filter-table>
  </div>
  <div *ngSwitchCase="'tree'">
    <app-paginator
      [length]="app.tree.pages"
      [index]="app.paginator.focus"
      (focus)="app.paginator.focus = $event"
      [type]="'rm-tree'"
    >
    </app-paginator>
    <rm-submenu
      [sections]="[
        'status',
        'risk',
        'likelihood',
        'impact',
        'rohs',
        'reach',
        'lead'
      ]"
    ></rm-submenu>
    <div>
      <div class="p-1 tableFixHead table-responsive">
        <table class="table table-font-size table-striped spacing-table">
          <thead>
            <tr>
              <th
                *ngFor="let column of app.tree.currentColumns"
                [ngSwitch]="column"
                class="p-1"
                [ngClass]="app.tree.getHeaderStyle(column)"
                [title]="app.table.setHeaderTooltip(column)"
              >
                <div
                  [ngClass]="{
                    'resize-table': app.table.canColumnBeResized(column)
                  }"
                >
                  <span
                    [innerHtml]="app.field.handleSpecialLogicLabels(column)"
                  ></span>
                </div>
              </th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td *ngIf="loading" colspan="99" class="text-center">
                {{ this.app.text.core.loading }}
              </td>
            </tr>
            <tr>
              <td
                *ngIf="!app.tree.loading && app.tree.items.length === 0"
                colspan="99"
                class="text-center"
              >
                {{ app.text.app.noResults }}
              </td>
            </tr>
            <tr
              *ngFor="let row of app.tree.items; let i = index"
              class="treeRow"
              [ngClass]="{ manufacturerRow: app.tree.isManufacturerRow(row) }"
            >
              <app-tree-row
                *ngIf="!app.tree.isManufacturerRow(row) || !displayMessage(row)"
                [row]="row"
                [columns]="app.tree.currentColumns"
                [index]="i"
                style="display: contents"
              ></app-tree-row>
              <!-- Display message in a tree row if there are no manufacturers under a part-->
              <td *ngIf="displayMessage(row)" colspan="99">
                <span class="m-5">
                  {{ app.text.manufacturer.noManufacturer }}
                </span>
              </td>
              <!-- <td *ngFor="let column of columns" class="p-1">
                <span
                  *ngIf="
                    !app.tree.isManufacturerRow(row) || !displayMessage(row)
                  "
                >
                  <app-tree-cell [row]="row" [column]="column"></app-tree-cell>
                </span>
              </td> -->
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</div>
