<ng-template #content let-modal>
  <div class="modal-header">
    <div class="col-md-12">
      <button class="btn pull-right" (click)="modal.dismiss('CANCEL')">
        <i class="fa fa-times"></i>
      </button>
    </div>
  </div>
  <div class="modal-body">
    <div class="col-md-12" *ngIf="type ==='discard'">
      <span *ngIf="step === 'currentMatch'">
        {{app.text.matchingManufacturerModal.currentMatch}}
      </span>
      <span *ngIf="step === 'keepCurrentMatch'">
        {{app.text.matchingManufacturerModal.keepCurrentMatch}}
      </span>
      <span *ngIf="step === 'discardCurrentMatch'">
        {{app.text.matchingManufacturerModal.discardCurrentMatch}}
      </span>
    </div>
    <div class="col-md-12" *ngIf="type ==='save'">
      <span *ngIf="step ==='selectItem'">
        {{app.text.matchingManufacturerModal.selectItem}}
      </span>
      <span *ngIf="step ==='loadData'">
        {{app.text.matchingManufacturerModal.loadedData}}
      </span>
    </div>
  </div>
  <div class="modal-footer">
    <button class="btn btn-cancel text-center m-1" (click)="goToNext(step, 'dismiss')">{{getText(step, 'dismiss')}}</button>
    <button class="btn btn-ok text-center m-1" (click)="goToNext(step, 'save')">{{getText(step, 'save')}}</button>
  </div>
</ng-template>