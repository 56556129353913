<ng-template #taskCompletedNoteModal let-modal>
  <div class="modal-header">
    <span class="modal-title">{{ app.field.getLabel("post.taskNote") }}</span>
  </div>
  <div class="modal-body">
    <div class="mb-3">{{ app.text.post.taskNoteInfo }}</div>
    <textarea class="form-control m-0 lineHeight taskNoteArea" type="text" rows="5" [(ngModel)]="taskNote"
      maxlength="4096">
    </textarea>
  </div>
  <div class="modal-footer">
    <button class="btn btn-cancel text-center m-1" (click)="closeModal()">
      {{ app.text.post.cancel }}
    </button>
    <button class="btn btn-ok text-center m-1" (click)="addTaskNote(currentTask)">
      {{ app.text.post.save }}
    </button>
  </div>
</ng-template>