<div
  class="btn-toolbar justify-content-between"
  [ngClass]="changeHeaderColor()"
  role="toolbar"
  aria-label="Toolbar with button groups"
>
  <div class="btn-group" role="group" aria-label="First group">
    <button
      class="btn btn-toggle"
      (click)="app.header.toggleSidebar()"
      *ngIf="app.header.showSidebar"
    >
      <i class="fal fa-chevron-double-left icon-toggle"></i>
    </button>
    <button
      class="btn btn-toggle"
      (click)="app.header.toggleSidebar()"
      *ngIf="!app.header.showSidebar"
    >
      <i class="fal fa-chevron-double-right icon-toggle"></i>
    </button>
    <div class="input-group">
      <div class="input-group-prepend">
        <div
          class="header ps-1 ms-1"
          id="header"
          [ngClass]="{ 'small-header': displayFeatures }"
        >
          {{ app.header.headerTitle }}
        </div>
        <div
          class="header"
          *ngIf="displayFeatures"
          [ngClass]="{ 'small-header': displayFeatures }"
        >
          <span class="d-md-block ms-lg-2 d-lg-inline-block ms-lg-0">
            {{ app.text.sidebar.poweredBy }}
            <img
              class="seLogo pointer"
              src="./../assets/siliconexpert-logo.png"
              (click)="
                app.routing.openInNewTab('https://www.siliconexpert.com')
              "
            />
            {{ app.text.sidebar.and }}
            <img
              class="lcmLogo pointer"
              src="./../assets/lcm-client-logo-rgb.png"
              (click)="app.routing.openInNewTab('https://lcm-client.com')"
            />
          </span>
        </div>
      </div>
    </div>
  </div>

  <div class="btn-group" role="group" aria-label="third-button-group">
    <div
      class="mx-2 mt-1"
      *ngIf="
        app.permission.thread.hasPermissionToCreateThread && app.view === 'home'
      "
      [ngClass]="{ active: app.thread.isNew && app.view === 'thread' }"
    >
      <button
        class="btn btn-sm btn-default-red mt-2"
        (click)="app.clearModel(); navigateNewThread()"
        *ngIf="app.permission.thread.hasPermissionToCreateThread"
        [disabled]="app.unlockedId != null"
      >
        <i class="fas fa-folder-plus"></i>&nbsp;
        {{ app.text.thread.newCase }}
      </button>
    </div>
    <!-- <div
      class="mx-2 mt-1"
      *ngIf="app.view == 'RM' && app.permission.operations.seeOperations"
    >
      <button
        class="btn btn-sm btn-default-blue mt-2"
        (click)="app.routing.navigateOperations(); app.paginator.focus = 0"
      >
        <i class="fas fa-toolbox"></i>&nbsp;
        {{ app.text.operations.operations }}
      </button>
    </div> -->
    <div *ngIf="app.view === 'users'" class="mx-2 mt-1">
      <button
        class="btn btn-sm btn-clean mt-2"
        (click)="app.users.exportData()"
      >
        <i class="fa fa-download"></i>&nbsp; {{ app.text.post.export }}
      </button>
    </div>
    <div class="mx-2 mt-1" *ngIf="showAddUserBtn">
      <button
        class="btn btn-sm btn-default-orange mt-2"
        (click)="app.routing.navigateNewUser()"
      >
        <i class="fa fa-plus"></i>&nbsp; {{ app.text.user.add }}
      </button>
    </div>
  </div>
</div>
