import { ActivatedRoute, Router } from "@angular/router";
import { Component, OnInit } from "@angular/core";
import { getApp } from "../app";
import { InboxType } from "../state/state";

@Component({
  selector: "app-mail",
  templateUrl: "./mail.component.html",
  styleUrls: ["./mail.component.scss"],
})
export class MailComponent implements OnInit {
  mode: InboxType = "change";
  pcnId: string = "";
  showExport: boolean = false;
  url: string = "";

  app = getApp((app) => {
    this.app = app;
  });

  constructor(private _route: ActivatedRoute) {}

  public ngOnInit(): void {
    this.app.type = "mail";
    if (this._route.snapshot.firstChild != null) {
      this.pcnId = this._route.snapshot.firstChild.params["pcnId"];
      const data = this._route.snapshot.firstChild.data;
      if (data != null) {
        this.app.mail.tab = data.mode;
        this.mode = data.mode;
      }
      this.showExportBtn();
    }
    this.pcnURL();
  }

  showExportBtn() {
    if (
      this.mode === "alert" ||
      this.mode === "changeAlert" ||
      this.mode === "leadTimeAlert" ||
      this.mode === "inventoryMonitoringAlert"
    ) {
      this.showExport = true;
    }
  }

  routerLinkUrl(route: string) {
    return `/${this.app.customers.expectCurrent}/` + route;
  }

  pcnURL() {
    this.url = `https://app.lcm-client.com/${this.app.customers.expectCurrent}/pcn`;
    return this.url;
  }
}
