import { AppType, APP_TYPE } from "../app.type";
import { PersonServiceType } from "./person.service.type";
import { Inject, Injectable } from "@angular/core";
import { getPersonDocs } from "../api.service";
import { Person } from "../../../../shared/models/person";

@Injectable()
export class PersonService implements PersonServiceType {
  constructor(@Inject(APP_TYPE) private app: AppType) {}
  persons: Person[] = [];

  async getPersons() {
    this.app.person.persons = await getPersonDocs(
      this.app.customers.expectCurrent
    );
  }

  getPersonOptions() {
    let persons = this.app.person.persons;
    if (persons == null) {
      return [];
    }
    return persons.map((p) => p.firstName + " " + p.lastName);
  }

  setPersonFields(fieldName: string, option: string) {
    let index = this.app.person.persons.findIndex(
      (p) => p.firstName + " " + p.lastName === option
    );
    let selectedPerson = this.app.person.persons[index];

    if (fieldName === this.app.fieldId.thread.indicatorName) {
      this.app.thread.thread[
        "thread.indicatorName"
      ] = `${selectedPerson.firstName} ${selectedPerson.lastName}`;
      this.app.thread.thread["thread.indicatorNameShort"] =
        selectedPerson.shortName;
      this.app.thread.thread["thread.indicatorEmail"] = selectedPerson.email;
      this.app.thread.thread["thread.indicatorTelephone"] =
        selectedPerson.telephone;
      this.app.thread.thread["thread.indicatorDep"] = selectedPerson.department;
    }

    if (fieldName === this.app.fieldId.thread.dinCodeRespName) {
      this.app.thread.thread[
        "thread.dinCodeRespName"
      ] = `${selectedPerson.firstName} ${selectedPerson.lastName}`;
      this.app.thread.thread["thread.dinCodeRespDep"] =
        selectedPerson.department;
      this.app.thread.thread["thread.dinCodeRespEmail"] = selectedPerson.email;
    }
  }
}
