<span [ngSwitch]="column">
  <!-- Part number -->
  <div *ngSwitchCase="'part.partNumber'" class="width-partNumber">
    &nbsp;
    <!-- Indentation -->
    <span *ngFor="let p of counter(row.level)"
      >&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span
    >
    <span *ngIf="isPart">
      <span
        *ngIf="!app.tree.isOpenRow(row) && !app.tree.isLeafRow(row)"
        (click)="app.tree.toggleTreeRow(row)"
        ><i class="far fa-plus icon-gray pointer p-1"></i>
      </span>
      <span
        *ngIf="app.tree.isOpenRow(row)"
        (click)="app.tree.toggleTreeRow(row)"
        ><i class="far fa-minus icon-gray pointer p-1"></i
      ></span>
      <a
        class="partNumber text-center"
        (click)="app.treeCell.navigateToDetailPage(row)"
      >
        <span
          *ngIf="app.tree.isLeafRow(row)"
          (click)="app.tree.toggleTreeRow(row)"
          ><i class="fa fa-minus icon-gray pointer p-1"></i
        ></span>
        {{ row.node.part.partNumber }}
      </a>
    </span>
    <a
      *ngIf="isManufacturer"
      class="partNumber"
      (click)="app.treeCell.navigateToDetailPage(row)"
    >
      <i class="fa fa-angle-right icon-gray"></i>
      {{ row.manufacturer.manufacturerPartNumber }}
    </a>
  </div>

  <!-- Parts To Vehicles -->
  <!-- Do not display the icon if it doesn't contain any "pontential vehicles" -->
  <div *ngSwitchCase="'part.partsToVehicles'" class="text-center cursor">
    <span
      *ngIf="isPart && row.level !== 0"
      (click)="openWhereUsedModal(row.node.part.partNumber)"
    >
      <i class="fas fa-sitemap fa-rotate-270 pointer icon-blue"></i>
    </span>
  </div>

  <!-- Quantity -->
  <div *ngSwitchCase="'part.quantity'" class="text-center">
    <span *ngIf="isPart">{{ row.quantity ? row.quantity : 1 }}</span>
    <span *ngIf="isManufacturer"></span>
  </div>

  <!-- Purchase part number(manufacturer) -->
  <div *ngSwitchCase="'manufacturer.purchasePartNumber'">
    <span *ngIf="isPart">{{ row.node.part.purchasePartNumber }}</span>
    <span *ngIf="isManufacturer">{{
      row.manufacturer.purchasePartNumber
    }}</span>
  </div>

  <!-- Manage proactively? / Preferred Item? -->
  <div
    *ngSwitchCase="'part.obsolescenceManagement' || 'manufacturer.active'"
    class="text-center"
  >
    <span *ngIf="isPart">
      <i
        class="far fa-dot-circle"
        *ngIf="row.node.part.obsolescenceManagement === 'true'"
      ></i>
      <i
        class="fal fa-circle"
        *ngIf="row.node.part.obsolescenceManagement === 'false'"
      ></i>
    </span>
    <span *ngIf="isManufacturer">
      <i
        class="fas fa-star-of-life"
        *ngIf="row.manufacturer.active === true"
      ></i>
    </span>
  </div>

  <!-- Description / Manufacturer name (raw)  -->
  <div
    *ngSwitchCase="'part.description' || 'manufacturer.name'"
    class="description-cell"
  >
    <span *ngIf="isPart">{{ row.node.part.description }}</span>
    <span *ngIf="isManufacturer">{{ row.manufacturer.name }} </span>
  </div>

  <!-- See detailed view -->
  <a
    *ngSwitchCase="'part.detailView'"
    class="icon-column"
    (click)="app.treeCell.navigateToDetailPage(row)"
  >
    <i class="fa fa-info icon-black pointer p-1"></i>
  </a>
  <!-- See case view -->
  <div *ngSwitchCase="'part.threadCase'" class="icon-column">
    <span *ngIf="app.permission.RM.createCase">
      <i
        class="far fa-folder-plus icon-blue pointer p-1"
        *ngIf="
          !app.treeCell.getCase(row) &&
          isManufacturer &&
          row.manufacturer.manufacturerPartNumber !== ''
        "
        (click)="app.treeCell.newCase(row); app.treeCell.currentRow = row"
      ></i>
      <i
        class="fas fa-folder-open icon-blue pointer p-1"
        *ngIf="app.treeCell.getCase(row)"
        (click)="
          seeOpenedCasesModal.open();
          app.treeCell.getCasesByPartNumber(row);
          app.treeCell.currentRow = row
        "
      ></i>
    </span>
  </div>

  <!-- See match system -->
  <div *ngSwitchCase="'part.matchedSystem'" class="icon-column">
    <a
      *ngIf="isManufacturer"
      (click)="app.treeCell.navigateToMatchingPage(row)"
    >
      <i
        class="far fa-link icon-blue pointer p-1"
        *ngIf="
          app.manufacturer.getMatchingStatusOfManufacturer(row.manufacturer) ===
          'matched'
        "
      ></i>
      <i
        class="fa fa-user-circle icon-blue pointer p-1"
        *ngIf="
          app.manufacturer.getMatchingStatusOfManufacturer(row.manufacturer) ===
          'manual'
        "
      ></i>
      <i
        class="far fa-unlink icon-blue pointer p-1"
        *ngIf="
          app.manufacturer.getMatchingStatusOfManufacturer(row.manufacturer) ===
          'unmatched'
        "
      ></i>
      <i
        class="far fa-hand-paper icon-blue"
        *ngIf="
          app.manufacturer.getMatchingStatusOfManufacturer(row.manufacturer) ===
          'override'
        "
      ></i>
      <i
        class="fas fa-user-edit icon-blue pointer"
        *ngIf="
          app.manufacturer.getMatchingStatusOfManufacturer(row.manufacturer) ===
          'manualAssignment'
        "
      ></i>
    </a>
  </div>

  <!-- Material Status (SAP) -->
  <div *ngSwitchCase="'part.sapStatus'" class="text-center">
    <span *ngIf="isPart">{{ row.node.part.sapStatus }}</span>
    <span *ngIf="isManufacturer">{{ row.manufacturer.sapStatus }}</span>
  </div>

  <!-- Current Obsolescence Status -->
  <div *ngSwitchCase="'part.obsolescenceStatus'" class="text-center">
    <span
      *ngIf="isPart"
      class="badge"
      [ngStyle]="
        app.field.getColor(
          app.fieldId.part.obsolescenceStatus,
          row.node.part.obsolescenceStatus
        )
      "
    >
      {{
        app.field.getOptionText(
          app.fieldId.part.obsolescenceStatus,
          row.node.part.obsolescenceStatus
        )
      }}
    </span>
    <span
      *ngIf="isManufacturer"
      class="badge"
      [ngStyle]="
        app.field.getColor(
          app.fieldId.manufacturer.obsolescenceStatus,
          row.manufacturer.obsolescenceStatus
        )
      "
    >
      {{
        app.field.getOptionText(
          app.fieldId.manufacturer.obsolescenceStatus,
          row.manufacturer.obsolescenceStatus
        )
      }}
    </span>
  </div>

  <!-- 2 Years Forecast -->
  <div *ngSwitchCase="'part.obsolescenceStatus2years'" class="text-center">
    <span
      *ngIf="isPart"
      class="badge"
      [ngStyle]="
        app.field.getColor(
          app.fieldId.part.obsolescenceStatus2years,
          row.node.part.obsolescenceStatus2years
        )
      "
    >
      {{
        app.field.getOptionText(
          app.fieldId.part.obsolescenceStatus2years,
          row.node.part.obsolescenceStatus2years
        )
      }}
    </span>
    <span
      *ngIf="isManufacturer"
      class="badge"
      [ngStyle]="
        app.field.getColor(
          app.fieldId.manufacturer.obsolescenceStatus2years,
          row.manufacturer.obsolescenceStatus2years
        )
      "
    >
      {{
        app.field.getOptionText(
          app.fieldId.manufacturer.obsolescenceStatus2years,
          row.manufacturer.obsolescenceStatus2years
        )
      }}
    </span>
  </div>

  <!-- 4 Years Forecast -->
  <div *ngSwitchCase="'part.obsolescenceStatus4years'" class="text-center">
    <span
      *ngIf="isPart"
      class="badge"
      [ngStyle]="
        app.field.getColor(
          app.fieldId.part.obsolescenceStatus4years,
          row.node.part.obsolescenceStatus4years
        )
      "
    >
      {{
        app.field.getOptionText(
          app.fieldId.part.obsolescenceStatus4years,
          row.node.part.obsolescenceStatus4years
        )
      }}
    </span>
    <span
      *ngIf="isManufacturer"
      class="badge"
      [ngStyle]="
        app.field.getColor(
          app.fieldId.manufacturer.obsolescenceStatus4years,
          row.manufacturer.obsolescenceStatus4years
        )
      "
    >
      {{
        app.field.getOptionText(
          app.fieldId.manufacturer.obsolescenceStatus4years,
          row.manufacturer.obsolescenceStatus4years
        )
      }}
    </span>
  </div>

  <!-- 6 Years Forecast -->
  <div *ngSwitchCase="'part.obsolescenceStatus6years'" class="text-center">
    <span
      *ngIf="isPart"
      class="badge"
      [ngStyle]="
        app.field.getColor(
          app.fieldId.part.obsolescenceStatus6years,
          row.node.part.obsolescenceStatus6years
        )
      "
    >
      {{
        app.field.getOptionText(
          app.fieldId.part.obsolescenceStatus6years,
          row.node.part.obsolescenceStatus6years
        )
      }}
    </span>
    <span
      *ngIf="isManufacturer"
      class="badge"
      [ngStyle]="
        app.field.getColor(
          app.fieldId.manufacturer.obsolescenceStatus6years,
          row.manufacturer.obsolescenceStatus6years
        )
      "
    >
      {{
        app.field.getOptionText(
          app.fieldId.manufacturer.obsolescenceStatus6years,
          row.manufacturer.obsolescenceStatus6years
        )
      }}
    </span>
  </div>

  <!-- 8 Years Forecast -->
  <div *ngSwitchCase="'part.obsolescenceStatus8years'" class="text-center">
    <span
      *ngIf="isPart"
      class="badge"
      [ngStyle]="
        app.field.getColor(
          app.fieldId.part.obsolescenceStatus8years,
          row.node.part.obsolescenceStatus8years
        )
      "
    >
      {{
        app.field.getOptionText(
          app.fieldId.part.obsolescenceStatus8years,
          row.node.part.obsolescenceStatus8years
        )
      }}
    </span>
    <span
      *ngIf="isManufacturer"
      class="badge"
      [ngStyle]="
        app.field.getColor(
          app.fieldId.manufacturer.obsolescenceStatus8years,
          row.manufacturer.obsolescenceStatus8years
        )
      "
    >
      {{
        app.field.getOptionText(
          app.fieldId.manufacturer.obsolescenceStatus8years,
          row.manufacturer.obsolescenceStatus8years
        )
      }}
    </span>
  </div>

  <!-- Obsolescence Risk -->
  <div *ngSwitchCase="'part.totalRisk'" class="text-center">
    <span
      *ngIf="isPart"
      class="badge"
      [ngStyle]="
        app.field.getColor(app.fieldId.part.totalRisk, row.node.part.totalRisk)
      "
    >
      {{
        app.field.getOptionText(
          app.fieldId.part.totalRisk,
          row.node.part.totalRisk
        )
      }}
    </span>
    <span *ngIf="isManufacturer"></span>
  </div>

  <!-- Obsolescence Likelihood -->
  <div *ngSwitchCase="'part.likelihood'" class="text-center">
    <span
      *ngIf="isPart"
      class="badge"
      [ngStyle]="
        app.field.getColor(
          app.fieldId.part.likelihood,
          row.node.part.likelihood
        )
      "
    >
      {{
        app.field.getOptionText(
          app.fieldId.part.likelihood,
          row.node.part.likelihood
        )
      }}
    </span>
    <span
      *ngIf="isManufacturer"
      class="badge"
      [ngStyle]="
        app.field.getColor(
          app.fieldId.manufacturer.likelihood,
          row.manufacturer.likelihood
        )
      "
    >
      {{
        app.field.getOptionText(
          app.fieldId.manufacturer.likelihood,
          row.manufacturer.likelihood
        )
      }}
    </span>
  </div>

  <!-- No of valid manufacturers -->

  <div *ngSwitchCase="'part.numberSecSource'" class="text-center">
    <span *ngIf="isPart">{{ row.node.part.numberSecSource }}</span>
    <span *ngIf="isManufacturer"></span>
  </div>

  <!-- Obsolescence Impact -->
  <div *ngSwitchCase="'part.impact'" class="text-center">
    <span
      *ngIf="isPart"
      class="badge"
      [ngStyle]="
        app.field.getColor(app.fieldId.part.impact, row.node.part.impact)
      "
    >
      {{
        app.field.getOptionText(app.fieldId.part.impact, row.node.part.impact)
      }}
    </span>
    <span *ngIf="isManufacturer"></span>
  </div>
  <div *ngSwitchCase="'part.itemType'">
    <span *ngIf="isPart">
      {{
        app.field.getOptionText(
          app.fieldId.part.itemType,
          row.node.part.itemType
        )
      }}
    </span>
  </div>

  <!-- No of affected assemblies -->

  <div *ngSwitchCase="'part.freqUse'" class="text-center">
    <span *ngIf="isPart">{{ row.node.part.freqUse }}</span>
    <span *ngIf="isManufacturer"></span>
  </div>

  <!-- Complexity -->

  <div *ngSwitchCase="'part.catComplexity'" class="text-center">
    <span *ngIf="isPart">
      {{
        app.field.getOptionText(
          app.fieldId.part.catComplexity,
          row.node.part.catComplexity
        )
      }}</span
    >
    <span *ngIf="isManufacturer"></span>
  </div>

  <!-- End of Production Forecast (Year) -->
  <div *ngSwitchCase="'manufacturer.estimatedEOLDate'" class="text-center">
    <span *ngIf="isPart"></span>
    <span *ngIf="isManufacturer">{{ row.manufacturer.estimatedEOLDate }}</span>
  </div>

  <!-- End of Service & Repair (EOSR) Forecast" -->
  <div *ngSwitchCase="'manufacturer.productEOSR'" class="text-center">
    <span *ngIf="isPart"></span>
    <span *ngIf="isManufacturer">{{ row.manufacturer.productEOSR }}</span>
  </div>

  <!-- End of Production Forecast (YTEOL) -->
  <div *ngSwitchCase="'manufacturer.estimatedYearsToEOL'" class="text-center">
    <span *ngIf="isPart"></span>
    <span *ngIf="isManufacturer">{{
      row.manufacturer.estimatedYearsToEOL
    }}</span>
  </div>

  <!-- Life Cycle Code -->
  <div *ngSwitchCase="'manufacturer.lifecycleCode'">
    <span *ngIf="isPart"></span>
    <span *ngIf="isManufacturer">{{ row.manufacturer.lifecycleCode }}</span>
  </div>

  <!-- End of Sale (Last Time Buy - LTB) -->
  <div *ngSwitchCase="'manufacturer.ltbDate'" class="text-center">
    <span *ngIf="isPart"></span>
    <span *ngIf="isManufacturer">{{ row.manufacturer.ltbDate }}</span>
  </div>
  <!-- Years to End of Service & Repair (YTEOSR) Forecast -->
  <div *ngSwitchCase="'manufacturer.productYearsToEOSR'" class="text-center">
    <span *ngIf="isPart"></span>
    <span *ngIf="isManufacturer">{{
      row.manufacturer.productYearsToEOSR
    }}</span>
  </div>

  <!-- Item product category -->
  <div *ngSwitchCase="'part.productCategory'" class="text-center">
    <span *ngIf="isPart">{{
      app.field.getOptionText(
        app.fieldId.part.productCategory,
        row.node.part.productCategory
      )
    }}</span>
    <span *ngIf="isManufacturer">{{
      app.field.getOptionText(
        app.fieldId.manufacturer.taxonomy,
        row.manufacturer.taxonomy
      )
    }}</span>
  </div>

  <!--Status Risk-->
  <div *ngSwitchCase="'part.statusRisk'" class="text-center">
    <span *ngIf="isPart">{{
      app.field.getOptionText(
        app.fieldId.part.statusRisk,
        row.node.part.statusRisk
      )
    }}</span>
    <span *ngIf="isManufacturer"></span>
  </div>

  <!-- Stock Range in Months -->
  <div *ngSwitchCase="'part.stockRange'" class="text-center">
    <span *ngIf="isPart && app.customer !== 'mrce'">{{
      app.field.formatNumberWithDecimals(row.node.part.stockRange, 2)
    }}</span>
    <span *ngIf="isPart && app.customer === 'mrce'">{{
      row.node.part.totalLifeCycleUsage
    }}</span>
    <span *ngIf="isManufacturer">{{
      app.field.formatNumberWithDecimals(row.manufacturer.stockRange, 2)
    }}</span>
  </div>

  <!-- Design type -->
  <div *ngSwitchCase="'part.designType'" class="text-center">
    <span *ngIf="isPart">{{ row.node.part.designType }}</span>
    <span *ngIf="isManufacturer">{{ row.manufacturer.designType }}</span>
  </div>

  <!-- Actual stock -->
  <div *ngSwitchCase="'part.actualStock'">
    <span *ngIf="isPart">{{ row.node.part.actualStock }}</span>
  </div>
  <!-- 24 months demand forecast-->
  <div *ngSwitchCase="'part.totalForecast2Years'">
    <span *ngIf="isPart">{{ row.node.part.totalForecast2Years }}</span>
  </div>
  <!-- Life Cycle demand forecast-->
  <div *ngSwitchCase="'part.totalLifeCycleUsage'">
    <span *ngIf="isPart">{{
      app.field.formatNumberWithDecimals(row.node.part.totalLifeCycleUsage, 2)
    }}</span>
  </div>
  <!-- Forecast Stock Level at EOL-->
  <div *ngSwitchCase="'part.forecastEOLStock'">
    <span *ngIf="isPart">{{
      app.field.formatNumberWithDecimals(row.node.part.forecastEOLStock, 2)
    }}</span>
  </div>
  <!-- Safety Stock-->
  <div *ngSwitchCase="'part.safetyStock'">
    <span *ngIf="isPart">{{ row.node.part.safetyStock }}</span>
  </div>

  <!-- EU RoHS Status -->
  <div *ngSwitchCase="'manufacturer.euRoHS'" class="text-center">
    <span
      *ngIf="isPart"
      class="badge"
      [ngStyle]="
        app.field.getColor(app.fieldId.part.euRoHS, row.node.part.euRoHS)
      "
    >
      {{
        app.field.getOptionText(app.fieldId.part.euRoHS, row.node.part.euRoHS)
      }}
    </span>
    <span
      *ngIf="isManufacturer"
      class="badge"
      [ngStyle]="
        app.field.getColor(
          app.fieldId.manufacturer.euRoHS,
          row.manufacturer.euRoHS
        )
      "
    >
      {{
        app.field.getOptionText(
          app.fieldId.manufacturer.euRoHS,
          row.manufacturer.euRoHS
        )
      }}
    </span>
  </div>

  <!-- RoHS Version -->
  <div *ngSwitchCase="'manufacturer.euRoHSVersion'" class="text-center">
    <span *ngIf="isPart"></span>
    <span *ngIf="isManufacturer">{{ row.manufacturer.euRoHSVersion }}</span>
  </div>

  <!-- Exemption-->
  <div *ngSwitchCase="'manufacturer.exemption'">
    <span *ngIf="isPart">{{ row.node.part.exemption }}</span>
    <span *ngIf="isManufacturer">{{ row.manufacturer.exemption }}</span>
  </div>
  <!-- Expiration date -->
  <div *ngSwitchCase="'manufacturer.expirationDate'">
    <span *ngIf="isPart"></span>
    <span *ngIf="isManufacturer">{{ row.manufacturer.expirationDate }}</span>
  </div>

  <!-- Link -->
  <div *ngSwitchCase="'manufacturer.environmentalLink'">
    <span *ngIf="isPart"></span>
    <span *ngIf="isManufacturer">
      <a
        *ngIf="row.manufacturer.environmentalLink"
        [href]="row.manufacturer.environmentalLink"
        target="_blank"
      >
        <i class="fas fa-file icon-blue"></i>
      </a>
    </span>
  </div>

  <!-- Reach affected -->
  <div *ngSwitchCase="'manufacturer.reachAffected'" class="text-center">
    <span
      *ngIf="isPart"
      class="badge"
      [ngStyle]="
        app.field.getColor(
          app.fieldId.part.reachAffected,
          row.node.part.reachAffected
        )
      "
    >
      {{
        app.field.getOptionText(
          app.fieldId.part.reachAffected,
          row.node.part.reachAffected
        )
      }}
    </span>
    <span
      *ngIf="isManufacturer"
      class="badge"
      [ngStyle]="
        app.field.getColor(
          app.fieldId.manufacturer.reachAffected,
          row.manufacturer.reachAffected
        )
      "
    >
      {{
        app.field.getOptionText(
          app.fieldId.manufacturer.reachAffected,
          row.manufacturer.reachAffected
        )
      }}
    </span>
  </div>

  <!-- REACH Substance Identification -->
  <div *ngSwitchCase="'manufacturer.reachSubstances'" class="text-center">
    <span *ngIf="isPart">{{ row.node.part.reachSubstances }}</span>
    <span *ngIf="isManufacturer">{{ row.manufacturer.reachSubstances }}</span>
  </div>

  <!-- EU SVHC List Version -->
  <div *ngSwitchCase="'manufacturer.svhcListVersion'" class="text-center">
    <span *ngIf="isPart"></span>
    <span *ngIf="isManufacturer">{{ row.manufacturer.svhcListVersion }}</span>
  </div>

  <!-- Material Declaration -->
  <div *ngSwitchCase="'manufacturer.materialDeclaration'">
    <span *ngIf="isPart"></span>
    <span *ngIf="isManufacturer">
      <a
        *ngIf="row.manufacturer.materialDeclaration"
        [href]="row.manufacturer.materialDeclaration"
        target="_blank"
      >
        <i class="fas fa-file icon-blue"></i>
      </a>
    </span>
  </div>

  <!-- Lead Time -->
  <div *ngSwitchCase="'manufacturer.leadTime'" class="text-center">
    <span
      *ngIf="isPart"
      class="badge"
      [ngStyle]="
        app.field.getColor(app.fieldId.part.leadTime, row.node.part.leadTime)
      "
    >
      {{
        app.field.getOptionText(
          app.fieldId.part.leadTime,
          row.node.part.leadTime
        )
      }}
    </span>
    <span
      *ngIf="isManufacturer"
      class="badge"
      [ngStyle]="
        app.field.getColor(
          app.fieldId.manufacturer.leadTime,
          row.manufacturer.leadTime
        )
      "
    >
      {{
        app.field.getOptionText(
          app.fieldId.manufacturer.leadTime,
          row.manufacturer.leadTime
        )
      }}
    </span>
  </div>

  <!-- Max Weeks -->
  <div *ngSwitchCase="'manufacturer.maxWeeks'" class="text-center">
    <span *ngIf="isPart"></span>
    <span *ngIf="isManufacturer">{{ row.manufacturer.maxWeeks }}</span>
  </div>

  <!-- Min Weeks -->
  <div *ngSwitchCase="'manufacturer.minWeeks'" class="text-center">
    <span *ngIf="isPart"></span>
    <span *ngIf="isManufacturer">{{ row.manufacturer.minWeeks }}</span>
  </div>

  <!-- Country of Origin -->
  <div *ngSwitchCase="'manufacturer.countryOfOrigin'" class="text-center">
    <span *ngIf="isPart"></span>
    <span *ngIf="isManufacturer">{{ row.manufacturer.countryOfOrigin }}</span>
  </div>

  <!-- Average Price -->
  <div *ngSwitchCase="'manufacturer.averagePrice'" class="text-center">
    <span *ngIf="isPart"></span>
    <span *ngIf="isManufacturer">{{
      app.field.formatNumberWithDecimals(row.manufacturer.averagePrice, 2)
    }}</span>
  </div>

  <!-- Unknown -->
  <div *ngSwitchDefault>(UNKNOWN)</div>
</span>

<app-modal
  #seeStructureModal
  [modalTitle]="app.text.part.whereUsed"
  [modalType]="'showWhereUsed'"
  [showButtons]="false"
>
</app-modal>

<app-modal
  #seeOpenedCasesModal
  [modalTitle]="app.text.thread.threads"
  [modalType]="'showListOfCases'"
  [showButtons]="false"
>
</app-modal>
