<ng-template #content let-modal>
  <div class="modal-header">
    <div class="col-md-12">
      <button class="btn pull-right" (click)="dismiss()">
        <i class="fa fa-times"></i>
      </button>
      <span> {{ app.text.part.addNewPart }} </span>
    </div>
  </div>
  <div class="modal-body">
    <div class="col-md-12">
      <app-field
        [model]="model"
        [field]="app.fieldId.part.partNumber"
        [index]="index"
      ></app-field>
      <div class="row">
        <div class="fieldLabel col-md-6 ps-4">
          <span class="fieldName">
            {{ app.field.getLabel(this.app.fieldId.part.quantity) }}
          </span>
        </div>
        <div class="col-md-6">
          <input
            type="number"
            class="form-control lineHeight underline"
            [(ngModel)]="app.model['part.quantity']"
          />
        </div>
      </div>
    </div>
  </div>
  <div class="modal-footer">
    <button class="btn btn-cancel text-center m-1" (click)="dismiss()">
      {{ app.text.modal.cancel }}
    </button>
    <button
      class="btn btn-ok text-center m-1"
      (click)="save()"
      [disabled]="hasRequiredFields()"
    >
      {{ app.text.part.save }}
    </button>
  </div>
</ng-template>
