import { GraphDataComplex, GraphDataSimple } from "./graphs.service.type";
import { AppType } from "../app.type";
export abstract class AbstractGraphData {
  constructor(protected app: AppType) {}

  abstract get data(): GraphDataComplex | GraphDataSimple;

  /** turns a Date object into a string with format "dddd-MM" */
  protected getDateLabel(date: Date): string {
    return new Date(Date.UTC(date.getFullYear(), date.getMonth()))
      .toISOString()
      .slice(0, 7);
  }
}
