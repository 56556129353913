import { Credits } from "../credits";

export class OCPost extends Credits {
  [key: string]: any;
  title: string = "";
  message: string = "";
  tag: string = "general";
  counterLikes: number = 0;
  counterComment: number = 0;
  type?: string = "ocPost";
}
