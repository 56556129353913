import { Component, ViewChild, OnInit } from "@angular/core";
import { getApp } from "../app";
import { Type } from "../../../../shared/components";
import { Responsibles } from "../../../../shared/models/responsibles";
import { Customer } from "../../../../shared/types/customers";

@Component({
  selector: "app-import",
  templateUrl: "./import.component.html",
  styleUrls: ["./import.component.scss"],
})
export class ImportComponent implements OnInit {
  step: "first" | "second" | "third" | "fourth" | "fifth" | "error" = "first";
  type: Type | null = null;
  types: Type[] | any = [];
  deletingType: Type = "part";

  @ViewChild("fileInput", { static: true }) fileInput: any;

  app = getApp((app) => {
    this.app = app;
  });

  constructor() {}

  ngOnInit(): void {
    // to be uncommented when the import will work for all types
    // this.types =  this.app.types;
    this.types = [
      "part",
      "manufacturer",
      "assembly",
      "thread",
      "dinCodeResponsible",
      "commodityGroupResponsible",
      "alert",
      "message",
      "all-config",
      "vehicleResponsible",
      "productCategory",
      "manufacturerCode",
    ];
    if (this.app.state.isRMAdmin || this.app.auth.isTeam) {
      this.types = ["part", "manufacturer", "assembly"];
    }
    this.app.import.step = "first";

    // empty file details if there was a previous upload
    if (sessionStorage.getItem("clearFiles") === "true") {
      this.fileInput.nativeElement.value = "";
    }
    sessionStorage.removeItem("clearFiles");
    this.app.import.duplicatedItems = [];
  }

  async upload() {
    // this.step = 'second';
    this.app.import.invalidKeys = [];
    let files: FileList = this.fileInput.nativeElement.files;
    this.app.spinner.showSpinner();
    if (files[0].name.split(".").pop() !== "json") {
      await this.app.importXlsx(files);
      await this.app.import.prepareDocs();
    } else {
      await this.app.importJson(files);
      await this.app.import.prepareJsonDocs();
    }
    if (this.app.import.invalidKeys.length > 0) {
      this.app.import.step = "error";
      this.step = "error";
    } else {
      this.app.import.step = "third";
      this.step = "third";
    }
    this.fileInput.nativeElement.value = "";
    this.app.spinner.hideSpinner();
  }

  getLabel(type: any) {
    if (type === "assembly") {
      return this.app.text.import.structure;
    }
    if (type === "productCategory") {
      return this.app.text.productCategory.productCategory;
    }
    if (type === "all-config") {
      return "All configuration data";
    }
    if (type === "manufacturerCode") {
      return this.app.text.import.manufacturerName;
    }
    return this.app.getText(type + "." + type);
  }

  getFieldLabel(type: any, id: any) {
    if (this.app.import.type === "assembly") {
      type = "part";
    }
    if (
      this.app.customers.expectCurrent === Customer.DB &&
      (this.app.import.type === "vehicleResponsible" ||
        this.app.import.type === "impact")
    ) {
      let responsibles = new Responsibles();
      if (Object.keys(responsibles).find((field) => field == id)) {
        return this.app.field.getLabel("responsibles" + "." + id);
      } else {
        return this.app.field.getLabel(this.app.import.type + "." + id);
      }
    } else {
      return this.app.field.getLabel(type + "." + id);
    }
  }

  emptyImportMessages() {
    this.app.import.importMessages = [];
  }

  onClear() {
    this.app.import.docs = [];
    this.app.import.step = "first";
    this.app.import.importMessages = [];
  }
}
