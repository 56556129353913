let CHUNK_MAX_SIZE = 200000; //KB

export function createChunk(docs: any) {
  let chunk = [];
  for (let i = 0; i < docs.length; i++) {
    if (canBeAddedToChunck(CHUNK_MAX_SIZE, chunk, docs[i])) {
      chunk.push(docs[i]);
    } else {
      docs.splice(0, chunk.length);
      return { chunk: chunk, newDocs: docs };
    }
  }
  if (chunk.length === docs.length) {
    docs.splice(0, chunk.length);
  }
  return { chunk: chunk, newDocs: docs };
}

function canBeAddedToChunck(size: number, chunk: any, element: any) {
  let newChunk = Array.from(chunk);
  newChunk.push(element);
  let newChunkSize = new Blob([JSON.stringify(newChunk)]).size;

  if (newChunkSize < size && element != null) {
    return true;
  }
  return false;
}

//sort the documents based on their size for faster saving
export function sortDocsBySize(docs: any): any {
  let startTime = Date.now();
  console.log("sorting started");
  let sortedDocs = docs.sort((a: any, b: any) => {
    return (
      new Blob([JSON.stringify(b)]).size - new Blob([JSON.stringify(a)]).size
    );
  });
  console.log("sorting finished");
  let endTime = Date.now();
  console.log("sorting took: ", startTime - endTime, " millisenconds");
  return sortedDocs;
}
