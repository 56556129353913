import { Component, Input, OnDestroy, OnInit } from "@angular/core";
import { formatDatetime } from "../utils/date.util";
import { getApp } from "../app";
import { Subscription } from "rxjs";
import { Type } from "../../../../shared/components";
import { ViewCredits } from "../../../../shared/models/credits";

@Component({
  selector: "app-credits",
  templateUrl: "./credits.component.html",
  styleUrls: ["./credits.component.scss"],
})
export class CreditsComponent implements OnInit, OnDestroy {
  @Input() type: Type = "thread";
  @Input() inputDoc?: any = {};
  @Input() isCreated?: boolean = false;
  @Input() isEdited?: boolean = false;

  credits = {} as ViewCredits;
  _creditsSubscription: Subscription = new Subscription();
  _creditsSubscriptionForPanel: Subscription = new Subscription();

  app = getApp((app) => {
    this.app = app;
  });

  constructor() {}

  ngOnInit() {
    if (Object.keys(this.inputDoc).length == 0) {
      switch (this.type) {
        case "thread":
          this._creditsSubscription = this.app.thread.creditsSubject.subscribe(
            async (thread) => {
              this.inputDoc = thread;
              this.getCredits();
            }
          );
          break;
        case "post":
          this._creditsSubscriptionForPanel =
            this.app.panel.creditsSubject.subscribe(async (doc) => {
              this.inputDoc = doc;
              this.getCredits();
            });
          break;
        default:
          break;
      }
    } else {
      this.getCredits();
    }
  }

  /** get credits without using buffers and getters */
  getCredits() {
    this.credits.create_time = formatDatetime(this.inputDoc["create_time"]);
    this.credits.user_id = this.inputDoc["user_id"];
    this.credits.update_user = this.inputDoc["update_user"];
    this.credits.update_time = formatDatetime(this.inputDoc["update_time"]);
  }

  ngOnDestroy() {
    this._creditsSubscription.unsubscribe();
    this._creditsSubscriptionForPanel.unsubscribe();
  }
}
