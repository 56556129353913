import { APP_TYPE, AppType } from "../app.type";
import { Inject, Injectable } from "@angular/core";
import { RMSearchServiceType } from "./rm-search.service.type";
import { getRMDocsByType, getTopLevelItems } from "../api.service";
import { RMDoc } from "../../../../shared/models/rm-search";
import { BehaviorSubject } from "rxjs";

export const ASSEMBLY = "assembly";
export const PART = "part";
export const MANUFACTURER = "manufacturer";
export type RMSectionType = typeof ASSEMBLY | typeof PART | typeof MANUFACTURER;

@Injectable()
export class RMSearchService implements RMSearchServiceType {
  filterMode: string = "";
  model: string = "";
  RMDocs: RMDoc[] = [];
  cleanRMDocs: RMDoc[] = [];
  displayResults: boolean = false;
  columns: string[] = [];
  properties: string[] = [];
  selectedType: RMSectionType = ASSEMBLY;
  result: string[] = [];
  clearField: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  constructor(@Inject(APP_TYPE) private app: AppType) {}

  getRMSearchColumns() {
    switch (this.selectedType) {
      case ASSEMBLY:
      case PART:
        this.columns = this.app.list.RM.rmSearchPartColumns;
        break;
      case MANUFACTURER:
        this.columns = this.app.list.RM.rmSearchManufacturerColumns;
        break;
    }
  }

  getFilterLabel(fieldId: string = this.filterMode) {
    switch (fieldId) {
      case this.app.fieldId.part.partNumber:
        return this.app.text.RM.cpn;
      case this.app.fieldId.manufacturer.manufacturerPartNumber:
        return this.app.text.RM.mpn;
      case this.app.fieldId.part.description:
      case this.app.fieldId.manufacturer.manufacturerPartDescription:
        return this.app.text.RM.designation;
      default:
        return this.app.field.getLabel(fieldId);
    }
  }

  setFilterMode(mode: string) {
    this.filterMode = mode;
    this.clearField.next(true);
    this.resetFilterMode();
  }

  resetFilterMode() {
    this.model = "";
    this.displayResults = false;
    this.RMDocs = [];
  }

  async getRMSearchResults() {
    if (this.model === "") {
      return;
    }

    this.app.spinner.showSpinner();
    // Filter data directly in the database using a backend request
    const customer = this.app.customers.expectCurrent;
    if (Object.keys(this.model).includes("RM.searchField")) {
      this.model = Object.values(this.model)[0];
    }
    this.model = this.model.toLowerCase().trim();
    this.RMDocs = await getRMDocsByType(
      customer,
      this.model,
      this.selectedType,
      this.app.RMSearch.filterMode.split(".")[1]
    );

    this.displayResults = true;
    this.app.spinner.hideSpinner();
  }
}
