import {
  paragraphDef,
  TableDef,
  TDocumentDefinitions,
} from "pdfmake/build/pdfmake";
import {
  head,
  ChangeData,
  description,
  getItemNumberDef,
  getMasterDataDef,
  ItemData,
  MasterData,
  styles,
  getImpactsTableDefinition,
  getPostDef,
  PostData,
  omLogo,
  ItemNumbers,
} from "./definitions";
import { getApp } from "../app";
import { clientMapping } from "./client-mapping";
import { PdfTable } from "./table";

import { AdditionalField } from "./definitions/posts";
import { AttachmentData, getAttachmentDef } from "./definitions/attachments";
import { AppType } from "../app.type";
import { sbbLogo } from "./definitions/sbbLogo";
import { getTrainsTableDefinition } from "./definitions/trainseries";
import { Impact } from "../../../../shared/models/impact";
import { Post } from "../../../../shared/models/post";
import { logo } from "./definitions/pcnGenLogo";
import {
  pcnMapping,
  pcnMappingPdf,
  itemMapping,
} from "../pcn-generator/pcn-mapping";
import { model2Doc } from "../api.service";

import { Customer } from "../../../../shared/types/customers";
import { TASK } from "../post/post.service.type";

function toDateString(num: number) {
  const app = getApp();
  const offset = new Date().getTimezoneOffset();
  let date = new Date(num);
  date = new Date(date.getTime() - offset * 60 * 1000);

  if (date == null) {
    return "";
  }
  try {
    return date
      .toISOString()
      .split(".")[0]
      .replace("T", " " + app.text.credits.at + " ");
  } catch (err) {
    return null;
  }
}

export class Definition {
  private readonly defaults: TDocumentDefinitions = {
    pageSize: "A4",
    pageOrientation: "portrait",
    styles: styles,
    content: [],
  };

  private definition: TDocumentDefinitions;

  static mapClientFields(): ChangeData & MasterData & ItemData {
    const mappedData = {
      ...new ChangeData(),
      ...new MasterData(),
      ...new ItemData(),
    };

    const app = getApp();

    for (const key in clientMapping) {
      if (clientMapping[key] != null) {
        const omcKey = clientMapping[key];
        let value = "";
        switch (omcKey) {
          case "changeClasses":
          case "dinText":
            value = app.field.getFieldValueAsText(
              "thread." + omcKey,
              app.thread.thread["thread." + omcKey]
            );
            break;
          case "dinCode":
            let dinCodeValue: string = "";

            value =
              dinCodeValue !== ""
                ? dinCodeValue
                : app.thread.thread["thread." + omcKey];
            break;
          default:
            value = app.thread.thread["thread." + omcKey];
            break;
        }
        mappedData[key] = value;
      }
    }
    return mappedData;
  }

  static mapClientFieldsPcn(): ChangeData & MasterData & ItemNumbers {
    const app = getApp();
    const mappedDataPcn = {
      ...new ChangeData(),
      ...new MasterData(),
      ...new ItemNumbers(),
    };

    for (const key in pcnMappingPdf) {
      if (pcnMappingPdf[key] != null) {
        const omcKey = pcnMapping[key];
        let value: string | string[] = "";
        if (omcKey === "itemNumbers") {
          const items: any[] = [];
          const itemNumbers =
            app.pcnGenerator.pcnModel["pcnGenerator.itemNumbers"];

          itemNumbers.forEach((item: any) => {
            const itemModel: any = model2Doc("pcnGenerator", item);
            let newModel = {} as ItemData;
            Object.keys(itemMapping).forEach((itemKey) => {
              if (itemMapping[itemKey] != null) {
                const pcnKey = itemMapping[itemKey];
                newModel[itemKey] = itemModel[pcnKey];
              }
            });
            items.push(newModel);
          });
          value = items;
        } else {
          value = app.pcnGenerator.pcnModel["pcnGenerator." + omcKey];
        }
        mappedDataPcn[key] = value;
      }
    }
    return mappedDataPcn;
  }

  constructor(args: Partial<TDocumentDefinitions>, private app: AppType) {
    this.definition = { ...this.defaults, ...args };
  }

  get(): TDocumentDefinitions {
    return this.definition;
  }

  addVdmaHeadCluester() {
    this.definition.content.push(head());
  }

  addPcnGenLogo() {
    this.definition.content.push(logo());
  }

  addVdmaMasterCluster(data: MasterData, type: "omc" | "pcngen") {
    getMasterDataDef(data, type).forEach((definition) => {
      this.definition.content.push(definition);
    });
  }

  addVdmaItemCluster(data: ItemData) {
    this.definition.content.push(getItemNumberDef(data));
  }

  addVdmaItemClusterPcn(itemNumbers: ItemData[]) {
    itemNumbers.forEach((item) => {
      this.definition.content.push(getItemNumberDef(item));
    });
  }

  addVdmaChangeCluster(data: ChangeData, type: "omc" | "pcngen") {
    this.definition.content.push(description(data, type));
  }

  addPostSection() {
    let solutions: TableDef[] = [];
    let comments: TableDef[] = [];
    let tasks: TableDef[] = [];

    if (this.app.post.solutions != null) {
      solutions = this.app.post.solutions
        .filter((post) => post.resolveClass)
        .sort((a: Post, b: Post) => this.sortPosts(a, b))
        .map((post) => this.getPostDefinition(post));
    }
    if (this.app.post.comments != null) {
      comments = this.app.post.comments
        .filter((post) => !post.resolveClass)
        .sort((a: Post, b: Post) => this.sortPosts(a, b))
        .map((post) => this.getPostDefinition(post));
    }
    if (this.app.post.tasks != null) {
      tasks = this.app.post.tasks
        .filter((post) => post.taskResponsible)
        .sort((a: Post, b: Post) => this.sortPosts(a, b))
        .map((post) => this.getPostDefinition(post));
    }

    this.addSectionTitle(
      `${this.app.text.pdf.comments}/${this.app.text.pdf.solutions}`
    );
    this.definition.content.push({
      table: {
        widths: ["*", "*"],
        body: [
          [
            this.app.text.pdf.comments.toUpperCase(),
            this.app.text.pdf.solutions.toUpperCase(),
          ],
          [[...comments], [...solutions]],
        ],
      },
      layout: "noBorders",
    });
    this.addSectionTitle(`${this.app.text.pdf.tasks}`);
    this.definition.content.push({
      table: {
        widths: ["*", "*"],
        body: [[this.app.text.pdf.tasks.toUpperCase()], [[...tasks]]],
      },
      layout: "noBorders",
    });
    this.addParagraph({ text: "" });
  }

  addImpactSection(impacts: Impact[]) {
    if (impacts.length < 1) {
      return;
    }
    this.addSectionTitle(this.app.text.impact.impacts);
    this.definition.content.push(getImpactsTableDefinition(impacts));
    this.addParagraph({ text: "", pageBreak: "after" });
  }

  addTrainSection(impactedTrains: Impact[]) {
    if (impactedTrains.length < 1) {
      return;
    }
    this.addSectionTitle(this.app.text.train.trainseries);
    this.definition.content.push(getTrainsTableDefinition(impactedTrains));
    this.addParagraph({ text: "", pageBreak: "after" });
  }

  addLayerSection(fields: string[], section?: string) {
    if (fields.length < 1) {
      return;
    }
    const app = getApp();
    if (section != null) {
      this.addSectionTitle(app.getText(section));
    } else {
      this.addSectionTitle(app.text.thread.layer);
    }
    const table = this.addTable({ layout: "lightHorizontalLines" });
    fields.forEach((field) => {
      table.addRow([
        app.field.getLabel(field),
        app.field.getFieldValueAsText(field, this.app.thread.thread[field]),
      ]);
    });
  }

  addAttachmentsSection() {
    let attachmentsName: string[] = [];
    let attachmentsInfo: AttachmentData[] = [];
    let attachments: TableDef[] = [];

    if (
      this.app.thread.thread["thread._attachments"] != null &&
      Object.keys(this.app.thread.thread["thread._attachments"]).length > 0
    ) {
      attachmentsName = Object.keys(
        this.app.thread.thread["thread._attachments"]
      );
    } else {
      return;
    }

    attachmentsName.forEach((name) => {
      let tags: string[] = [];
      tags.push(this.app.thread.thread["thread.attachmentHistory"][name].tags);
      attachmentsInfo.push({ name: name, tags: tags });
    });

    attachments = attachmentsInfo.map((attachment) =>
      this.getAttachmentsDefinition(attachment)
    );

    this.addSectionTitle(`${this.app.text.pdf.attachments}`);
    this.definition.content.push({
      table: {
        widths: ["*"],
        body: [[attachments]],
      },
      layout: "noBorders",
    });
  }

  addSignatureFields() {
    this.definition.content.push({
      margin: [0, 100, 0, 0],
      text: this.app.text.pdf.sectionSignature,
      bold: true,
      fontSize: 15,
    });

    this.definition.content.push({
      margin: [0, 20, 0, 0],
      columnGap: 40,
      columns: [
        [
          {
            table: {
              widths: ["*"],
              body: [["\n\n\n\n"]],
            },
          },
          {
            margin: [0, 10, 0, 0],
            text: this.app.text.pdf.signatureA,
            italics: true,
          },
        ],
        [
          {
            table: {
              widths: ["*"],
              body: [["\n\n\n\n"]],
            },
          },
          {
            text: this.app.text.pdf.signatureB,
            italics: true,
            margin: [0, 10, 0, 0],
          },
        ],
      ],
    });
  }

  private getPostDefinition(post: Post) {
    return getPostDef(this.getPostData(post));
  }

  private getPostData(post: Post) {
    let data = {} as PostData;
    if (post.typeOfPost === TASK) {
      data.taskResponsible = post.taskResponsible;
      data.taskDueDate = post.taskDueDate;
      data.taskDescription = post.taskDescription;
      data.taskNote = post.taskNote;
      data.taskCompleted = post.taskCompleted;
    } else {
      data.title = post.title_comment;
      data.content = post.content;
    }
    data.additionalFields = this.getAdditionalPostFields(post);
    data.credits = this.getPostCredits(post);
    return data;
  }

  private sortPosts(a: Post, b: Post): number {
    if (a.create_time != null && b.create_time != null) {
      return a.create_time - b.create_time;
    } else if (a.create_time != null) {
      return a.create_time;
    } else if (b.create_time != null) {
      return b.create_time;
    }
    return 0;
  }

  private getAdditionalPostFields(post: Post): AdditionalField[] {
    const keys: (keyof Post)[] = [];
    let resolveClass = false;
    if ("resolveClass" in post && post.resolveClass) {
      keys.push("resolveClass");
      if (this.app.customers.expectCurrent !== Customer.MTU) {
        keys.push("acceptedSolution");
      }
      resolveClass = true;
    }
    switch (post.resolveClass) {
      case "Alternative":
        keys.push("newCrtNumber", "newContact");
        break;
      case "Alternativer Lieferant":
        keys.push("altCrtNumber", "altCrtContact");
        break;
      case "LTB-Last Time Buy":
        keys.push(
          "noOfItems",
          "pricePerItem",
          "costForStorage",
          "yearsOfStorage",
          "totalAmount"
        );
        break;
    }
    if (resolveClass && "resolveCost" in post) {
      keys.push("resolveCost");
      keys.push("resolveCostType");
    }
    return keys.map(
      (key) =>
        <AdditionalField>{
          name: getApp().field.getLabel("post." + key),
          value:
            key === "resolveClass"
              ? getApp().field.getFieldValueAsText(
                  getApp().fieldId.post.resolveClass,
                  post[key]
                )
              : post[key],
        }
    );
  }

  private getPostCredits(post: Post) {
    const credits = { create: "", update: "" };
    const app = getApp();

    try {
      if ("user_id" in post && post.create_time != null) {
        credits.create = `${app.field.getLabel(app.fieldId.post.user_id)} ${
          post.user_id
        } ${this.app.text.pdf.creditsOnDay} ${toDateString(post.create_time)}`;
      }
    } catch (err) {
      console.log(err);
    }

    try {
      if (
        post.update_user != null &&
        post.update_time != null &&
        post.update_time !== post.create_time
      ) {
        credits.update = `${app.field.getLabel(app.fieldId.post.update_user)} ${
          post.update_user
        } ${this.app.text.pdf.creditsOnDay} ${toDateString(post.update_time)}`;
      }
    } catch (err) {
      console.log(err);
    }

    return credits;
  }

  private getAttachmentsDefinition(attachment: AttachmentData) {
    return getAttachmentDef(this.getAttachmentData(attachment), this.app);
  }

  private getAttachmentData(attachment: AttachmentData) {
    let data = {} as AttachmentData;
    data.name = attachment.name;
    data.tags = attachment.tags;
    return data;
  }

  private addSectionTitle(title: string) {
    const header = this.addTable({
      style: "sectionTitle",
      table: { widths: ["*"], body: [] },
    });
    header.addRow([{ text: title, border: [false, true, false, true] }]);
  }

  addVdmaStandardClientDef(
    data: ChangeData & MasterData & ItemData,
    type: "omc" | "pcngen",
    pdfType?: string
  ) {
    // TODO: find a better way to display the right image for each customer
    this.addVdmaMasterCluster(data, type);
    this.addVdmaItemCluster(data);
    this.addVdmaChangeCluster(data, type);
    if (pdfType === "allData") {
      //add the pageBreak only if we export more than 1 page, for vdma only first page is exported
      this.addParagraph({ text: "", pageBreak: "after" });
    }
    this.addOmpLogoFooter();
  }

  addVdmaStandardClientDefPcn(
    data: ChangeData & MasterData & ItemNumbers,
    type: "omc" | "pcngen"
  ) {
    this.addPcnGenLogo();
    this.addVdmaHeadCluester();
    this.addVdmaMasterCluster(data, type);
    this.addVdmaItemClusterPcn(data.ItemNumbers);
    this.addVdmaChangeCluster(data, type);
  }

  addParagraph(paragraph: paragraphDef): Definition {
    this.definition.content.push(paragraph);
    return this;
  }

  private addTable(table: Partial<TableDef>): PdfTable {
    const pdfTable = new PdfTable(table);
    this.definition.content.push(pdfTable.get());
    return pdfTable;
  }

  private addSbbLogoHeader() {
    this.definition.header = <any>{
      columns: [
        {
          width: "*",
          text: "",
        },
        {
          width: "*",
          image: sbbLogo,
          margin: [0, 20, 20, 0],
          absolutePosition: { x: 700, y: 20 },
          fit: [100, 100],
        },
      ],
    };
  }

  private addOmpLogoFooter() {
    this.definition.footer = <any>{
      columns: [
        {
          width: "*",
          text: "Printed from AMSYS LCM Client",
          fontSize: 8,
          margin: [0, 10, 0, 0],
          alignment: "center",
          pageBreak: "after",
        },
        {
          width: [25],
          image: omLogo,
          aligment: "center",
          margin: [0, 10, 0, 0],
          absolutePosition: { x: 400, y: -20 },
        },
      ],
    };
  }
}
