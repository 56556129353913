<app-modal
  #deleteThreadModal
  [modalMessage]="app.text.thread.deleteQuestion"
  [modalTitle]="app.text.thread.modalTitle"
  (ok)="app.thread.deleteThread()"
>
</app-modal>
<app-modal
  #unFavModal
  [modalMessage]="app.text.thread.unFavCaseMessage"
  [modalTitle]="app.text.thread.modalTitle"
  (ok)="app.thread.setFav()"
>
</app-modal>
<app-modal #noIdModal [modalMessage]="app.text.thread.idRequired"></app-modal>
<app-history
  #historyModal
  [historyModalTitle]="app.text.history.threadHistory"
></app-history>
<app-override-mpnFields #overrideMpnFieldsModal></app-override-mpnFields>

<div class="row">
  <!-- top side content -->
  <div *ngIf="!app.thread.isNew" class="col-12 no-padding">
    <div class="top-section" [ngClass]="app.thread.changeHeadlineColor()">
      <div *ngIf="app.unlockedId == null">
        <div class="d-flex control-bar">
          <button
            class="btn btn-control me-auto p-2"
            (click)="navigateBack()"
            [title]="app.text.thread.close"
          >
            <i class="far fa-arrow-left"></i> &nbsp;{{
              app.text.app.backToList
            }}
          </button>
          <span *ngIf="app.thread.ids.length > 0">
            <app-next
              class="pull-left"
              [current]="app.thread.thread['thread._id']"
              [array]="app.thread.ids"
              (navigate)="app.routing.navigateThread($event)"
            ></app-next>
          </span>
        </div>
      </div>
      <div class="px-2 mx-2 mt-2 heading">
        <span [ngClass]="app.thread.changeHeadlineColor()">
          {{ app.thread.thread["thread.title"] }}
        </span>
      </div>
      <div class="thread-controls mb-2 mx-2 px-2">
        <div class="col-md-3">
          <app-credits
            class="ml-1 credits"
            *ngIf="!app.thread.isNew"
            [id]="app.thread.id"
            type="thread"
          ></app-credits>
        </div>
        <div class="col-md-9">
          <div class="controls">
            <span *ngIf="app.unlockedId == null && !app.thread.isNew">
              <button
                *ngIf="app.customer !== 'coop'"
                class="btn btn-export btn-sm btn-clean"
                type="button"
                data-bs-toggle="dropdown"
              >
                <i class="fas fa-file-export"></i>&nbsp;
                {{ app.text.thread.exportAs }}
              </button>
              <div class="dropdown-menu m-0 p-1">
                <button
                  *ngIf="app.customer !== 'coop'"
                  class="btn dropdown-item m-0 p-1"
                  href="#"
                  (click)="app.thread.exportZip()"
                >
                  <i class="fas fa-file-archive"></i>&nbsp;&nbsp; .ZIP
                </button>
                <button
                  *ngIf="app.customer !== 'coop'"
                  class="btn dropdown-item m-0 p-1"
                  href="#"
                  (click)="downloadPDF()"
                >
                  <i class="fas fa-file-pdf"></i>&nbsp;&nbsp;
                  {{ app.text.pdf.VDMA }}
                </button>
                <button
                  *ngIf="app.customer !== 'coop'"
                  class="btn dropdown-item m-0 p-1"
                  href="#"
                  (click)="downloadPDF('allData')"
                >
                  <i class="fas fa-file-pdf"></i>&nbsp;&nbsp;
                  {{ app.text.pdf.pdfAllData }}
                </button>
              </div>
              <button
                *ngIf="app.unlockedId == null"
                class="btn btn-sm btn-clean"
                (click)="historyModal.open()"
                [ngClass]="app.thread.changeHeadlineColor()"
              >
                <i class="fas fa-archive"></i>&nbsp;
                {{ app.getText("thread.history") }}
              </button>
              <button
                *ngIf="app.thread.isFavorite"
                class="btn btn-sm btn-clean"
                (click)="$event.stopPropagation(); unFavModal.open()"
                [title]="app.text.thread.unfav"
                [ngClass]="app.thread.changeHeadlineColor()"
              >
                <i class="fas fa-star"></i>&nbsp;
                {{ app.text.thread.removeFav }}
              </button>

              <button
                *ngIf="!app.thread.isFavorite"
                class="btn btn-sm btn-clean"
                (click)="
                  $event.stopPropagation();
                  app.thread.setFav(app.thread.id, true)
                "
                [title]="app.text.thread.fav"
                [ngClass]="app.thread.changeHeadlineColor()"
              >
                <i class="far fa-star"></i>&nbsp;
                {{ app.text.thread.addToFav }}
              </button>
              <button
                *ngIf="app.thread.hasPermissionToEdit"
                class="btn btn-sm btn-clean"
                (click)="deleteThreadModal.open()"
                [title]="app.text.thread.deleteLabel"
                [ngClass]="app.thread.changeHeadlineColor()"
              >
                <i class="fa fa-trash"></i>&nbsp;
                {{ app.text.thread.deleteCase }}
              </button>

              <app-sync
                [id]="app.thread.thread['thread._id']"
                type="thread"
              ></app-sync>
              <button
                *ngIf="app.permission.thread.hasPermissionToCreateThread"
                class="btn btn-sm btn-clean"
                (click)="app.thread.duplicateCase(app.thread.thread)"
                [title]="app.text.thread.duplicateCase"
                [ngClass]="app.thread.changeHeadlineColor()"
              >
                <i class="fa fa-copy"></i>&nbsp;
                {{ app.text.thread.duplicateCase }}
              </button>
            </span>
          </div>
        </div>
      </div>
    </div>
  </div>

  <!-- top side new-thread -->
  <div *ngIf="app.thread.isNew" class="top-section col-12 no-padding">
    <div class="control-bar">
      <button
        class="btn btn-control me-auto p-2"
        (click)="navigateBack()"
        [title]="app.text.thread.close"
      >
        <i class="far fa-arrow-left"></i> &nbsp;{{ app.text.app.back }}
      </button>
    </div>

    <!-- subtitle info -->
    <div class="case-detail subtitle col-12 my-1 py-1 ps-3">
      <div
        *ngFor="let field of app.list.thread.subTitleSection"
        class="subtitle--info"
      >
        <span>{{ app.field.getLabel(field) }}:&nbsp;</span>
        {{ app.thread.thread[field] }}
      </div>
    </div>
  </div>

  <!-- left side navigation -->
  <div
    class="col-sm-12 col-md-12"
    [ngClass]="!app.thread.isNew ? 'col-xl-5' : 'col-xl-8'"
  >
    <div *ngIf="!app.thread.isNew" class="d-flex justify-content-between my-3">
      <!-- case image -->
      <div
        class="card case-image"
        *ngIf="!app.thread.isNew"
        [ngClass]="{ smallBox: app.thread.externalImage == '' }"
      >
        <div class="card-header d-flex justify-content-between">
          <span class="pt-1">
            {{ app.text.thread.caseImage }}
          </span>
          <input
            #fileInput
            type="file"
            accept="image/*"
            style="display: none"
            (change)="onUpload()"
          />
          <button
            *ngIf="app.thread.hasPermissionToEdit"
            class="btn m-0 p-0 pt-1"
            (click)="fileInput.click()"
            [title]="app.text.thread.uploadImage"
            [disabled]="app.unlockedId != null"
          >
            <i class="fal fa-file-alt icon-orange"></i>
          </button>
        </div>
        <app-left-nav
          *ngIf="!app.thread.isNew"
          [showImageControlBtn]="app.thread.hasPermissionToDeleteImage"
          [imageSource]="app.thread.externalImage"
          [showBoxes]="false"
          [docType]="'thread'"
          [file]="app.thread.getImage()"
        >
        </app-left-nav>
      </div>

      <!-- case details -->
      <div class="card case-detail" *ngIf="!app.thread.isNew">
        <div
          class="card-header d-flex justify-content-between border-bottom-0 pe-0"
        >
          <!-- <div class="row"> -->
          <span class="pt-1">
            {{ app.text.thread.caseDetails }}
          </span>
          <div *ngIf="app.thread.threadReady" class="ms-auto">
            <app-case-controls></app-case-controls>
          </div>
          <!-- </div> -->
        </div>
        <div class="border-top pt-2">
          <!-- <div class="subtitle col-12 my-1 py-1">
            <div
              *ngFor="let field of app.list.thread.subTitleSection"
              class="subtitle--info"
            >
              <span>{{ app.field.getLabel(field) }}:&nbsp;</span>
              {{ app.thread.thread[field] }}
            </div>
          </div> -->
          <div class="px-3">
            <span class="details">{{ app.text.thread.caseNumber }}&nbsp;</span>
            <span *ngIf="app.thread.thread['thread.omfNumber']">
              {{ app.thread.thread["thread.omfNumber"] }}
            </span>
          </div>
          <div *ngIf="app.customer !== 'db'" class="px-3">
            <span class="details">{{ app.text.thread.cpn }}&nbsp;</span>
            <span *ngIf="app.thread.thread['thread.artNumber']">
              {{ app.thread.thread["thread.artNumber"] }}
            </span>
          </div>
          <div *ngIf="app.customer !== 'db'" class="px-3">
            <span class="details">{{ app.text.thread.mpn }}&nbsp;</span>
            <span *ngIf="app.thread.thread['thread.crtNumber']">
              {{ app.thread.thread["thread.crtNumber"] }}
            </span>
          </div>
          <div class="px-3">
            <span class="details"
              >{{ app.field.getLabel("thread.creator") }}:&nbsp;</span
            >
            <span *ngIf="app.thread.thread['thread.creator']">
              {{ app.thread.thread["thread.creator"] }}
            </span>
          </div>
          <div *ngIf="app.customer === 'maquetcardio'" class="px-3">
            <span class="details"
              >{{ app.field.getLabel("thread.dueDate") }}&nbsp;</span
            >
            <span *ngIf="app.thread.thread['thread.dueDate']">
              {{ app.thread.thread["thread.dueDate"] }}
            </span>
          </div>
        </div>
      </div>
    </div>

    <!-- description -->
    <div
      *ngIf="!app.thread.isNew"
      class="card my-3"
      [ngClass]="{ editModeDescr: app.thread.isEditMode() }"
    >
      <div
        class="card-header"
        [ngClass]="{
          'bottom-line':
            app.thread.thread['thread.descr'] !== '' && app.unlockedId != null
        }"
      >
        <span>{{ app.text.thread.description }}</span>
      </div>
      <div
        [ngClass]="{
          hideBox:
            app.thread.thread['thread.descr'] === '' && app.unlockedId == null
        }"
      >
        <app-box
          *ngIf="app.unlockedId != null"
          [box]="app.listId.thread.description"
          [model]="app.thread.thread['thread.descr']"
          [unlock]="app.thread.isNew ? null : app.thread.id"
          [showHeader]="false"
        ></app-box>
        <div
          *ngIf="app.unlockedId == null"
          class="fieldValue text-md-left px-4 py-2"
          (click)="$event.stopPropagation()"
        >
          <app-limit
            [textInput]="app.thread.thread['thread.descr']"
            limit="400"
          ></app-limit>
        </div>
      </div>
    </div>

    <div
      class="col-md-12 text-center font-weight-bold mt-3"
      *ngIf="!app.thread.threadReady"
    >
      {{ app.text.app.loading }}
    </div>

    <!-- 1. left-nav + box -->
    <div *ngIf="!app.thread.isEditMode() && app.thread.threadReady">
      <app-left-nav
        [showImageControlBtn]="app.thread.hasPermissionToDeleteImage"
        [listBoxes]="boxesLeft"
        [showImage]="false"
      >
      </app-left-nav>
    </div>
    <!-- 2. left-nav + box -->
    <div
      *ngIf="
        app.thread.isEditMode() && !app.thread.isNew && app.thread.threadReady
      "
    >
      <app-left-nav
        [showImageControlBtn]="app.thread.hasPermissionToDeleteImage"
        [listBoxes]="app.list.thread.boxesLeftEditMode"
        [showImage]="false"
      >
      </app-left-nav>
    </div>
    <!-- 3. left-nav + box -->
    <div
      *ngIf="
        app.thread.isEditMode() && app.thread.isNew && app.thread.threadReady
      "
    >
      <app-left-nav
        [listBoxes]="app.thread.boxesLeftCreateCase"
        [showImage]="false"
      >
      </app-left-nav>
    </div>

    <div *ngIf="app.thread.threadReady" class="center-content mb-3">
      <div
        class="box-content"
        [ngClass]="{ editMode: app.thread.isEditMode() }"
      >
        <app-box
          [box]="app.leftNav.selectedBox"
          [model]="app.thread.thread"
          [unlock]="app.thread.isNew ? null : app.thread.id"
          [showHeader]="false"
        ></app-box>
      </div>
    </div>
  </div>

  <!-- right side content -->
  <div class="col-sm-12 col-md-12 col-xl-7">
    <app-stepper
      *ngIf="!app.thread.isNew"
      [field]="app.fieldId.thread.omfStatus"
      (select)="
        app.thread.thread[app.fieldId.thread.omfStatus] = $event;
        app.thread.save(app.thread.thread)
      "
    ></app-stepper>

    <!-- do not show comments on new case and case edit mode = true -->
    <div class="right-content" *ngIf="!app.thread.isNew">
      <div>
        <div *ngFor="let box of app.list.thread.boxesRight">
          <app-box
            [box]="box"
            [model]="app.model"
            [unlock]="app.thread.id"
            class="thread-comments"
          ></app-box>
        </div>
      </div>
      <div class="row">
        <div class="col-md-12 p-0">
          <div>
            <div *ngFor="let box of app.list.thread.boxesBottom">
              <app-box
                [box]="box"
                [model]="app.model"
                [unlock]="app.thread.id"
              ></app-box>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
