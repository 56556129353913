export const manufacturerMapping: { [key: string]: string | null } = {
  partNumber: "artNumber",
  manufacturerPartNumber: "crtNumber",
  manufacturerPartDescription: "omfShortDescr",
  name: "creator",
  ltbDate: "itemEOS",
  estimatedEOLDate: "itemEOP",
  taxonomy: "identificationMethod",
  startOfProduction: "itemSOP",
  productEOSR: "itemEOSR",
  purchasePartNumber: "drawNumber",
  designType: "designType",
  eolLink: "itemSubData",
  valid: "valid",
  image: "externalImageSource",
  instructions: "instructions",
  mtbf: "mtbf",
};

export const partMapping: { [key: string]: string | null } = {
  partNumber: "artNumber",
  purchasePartNumber: "drawNumber",
  productCategory: "productCategory",
  sapStatus: "sapStatus",
  readilyAvailableStock: "readilyAvailableStock",
  actualStock: "actualStock",
  globalStock: "globalStock",
  safetyStock: "safetyStock",
  totalLifeCycleUsage: "totalLifeCycleUsage",
  totalForecast2Years: "totalForecast2Years",
  forecastEOLStock: "forecastEOLStock",
  description: "omfShortDescr",
};

export const alertMapping: { [key: string]: string | null } = {
  partNumber: "artNumber",
  manufacturerPartNumber: "crtNumber",
  manufacturerPartDescriptionRaw: "omfShortDescr",
  name: "creator",
  ltbDate: "itemEOS",
  estimatedEOLDate: "itemEOP",
  startOfProduction: "itemSOP",
  productEOSR: "itemEOSR",
  purchasePartNumber: "drawNumber",
  typeOfChange: "changeClasses",
  effectiveDate: "itemEOP",
  issueDate: "oDate",
  image: "externalImageSource",
  pcnID: "pcnID",
  pcnSource: "pcnSource",
};

export const seMatchMapping: { [key: string]: string | null } = {
  PartNumber: "crtNumber",
  Manufacturer: "creator",
  Description: "omfShortDescr",
  Datasheet: "itemSubData",
};

export const z2DataMatchMapping: { [key: string]: string | null } = {
  MPN: "crtNumber",
  Manufacturer: "creator",
  Description: "omfShortDescr",
  Datasheet: "itemSubData",
};
