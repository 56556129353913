import { Component, Input, Output } from "@angular/core";
import { getApp } from "../app";
import { Subject } from "rxjs";

@Component({
  selector: "app-next",
  templateUrl: "./next.component.html",
  styleUrls: ["./next.component.scss"]
})
export class NextComponent {
  app = getApp(app => {
    this.app = app;
  });
  @Input() current: string = "";
  @Input() array: string[] = [];
  @Output() navigate = new Subject<string>();

  constructor() {}
}
