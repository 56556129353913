<app-modal
  #deleteImpactModal
  [modalMessage]="app.text.impact.deleteMessage"
  [modalTitle]="app.text.impact.modalTitle"
  (ok)="deleteImpact(doc)"
></app-modal>
<div>
  <div [ngSwitch]="field">
    <div *ngSwitchCase="app.fieldId.change.omfStatus" class="cell">
      <b>{{ app.field.getValueAsText(field, doc) }}</b>
    </div>
    <div *ngSwitchCase="app.fieldId.thread.omfNumber" class="cell">
      <b>{{ app.field.getValue(field, doc) }}</b>
    </div>
    <div *ngSwitchCase="app.fieldId.history.docType" class="cell">
      {{ app.getTypeText(app.field.getValue(field, doc)) }}
    </div>
    <div *ngSwitchCase="app.fieldId.history.fieldId" class="cell">
      {{ app.field.getLabel(app.field.getValue(field, doc)) }}
    </div>
    <div *ngSwitchCase="app.fieldId.thread.vehicles" class="cell">
      {{ app.thread.getAffectedItems(doc) }}
    </div>
    <!-- add sap data functionality here -->
    <div *ngSwitchCase="app.fieldId.thread.items" class="cell">
      Sap Numbers here
    </div>
    <div *ngSwitchCase="app.fieldId.history.newValue">
      <app-field
        [field]="historyFieldId"
        [model]="historyModel"
        [disabled]="true"
        [showLabel]="false"
      ></app-field>
    </div>
    <div
      *ngSwitchCase="app.fieldId.impact.actions"
      (click)="$event.stopPropagation()"
      class="actions"
    >
      <i class="fa fa-trash" (click)="deleteImpactModal.open()"></i>
    </div>
    <div
      *ngSwitchCase="app.fieldId.change.change_type"
      style="text-align: center; width: 100%"
    >
      <div [ngSwitch]="app.field.getValue(field, doc)">
        <span *ngSwitchCase="'Kommentar'"><i class="fa fa-comment"></i></span>
        <span *ngSwitchCase="'Loesung'"><i class="fa fa-lightbulb-o"></i></span>
        <span *ngSwitchCase="'Fall'"><i class="fa fa-file"></i></span>
        <span *ngSwitchDefault>{{ app.field.getValue(field, doc) }}</span>
      </div>
    </div>
    <div class="due-date" *ngSwitchCase="app.fieldId.thread.dueDate">
      <div [ngStyle]="getDueDateStyle(field, doc)">
        {{ app.field.getValue(field, doc) }}
      </div>
    </div>
    <div *ngSwitchDefault class="cell">
      <span [ngSwitch]="app.field.getType(field)">
        <span *ngSwitchCase="'checkbox'">
          <i
            *ngIf="app.field.getValue(field, doc) === true"
            class="far fa-check-square"
          ></i>
          <i
            *ngIf="app.field.getValue(field, doc) !== true"
            class="far fa-square"
          ></i>
        </span>
        <span *ngSwitchCase="'timestamp'" style="white-space: nowrap">
          {{ app.field.getValue(field, doc) | date: "yyyy-MM-dd HH:mm:ss" }}
        </span>
        <span *ngSwitchCase="'options'" style="white-space: nowrap">
          <span *ngIf="app.field.isMultiple(field)">
            <span *ngFor="let option of app.field.getValue(field, doc)">
              {{ app.field.getOptionText(field, option) }}
            </span>
          </span>
          <span
            *ngIf="!app.field.isMultiple(field)"
            [ngClass]="{
              changeColor: app.field.getValue(field, doc) === '120'
            }"
          >
            {{ app.field.getOptionText(field, app.field.getValue(field, doc)) }}
          </span>
        </span>
        <span *ngSwitchCase="'radio'" style="white-space: nowrap">
          {{ app.field.getOptionText(field, app.field.getValue(field, doc)) }}
        </span>
        <span *ngSwitchCase="'data'" style="white-space: nowrap">
          {{ app.field.getValue(field, doc) | json }}
        </span>

        <span class="cell" *ngSwitchDefault>{{
          app.field.getValue(field, doc)
        }}</span>
      </span>
    </div>
  </div>
</div>
