import { Component, Input, Output } from "@angular/core";
import { Subject } from "rxjs";

@Component({
  selector: "app-frame",
  templateUrl: "./frame.component.html",
  styleUrls: ["./frame.component.scss"],
})
export class FrameComponent {
  @Input() title: string = "";
  @Input() showContent = true;
  @Output() showsContent = new Subject();
  @Input() showHeader: boolean = true;
  @Input() changeHeader: boolean = false;
  @Input() filterBox: boolean = false;
  @Input() noteBox: boolean = false;
  @Input() fieldTableBox: boolean = false;

  constructor() {}

  // toggleContent() {
  //   if (this.showContent) {
  //     this.showContent = false;
  //   } else {
  //     this.showContent = true;
  //   }
  //   this.showsContent.next(this.showContent);
  // }
}
