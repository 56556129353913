import { ActivatedRoute } from "@angular/router";
import { Component, Input, OnInit, Output } from "@angular/core";
import { getApp } from "../app";
import { Subject } from "rxjs";
import { Impact } from "../../../../shared/models/impact";
import { doc2Model } from "../api.service";
import { Customer } from "../../../../shared/types/customers";

@Component({
  selector: "app-impact",
  templateUrl: "./impact.component.html",
  styleUrls: ["./impact.component.scss"],
})
export class ImpactComponent implements OnInit {
  @Input() showContent = true;
  @Output() showsContent = new Subject();
  impactId = "";

  app = getApp((app) => {
    this.app = app;
  });

  constructor(private route: ActivatedRoute) {}
  async ngOnInit() {
    this.route.params.subscribe(async (params) => {
      this.impactId = params.impact; //params.impact used when navigate to an impact
      if (params.impact !== undefined && params.impact !== null) {
        this.app.impact.id = params.impact;
      }
      if (this.impactId) {
        await this.app.impact.getImpactById(this.impactId);
      } else {
        const impactModel: any = doc2Model("impact", new Impact());
        this.app.impact.currentImpact = impactModel;
      }
      if (
        this.app.customers.expectCurrent === Customer.DB &&
        sessionStorage.getItem("viewRefresh")
      ) {
        //get the thread when page is refreshed in order to display the omfStatus on impacts page
        await this.app.thread.getThread(params.threadId);
      }
    });
    //get list of users to be displayed in the vehicles responsible dropdowns
    this.app.users.getUsersWithRoles();
    await this.app.vehicleResponsible.getVehicleDocuments();
  }

  toggleContent() {
    if (this.showContent) {
      this.showContent = false;
    } else {
      this.showContent = true;
    }
    this.showsContent.next(this.showContent);
  }

  discardChanges() {
    this.app.impact.id = this.app.impact.id;
    this.app.unlockedId = null;
    if (this.app.impact.isNew) {
      this.app.impact.id = null;
    }
    // this.app.routing.navigateThread(this.app.thread.cleanModel["thread._id"]);
    this.app.routing.navigateBack();
  }
}
