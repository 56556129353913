import { paragraphDef, TableDef } from "pdfmake/build/pdfmake";
import { Impact } from "../../../../../shared/models/impact";
import { getApp } from "../../../app/app";

const getTrainTableBody = (trains: Impact[]): paragraphDef[][] => {
  const app = getApp();
  const body = [] as paragraphDef[][];
  trains.forEach((train) => {
    const row: paragraphDef[] = app.list.train.pdfColumns.map((field) =>
      train[field.split(".")[1]] != null ? train[field.split(".")[1]] : ""
    );
    body.push(row);
  });
  return body;
};

export const getTrainsTableDefinition = (trains: Impact[]): TableDef => {
  const app = getApp();
  return {
    layout: "lightHorizontalLines",
    table: {
      widths: ["*", "*"],
      body: [
        app.list.train.pdfColumns.map((field) => app.field.getLabel(field)),
        ...getTrainTableBody(trains),
      ],
    },
  };
};
