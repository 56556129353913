export enum GraphName {
  Vehicles = "vehicle",
  DinCodes = "dinCode",
  Fleet = "fleet",
  Status = "status",
  Departments = "department",
  Responsibles = "responsible",
  TotalCostLTB = "totalCostLTB",
  OMApproachesCurrentYear = "omapproachesCurrentYear",
  OMApproachesPerYear = "omapproachesPerYear",
  SolutionsCurrentYear = "solutionsCurrentYear",
  SolutionsPerYear = "solutionsPerYear",
}
