import { Component, ViewChild, Input, Output } from "@angular/core";
import { Subject } from "rxjs";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { getApp } from "../app";
import { Impact } from "../../../../shared/models/impact";
import { doc2Model } from "../api.service";

@Component({
  selector: "app-impact-modal",
  templateUrl: "./impact-modal.component.html",
  styleUrls: ["./impact-modal.component.scss"],
})
export class ImpactModalComponent {
  @Input() modalTitle = "";

  @ViewChild("content", { static: false }) content: any;
  @Output() ok = new Subject<any>();

  app = getApp((app) => {
    this.app = app;
  });

  fields: string[] = this.app.list.impact.fields;

  constructor(private modalService: NgbModal) {}

  async open(impact: Impact) {
    if (impact == null) {
      this.app.impact.currentImpact = doc2Model(
        "impact",
        new Impact()
      ) as Impact;
    } else {
      this.app.impact.cleanModel = doc2Model("impact", impact) as Impact;
      this.app.impact.currentImpact = doc2Model("impact", impact) as Impact;
    }

    try {
      const result = await this.modalService.open(this.content, {
        windowClass: "mediumModal",
      }).result;

      this.ok.next(result);
    } catch (err) {
      // NOP
    }
  }

  showSaveButton() {
    if (
      this.app.field.getInvalidFields("impact", this.app.impact.currentImpact)
        .length < 1 &&
      this.app.impact.modelHasChanges
    ) {
      return true;
    }
    if (
      this.app.field.getInvalidFields("impact", this.app.impact.currentImpact)
        .length < 1
    ) {
      return true;
    } else {
      return false;
    }
  }

  closeModal() {
    this.modalService.dismissAll();
  }
}
