import { Component, Input } from "@angular/core";
import { Type } from "../../../../shared/components";
import { getApp } from "../app";
@Component({
  selector: "app-filter-fields",
  templateUrl: "./filter-fields.component.html",
  styleUrls: ["./filter-fields.component.scss"],
})
export class FilterFieldsComponent {
  fieldList: string[] = [];
  @Input() types: Type[] = [];

  app = getApp((app) => {
    this.app = app;
  });

  constructor() {}

  getFieldOptions() {
    return new Map<string, Set<any>>();
  }

  getFeatureFields() {
    const types = new Set(this.types);
    if (!types.has("thread")) {
      return [];
    }
    return [
      this.app.fieldId.thread.changeClasses,
      this.app.fieldId.thread.omfStatus,
      this.app.fieldId.post.resolveClass,
    ];
  }

  getFilterFields() {
    if (this.app.state.filterByPage.length !== 0) {
      return true;
    } else {
      return false;
    }
  }

  getDisplayValue(field: string, value: any) {
    if (field === null) {
      return "" + value;
    }
    return this.app.field.getFieldValueAsText(field, value);
  }
}
