<div>
  <div
    *ngIf="app.filterList.field !== null"
    class="col-md-8 no-padding pb-3 fa-pull-right"
  >
    <app-field
      [field]="app.filterList.field"
      [model]="app.model"
      [differentDisplay]="true"
      [showLabel]="false"
      [largeInput]="true"
      [forceEnabled]="true"
      (keydown.enter)="app.filterList.add()"
      (selected)="app.filterList.add()"
      [typeaheadPlaceholder]="''"
    ></app-field>
  </div>
  <div *ngIf="getFilterFields()">
    <div *ngFor="let field of app.filterList.fields">
      <span *ngFor="let value of app.filterList.getValues(field)">
        <button
          (click)="app.filterFields.removeFilterValue(field, value)"
          class="button btn p-0 pb-3 m-0 pull-right"
          [ngbTooltip]="app.text.filter.remove"
          *ngIf="!app.filterFields.isQuickFilterField(field)"
        >
          <span class="badge badge-pill badge-om p-2">
            {{ app.field.getLabel(field) }} : &nbsp;
            {{ getDisplayValue(field, value) | uppercase }}
            &nbsp;
            <i class="far fa-times-circle"></i>
          </span>
        </button>
      </span>
    </div>
    <!-- <button
      class="button btn"
      (click)="
        app.filterList.clear();
        app.filterList.open = true;
        app.filterFields.addQuickFilter('showAllCases')
      "
      *ngIf="app.filterList.fields.length > 1"
    >
      <i class="fas fa-redo-alt filter-reset-icon"></i>
      {{ app.text.RM.resetFilter }}
    </button> -->
  </div>
</div>
