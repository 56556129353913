import { ComponentSettings } from "../settings/settings";

const settings = {
  permission: {},
  text: {
    search: { de: "Suchen", en: "Search", nl: "Zoeken" },
    remove: {
      de: "Filter entfernen",
      en: "Remove filter",
      nl: "Verwijder filter",
    },
    selectField: {
      de: "In einem bestimmten Feld suchen",
      en: "Search in a particular field",
      nl: "Zoeken in een ",
    },
    searchInAllFields: {
      de: "In allen Feldern suchen",
      en: "Search in all fields",
      nl: "Zoek in alle velden",
    },
  },
  list: {},
  url: {},
  field: {},
};

export const filter: ComponentSettings<typeof settings> = settings;
