import { ComponentSettings, FieldTypes } from "../settings/settings";

const field = {
  // productCategory: {
  //   type: FieldTypes.value,
  //   text: {
  //     de: "Product Category",
  //     en: "Product Category",
  //   },
  // },
  category: {
    type: FieldTypes.value,
    text: {
      de: "Category",
      en: "Category",
    },
  },
  subcategory: {
    type: FieldTypes.value,
    text: {
      de: "Subcategory",
      en: "Subcategory",
    },
  },
  item: {
    type: FieldTypes.value,
    text: {
      de: "Item",
      en: "Item",
    },
  },
};

const settings = {
  permission: {},
  text: {
    productCategory: {
      de: "Product Category",
      en: "Product Category",
    },
  },
  url: {},
  field,
  list: {},
};

export const productCategory: ComponentSettings<typeof settings> = settings;
