import { Inject, Injectable } from "@angular/core";
import {
  manufacturerMapping,
  partMapping,
} from "../../../../shared/rm-mapping";
import { AppType, APP_TYPE } from "../app.type";
import { OverrideMpnFieldsServiceType } from "./override-mpnFields-service.type";
import { Customer } from "../../../../shared/types/customers";
import { StringUtils } from "../../../../shared/utils/string.utils";

@Injectable()
export class OverrideMpnFieldsService implements OverrideMpnFieldsServiceType {
  constructor(@Inject(APP_TYPE) private app: AppType) {}

  partKeys: string[] = [];
  manufacturerKeys: string[] = [];
  selectedField: string = "";
  openOverrideModal: boolean = false;

  async overrideFieldsFromOption(field: string, option: string) {
    if (
      field === this.app.fieldId.thread.artNumber ||
      field === this.app.fieldId.thread.crtNumber
    ) {
      this.selectedField = field;
      await this.app.manufacturer.prepareData(field, option);
    }
  }
  async setFieldsOnThread() {
    if (this.selectedField === this.app.fieldId.thread.artNumber) {
      this.app.thread.copyRmFieldsToThread(
        this.app.manufacturer.selectedPart,
        partMapping
      );
    }
    if (this.selectedField === this.app.fieldId.thread.crtNumber) {
      this.app.thread.copyRmFieldsToThread(
        this.app.manufacturer.selectedManufacturer,
        manufacturerMapping
      );
    }
    if (this.app.customers.expectCurrent === Customer.KNDS) {
      this.app.field.manufacturerCodes = [];
      this.app.thread.thread[this.app.fieldId.thread.cageCode] = "";

      let value: string =
        this.app.thread.thread[this.app.fieldId.thread.creator];
      if (!StringUtils.isNullOrEmpty(value)) {
        value = value.toUpperCase();
        await this.app.field.getManufacturerCodeByName(value);
      }
    }
  }
}
