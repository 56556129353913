import { ComponentSettings, empty, FieldTypes } from "../settings/settings";
import { creditsFields } from "./credits.settings";

const field = {
  fileName: {
    type: FieldTypes.value,
    text: { de: "Name", en: "Name", nl: "Naam" },
  },
  mimeType: {
    type: FieldTypes.value,
    text: { de: "Typ", en: "Type", nl: "Type" },
  },
  docType: {
    type: FieldTypes.value,
    text: { de: "Dokumenten-Type", en: "Document-Type", nl: "Document-Type" },
  },
  docId: {
    type: FieldTypes.value,
    text: { de: "Domumenten-ID", en: "Document-ID", nl: "DOcument-ID" },
  },
  tag: {
    type: FieldTypes.value,
    text: {
      de: "Tag hinzufügen",
      en: "Add tag",
      nl: "Voeg tag toe",
    },
    maxLength: 20,
  },
  tags: {
    type: FieldTypes.value,
    text: {
      de: "Tags",
      en: "Tags",
      nl: "Tags",
    },
    multiple: true,
  },
  omfNumber: {
    type: FieldTypes.value,
    text: {
      de: "case number",
      en: "case number",
      nl: "case number",
    },
  },
  length: {
    type: FieldTypes.value,
    text: { de: "Größe", en: "Length", nl: "Lengte" },
  },
  ...creditsFields,
};

const settings = {
  permission: {
    markAttAsSensitive: false,
    addTag: false,
    removeTag: false,
  },
  text: {
    attachment: { de: "Anhang", en: "Attachment", nl: "Bijlage" },
  },
  list: {
    columns: empty,
  },
  url: {},
  field,
};

export const attachment: ComponentSettings<typeof settings> = settings;
