export const styles = {
  fieldName: {
    bold: true,
    fontSize: 11
  },
  section: {
    bold: true,
    fillColor: "#eee"
  },
  fieldNameTiny: {
    bold: true,
    fontSize: 8
  },
  fieldNameInitials: {
    bold: true,
    fontSize: 11
  },
  hint: {
    fontSize: 8,
    italics: true
  },
  input: {
    margin: [0, 10, 0, 0],
    fontSize: 11
  },
  input_master: {
    fontSize: 11
  },
  mfr_data: {},
  noBorders: {
    border: [false, false, false, false]
  },
  sectionTitle: {
    margin: [0, 20, 0, 10],
    fontSize: 14,
    alignment: "center",
    border: [false, true, false, true],
    fillColor: "#eee"
  }
};
