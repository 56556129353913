import { FleetGraphData } from "./fleet-graph-data";
import { AbstractGraph } from "../abstract-graph";
import { AppType } from "../../app.type";
import { GraphConfig } from "../graph.type";

export class FleetGraph extends AbstractGraph<FleetGraphData> {
  get config(): GraphConfig {
    return {
      type: "bar-vertical-2d",
      showXAxis: true,
      showYAxis: true,
      showLegend: true,
      xAxisLabel: null,
      yAxisLabel: null,
      colorScheme: {
        domain: ["#686868", "#939393", "#afafaf"],
      },
    };
  }

  constructor(protected app: AppType) {
    super(app);
  }

  protected getSortFunction() {
    return (a: FleetGraphData, b: FleetGraphData) => a.getTime() - b.getTime();
  }

  protected getMap(map: Map<Date, FleetGraphData>) {
    for (const date of this.generateDates()) {
      map.set(date, new FleetGraphData(date, this.app));
    }
    this.app.graphs.allImpacts.forEach((vehicle) => {
      map.forEach((fleetData) => {
        fleetData.add(vehicle);
      });
    });
  }
}
