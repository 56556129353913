import { OMApproachesCurrentYear } from "./approaches-current-year-graph-data";
import { AbstractGraph } from "../abstract-graph";
import { AppType } from "../../app.type";
import { GraphConfig } from "../graph.type";
import { Thread } from "../../../../../shared/models/thread";

export const months = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December",
];
export class OMApproachesCurrentYearGraph extends AbstractGraph<OMApproachesCurrentYear> {
  get config(): GraphConfig {
    return {
      type: "bar-vertical-stacked",
      showXAxis: true,
      showYAxis: true,
      showLegend: true,
      xAxisLabel: null,
      yAxisLabel: null,
      colorScheme: {
        domain: ["#660000", "#003d7a", "#757575"],
      },
    };
  }

  constructor(protected app: AppType) {
    super(app);
  }

  getThreadMonth(time: number) {
    let date = new Date(time);
    let month = months[date.getMonth()];
    return this.getMonthLabel(month);
  }

  getMonthLabel(month: string | null) {
    if (month == null) {
      return "";
    }
    switch (month) {
      case "January":
        return this.app.text.graphs.january;
      case "February":
        return this.app.text.graphs.february;
      case "March":
        return this.app.text.graphs.march;
      case "April":
        return this.app.text.graphs.april;
      case "May":
        return this.app.text.graphs.may;
      case "June":
        return this.app.text.graphs.june;
      case "July":
        return this.app.text.graphs.july;
      case "August":
        return this.app.text.graphs.august;
      case "September":
        return this.app.text.graphs.september;
      case "October":
        return this.app.text.graphs.october;
      case "November":
        return this.app.text.graphs.november;
      case "December":
        return this.app.text.graphs.december;
      default:
        return "";
    }
  }

  protected getMap(map: Map<string, OMApproachesCurrentYear>) {
    months.forEach((month) => {
      map.set(
        this.getMonthLabel(month),
        new OMApproachesCurrentYear(this.getMonthLabel(month), this.app)
      );
    });

    this.app.graphs.allThreads.forEach((thread: Partial<Thread>) => {
      if (thread.create_time == null) {
        return;
      }
      if (
        new Date(thread.create_time).getFullYear() < new Date().getFullYear()
      ) {
        return;
      }
      const item = map.get(this.getThreadMonth(Number(thread.create_time)));
      if (item == null) {
        return;
      }
      item.add(thread);
    });
  }
}
