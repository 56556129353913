export function objForEach<T>(
  obj: { [key: string]: T },
  f: (val: T, key: string) => void
) {
  if (obj == null) return;
  for (const key in obj) {
    const val = obj[key];
    f(val, key);
  }
}

export function objMap<X, Y>(obj: { [key: string]: X }, f: (x: X) => Y) {
  const map = new Map<string, Y>();
  objForEach(obj, (val, key) => {
    map.set(key, f(val));
  });
  return map;
}
