<div class="mx-3">
  <div class="col-12">
    <div *ngIf="app.message.note === true">
      <div class="d-flex justify-content-between sm-text">
        <span class="m-1 py-2">{{ app.text.message.leaveNote }}</span>
        <button
          class="btn btn-sm btn-clean sm-text mt-2"
          (click)="
            app.message.discardChanges();
            app.unlockedId = null;
            this.app.message.clearModel()
          "
        >
          <i class="fas fa-times close-icon"></i>&nbsp;
          {{ app.text.post.cancel }}
        </button>
      </div>
      <app-field
        [model]="app.message.model"
        [field]="app.fieldId.message.message"
        [labelInside]="true"
        [showLabel]="false"
      ></app-field>
    </div>
    <div class="d-flex justify-content-end">
      <button
        *ngIf="!app.message.showSaveButton"
        class="btn btn-sm btn-default-blue my-3 m-0"
        (click)="app.message.showContent()"
        [disabled]="app.unlockedId != null"
      >
        {{ app.text.message.add }}
      </button>
      <button
        *ngIf="app.message.showSaveButton"
        class="btn btn-sm btn-default-blue my-3 m-0"
        (click)="save(); app.message.discardChanges()"
        [disabled]="!showSaveButton(id)"
      >
        {{ app.text.message.add }}
      </button>
    </div>
  </div>
</div>
