<div class="row d-flex px-3 text-center">
  <span
    class="col-sm section-item"
    *ngFor="let section of sections"
    (click)="app.RMSubmenu.setSection(section)"
    [ngClass]="{ 'section-active': app.RMSubmenu.section === section }"
  >
    {{ app.RMSubmenu.getSectionLabel(section) }}
  </span>
</div>
