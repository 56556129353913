import { Component, EventEmitter, Input, Output } from "@angular/core";
import { getApp } from "../app";
import {
  doc2Model,
  model2Doc,
  saveMessage,
  updateManufacturer,
} from "../api.service";
import { Manufacturer } from "../../../../shared/models/manufacturer";
import { ActivatedRoute } from "@angular/router";
import { formatDate } from "../utils/date.util";
import { Message } from "../../../../shared/models/message";

@Component({
  selector: "app-message",
  templateUrl: "./message.component.html",
  styleUrls: ["./message.component.scss"],
})
export class MessageComponent {
  app = getApp((app) => {
    this.app = app;
  });

  @Input() id: string | null = null;
  @Input() type: string = "";
  @Input() showMessages = true;

  saveAttempted = false;
  constructor(private route: ActivatedRoute) {}
  ngOnInit() {
    //reset the model if id is null
    if (this.id == null) {
      this.app.message.model = {} as Message;
    }

    this.route.params.subscribe((params) => {
      if (params.partNumber) {
        this.app.message.entityId = params.partNumber;
      }
      if (params.manufacturerId) {
        this.app.message.entityId = params.manufacturerId;
      }
    });
  }

  showSaveButton(messageId: string | null) {
    if (this.app.unlockedId) {
      if (
        this.app.field.getInvalidFields("message", this.app.message.model)
          .length < 1 &&
        this.app.message.modelHasChanges(messageId)
      ) {
        return true;
      }
    } else {
      if (
        this.app.field.getInvalidFields("message", this.app.message.model)
          .length < 1
      ) {
        return true;
      } else {
        return false;
      }
    }
  }

  togglePost() {
    this.showMessages = !this.showMessages;
  }

  async save() {
    this.saveAttempted = true;
    const customer = this.app.customers.expectCurrent;

    let doc: any = {} as Message;
    doc = model2Doc("message", this.app.message.model);
    doc.entityId = this.app.message.entityId;
    doc.type = "message";
    await saveMessage(customer, doc);
    this.app.message.clearModel();

    // TODO: type is always coming as empty string, so it seems that this code is not used anymore, please remove after a while if no complains
    // if (this.type === "manufacturer" && this.app.message.entityId !== null) {
    //   let oldManufacturerDoc = await this.app.manufacturer.getManufacturer(
    //     this.app.message.entityId
    //   );
    //   let newManufacturerDoc = {} as Manufacturer;
    //   for (const key of Object.keys(oldManufacturerDoc)) {
    //     newManufacturerDoc[key] = oldManufacturerDoc[key];
    //   }
    //   newManufacturerDoc["lastUpdatedOn"] = formatDate(new Date().getTime());
    //   await updateManufacturer(
    //     this.app.customers.expectCurrent,
    //     "manufacturer",
    //     newManufacturerDoc
    //   );
    //   this.app.message.newPostSubject.next(true);
    // }
    this.app.message.clearModel();
    this.app.unlockedId = null;
    this.saveAttempted = false;
    if (this.app.message.entityId != null) {
      this.app.message.getMessages(this.app.message.entityId);
    }
  }
}
