import { OMApproachesPerYear } from "./approaches-per-year-graph-data";
import { AbstractGraph } from "../abstract-graph";
import { AppType } from "../../app.type";
import { GraphConfig } from "../graph.type";
import { Thread } from "../../../../../shared/models/thread";

export class OMApproachesPerYearGraph extends AbstractGraph<OMApproachesPerYear> {
  get config(): GraphConfig {
    return {
      type: "bar-vertical-stacked",
      showXAxis: true,
      showYAxis: true,
      showLegend: true,
      xAxisLabel: null,
      yAxisLabel: null,
      colorScheme: {
        domain: ["#660000", "#003d7a", "#757575"],
      },
    };
  }

  constructor(protected app: AppType) {
    super(app);
  }

  generateYears() {
    let threadsDates: number[] = [];
    this.app.graphs.allThreads.forEach((thread) => {
      let time = new Date(thread.create_time);
      threadsDates.push(time.getFullYear());
    });
    return [...new Set(threadsDates.sort())];
  }

  getThreadYear(time: number) {
    let date = new Date(time);
    return date.getFullYear().toString();
  }

  protected getMap(map: Map<string, OMApproachesPerYear>) {
    let date: number;
    for (date of this.generateYears()) {
      map.set(
        date.toString(),
        new OMApproachesPerYear(date.toString(), this.app)
      );
    }
    this.app.graphs.allThreads.forEach((thread: Partial<Thread>) => {
      if (thread.create_time == null) {
        return;
      }
      const item = map.get(this.getThreadYear(Number(thread.create_time)));
      if (item == null) {
        return;
      }
      item.add(thread);
    });
  }
}
