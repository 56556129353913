import { Component } from "@angular/core";
import { getApp } from "../app";
import { Router, NavigationStart } from "@angular/router";
import { COMMENT, SOLUTION, STREAM, TASK } from "./post.service.type";
import { Customer } from "../../../../shared/types/customers";

@Component({
  selector: "app-post",
  templateUrl: "./post.component.html",
  styleUrls: ["./post.component.scss"],
})
export class PostComponent {
  isFilterSet: boolean = false;
  taskColumns: string[] = [];

  app = getApp((app) => {
    this.app = app;
  });

  constructor(private router: Router) {
    router.events.subscribe((val) => {
      if (val instanceof NavigationStart) {
        this.app.post.sectionType = STREAM;
      }
    });
  }

  ngOnInit() {
    // Set the STREAM section to be the default one for omp and coop since they do not have the task section
    if (
      this.app.customers.expectCurrent === Customer.OMP ||
      this.app.customers.expectCurrent === Customer.COOP
    ) {
      this.app.post.sectionType = STREAM;
      this.app.post.sortingOrder = "descending";
    }

    this.app.post.showResolveClassField(this.app.post.model["post._id"]);
    this.app.post.setFilters(this.app.post.sectionType);
    this.app.post.setDefaultFilter(this.app.post.sectionType);

    this.app.post.groupingByFilterSubject.subscribe((value) => {
      if (value.length >= 0) {
        if (this.app.post.sectionType === STREAM) {
          this.app.post.cleanPosts = value;
        }
        if (this.app.post.sectionType === COMMENT) {
          this.app.post.comments = value;
        }
        if (this.app.post.sectionType === TASK) {
          this.app.post.tasks = value;
        }
        if (this.app.post.sectionType === SOLUTION) {
          this.app.post.solutions = value;
        }
      }
    });

    // Define task columns for the task creation
    // TaskNote field needs to be removed when tasks are created
    this.taskColumns = this.app.list.post.taskFieldsList;
    const taskNoteIndex = this.taskColumns.indexOf(
      this.app.fieldId.post.taskNote
    );
    this.taskColumns.splice(taskNoteIndex, 1);
    this.resetProjectFilter();
  }

  resetProjectFilter() {
    this.app.post.isFilterSet = false;
    this.app.post.isSortingOrderSet = false;
    this.app.post.projectFilters = [];
    this.app.post.projectNames = [];
    this.app.field.manufacturerCodes = [];
  }

  getHelperText(value: string) {
    let result = this.app.field.getHelpTextArray(
      this.app.field.getOptionText("post.resolveClassDescription", value)
    );
    if (result[result.length - 1] === value) {
      result = null;
    }
    return result;
  }

  showSaveButton() {
    if (this.app.unlockedId) {
      this.app.post.isNew = false;
      if (
        this.app.field.getInvalidFields("post", this.app.post.model).length <
          1 &&
        this.app.post.modelHasChanges(this.app.unlockedId)
      ) {
        return true;
      }
    } else {
      this.app.post.isNew = true;
      if (
        this.app.field.getInvalidFields("post", this.app.post.model).length < 1
      ) {
        return true;
      } else {
        return false;
      }
    }
  }

  showResolveClassDescription() {
    if (
      this.app.customers.expectCurrent === Customer.DB &&
      this.app.post.model[this.app.fieldId.post.resolveClass] &&
      this.app.post.postType === SOLUTION &&
      !this.app.post.isReadMode
    ) {
      return true;
    }
    return false;
  }

  showMyTasksOnTopSortation() {
    if (
      (this.app.post.sectionType === "task" ||
        this.app.post.sectionType === "stream") &&
      !(
        this.app.customers.expectCurrent === Customer.OMP ||
        this.app.customers.expectCurrent === Customer.COOP
      )
    ) {
      return true;
    }
    return false;
  }

  ngOnDestroy(): void {
    this.app.post.setDefaultSorting("stream");
    this.app.post.setDefaultFilter("stream");
  }
}
