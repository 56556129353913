import { GraphDataSimple } from "../graphs.service.type";
import { AbstractGraphData } from "../abstract-graph-data";
import { AppType } from "../../app.type";

export class VehicleGraphData extends AbstractGraphData {
  count = 0;

  constructor(private vehicle: string, app: AppType) {
    super(app);
    this.add();
  }

  add() {
    this.count++;
  }

  get data(): GraphDataSimple {
    return {
      name: this.vehicle,
      value: this.count
    };
  }
}
