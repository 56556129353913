<app-trains-modal #openTrain [modalTitle]="app.text.train.trains">
</app-trains-modal>

<!-- <app-modal
  #deleteTrainModal
  [modalMessage]="app.text.train.deleteMessage"
  [modalTitle]="app.text.train.modalTitle"
  (ok)="app.train.delete()"
></app-modal> -->

<div class="col-12 no-padding">
  <div class="control-bar">
    <button
      class="btn btn-control p-2"
      (click)="app.routing.navigateThread(threadId)"
    >
      <i class="far fa-arrow-left"></i> &nbsp; {{ app.text.app.backToCase }}
    </button>
  </div>
</div>

<div class="container-fluid">
  <button
    class="btn btn-sm btn-default-orange pull-right my-3"
    (click)="openTrain.open()"
  >
    <i class="fa fa-plus"></i>&nbsp; {{ app.text.app.add }}
  </button>

  <div>
    <div class="tableFixHead">
      <table class="table table-striped spacing-table">
        <thead>
          <tr>
            <th
              class="p-1 text-center"
              *ngFor="let column of app.list.train.trains"
            >
              <span
                (click)="
                  app.table.toggleSortDirection(column); app.table.sort(trains)
                "
              >
                {{ app.field.getLabel(column) }}
              </span>
              <span *ngIf="column === app.table.sortField">
                <i
                  *ngIf="app.table.sortDirection === 1"
                  class="fa fa-chevron-up"
                ></i>
                <i
                  *ngIf="app.table.sortDirection === -1"
                  class="fa fa-chevron-down"
                ></i>
              </span>
            </th>
          </tr>
        </thead>
        <tbody>
          <tr
            *ngFor="let train of trains; let i = index"
            (click)="openTrain.open(train)"
            class="pointer"
          >
            <td
              *ngFor="let column of app.list.train.trains"
              class="text-center"
            >
              <span *ngIf="column !== 'impact.actions'">
                {{ train[remove(column)] }}
              </span>
              <span
                class="pointer"
                *ngIf="
                  column === 'impact.actions' &&
                  app.permission.train.canAddTrainToCase
                "
                (click)="
                  $event.stopPropagation();
                  app.train.delete(train);
                  removeItem(i)
                "
              >
                <i class="fa fa-trash"></i>
              </span>
            </td>
          </tr>
          <tr>
            <td *ngIf="loading" colspan="99" class="text-center">
              {{ this.app.text.core.loading }}
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</div>
