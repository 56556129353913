import { ActivatedRoute } from "@angular/router";
import { AppType } from "../../app.type";
import { Customer } from "../../../../../shared/types/customers";
import { getApp } from "../../app";
import { getMpnsListByPartNumber, saveImpact } from "../../api.service";
import { Part } from "../../../../../shared/models/part";
import { Subject, Subscription } from "rxjs";
import {
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
} from "@angular/core";
import {
  Impact,
  ImpactElement,
  ImpactRelative,
} from "../../../../../shared/models/impact";

@Component({
  selector: "app-where-used-in",
  templateUrl: "./where-used-in.component.html",
  styleUrls: ["./where-used-in.component.scss"],
})
export class WhereUsedInComponent implements OnInit, OnDestroy {
  @Input() showContent = true;
  @Input() partNumber = "";
  @Output() showsContent = new Subject();
  @Output() toggled = new EventEmitter();
  openNodes = new Map<string, string>();
  vehicles: ImpactRelative[] = [];
  totalUsages = 0;
  freqUse = 0;

  private _modeSubscription: Subscription = new Subscription();
  private _routeSubscription: Subscription = new Subscription();
  private _partsToVehiclesSubscription: Subscription = new Subscription();

  app = getApp((app: AppType) => {
    this.app = app;
  });
  mpns: string[] = [];
  part: Part = {} as Part;
  selectedManufacturer: string = "";
  threadPage: boolean = false;
  columns: string[] = [];
  constructor(private route: ActivatedRoute) {}

  ngOnInit() {
    this.init();
  }

  init() {
    this._routeSubscription = this.route.params.subscribe(async (params) => {
      if (params.threadId !== null && params.threadId !== undefined) {
        if (this.app.thread.id !== null && this.app.unlockedId === null) {
          //display only the impacts which do not have the impactType = "train"
          // the impacts with the impactType="train" are dipalyed in the trainseries section
          this.threadPage = true;
          let impacts = await this.app.impacts.getImpacts(this.app.thread.id);
          if (this.app.customers.expectCurrent === Customer.KNDS) {
            this.app.post.projectNames = impacts
              .filter((o: any) => o.projectNumber != undefined)
              .map((x: any) => x.projectNumber);
            this.app.post.projectNames = [
              ...new Set(this.app.post.projectNames),
            ];
          }
          this.vehicles = impacts.filter(
            (impact: any) =>
              impact.impactType === undefined || impact.impactType !== "train"
          );

          this.part = await this.app.part.getPartByPartNumberWithoutBuffer(
            this.app.thread.thread["thread.artNumber"]
          );
          if (this.part) {
            this.freqUse =
              this.part.freqUse != null && this.part.freqUse !== ""
                ? Number(this.part.freqUse)
                : 0;
            this.mpns = await getMpnsListByPartNumber(
              this.app.customers.expectCurrent,
              this.part._id
            );
          }
          this.vehicles.forEach((i: ImpactRelative, index: number) => {
            i.index = index.toString();
          });
          this.app.treeRow.currentPart = {} as Part;
          this.getVehicleCount(this.vehicles);
        }
      } else {
        this.threadPage = false;
      }
    });

    this._modeSubscription = this.app.impact.newImpactSubject.subscribe(() => {
      if (this.app.thread.id !== undefined && this.app.thread.id !== null) {
        this.vehicles.forEach((i: ImpactRelative, index: number) => {
          i.index = index.toString();
        });

        this.vehicles = this.app.impacts.impacts.filter(
          (impact: any) =>
            impact.impactType === undefined || impact.impactType !== "train"
        );
      }
    });

    this._partsToVehiclesSubscription =
      this.app.impacts.partsToVehiclesSubject.subscribe((value: any) => {
        if (value) {
          this.vehicles = this.app.impacts.partsToVehicles;
          /** set an initial index for each top level impact */
          this.vehicles.forEach((i: ImpactRelative, index: number) => {
            i.index = index.toString();
          });
        }
      });
    this.getColumns();
  }

  setVehiclesForSorting(vehicles: ImpactRelative[]) {
    Object.assign(this.app.impacts.cleanVehicles, vehicles);
    vehicles.forEach((i: ImpactRelative) => {
      let index = this.vehicles.indexOf(i);

      if (i.parent != undefined) {
        if (index != -1) {
          vehicles.splice(index, 1);
        }
      }
    });

    vehicles.forEach((i: ImpactRelative) => {
      if (i.hasOwnProperty("parent")) {
        this.setVehiclesForSorting(vehicles);
      }
    });

    this.app.table.sort(vehicles);
  }

  specialLogicLabels(id: string) {
    switch (id) {
      case "thread.artNumber":
        if (this.app.customers.expectCurrent === Customer.LRE) {
          return this.app.field.getLabel(this.app.fieldId.impact.artNumber);
        } else {
          return this.app.field.getLabel(id);
        }
      case "impact.omfVehicleCnt":
        if (this.app.customers.expectCurrent === Customer.NS) {
          return this.app.field.getLabel(this.app.fieldId.part.quantity);
        } else {
          return this.app.field.getLabel(id);
        }
      case "impact.omfVehicleName":
        if (this.app.customers.expectCurrent === Customer.NS) {
          return this.app.field.getLabel(this.app.fieldId.part.description);
        } else {
          return this.app.field.getLabel(id);
        }
      default:
        return this.app.field.getLabel(id);
    }
  }

  getColumns() {
    if (this.app.overrideMpnFields.openOverrideModal) {
      this.columns = [this.app.fieldId.impact.artNumber];
    } else {
      this.columns = this.app.list.impact.RMClientColumns;
    }
  }

  getVehicleCountRecursively(vehicle: Impact, result = 0) {
    if (result === 0) {
      result = Number(vehicle.omfVehicleCnt);
    } else {
      result = result * Number(vehicle.omfVehicleCnt);
    }

    if (vehicle.impacts && vehicle.impacts.length > 0) {
      vehicle.impacts.forEach((impact: any) => {
        result = this.getVehicleCountRecursively(impact.impact, result);
      });
    }

    return result;
  }

  getVehicleCount(vehicles: Impact[]) {
    let sum = 0;

    vehicles.forEach((vehicle) => {
      sum += this.getVehicleCountRecursively(vehicle);
    });
    this.totalUsages = sum;
    return sum;
  }

  navigateToImpact(impact: Impact) {
    if (this.route.snapshot.params.threadId) {
      if (impact._id !== undefined) {
        this.app.routing.navigateImpact(
          this.app.thread.thread["thread._id"],
          impact._id
        );
      }
    }
  }

  resetNodes() {
    this.openNodes.clear();
  }

  toggleNode(impact: ImpactRelative): void {
    let open = false;
    let index = impact.index != null ? impact.index : "";
    /** if the open nodes includes the current index, it has to be removed */
    if (!this.openNodes.has(index)) {
      if (impact.artNumber != null) {
        this.openNodes.set(index, impact.artNumber);
        open = true;
      }
    } else {
      this.openNodes.delete(index);
      open = false;
    }

    /** generate an array of children's artNumbers */
    if (impact.impacts != null) {
      impact.children = impact.impacts.map((i: ImpactElement) =>
        i.impact.artNumber != null ? i.impact.artNumber : ""
      );
    }

    /** on closing of a specific node/top level, it should close its children */
    if (!open) {
      this.openNodes.forEach((v: string, k: string) => {
        if (k.includes(index)) {
          this.openNodes.delete(k);
        }
      });
    }

    if (impact.artNumber != null) {
      this.openChildren(impact.artNumber, this.openNodes.has(index), index);
    }
  }

  openChildren(artNumber: string, open: boolean, parentIndex: string) {
    let impactIndex = this.vehicles.findIndex(
      (v: ImpactRelative) => v.index === parentIndex
    );

    if (open) {
      if (impactIndex !== -1) {
        /** generate the list of parents under the current impact  */
        let parents: string[] | undefined = [];

        if (this.vehicles[impactIndex].parent != null) {
          parents = this.vehicles[impactIndex].parent;
        }
        /** generate the list of children under the current impact */
        let impactElements: ImpactElement[] | undefined = [];

        if (this.vehicles[impactIndex].impacts != null) {
          impactElements = this.vehicles[impactIndex].impacts;
        }

        if (impactElements != undefined) {
          let impacts = impactElements.map((i, index: number) => {
            /** generate the list of parents for each impact, including the current impact */
            if (parents == undefined) {
              (i.impact as ImpactRelative).parent = [artNumber];
            } else {
              (i.impact as ImpactRelative).parent = [...parents, artNumber];
            }
            /** generate the index based on the current impact's current index  */
            /** Eg: Impact1 -> will have index 1,
             * the first child from the first level will have the index 1-0
             * the second child from the first level will have the index 1-1
             * the first child from the second level will have the index 1-0-0
             */

            (i.impact as ImpactRelative).index = `${parentIndex}-${index}`;

            return i.impact;
          });

          this.vehicles.splice(impactIndex + 1, 0, ...impacts);
        }
      }
    } else {
      /** determine the level of the current impact */
      let splitIndex = parentIndex.split("-");
      let level = splitIndex.length - 1;

      /** close every child of the current impact */
      let impacts = this.vehicles.filter(
        (v: ImpactRelative) =>
          v.index != null &&
          v.index !== "" &&
          v.index !== parentIndex &&
          this.shouldCloseRow(level, v.index, splitIndex)
      );

      impacts.forEach((i: ImpactRelative) => {
        let index = this.vehicles.indexOf(i);

        this.vehicles.splice(index, 1);
      });
    }
  }

  shouldCloseRow(level: number, index: string, splitIndex: string[]): boolean {
    /** ensure that the segments match up until the last segment  */
    /** eg: when closing 1-0, it should close every index that contains 1-0 explicitly */
    for (var i = level; i >= 0; i--) {
      if (index.split("-")[i] !== splitIndex[i]) {
        return false;
      }
    }
    return true;
  }

  shouldDisplayText(): boolean {
    if (
      this.app.treeRow.currentPart != null &&
      Object.keys(this.app.treeRow.currentPart).length > 0
    ) {
      return true;
    } else {
      return false;
    }
  }

  async deleteSelectedImpact(impact: Impact) {
    let doc: Partial<Impact> = {
      _id: impact._id,
      _rev: impact._rev,
      omfVehicleName: impact.omfVehicleName,
      omfNumber: impact.omfNumber,
      _deleted: true,
    };

    await saveImpact(this.app.customers.expectCurrent, doc);

    if (this.app.customers.expectCurrent === Customer.KNDS) {
      // Mark open tasks as completed if the corresponding project has been deleted
      if (impact.projectNumber !== undefined) {
        let impactsByProject = this.app.impacts.impacts.filter(
          (project: any) => project.projectNumber === impact.projectNumber
        );

        if (impactsByProject.length === 1) {
          this.app.post.markTaskCompleted(impact.projectNumber);
        }
      }
    }
    this.init();
  }

  ngOnDestroy(): void {
    if (this._routeSubscription) {
      this._routeSubscription.unsubscribe();
    }

    if (this._partsToVehiclesSubscription) {
      this._partsToVehiclesSubscription.unsubscribe();
    }
    if (this._modeSubscription) {
      this._modeSubscription.unsubscribe();
    }
  }
}
