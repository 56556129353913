<button
  *ngIf="permission && app.unlockedId == null"
  class="btn btn-edit btn-sm"
  [ngClass]="{ 'btn-edit': hasText }"
  (click)="edit()"
>
  <i class="fa fa-edit">&nbsp;&nbsp;</i>
  <span *ngIf="hasText">{{ app.text.app.edit }}</span>
</button>

<ng-content *ngIf="app.unlockedId === id"></ng-content>
