import { EncodingUtils } from "../../../../shared/utils/encoding.utils";

export const TOKEN = "app_token";

export function getToken(): string | null {
  if (typeof Storage !== "undefined") {
    let tokenValue: string | null = null;

    if (localStorage.getItem(TOKEN) !== null) {
      tokenValue = localStorage.getItem(TOKEN);
    }

    if (tokenValue !== null) {
      return EncodingUtils.decodeBase64(tokenValue);
    }
  }

  return null;
}
