<app-vehicle-responsible-modal
  #openVehicle
  [modalTitle]="app.text.vehicleResponsible.vehicleResponsible"
>
</app-vehicle-responsible-modal>
<app-modal
  #addNewVehicle
  [modalType]="'vehicle-responsible-new'"
  [showButtons]="false"
>
</app-modal>
<app-modal
  #seeVehicleDetails
  [modalType]="'vehicle-responsible'"
  [showButtons]="false"
>
</app-modal>

<div class="col-12 no-padding">
  <div class="control-bar">
    <button
      class="btn btn-control p-2"
      (click)="app.routing.navigateBack()"
      [title]="app.text.thread.close"
    >
      <i class="far fa-arrow-left"></i> &nbsp; {{ app.text.app.backToCase }}
    </button>
  </div>
</div>

<div class="container-fluid">
  <div class="row">
    <div class="col-sm-12 col-md-12 col-xl-12">
      <div class="row py-3">
        <div class="col-md-12">
          <button
            class="btn btn-sm btn-default-orange"
            [title]="app.text.impact.add"
            (click)="
              app.vehicleResponsible.id = null;
              app.model = {};
              addNewVehicle.open()
            "
          >
            <i class="fa fa-plus"></i>&nbsp; {{ app.text.app.add }}
          </button>

          <!-- Filter button -->
          <button
            class="btn btn-sm btn-default-red fa-pull-right"
            data-toggle="collapse"
            data-target="#collapseFilter"
            (click)="
              filterOpen = !filterOpen;
              app.filterList.open = !app.filterList.open
            "
            [ngClass]="{ btnFilterOpen: app.filterList.open }"
          >
            <i class="fas fa-filter"></i>
            {{ app.text.home.filters }}
            <i *ngIf="!app.filterList.open" class="fal fa-angle-right"></i>
            <i *ngIf="app.filterList.open" class="far fa-times"></i>
          </button>
        </div>
      </div>

      <!-- Second line -->
      <div class="row bottom-line">
        <div class="col-12">
          <div class="col-8 pull-right no-padding">
            <!-- Filter cips -->
            <app-filter-fields></app-filter-fields>
          </div>
        </div>
      </div>

      <app-paginator
        [length]="app.vehicleResponsible.ids.length"
        [index]="app.paginator.focus"
        (focus)="app.paginator.focus = $event"
      ></app-paginator>
      <div *ngIf="app.customers.expectCurrent !== 'db'">
        <div class="tableFixHead">
          <table class="table table-striped">
            <thead>
              <tr>
                <th class="p-1 text-center" *ngFor="let column of columns">
                  <span
                    *ngIf="column !== 'actions'"
                    class="pointer"
                    (click)="
                      app.table.toggleSortDirection(column);
                      app.table.sort(app.vehicleResponsible.ids)
                    "
                  >
                    {{ app.field.getLabel(column) }}
                  </span>

                  <span *ngIf="column === 'actions'">
                    {{ app.field.getLabel("impact.actions") }}
                  </span>

                  <span *ngIf="column === app.table.sortField">
                    <i
                      *ngIf="app.table.sortDirection === 1"
                      class="fa fa-chevron-up"
                    ></i>
                    <i
                      *ngIf="app.table.sortDirection === -1"
                      class="fa fa-chevron-down"
                    ></i>
                  </span>
                </th>
              </tr>
            </thead>
            <tbody>
              <tr
                *ngFor="let vehicle of app.paginator.getPageWithDoc()"
                class="pointer"
                (click)="
                  seeVehicleDetails.open();
                  app.vehicleResponsible.id = vehicle._id
                "
              >
                <td class="p-1 text-center" *ngFor="let column of columns">
                  <span *ngIf="!column.includes('responsibles')">
                    {{ vehicle[column.split(".")[1]] }}
                  </span>
                  <!-- display the responsible fields of a vehicle(DB client only)  -->
                  <span
                    *ngIf="
                      column.includes('responsibles') &&
                      app.customers.expectCurrent === 'db'
                    "
                  >
                    {{ vehicle["responsibles"][column.split(".")[1]] }}
                  </span>
                  <span
                    class="pointer"
                    *ngIf="column === 'actions'"
                    (click)="$event.stopPropagation(); deleteVehicle(vehicle)"
                  >
                    <i class="fa fa-trash"></i>
                  </span>
                </td>
              </tr>
              <tr *ngIf="app.vehicleResponsible.ids.length === 0">
                <td colspan="99" class="text-center">
                  {{ app.text.app.noResults }}
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
      <div *ngIf="app.customers.expectCurrent === 'db'">
        <div class="tableFixHead">
          <table class="table table-bordered table-striped">
            <thead class="text-center">
              <tr class="departmentRow">
                <th></th>
                <th></th>
                <th colspan="2" class="departmentNames">FV</th>

                <th colspan="2" class="departmentNames">Regio</th>
                <th colspan="2" class="departmentNames">Cargo</th>
                <th colspan="2" class="departmentNames">Sonstige</th>
                <th colspan="2" class="departmentNames">FZI</th>
              </tr>
              <tr>
                <th>{{ app.field.getLabel("impact.omfVehicleName") }}</th>
                <th>
                  {{ app.field.getLabel("vehicleResponsible.vehicleFleet") }}
                </th>

                <th>BAV</th>
                <th>MaWI</th>

                <th>BAV</th>
                <th>MaWI</th>

                <th>BAV</th>
                <th>MaWI</th>

                <th>BAV</th>
                <th>MaWI</th>

                <th>BAV</th>
                <th>MaWI</th>
              </tr>
              <tr>
                <th *ngFor="let column of columnsShort">
                  <div>
                    <app-filter-table
                      [column]="column"
                      [columns]="columnsShort"
                      [docType]="'vehicleResponsible'"
                      [cleanDocs]="app.vehicleResponsible.cleanVehicles"
                      [selectedFilters]="selectedFilters"
                      (resultedDocs)="
                        app.filterTable.getFilterResults(
                          $event,
                          'vehicleResponsible'
                        )
                      "
                    >
                    </app-filter-table>
                  </div>
                </th>
              </tr>
            </thead>
            <tbody>
              <tr
                *ngFor="let vehicle of app.paginator.getPageWithDoc()"
                (click)="
                  seeVehicleDetails.open();
                  app.vehicleResponsible.id = vehicle._id
                "
                class="pointer"
              >
                <td class="p-1 text-center">
                  {{ vehicle["vehicleName"] }}
                </td>
                <td class="p-1 text-center">
                  {{ vehicle["vehicleFleet"] }}
                </td>
                <td class="p-1 text-center">
                  {{ vehicle.responsibles["bavFv"] }}
                </td>
                <td class="p-1 text-center">
                  {{ vehicle.responsibles["mawiFv"] }}
                </td>
                <td class="p-1 text-center">
                  {{ vehicle.responsibles["bavRegio"] }}
                </td>
                <td class="p-1 text-center">
                  {{ vehicle.responsibles["mawiRegio"] }}
                </td>
                <td class="p-1 text-center">
                  {{ vehicle.responsibles["bavCargo"] }}
                </td>
                <td class="p-1 text-center">
                  {{ vehicle.responsibles["mawiCargo"] }}
                </td>
                <td class="p-1 text-center">
                  {{ vehicle.responsibles["bavNetz"] }}
                </td>
                <td class="p-1 text-center">
                  {{ vehicle.responsibles["mawiNetz"] }}
                </td>
                <td class="p-1 text-center">
                  {{ vehicle.responsibles["bavFzi"] }}
                </td>
                <td class="p-1 text-center">
                  {{ vehicle.responsibles["mawiFzi"] }}
                </td>
              </tr>
              <tr>
                <td
                  *ngIf="app.paginator.getPageWithDoc().length === 0"
                  colspan="99"
                  class="text-center"
                >
                  {{ app.text.app.noResults }}
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
      <app-paginator
        [length]="app.vehicleResponsible.ids.length"
        [index]="app.paginator.focus"
        (focus)="app.paginator.focus = $event"
      ></app-paginator>
    </div>
  </div>
</div>
