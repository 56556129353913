import { Component, Input } from "@angular/core";
import { getApp } from "../app";

@Component({
  selector: "app-cell",
  templateUrl: "./cell.component.html",
  styleUrls: ["./cell.component.scss"],
})
export class CellComponent {
  app = getApp((app) => {
    this.app = app;
  });
  @Input() field: string | null = null;
  @Input() doc: string | null = null;

  constructor() {}

  get historyFieldId() {
    const { app, doc } = this;
    return app.field.getValue(app.fieldId.history.fieldId, doc);
  }

  get historyFieldValue() {
    const { app, doc } = this;
    return app.field.getValue(app.fieldId.history.newValue, doc);
  }

  get historyModel() {
    const { historyFieldId, historyFieldValue } = this;
    return { [historyFieldId]: historyFieldValue };
  }

  deleteImpact(doc: any) {
    if (doc === null && doc === undefined) {
      return;
    } else {
      this.app.impact.delete(doc);
    }
  }

  // async isVehicle(field: string, impactId: string) {
  //   console.log(field, impactId)
  //   // const currentDoc = await this.app.impact.getImpactById(impactId)
  //   // console.log(currentDoc)
  //   const [type, fieldName] = field.split(".");
  //   console.log(this.app.field.getValue(field, impactId))
  //   return this.app.field.getValue(field, impactId)
  //   // return (
  //   //   type === "impact" &&
  //   //   this.app.field.getType(field) === "options" &&
  //   //   !fieldName.includes("omfVehicle")
  //   // );
  // }
}
