<div>
  <button
    class="btn btn-sm btn-default-orange pull-right mb-3"
    (click)="routeToNewCase()"
    *ngIf="!partRow && canCreateCase"
    [title]="app.text.thread.create"
  >
    <i class="far fa-folder-plus pointer"></i>&nbsp;
    {{ app.text.app.add }}
  </button>
</div>
<div class="tableFixHead clear">
  <table class="table spacing-table table-striped">
    <thead>
      <!-- <th class="text-center" *ngFor="let field of app.list.thread.boxesOnListOfOpenedCases">{{ app.field.getLabel("part.partNumber") }} {{field}}</th> -->
      <th
        class="text-center"
        *ngFor="let field of app.list.thread.boxesOnListOfOpenedCases"
      >
        <!-- {{ app.field.getLabel(field) }} -->
        {{ app.field.getTableLabel(field) }}
      </th>
    </thead>
    <tbody>
      <tr *ngFor="let thread of app.thread.threadsByPartNumber" class="pointer">
        <td *ngFor="let column of columns" class="text-center">
          <span>
            <a
              (click)="routeToThreadDetails(thread._id)"
              *ngIf="column !== 'omfStatus'"
            >
              {{ thread[column] }}
            </a>
            <a *ngIf="column === 'omfStatus'">
              {{ app.field.getOptionText("thread." + column, thread[column]) }}
            </a>
          </span>
        </td>
      </tr>
    </tbody>
  </table>
</div>
