export async function getFileContentAsArrayBuffer(file: File | Blob) {
  return new Promise<ArrayBuffer>((resolve, reject) => {
    const reader = new FileReader();
    reader.onload = (event: Event) => {
      const result: ArrayBuffer = (<any>event.target).result;

      resolve(result);
    };
    reader.readAsArrayBuffer(file);
  });
}

export async function getFileContentAsString(file: File) {
  return new Promise<string>((resolve, reject) => {
    const reader = new FileReader()
    reader.onload = (event: Event) => {
      const result: string = (<any>event.target).result
      resolve(result)
    }
    reader.readAsText(file)
  });
}