<div class="col-sm-12 px-1">
  <div class="d-flex justify-content-center">
    <button
      class="btn btn-reset col text-center m-4"
      [disabled]="!enableReset"
      (click)="resetFilter()"
    >
      <i class="fas fa-redo-alt"></i>
      {{ app.text.RM.resetFilter }}
    </button>
  </div>
  <div *ngFor="let field of fieldsOnFilter; let i = index" class="m-2 mt-0">
    <div
      class="filter-header"
      *ngIf="app.field.isVisibleInFilter(field) && !isAssemblySelected(field)"
    >
      <div
        class="px-2 py-3"
        (click)="isCollapsed[i] = !isCollapsed[i]"
        [attr.aria-expanded]="!isCollapsed[i]"
      >
        {{ app.field.getLabel(field) }}
        <span class="float-end">
          <i *ngIf="!isCollapsed[i]" class="far fa-chevron-down"></i>
          <i *ngIf="isCollapsed[i]" class="far fa-chevron-up"></i>
        </span>
        <div
          class="filter-selected-text"
          *ngIf="
            app.filter.isSelected(field, app.filterTree.filters) &&
            !isCollapsed[i]
          "
        >
          {{ app.text.RM.selectedFilter }}
          <span>{{ app.filterTree.getSelectedOptions(field) }} </span>
        </div>
      </div>
      <div
        [ngSwitch]="app.filterTree.getFieldType(field)"
        [ngbCollapse]="!isCollapsed[i]"
      >
        <!-- special case for part.partNumber since it has to search through all assemblies -->
        <div *ngSwitchCase="partNumber" class="filter-section">
          <div>
                  <app-field
        [field]="app.fieldId.part.partNumber"
        [showLabel]="false"
        [showMandatory]="true"
        [typeaheadPlaceholder]="app.text.RM.filter"
      >
      </app-field>
            <!-- <input
              type="text"
              class="form-control search-input"
              [(ngModel)]="assemblyValue"
              (input)="searchInAssemblyList()"
              placeholder="{{ app.text.RM.filterByAssembly }}"
            /> -->
            <!-- <div class="assemblies">
              <div *ngFor="let assembly of assemblies">
                <input
                  #checkboxes
                  type="checkbox"
                  class="filter-input"
                  value="{{ assembly.cpn }}"
                  [checked]="assembly.isChecked"
                  (click)="app.filterTree.filter(field, assembly.cpn)"
                  (change)="app.filterTree.onChangeAssembly($event)"
                />
                <span class="label">{{ assembly.cpn }}</span>
              </div>
            </div> -->
          </div>
        </div>
        <!-- when the type of the field is input -->
        <!-- <div *ngSwitchCase="input" class="filter-section">
          <div class="input-group">
            <input
              type="text"
              class="form-control search-input"
              [(ngModel)]="model[field]"
              (keydown.enter)="app.filterTree.filter(field, model[field])"
              placeholder="{{ app.text.RM.search }}"
            />
            <button class="btn search-button">
              <i
                class="fa fa-search"
                (click)="app.filterTree.filter(field, model[field])"
              ></i>
            </button>
          </div>
          <div
            class="filter-selected-text"
            *ngIf="app.filterTree.isSelected(field)"
          >
            {{ app.text.RM.selectedFilter }}
            <span>{{ model[field] }}</span>
          </div>
        </div> -->
        <!-- on the tree view, we are displaying different options for obsolescenceStatusOptions  -->
        <div *ngSwitchCase="specialStatus" class="filter-section">
          <div *ngFor="let status of obsolescenceStatusOptions">
            {{ option }}
            <input
              #checkboxes
              type="checkbox"
              class="filter-input"
              value="{{ status }}"
              [checked]="
                app.filter.isChecked(field, status, app.filterTree.filters)
              "
              (click)="app.filterTree.filter(field, status)"
            />
            <span class="label">{{
              app.filterTree.getOptionText(field, status)
            }}</span>
          </div>
        </div>
        <!-- default is option type -->
        <div *ngSwitchDefault class="filter-section">
          <div *ngFor="let option of app.field.getOptions(field)">
            <input
              #checkboxes
              type="checkbox"
              class="filter-input"
              value="{{ option }}"
              [checked]="
                app.filter.isChecked(field, option, app.filterTree.filters)
              "
              (click)="app.filterTree.filter(field, option)"
            />
            <span class="label">{{
              app.field.getOptionText(field, option)
            }}</span>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
