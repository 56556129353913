import { TableDef } from "pdfmake/build/pdfmake";
import { getApp } from "../../../app/app";

// export const head = (appType: "pcngen" | "omc" = "pcngen") => {
//   const app = getApp();
//   if (appType === "omc") {
//     return (data: MasterData): TableDef => {
//       return {
//         table: {
//           body: [[{ text: "One" }, { text: "two" }, { text: "three" }]]
//         }
//       };
//     };
//   } else if (appType === "pcngen") {
//     return {
//       table: {
//         widths: ["*"],
//         body: [
//           [
//             {
//               text: app.text.pdf.title,
//               alignment: "center",
//               fontSize: 14,
//               bold: true,
//               margin: [0, 8, 0, 8],
//               border: [true, true, true, false]
//             }
//           ]
//         ]
//       }
//     };
//   }
// };

export const head = (): TableDef => {
  const app = getApp();
  return {
    table: {
      widths: ["*"],
      body: [
        [
          {
            text: app.text.pdf.title,
            alignment: "center",
            fontSize: 14,
            bold: true,
            margin: [0, 8, 0, 8],
            border: [true, true, true, false],
          },
        ],
      ],
    },
  };
};
