import { ComponentSettings } from "../settings/settings";

const settings = {
  permission: {},
  text: {
    noResults: {
      de: "Keine Ergebnisse gefunden",
      en: "No results found",
      nl: "Geen resultaten gevonden.",
    },
    loading: {
      de: "Daten werden geladen... Bitte warten Sie.",
      en: "Loading data... Please wait.",
      nl: "Data wordt geladen... een moment geduld...",
    },
  },
  list: {},
  field: {},
  url: {},
};

export const core: ComponentSettings<typeof settings> = settings;
