import { ComponentSettings } from "../settings/settings";
import { GraphName } from "../types/graphName";

const settings = {
  permission: {
    view: false,
  },
  text: {
    january: {
      de: "Januar",
      en: "January",
    },
    february: {
      de: "Februar",
      en: "February",
    },
    march: {
      de: "März",
      en: "March",
    },
    april: {
      de: "April",
      en: "April",
    },
    may: {
      de: "Mai",
      en: "May",
    },
    june: {
      de: "Juni",
      en: "June",
    },
    july: {
      de: "Juli",
      en: "July",
    },
    august: {
      de: "August",
      en: "August",
    },
    september: {
      de: "September",
      en: "September",
    },
    october: {
      de: "Oktober",
      en: "October",
    },
    november: {
      de: "November",
      en: "November",
    },
    december: {
      de: "Dezember",
      en: "December",
    },
    title: { de: "Statistik", en: "Charts", nl: "Grafieken" },
    unknown: {
      de: "Unbekannt",
      en: "Unknown",
    },
    number: {
      de: "Anzahl",
      en: "Number",
    },
    vehicle: {
      de: "Fälle pro Fahrzeug",
      en: "Cases per Vehicle",
      nl: "Issues per Materieelserie",
    },
    dinCode: {
      de: "Auswirkung nach DIN-Code",
      en: "Impact for each DIN-code",
      nl: "Impact per DIN-code",
    },
    fleet: {
      de: "Auswirkung nach Flotte",
      en: "Impact per fleet",
      nl: "Impact per vloot",
    },
    status: {
      de: "Fälle nach Status",
      en: "Cases according to status",
      nl: "Issues per status",
    },
    department: {
      de: "Fälle pro Abteilung",
      en: "Cases per Department",
      nl: "Issues per afdeling",
    },
    responsible: {
      de: "Fälle pro Verantwortlicher",
      en: "Cases per Responsible",
      nl: "Issues per OM-aanspreekpunt",
    },
    totalCostLTB: {
      de: "LNB Gesamtkosten",
      en: "LNB Total Costs",
    },
    omapproachesCurrentYear: {
      de: "OM-Ansätze (Aktuelles Jahr)",
      en: "OM-Approaches (Current Year)",
      nl: "OM-Benaderingen (Huidig Jaar)",
    },
    omapproachesPerYear: {
      de: "OM-Ansätze (Jährlich)",
      en: "OM-Approaches (Per Year)",
      nl: "OM-Benaderingen",
    },
    solutionsCurrentYear: {
      de: "Ausgewählte Lösungen (Aktuelles Jahr)",
      en: "Selected Solutions (Current Year)",
      nl: "Geselecteerde Afhandelstrategieën (Huidig Jaar)",
    },
    solutionsPerYear: {
      de: "Ausgewählte Lösungen (Gesamt)",
      en: "Selected Solutions (Overall)",
      nl: "Geselecteerde Afhandelstrategieën (Totaal)",
    },
    sortBy_alphabetic: {
      de: "alphabetisch",
      en: "alphabetic",
      nl: "alfabetisch",
    },
    sortBy_impacts: {
      de: "Auswirkungen",
      en: "impacts",
      nl: "Impact",
    },
    sortBy: {
      de: "sortieren nach: ",
      en: "sort by: ",
      nl: "gesorteerd naar: ",
    },
    closed: {
      de: "Geschlossen",
      en: "Closed",
      nl: "Gesloten",
    },
    open: {
      de: "Offen",
      en: "Open",
      nl: "Open",
    },
    others: {
      de: "Sonstige",
      en: "Others",
      nl: "Overige",
    },
    quantity: {
      de: "Anzahl",
      en: "Number",
      nl: "Nummer",
    },
  },
  list: {
    graphs: [
      GraphName.Vehicles,
      GraphName.DinCodes,
      GraphName.Fleet,
      GraphName.Status,
      GraphName.Departments,
      GraphName.Responsibles,
      GraphName.OMApproachesCurrentYear,
      GraphName.OMApproachesPerYear,
    ],
    vehicles: [""],
  },
  url: {},
  field: {},
};

export const graphs: ComponentSettings<typeof settings> = settings;
