<div class="row">
  <div class="col-sm-12 col-md-12 col-xl-12">
    <app-paginator
      [length]="app.users.userIds.length"
      [index]="app.paginator.focus"
      (focus)="app.paginator.focus = $event"
    ></app-paginator>
    <div>
      <div
        class="tableFixHead"
        [ngClass]="{ tableHeight: app.filterTable.isDropdownOpen }"
      >
        <table class="table table-striped spacing-table">
          <thead>
            <tr class="pointer">
              <th class="p-2">No.</th>
              <th class="p-2" *ngFor="let column of columnList">
                <span *ngIf="column === 'active'">
                  {{ app.field.getLabel("user." + column) }}</span
                >
                <span
                  *ngIf="column !== 'active'"
                  (click)="
                    app.table.toggleSortDirection('user.' + column);
                    app.table.sort(app.users.users)
                  "
                >
                  {{ app.field.getLabel("user." + column) }}</span
                >&nbsp;
                <span *ngIf="'user.' + column === app.table.sortField">
                  <i
                    *ngIf="app.table.sortDirection === 1"
                    class="fa fa-chevron-up"
                  ></i>
                  <i
                    *ngIf="app.table.sortDirection === -1"
                    class="fa fa-chevron-down"
                  ></i>
                </span>
                <div *ngIf="app.filterTable.getFilterTableColumns(column)">
                  <app-filter-table
                    [column]="column"
                    [columns]="columnList"
                    [docType]="'user'"
                    [cleanDocs]="app.users.cleanUsers"
                    [selectedFilters]="selectedFilters"
                    (resultedDocs)="
                      app.filterTable.getFilterResults($event, 'users')
                    "
                  >
                  </app-filter-table>
                </div>
              </th>
            </tr>
          </thead>
          <tbody>
            <tr
              *ngFor="
                let user of app.paginator.getPage(app.users.users);
                let i = index
              "
              [ngClass]="{ isSelected: app.isSelected(user) }"
              (click)="checkPermission(user.name)"
              class="pointer"
            >
              <td class="px-2 py-1">{{ i + 1 }}</td>
              <td class="px-2 py-1" *ngFor="let column of columnList">
                <span *ngIf="column !== 'roles' && column !== 'active'">
                  {{
                    app.field.getFieldValueAsText(
                      "user." + column,
                      user[column]
                    )
                  }}
                </span>
                <span *ngIf="column === 'roles'">
                  <span *ngFor="let role of user[column]">
                    {{
                      app.field.getOptionText(
                        "user." + column,
                        getUserRole(role)
                      )
                    }}
                  </span>
                </span>
                <span *ngIf="column === 'active'">
                  <i *ngIf="!user[column]" class="fas fa-circle offline"></i>
                  <i *ngIf="user[column]" class="fas fa-circle online"></i>
                </span>
              </td>
            </tr>
            <tr
              *ngIf="app.users.userIds.length === 0"
              colspan="99"
              class="text-center"
            >
              <td>
                {{ app.text.app.noResults }}
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
    <app-paginator
      [length]="app.users.userIds.length"
      [index]="app.paginator.focus"
      (focus)="app.paginator.focus = $event"
    ></app-paginator>
  </div>
</div>
