import { Component, OnInit } from "@angular/core";
import { getApp } from "../../app";

@Component({
  selector: "app-import-stepper",
  templateUrl: "./import-stepper.component.html",
  styleUrls: ["./import-stepper.component.scss"],
})
export class ImportStepperComponent implements OnInit {
  step: "first" | "second" | "third" | "fourth" | "fifth" | "error" = "first";

  app = getApp((app) => {
    this.app = app;
  });

  constructor() {}

  ngOnInit(): void {
    this.app.import.step = "first";
  }

  reset() {
    this.app.import.step = "first";
    this.app.import.stepper = "selectType";
    this.app.import.type = null;
    this.app.import.docs = [];
    this.app.import.importedKeys.clear();
    sessionStorage.setItem("clearFiles", "true");
  }

  next() {
    this.app.import.importMessages = [];
    switch (this.app.import.step) {
      case "first":
        this.app.import.step = "second";
        this.app.import.stepper = "uploadfile";
        this.app.import.invalidKeys = [];
        break;
      case "second":
        this.app.import.step = "third";
        break;
      case "third":
        this.app.import.step = "first";
        this.app.import.stepper = "selectType";
        break;
      case "error":
        this.app.import.step = "error";
        break;
    }
  }

  previous() {
    this.app.import.importMessages = [];
    switch (this.app.import.step) {
      case "second":
        this.app.import.docs = [];
        this.app.import.step = "first";
        this.step = "first";
        break;
      case "third":
        this.app.import.docs = [];
        this.app.import.step = "second";
        this.step = "second";
        break;
      case "error":
        this.app.import.invalidKeys = [];
        this.app.import.docs = [];
        this.app.import.step = "second";
        this.step = "second";
        break;
    }
  }
}
