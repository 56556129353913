<app-modal
  #discardChangesModal
  [modalMessage]="app.text.manufacturer.discard"
  [modalTitle]="app.text.manufacturer.modalTitle"
  (ok)="discardChanges()"
></app-modal>

<app-modal
  #cancelOverrideModeModal
  [modalMessage]="app.text.manufacturer.cancelOverride"
  [modalTitle]="app.text.manufacturer.modalTitle"
  (ok)="app.manufacturer.cancelOverrideMode()"
></app-modal>

<app-modal
  #replacementsModal
  [modalTitle]="app.text.manufacturer.crossesAndReplacements"
  [showButtons]="false"
  [modalTitle]="app.text.manufacturer.crossesAndReplacements"
  [modalType]="'replacements'"
></app-modal>

<app-modal
  #svhcModal
  [modalTitle]="app.text.manufacturer.svhcDetailsModalTitle"
  [showButtons]="false"
  [modalType]="'svhcItems'"
></app-modal>
<app-modal
  #pcnHistoryModal
  [modalTitle]="app.text.manufacturer.pcnHistory"
  [showButtons]="false"
  [modalType]="'pcnHistory'"
></app-modal>
<app-history
  #historyModal
  [historyModalTitle]="app.text.history.manufacturerHistory"
></app-history>

<div class="col-12 no-padding">
  <!-- sub-section buttons -->
  <div class="sub-title">
    <div class="control-bar">
      <button
        class="btn btn-control p-2"
        (click)="app.routing.closeTab()"
        [title]="app.text.thread.close"
      >
        <i class="far fa-arrow-left"></i> &nbsp; {{ app.text.app.backToList }}
      </button>
    </div>
    <!-- manufacturer details -->
    <div class="container-fluid">
      <div class="row no-gutters">
        <div class="nested-wrapper1 ps-20">
          <div class="card custom-card my-3">
            <div class="card-header py-2">
              <span class="pt-2">
                {{ app.text.part.mpnDetails }}
              </span>
              <!-- Manufacturer controls - edit -->
              <div class="float-end">
                <app-unlock
                  [id]="app.manufacturer.id"
                  [permission]="app.manufacturer.hasEditPermission()"
                  [title]="app.text.manufacturer.edit"
                >
                  <span
                    *ngIf="
                      app.manufacturer.modelHasChanges(
                        app.manufacturer.cleanManufacturer,
                        app.manufacturer.currentManufacturer
                      );
                      then thenBlock;
                      else elseBlock
                    "
                  >
                  </span>
                  <!-- then block - edit manufacturer and there are changes  -->
                  <ng-template #thenBlock>
                    <button
                      *ngIf="
                        app.manufacturer.modelHasChanges(
                          app.manufacturer.cleanManufacturer,
                          app.manufacturer.currentManufacturer
                        )
                      "
                      class="btn btn-save btn-sm"
                      (click)="app.manufacturer.save()"
                      [title]="app.text.manufacturer.save"
                    >
                      <i class="fa fa-check"></i> {{ app.text.app.save }}
                    </button>
                    <button
                      class="btn btn-sm btn-clean"
                      (click)="discardChangesModal.open()"
                      [title]="app.text.manufacturer.discard"
                    >
                      <i class="fas fa-times close-icon"></i>&nbsp;
                      {{ app.text.post.cancel }}
                    </button>
                  </ng-template>
                  <!-- #else block - edit manufacturer and there are no changs  -->
                  <ng-template #elseBlock>
                    <button class="btn btn-save-disabled btn-sm">
                      <i class="fa fa-check"></i> {{ app.text.app.save }}
                    </button>
                    <button
                      class="btn btn-sm btn-clean"
                      (click)="app.unlockedId = null"
                      [title]="app.text.manufacturer.close"
                    >
                      <i class="fas fa-times close-icon"></i>&nbsp;
                      {{ app.text.post.cancel }}
                    </button>
                  </ng-template>
                </app-unlock>
              </div>
            </div>
            <div class="border-top details-box py-1">
              <div class="ps-3">
                <div>
                  <span>
                    {{
                      app.field.getLabel(app.fieldId.manufacturer.partNumber)
                    }}:&nbsp;</span
                  >
                  <a
                    class="links pointer"
                    (click)="
                      app.part.navigateToPartDetails(
                        app.manufacturer.currentManufacturer[
                          'manufacturer.partNumber'
                        ]
                      )
                    "
                  >
                    {{
                      app.manufacturer.currentManufacturer[
                        "manufacturer.partNumber"
                      ]
                    }}
                  </a>
                </div>
                <div
                  *ngFor="let field of subTitleFieldsList"
                >
                  <span>{{ app.field.getLabel(field) }}:&nbsp;</span>
                  {{ app.manufacturer.currentManufacturer[field] }}
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="controls-wrapper">
          <span>
            <a
              class="btn btn-sm btn-clean"
              [ngClass]="{ 'my-3': app.unlockedId != null }"
              (click)="
                app.treeRow.navigateToMatchingPage(null, manufacturerObject)
              "
              *ngIf="app.permission.manufacturer.canMatch"
            >
              <div *ngIf="status === 'matched'">
                <i class="far fa-link pointer"></i>&nbsp;&nbsp;{{
                  app.text.RM.matched
                }}
              </div>
              <div *ngIf="status === 'manual'">
                <i class="fa fa-user-circle pointer"></i>&nbsp;&nbsp;{{
                  app.text.RM.manual
                }}
              </div>
              <div *ngIf="status === 'unmatched'">
                <i class="far fa-unlink pointer"></i>&nbsp;&nbsp;{{
                  app.text.RM.unmatched
                }}
              </div>
              <div *ngIf="status === 'override'">
                <i class="far fa-hand-paper pointer"></i>&nbsp;&nbsp;{{
                  app.text.RM.override
                }}
              </div>
              <div *ngIf="status === 'manualAssignment'">
                <i class="fas fa-user-edit pointer"></i>&nbsp;&nbsp;{{
                  app.text.matchingManufacturer.edited
                }}
              </div>
            </a>
          </span>
          <button
            *ngIf="app.unlockedId == null"
            class="btn btn-sm btn-clean my-3"
            (click)="historyModal.open()"
          >
            <i class="fas fa-archive"></i>&nbsp;
            {{ app.getText("part.history") }}
          </button>
          <button
            *ngIf="app.unlockedId == null"
            class="btn btn-sm btn-clean btn-blue my-3"
            (click)="replacementsModal.open()"
          >
            <i class="fas fa-exchange-alt"></i>&nbsp;
            {{ app.text.manufacturer.crossesAndReplacements }}
          </button>
          <span *ngIf="app.permission.RM.createCase && app.unlockedId == null">
            <button
              *ngIf="
                !app.thread.getCase(currentModel) &&
                currentModel.manufacturerPartNumber !== ''
              "
              class="btn btn-sm btn-clean btn-blue my-3"
              (click)="app.thread.newCase(currentModel)"
            >
              <i class="far fa-folder-plus"></i>&nbsp;
              {{ app.text.alert.createThread }}
            </button>
            <button
              *ngIf="app.thread.getCase(currentModel)"
              class="btn btn-sm btn-clean btn-blue my-3"
              (click)="
                seeOpenedCasesModal.open();
                app.manufacturersFilter.currentManufacturer = currentModel;
                app.thread.getCasesByPartNumber(currentModel)
              "
            >
              <i class="fas fa-folder-open"></i>
              &nbsp;
              {{ app.text.alert.createThread }}
            </button>
          </span>
        </div>
      </div>
    </div>
  </div>
</div>
<div class="container-fluid">
  <div class="wrapper mb-3">
    <!-- left side content-->
    <div class="nested-wrapper1">
      <div class="content-box">
        <!-- left side menu navigation -->
        <div *ngIf="app.unlockedId == null">
          <app-left-nav
            [listBoxes]="boxesLeft"
            [lastUpdatedOn]="
              app.manufacturer.currentManufacturer['manufacturer.lastUpdatedOn']
            "
            [showImage]="false"
          >
          </app-left-nav>
        </div>

        <div *ngIf="app.unlockedId !== null">
          <app-left-nav [listBoxes]="boxesLeftEditMode" [showImage]="false">
          </app-left-nav>
        </div>

        <div class="mb-3">
          <!-- manufacturer content-->
          <div *ngFor="let box of app.list.manufacturer.boxesLeft">
            <app-frame
              [showHeader]="false"
              *ngIf="
                app.leftNav.selectedBox === box &&
                box !== 'manufacturer.history' &&
                box !== 'manufacturer.supplyChainSection'
              "
            >
              <div *ngIf="box != null">
                <app-field
                  *ngFor="
                    let field of app.manufacturer.getListOfFields(
                      app.manufacturer.currentManufacturer,
                      box
                    );
                    index as index
                  "
                  [model]="app.manufacturer.currentManufacturer"
                  [field]="field"
                  [index]="index"
                  [disabled]="app.isLocked(app.manufacturer.id)"
                  (click)="getFieldAction()"
                  (input)="
                    app.manufacturer.getInputChanges(
                      app.manufacturer.currentManufacturer,
                      field
                    )
                  "
                ></app-field>
              </div>
            </app-frame>
            <div
              *ngIf="
                app.leftNav.selectedBox === box &&
                box === 'manufacturer.supplyChainSection'
              "
            >
              <app-box
                [box]="app.listId.manufacturer.supplyChainSection"
                [model]="app.manufacturer.currentManufacturer"
                [unlock]="
                  app.manufacturer.currentManufacturer['manufacturer._id']
                "
                [showHeader]="false"
              ></app-box>
            </div>
            <div
              *ngIf="
                app.leftNav.selectedBox === box &&
                app.leftNav.selectedBox === 'manufacturer.history'
              "
            >
              <app-history></app-history>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- right side content-->
    <div class="nested-wrapper2">
      <!-- Image box -->
      <div class="image-box">
        <div class="card my-3">
          <div class="card-header d-flex justify-content-between">
            {{ app.text.part.image }}
            <input
              #fileInput
              type="file"
              accept="image/*"
              style="display: none"
              (change)="onUpload()"
            />

            <button
              *ngIf="
                (app.manufacturer.currentManufacturer['manufacturer.image'] ==
                  null ||
                  app.manufacturer.currentManufacturer['manufacturer.image'] ==
                    '') &&
                manualOverride
              "
              class="btn btn-sm"
              (click)="fileInput.click()"
              [title]="app.text.thread.uploadImage"
              [disabled]="app.unlockedId != null"
            >
              <i class="fal fa-file-alt icon-orange"></i>
            </button>
          </div>
          <app-left-nav
            [imageSource]="app.manufacturer.externalImage"
            [showBoxes]="false"
            [showImageControlBtn]="
              app.manufacturer.hasPermissionToDeleteImage && manualOverride
            "
            [docType]="'manufacturer'"
            [file]="app.manufacturer.getImage()"
          >
          </app-left-nav>
        </div>
      </div>
      <!-- messages -->
      <div class="message-box">
        <app-box
          [box]="'rm-messages'"
          [model]="app.model"
          [unlock]="manufacturerId"
        ></app-box>
      </div>
    </div>
  </div>
</div>
<app-modal
  #seeOpenedCasesModal
  [modalTitle]="app.text.thread.threads"
  [modalType]="'showListOfCases'"
  [showButtons]="false"
>
</app-modal>
