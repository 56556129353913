import { Component, Input, OnDestroy, OnInit, ViewChild } from "@angular/core";
import { getApp } from "../app";
import { Row } from "../tree/tree.util";
import { Subscription } from "rxjs";

@Component({
  selector: "app-tree-row",
  templateUrl: "./tree-row.component.html",
  styleUrls: ["./tree-row.component.scss"],
})
export class TreeRowComponent implements OnInit, OnDestroy {
  @ViewChild("seeStructureModal", { static: false }) seeStructureModal: any;

  app = getApp((app) => {
    this.app = app;
  });
  @Input() row: Row | null = null;
  @Input() index: number = 0;

  isPart: boolean = false;
  isManufacturer: boolean = false;
  tableColumns: string[] = [];
  getCase: boolean = false;
  manufacturerStatus: string = "";
  statusAlertInfo: {
    column: string;
    showIcon: boolean;
    tooltipText: string;
  } = { column: "", showIcon: false, tooltipText: "" };
  activeMPNs: number = 0;

  private _sectionSubscription: Subscription = new Subscription();

  constructor() {}

  ngOnInit() {
    this._sectionSubscription = this.app.RMSubmenu.sectionSubject.subscribe(
      () => {
        this.init();
      }
    );
  }

  init() {
    this.getTableColumns();
    this.getRowTypes();
    this.getStatusTextAndColor();
    this.getStatusAletInfo();
    if (this.row != null) {
      this.getCase = this.app.treeRow.getCase(this.row);
    }
    this.getManufacturerStatus();
    this.app.treeRow.isOpenRow(this.row);
    this.app.treeRow.isLeafRow(this.row);
    if (this.index + 1 === this.app.tree.items.length) {
      this.app.tree.loadingTreeRows = false;
    }
    if (this.row != null && this.row.node != null) {
      this.activeMPNs = Object.keys(this.row.node.part["parts"]).length;
    }
  }

  getRowTypes() {
    this.isPart = this.app.tree.isPartRow(this.row);
    this.isManufacturer = this.app.tree.isManufacturerRow(this.row);
  }

  getTableColumns() {
    this.tableColumns = this.app.tree.currentColumns.map(
      (c: string) => c.split(".")[1]
    );
  }

  getManufacturerStatus() {
    if (this.isManufacturer && this.row && this.row.manufacturer) {
      this.manufacturerStatus =
        this.app.manufacturer.getMatchingStatusOfManufacturer(
          this.row.manufacturer
        );
    }
  }

  getStatusTextAndColor() {
    this.tableColumns.forEach((column: string) => {
      //badge column type
      if (this.getColumnType(column) === "badge") {
        if (this.isPart) {
          if (
            this.row &&
            this.row.node &&
            typeof this.row.node.part[column] === "string"
          ) {
            this.row.node.part[column] = this.insertDisplayInfo(column);
          }
        }
        if (this.isManufacturer) {
          if (
            this.row &&
            this.row.manufacturer &&
            typeof this.row.manufacturer[column] === "string"
          ) {
            this.row.manufacturer[column] = this.insertDisplayInfo(column);
          }
        }
      }

      //optionText column type
      if (this.getColumnType(column) === "optionText") {
        if (this.isPart) {
          if (this.row && this.row.node) {
            this.row.node.part[column] = this.app.field.getOptionText(
              "part." + column,
              this.row.node.part[column]
            );
          }
        }
        if (this.isManufacturer) {
          if (this.row && this.row.manufacturer) {
            if (column === "productCategory") {
              if (this.row.manufacturer.taxonomy) {
                this.row.manufacturer[column] = this.app.field.getOptionText(
                  this.app.fieldId.manufacturer.taxonomy,
                  this.row.manufacturer.taxonomy
                );
              }
            } else {
              this.row.manufacturer[column] = this.app.field.getOptionText(
                "manufacturer." + column,
                this.row.manufacturer[column]
              );
            }
          }
        }
      }
    });
  }

  insertDisplayInfo(column: string) {
    if (this.isPart) {
      if (this.row != null && this.row.node) {
        return {
          field: column,
          label: this.app.field.getOptionText(
            "part." + column,
            this.row.node.part[column]
          ),
          color: this.app.field.getColor(
            "part." + column,
            this.row.node.part[column]
          ),
        };
      }
    }
    if (this.isManufacturer) {
      if (this.row && this.row.manufacturer) {
        return {
          field: column,
          label: this.app.field.getOptionText(
            "manufacturer." + column,
            this.row.manufacturer[column]
          ),
          color: this.app.field.getColor(
            "manufacturer." + column,
            this.row.manufacturer[column]
          ),
        };
      }
    }
  }
  getColumnType(column: string) {
    switch (column) {
      case "quantity":
        return "quantity";
      case "where-used":
        return "where-used";
      case "partsToVehicles":
        return "where-used";
      case "matchedSystem":
        return "matchedSystem";
      case "statusAlerts":
        return "statusAlerts";
      case "supplierPending":
        return "pending";
      case "activeMPNs":
        return "activeMPNs";
      default:
        return this.app.table.getColumnTypeDisplay(column);
    }
  }
  counter(i: number) {
    return new Array(i);
  }

  openWhereUsedModal(partNumber: string) {
    this.app.spinner.showSpinner();
    this.seeStructureModal.open();
    this.app.treeRow.generateImpactsFromParts(partNumber);
  }

  getStatusAletInfo() {
    let counter: number = 0;
    let showAlertIcon: boolean = false;
    let tooltipText: string = "";
    if (this.isPart) {
      if (
        this.row != null &&
        this.row.node != null &&
        this.row.node.part["statusAlerts"] != null
      ) {
        let partsCounter: number = 0;
        partsCounter = Object.keys(this.row.node.part["parts"]).length;

        switch (this.app.RMSubmenu.section) {
          case "status":
            counter = this.row.node.part["statusAlerts"].currentStatusCounter;
            if (counter > 0) {
              showAlertIcon = true;
            }
            if (partsCounter > 0) {
              tooltipText = `${counter} ${this.app.text.part.currentStatusAlert}`;
            }
            break;
          case "risk":
            counter = this.row.node.part["statusAlerts"].riskCounter;
            if (counter > 0) {
              showAlertIcon = true;
            }
            if (partsCounter > 0) {
              tooltipText = `${counter} ${this.app.text.part.riskStatusAlert}`;
            }
            break;
          case "likelihood":
            counter = this.row.node.part["statusAlerts"].likelihoodCounter;
            if (counter > 0) {
              showAlertIcon = true;
            }
            if (partsCounter > 0) {
              tooltipText = `${counter} ${this.app.text.part.likelihoodStatusAlert}`;
            }
            break;
          case "impact":
            counter = this.row.node.part["statusAlerts"].impactCounter;
            if (counter > 0) {
              showAlertIcon = true;
            }
            if (partsCounter > 0) {
              tooltipText = `${counter} ${this.app.text.part.impactStatusAlert}`;
            }
            break;
          case "rohs":
            counter = this.row.node.part["statusAlerts"].rohsCounter;
            if (counter > 0) {
              showAlertIcon = true;
            }
            if (partsCounter > 0) {
              tooltipText = `${counter} ${this.app.text.part.rohsStatusAlert}`;
            }
            break;
          case "reach":
            counter = this.row.node.part["statusAlerts"].reachCounter;
            if (counter > 0) {
              showAlertIcon = true;
            }
            if (partsCounter > 0) {
              tooltipText = `${counter} ${this.app.text.part.reachStatusAlert}`;
            }
            break;
          case "lead":
            counter = this.row.node.part["statusAlerts"].leadTimeCounter;
            if (counter > 0) {
              showAlertIcon = true;
            }
            if (partsCounter > 0) {
              tooltipText = `${counter} ${this.app.text.part.leadTimeStatusAlert}`;
            }
            break;
          default:
            counter = this.row.node.part["statusAlerts"].currentStatusCounter;
            if (counter > 0) {
              showAlertIcon = true;
            }
            if (partsCounter > 0) {
              tooltipText = `${counter} ${this.app.text.part.currentStatusAlert}`;
            }
            break;
        }
        this.statusAlertInfo = {
          column: this.app.RMSubmenu.section,
          showIcon: showAlertIcon,
          tooltipText: tooltipText,
        };
        return this.statusAlertInfo;
      }
    }
  }

  ngOnDestroy(): void {
    if (this._sectionSubscription) {
      this._sectionSubscription.unsubscribe();
    }
  }
}
