import { Component, OnInit } from "@angular/core";
import { getApp } from "../app";
import { Manufacturer, SeMatch } from "../../../../shared/models/manufacturer";
import { AlertData } from "../../../../shared/models/alert";
import { Customer } from "../../../../shared/types/customers";
import { DataProvider } from "../../../../shared/constants/dataProvider";
import { Z2DataMatch } from "../../../../shared/models/dataProviders/z2data";

@Component({
  selector: "app-case-controls",
  templateUrl: "./case-controls.component.html",
  styleUrls: ["./case-controls.component.scss"],
})
export class CaseControlsComponent implements OnInit {
  app = getApp((app) => {
    this.app = app;
  });

  constructor() {}
  ngOnInit() {}

  discardChanges() {
    this.app.thread.id = this.app.thread.id;
    /**if the checkbox is set it has to be cleaned on discard */
    this.app.thread.thread[this.app.fieldId.thread.criticalComponent] =
      this.app.thread.cleanModel[this.app.fieldId.thread.criticalComponent];

    this.resetExistingCases();
    this.app.unlockedId = null;
    this.app.thread.externalChanges = false;

    Object.assign(this.app.thread.thread, this.app.thread.cleanModel);
    if (this.app.customers.expectCurrent === Customer.KNDS) {
      this.app.field.manufacturerCodes = [];
    }
    if (this.app.thread.isNew) {
      this.app.treeRow.currentManufacturer = new Manufacturer();
      this.app.alerts.currentSelected = {} as AlertData;
      this.app.customers.dataProvider === DataProvider.Z2DATA
        ? (this.app.externalDataFilter.selectedMatch = new Z2DataMatch())
        : (this.app.externalDataFilter.selectedMatch = new SeMatch());

      //discard changes after user started to sync a doc
      if (this.app.sync.syncInfo.syncedFromCustomer != null) {
        this.app.thread.id =
          this.app.sync.syncInfo.syncedDoc["thread.omfNumber"];
        this.app.sync.clearTheSyncInfo();
      }
      if (this.app.pcn.pcn != null) {
        this.app.pcn.pcn = null;
        this.app.pcn.id = null;
      }
      //discard changes on a regular new thread
      else {
        this.app.thread.id = null;
      }
      this.app.thread.isNew = false;
      if (sessionStorage.manufacturer) {
        sessionStorage.removeItem("manufacturer");
      }
      this.app.routing.navigateBack();
    }
  }

  resetExistingCases() {
    if (this.app.thread.currentExistingCases.cpn != null) {
      this.app.thread.existingCasesPerCpn = [
        ...this.app.thread.currentExistingCases.cpn,
      ];
    }
    if (this.app.thread.currentExistingCases.mpn != null) {
      this.app.thread.existingCasesPerMpn = [
        ...this.app.thread.currentExistingCases.mpn,
      ];
    }
    if (this.app.thread.currentExistingCases.pcnId != null) {
      this.app.thread.existingCasesPerPcnId = [
        ...this.app.thread.currentExistingCases.pcnId,
      ];
    }
  }

  removeSessionSettings() {
    sessionStorage.removeItem("fromTree");
  }

  disabledSaveButton() {
    if (
      this.app.field.getInvalidFields("thread", this.app.thread.thread).length <
      1
    ) {
      return false;
    } else {
      return true;
    }
  }
}
