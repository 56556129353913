<div
  (click)="app.OCPost.openPost(post); app.routing.navigateOCPost(post._id)"
  class="card post-card pointer m-1 p-2"
  [ngClass]="{ 'selected-post': post === app.OCPost.selectedPost }"
>
  <!-- Details -->
  <!-- First line -->
  <div class="d-flex flex-wrap align-items-center">
    <img
      *ngIf="post.avatar != ''"
      class="profile-image-sm"
      target="_blank"
      [src]="app.file.getUrl(post.avatar)"
    />
    <img
      *ngIf="post.avatar === ''"
      class="profile-image-sm"
      target="_blank"
      [src]="app.url.profile.defaultImage"
    />
    <span *ngIf="!app.OCPost.postHasValidUserName(post)" class="text m-1"
      ><b>{{ post.user_id }}</b></span
    >
    <span *ngIf="app.OCPost.postHasValidUserName(post)" class="text m-1"
      ><b>{{ post.userFirstName }} {{ post.userLastName }}</b></span
    >
    <div *ngFor="let badge of post.userBadges">
      <i
        class="fa-solid fa-medal fa-fw"
        [style.color]="app.OCHome.setBadgeColor(badge)"
        [ngbTooltip]="app.OCHome.setBadgeTooltip(badge)"
      ></i>
    </div>
    <div
      class="credits tag p-1 ms-auto"
      [ngStyle]="
        app.OCPostItem.getTagColor(app.fieldId.ocPost.tagCategory, post.tag)
      "
    >
      {{ app.field.getOptionText(app.fieldId.ocPost.tagCategory, post.tag) }}
    </div>
  </div>
  <!-- Second line -->
  <div>
    <span class="text"
      ><b>{{ post.title }}</b></span
    >
  </div>
  <!-- 3th line -->
  <div
    class="text post-text"
    [innerHtml]="app.field.highlightLinks(post.message)"
  >
    {{ post.message }}
  </div>
  <!-- 4th line -->
  <div class="d-flex flex-wrap align-items-center">
    <!-- Commnets -->
    <div class="px-1">
      <i class="fa-regular fa-comment controls me-2"></i>
      <span class="controls-text">{{ post.counterComment }}</span>
    </div>
    <!-- Likes -->
    <div class="px-1">
      <i
        *ngIf="!post.isLikedByLoggedInUser"
        class="fa-regular fa-thumbs-up controls me-2"
      ></i>
      <i
        *ngIf="post.isLikedByLoggedInUser"
        class="fa-solid fa-thumbs-up controls me-2 liked"
      ></i>
      <span [ngClass]="{ liked: post.isLiked }" class="controls-text">
        {{ post.counterLikes }}
      </span>
    </div>
    <!-- Time info -->
    <div class="controls-text ms-auto">
      <span>Posted on {{ app.OCHome.getTimeInfo(post.create_time) }}</span>
      <span *ngIf="post.update_time != post.create_time"
        >&nbsp;Edited on {{ app.OCHome.getTimeInfo(post.update_time) }}</span
      >
    </div>
  </div>
</div>
