import { Settings } from "../components";
import { _ComponentSettings } from "../settings/settings";

export type UrlProxy = {
  [name in keyof Settings]: ComponentUrlProxy<Settings[name]["url"]>;
};

type ComponentUrlProxy<T> = {
  [name in keyof T]: string;
};

export function getUrlProxy(settings: Settings) {
  const proxy: any = {};
  Object.keys(settings).forEach((name) => {
    Object.defineProperty(proxy, name, {
      get: () => {
        const component: _ComponentSettings = (<any>settings)[name];
        return getComponentUrlProxy(component);
      },
    });
  });

  return <UrlProxy>proxy;
}

function getComponentUrlProxy<T extends _ComponentSettings>(component: T) {
  const proxy: any = {};
  const { url } = component;
  Object.keys(url).forEach((name) => {
    Object.defineProperty(proxy, name, {
      get: () => component.url[name],
    });
  });
  return <ComponentUrlProxy<T>>proxy;
}
