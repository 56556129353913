<ng-template #content let-modal class="history-modal">
  <div class="modal-header">
    <div class="col-md-12">
      <span class="modal-title">
        {{ historyModalTitle }}
      </span>
      <button class="btn pull-right" (click)="closeModal()">
        <i class="fa fa-times"></i>
      </button>
    </div>
  </div>
  <div class="modal-body">
    <app-paginator
      [length]="app.history.historyDocs.length"
      [index]="app.paginator.focus"
      (focus)="app.paginator.focus = $event"
    ></app-paginator>
    <div *ngIf="app.unlockedId == null">
      <div
        *ngFor="
          let historyItem of app.paginator.getPage(app.history.historyDocs)
        "
      >
        <div class="history-box col-sm-12">
          <!-- CREATE DOCS HISTORY -->
          <div
            *ngIf="historyItem.action === 'create'"
            class="col-sm-12 create-line"
          >
            <span class="user-name">{{ historyItem.user }}</span>
            <span>
              {{ app.history.displayCreateMessage(historyItem) }}
            </span>
            <span>{{ app.text.history.on }}</span>
            <span class="create-date">
              {{ app.history.getDateformat(historyItem.create_time) }}
            </span>
          </div>

          <!-- DELETE DOCS HISTORY -->

          <div *ngIf="historyItem.action === 'delete'">
            <div class="col-sm-12 delete-line">
              <span class="user-name">{{ historyItem.user }}</span>
              <span>
                {{ app.history.deleteDocText(historyItem) }}
              </span>
              <span>{{ app.text.history.on }}</span>
              <span class="create-date"
                >{{ app.history.getDateformat(historyItem.create_time) }}
              </span>
            </div>
          </div>

          <!-- EDIT DOCS HISTORY -->
          <div *ngIf="historyItem.action === 'edit'">
            <div class="col-sm-12 edit-line">
              <!-- Display user name only in cases which we need a different message than default -->
              <span *ngIf="!app.history.displayEditDefaultMessage(historyItem)">
                <span class="user-name">{{ historyItem.user }}</span>
              </span>

              <span> {{ app.history.displayEditMessage(historyItem) }} </span>

              <!-- Display date only in cases which we need a different message than default -->
              <span *ngIf="!app.history.displayEditDefaultMessage(historyItem)">
                <span>{{ app.text.history.on }}</span>
                <span class="create-date"
                  >{{ app.history.getDateformat(historyItem.create_time) }}
                </span>
              </span>
            </div>

            <table
              class="table"
              *ngIf="app.history.displayEditDefaultMessage(historyItem)"
            >
              <thead>
                <tr>
                  <td class="col-sm-2">{{ app.text.history.date }}</td>
                  <td class="col-sm-2">{{ app.text.history.userName }}</td>
                  <td class="col-sm-2">{{ app.text.history.fieldName }}</td>
                  <td class="col-sm-3">{{ app.text.history.oldValue }}</td>
                  <td class="col-sm-3">{{ app.text.history.newValue }}</td>
                </tr>
              </thead>
              <tbody>
                <tr
                  *ngFor="
                    let changedField of historyItem.data.operations;
                    let i = index
                  "
                >
                  <td class="col-sm-2">
                    <span *ngIf="i === 0">
                      {{ app.history.getDateformat(historyItem.create_time) }}
                    </span>
                  </td>
                  <td class="col-sm-2">
                    <span *ngIf="i === 0">
                      {{ historyItem.user }}
                    </span>
                  </td>
                  <td class="col-sm-2">
                    <span>
                      {{
                        app.history.getFieldName(
                          historyItem.docType,
                          changedField.property
                        )
                      }}
                    </span>
                  </td>
                  <td class="col-sm-3">
                    {{
                      app.history.getText(
                        historyItem.docType,
                        changedField.property,
                        changedField.oldValue
                      )
                    }}
                  </td>
                  <td class="col-sm-3">
                    {{
                      app.history.getText(
                        historyItem.docType,
                        changedField.property,
                        changedField.newValue
                      )
                    }}
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  </div>
</ng-template>
