<button class="btn btn-sm btn-light" (click)="openModal()">
  {{ app.text.applicationArea.newOption }}
</button>
<ng-template #newApplicationAreaOptionModal let-modal>
  <div class="modal-header">
    <div class="col-md-12">
      <span class="modal-title">
        {{ app.text.applicationArea.newOption }}
      </span>
    </div>
  </div>
  <div class="modal-body">
    <div class="input-group">
      <input
        class="form-control m-0 lineHeight applicationAreaInput"
        type="text"
        [(ngModel)]="applicationAreaOption"
      />
    </div>
  </div>
  <div class="modal-footer">
    <button class="btn btn-cancel text-center m-1" (click)="closeModal()">
      {{ app.text.applicationArea.cancel }}
    </button>
    <button
      class="btn btn-ok text-center m-1"
      (click)="saveApplicationArea(applicationAreaOption)"
    >
      {{ app.text.applicationArea.save }}
    </button>
  </div>
</ng-template>
