<div class="row mt-3">
  <div class="col-md-6">
    <div class="btn-group m-1 pull-left">
      <button
        class="btn btn-sm"
        *ngFor="let size of app.paginator.pageSizes"
        [ngClass]="{ selected: size === app.paginator.pageSize }"
        (click)="app.paginator.setSize(size)"
      >
        {{ size }}
      </button>
    </div>
  </div>
  <div class="col-md-6 controls">
    <button class="btn btn-sm" disabled>
      {{ start }} - {{ end }} / {{ length }}
    </button>
    <div class="btn-group m-1">
      <button class="btn" (click)="next(0)" [disabled]="disabledPrev()">
        <i class="fa fa-angle-double-left"></i>
      </button>
      <button
        class="btn btn-sm"
        (click)="next(index - app.paginator.pageSize)"
        [disabled]="disabledPrev()"
      >
        <i class="fa fa-angle-left"></i>
      </button>
      <button
        class="btn btn-sm"
        (click)="next(index + app.paginator.pageSize)"
        [disabled]="disabledNext()"
      >
        <i class="fa fa-angle-right"></i>
      </button>
      <button class="btn btn-sm" (click)="goLast()" [disabled]="disabledNext()">
        <i class="fa fa-angle-double-right"></i>
      </button>
    </div>
  </div>
</div>
