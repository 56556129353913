<app-modal
  #deleteAlertsModal
  [modalMessage]="app.text.manufacturer.deleteSvhcModalText"
  [modalTitle]="app.text.app.delete"
  (ok)="app.SVHCItems.delete()"
>
</app-modal>

<button
  class="btn btn-sm btn-default-orange pull-right mb-3"
  (click)="newItem = true; app.SVHCItems.getOptions()"
  [disabled]="!manualOverride"
  [title]="app.text.manufacturer.addNewLineSvhc"
>
  <i class="fa fa-plus"></i>&nbsp; {{ app.text.app.add }}
</button>

<div class="tableFixHead clear">
  <table class="table spacing-table table-striped reachSubstancesTable">
    <thead>
      <tr class="text-center">
        <th class="p-1">
          {{
            app.field.getLabel(app.fieldId.SVHCItems.substanceIdentification)
          }}
        </th>
        <th class="p-1">
          {{ app.field.getLabel(app.fieldId.SVHCItems.casNumber) }}
        </th>
        <th class="p-1">
          {{ app.field.getLabel(app.fieldId.SVHCItems.concentration) }}
        </th>
        <th class="p-1">
          {{ app.field.getLabel(app.fieldId.SVHCItems.thresholdLimitExceeded) }}
        </th>
        <th class="p-1">
          {{ app.text.app.delete }}
          <div class="text-center">
            <button
              *ngIf="app.SVHCItems.selected.size === 0"
              [disabled]="!manualOverride"
              (click)="app.SVHCItems.selectAll()"
            >
              <i class="far fa-square"></i>
            </button>
            <button
              *ngIf="app.SVHCItems.selected.size > 0"
              [disabled]="!manualOverride"
              (click)="app.SVHCItems.clearSelection()"
            >
              <i class="far fa-check-square"></i>
            </button>
          </div>
          <div class="text-center">
            <button
              *ngIf="app.SVHCItems.selected.size > 0"
              (click)="deleteAlertsModal.open()"
            >
              <i class="fa fa-trash"></i>
            </button>
          </div>
        </th>
      </tr>
    </thead>
    <tbody>
      <tr class="text-center" *ngFor="let item of app.SVHCItems.substances">
        <td class="p-1">{{ item.substanceIdentification }}</td>
        <td class="p-1">{{ item.casNumber }}</td>
        <td class="p-1">{{ item.concentration }}</td>
        <td class="p-1">
          <div class="text-center">
            <span *ngIf="item.thresholdLimitExceeded">{{
              app.text.app.yes
            }}</span>
            <span *ngIf="!item.thresholdLimitExceeded">{{
              app.text.app.no
            }}</span>
          </div>
        </td>
        <td class="p-1">
          <div *ngIf="!app.SVHCItems.selected.has(item)">
            <button
              [disabled]="!manualOverride"
              (click)="app.SVHCItems.toggleSelect(item)"
            >
              <i class="far fa-square"></i>
            </button>
          </div>
          <div *ngIf="app.SVHCItems.selected.has(item)">
            <button
              [disabled]="!manualOverride"
              (click)="app.SVHCItems.toggleSelect(item)"
            >
              <i class="far fa-check-square"></i>
            </button>
          </div>
        </td>
      </tr>
      <tr *ngIf="newItem" class="text-center newSvhcRow">
        <td>
          <app-field
            [model]="model"
            field="SVHCItems.substanceIdentification"
            [showLabel]="false"
            [required]="true"
            [largeInput]="true"
          ></app-field>
        </td>

        <td>
          <app-field
            [model]="model"
            field="SVHCItems.casNumber"
            [showLabel]="false"
            [required]="true"
            [largeInput]="true"
          ></app-field>
        </td>
        <td>
          <app-field
            [model]="model"
            field="SVHCItems.concentration"
            [showLabel]="false"
            [required]="true"
            [largeInput]="true"
          ></app-field>
        </td>

        <td class="p-1 pt-4">
          <div class="text-center">
            <!-- TODO:  replace this with a function -->
            <span *ngIf="model['SVHCItems.concentration'] >= 1000">{{
              app.text.app.yes
            }}</span>
            <span *ngIf="model['SVHCItems.concentration'] < 1000">{{
              app.text.app.no
            }}</span>
          </div>
        </td>

        <td class="p-1 pt-4">
          <div class="btn-group btn-group-sm action-buttons" role="group">
            <button
              type="button"
              class="btn pe-1"
              [disabled]="
                app.field.getInvalidFields('SVHCItems', model).length > 0
              "
              (click)="app.SVHCItems.add(model)"
            >
              <i class="fa fa-check"></i>
            </button>
            <button type="button" class="btn pe-1" (click)="newItem = false">
              <i class="fa fa-times"></i>
            </button>
          </div>
        </td>
      </tr>
      <tr>
        <td
          *ngIf="
            app.SVHCItems.substances &&
            app.SVHCItems.substances.length === 0 &&
            !newItem
          "
          colspan="99"
          class="text-center"
        >
          {{ app.text.app.noResults }}
        </td>
      </tr>
    </tbody>
  </table>
</div>
