import {
  HttpEvent,
  HttpHandler,
  HttpHeaders,
  HttpInterceptor,
  HttpRequest,
} from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { TOKEN } from "./token";
import { EncodingUtils } from "../../../../shared/utils/encoding.utils";

@Injectable()
export class TokenInterceptor implements HttpInterceptor {
  public intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    if (typeof Storage !== "undefined") {
      let token: string | null = null;

      if (localStorage.getItem(TOKEN) !== null) {
        token = localStorage.getItem(TOKEN);
      } else if (sessionStorage.getItem(TOKEN) !== null) {
        token = sessionStorage.getItem(TOKEN);
      }

      if (token !== null) {
        request = request.clone({
          headers: new HttpHeaders({
            "Content-Type": "application/json",
            Authorization: `Bearer ${EncodingUtils.decodeBase64(token)}`,
          }),
        });
      }
    }

    return next.handle(request);
  }
}
