import { Component, ViewChild, Input, Output } from "@angular/core";
import { Subject } from "rxjs";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { getApp } from "../app";
import { VehicleResponsible } from "../../../../shared/models/vehicleResponsible";
import { doc2Model } from "../api.service";

@Component({
  selector: "app-vehicle-responsible-modal",
  templateUrl: "./vehicle-responsible-modal.component.html",
  styleUrls: ["./vehicle-responsible-modal.component.scss"],
})
export class VehicleResponsibleModalComponent {
  @Input() modalTitle = "";

  @ViewChild("content", { static: false }) content: any;
  @Output() ok = new Subject<any>();

  app = getApp((app) => {
    this.app = app;
  });

  fields: string[] = this.app.list.vehicleResponsible.vehicleResponsible;

  constructor(private modalService: NgbModal) {}

  ngOnInit() {
    if (this.fields.find((x) => x === "impact.actions")) {
      this.fields.splice(this.fields.length - 1, 1);
    }
  }

  async open(vehicle: VehicleResponsible) {
    if (vehicle == null) {
      this.app.vehicleResponsible.isNew = true;
      const newVehicle = new VehicleResponsible();
      //delete the _id when create a vehicle, otherwise the vehicle would be saved with empty string _id
      delete newVehicle._id;
      this.app.vehicleResponsible.model = doc2Model(
        "vehicleResponsible",
        newVehicle
      ) as VehicleResponsible;
    } else {
      this.app.vehicleResponsible.cleanModel = doc2Model(
        "vehicleResponsible",
        vehicle
      ) as VehicleResponsible;
      this.app.vehicleResponsible.model = doc2Model(
        "vehicleResponsible",
        vehicle
      ) as VehicleResponsible;
    }
    try {
      const result = await this.modalService.open(this.content, {
        windowClass: "mediumModal",
      }).result;

      this.ok.next(result);
    } catch (err) {
      // NOP
    }
  }

  showSaveButton() {
    if (
      this.app.field.getInvalidFields(
        "vehicleResponsible",
        this.app.vehicleResponsible.model
      ).length < 1 &&
      this.app.vehicleResponsible.modelHasChanges
    ) {
      return true;
    }
    if (
      this.app.field.getInvalidFields(
        "vehicleResponsible",
        this.app.vehicleResponsible.model
      ).length < 1
    ) {
      return true;
    } else {
      return false;
    }
  }

  closeModal() {
    this.modalService.dismissAll();
    this.app.typeAheadBasic.model = {};
  }
}
