import { Roles } from "./roles";

export class User {
  [key: string]: any;
  _id?: string = "";
  name: string = "";
  email: string = "";
  password: string = "";
  roles: string[] = [];
  jobTitle: string = "";
  businessArea: string = "";
  displayAsComponent: boolean = false;
  active: boolean = true;
  passwordExpireDate: string = "";
  lastFivePasswords?: {
    derived_key: string;
    iterations: number;
    salt: string;
  }[];
  retypePassword?: string = "";
  oldPassword?: string = "";
  lastChangedPassword?: number;
  forcePasswordChangeOnFirstLogin?: boolean = false;
  passwordNeverExpire: boolean = false;
  salt?: any;
  iterations?: any;
  derived_key?: any;
  language?: string = "";
  projectNumber?: string = "";
}

export class ViewUser {
  _id: string = "";
  name: string = "";
  email: string = "";
  roles: string[] = [];
  jobTitle: string = "";
  businessArea: string = "";
  displayAsComponent: boolean = false;
  active: boolean = true;
  passwordExpireDate: string = "";
  lastChangedPassword?: number;
  forcePasswordChangeOnFirstLogin?: boolean = false;
  passwordNeverExpire: boolean = false;
  language?: string;
  projectNumber?: string;
}

export class UserCompressed {
  id: string = "";
  name: string = "";
  email: string = "";
  roles: string[] = [];
  jobTitle: string = "";
  businessArea: string = "";
  projectNumber?: string = "";
  displayAsComponent: boolean = false;
  active: boolean = true;
  passwordExpireDate: string = "";
}

export class UserOptions {
  name: string = "";
  email: string = "";
  roles: string[] = [];
  jobTitle: string = "";
  businessArea: string = "";
  projectNumber?: string = "";
}

export class ResetToken {
  token: string = "";
  type: string = "resetToken";
}

export class UserWithRoles {
  username: string = "";
  roles: Roles = {
    admin: false,
    guest: false,
    team: false,
    user: false,
    analyst: false,
    RMAdmin: false,
  };
}

/**
 * Quickly convert from a regular User entity to a stripped down ViewUser entity
 * @param user User entity to be converted
 * @param customer Customer the user is requested for - for stripping down roles
 * @returns Stripped down ViewUser entity
 */
export function convertUserForView(user: User, customer: string): ViewUser {
  return {
    _id: user._id,
    name: user.name,
    email: user.email,
    roles: user.roles.filter((r) => r.split("-")[0] === customer),
    jobTitle: user.jobTitle,
    businessArea: user.businessArea,
    displayAsComponent: user.displayAsComponent,
    active: user.active,
    passwordExpireDate: user.passwordExpireDate,
    lastChangedPassword: user.lastChangedPassword,
    forcePasswordChangeOnFirstLogin: user.forcePasswordChangeOnFirstLogin,
    passwordNeverExpire: user.passwordNeverExpire,
    language: user.language,
    projectNumber: user.projectNumber,
  } as ViewUser;
}
