export const typesMap: { [key: string]: string } = {
  GENMFRNAME: "MANAQ",
  GENCUSSPEC: "CHARA",
  GENCUSINTERF: "CHARA",
  GENFUNC: "FUNCT",
  GENDATAELEC: "CHARA",
  GENDATAPHYSCHEM: "CHARA",
  GENDATACORR: "CORR",
  GENADDSPEC: "DOCUM",
  GENPARTNOCHG: "LABEL",
  GENPARTPACHG: "FORM",
  GENINSPMARK: "DOCUM",
  GENCUSREQU: "CHARA",
  GENOPERMETHOD: "CHARA",
  GENQUALITYCHANGE: "",
  GENRELIABILITYCHANGE: "",
  GNCREGREQU: "CHARA",
  GNCSTDREQU: "CHARA",
  GNCINNCHNG: "CHARA",
  GNCSAFSEC: "CHARA",
  GNCFIFOFU: "CHARA",
  MATCOMP: "MATER",
  MATWAFSUB: "MATER",
  MATDOPIMP: "MATER",
  MATGATEDIELECT: "MATER",
  MATLEADFRAMEBASE: "MATER",
  MATLEADFRAMEFINISH: "MATER",
  MATBONDWIRE: "MATER",
  MATBGA: "MATER",
  MATDIEOVERCOATUNDERFILL: "MATER",
  MATMETALLIZATION: "MATER",
  MATENCAPSSEALING: "MATER",
  MATBUMPMETAL: "MATER",
  MATMOLD: "MATER",
  MATOUTER: "MATER",
  MATPIN: "MATER",
  MATDIEATTACH: "MATER",
  MATSUBCOMP: "MATER",
  MATCONV: "MATER",
  MATSUPPL: "PSITE",
  MATSUPPLDIR: "PSITE",
  MATSUPPLWAF: "PSITE",
  MATSUPPLNOIMP: "PSITE",
  MATSUPPLCONV: "PSITE",
  DESGCHG: "CHARA",
  DESGINNERCONST: "CHARA",
  DESGSEV: "FORM",
  DESGWAFTHICK: "MATER",
  DESGWAFDIAM: "MATER",
  DESGWAFSETUP: "MATER",
  DESGWAFEDGE: "CHARA",
  DESGWAFLAY: "CHARA",
  DESGDIESIZE: "CHARA",
  DESGOUTERDIM: "CHARA",
  DESGLEADFRAME: "CHARA",
  DESGMARK: "LABEL",
  DESGPLAT: "MATER",
  DESGPIN: "FIT",
  DESGACTIVE: "CHARA",
  DESGROUT: "CHARA",
  DESGDIESHRINK: "CHARA",
  PROCGEN: "PPROC",
  PROCSTEPCHG: "PPROC",
  PROCASSY: "PPROC",
  PROCWAF: "PPROC",
  PROCPLAT: "PPROC",
  PROCWIREBOND: "PPROC",
  PROCMARK: "PPROC",
  PROCMETALLIZATIONBACK: "MATER",
  PROCMETALLIZATIONFRONT: "MATER",
  PROCPASSIVATION: "MATER",
  PROCTUN: "PPROC",
  PROCCONTIMPR: "PPROC",
  TESTSPEC: "TESTP",
  TESTFINALELIM: "TESTP",
  TESTPROCFLOW: "TESTP",
  LOCCOAQU: "MANAQ",
  LOCMFR: "PSITE",
  LOCWAF: "PSITE",
  LOCASSY: "PSITE",
  LOCWAFTEST: "TESTS",
  LOCTOOLNEWTECH: "PSITE",
  LOCTOOLOLDTECH: "PSITE",
  LOCTOOLNEWTEST: "TESTP",
  LOCSW: "SOFTW",
  SHIPCUS: "SHIP",
  SHIPSVC: "SHIP",
  SHIPPKGDIM: "SHIP",
  SHIPENV: "SHIP",
  SHIPDRYPACK: "SHIP",
  SHIPCARRIER: "SHIP",
  SHIPLABEL: "PACKA",
  SHIPSPEC: "PACKA",
  SHIPMAT: "PACKA",
  SWUPDSAFSEC: "",
  SWUPGRD: "SOFTW",
  SWCHGFUNC: "SOFTW",
  SWCHGIFC: "SOFTW",
  SWERRCOR: "SOFTW",
  SWPARCHG: "SOFTW",
  SWCERCRP: "SOFTW",
  SWOSCHG: "SOFTW",
  SWDOCCHG: "SOFTW",
  SWLIBCHG: "SOFTW",
};
