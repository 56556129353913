import { TotalCostGraphData } from "./totalCost-graph-data";
import { AbstractGraph } from "../abstract-graph";
import { AppType } from "../../app.type";
import { GraphConfig } from "../graph.type";

export class TotalCostLTBGraph extends AbstractGraph<TotalCostGraphData> {
  get config(): GraphConfig {
    return {
      type: "line-chart",
      showXAxis: true,
      showYAxis: true,
      showLegend: true,
      xAxisLabel: null,
      yAxisLabel: null,
      colorScheme: {
        domain: ["#5AA454", "#A10A28", "#C7B42C", "#AAAAAA"],
      },
      filter: 10,
    };
  }

  constructor(protected app: AppType) {
    super(app);
  }

  protected async getMap(map: Map<string, TotalCostGraphData>) {}
}
