import { PcnServiceType } from "./pcn.service.type";
import { AppType, APP_TYPE } from "../app.type";
import {
  downloadFile,
  uploadFile,
  bulkDocsByType,
  getPcn,
  model2Doc,
  doc2Model,
} from "../api.service";
import { Inject, Injectable } from "@angular/core";
import { Pcn } from "../../../../shared/models/pcn";
import { EncodingUtils } from "../../../../shared/utils/encoding.utils";

@Injectable()
export class PcnService implements PcnServiceType {
  pcn: any = {} as Pcn;
  id: string | null = null;

  constructor(@Inject(APP_TYPE) private app: AppType) {}

  // get id() {
  //   return this.app.state.pcnId;
  // }

  // set id(pcnId: string | null) {
  //   this.app.state.next({ pcnId });
  // }

  async getPcn(pcnId: string) {
    const customer = this.app.customers.expectCurrent;
    const pcn = await getPcn(customer, pcnId);
    this.id = pcnId;

    if (pcn[0] != null) {
      this.pcn = doc2Model("pcn", pcn[0]);
      return this.pcn;
    }
  }

  prepareDelete(pcn: Pcn) {
    let pcnDoc: any = {} as Pcn;
    pcnDoc = model2Doc("pcn", pcn);
    this.app.table.toggleSelect(pcnDoc);
    this.app.table.prepareDocsToUpdate(pcnDoc, "pcn", "delete");
  }

  async syncPcnDocument(id: string) {
    if (id == null) {
      return;
    }
    // get and upload the attachments of the pcn document to the newly created thread
    this.app.spinner.showSpinner();
    const customer = this.app.customers.current;
    const attachments = await this.app.file.getAttachmentsByDocType(
      this.pcn["pcn._id"],
      "pcn",
      customer
    );

    if (attachments.length !== 0) {
      const entries = Object.entries(attachments);
      for (let i = 0; i < entries.length; i++) {
        const fileName = entries[i][0];
        const fileLink = [
          customer,
          "pcn",
          EncodingUtils.encodeBase64(this.pcn["pcn._id"]),
          fileName,
        ].join("/");

        const data = await downloadFile(fileLink);
        const file = new File([data], fileName, {
          type: entries[i][1].content_type || "",
        });

        const attachmentLink = [customer, "thread", EncodingUtils.encodeBase64(id), fileName].join(
          "/"
        );
        await uploadFile(file, attachmentLink);
      }
    }

    // prepare pcn document to be deleted after a case was created
    this.deleteSyncedPcn();
    this.app.spinner.hideSpinner();
    //pcn = null it used on the mail.component.html to display the menu after creating a thread from pcn
    this.pcn = null;
    this.id = null;
  }

  private async deleteSyncedPcn() {
    let doc: any;
    const index = this.app.pcns.pcns.findIndex(
      (p) => p._id === this.pcn["pcn._id"]
    );

    if (index !== -1) {
      doc = this.app.pcns.pcns[index];
      doc._deleted = true;
      await bulkDocsByType(this.app.customers.expectCurrent, "pcn", [doc]);
    }
  }
}
