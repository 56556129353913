import { APP_TYPE, AppType } from "../app.type";
import { Inject, Injectable } from "@angular/core";
import { HomeServiceType } from "./home.service.type";

export type HomeMode = "list" | "table";

@Injectable()
export class HomeService implements HomeServiceType {
  homeMode: HomeMode = this.app.list.app.homeMode[0] as HomeMode;

  constructor(@Inject(APP_TYPE) private app: AppType) {}
}
