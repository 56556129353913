<div *ngIf="app.hasError" class="top-bar error-notification" style="cursor: pointer">
    <div class="container">
        <div class="p-1">
            <i class="fas fa-exclamation-triangle"></i>
            {{ app.errorText }}
            <div class="close-btn pull-right" (click)="app.hasError = false">
                <i class="fa fa-times"></i>
            </div>
        </div>
    </div>
</div>
<div class="app-container offset-md-3 col-md-6">
    <div class="row">
        <div class="col-md-12">
            <div class="text-center p-lg-4">
                <img *ngIf="app.auth.landing == null" class="p-3 m-3 login-image" [src]="app.url.auth.logo" />
                <img *ngIf="app.auth.landing != null" class="p-3 m-2 login-image-omp"
                    [src]="app.url.auth.landingLogo" />
            </div>
        </div>
    </div>
    <div class="row">
        <div class="card-login offset-lg-2 offset-md-1 offset-sm-2 col-lg-8 col-md-12 col-sm-8 mb-5">
            <div class="m-1 p-1">
                <div class="m-2 p-2 text-center">
                    <button class="btn btn-lock">
                        <i class="fa-duotone fa-solid fa-lock fa-lock-orange"></i>
                    </button>
                </div>
                <div class="m-2 p-2">
                    <h2 class="heading text-center">{{ app.text.auth.twoFactorAuth }}</h2>
                </div>
                <div class="m-2 p-2">
                    <h6 class="two-factor-text text-center">{{ app.text.auth.twoFactorText }}</h6>
                </div>
                <div class="countdown">
                    <h3>{{ countdownTime }}</h3>
                </div>
                <div class="m-2 p-2 two-factor-code">
                    <div class="otp-container">
                        <input *ngFor="let digit of otpArray; let i = index" [attr.id]="'otp-' + i" [(ngModel)]="otpCode[i]" maxlength="1"
                            (keydown)="onKeyDown($event, i)" (input)="onInput($event, i)" (paste)="onPaste($event)" type="text"
                            autocomplete="off" />
                    </div>
                </div>
                <div class="controls text-center my-4">
                    <!-- <button class="btn btn-resend-code text-center">
                        {{ app.text.auth.resendCode }}
                    </button> -->
                    <button class="btn btn-submit-code text-center" (click)="submitCode()">
                        {{ app.text.auth.submitCode }}
                    </button>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="app-footer">
    <app-footer></app-footer>
</div>