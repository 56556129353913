import { ComponentSettings, empty } from "../settings/settings";

export const field = {};

const settings = {
  permission: {
    editOwn: false,
  },
  list: {
    emails: empty,
  },
  url: {},
  text: {
    emailPreferences: {
      de: "E-Mail Einstellungen",
      en: "Email Preferences",
      nl: "E-mail voorkeuren",
    },
    emailPreferencesSet: {
      de: "E-Mail Einstellungen aktualisiert",
      en: "Email preferences updated",
      nl: "E-mail voorkeuren bijgewerkt",
    },
    1: {
      de: "Fall erstellt",
      en: "Case created",
      nl: "Geval gemaakt",
    },
    2: {
      de: "Fall aktualisiert",
      en: "Case updated",
      nl: "Zaak bijgewerkt",
    },
    3: {
      de: "Aufgabe erstellt - Vertreter",
      en: "Task created deputy",
      nl: "Taak aangemaakt - Vertegenwoordiger",
    },
    4: {
      de: "Aufgabe - erste Erinnerung",
      en: "Task first reminder",
      nl: "Taak eerste herinnering",
    },
    5: {
      de: "Case responsible advancement",
      en: "Case responsible advancement",
      nl: "Case responsible advancement",
    },
    6: {
      de: "Aufgabe - zweite Erinnerung",
      en: "Task second reminder",
      nl: "Taak tweede herinnering",
    },
    7: {
      de: "Fallstatus geaendert",
      en: "Case status changed",
      nl: "Zaakstatus gewijzigd",
    },
    8: {
      de: "Fallstatus abgeschlossen",
      en: "Case status closed",
      nl: "Zaakstatus gesloten",
    },
    9: {
      de: "Kommentar hinzugefuegt",
      en: "Comment added",
      nl: "Commentaar toegevoegd",
    },
    10: {
      de: "Kommentar aktualisiert",
      en: "Comment updated",
      nl: "Commentaar bijgewerkt",
    },
    11: {
      de: "Loesung hinzugefuegt",
      en: "Solution added",
      nl: "Oplossing toegevoegd",
    },
    12: {
      de: "Loesung aktualisiert",
      en: "Solution updated",
      nl: "Oplossing bijgewerkt",
    },
    13: {
      de: "Aufgabe hinzugefuegt",
      en: "Task added",
      nl: "Taak toegevoegd",
    },
    14: {
      de: "Aufgabe erledigt",
      en: "Task completed",
      nl: "Taak voltooid",
    },
    15: {
      de: "Aufgabe nicht erledigt",
      en: "Task uncompleted",
      nl: "Taak niet voltooid",
    },
    16: {
      de: "Benutzer erstellt",
      en: "User created",
      nl: "Gebruiker aangemaakt",
    },
    17: {
      de: "Benutzer erstellt erzwingt Passwortaenderung",
      en: "User created force password change",
      nl: "Gebruiker aangemaakt wachtwoord wijzigen afdwingen",
    },
    18: {
      de: "Passwort zuruecksetzen",
      en: "Reset password",
      nl: "Wachtwoord opnieuw instellen",
    },
    20: {
      de: "Admin setzt Benutzerpasswort zurueck",
      en: "Admin reset user password",
      nl: "Admin reset gebruikerswachtwoord",
    },
    21: {
      de: "Fall Aenderung - bereit",
      en: "Change status ready",
      nl: "Statuswijziging - klaar",
    },
    // Obsolescence Community emails
    22: {
      de: "Post created",
      en: "Post created",
      nl: "Post created",
    },
    23: {
      de: "Post updated",
      en: "Post updated",
      nl: "Post updated",
    },
    24: {
      de: "Comment added",
      en: "Comment added",
      nl: "Comment added",
    },
    25: {
      de: "Comment updated",
      en: "Comment updated",
      nl: "Comment updated",
    },
    27: {
      de: "User created",
      en: "User created",
      nl: "User created",
    },
    security: {
      de: "Sicherheit",
      en: "Security",
      nl: "Beveiliging",
    },
    securityPreferencesSet: {
      de: "Sicherheitseinstellungen aktualisiert",
      en: "Security preferences updated",
      nl: "Beveiligingsvoorkeuren bijgewerkt",
    },
    MFAEmail: {
      de: "Multi-Faktor-Authentifizierung - E-Mail",
      en: "Multi-Factor Authentication - E-mail",
      nl: "Multi-Factor Authenticatie - E-mail",
    },
  },
  field,
};

export const userSettings: ComponentSettings<typeof settings> = settings;
