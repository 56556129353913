import { Credits } from "./credits";
import { ItemNumber } from "./item-number";

export class Thread extends Credits {
  [key: string]: any;
  type: string = "thread";
  safetyEvaluation: boolean = false;
  creator_class: string = "";
  identificationMethod: string = "";
  omfStatus: string = "";
  omfNumber: string = "";
  omfShortDescr: string = "";
  supplier: string = "";
  supplierEmail: string = "";
  title: string = "";
  artNumber: string = "";
  productCategory: string = "";
  productCategoryActiveElectronics: string = "";
  productCategoryPassiveElectronics: string = "";
  itemSubData: string = "";
  werk: string = "";
  changeClasses: string[] = [];
  dinCode: string = "";
  dinCodeRespNameShort: string = "";
  dinText: string = "";
  creator: string = "";
  mfrhighlevel: string = "";
  crtContact: string = "";
  crtStreet: string = "";
  crtPostCode: string = "";
  crtCity: string = "";
  crtState: string = "";
  crtCountry: string = "";
  crtNumber: string = "";
  issueDate: string = "";
  pcnID: string = "";
  pcnRevision: string = "";
  typeIdNum: string = "";
  drawNumber: string = "";
  oDate: string = "";
  itemSOP: string = "";
  itemEOP: string = "";
  itemEOS: string = "";
  itemLTD: string = "";
  itemEOSR: string = "";
  items?: string[] = [];
  itemNumbers?: ItemNumber[] = [];
  replmanfItemnumber: string = "";
  descr: string = "";
  priority: string = "";
  dueDate: string | number = "";
  dueDateClass: string = "";
  intDescr: string = "";
  company: string = "";
  entryDate: string = "";
  entryCheckbox: string = "";
  vehicleCnt: string = "";
  deputy: string = "";
  deputyDepartment: string = "";
  deputyEmail: string = "";
  dlzDays: string = "";
  dinCodeRespName: string = "";
  dinCodeRespEmail: string = "";
  businessArea: string[] = [];
  closingDate: string | number = "";
  complexity: string = "";
  actualEffort: string = "";
  componentResponsible: string = "";
  componentResponsibleEmail: string = "";
  vehicleNames: string[] = [];
  showCheckBearbeiter: boolean = false;
  vehicles?: string[] = [];
  showCheckKomponentManager: boolean = false;
  componentResponsibleSigned: boolean = false;
  caseSource: string = "";
  seen: boolean = false;
  last_update: number | undefined = undefined;
  threadImage?: string = "";
  omApproach: string = "";
  usedIn: string = "";
  commodityClass: string = "";
  statusRisk: string = "";
  stakeholder: string[] = [];
  repairable: boolean = false;
  obsolescenceStatus: string = "";
  mtbf?: number | null = null;
  indicatorName?: string = "";
  indicatorDep?: string = "";
  indicatorTelephone?: string = "";
  indicatorEmail?: string = "";
  engineerName?: string = "";
  engineerDep?: string = "";
  engineerEmail?: string = "";
  buyer?: string = "";
  materialPlaner?: string = "";
  dinCodeRespDep?: string = "";
  dinCodeRisk?: string = "";
  originOfNotification?: string = "";
  actualStock?: number = undefined;
  omfCommodityId?: string = "";
  omfCommodityDescr?: string = "";
  omfCommodityRespName?: string = "";
  //next properties are used only by ns client
  teamMemberName: string = "";
  supplyManagerName: string = "";
  buyerName: string = "";
  plannerName: string = "";
  sysEngineerName: string = "";
  otherStakeholder: string[] = [];
  otherStakeholderEmail: string = "";
  itemType: string = "";
  projectStatus: boolean = false;
  classNumber: string = "";
  usedInSystem: string = "";
  norm: string = "";
  readilyAvailableStock: string = "";
  safetyStock: string = "";
  actualStockBr: string = "";
  actualStockFi: string = "";
  actualStockGru: string = "";
  sapNumber: string = "";
  totalLifeCycleUsage: string = "";
  totalForecast2Years: string = "";
  forecastEOLStock: string = "";
  prognosis: string = "";
  threadEditFields?: string = "";
  averageCons?: number = undefined;
  averageConsStochastic?: number = undefined;
  actualStockDate?: string = "";
  bpNumber: string = "";
  totalConsumation?: number = undefined;
  stockRangeCR?: number = undefined;
  stockRangeCons?: number = undefined;
  stockRangeRequired?: number = undefined;
  closingDateAfterCons?: string = "";
  closingDateRequired?: string = "";
  closingDateCR?: string = "";
  fav?: string = "";
  applicationArea?: string[] = [];
  reporterName?: string = "";
  closedOn: string = "";
  totalPrice?: number = undefined;
  closedBy: string = "";
  pcnOrigin?: string[] = [];
  process?: string = "";
  datasheet?: string[] = [];
  rmNotes?: string = "";
  postCount?: PostCount;
  criticalComponent?: boolean = false;
  otherCustomers?: string = "";
  unavailabilityRisk?: string[] = [];
  procComment?: string = "";
  procLeadTime?: number = undefined;
  procDate?: number = undefined;
  procQty?: number = undefined;
  stockInclProc?: number = undefined;
  fdd?: number = undefined;
  pricePerUnit?: number = undefined;
  pcnSource?: string = "";
  sapStatus?: string = "";
  electronicalMat?: boolean = false;
  valid?: string = "";
  pcnLink: string = "";
  externalImageSource: string = "";
  usage?: string = "";
  criticality?: string = "";
  consumption?: string = "";
  rangeForecast?: string = "";
  TISdescr1?: string = "";
  TISdescr2?: string = "";
  cageCode?: string = "";
  pcnStatus?: string[] = [];
  instructions?: string = "";
  /** coop specific fields */
  typeOfPost: string = "";
}

export class ThreadBulk extends Thread {
  [key: string]: any;
  _id?: string;
}

export class PostCount {
  commentsCount: string = "";
  solutionsCount: string = "";
  tasksCount: string = "";
  finalSolution: string = "";
  completedTasks: string[] = [];
}

export class ResponsibleCorrespondent {
  name: string = "";
  deputy?: string = "";
  vehicleNames?: string[] = [];
}
