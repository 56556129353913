export const pcnMapping: { [key: string]: string | null } = {
  pcnTitle: "title",
  pcnType: "creator_class",
  pcnCompany: "creator",
  pcnMfrName: "mfrhighlevel",
  pcnEmail: "crtContact",
  pcnRevision: "pcnRevision",
  pcnNumber: "pcnID",
  pcnState: "crtState",
  pcnStreet: "crtStreet",
  pcnZipCode: "crtPostCode",
  pcnCity: "crtCity",
  pcnCountry: "crtCountry",
  pcnIssueDate: "oDate",
  itemMfrNumber: "crtNumber",
  itemMfrTypeIdent: "drawNumber",
  itemMfrName: "omfShortDescr",
  itemRev: "typeIdNum",
  itemCategory: "productCategory",
  itemSubData: "itemSubData",
  dinCode: "dinCode",
  dinText: "dinText",
  artNumber: "artNumber",
  itemChangeType: "changeClasses",
  itemMfrReplNumber: "replmanfItemnumber",
  itemSOP: "itemSOP",
  itemEOPeffDate: "itemEOP",
  itemEOS: "itemEOS",
  itemLTD: "itemLTD",
  itemEOSR: "itemEOSR",
  pcnChangeIdentificationMethod: "identificationMethod",
  pcnChangeDetail: "descr",
  ItemNumbers: "itemNumbers",

  // pcnSubRevision: "pcnSubRevision",
  // pcnSubType: "pcnSubType",
  // pcnSubData: "pcnSubData",

  // pcnSOP: "itemSOP",
  // pcnEOS: "itemEOS",
  // pcnEOpeffDate: "itemEOP",
  // pcnLTD: "itemLTD",
  // pcnEOSR: "itemEOSR",

  // pcnChangeDescription: "pcnChangeDescription",
  // pcnChangeType: "pcnChangeType"
};

export const pcnMappingPdf: { [key: string]: string | null } = {
  pcnTitle: "title",
  pcnType: "creator_class",
  pcnCompany: "creator",
  pcnMfrName: "mfrhighlevel",
  pcnEmail: "crtContact",
  pcnRevision: "pcnRevision",
  pcnNumber: "pcnID",
  pcnState: "crtState",
  pcnStreet: "crtStreet",
  pcnZipCode: "crtPostCode",
  pcnCity: "crtCity",
  pcnCountry: "crtCountry",
  pcnIssueDate: "oDate",
  pcnChangeIdentificationMethod: "identificationMethod",
  pcnChangeDetail: "descr",
  ItemNumbers: "itemNumbers",
};

export const itemMapping: { [key: string]: string } = {
  itemMfrNumber: "crtNumber",
  itemMfrTypeIdent: "drawNumber",
  itemMfrName: "omfShortDescr",
  itemRev: "typeIdNum",
  itemCategory: "productCategory",
  itemSubData: "itemSubData",
  itemChangeText: "changeClasses",
  itemMfrReplNumber: "replmanfItemnumber",
  itemSOP: "itemSOP",
  itemEOPeffDate: "itemEOP",
  itemEOS: "itemEOS",
  itemLTD: "itemLTD",
  itemEOSR: "itemEOSR",
};
