<ng-template #content let-modal>
  <div class="modal-header">
    <div class="col-md-12">
      <button class="btn pull-right" (click)="dismiss()">
        <i class="fa fa-times"></i>
      </button>
      <span>
        {{ app.text.part.createPart }}
      </span>
    </div>
  </div>
  <div class="modal-body">
    <span *ngIf="warning" class="warning">{{ warning }}</span>
    <div class="col-md-12">
      <app-field
        *ngFor="let field of columns; index as index"
        [model]="model"
        [field]="field"
        [index]="index"
      ></app-field>
    </div>
  </div>
  <div class="modal-footer">
    <button class="btn btn-cancel text-center m-1" (click)="dismiss()">
      {{ app.text.modal.cancel }}
    </button>
    <button
      class="btn btn-ok text-center m-1"
      (click)="save()"
      [disabled]="hasRequiredFields()"
    >
      {{ app.text.part.save }}
    </button>
  </div>
</ng-template>
