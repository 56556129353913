<app-predefined-tasks
  #openPredefinedTasks
  [modalTitle]="app.text.post.addPredefinedTask"
>
</app-predefined-tasks>

<div>
  <div class="post-type btn-group btn-block">
    <button
      target="_blank"
      class="btn btn-post overrideButton text-center m-0"
      [ngClass]="{ 'btn-post-active': app.post.sectionType === 'stream' }"
      (click)="
        app.post.changePostSection('stream', 'myTasks', app.post.filterMode)
      "
    >
      {{ app.text.post.stream }}&nbsp;
      <span
        *ngIf="app.post.posts.length > 0"
        class="badge"
        [ngClass]="{ 'badge-active': app.post.sectionType === 'stream' }"
      >
        {{ app.post.posts.length }}
      </span>
    </button>
    <button
      class="btn btn-post overrideButton text-center m-0"
      [ngClass]="{ 'btn-post-active': app.post.sectionType === 'comment' }"
      (click)="
        app.post.changePostSection(
          'comment',
          app.post.sortingOrder,
          app.post.filterMode
        )
      "
    >
      {{ app.text.post.post }}&nbsp;

      <span
        *ngIf="app.post.numberOfComments > 0"
        class="badge"
        [ngClass]="{ 'badge-active': app.post.sectionType === 'comment' }"
      >
        {{ app.post.numberOfComments }}
      </span>
    </button>
    <button
      *ngIf="app.permission.post.hasTasks"
      class="btn btn-post overrideButton text-center m-0"
      [ngClass]="{ 'btn-post-active': app.post.sectionType === 'task' }"
      (click)="
        app.post.changePostSection('task', 'myTasks', app.post.filterMode)
      "
    >
      {{ app.text.post.task }}&nbsp;
      <span
        *ngIf="
          app.post.numberOfTasks > 0 && app.customer !== 'rheinmetallairdefence'
        "
        class="badge"
        [ngClass]="{ 'badge-active': app.post.sectionType === 'task' }"
      >
        {{ app.post.numberOfTasks }}
      </span>
      <span
        *ngIf="app.customer === 'rheinmetallairdefence'"
        class="badge"
        [ngClass]="{ 'badge-active': app.post.sectionType === 'task' }"
      >
        {{ app.post.numberOfOpenTasks }}
      </span>
    </button>
    <button
      class="btn btn-post overrideButton text-center m-0"
      [ngClass]="{ 'btn-post-active': app.post.sectionType === 'solution' }"
      (click)="
        app.post.changePostSection(
          'solution',
          app.post.sortingOrder,
          app.post.filterMode
        )
      "
    >
      {{ app.text.post.solution }}&nbsp;
      <span
        *ngIf="app.post.numberOfSolutions > 0"
        class="badge"
        [ngClass]="{ 'badge-active': app.post.sectionType === 'solution' }"
      >
        {{ app.post.numberOfSolutions }}
      </span>
    </button>
  </div>

  <div class="col-md-12 col-xl-12 col-lg-12">
    <!-- SOLUTION FIELDS -->
    <div
      class="m-1"
      *ngIf="app.post.postType === 'solution' && !app.post.isReadMode"
    >
      <div class="col-12 my-2">
        <div class="d-flex justify-content-between post px-3">
          <span class="pt-2">{{ app.text.post.leaveSol }}</span>
          <button
            class="btn btn-sm btn-clean"
            (click)="app.post.discardChanges()"
          >
            <i class="fas fa-times close-icon"></i>&nbsp;
            {{ app.text.post.cancel }}
          </button>
        </div>
      </div>
      <app-field
        *ngIf="
          app.post.postType === 'solution' ||
          (app.unlockedId !== null && app.post.isSolution)
        "
        [model]="app.post.model"
        [field]="app.fieldId.post.resolveClass"
        [showMandatory]="true"
        [labelInside]="true"
        [showLabel]="false"
      ></app-field>

      <div *ngIf="app.post.postType === 'solution'">
        <!-- Show fields based on the certain solution type list -->
        <div
          *ngFor="
            let field of app.post.getNewSolutionFieldsList(
              app.post.model[app.fieldId.post.resolveClass]
            )
          "
        >
          <app-field
            *ngIf="!app.list.post.excludedFieldsFromPost.includes(field)"
            [model]="app.post.model"
            [field]="field"
            [labelInside]="true"
            [showLabel]="false"
          ></app-field>
        </div>
      </div>
    </div>

    <!-- COMMON FIELDS FOR SOLUTIONS AND COMMENS -->
    <div
      class="m-1"
      *ngIf="app.post.postType !== 'task' && !app.post.isReadMode"
    >
      <div class="col-12 my-2">
        <div
          *ngIf="app.post.postType === 'comment'"
          class="d-flex justify-content-between post"
        >
          <span class="pt-2">{{ app.text.post.leaveComm }}</span>
          <button
            class="btn btn-sm btn-clean"
            (click)="app.post.discardChanges()"
          >
            <i class="fas fa-times close-icon"></i>&nbsp;
            {{ app.text.post.cancel }}
          </button>
        </div>
      </div>
      <app-field
        *ngIf="app.post.postType === 'comment' && app.customers.expectCurrent !== 'rheinmetallairdefence'"
        [model]="app.post.model"
        [field]="app.fieldId.post.title_comment"
        [labelInside]="true"
        [showLabel]="false"
      ></app-field>

      <!-- Aded it here because of the design of the form -->
      <div
        *ngIf="
          app.post.postType === 'comment' &&
          app.customers.expectCurrent === 'db'
        "
      >
        <app-field
          [model]="app.post.model"
          [field]="app.fieldId.post.responsibleRole"
          [labelInside]="true"
          [showLabel]="false"
        ></app-field>
      </div>
      <app-field
        [model]="app.post.model"
        [field]="app.fieldId.post.content"
        [labelInside]="true"
        [showLabel]="false"
      ></app-field>
    </div>

    <!-- Description of the selected solution type -- used for db -->
    <div *ngIf="showResolveClassDescription()" class="p-1">
      <ul>
        <li
          *ngFor="
            let text of getHelperText(app.post.model['post.resolveClass'])
          "
          class="resolveClassDescription"
        >
          {{ text }}
        </li>
      </ul>
    </div>

    <!-- TASKS FIELDS -->
    <div
      class="m-1"
      *ngIf="app.post.postType === 'task' && !app.post.isReadMode"
    >
      <div class="col-12 py-2">
        <div class="d-flex justify-content-between post">
          <span class="pt-2">{{ app.text.post.leaveTask }}</span>
          <button
            class="btn btn-sm btn-clean"
            (click)="app.post.discardChanges()"
          >
            <i class="fas fa-times close-icon"></i>&nbsp;
            {{ app.text.post.cancel }}
          </button>
        </div>
      </div>
      <div *ngFor="let postField of taskColumns">
        <app-field
          [model]="app.post.model"
          [field]="postField"
          [labelInside]="true"
          [showLabel]="false"
        ></app-field>
      </div>
    </div>

    <div
      *ngIf="app.post.sectionType !== 'stream'"
      class="d-flex justify-content-end me-2"
    >
      <button
        *ngIf="
          app.post.hasPermissionToAddPost(app.post.postType) &&
          !app.post.showSaveButton
        "
        class="btn btn-sm btn-default-red m-2"
        (click)="app.post.setRequiredFields(app.post.postType)"
        [disabled]="app.unlockedId != null"
      >
        <i class="fa fa-plus"></i>&nbsp;
        {{ app.post.getLabelBySectionType(app.post.sectionType) }}
      </button>
      <button
        *ngIf="app.post.showSaveButton"
        class="btn btn-sm btn-default-red m-2"
        [ngClass]="{ 'btn-save': app.unlockedId != null }"
        (click)="app.post.save()"
        [disabled]="!showSaveButton()"
      >
        <i
          [ngClass]="{
            'fas fa-plus': app.unlockedId == null,
            'fa fa-check': app.unlockedId != null
          }"
        ></i
        >&nbsp;
        {{
          app.unlockedId == null
            ? app.post.getLabelBySectionType(app.post.sectionType)
            : app.text.post.save
        }}
      </button>
      <button
        class="btn btn-sm btn-default-red m-2"
        *ngIf="
          app.permission.post.canAddTask &&
          app.customer === 'knds' &&
          app.post.postType === 'task'
        "
        (click)="openPredefinedTasks.open()"
        [disabled]="app.unlockedId != null"
      >
        <i class="fa fa-plus"></i>&nbsp;
        {{ app.text.post.addPredefinedTask }}
      </button>
    </div>
    <div
      *ngIf="app.post.sectionType === 'stream' && app.post.isReadMode"
      class="d-flex justify-content-end me-2"
    >
      <button
        *ngIf="app.permission.post.canAddComment"
        class="btn btn-sm btn-default-red m-2"
        (click)="app.post.setRequiredFields('comment')"
        [disabled]="app.unlockedId != null"
      >
        <i class="fa fa-plus"></i>&nbsp;
        {{ app.post.getLabelBySectionType("commnet") }}
      </button>
      <button 
        class="btn btn-sm btn-default-red m-2" 
        *ngIf="app.permission.post.canAddTask"
        (click)="app.post.setRequiredFields('task')" 
        [disabled]="app.unlockedId != null"
      >
        <i class="fa fa-plus"></i>&nbsp;
        {{ app.post.getLabelBySectionType("task") }}
      </button>
      <button
        *ngIf="app.permission.post.canAddSolution"
        class="btn btn-sm btn-default-red m-2"
        (click)="app.post.setRequiredFields('solution')"
        [disabled]="app.unlockedId != null"
      >
        <i class="fa fa-plus"></i>&nbsp;
        {{ app.post.getLabelBySectionType("solution") }}
      </button>
    </div>
    <div
      *ngIf="app.post.sectionType === 'stream' && !app.post.isReadMode"
      class="d-flex justify-content-end me-2"
    >
      <button
        *ngIf="app.post.showSaveButton"
        class="btn btn-sm btn-default-red m-2"
        [ngClass]="{ 'btn-save': app.unlockedId != null }"
        (click)="app.post.save()"
        [disabled]="!showSaveButton()"
      >
        <i
          [ngClass]="{
            'fas fa-plus': app.unlockedId == null,
            'fa fa-check': app.unlockedId != null
          }"
        ></i
        >&nbsp;
        {{
          app.unlockedId == null
            ? app.post.getLabelBySectionType(app.post.sectionType)
            : app.text.post.save
        }}
      </button>
      <button
        class="btn btn-sm btn-default-red m-2"
        *ngIf="
          app.permission.post.canAddTask &&
          app.post.postType === 'task' &&
          app.customer === 'knds'
        "
        (click)="openPredefinedTasks.open()"
        [disabled]="app.unlockedId != null"
      >
        <i class="fa fa-plus"></i>&nbsp;
        {{ app.text.post.addPredefinedTask }}
      </button>
    </div>
  </div>
</div>
<div *ngIf="app.unlockedId == null" class="row m-1">
  <div class="col-md-12">
    <!-- export list - only for DB -->
    <button
      *ngIf="app.customer === 'db'"
      class="btn btn-sm btn-clean pull-right"
      (click)="app.post.exportData()"
    >
      <i class="fa fa-download"></i>&nbsp; {{ app.text.post.export }}
    </button>
    <!-- quickFilters -->
    <div class="dropdown pull-right quickFilters">
      <button
        class="btn dropdown-toggle"
        type="button"
        id="quickFilters"
        data-bs-toggle="dropdown"
        aria-haspopup="true"
        aria-expanded="false"
      >
        <div class="icon-center me-1">
          <i class="fas fa-eye menu-icons"></i>
        </div>
        <span>&nbsp;{{ app.text.post.showMode }} &nbsp;</span>
        <span class="text-danger">
          {{ app.post.getPostFilterText(app.post.filterMode) }}
        </span>
      </button>
      <div class="dropdown-menu" aria-labelledby="quickFiltersDropdown">
        <a
          class="dropdown-item pointer"
          *ngFor="let filter of app.post.postFilters"
          (click)="
            app.post.getPostsByFilter(filter); app.post.isFilterSet = true
          "
          [ngClass]="{
            lightBackground: filter === 'byOthers'
          }"
          >{{ app.post.getPostFilterText(filter) }}</a
        >
      </div>
    </div>
    <!-- sortOptions -->
    <div class="dropdown pull-right sortOptions">
      <button
        class="btn dropdown-toggle"
        type="button"
        id="sortOptions"
        data-bs-toggle="dropdown"
        aria-haspopup="true"
        aria-expanded="false"
      >
        <div class="icon-center me-1">
          <i class="fas fa-sort-alt menu-icons"></i>
        </div>
        <span>&nbsp;{{ app.text.home.sort }}&nbsp;</span>
        <span class="text-danger" *ngIf="app.post.sortingOrder === 'descending'"
          >&nbsp;{{ app.text.post.newest }}&nbsp;</span
        >
        <span class="text-danger" *ngIf="app.post.sortingOrder === 'ascending'"
          >&nbsp;{{ app.text.post.oldest }}&nbsp;</span
        >
        <span class="text-danger" *ngIf="app.post.sortingOrder === 'myTasks'"
          >&nbsp;{{ app.text.post.myTasks }}&nbsp;</span
        >
      </button>
      <div class="dropdown-menu" aria-labelledby="sortOptions">
        <div class="dropdown-item pointer">
          <i class="fas fa-sort-amount-down text-danger"></i>
          <a
            (click)="
              app.post.sortingOrder = 'descending';
              app.post.sortPosts(
                app.post.posts,
                'descending',
                app.post.sectionType
              );
              app.post.isSortingOrderSet = true
            "
          >
            {{ app.text.post.sortToOldest }}</a
          >
        </div>
        <div class="dropdown-item pointer">
          <i class="fas fa-sort-amount-up text-danger"></i>
          <a
            (click)="
              app.post.sortingOrder = 'ascending';
              app.post.sortPosts(
                app.post.posts,
                'ascending',
                app.post.sectionType
              );
              app.post.isSortingOrderSet = true
            "
          >
            {{ app.text.post.sortToNewest }}
          </a>
        </div>
        <div class="dropdown-item pointer" *ngIf="showMyTasksOnTopSortation()">
          <i class="far fa-list-check text-danger"></i>
          <a
            (click)="
              app.post.sortingOrder = 'myTasks';
              app.post.sortPosts(
                app.post.posts,
                'myTasks',
                app.post.sectionType
              );
              app.post.isSortingOrderSet = true
            "
          >
            {{ app.text.post.sortToMyTasks }}
          </a>
        </div>
      </div>
    </div>
    <!-- KNDS project filter -->
    <div
      *ngIf="
        app.customers.expectCurrent === 'knds' &&
        (app.auth.isTeam || app.auth.isAdmin)
      "
      class="dropdown pull-right quickFilters"
    >
      <button
        class="btn dropdown-toggle"
        type="button"
        id="quickFilters"
        data-bs-toggle="dropdown"
        aria-haspopup="true"
        aria-expanded="false"
      >
        <div class="icon-center me-1">
          <i class="fa-solid fa-filter menu-icons"></i>
        </div>
        <span
          >&nbsp;
          {{ app.field.getLabel(app.fieldId.impact.projectNumber) }}&nbsp;</span
        >
        <span
          *ngFor="let filter of app.post.projectFilters"
          class="text-danger"
        >
          {{
            app.field.getOptionText(app.fieldId.impact.projectNumber, filter)
          }}
        </span>
      </button>
      <div *ngIf="app.post.projectNames.length > 0" class="dropdown-menu">
        <button
          class="dropdown-item pointer"
          *ngFor="let filter of app.post.projectNames"
          (click)="app.post.toggleOption(filter)"
        >
          <input
            [checked]="app.post.isOptionSelected(filter, model)"
            type="checkbox"
          />
          {{
            app.field.getOptionText(app.fieldId.impact.projectNumber, filter)
          }}
        </button>
      </div>
    </div>
  </div>
</div>
