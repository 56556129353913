import { ActivatedRoute } from "@angular/router";
import { Component, OnDestroy, OnInit, ViewChild } from "@angular/core";
import { getApp } from "../../app";
import { Part } from "../../../../../shared/models/part";
import { Subscription } from "rxjs";
import { Thread } from "../../../../../shared/models/thread";
import { getThreadsCompressed } from "../../api.service";

@Component({
  selector: "app-parts-filter-table",
  templateUrl: "./parts-filter-table.component.html",
  styleUrls: ["./parts-filter-table.component.scss"],
})
export class PartsFilterTableComponent implements OnInit, OnDestroy {
  @ViewChild("deletePartModal", { static: false })
  deletePartModal: any;
  loading = true;

  private _routeParamsSubscription: Subscription = new Subscription();

  app = getApp((app) => {
    this.app = app;
  });

  constructor(private route: ActivatedRoute) {}

  async ngOnInit() {
    this.app.type = "part";
    this._routeParamsSubscription = this.route.params.subscribe(
      async (params) => {
        //reset parts array when changing the view to avoid displaying the old data until the new one is loading
        this.app.partsTable.parts = [];
        this.app.partsTable.cleanParts = [];
        this.loading = true;

        if (params.mode != null) {
          this.app.RM.mode = params.mode;
        }
      }
    );
    this.app.partsTable.getPartsTableColumns(this.app.RM.mode);

    //reset filter component when change the section on RM all parts table
    this.app.filterHeaderTable.resetAllFilters.next(true);
    this.app.filterTree.resetFilterTree();
    this.app.partsTable.parts = this.app.partsTable.cleanParts;
    // update threadsCompressed in order to set the new added case in seeOpenedCasesModal
    if (this.app.thread.threadsCompressed.length === 0) {
      this.app.thread.threadsCompressed = await getThreadsCompressed(
        this.app.customers.expectCurrent,
        "thread"
      );
    }

    this.app.state.sortField = "part.partNumber";
    this.app.state.sortDirection = 1;
    await this.app.partsTable.getFilterResultBasedOnView("1");
    this.loading = false;
    this.app.table.selected.clear();
  }

  async getThreadsByPartNumber(id: string) {
    let threads: Partial<Thread>[] = [];
    threads = this.app.thread.threadsCompressed.filter(
      (thread: Partial<Thread>) => thread.artNumber === id
    );
    if (threads.length > 0) {
      this.app.thread.threadsByPartNumber = threads as any;
    } else {
      this.app.thread.threadsByPartNumber = [];
    }

    /**return the result sorted by omfNumber */
    let sortedData = this.app.thread.threadsByPartNumber.sort(
      (a: any, b: any) => {
        if (a["omfNumber"] < b["omfNumber"]) {
          return -1;
        } else {
          return 1;
        }
      }
    );
    return sortedData;
  }

  hasOpenCases(part: Partial<Part>) {
    let threadIndex = -1;
    if (this.app.thread.threadsCompressed !== undefined) {
      threadIndex = this.app.thread.threadsCompressed.findIndex(
        (thread) => thread.artNumber === part.partNumber
      );
      if (threadIndex !== -1 && part.partNumber !== "") {
        return true;
      } else {
        return false;
      }
    }
    return false;
  }

  deleteItem(part: Partial<Part>) {
    this.deletePartModal.open();
    this.app.partsTable.selectedPart = part;
  }

  ngOnDestroy(): void {
    if (this._routeParamsSubscription) {
      this._routeParamsSubscription.unsubscribe();
    }
  }
}
