<app-modal
  #deleteVehicleModal
  [modalMessage]="app.text.impact.deleteMessage"
  [modalTitle]="app.text.impact.modalTitle"
  (ok)="deleteVehicle()"
></app-modal>
<app-modal
  #discardChangesModal
  [modalMessage]="app.text.impact.discard"
  [modalTitle]="app.text.impact.modalTitle"
  (ok)="discardChanges()"
>
</app-modal>
<div class="controls">
  <app-unlock
    [id]="app.vehicleResponsible.id"
    [permission]="!app.vehicleResponsible.isNew"
  >
    <span
      *ngIf="
        app.vehicleResponsible.modelHasChanges || app.vehicleResponsible.isNew;
        then thenBlock;
        else elseBlock
      "
    ></span>
    <ng-template #thenBlock>
      <button
        class="btn"
        *ngIf="!hasInvalidFields() && !saveDisabled"
        (click)="
          app.vehicleResponsible.save(app.vehicleResponsible.model);
          this.app.field.inputSubject.next(true)
        "
        [title]="app.text.impact.save"
      >
        <i class="fa fa-check"></i>
      </button>
      <button
        class="btn"
        [title]="app.text.impact.discard"
        (click)="discardChangesModal.open()"
      >
        <i class="fa fa-times"></i>
      </button>
    </ng-template>

    <ng-template #elseBlock> </ng-template>
  </app-unlock>
  <span *ngIf="app.unlockedId == null">
    <span *ngIf="!app.vehicleResponsible.isNew">
      <button
        class="btn"
        (click)="deleteVehicleModal.open()"
        [title]="app.text.impact.deleteImpact"
      >
        <i class="fa fa-trash"></i>
      </button>
    </span>
  </span>
</div>
<div class="col-md-12 mt-5" *ngIf="app.customers.expectCurrent !== 'db'">
  <app-box
    [box]="app.listId.vehicleResponsible.vehicleResponsible"
    [unlock]="app.vehicleResponsible.id"
    [model]="app.vehicleResponsible.model"
  ></app-box>
</div>
<div class="descriptionList" *ngIf="app.customers.expectCurrent === 'db'">
  <div *ngIf="app.isLocked(app.vehicleResponsible.id)" class="col-md-12">
    <legend>
      <div class="heading">{{ app.text.app.data }}</div>
    </legend>
    <div class="field-label col-md-12" id="vehicleResponsible.vehicleName">
      {{ app.field.getLabel("vehicleResponsible.vehicleName") }}
    </div>
    <div class="lineHeight col-md-12" id="vehicleResponsible.vehicleName">
      {{ app.vehicleResponsible.model["vehicleResponsible.vehicleName"] }}
    </div>
    <div class="field-label col-md-12" id="vehicleResponsible.vehicleFleet">
      {{ app.field.getLabel("vehicleResponsible.vehicleFleet") }}
    </div>
    <div class="lineHeight col-md-12" id="vehicleResponsible.vehicleFleet">
      {{ app.vehicleResponsible.model["vehicleResponsible.vehicleFleet"] }}
    </div>
  </div>
  <div *ngIf="!app.isLocked(app.vehicleResponsible.id)" class="col-md-12">
    <app-field
      [model]="app.vehicleResponsible.model"
      [field]="app.fieldId.vehicleResponsible.vehicleName"
      [disabled]="app.isLocked(app.vehicleResponsible.id)"
    ></app-field>
    <app-field
      [model]="app.vehicleResponsible.model"
      [field]="app.fieldId.vehicleResponsible.vehicleFleet"
      [disabled]="app.isLocked(app.vehicleResponsible.id)"
    ></app-field>
  </div>
  <div class="col-md-12 mt-2">
    <div class="tableFixHead">
      <table class="table table-striped">
        <thead>
          <th class="p-1 text-center">
            {{ app.text.responsibles.department }}
          </th>
          <th class="p-1 text-center">BAV</th>
          <th class="p-1 text-center">MaWI</th>
          <th
            class="p-1"
            *ngIf="app.auth.isTeam && app.isLocked(app.impact.id)"
          >
            {{ app.field.getLabel("impact.actions") }}
          </th>
        </thead>
        <tbody *ngIf="!loading">
          <tr *ngFor="let department of departments" id="{{ department.name }}">
            <td class="p-1 text-center" id="{{ department.name }}">
              <span class="department-name">{{ department.name }}</span>
            </td>
            <td class="p-1">
              <div
                class="col-md-12 text-center"
                id="{{ department.fields.bav }}"
              >
                <div *ngIf="app.isLocked(app.vehicleResponsible.id)">
                  {{
                    app.vehicleResponsible.model[
                      "vehicleResponsible.responsibles"
                    ][department.fields.bav]
                  }}
                </div>
                <div *ngIf="!app.isLocked(app.vehicleResponsible.id)">
                  <app-field
                    [model]="
                      app.vehicleResponsible.model[
                        'vehicleResponsible.responsibles'
                      ]
                    "
                    [field]="department.fields.bav"
                    [disabled]="!app.isLocked(app.vehicleResponsible.id)"
                    [showLabel]="false"
                    [largeInput]="true"
                  ></app-field>
                </div>
                <!-- VERTRETER FIELDS / READ MODE-->
                <div
                  class="text-muted font-weight-light font-italic"
                  id="{{ department.fields.bavVertreter }}"
                  *ngIf="app.isLocked(app.vehicleResponsible.id)"
                >
                  <small
                    class="text-muted font-weight-light font-italic"
                    *ngIf="display(department.fields.bavVertreter)"
                    >{{ app.text.impact.deputy }}:
                    {{
                      app.vehicleResponsible.model[
                        "vehicleResponsible.responsibles"
                      ][department.fields.bavVertreter]
                    }}
                  </small>
                </div>

                <!-- VERTRETER FIELDS ON CREATE/EDIT MODE -->
                <span
                  *ngIf="!app.isLocked(app.vehicleResponsible.id)"
                  id="{{ department.fields.bavVertreter }}"
                >
                  <app-field
                    [model]="
                      app.vehicleResponsible.model[
                        'vehicleResponsible.responsibles'
                      ]
                    "
                    [field]="department.fields.bavVertreter"
                    [disabled]="app.isLocked(app.vehicleResponsible.id)"
                    [showLabel]="true"
                  ></app-field>
                </span>
              </div>
            </td>
            <td class="p-1">
              <div
                class="col-md-12 text-center"
                id="{{ department.fields.mawi }}"
              >
                <div *ngIf="app.isLocked(app.vehicleResponsible.id)">
                  {{
                    app.vehicleResponsible.model[
                      "vehicleResponsible.responsibles"
                    ][department.fields.mawi]
                  }}
                </div>
                <div *ngIf="!app.isLocked(app.vehicleResponsible.id)">
                  <app-field
                    [model]="
                      app.vehicleResponsible.model[
                        'vehicleResponsible.responsibles'
                      ]
                    "
                    [field]="department.fields.mawi"
                    [disabled]="app.isLocked(app.vehicleResponsible.id)"
                    [showLabel]="false"
                    [largeInput]="true"
                  ></app-field>
                </div>

                <div
                  *ngIf="app.isLocked(app.vehicleResponsible.id)"
                  class="text-muted font-weight-light font-italic"
                  id="{{ department.fields.mawiVertreter }}"
                >
                  <small
                    class="text-muted font-weight-light font-italic"
                    *ngIf="display(department.fields.mawiVertreter)"
                    >{{ app.text.impact.deputy }}:
                    {{
                      app.vehicleResponsible.model[
                        "vehicleResponsible.responsibles"
                      ][department.fields.mawiVertreter]
                    }}
                  </small>
                </div>
                <span
                  *ngIf="!app.isLocked(app.vehicleResponsible.id)"
                  id="{{ department.fields.mawiVertreter }}"
                >
                  <div *ngIf="app.isLocked(app.impact.id)">
                    {{
                      app.vehicleResponsible.model[
                        "vehicleResponsible.responsibles"
                      ][department.fields.mawiVertreter]
                    }}
                  </div>
                  <div *ngIf="!app.isLocked(app.impact.id)">
                    <app-field
                      [model]="
                        app.vehicleResponsible.model[
                          'vehicleResponsible.responsibles'
                        ]
                      "
                      [field]="department.fields.mawiVertreter"
                      [disabled]="app.isLocked(app.vehicleResponsible.id)"
                      [showLabel]="true"
                    ></app-field>
                  </div>
                </span>
              </div>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</div>
