import { Inject, Injectable } from "@angular/core";
import { doc2Model, model2Doc, savePcnGen } from "../api.service";
import { AppType, APP_TYPE } from "../app.type";
import { DocModel } from "../state/state";
import { PcnGeneratorServiceType } from "./pcn-generator.service.type";
import { Subject } from "rxjs";
import { pcnMapping } from "./pcn-mapping";
import { FileData, Pcn } from "../../../../shared/models/pcn";
import { ItemNumber } from "../../../../shared/models/item-number";
import { Router } from "@angular/router";
import { EmailDetails } from "../../../../shared/models/email-details";
import { EncodingUtils } from "../../../../shared/utils/encoding.utils";

@Injectable()
export class PcnGeneratorService implements PcnGeneratorServiceType {
  completedSection: string[] = [];
  pcnDoc: any = {} as Pcn;
  item: any = {} as ItemNumber;
  pcnModel: DocModel = {};
  currentItem: any = {};
  attachments: any[] = [];
  attachmentSubject: Subject<any[]> = new Subject();
  isPcnSent: boolean = false;
  isValidZip: boolean = false;
  allInvalidFields: any[] = [];
  isManually: boolean = false;
  cleanItemModel: any = {} as ItemNumber;
  itemIndex: number | null = null;
  public isPcnGenerator: boolean = false;
  switchContent: boolean = false;
  emailContent: { name: string; email: string } = { name: "", email: "" };

  importType: ImportType = ImportType.Manually;
  IMPORT = ImportType.Import;
  MANUALLY = ImportType.Manually;

  constructor(@Inject(APP_TYPE) private app: AppType, private router: Router) {}

  handlePcnGeneratorRoute(): void {
    if (this.router.url.includes("pcn")) {
      this.isPcnGenerator = true;
    } else {
      this.isPcnGenerator = false;
    }
  }

  createModel(item?: ItemNumber, index?: number) {
    this.cleanItemModel = Object.assign({}, item);

    if (index != null) {
      this.itemIndex = index;
    }

    if (item == null && index == null) {
      this.app.pcnGenerator.item = {} as ItemNumber;
      return;
    }
    if (item != null && index != null) {
      this.item = item;
    }
    return this.item;
  }

  createPcnDoc(doc: any) {
    /** result needs to be set to the wanted document  */
    const pcnKeys = Object.keys(doc);
    pcnKeys.forEach((key) => {
      const parseKey = pcnMapping[key];
      if (key === "ItemNumbers") {
        const items: any[] = [];
        doc[key].forEach((itemNumbersKey: any) => {
          let item = new ItemNumber();
          const itemModel = doc2Model("pcnGenerator", item);
          let id = "Doc" + Math.floor(Math.random() * 1000000);

          Object.keys(itemNumbersKey).forEach((itemKey) => {
            const parseKeyItem = pcnMapping[itemKey];
            itemModel[`pcnGenerator.${parseKeyItem}`] = itemNumbersKey[itemKey];
          });
          itemModel.id = id.toString();
          items.push(itemModel);
        });
        this.pcnModel[`pcnGenerator.${parseKey}`] = items;
      } else {
        this.pcnModel[`pcnGenerator.${parseKey}`] = doc[key];
      }
    });
    this.pcnModel["pcnGenerator._attachments"] =
      this.app.pcnGenerator.attachments;
    return this.pcnModel;
  }

  /** Create List of Item */
  createItem(item: ItemNumber) {
    /** create new item  - initially the item has no id*/
    if (item && item.id === undefined) {
      let newItem = new ItemNumber();
      const newItemModel = doc2Model("pcnGenerator", newItem);
      /** set a random id in order to be set on the model */
      let id = "Doc" + Math.floor(Math.random() * 1000000);
      Object.keys(newItemModel).forEach((key: string) => {
        newItemModel[key] = (item as any)[key];
      });
      newItemModel.id = id.toString();
      /** add item into the itemNumbers array */
      this.pcnModel["pcnGenerator.itemNumbers"].push(newItemModel);
    } else {
      /** update item */
      /** in the list of itemNumbers search for the item that has the current id - selected id from the table row */
      let index = this.pcnModel["pcnGenerator.itemNumbers"].findIndex(
        (i: any) => i.id === item["pcnGenerator.id"]
      );
      /** if there is an item with that id create a new doc from that  */
      if (index !== -1) {
        /** delete the existing item from the array in order to be replaced with the updated one */
        this.currentItem = model2Doc("pcnGenerator", item);
        this.pcnModel["pcnGenerator.itemNumbers"].splice(
          index,
          1,
          this.currentItem
        );
      }
    }
  }

  deleteItem(item: ItemNumber) {
    const index = this.pcnModel["pcnGenerator.itemNumbers"].indexOf(item);
    if (index > -1) {
      this.pcnModel["pcnGenerator.itemNumbers"].splice(index, 1);
    }
    if (index === 0 && this.pcnModel["pcnGenerator.itemNumbers"].length === 0) {
      this.deleteSection(this.app.listId.pcnGenerator.itemsBox);
      this.item = {} as ItemNumber;
    }
  }

  setDinCode(dinCode: string) {
    const itemIndex = this.itemIndex;
    let currentItem: ItemNumber = {} as ItemNumber;
    if (itemIndex != null) {
      // edit item
      currentItem =
        this.pcnModel["pcnGenerator.itemNumbers"][itemIndex as number];
    } else {
      // new item
      currentItem = this.item;
    }
    currentItem["pcnGenerator.dinCode"] = dinCode;
    currentItem["pcnGenerator.dinText"] = dinCode;
  }

  /** the save button will be enabled if all required fields are completed
   * and the email has a valid format
   */
  isPcnInvalid(invalidEmail: boolean) {
    this.allRequiredFields();

    if (this.allInvalidFields.length === 0 && !invalidEmail) {
      this.addSection("pcnGenerator.sendBox");
      return false;
    } else {
      this.deleteSection("pcnGenerator.sendBox");
      return true;
    }
  }

  checkCompleted(box: string) {
    if (box !== "pcnGenerator.sendBox") {
      this.toggleCompletedSections(box);
    }
    let checkComplete = this.completedSection.find(
      (element) => element === box
    );
    if (!checkComplete) {
      return false;
    } else {
      return true;
    }
  }

  /** check if all required fields in each box have been completed */
  hasInvalidFieldsByBox(box?: string) {
    const fieldsByBox = this.getRequiredFieldsByBox(box!);
    let isInvalid = false;
    if (fieldsByBox != null) {
      fieldsByBox.result.forEach((field: any) => {
        const isValid = this.app.field.isValid(field, fieldsByBox.model[field]);
        if (!isValid) {
          isInvalid = true;
        }
      });
    }
    return isInvalid;
  }

  allRequiredFields() {
    let invalidFieldsByBox: any = [];
    this.allInvalidFields = [];
    this.app.list.pcnGenerator.boxesLeft.forEach((box: string) => {
      let fieldsByBox = this.getRequiredFieldsByBox(box!);
      if (fieldsByBox !== null) {
        invalidFieldsByBox = fieldsByBox.result;
        let missingInvalidFields = invalidFieldsByBox.filter(
          (x: string) => !this.allInvalidFields.includes(x)
        );
        missingInvalidFields.forEach((x: string) =>
          this.allInvalidFields.push(x)
        );
      }
    });
    return this.allInvalidFields;
  }

  getRequiredFieldsByBox(
    box: string
  ): { boxName: string; result: any; model: any } | null {
    let model: any;
    if (box == null) {
      return null;
    }

    let fields = this.getFieldsByBox(box);
    if (box === "pcnGenerator.itemsBox") {
      if (this.app.pcnGenerator.isManually) {
        model = this.item;
      } else {
        model = this.pcnModel["pcnGenerator.itemNumbers"][0];
      }
    } else {
      model = this.pcnModel;
    }

    /** all invalid fields from all sections */
    let allInvalidFields = this.app.field
      .getInvalidFields("pcnGenerator", model)
      .map((field) => field);
    /** array of all required/invalid fields for each box */
    let result = fields.filter((x: string) =>
      allInvalidFields.some((y: string) => x === y)
    );
    let boxName = this.app.getText(box);
    return { boxName, result, model };
  }

  /** verifica daca nu se poate sa te folosesti doar de result.length */
  /** result.length ar trebui sa contina oricum si required fields din items */
  toggleCompletedSections(box: string) {
    let fieldsByBox = this.getRequiredFieldsByBox(box!);
    if (fieldsByBox != null) {
      if (fieldsByBox.result.length === 0) {
        this.addSection(box!);
      } else if (
        fieldsByBox.result.length > 0 &&
        this.completedSection.includes(box!)
      ) {
        this.deleteSection(box!);
      }
    }
  }

  addSection(box: string) {
    let boxCompleted = this.completedSection.includes(box!);
    if (!boxCompleted) {
      /** array of sections that have all required fields completed */
      this.completedSection.push(box!);
    }
  }

  deleteSection(box: string) {
    const index = this.completedSection.indexOf(box!);
    this.completedSection.splice(index, 1);
  }

  /** returns fields from each section */
  getFieldsByBox(box: string) {
    switch (box) {
      case "pcnGenerator.mainBox":
        return this.app.list.pcnGenerator["mainBox"];
      case "pcnGenerator.itemsBox":
        return this.app.list.pcnGenerator["itemDetail"];
      case "pcnGenerator.filesBox":
        return this.app.list.pcnGenerator["filesBox"];
      // case "pcnGenerator.sendBox":
      //   return this.app.list.pcnGenerator["sendBox"];
      default:
        return [];
    }
  }

  async save(
    customer: string = this.app.customers.expectCurrent,
    emailContent: { name: string; email: string },
    fileContent?: FileData
  ) {
    this.pcnDoc = model2Doc("pcnGenerator", this.pcnModel);
    delete this.pcnDoc._attachments;

    const itemNumbers = this.pcnDoc["itemNumbers"];
    delete this.pcnDoc["itemNumbers"];

    let items: any[] = [];
    itemNumbers.forEach((item: any) => {
      let model = model2Doc("pcnGenerator", item);
      items.push(model);
    });

    let newItemArray: any[] = [];
    items.forEach((item) => {
      let modelItem = { ...this.pcnDoc, ...item };
      modelItem.type = "thread";
      newItemArray.push(modelItem);
      newItemArray.forEach((modelItem) => {
        let currentDate = Date.now();
        modelItem.create_time = currentDate;
      });
    });

    const emailDetails: EmailDetails = {
      name: emailContent.name,
      email: emailContent.email,
      language: localStorage.getItem("LANGUAGE"),
    };
    let pdf = fileContent!;
    this.app.spinner.showSpinner();
    let result: any = await savePcnGen(
      customer,
      newItemArray,
      emailDetails,
      pdf
    );

    const files = this.pcnModel["pcnGenerator._attachments"];
    result.forEach(async (res: any) => {
      if (files != null) {
        for (let file of files) {
          const fileLink = [customer, "pcn", EncodingUtils.encodeBase64(res.id), file.name].join("/");
          await this.app.file.upload(file, fileLink, true);
        }
      }
    });
    this.app.spinner.hideSpinner();
    this.isPcnSent = true;
  }

  changePcnType(type: ImportType) {
    this.importType = type;
    this.app.leftNav.selectedBox = this.app.listId.pcnGenerator.mainBox;
    if (this.importType === ImportType.Manually) {
      this.app.pcnGenerator.completedSection.length = 0;
    } else {
      this.app.pcnGenerator.isManually = false;
    }
    this.clearModel();
    this.app.pcnGenerator.isPcnSent = false;
    this.app.pcnGenerator.emailContent = { name: "", email: "" };
  }

  clearModel() {
    Object.keys(this.app.pcnGenerator.pcnModel).forEach((key) => {
      this.app.pcnGenerator.pcnModel[key] = Array.isArray(
        this.app.pcnGenerator.pcnModel[key]
      )
        ? []
        : null;
    });
    this.app.pcnGenerator.item = {} as ItemNumber;
    if (this.importType === ImportType.Import) {
      this.switchContent = false;
    }
  }
}

export enum ImportType {
  Import = 1,
  Manually = 2,
}
