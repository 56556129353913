<div class="card my-3">
  <div class="text-center mt-2">
    <button
      *ngFor="let name of app.graphs.names"
      type="button"
      class="btn btn-sm m-1"
      [ngClass]="{ 'btn-default-orange': name === graphName }"
      (click)="app.routing.navigateGraphs(name)"
    >
      {{ app.getText("graphs." + name) }}
    </button>
  </div>

  <h1 *ngIf="graphName != null" style="text-align: center">
    {{ app.getText("graphs." + graphName) }}
  </h1>

  <div class="px-3" *ngIf="app.graphs.getSortOptions().length > 0">
    <span>{{ app.text.graphs.sortBy }}</span>

    <button
      class="btn btn-sm m-1"
      *ngFor="let option of app.graphs.getSortOptions()"
      (click)="app.graphs.sortBy = option"
      [ngClass]="{ 'btn-default-orange': app.graphs.sortBy === option }"
    >
      {{ app.getText("graphs.sortBy_" + option) }}
    </button>
  </div>

  <div
    class="col-md-12 text-center font-weight-bold"
    *ngIf="!results || results.length === 0"
  >
    {{ app.text.app.loading }}
  </div>
  <div
    class="chart-container mb-3"
    *ngIf="results && results.length > 0"
    [ngStyle]="graphDimensions"
    [ngSwitch]="graphConfig.type"
  >
    <ngx-charts-bar-horizontal
      *ngSwitchCase="'bar-horizontal'"
      (select)="onSelectColumn($event, app.graphs.name)"
      [showDataLabel]="true"
      [results]="results"
      [scheme]="graphConfig.colorScheme"
      [xAxis]="graphConfig.showXAxis"
      [yAxis]="graphConfig.showYAxis"
      [legend]="graphConfig.showLegend"
      [showXAxisLabel]="graphConfig.xAxisLabel != null"
      [showYAxisLabel]="graphConfig.yAxisLabel != null"
      [xAxisLabel]="graphConfig.xAxisLabel"
      [yAxisLabel]="graphConfig.yAxisLabel"
      [animations]="false"
    >
    </ngx-charts-bar-horizontal>

    <ngx-charts-bar-horizontal-2d
      *ngSwitchCase="'bar-horizontal-2d'"
      [showDataLabel]="true"
      [results]="results"
      [scheme]="graphConfig.colorScheme"
      [xAxis]="graphConfig.showXAxis"
      [yAxis]="graphConfig.showYAxis"
      [legend]="graphConfig.showLegend"
      [showXAxisLabel]="graphConfig.xAxisLabel != null"
      [showYAxisLabel]="graphConfig.yAxisLabel != null"
      [animations]="false"
    ></ngx-charts-bar-horizontal-2d>

    <ngx-charts-bar-vertical-2d
      *ngSwitchCase="'bar-vertical-2d'"
      (select)="onSelectColumn($event, app.graphs.name)"
      [showDataLabel]="true"
      [results]="results"
      [scheme]="graphConfig.colorScheme"
      [xAxis]="graphConfig.showXAxis"
      [yAxis]="graphConfig.showYAxis"
      [legend]="graphConfig.showLegend"
      [showXAxisLabel]="graphConfig.xAxisLabel != null"
      [showYAxisLabel]="graphConfig.yAxisLabel != null"
      [animations]="false"
    ></ngx-charts-bar-vertical-2d>

    <ngx-charts-bar-vertical-stacked
      *ngSwitchCase="'bar-vertical-stacked'"
      (select)="onSelectColumn($event, app.graphs.name)"
      [showDataLabel]="true"
      [results]="results"
      [scheme]="graphConfig.colorScheme"
      [xAxis]="graphConfig.showXAxis"
      [yAxis]="graphConfig.showYAxis"
      [legend]="graphConfig.showLegend"
      [showXAxisLabel]="graphConfig.xAxisLabel != null"
      [showYAxisLabel]="graphConfig.yAxisLabel != null"
      [animations]="false"
      [barPadding]="50"
    ></ngx-charts-bar-vertical-stacked>

    <ngx-charts-line-chart
      *ngSwitchCase="'line-chart'"
      [results]="results"
      [scheme]="graphConfig.colorScheme"
      [xAxis]="graphConfig.showXAxis"
      [yAxis]="graphConfig.showYAxis"
      [legend]="graphConfig.showLegend"
      [showXAxisLabel]="graphConfig.xAxisLabel != null"
      [showYAxisLabel]="graphConfig.yAxisLabel != null"
      [animations]="false"
    ></ngx-charts-line-chart>
  </div>

  <div class="mt-3 p-3" *ngIf="graphName === 'dinCode'">
    <div *ngFor="let code of dinCodesWithText">
      {{ code.dinCode }} {{ code.dinCodeText }}
    </div>
  </div>
</div>
