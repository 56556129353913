import { Inject, Injectable } from "@angular/core";
import { BehaviorSubject } from "rxjs";
import { Manufacturer, SVHCItem } from "../../../../shared/models/manufacturer";
import { model2Doc } from "../api.service";
import { AppType, APP_TYPE } from "../app.type";
import { DocModel } from "../state/state";
import { casNumberOptions } from "./casNumber-options";
import { SVHCItemsServiceType } from "./SVHC-items.service.type";

/**Substances of Very High Concern */
@Injectable()
export class SVHCItemsService implements SVHCItemsServiceType {
  selected = new Set<SVHCItem>();

  manufacturer: Manufacturer = new Manufacturer();

  substances: SVHCItem[] = [];

  itemSubject = new BehaviorSubject(false);

  casNumberOptions: string[] = [];

  identificationOptions: string[] = [];

  constructor(@Inject(APP_TYPE) private app: AppType) {}

  toggleSelect(substance: SVHCItem) {
    if (this.selected.has(substance)) {
      this.selected.delete(substance);
    } else {
      this.selected.add(substance);
    }
    return this.selected;
  }

  selectAll() {
    let svchArray =
      this.app.manufacturer.currentManufacturer["manufacturer.svhcItems"];
    svchArray.forEach((element: SVHCItem) => {
      this.selected.add(element);
    });
    return this.selected;
  }

  clearSelection() {
    this.selected = new Set();
    return this.selected;
  }

  async delete() {
    let selected = Array.from(this.selected);
    selected.forEach((item) => {
      this.app.manufacturer.currentManufacturer[
        "manufacturer.svhcItems"
      ].splice(
        this.app.manufacturer.currentManufacturer[
          "manufacturer.svhcItems"
        ].indexOf(item),
        1
      );
    });

    await this.app.manufacturer.save(this.manufacturer);
    this.clearSelection();
    this.itemSubject.next(true);
  }

  async add(model: DocModel) {
    let substance = model2Doc("SVHCItems", model);

    /** set thresholdLimit based on the concentration just inputed */
    if (Number(substance.concentration) >= 1000) {
      substance.thresholdLimitExceeded = true;
    } else {
      substance.thresholdLimitExceeded = false;
    }
    /*** */

    substance.concentration = substance.concentration.toString();
    if (
      this.app.manufacturer.currentManufacturer["manufacturer.svhcItems"] ==
        null ||
      this.app.manufacturer.currentManufacturer["manufacturer.svhcItems"] === ""
    ) {
      this.app.manufacturer.currentManufacturer["manufacturer.svhcItems"] = [
        substance,
      ];
    } else {
      this.app.manufacturer.currentManufacturer["manufacturer.svhcItems"].push(
        substance
      );
    }

    await this.app.manufacturer.save(this.manufacturer);
    this.itemSubject.next(true);
  }

  getOptions() {
    let numberOptions = new Set<any>();
    let identificationOptions = new Set<any>();
    /** get the list of possible options for
     * CAS Number field & Substance Identification
     * and store the into an array*/
    Object.keys(casNumberOptions).forEach((key) => {
      numberOptions.add(key);
      let { en } = casNumberOptions[key];
      identificationOptions.add(en);
    });

    this.casNumberOptions = Array.from(numberOptions);
    this.identificationOptions = Array.from(identificationOptions);
  }

  setOptions(option: string, model: DocModel, field: string) {
    let result: any[] = [];
    /** if the current field is casNumber then set the
     * substanceIdentification that matches
     * that casNumber */
    if (field === "SVHCItems.casNumber") {
      result = Object.keys(casNumberOptions)
        .filter((key: any) => key === option)
        .map((item: any) => casNumberOptions[item].en);
      model["SVHCItems.substanceIdentification"] = result[0];
    } else {
      /** if the current field is substanceIdentification then set the
       * casNumber that matches that
       * description*/
      result = Object.keys(casNumberOptions)
        .filter((key: any) => casNumberOptions[key].en === option)
        .map((o: any) => o);
      model["SVHCItems.casNumber"] = result[0];
    }
    return model;
  }

  getItemsNotEmpty() {
    let items: SVHCItem[] =
      this.app.manufacturer.currentManufacturer["manufacturer.svhcItems"] !=
        null &&
      this.app.manufacturer.currentManufacturer["manufacturer.svhcItems"] !== ""
        ? this.app.manufacturer.currentManufacturer["manufacturer.svhcItems"]
        : [];

    /** the svhc items are created even though the 3 properties could be missing */
    /** do not display the item if its missing these 3 properties - since they are required in the UI */
    let index = items.findIndex(
      (item: SVHCItem) =>
        item.substanceIdentification === "" &&
        item.casNumber === "" &&
        item.concentration === ""
    );
    if (index !== -1) {
      items.splice(index, 1);
    }

    return items;
  }
}
