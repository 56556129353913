import { ComponentSettings } from "../settings/settings";

const settings = {
  text: {
    cases: {
      de: "Fälle",
      en: "Cases",
      nl: "Gevallen",
    },
    OM: {
      de: "Obsoleszenz Management",
      en: "Obsolescence Management",
      nl: "Obsolescence Management",
    },
    RM: {
      de: "Risiko Management",
      en: "Risk Management",
      nl: "Risk Management",
    },
    profile: {
      de: "Benutzerkonto",
      en: "User Account",
      nl: "Gebruikersaccount",
    },
    users: {
      en: "User Management",
      de: "Benutzermanagement",
      nl: "Gebruikersbeheer ",
    },
    charts: {
      de: "Statistik",
      en: "Charts",
      nl: "Grafieken",
    },
    searchInComponent: {
      de: "Suche in Komponentendatenbank",
      en: "Search from Component Database",
      nl: "Zoeken in de database met componenten",
    },
    notification: {
      de: "Benachrichtigungen",
      en: "Notifications",
      nl: "Notificaties",
    },
    logout: { de: "Abmelden", en: "Logout", nl: "Afmelden" },
    import: {
      de: "Daten importieren",
      en: "Import Data",
      nl: "Gegevens importeren",
    },
    export: {
      de: "Exportiere...",
      en: "Export...",
      nl: "Exporteren...",
    },
    help: {
      de: "Hilfe",
      en: "Help",
      nl: "Help",
    },
    helpInfo: {
      de: "Hilfreiche Informationen",
      en: "Helpful Information",
      nl: "Handige informatie",
    },
    info: {
      de: "Unter den folgenden Links finden Sie nützliche Informationen über unsere App",
      en: "In the links bellow you can find useful information about our app",
      nl: "Onder de volgende links vindt u handige informatie over onze app",
    },
    poweredBy: {
      de: "- angetrieben von",
      en: "- powered by",
      nl: "- mogelijk gemaakt door",
    },
    and: {
      de: "und",
      en: "and",
      nl: "en",
    },
  },
  url: {},
  field: {},
  list: {},
  permission: { seeClientName: false },
};

export const sidebar: ComponentSettings<typeof settings> = settings;
