<!-- Table with parts views  -->
<app-paginator
  [length]="app.partsTable.size"
  [index]="app.paginator.focus"
  (focus)="app.paginator.focus = $event"
  [type]="'partsTable'"
></app-paginator>
<app-modal
  #deletePartModal
  [modalMessage]="app.text.part.deletePart"
  [modalTitle]="app.text.part.deleteModal"
  (ok)="app.detailTable.deleteBOMItem('part')"
></app-modal>

<div>
  <button
    class="btn btn-sm btn-default-orange pull-right my-2"
    *ngIf="app.table.selected.size > 0"
    (click)="deletePartModal.open()"
  >
    <i class="fas fa-trash"></i> &nbsp;
    {{ app.text.app.delete }}
  </button>
</div>
<div
  class="tableFixHead clear"
  [ngClass]="{ tableHeight: app.filterTable.isDropdownOpen }"
>
  <table class="table table-striped spacing-table">
    <thead>
      <tr>
        <th
          class="p-1"
          *ngFor="let column of app.partsTable.partsTableColumns"
          [title]="app.table.setHeaderTooltip('part.' + column)"
        >
          <div class="d-inline-flex">
            <div
              [ngClass]="{
                'resize-table': app.table.canColumnBeResized(column)
              }"
            >
              <div
                class="pointer"
                (click)="
                  app.table.toggleSortDirection('part.' + column);
                  app.table.sort(app.partsTable.parts)
                "
                [innerHtml]="app.partsTable.getPartsHeaderLabels(column)"
                [ngClass]="app.table.getSizeOfColumn(column)"
              ></div>
            </div>
          </div>
          &nbsp;
          <span *ngIf="'part.' + column === app.table.sortField">
            <i
              *ngIf="app.table.sortDirection === 1"
              class="fa fa-chevron-up"
            ></i>
            <i
              *ngIf="app.table.sortDirection === -1"
              class="fa fa-chevron-down"
            ></i>
          </span>
          <div *ngIf="app.filterTable.getFilterTableColumns(column)">
            <app-filter-header-table [column]="column" [docType]="'part'">
            </app-filter-header-table>
          </div>
          <!-- <div *ngIf="app.filterTable.getFilterTableColumns(column)">
            <app-filter-table
              [column]="column"
              [columns]="app.partsTable.getPartsTableColumns()"
              [docType]="'part'"
              [cleanDocs]="app.partsTable.cleanParts"
              [selectedFilters]="selectedFilters"
              [resetComponent]="resetFilterInputsObservable"
              (resultedDocs)="app.filterTable.getFilterResults($event, 'part')"
            >
            </app-filter-table>
          </div> -->
        </th>
        <th class="p-1" *ngIf="app.RM.mode === 'parts-to-be-deleted'">
          {{ app.text.app.delete }}
          <div>
            <i
              class="far fa-square"
              *ngIf="app.table.selected.size === 0"
              (click)="app.table.selectAll(app.partsTable.parts)"
            ></i>
            <i
              class="far fa-check-square"
              *ngIf="app.table.selected.size > 0"
              (click)="app.table.clearSelection()"
            ></i>
          </div>
        </th>
      </tr>
    </thead>
    <tbody>
      <tr *ngFor="let id of app.partsTable.parts">
        <td class="p-1" *ngFor="let column of app.partsTable.partsTableColumns">
          <div [ngSwitch]="app.table.getColumnTypeDisplay(column)">
            <span *ngSwitchCase="'normal'">
              <span *ngIf="column !== 'stockRange'">
                {{ id[column] }}
              </span>
              <span *ngIf="column === 'stockRange' && id[column] > -1">
                {{ id[column] }}
              </span>
            </span>
            <div *ngSwitchCase="'textCenter'" class="text-center">
              {{ id[column] }}
            </div>
            <div *ngSwitchCase="'navigateAction'">
              <a
                class="partNumber"
                (click)="app.part.navigateToPartDetails(id[column])"
              >
                {{ id[column] }}
              </a>
            </div>
            <div *ngSwitchCase="'badge'">
              <span
                class="badge text-center"
                [ngStyle]="app.field.getColor('part.' + column, id[column])"
              >
                {{ app.field.getOptionText("part." + column, id[column]) }}
              </span>
            </div>
            <div *ngSwitchCase="'radio'">
              <i class="far fa-dot-circle" *ngIf="id[column] === 'true'"></i>
              <i class="fal fa-circle" *ngIf="id[column] === 'false'"></i>
            </div>
            <div
              *ngSwitchCase="'partsToVehicles'"
              (click)="
                seeStructureModal.open();
                app.treeRow.generateImpactsFromParts(id['partNumber'])
              "
              [title]="app.field.getLabel(app.fieldId.part.partsToVehicles)"
            >
              <i class="fas fa-sitemap fa-rotate-270 pointer icon-blue"></i>
            </div>
            <div *ngSwitchCase="'actions'">
              <span *ngIf="column == 'threadCase'">
                <i
                  class="fas fa-folder-open icon-blue pointer p-1"
                  *ngIf="hasOpenCases(id)"
                  (click)="
                    seeOpenedCasesModal.open();
                    app.treeCell.currentRow = id;
                    getThreadsByPartNumber(id._id)
                  "
                ></i>
              </span>
            </div>
            <div class="fixedWidth" *ngSwitchCase="'limit'">
              <span (click)="$event.stopPropagation()">
                <app-limit [textInput]="id[column]" limit="20"> </app-limit>
              </span>
            </div>
          </div>
        </td>
        <td class="p-1" *ngIf="app.RM.mode === 'parts-to-be-deleted'">
          <button
            class="btn btn-hidden pt-0 ps-0"
            (click)="app.table.toggleSelect(id); $event.stopPropagation()"
          >
            <i
              class="far fa-check-square"
              *ngIf="app.table.selected.has(id)"
            ></i>
            <i class="far fa-square" *ngIf="!app.table.selected.has(id)"></i>
          </button>
        </td>
      </tr>
      <tr>
        <td *ngIf="loading" colspan="99" class="text-center">
          {{ this.app.text.core.loading }}
        </td>
        <td
          *ngIf="!loading && app.partsTable.parts.length === 0"
          colspan="99"
          class="text-center"
        >
          {{ this.app.text.core.noResults }}
        </td>
      </tr>
    </tbody>
  </table>
</div>
<app-paginator
  [length]="app.partsTable.size"
  [index]="app.paginator.focus"
  (focus)="app.paginator.focus = $event"
  [type]="'partsTable'"
></app-paginator>

<app-modal
  #seeStructureModal
  [modalTitle]="app.text.part.whereUsed"
  [showButtons]="false"
  [modalType]="'showWhereUsed'"
>
</app-modal>
<app-modal
  #seeOpenedCasesModal
  [modalTitle]="app.text.thread.threads"
  [modalType]="'showListOfCases'"
  [showButtons]="false"
>
</app-modal>
