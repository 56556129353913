import { Component, OnInit } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { Pcn } from "../../../../shared/models/pcn";
import { doc2Model, model2Doc, updatePcn } from "../api.service";
import { getApp } from "../app";

@Component({
  selector: "app-pcn",
  templateUrl: "./pcn.component.html",
  styleUrls: ["./pcn.component.scss"],
})
export class PcnComponent implements OnInit {
  selectedBox = "";
  pcnId: string | null = null;
  pcnDocument: any = {} as Pcn;
  ids: string[] = [];

  app = getApp((app) => {
    this.app = app;
  });

  constructor(private route: ActivatedRoute) {}

  async ngOnInit() {
    this.app.leftNav.selectedBox = this.app.listId.pcn.main;
    // this.app.leftNav.resetContainer();

    // Access it from paramMap because state takes a while to initialize.
    // After ngOnInit you can get the current pcnId from this.app.pcn.id or this.pcnId as well
    this.pcnId = this.route.snapshot.paramMap.get("pcnId");

    if (this.pcnId != null) {
      this.app.pcns.pcnId = this.pcnId;
      this.pcnDocument = await this.app.pcn.getPcn(this.pcnId);
    }

    this.getUnreadPcns();
    this.markPcnsAsSeen();
    await this.app.pcns.getAllPcns();
    this.ids = this.app.pcns.pcns.map((pcn: any) => pcn._id);
  }

  getUnreadPcns() {
    let unreadPcns =
      this.app.pcns.unreadPcns.length !== 0
        ? this.app.pcns.unreadPcns.length - 1
        : 0;
    this.app.state.next({ numberOfUnreadPcns: unreadPcns });
  }

  async markPcnsAsSeen() {
    const customer = this.app.customers.expectCurrent;

    if (this.pcnDocument[this.app.fieldId.pcn.seen]) {
      return;
    }

    this.pcnDocument[this.app.fieldId.pcn.seen] = true;
    this.pcnDocument = model2Doc("pcn", this.pcnDocument);
    const pcn = await updatePcn(customer, this.pcnDocument);
    this.pcnDocument = doc2Model("pcn", pcn);
  }
}
