import { Component, OnDestroy, OnInit, ViewChild } from "@angular/core";
import { getApp } from "../app";
import { Manufacturer } from "../../../../shared/models/manufacturer";
import { Subscription } from "rxjs";
import { model2Doc } from "../api.service";
import { ActivatedRoute } from "@angular/router";
import { getThreadsCompressed } from "../api.service";
import { StringUtils } from "../../../../shared/utils/string.utils";
import { DataProvider } from "../../../../shared/constants/dataProvider";

@Component({
  selector: "app-manufacturer",
  templateUrl: "./manufacturer.component.html",
  styleUrls: ["./manufacturer.component.scss"],
})
export class ManufacturerComponent implements OnInit, OnDestroy {
  @ViewChild("replacementsModal", { static: false }) replacementsModal: any;
  @ViewChild("svhcModal", { static: false }) svhcModal: any;
  @ViewChild("pcnHistoryModal", { static: false }) pcnHistoryModal: any;
  @ViewChild("cancelOverrideModeModal", { static: false })
  cancelOverrideModeModal: any;
  @ViewChild("fileInput", { static: false })
  fileInput: any;

  manufacturerId: string | null = null;
  isNewTab: boolean = history.length > 1 ? false : true;
  manufacturerObject = new Manufacturer();

  /** Boxes used in read mode */
  boxesLeft: string[] = [];
  boxesLeftEditMode: string[] = [];

  image: string = "";
  manualOverride: boolean = false;

  status: string = "";
  subTitleFieldsList: string[] = [];

  currentModel = new Manufacturer();

  private _routeParamsSubscription: Subscription = new Subscription();
  private _newPostSubscription: Subscription = new Subscription();
  private _attachmentEdited: Subscription = new Subscription();
  private _newManufacturerSubscription: Subscription = new Subscription();

  app = getApp((app) => {
    this.app = app;
  });

  constructor(private route: ActivatedRoute) {}

  async ngOnInit(): Promise<void> {
    // Subscribe to params from the route to get the thread id
    this._routeParamsSubscription = this.route.params.subscribe(
      async (param) => {
        if (param.manufacturerId !== undefined) {
          this.manufacturerId = param.manufacturerId;
        }
      }
    );

    // Initialize boxes
    this.initializeBoxes();

    // Get default selected box
    this.getSelectedBox();

    // Get current manufacturer
    if (this.manufacturerId != null) {
      await this.app.manufacturer.getManufacturer(this.manufacturerId);
    }

    // Set the title of the tab based on current manufacturerPartNumber
    this.app.routing.setTabTitle(
      this.app.manufacturer.currentManufacturer[
        this.app.fieldId.manufacturer.manufacturerPartNumber
      ] + " - LCM Client"
    );

    // Initialize manufacturer
    this.app.manufacturer.initializeManufacturer();

    //Get fields list for sub title
    this.subTitleFieldsList = this.getSubTtitleFieldsList();

    // Assign the matches from the SEData database
    await this.assignMatches();

    // Get the current visible boxes based on data provider part id
    this.getVisibleBoxes();

    // Get messages
    if (this.manufacturerId != null) {
      await this.app.message.getMessages(this.manufacturerId);
    }

    this.app.state.createFromPart = true;

    this._attachmentEdited = this.app.file.attachmentsInfo.subscribe(
      (value: any) => {
        if (
          value &&
          this.app.manufacturer.currentManufacturer[
            "manufacturer._attachments"
          ] &&
          !Object.keys(
            this.app.manufacturer.currentManufacturer[
              "manufacturer._attachments"
            ]
          ).includes("Bild.jpg")
        ) {
          this.app.manufacturer.externalImage = "";
        }
        if (
          !StringUtils.isNullOrEmpty(
            this.app.manufacturer.currentManufacturer["manufacturer.image"]
          )
        ) {
          this.app.manufacturer.externalImage =
            this.app.manufacturer.currentManufacturer["manufacturer.image"];
        }
      }
    );

    this.app.manufacturer.getImageExternal();
    this.app.manufacturer.hasDeleteImagePermission();

    /**if the manual override is true, the user should be able to upload a new image to the MPN Details */
    this.manualOverride =
      this.app.manufacturer.currentManufacturer[
        "manufacturer.obsolescenceStatusOverride"
      ];

    this.app.type = "manufacturer";
    this.app.thread.threadsCompressed = await getThreadsCompressed(
      this.app.customers.expectCurrent,
      "thread"
    );

    this.currentModel = model2Doc(
      "manufacturer",
      this.app.manufacturer.currentManufacturer
    );

    this.status = this.app.manufacturer.getMatchingStatusOfManufacturer(
      this.manufacturerObject
    );

    this._newManufacturerSubscription =
      this.app.manufacturer.manufacturerAction.subscribe(async (value) => {
        if (value) {
          this.currentModel = model2Doc(
            "manufacturer",
            this.app.manufacturer.currentManufacturer
          );
        }
      });

    this.app.manufacturer.subjectStatus.subscribe((value) => {
      if (value) {
        this.status = value;
      }
    });
  }

  async assignMatches(): Promise<void> {
    this.manufacturerObject = model2Doc(
      "manufacturer",
      this.app.manufacturer.currentManufacturer
    );
    let matches = await this.app.manufacturer.getMatches(
      this.manufacturerObject
    );

    if (matches != null) {
      this.manufacturerObject.matches = matches;
      this.manufacturerObject.numberOfMatches = matches.length.toString();
    }
  }

  async getFieldAction() {
    const field = this.app.field.current;
    let fieldName =
      this.app.customers.dataProvider === DataProvider.Z2DATA
        ? this.app.fieldId.manufacturer.z2DataId
        : this.app.fieldId.manufacturer.seId;
    let dataProviderId = this.app.manufacturer.currentManufacturer[fieldName];

    switch (field) {
      case this.app.fieldId.manufacturer.obsolescenceStatusOverride:
        if (this.app.manufacturer.currentManufacturer[field]) {
          this.app.manufacturer.makeFieldsMutable(true);
          this.app.manufacturer.currentManufacturer[fieldName] = "";
        }
        if (
          this.app.manufacturer.currentManufacturer[field] === false &&
          this.app.unlockedId != null
        ) {
          // keep the checkbox checked in case the user close the modal
          this.app.manufacturer.currentManufacturer[field] = true;
          this.cancelOverrideModeModal.open();
        }
        break;
      case this.app.fieldId.manufacturer.estimatedEOLDate:
      case this.app.fieldId.manufacturer.estimatedYearsToEOL:
      case this.app.fieldId.manufacturer.productEOSR:
      case this.app.fieldId.manufacturer.productYearsToEOSR:
        if (this.app.unlockedId != null) {
          this.app.manufacturer.getInputChanges(
            this.app.manufacturer.currentManufacturer,
            field
          );
          break;
        }
      case this.app.fieldId.manufacturer.loadReplacements:
        if (this.app.leftNav.selectedBox === "manufacturer.tehnicalSection") {
          this.getCrossesAndReplacements();
        }
        break;
      case this.app.fieldId.manufacturer.svhcItems:
        this.svhcModal.open();
        break;
      case this.app.fieldId.manufacturer.pcnHistory:
        this.app.manufacturerUtils.pcnHistoryLoading = true;
        this.app.manufacturerUtils.pcnIds = [];
        this.app.manufacturerUtils.pcnHistoryDocs = [];

        await this.app.manufacturerUtils.getCurrentDetails(dataProviderId);

        this.app.manufacturerUtils.pcnHistoryLoading = false;
        this.pcnHistoryModal.open();
    }
  }

  async getCrossesAndReplacements() {
    this.replacementsModal.open();
    this.app.field.current = null;
  }

  discardChanges() {
    this.app.manufacturer.id = this.app.manufacturer.id;
    this.app.unlockedId = null;
    this.app.manufacturer.currentManufacturer = Object.assign(
      {},
      this.app.manufacturer.cleanManufacturer
    );
  }

  private getSelectedBox(): void {
    const selectedBox: string =
      this.app.leftNav.selectedBox != null ? this.app.leftNav.selectedBox : "";
    this.app.leftNav.selectedBox =
      this.app.list.manufacturer.boxesLeft.indexOf(selectedBox) === -1
        ? "manufacturer.clientSection"
        : this.app.leftNav.selectedBox;
  }

  private initializeBoxes(): void {
    this.boxesLeft = this.app.list.manufacturer.boxesLeft;
    this.boxesLeftEditMode = this.app.list.manufacturer.boxesLeftEditMode;
  }

  private getVisibleBoxes(): void {
    let dataProviderId =
      this.app.customers.dataProvider === DataProvider.Z2DATA
        ? this.app.manufacturer.currentManufacturer[
            this.app.fieldId.manufacturer.z2DataId
          ]
        : this.app.manufacturer.currentManufacturer[
            this.app.fieldId.manufacturer.seId
          ];
    const index = this.boxesLeft.findIndex(
      (box) => box === "manufacturer.matchingSection"
    );
    const indexEdit = this.boxesLeftEditMode.findIndex(
      (box) => box === "manufacturer.matchingSection"
    );

    if (StringUtils.isNullOrEmpty(dataProviderId)) {
      /** https://app.asana.com/0/1199156146806847/1199240058967634 */
      if (index !== -1 && indexEdit !== -1) {
        this.boxesLeft.splice(index, 1);
        this.boxesLeftEditMode.splice(indexEdit, 1);
      }
    }
  }

  async onUpload() {
    const file: File = this.fileInput.nativeElement.files[0];
    if (file.type.indexOf("image/") !== 0 && file != null) {
      this.app.hasError = true;
      this.app.errorText = this.app.text.thread.uploadImageTypeError;
    } else {
      this.app.hasError = false;
      this.app.errorText = "";
      const fileLink = this.app.manufacturer.getImage();

      await this.app.file.upload(file, fileLink);
      this.app.manufacturer.getImageExternal();
      this.app.manufacturer.hasDeleteImagePermission();
    }
  }

  hasDataProviderPartId() {
    let manufacturer = this.app.manufacturer.currentManufacturer;
    switch (this.app.customers.dataProvider) {
      case DataProvider.Z2DATA:
        if (StringUtils.isNullOrEmpty(manufacturer.z2dataId)) {
          return false;
        }
        return true;
      case DataProvider.SE:
        if (StringUtils.isNullOrEmpty(manufacturer.seId)) {
          return false;
        }
        return true;
    }
    return false;
  }

  getSubTtitleFieldsList() {
    if (this.hasDataProviderPartId()) {
      return this.app.list.manufacturer.subTitleSectionClean;
    }
    return this.app.list.manufacturer.subTitleSectionRaw;
  }

  ngOnDestroy(): void {
    if (this._routeParamsSubscription) {
      this._routeParamsSubscription.unsubscribe();
    }
    if (this._newPostSubscription) {
      this._newPostSubscription.unsubscribe();
    }
    if (this._attachmentEdited) {
      this._attachmentEdited.unsubscribe();
    }
    if (this._newManufacturerSubscription) {
      this._newManufacturerSubscription.unsubscribe();
    }
  }
}
