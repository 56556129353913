import { Component, OnInit } from "@angular/core";
import { getApp } from "../app";
import { Customer } from "../../../../shared/types/customers";
import { Subscription } from "rxjs";

@Component({
  selector: "app-task-items",
  templateUrl: "./task-items.component.html",
  styleUrls: ["./task-items.component.scss"],
})
export class TaskItemsComponent implements OnInit {
  private checkOpenTaskSubscription: Subscription = new Subscription();
  currentTask: Task = {} as Task;
  columns: string[] = [];
  shortColumns: string[] = [];

  app = getApp((app) => {
    this.app = app;
  });

  constructor() {}

  ngOnInit() {
    // To be added on the clients side if different columns needs to be displayed
    this.columns = [
      this.app.fieldId.post.taskCompleted,
      this.app.fieldId.post.taskDescription,
      this.app.fieldId.post.taskResponsible,
      this.app.fieldId.post.taskDueDate,
      this.app.fieldId.post.taskNote,
    ];
    this.shortColumns = this.columns.map((c) => c.split(".")[1]);

    this.checkOpenTaskSubscription =
      this.app.taskCompleted.checkOpenTasksKNDS.subscribe((value) => {
        if (value) {
          if (this.app.customers.expectCurrent === Customer.KNDS) {
            this.app.post.posts.forEach((task: any) => {
              this.app.taskCompleted.checkOpenTasks(task);
            });
          }
        }
      });
  }

  ngOnDestroy(): void {
    if (this.checkOpenTaskSubscription) {
      this.checkOpenTaskSubscription.unsubscribe();
    }
  }
}
