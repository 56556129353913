import { AppType, APP_TYPE } from "../app.type";
import { MessageServiceType } from "./message.service.type";
import { hasChanges } from "../utils/app.utils";
import { Inject, Injectable } from "@angular/core";
import { Message } from "../../../../shared/models/message";
import {
  doc2Model,
  getDocsByPartNumber,
  getDocsByType,
  saveMessage,
} from "../api.service";

function getUpdateTime(message: Message) {
  const { create_time, update_time } = message;
  return Math.max(
    create_time == null ? 0 : create_time,
    update_time == null ? 0 : update_time
  );
}

@Injectable()
export class MessageService implements MessageServiceType {
  constructor(@Inject(APP_TYPE) private app: AppType) {}

  messages: any[] = [];
  entityId: string = "";
  model: any = {} as Message;
  note: boolean = false;
  showSaveButton: boolean = false;

  clearModel() {
    this.app.message.model = {} as Message;
  }

  getModelClean(id: any) {
    if (id == null) {
      return {};
    }
    return this.createMessageModel(id);
  }

  async getMessages(id: string) {
    this.messages = await getDocsByPartNumber(
      this.app.customers.expectCurrent,
      "message",
      id
    );
    this.sortMessages(this.messages);
    return this.messages;
  }

  sortMessages(messages: Message[]) {
    let sorted = messages.sort((a: any, b: any) => {
      let messageA = getUpdateTime(a);
      let messageB = getUpdateTime(b);
      if (messageA < messageB) {
        return 1;
      }
      if (messageA > messageB) {
        return -1;
      }
      return 0;
    });
    return sorted;
  }

  async deleteMessage(message: Message) {
    const customer = this.app.customers.expectCurrent;
    message._deleted = true;
    await saveMessage(customer, message);
    this.app.message.getMessages(this.app.message.entityId);
  }

  createMessageModel(message: Message) {
    return doc2Model("message", message);
  }

  hasEditPermission(message: Message) {
    const { permission, user } = this.app;
    if (permission.message.edit) {
      return true;
    }
    if (permission.message.editOwn) {
      const createUser = message.user_id;
      if (createUser === user) {
        return true;
      }
    }
    return false;
  }

  modelHasChanges(messageId: string | null) {
    if (messageId != null) {
      const modelHasChanges = hasChanges(
        this.getModelClean(messageId),
        this.app.message.model
      );
      return modelHasChanges;
    } else {
      return false;
    }
  }

  async generateMessagesForJsonImport(docs: any) {
    let messageDocs: any[] = [];
    this.messages = await getDocsByType(
      this.app.customers.expectCurrent,
      "message"
    );
    docs.forEach(async (doc: any) => {
      let messageDoc = {} as Message;
      let existingDoc = await this.app.import.checkIfExists(this.messages, doc);
      if (existingDoc !== undefined) {
        messageDoc = this.updateFieldsFromImport(doc, existingDoc);
      } else {
        messageDoc = doc;
      }
      messageDocs.push(messageDoc);
    });
    if (this.app.import.type === "all-config") {
      this.app.state.importBuffer.docs.data.push(messageDocs);
    } else {
      this.app.state.importBuffer.docs.data = messageDocs;
    }
    return messageDocs;
  }

  updateFieldsFromImport(doc: Message, existingDoc: Message) {
    Object.keys(doc).forEach((key) => {
      existingDoc[key] = doc[key];
    });
    return existingDoc;
  }

  showContent() {
    this.note = !this.note;
    this.showSaveButton = true;
  }

  discardChanges() {
    this.note = false;
    this.showSaveButton = false;
  }
}
