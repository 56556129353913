import { Component, OnInit, OnDestroy, ViewChild } from "@angular/core";
import { getApp } from "../../app";
import { Subscription } from "rxjs";
import { ActivatedRoute } from "@angular/router";
import { getThreadsCompressed } from "../../api.service";
import { Manufacturer } from "../../../../../shared/models/manufacturer";

@Component({
  selector: "app-manufacturers-filter-table",
  templateUrl: "./manufacturers-filter-table.component.html",
  styleUrls: ["./manufacturers-filter-table.component.scss"],
})
export class ManufacturersFilterTableComponent implements OnInit, OnDestroy {
  @ViewChild("deleteManufacturerModal", { static: false })
  deleteManufacturerModal: any;

  loading = true;

  app = getApp((app) => {
    this.app = app;
  });

  private _routeParamsSubscription: Subscription = new Subscription();
  private _sectionSubscription: Subscription = new Subscription();

  constructor(private route: ActivatedRoute) {}

  async ngOnInit() {
    this.app.type = "manufacturer";
    this.app.RMSubmenu.section = "status";

    this._routeParamsSubscription = this.route.params.subscribe(
      async (params) => {
        //reset manufacturers array when changing the view to avoid displaying the old data until the new one is loading
        this.app.manufacturersFilter.manufacturers = [];
        this.app.manufacturersFilter.cleanManufacturers = [];
        this.loading = true;

        if (params.mode != null) {
          this.app.RM.mode = params.mode;
        }
      }
    );
    this.app.filterHeaderTable.isFilterSelected = false;
    await this.app.manufacturersFilter.getFilterResultBasedOnView("1");
    this._sectionSubscription = this.app.RMSubmenu.sectionSubject.subscribe(
      () => {
        //reset filter component when change the section on RM all manufacturers table
        this.app.filterTree.resetFilterTree();
        this.app.filterHeaderTable.resetAllFilters.next(true);
        if (this.app.filterHeaderTable.isFilterSelected) {
          // When a filter is applied on a second/third/last page clean manufacturers whould contain the data from the page where the filter was applied
          // When change the section we want to reset the data displayed in the table to the first page, not to the clean which would be second/third/last page from table
          this.app.filterHeaderTable.isFilterSelected = false;
          this.app.manufacturersFilter.manufacturers =
            this.app.manufacturersFilter.firstPageManufacturers;
          this.app.manufacturersFilter.cleanManufacturers =
            this.app.manufacturersFilter.firstPageManufacturers;
        } else {
          this.app.manufacturersFilter.manufacturers =
            this.app.manufacturersFilter.cleanManufacturers;
        }

        this.app.manufacturersFilter.pages =
          this.app.manufacturersFilter.cleanSize;
      }
    );

    // update threadsCompressed in order to set the new added case in seeOpenedCasesModal
    this.app.thread.threadsCompressed = await getThreadsCompressed(
      this.app.customers.expectCurrent,
      "thread"
    );

    this.loading = false;
    this.app.table.selected.clear();
  }

  getSortTableColumns(column: string) {
    if (
      this.app.filterTable.getFilterTableColumns(column) ||
      this.app.table.getColumnType(column) === "preferred"
    ) {
      return true;
    }
    return false;
  }

  deleteItem(manufacturer: Manufacturer) {
    this.deleteManufacturerModal.open();
    this.app.manufacturer.selectedManufacturer = manufacturer;
  }

  ngOnDestroy(): void {
    if (this._routeParamsSubscription) {
      this._routeParamsSubscription.unsubscribe();
    }
    if (this._sectionSubscription) {
      this._sectionSubscription.unsubscribe();
    }
  }
}
