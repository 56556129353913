import { Component, OnDestroy, OnInit } from "@angular/core";
import { getApp } from "../app";
import { Type } from "../../../../shared/components";
import { Subscription } from "rxjs";
import { exportTypes, writeFileSpecialLogic } from "../export/export.service";
import { Manufacturer, SeMatch } from "../../../../shared/models/manufacturer";
import { AlertData } from "../../../../shared/models/alert";
import { getImpactsCompressed, getTrainsCompressed } from "../api.service";
import { Customer } from "../../../../shared/types/customers";
import { ActivatedRoute } from "@angular/router";
import { DataProvider } from "../../../../shared/constants/dataProvider";
import { Z2DataMatch } from "../../../../shared/models/dataProviders/z2data";

@Component({
  selector: "app-home",
  templateUrl: "./home.component.html",
  styleUrls: ["./home.component.scss"],
})
export class HomeComponent implements OnInit, OnDestroy {
  types: Type[] = ["thread", "post", "impact"];
  // loadedImageSubscription: Subscription = new Subscription();
  listReady: boolean = false;
  columns: string[] = [];
  filterOpen: boolean = false;
  exTypeTexts: string[] = [];

  private _routeSubscription: Subscription = new Subscription();

  app = getApp((app) => {
    this.app = app;
  });
  quickFilters: string[] = [];
  showFilters = false;

  constructor(private route: ActivatedRoute) {}

  async ngOnInit() {
    this.app.type = "thread";
    this.getSortOption();

    this._routeSubscription = this.route.data.subscribe((data) => {
      this.app.routing.setTabTitle(data.title);
    });

    this.getListOfColumns();
    if (
      this.app.auth.isUser &&
      this.app.customers.expectCurrent === Customer.DB
    ) {
      this.quickFilters = [
        "fav",
        "showAllCases",
        "personal",
        "deputy",
        "responsible",
      ];
    } else {
      this.quickFilters = this.app.list.thread.quickFilters;
    }

    await this.prepareCases();

    /** make the fields of type value - since they don't have know values on the homepage */
    // this.app.field.getFieldSettings("thread.crtNumber").type = "value";
    // this.app.field.getFieldSettings("thread.artNumber").type = "value";

    /** Used only to change the label for COOP client. To be removed when COOP will be on another branch */
    if (this.app.customers.expectCurrent === Customer.COOP) {
      this.app.thread.exportType = "All Anzeigen";
    } else {
      this.app.thread.exportType = "All Cases";
    }
  }

  getListOfColumns() {
    this.app.list.thread.columns.forEach((column) => {
      this.columns.push(column.split(".")[1]);
    });
  }

  async prepareCases() {
    if (this.app.customers.expectCurrent === Customer.OMP) {
      this.app.state.next({
        showExternalCases: this.app.filterList.externalCasesDefault,
      });
      await this.app.thread.getExternalCases();
      await this.app.applicationArea.getApplicationAreaFieldOptions();
    } else {
      await this.app.thread.getCasesCompressed();
      await this.app.vehicleResponsible.getVehicleDocuments();
    }
    if (this.app.state.filterByPage.length !== 0) {
      this.filterOpen = true;
      this.app.filterList.open = true;
      this.app.filterFields.createFilterObject();
    }
    if (this.app.filterFields.quickFilterSelected !== "showAllCases") {
      this.app.filterFields.addQuickFilter(
        this.app.filterFields.quickFilterSelected
      );
    }

    /** sort cases for NS customer on table mode needs to be done after both update_time and omfStatus properties */
    /** when navigate back from the thread to the home table, threadsCompressed is done again and is required to be first sorted by update_time */
    if (this.app.customers.expectCurrent === Customer.NS) {
      this.app.table.sortField = "thread.update_time";
      this.app.table.sortDocs(this.app.thread.threadsCompressed);
      this.getSortOption();
    }

    /**created a subscription in order to be able to catch when the cases are set on this.app.docs.docs */
    this.app.docs.docsSubject.subscribe(async (value) => {
      if (value) {
        this.listReady = value;

        this.app.table.sortDocs(this.app.thread.threadsCompressed);
        this.app.spinner.hideSpinner();
      }
    });
    if (this.app.customers.expectCurrent === Customer.DB) {
      await this.app.users.getUsersWithRoles();
    }
    if (this.app.customers.expectCurrent === Customer.NS) {
      this.app.train.trainsCompressed = await getTrainsCompressed(
        this.app.customers.expectCurrent
      );
    }
    this.app.impacts.impactsCompressed = await getImpactsCompressed(
      this.app.customers.expectCurrent
    );

    //get list of users to be displayed in the filters dropdowns
    this.app.users.getUsersWithRoles();
  }

  getPage() {
    let elements = this.app.paginator.getPage(this.app.thread.ids);
    return elements;
  }

  setFav(fav: boolean): void {
    this.app.thread.setFav(this.app.thread.thread._id, fav);
  }

  getSortOption() {
    //sort cases for NS customer depending on homeMode
    if (this.app.customers.expectCurrent === Customer.NS) {
      //https://app.asana.com/0/1201386012502025/1201399270863445
      if (this.app.home.homeMode === "table") {
        this.app.table.sortField = "thread.omfStatus";
        this.app.table.sortDirection = 1;
      } else {
        this.app.table.sortField = "thread.update_time";
        this.app.table.sortDirection = -1;
      }
    }
    let sortField = this.app.table.sortField;
    let index = this.app.list.thread.sortOptions.findIndex(
      (f) => f === sortField
    );
    if (index === -1 && this.app.home.homeMode === "list") {
      this.app.table.sortField = "thread.update_time";
      this.app.filterFields.quickFilterSelected = "showAllCases";
    } else {
      this.app.table.sortField = this.app.state.sortField;
    }
  }

  getExportTypeText(type: string) {
    if (type === "ALL-Cases" || type === "All Cases") {
      return "ALL-Cases";
    } else {
      if ((exportTypes as any)[type]) {
        return (exportTypes as any)[type].text[this.app.language];
      }
    }
  }

  /**prepare data for exporting */

  async writeFile(type: string) {
    this.app.thread.exportType = type;
    this.app.spinner.showSpinner();
    await this.app.thread.getAllThreads();
    if (this.app.customers.expectCurrent === Customer.DB) {
      await this.app.thread.setCommodityIdOnThread();
    }
    await writeFileSpecialLogic(this.app, type);
    this.app.mail.updateUnreadCount.next(true);
    this.app.spinner.hideSpinner();
  }

  navigateNewThread() {
    //clear all variables that could have fields info about a thread
    this.app.sync.clearTheSyncInfo();
    this.app.pcn.pcn = null;
    this.app.pcn.id = null;
    this.app.treeRow.currentManufacturer = new Manufacturer();
    this.app.alerts.currentSelected = {} as AlertData;
    this.app.customers.dataProvider === DataProvider.Z2DATA
      ? (this.app.externalDataFilter.selectedMatch = new Z2DataMatch())
      : (this.app.externalDataFilter.selectedMatch = new SeMatch());
    sessionStorage.removeItem("fromTree");
    this.app.routing.navigateNewThread();
  }

  ngOnDestroy(): void {
    // if (this.loadedImageSubscription) {
    //   this.loadedImageSubscription.unsubscribe();
    // }
    if (this._routeSubscription) {
      this._routeSubscription.unsubscribe();
    }
  }
}
