import { Component, Input, OnInit } from "@angular/core";
import { getApp } from "../app";
@Component({
  selector: "app-box",
  templateUrl: "./box.component.html",
  styleUrls: ["./box.component.scss"],
})
export class BoxComponent implements OnInit {
  @Input() box: string | null = null;
  @Input() model: any = {};
  @Input() unlock: string | null = null;
  @Input() showHeader = true;
  @Input() publicFieldName: string | null = null;

  app = getApp((app) => {
    this.app = app;
  });

  constructor() { }

  ngOnInit() {}
}
