<app-modal
  #unFavModal
  [modalMessage]="app.text.thread.unFavCaseMessage"
  [modalTitle]="app.text.thread.modalTitle"
  (ok)="setFav(false)"
>
</app-modal>

<div class="row">
  <div class="col-sm-12 col-md-12 col-12">
    <!-- First line -->
    <div class="row py-3">
      <!-- Left side -->
      <!-- Switch view buttons -->
      <div class="col-2">
        <div class="btn-group btn p-0">
          <button
            type="button"
            class="btn btn-light btn-toggle-list icon-center"
            [ngClass]="{ selected: app.home.homeMode === 'list' }"
            (click)="app.home.homeMode = 'list'; getSortOption()"
            [ngbTooltip]="app.text.home.list"
          >
            <i
              class="far fa-stream menu-icons"
              [ngClass]="{ selected: app.home.homeMode === 'list' }"
            ></i>
          </button>
          <button
            type="button"
            class="btn btn-light btn-toggle-list icon-center"
            [ngClass]="{ selected: app.home.homeMode === 'table' }"
            (click)="app.home.homeMode = 'table'; getSortOption()"
            [ngbTooltip]="app.text.home.table"
          >
            <i
              class="fal fa-table menu-icons"
              [ngClass]="{ selected: app.home.homeMode === 'table' }"
            ></i>
          </button>
        </div>
        <div class="btn-group btn-sm">
          <span *ngIf="app.home.homeMode === 'list'">{{
            app.text.home.listView
          }}</span>
          <span *ngIf="app.home.homeMode === 'table'">{{
            app.text.home.tableView
          }}</span>
        </div>
      </div>

      <!-- Right side -->

      <div class="col-10 d-flex flex-row-reverse">
        <div class="btn-group">
          <!-- Quick filters -->
          <div class="dropdown quickFilters">
            <button
              class="btn dropdown-toggle"
              type="button"
              id="quickFilters"
              data-bs-toggle="dropdown"
              aria-haspopup="true"
              aria-expanded="false"
            >
              <div class="icon-center me-1">
                <i class="fas fa-eye menu-icons"></i>
              </div>
              <span>&nbsp;{{ app.text.home.showOnly }}&nbsp;</span>
              <span class="text-danger">
                {{
                  app.filterFields.getQuickFilterText(
                    app.filterFields.quickFilterSelected
                  )
                }}
              </span>
            </button>
            <div
              id="quickFiltersDropdown"
              class="dropdown-menu"
              aria-labelledby="quickFilters"
              role="menu"
            >
              <a
                class="dropdown-item pointer"
                *ngFor="let item of quickFilters"
                (click)="
                  app.filterFields.addQuickFilter(item);
                  app.filterFields.quickFilterSelected = item
                "
                >{{ app.text.home[item] }}</a
              >
            </div>
          </div>

          <!-- Sort Options -->
          <div
            class="dropdown sortOptions"
            *ngIf="app.home.homeMode === 'list'"
          >
            <button
              class="btn dropdown-toggle"
              type="button"
              id="sortOptions"
              data-bs-toggle="dropdown"
              aria-haspopup="true"
              aria-expanded="false"
            >
              <div class="icon-center me-1">
                <i class="fas fa-sort-alt menu-icons"></i>
              </div>
              <span>&nbsp;{{ app.text.home.sort }}&nbsp;</span>
              <span class="text-danger" *ngIf="app.table.sortField">
                {{ app.field.getLabel(app.table.sortField) }}
              </span>
            </button>
            <div class="dropdown-menu" aria-labelledby="sortOptions">
              <a
                class="dropdown-item pointer"
                *ngFor="let item of app.list.thread.sortOptions"
                (click)="
                  app.table.sortField = item;
                  app.filterFields.createFilterObject()
                "
              >
                {{ app.field.getLabel(item) }}</a
              >
            </div>
          </div>

          <!-- Export Types -->
          <div class="dropdown" *ngIf="app.permission.home.download">
            <div *ngIf="app.list.thread.exportTypes.length > 1">
              <button
                class="btn btn-sm dropdown-toggle"
                type="button"
                id="exportTypeButton"
                data-bs-toggle="dropdown"
                [title]="app.text.home.export"
                aria-haspopup="true"
                aria-expanded="false"
              >
                <div class="icon-center me-1">
                  <i class="fa fa-download menu-icons"></i>
                </div>
                <span>&nbsp;{{ app.text.home.exportList }}&nbsp;</span>
                <span class="text-danger">
                  {{ getExportTypeText(app.thread.exportType) }}
                </span>
              </button>
              <div
                class="dropdown-menu exportTypeDropdown"
                aria-labelledby="exportTypeButton"
              >
                <div *ngFor="let item of app.list.thread.exportTypes">
                  <a
                    *ngIf="item === 'All Cases json' && app.auth.isAdmin"
                    class="dropdown-item pointer"
                    (click)="writeFile(item)"
                  >
                    {{ getExportTypeText(item) }}</a
                  >
                  <a
                    *ngIf="item !== 'All Cases json'"
                    class="dropdown-item pointer"
                    (click)="writeFile(item)"
                  >
                    {{ getExportTypeText(item) }}</a
                  >
                </div>
              </div>
            </div>
            <div *ngIf="app.list.thread.exportTypes.length === 1">
              <button
                class="btn btn-sm"
                type="button"
                id="exportTypeButton"
                [title]="app.text.home.export"
                (click)="writeFile('All Cases')"
              >
                <div class="icon-center me-1">
                  <i class="fa fa-download menu-icons"></i>
                </div>
                <span>&nbsp;{{ app.text.home.exportList }}&nbsp;</span>
                <!-- <span class="text-danger">
                    {{ getExportTypeText(app.thread.exportType) }}
                  </span> -->
              </button>
            </div>
          </div>

          <!-- Display only internal cases by default for all customers, but not SBB -->
          <div *ngIf="app.customer === 'omp'">
            <button
              class="btn btn-sm"
              *ngIf="!app.filterList.showExternalCases"
              (click)="app.filterList.toggle()"
              [ngbTooltip]="app.text.home.showAllCases"
            >
              <div class="icon-center me-1">
                <i class="fas fa-layer-plus menu-icons"></i>
              </div>
              <span>&nbsp;{{ app.text.home.showAllCases }}&nbsp;</span>
            </button>
            <button
              class="btn btn-sm"
              *ngIf="app.filterList.showExternalCases"
              (click)="app.filterList.toggle()"
              [title]="app.text.home.showInternalCases"
            >
              <div class="icon-center me-1">
                <i class="fas fa-layer-minus menu-icons"></i>
              </div>
              <span>&nbsp;{{ app.text.home.showInternalCases }}&nbsp;</span>
            </button>
          </div>

          <!-- Filter button -->
          <div>
            <button
              class="btn btn-sm btn-default-red me-1"
              data-toggle="collapse"
              data-target="#collapseFilter"
              [disabled]="!listReady"
              (click)="app.filterList.open = !app.filterList.open"
              [title]="app.text.home.filters"
              [ngClass]="{ btnFilterOpen: app.filterList.open }"
            >
              <i class="fas fa-filter"></i>
              {{ app.text.home.filters }}
              <i *ngIf="!app.filterList.open" class="fal fa-angle-right"></i>
              <i *ngIf="app.filterList.open" class="far fa-times ml-1"></i>
            </button>
          </div>
        </div>
      </div>
    </div>

    <!-- Second line -->
    <div class="row bottom-line">
      <!-- Right side -->
      <div class="col-12">
        <!-- Filter cips -->
        <div *ngIf="listReady" class="col-8 pull-right no-padding">
          <app-filter-fields></app-filter-fields>
        </div>
      </div>
    </div>

    <div class="col-md-12 text-center font-weight-bold" *ngIf="!listReady">
      {{ app.text.app.loading }}
    </div>
    <div *ngIf="listReady">
      <div [ngSwitch]="app.home.homeMode">
        <div *ngSwitchCase="'list'">
          <app-paginator
            [length]="app.thread.ids.length"
            [index]="app.paginator.focus"
            (focus)="app.paginator.focus = $event"
          ></app-paginator>
          <div class="no-results col-12" *ngIf="app.thread.ids.length == 0">
            {{ app.text.home.noResults }}
          </div>
          <div *ngFor="let id of getPage(app.thread.ids)">
            <a [routerLink]="app.thread.getRouterURL(id)">
              <app-thread-item [id]="id"></app-thread-item>
            </a>
          </div>
          <app-paginator
            [length]="app.thread.ids.length"
            [index]="app.paginator.focus"
            (focus)="app.paginator.focus = $event"
          ></app-paginator>
        </div>
        <div *ngSwitchCase="'table'">
          <app-paginator
            [length]="app.thread.ids.length"
            [index]="app.paginator.focus"
            (focus)="app.paginator.focus = $event"
          ></app-paginator>
          <div class="tableFixHead">
            <table class="table table-striped spacing-table">
              <thead>
                <tr>
                  <th
                    class="p-1 py-2"
                    *ngFor="let column of app.list.thread.columns"
                  >
                    <div>
                      <span (click)="app.table.toggleSortDirection(column)">{{
                        app.field.handleSpecialLogicLabels(column)
                      }}</span
                      >&nbsp;
                      <span *ngIf="column === app.table.sortField">
                        <i
                          *ngIf="app.table.sortDirection === 1"
                          class="fa fa-chevron-up"
                        ></i>
                        <i
                          *ngIf="app.table.sortDirection === -1"
                          class="fa fa-chevron-down"
                        ></i>
                      </span>
                    </div>
                  </th>
                </tr>
              </thead>
              <tbody *ngIf="listReady">
                <tr
                  *ngFor="let case of app.paginator.getPageWithDoc()"
                  [ngClass]="{ caseClosed: case.omfStatus === '120' }"
                  class="pointer"
                  (click)="app.routing.navigateThread(case._id)"
                >
                  <td class="p-1" *ngFor="let column of columns">
                    <a
                      [routerLink]="app.thread.getRouterURL(case._id)"
                      class="no-style"
                    >
                      <div [ngSwitch]="app.table.getColumnTypeDisplay(column)">
                        <div *ngSwitchCase="'fav'">
                          <div
                            class="col-md-12"
                            (click)="
                              app.thread.thread = case;
                              setFav(true);
                              $event.stopPropagation();
                              $event.preventDefault()
                            "
                          >
                            <i
                              [ngClass]="{
                                'far fa-star': !app.thread.isFav(case._id)
                              }"
                              [ngStyle]="{ color: '#787ABF' }"
                            ></i>
                          </div>
                          <div
                            class="col-md-12"
                            (click)="
                              unFavModal.open();
                              app.thread.thread = case;
                              $event.stopPropagation();
                              $event.preventDefault()
                            "
                          >
                            <i
                              [ngClass]="{
                                'fas fa-star': app.thread.isFav(case._id)
                              }"
                              [ngStyle]="{ color: '#787ABF' }"
                            ></i>
                          </div>
                        </div>
                        <div *ngSwitchCase="'dueDate'">
                          <span
                            class="due-date"
                            [ngStyle]="app.thread.getDueDateStyle(case)"
                          >
                            {{ case[column] | date : "yyyy-MM-dd" }}
                          </span>
                        </div>
                        <div class="fixedWidth" *ngSwitchCase="'limit'">
                          <span
                            (click)="
                              $event.stopPropagation(); $event.preventDefault()
                            "
                          >
                            <app-limit
                              [textInput]="
                                app.field.getItemsAsString(case[column], column)
                              "
                              limit="50"
                              [fieldName]="vehicleNames"
                            >
                            </app-limit>
                          </span>
                        </div>
                        <div *ngSwitchCase="'time'">
                          {{ case[column] | date : "yyyy-MM-dd HH:mm:ss" }}
                        </div>
                        <div *ngSwitchCase="'date'">
                          {{ case[column] | date : "yyyy-MM-dd" }}
                        </div>
                        <div *ngSwitchCase="'optionText'">
                          <span>
                            {{
                              app.field.getOptionText(
                                "thread." + column,
                                case[column]
                              )
                            }}
                          </span>
                        </div>
                        <div *ngSwitchCase="'checkBox'">
                          <span>
                            {{
                              app.field.getFieldValueAsText(
                                "thread." + column,
                                case[column]
                              )
                            }}
                          </span>
                        </div>
                        <div *ngSwitchCase="'artNumber'">
                          {{ case[column] }}
                        </div>
                        <div *ngSwitchCase="'normal'">
                          {{ case[column] }}
                        </div>
                      </div>
                    </a>
                  </td>
                </tr>
                <tr>
                  <td
                    *ngIf="app.thread.ids.length === 0"
                    colspan="99"
                    class="text-center"
                  >
                    {{ app.text.home.noResults }}
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <app-paginator
            [length]="app.thread.ids.length"
            [index]="app.paginator.focus"
            (focus)="app.paginator.focus = $event"
          ></app-paginator>
        </div>
        <!-- <div *ngSwitchCase="'posts-list'">
          <app-paginator
            [length]="app.post.ids.length"
            [index]="app.paginator.focus"
            (focus)="app.paginator.focus = $event"
          ></app-paginator>
          <app-paginator
            [length]="app.post.ids.length"
            [index]="app.paginator.focus"
            (focus)="app.paginator.focus = $event"
          ></app-paginator>
        </div> -->
        <div *ngSwitchDefault>Unknown: {{ app.home.homeMode | json }}</div>
      </div>
    </div>
  </div>
</div>
