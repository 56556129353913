import { ChangeDetectorRef, Component } from "@angular/core";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { Subscription } from "rxjs";
import { doc2Model } from "../../api.service";
import { getApp } from "../../app";
import { Impact } from "../../../../../shared/models/impact";
import { Responsibles } from "../../../../../shared/models/responsibles";

export const RED = "red";
export const GREEN = "green";

@Component({
  selector: "app-wf-automation",
  templateUrl: "./wf-automation.component.html",
  styleUrls: ["./wf-automation.component.scss"],
})
export class WorkflowAutomationComponent {
  impactId: string = "";
  currentResponsible: string = "";
  saveDisabled: boolean = false;
  size: number = 0;
  departments = departments;
  selected = new Set<string>();
  red = RED;
  green = GREEN;
  view: "discardChanges" | "detailView" | "delete" = "detailView";

  private _sizeSubscription: Subscription = new Subscription();

  app = getApp((app) => {
    this.app = app;
  });

  constructor(
    private modalService: NgbModal,
    private _cdRef: ChangeDetectorRef
  ) {}

  async ngOnInit() {
    await this.createModel();
    this.app.type = "impact";

    this._sizeSubscription = this.app.impact.newInvalidField.subscribe(
      (value: any) => {
        if (this.size !== value) {
          this.size = value;
          this.saveDisabled = this.isDisabled();
          this._cdRef.detectChanges();
        }
      }
    );
  }

  async createModel() {
    if (this.app.state.impactId) {
      this.impactId = this.app.state.impactId;
      await this.app.impact.getImpactById(this.impactId);
      this.app.model = this.app.impact.currentImpact;
    } else {
      const impactModel: any = doc2Model("impact", new Impact());
      this.app.impact.currentImpact = impactModel;
      // this.app.impact.cleanModel = impactModel;
      this.app.impact.currentImpact["impact.responsibles"] = doc2Model(
        "responsibles",
        new Responsibles()
      );
    }
    this.app.type = "impact";
    this.app.model = {};
  }

  display(field: string) {
    if (
      this.app.impact.currentImpact &&
      this.app.impact.currentImpact["impact.responsibles"] != null &&
      this.app.impact.currentImpact["impact.responsibles"][field] != null
    ) {
      if (this.app.impact.currentImpact["impact.responsibles"][field] !== "") {
        return true;
      } else {
        return false;
      }
    }
  }

  hasDifferentDisplay(department: string) {
    switch (department) {
      case "Cargo":
        if (this.app.thread.thread["thread.omfStatus"] === "90") {
          return true;
        }
      default:
        return false;
    }
  }

  async discardChanges() {
    this.app.impact.id = this.app.impact.id;
    this.app.unlockedId = null;
    if (this.app.impact.isNew) {
      this.app.impact.id = null;
    }
    await this.createModel();
    this.app.model = {};
    this.app.typeAheadBasic.model = {};
    this.app.field.inputSubject.next(true);
  }

  async save() {
    await this.app.impact.save();
    this.app.typeAheadBasic.model = {};
    this.modalService.dismissAll();
  }

  hasInvalidFields() {
    if (
      this.app.field.getInvalidFields("impact", this.app.impact.currentImpact)
        .length < 1 &&
      this.app.impact.modelHasChanges
    ) {
      return false;
    } else {
      return true;
    }
  }

  isDisabled() {
    if (this.size === 0) {
      return false;
    } else {
      return true;
    }
  }

  async deleteImpact() {
    await this.app.impact.delete(this.app.impact.currentImpact);
    this.modalService.dismissAll();
  }

  ngOnDestroy(): void {
    if (this._sizeSubscription) {
      this._sizeSubscription.unsubscribe();
    }
  }
}

export const departments = [
  {
    name: "Fernverkehr",
    fields: {
      bav: "responsibles.bavFv",
      bavVertreter: "responsibles.bavFvVertreter",
      mawi: "responsibles.mawiFv",
      mawiVertreter: "responsibles.mawiFvVertreter",
    },
  },
  {
    name: "Regio",
    fields: {
      bav: "responsibles.bavRegio",
      bavVertreter: "responsibles.bavRegioVertreter",
      mawi: "responsibles.mawiRegio",
      mawiVertreter: "responsibles.mawiRegioVertreter",
    },
  },
  {
    name: "Cargo",
    fields: {
      bav: "responsibles.bavCargo",
      bavVertreter: "responsibles.bavCargoVertreter",
      mawi: "responsibles.mawiCargo",
      mawiVertreter: "responsibles.mawiCargoVertreter",
    },
  },
  {
    name: "FZI",
    fields: {
      bav: "responsibles.bavFzi",
      bavVertreter: "responsibles.bavFziVertreter",
      mawi: "responsibles.mawiFzi",
      mawiVertreter: "responsibles.mawiFziVertreter",
    },
  },
  {
    name: "Sonstige",
    fields: {
      bav: "responsibles.bavNetz",
      bavVertreter: "responsibles.bavNetzVertreter",
      mawi: "responsibles.mawiNetz",
      mawiVertreter: "responsibles.mawiNetzVertreter",
    },
  },
];
