import { ComponentSettings } from "../settings/settings";

const settings = {
  permission: {},
  text: {
    selectAll: {
      de: "Alle auswählen",
      en: "Select all",
      nl: "Selecteer alles",
    },
    unselectAll: { de: "Alle zurücksetzen", en: "Clear all", nl: "Wis alles" },
    select: { de: "Auswählen", en: "Select", nl: "Selecteer" },
    unselect: { de: "Zurücksetzen", en: "Clear", nl: "Verwijderen" },
    id: { de: "ID", en: "ID", nl: "ID" },
    delete: { de: "Löschen", en: "Delete", nl: "Verwijder" },
    modalTitle: {
      de: "Bestätigung",
      en: "Confirm",
      nl: "Bevestigen",
    },
    deleteNotification: {
      de: "Wollen Sie die Benachrichtigung wirklich entfernen?",
      en: "Do you really want to remove the notification?",
      nl: "Wil je echt de melding verwijderen?",
    },
  },
  list: {},
  url: {},
  field: {},
};

export const table: ComponentSettings<typeof settings> = settings;
