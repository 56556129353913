<app-task-completed-note
  #taskCompletedModal
  [currentTask]="currentTask"
></app-task-completed-note>

<div>
  <table class="table table-striped spacing-table">
    <thead>
      <tr class="p-1">
        <th *ngFor="let column of columns">
          <span *ngIf="column !== this.app.fieldId.post.taskCompleted">
            {{ app.field.getLabel(column) }}
          </span>
        </th>
      </tr>
    </thead>
    <tbody *ngFor="let task of app.post.tasks">
      <tr>
        <td
          class="p-1"
          *ngFor="let column of shortColumns"
          (click)="app.tasks.openTaskPanel(task)"
        >
          <div [ngSwitch]="app.table.getColumnTypeDisplay(column)">
            <div
              *ngSwitchCase="'optionText'"
              (click)="$event.stopPropagation()"
            >
              <button
                class="btn btn-sm"
                *ngIf="!task.taskCompleted"
                [disabled]="
                  !app.taskCompleted.showCompleteTaskIcon(task) ||
                  task.cannotCloseTask
                "
                (click)="currentTask = task; taskCompletedModal.openModal()"
                [ngbTooltip]="app.text.post.markTaskCompleted"
              >
                <i class="far fa-check-circle"></i>
              </button>
              <button
                class="btn btn-sm"
                *ngIf="task.taskCompleted"
                [disabled]="!app.taskCompleted.showReopenTaskIcon(task)"
                (click)="
                  app.taskCompleted.changeTaskCompletion(app.customer, task)
                "
                [ngbTooltip]="app.text.post.markTaskIncomplete"
              >
                <i class="fas fa-check-circle"></i>
              </button>
            </div>
            <div *ngSwitchCase="'dueDate'" class="date-min-width">
              <span
                [ngStyle]="
                  app.tasks.getTaskDueDateColor(
                    task[column],
                    task.taskCompleted
                  )
                "
              >
                {{ task[column] }}
              </span>
            </div>
            <div *ngSwitchDefault class="column-width">
              {{ task[column] }}
            </div>
          </div>
        </td>
      </tr>
    </tbody>
  </table>
</div>
