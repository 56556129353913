import { ManufacturersFilterTableServiceType } from "./manufacturers-filter-table.service.type";
import { AppType, APP_TYPE } from "../../app.type";
import { Manufacturer } from "../../../../../shared/models/manufacturer";
import { Inject, Injectable } from "@angular/core";
import {
  filterManufacturers,
  filterManufacturersByCPNs,
  getAllMByPartNumberPaginated,
} from "../../api.service";
import { TreeMode } from "../RM.service";
import { DataProvider } from "../../../../../shared/constants/dataProvider";
@Injectable()
export class ManufacturersFilterTableService
  implements ManufacturersFilterTableServiceType
{
  manufacturers: Manufacturer[] = [];
  cleanManufacturers: Manufacturer[] = [];
  currentManufacturer = new Manufacturer();
  currentIds: string[] = [];
  pages: string = "";
  size: number = 0;
  cleanSize: string = "";
  firstPageManufacturers: Manufacturer[] = [];

  constructor(@Inject(APP_TYPE) private app: AppType) {}

  async getManufacturers(mode: TreeMode, page?: string) {
    this.app.spinner.showSpinner();
    if (page == null) {
      page = "1";
    }
    if (this.app.paginator.pageSize == null) {
      this.app.paginator.pageSize = 25;
    }
    if (mode === "all-manufacturers") {
      // Mark status as current section selected if coming from impact or risk
      if (
        this.app.RMSubmenu.section === "impact" ||
        this.app.RMSubmenu.section == "risk"
      ) {
        this.app.RMSubmenu.section = "status";
      }

      const { manufacturers, size } = await getAllMByPartNumberPaginated(
        this.app.customers.expectCurrent,
        null,
        page,
        this.app.paginator.pageSize.toString()
      );
      this.manufacturers = manufacturers;
      this.pages = size.toString();
    } else {
      // Always mark status as current section selected
      this.app.RMSubmenu.section = "status";
      const { manufacturers, size } = await filterManufacturers(
        this.app.customers.expectCurrent,
        mode,
        page,
        this.app.paginator.pageSize.toString()
      );

      this.manufacturers = manufacturers;

      this.pages = size.toString();
    }
    if (page === "1") {
      this.firstPageManufacturers = this.manufacturers;
    }
    this.app.state.sortField = "manufacturer.partNumber";
    this.app.state.sortDirection = 1;
    this.cleanManufacturers = this.manufacturers;
    this.cleanSize = this.pages;
    let uniqueIds = new Set<string>();
    this.manufacturers.forEach((m) => {
      if (m._id != null) {
        uniqueIds.add(m._id);
      }
    });
    this.currentIds = Array.from(uniqueIds);
    this.app.spinner.hideSpinner();
  }

  getManufacturersColumns(): string[] {
    const commonColumns: string[] =
      this.app.list.RM.manufacturersCommonColumns.map(
        (field) => field.split(".")[1]
      );
    const statusColumns: string[] =
      this.app.list.RM.manufacturersStatusColumns.map(
        (field) => field.split(".")[1]
      );

    //remove the "part.numberSecSource" column from mtu on manufacturer views
    const numberSecSourceIndex = this.app.list.RM.likelihoodColumns.indexOf(
      this.app.fieldId.part.numberSecSource
    );

    let likelihoodColumns: string[] = this.app.list.RM.likelihoodColumns;
    if (numberSecSourceIndex !== -1) {
      likelihoodColumns.splice(numberSecSourceIndex, 1);
    }
    likelihoodColumns = likelihoodColumns.map((field) => field.split(".")[1]);

    const rohsColumns: string[] = this.app.list.RM.rohsColumns.map(
      (field) => field.split(".")[1]
    );
    const reachColumns: string[] = this.app.list.RM.reachColumns.map(
      (field) => field.split(".")[1]
    );
    const leadColumns: string[] = this.app.list.RM.leadColumns.map(
      (field) => field.split(".")[1]
    );

    switch (this.app.RMSubmenu.section) {
      case "status":
        return [...commonColumns, ...statusColumns];
      case "likelihood":
        return [...commonColumns, ...likelihoodColumns];
      case "rohs":
        return [...commonColumns, ...rohsColumns];
      case "reach":
        return [...commonColumns, ...reachColumns];
      case "lead":
        return [...commonColumns, ...leadColumns];
      default:
        return [...commonColumns, ...statusColumns];
    }
  }

  getValuesAsManufacturerKeys(column: string) {
    const dataProvider: DataProvider = this.app.customers.dataProvider;
    let key = column;
    if (key.includes("manufacturer")) {
      key = column.split(".")[1];
    }

    if (dataProvider === DataProvider.SE) {
      switch (key) {
        case "select":
        case "datasheet":
          return null;
        case "manufacturerPartNumber":
          return "PartNumber";
        case "name":
          return "Manufacturer";
        case "manufacturerPartDescription":
          return "Description";
        case "obsolescenceStatus":
          return "Lifecycle";
        case "estimatedYearsToEOL":
          return "YEOL";
        case "rohs":
          return "RoHS";
      }
    }

    if (dataProvider === DataProvider.Z2DATA) {
      switch (key) {
        case "select":
        case "datasheet":
          return null;
        case "manufacturerPartNumber":
          return "MPN";
        case "name":
          return "Manufacturer";
        case "manufacturerPartDescription":
          return "Description";
        case "obsolescenceStatus":
          return "LifecycleStatus";
        case "estimatedYearsToEOL":
          return "EstimatedYearsToEOL";
        case "rohs":
          return "RoHSStatus";
      }
    }
  }

  async deleteManufacturer() {
    this.app.spinner.showSpinner();
    this.app.manufacturer.selectedManufacturer._deleted = true;
    await this.app.manufacturer.deleteManufacturer();
    await this.app.manufacturersFilter.getFilterResultBasedOnView("1");

    this.app.spinner.hideSpinner();
  }

  /** this function contains handling of 3 different situations  */
  /** 1. if you are coming from the RM search with a CPN selected but also have another filter set(right side) */
  /** 2. if you are coming from the RM search with a CPN selected but no other filter applied */
  /** 3. if you are on the manufacturer tables with a filter applied */
  /** 4. Default, just get the manufacturers paginated from the database */
  async getFilterResultBasedOnView(page: string) {
    this.app.spinner.showSpinner();
    if (
      this.app.RM.fromRMSearch ||
      this.app.filterHeaderTable.isFilterSelected
    ) {
      if (
        !Object.keys(this.app.filterHeaderTable.filterValues).includes(
          "partNumber"
        ) &&
        Object.keys(this.app.filterHeaderTable.filterValues).length > 0
      ) {
        await this.app.filterHeaderTable.getManufacturerSearchResult(page);
      } else {
        const { manufacturers, size } = await filterManufacturersByCPNs(
          this.app.customers.expectCurrent,
          this.app.RM.mode,
          this.app.tree.partsOfSearchedAssembly,
          page,
          this.app.paginator.pageSize.toString()
        );
        this.manufacturers = manufacturers;
        this.cleanManufacturers = manufacturers;
        if (page === "1") {
          this.firstPageManufacturers = manufacturers;
        }
        this.pages = size.toString();
        this.cleanSize = this.pages;
      }
    } else {
      await this.app.manufacturersFilter.getManufacturers(
        this.app.RM.mode,
        page
      );
    }
    this.app.spinner.hideSpinner();
  }
}
