import { FilterServiceType } from "./filter.service.type";
import { AppType, APP_TYPE } from "../app.type";
import { Inject, Injectable } from "@angular/core";
import { FilterPage } from "../filter-list/filter-list.state";

@Injectable()
export class FilterService implements FilterServiceType {
  constructor(@Inject(APP_TYPE) private app: AppType) {}

  isChecked(
    field: string,
    option: string,
    currentFilters: Partial<FilterPage>[]
  ) {
    let filters = currentFilters
      .filter((f: Partial<FilterPage>) => f.filterLabelField === field)
      .map((v) => v.values);

    if (filters.length === 0) {
      return false;
    }

    if (filters.findIndex((o: string) => o === option) !== -1) {
      return true;
    }
    return false;
  }

  isSelected(field: string, currentFilters: Partial<FilterPage>[]): boolean {
    if (
      currentFilters.findIndex(
        (filter: Partial<FilterPage>) => filter.filterLabelField === field
      ) !== -1
    ) {
      return true;
    }
    return false;
  }

  setFilterValues(currentFilters: Partial<FilterPage>[]) {
    let values: {} = {};

    // Get all the filters that should be applied to the current view
    var map = currentFilters.reduce(
      (entryMap, e) =>
        entryMap.set(e.filterLabelField, [
          ...(entryMap.get(e.filterLabelField) || []),
          e,
        ]),
      new Map()
    );

    // If there are any filters create query based on the type of the field
    Array.from(map.keys()).forEach(async (key: string) => {
      if (key != null) {
        let splitKey = key.split(".")[1];
        if (map.get(key).length === 1) {
          const searchedText = map.get(key)[0].values;
          if (this.getFieldType(key) === "value") {
            if (this.isFirstLetterSpecial(searchedText)) {
              (values as any)[splitKey] = {
                $regex: `(?i)\\${searchedText}`,
              };
            } else {
              (values as any)[splitKey] = {
                $regex: `(?i)${searchedText}`,
              };
            }
          } else {
            (values as any)[splitKey] = searchedText;
          }
        } else {
          (values as any)[splitKey] = {};
          (values as any)[splitKey].$or = [
            ...map.get(key).map((v: Partial<FilterPage>) => v.values),
          ];
        }
      }
    });

    return values;
  }

  private getFieldType(key: string) {
    if (this.app.RM.mode === "tree") {
      return this.app.filterTree.getFieldType(key);
    } else {
      return this.app.filterHeaderTable.getFieldType(key);
    }
  }

  private isFirstLetterSpecial(value: string) {
    let specialCharsRegExp = /^[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]+/;
    return specialCharsRegExp.test(value);
  }
}
