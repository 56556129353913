import { Component } from "@angular/core";
import { getApp } from "../app";

@Component({
  selector: "app-settings",
  templateUrl: "./settings.component.html",
  styleUrls: ["./settings.component.scss"]
})
export class SettingsComponent {
  app = getApp(app => {
    this.app = app;
  });

  constructor() {}
}
