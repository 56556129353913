import { Component, Input } from "@angular/core";
import { getApp } from "../app";

@Component({
  selector: "app-user-link",
  templateUrl: "./user-link.component.html",
  styleUrls: ["./user-link.component.scss"],
})
export class UserLinkComponent {
  @Input() name: string = "";
  @Input() type: string = "";

  app = getApp((app) => {
    this.app = app;
  });

  constructor() {}
}
