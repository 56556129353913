import { Component, Input } from "@angular/core";
import { BehaviorSubject, Observable } from "rxjs";
import { PcnData } from "../../../../../shared/models/manufacturer";
import { getApp } from "../../app";

@Component({
  selector: "app-pcn-history-table",
  templateUrl: "./pcn-history-table.component.html",
  styleUrls: ["./pcn-history-table.component.scss"],
})
export class PcnHitoryComponent {
  @Input() columns: string[] = [];
  @Input() showPaginator: boolean = true;
  selectedFilters: { columnName: string; value: string }[] = [];
  resetFilterInputs: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(
    false
  );
  resetFilterInputsObservable: Observable<boolean> =
    this.resetFilterInputs.asObservable();

  app = getApp((app) => {
    this.app = app;
  });
  cleanedColumns: string[] = [];
  cleanedDocs: PcnData[] = [];

  async ngOnInit() {
    this.app.manufacturerUtils.type = "pcnHistory";
    this.app.type = "pcnData";
    this.cleanedDocs = this.app.manufacturerUtils.pcnHistoryDocs;
    this.getCleanedColumns();
  }

  getCleanedColumns() {
    this.cleanedColumns = this.app.list.manufacturer.pcnHistoryColumns.map(
      (field: string) => field.split(".")[1]
    );
    return this.cleanedColumns;
  }

  /** display the list of type of change -
   * used locally just because the PcnData type does not have a settings file */
  getPcnTypeOfChange(value: string[]) {
    return value
      .map((value: string) =>
        this.app.field.getOptionText("manufacturer.pcnTypeOfChange", value)
      )
      .sort()
      .join(", ");
  }
}
