<div class="m-2 my-4">
  <div class="descr-header my-2 py-2">
    {{ app.text.debug.description }}
  </div>
  <button
    class="btn btn-type shadow-sm ms-0"
    [ngClass]="{ 'btn-type-active': data === 'part' }"
    type="button"
    (click)="showData('part')"
  >
    <i *ngIf="data === 'part'" class="fas fa-dot-circle"></i>
    <i *ngIf="data !== 'part'" class="far fa-circle"></i>
    {{ app.text.part.parts }}
  </button>
  <button
    class="btn btn-type shadow-sm"
    [ngClass]="{ 'btn-type-active': data === 'manufacturer' }"
    type="button"
    (click)="showData('manufacturer')"
  >
    <i *ngIf="data === 'manufacturer'" class="fas fa-dot-circle"></i>
    <i *ngIf="data !== 'manufacturer'" class="far fa-circle"></i>
    {{ app.text.debug.maunfacturers }}
  </button>
  <button
    class="btn btn-type shadow-sm"
    [ngClass]="{ 'btn-type-active': data === 'alert' }"
    type="button"
    (click)="showData('alert')"
  >
    <i *ngIf="data === 'alert'" class="fas fa-dot-circle"></i>
    <i *ngIf="data !== 'alert'" class="far fa-circle"></i>
    {{ app.text.mail.alert }}
  </button>
  <button
    class="btn btn-type shadow-sm"
    [ngClass]="{ 'btn-type-active': data === 'message' }"
    type="button"
    (click)="showData('message')"
  >
    <i *ngIf="data === 'message'" class="fas fa-dot-circle"></i>
    <i *ngIf="data !== 'message'" class="far fa-circle"></i>
    {{ app.text.debug.messages }}
  </button>
  <button
    class="btn btn-type shadow-sm"
    [ngClass]="{ 'btn-type-active': data === 'all' }"
    type="button"
    (click)="showData('all')"
  >
    <i *ngIf="data === 'all'" class="fas fa-dot-circle"></i>
    <i *ngIf="data !== 'all'" class="far fa-circle"></i>
    {{ app.text.debug.all }}
  </button>
</div>

<div class="card json-card m-2">
  <!-- <div *ngIf="downloadJsonHref">
    <div>
      <a
        class="btn btn-blue p-2 m-2"
        [href]="downloadJsonHref"
        [download]="app.customers.expectCurrent + '-' + data + '.json'"
      >
        <span class="btn-blue-text">Export {{ data }} as Json</span>
      </a>
    </div>
  </div> -->
  <div>
    <!-- <div>
      <pre>
        {{ docs | json }}
      </pre>
    </div> -->
    <div *ngIf="!downloadJsonHref">Fetching data. Please wait ...</div>
    <div *ngIf="downloadJsonHref">
      <div>Document ready for download!</div>
      <a
        class="btn btn-blue p-2 m-2"
        [href]="downloadJsonHref"
        [download]="createFileName()"
      >
        <span class="btn-blue-text">Export {{ data }} as Json</span>
      </a>
    </div>
  </div>
</div>
