import { ComponentSettings, FieldTypes } from "../settings/settings";

const field = {
  bavFv: {
    type: FieldTypes.typeahead,
    text: {
      de: "BAV-Fernverkehr",
      en: "BAV-Fernverkehr",
    },
    search: false,
  },
  bavFvEmail: {
    type: FieldTypes.options,
    text: {
      de: "BAV-Fernverkehr",
      en: "BAV-Fernverkehr",
    },
    search: false,
  },
  bavFvVertreter: {
    type: FieldTypes.typeahead,
    text: {
      de: "Vertreter",
      en: "Deputy",
    },
    search: false,
  },
  bavFvVertreterEmail: {
    type: FieldTypes.value,
    text: {
      de: "BAV-Fernverker Vertreter",
      en: "BAV-Fernverker Vertreter",
    },
    search: false,
  },
  mawiFv: {
    type: FieldTypes.typeahead,
    text: {
      de: "MaWi-Fernverkehr",
      en: "MaWi-Fernverkehr",
    },
    search: false,
  },
  mawiFvEmail: {
    type: FieldTypes.options,
    text: {
      de: "MaWi-Fernverkehr",
      en: "MaWi-Fernverkehr",
    },
    search: false,
  },
  mawiFvVertreter: {
    type: FieldTypes.typeahead,
    text: {
      de: "Vertreter",
      en: "Deputy",
    },
    search: false,
  },
  mawiFvVertreterEmail: {
    type: FieldTypes.options,
    text: {
      de: "MaWi FV Vertreter",
      en: "MaWi FV Vertreter",
    },
    search: false,
  },
  bavRegio: {
    type: FieldTypes.typeahead,
    text: {
      de: "BAV Regio",
      en: "BAV Regio",
    },
    search: false,
  },
  bavRegioEmail: {
    type: FieldTypes.options,
    text: {
      de: "BAV Regio",
      en: "BAV Regio",
    },
    search: false,
  },
  bavRegioVertreter: {
    type: FieldTypes.typeahead,
    text: {
      de: "Vertreter",
      en: "Deputy",
    },
    search: false,
  },
  bavRegioVertreterEmail: {
    type: FieldTypes.typeahead,
    text: {
      de: "BAV Regio Vertreter",
      en: "BAV Regio Vertreter",
    },
    search: false,
  },
  mawiRegio: {
    type: FieldTypes.typeahead,
    text: {
      de: "MaWi Regio",
      en: "MaWi Regio",
    },
    search: false,
  },
  mawiRegioEmail: {
    type: FieldTypes.options,
    text: {
      de: "MaWi Regio",
      en: "MaWi Regio",
    },
    search: false,
  },
  mawiRegioVertreter: {
    type: FieldTypes.typeahead,
    text: {
      de: "Vertreter",
      en: "Deputy",
    },
    search: false,
  },
  mawiRegioVertreterEmail: {
    type: FieldTypes.options,
    text: {
      de: "MaWi Regio Vertreter",
      en: "MaWi Regio Vertreter",
    },
    search: false,
  },
  bavCargo: {
    type: FieldTypes.typeahead,
    text: {
      de: "BAB Cargo",
      en: "BAB Cargo",
    },
    search: false,
  },
  bavCargoEmail: {
    type: FieldTypes.options,
    text: {
      de: "BAB Cargo",
      en: "BAB Cargo",
    },
    search: false,
  },
  bavCargoVertreter: {
    type: FieldTypes.typeahead,
    text: {
      de: "BAV Cargo",
      en: "BAV Cargo",
    },
    search: false,
  },
  bavCargoVertreterEmail: {
    type: FieldTypes.options,
    // text: {
    //   de: "BAV Cargo",
    //   en: "BAV Cargo",
    // },
    text: {
      de: "Vertreter",
      en: "Deputy",
    },
    search: false,
  },
  mawiCargo: {
    type: FieldTypes.typeahead,
    text: {
      de: "MaWi Cargo",
      en: "MaWi Cargo",
    },
    search: false,
  },
  mawiCargoEmail: {
    type: FieldTypes.options,
    text: {
      de: "MaWi Cargo",
      en: "MaWi Cargo",
    },
    search: false,
  },
  mawiCargoVertreter: {
    type: FieldTypes.typeahead,
    text: {
      de: "Vertreter",
      en: "Deputy",
    },
    search: false,
  },
  mawiCargoVertreterEmail: {
    type: FieldTypes.options,
    text: {
      de: "MaWi Cargo Vertreter",
      en: "MaWi Cargo Vertreter",
    },
    search: false,
  },
  bavFzi: {
    type: FieldTypes.typeahead,
    text: {
      de: "BAV (Drittkunden)",
      en: "BAV (Drittkunden)",
    },
    search: false,
  },
  bavFziEmail: {
    type: FieldTypes.options,
    text: {
      de: "BAV (Drittkunden)",
      en: "BAV (Drittkunden)",
    },
    search: false,
  },
  bavFziVertreter: {
    type: FieldTypes.typeahead,
    text: {
      de: "Vertreter",
      en: "Deputy",
    },
    search: false,
  },
  bavFziVertreterEmail: {
    type: FieldTypes.options,
    text: {
      de: "BAV FZI Vertreter",
      en: "BAV FZI Vertreter",
    },
    search: false,
  },
  mawiFzi: {
    type: FieldTypes.typeahead,
    text: {
      de: "MaWi FZI",
      en: "MaWi FZI",
    },
    search: false,
  },
  mawiFziEmail: {
    type: FieldTypes.options,
    text: {
      de: "MaWi FZI",
      en: "MaWi FZI",
    },
    search: false,
  },
  mawiFziVertreter: {
    type: FieldTypes.typeahead,
    text: {
      de: "Vertreter",
      en: "Deputy",
    },
    search: false,
  },
  mawiFziVertreterEmail: {
    type: FieldTypes.options,
    text: {
      de: "MaWi Fzi Vertreter",
      en: "MaWi Fzi Vertreter",
    },
    search: false,
  },
  bavNetz: {
    type: FieldTypes.typeahead,
    text: {
      de: "BAV Sonstige",
      en: "BAV Sonstige",
    },
    search: false,
  },
  bavNetzEmail: {
    type: FieldTypes.options,
    text: {
      de: "BAV Sonstige",
      en: "BAV Sonstige",
    },
    search: false,
  },
  bavNetzVertreter: {
    type: FieldTypes.typeahead,
    text: {
      de: "Vertreter",
      en: "Deputy",
    },
    search: false,
  },
  bavNetzVertreterEmail: {
    type: FieldTypes.options,
    text: {
      de: "BAV Sonstige Vertreter",
      en: "BAV Sonstige Vertreter",
    },
    search: false,
  },
  mawiNetz: {
    type: FieldTypes.typeahead,
    text: {
      de: "MaWi Sonstige",
      en: "MaWi Sonstige",
    },
    search: false,
  },
  mawiNetzEmail: {
    type: FieldTypes.options,
    text: {
      de: "MaWi Sonstige",
      en: "MaWi Sonstige",
    },
    search: false,
  },
  mawiNetzVertreter: {
    type: FieldTypes.typeahead,
    text: {
      de: "Vertreter",
      en: "Deputy",
    },
    search: false,
  },
  mawiNetzVertreterEmail: {
    type: FieldTypes.options,
    text: {
      de: "MaWi Sonstige Vertreter",
      en: "MaWi Sonstige Vertreter",
    },
    search: false,
  },
  responsibles: {
    type: FieldTypes.value,
    text: {
      de: "Fahrzeug verantwortlich",
      en: "Vehicle Responsible",
      nl: "Voertuig verantwoordelijk",
    },
  },
};

export const settings = {
  permission: {},
  text: {
    department: { de: "Abteilung", en: "Department", nl: "Afdeling" },
    completeTask: {
      de: "KOMPLETTE AUFGABE",
      en: "COMPLETE TASK",
      nl: "VOLTOOIDE TAAK",
    },
  },
  url: {},
  field,
  list: {},
};

export const responsibles: ComponentSettings<typeof settings> = settings;
