<app-modal
  #deleteDocsModal
  [modalMessage]="app.text.pcn.deleteQuestion"
  [modalTitle]="app.text.alert.modalTitle"
  (ok)="deletePcnDocs()"
>
</app-modal>
<app-paginator
  [length]="app.pcns.pcns.length"
  [index]="app.paginator.focus"
  (focus)="app.paginator.focus = $event"
></app-paginator>
<div class="row" *ngIf="app.table.selected.size > 0">
  <div class="col-md-4">
    <div class="d-inline-flex selectedAlerts">
      <a
        class="pointer"
        *ngIf="app.table.selected.size > 0"
        (click)="deleteDocsModal.open()"
      >
        <i class="fas fa-trash"></i> &nbsp;
        {{ app.text.alert.deleteAllSelected }}
      </a>
    </div>
  </div>
  <div class="col-md-4 text-center">
    <div class="text-center d-inline-flex selectedAlerts">
      <span> {{ app.table.selected.size }} {{ app.text.mail.selected }} </span>
      <a
        class="selectAllButtton"
        (click)="app.table.selectAll(app.pcns.pcns, true)"
        *ngIf="app.table.selected.size !== app.pcns.pcns.length"
      >
        {{ app.text.mail.selectAll }} {{ app.pcns.pcns.length }}
      </a>
      <a
        class="selectAllButtton"
        *ngIf="app.table.selected.size === app.pcns.pcns.length"
        (click)="app.table.clearSelection()"
      >
        {{ app.text.mail.deselectAll }}
      </a>
    </div>
  </div>
</div>
<div
  class="tableFixHead"
  [ngClass]="{ tableHeight: app.filterTable.isDropdownOpen }"
>
  <table class="table table-striped spacing-table">
    <thead>
      <tr>
        <th class="p-1" *ngFor="let column of columns">
          <div class="d-inline-flex">
            <div
              [ngClass]="{
                'resize-table': app.table.canColumnBeResized(column)
              }"
            >
              <span
                (click)="
                  app.table.toggleSortDirection(column);
                  app.table.sort(app.pcns.pcns)
                "
              >
                {{ app.field.handleSpecialLogicLabels(column) }}
              </span>
              <div *ngIf="column === 'select'">
                <i
                  class="far fa-square"
                  *ngIf="app.table.selected.size === 0"
                  (click)="app.table.selectAll(app.pcns.pcns)"
                ></i>
                <i
                  class="far fa-check-square"
                  *ngIf="app.table.selected.size > 0"
                  (click)="app.table.clearSelection()"
                ></i>
              </div>
            </div>
            &nbsp;
            <span *ngIf="column === app.table.sortField">
              <i
                *ngIf="app.table.sortDirection === 1"
                class="fa fa-chevron-up"
              ></i>
              <i
                *ngIf="app.table.sortDirection === -1"
                class="fa fa-chevron-down"
              ></i>
            </span>
          </div>
          <div *ngIf="app.filterTable.getFilterTableColumns(column)">
            <app-filter-table
              [column]="app.mail.getColumnShort(column)"
              [columns]="columnsShort"
              [docType]="'pcn'"
              [cleanDocs]="app.pcns.cleanPcns"
              [selectedFilters]="selectedFilters"
              (resultedDocs)="app.filterTable.getFilterResults($event, 'pcn')"
            >
            </app-filter-table>
          </div>
        </th>
      </tr>
    </thead>
    <tbody>
      <tr
        class="p-1"
        *ngFor="let pcn of app.paginator.getPage(app.pcns.pcns)"
        [ngClass]="{ read: pcn.seen }"
        class="pointer"
        (click)="
          app.mail.markItemsAsSeen('seen', pcn);
          app.routing.navigatePcn(pcn._id)
        "
      >
        <td class="p-1" *ngFor="let column of columnsShort">
          <div [ngSwitch]="app.table.getColumnTypeDisplay(column)">
            <div *ngSwitchCase="'time'">
              {{ pcn[column] | date : "yyyy-MM-dd HH:mm:ss" }}
            </div>
            <div *ngSwitchCase="'normal'">{{ pcn[column] }}</div>
            <div *ngSwitchCase="'artNumber'">{{ pcn[column] }}</div>
            <div *ngSwitchCase="'actions'">
              <div *ngIf="column === 'createCase'">
                <i
                  class="far fa-folder-plus click-area icon-blue pointer"
                  (click)="
                    app.routing.navigateNewThread();
                    app.pcn.id = pcn._id;
                    app.pcn.pcn = app.pcn.getPcn(pcn._id)
                  "
                ></i>
              </div>
              <div *ngIf="column === 'seen'">
                <button
                  class="btn btn-hidden pt-0 ps-0"
                  *ngIf="
                    pcn[column] &&
                    pcn.update_user !== undefined &&
                    pcn.update_time !== null
                  "
                  [ngbTooltip]="
                    pcn.update_user +
                    ' ' +
                    app.text.credits.on +
                    ' ' +
                    (pcn.update_time | date : 'yyyy-MM-dd')
                  "
                >
                  <i class="fas fa-check-double fa-green"></i>
                </button>
                <button
                  class="btn btn-hidden pt-0 ps-0"
                  *ngIf="pcn[column] && pcn.update_user == undefined"
                >
                  <i class="fas fa-check-double fa-green"></i>
                </button>
                <button class="btn btn-hidden pt-0 ps-0" *ngIf="!pcn[column]">
                  <i class="fas fa-exclamation-circle fa-orange"></i>
                </button>
              </div>
              <div *ngIf="column === 'select'">
                <button
                  class="btn btn-hidden pt-0 ps-0"
                  *ngIf="!pcn[column]"
                  (click)="
                    app.table.toggleSelect(pcn); $event.stopPropagation()
                  "
                >
                  <i
                    class="far fa-check-square"
                    *ngIf="app.table.selected.has(pcn)"
                  ></i>
                  <i
                    class="far fa-square"
                    *ngIf="!app.table.selected.has(pcn)"
                  ></i>
                </button>
              </div>
            </div>
            <div *ngSwitchCase="'getFieldValueAsText'">
              {{ app.field.getFieldValueAsText("pcn." + column, pcn[column]) }}
            </div>
            <div *ngSwitchCase="'optionText'">
              <span
                class="badge badgeStatus"
                [ngStyle]="
                  app.field.getColor(
                    app.fieldId.alert.criticality,
                    pcn.criticality
                  )
                "
              >
                {{ app.field.getOptionText("pcn." + column, pcn[column]) }}
              </span>
            </div>
            <div class="fixedWidth" *ngSwitchCase="'limit'">
              <span (click)="$event.stopPropagation()">
                <app-limit
                  [textInput]="
                    app.field.getItemsAsString(
                      app.field.getFieldValueAsArray(
                        'pcn.' + column,
                        pcn[column]
                      )
                    )
                  "
                  limit="20"
                >
                </app-limit>
              </span>
            </div>
          </div>
        </td>
      </tr>
      <tr>
        <td *ngIf="loading" colspan="99" class="text-center">
          {{ this.app.text.core.loading }}
        </td>
        <td
          *ngIf="!loading && app.pcns.pcns.length === 0"
          colspan="99"
          class="text-center"
        >
          {{ app.text.app.noResults }}
        </td>
      </tr>
    </tbody>
  </table>
</div>
<app-paginator
  [length]="app.pcns.pcns.length"
  [index]="app.paginator.focus"
  (focus)="app.paginator.focus = $event"
></app-paginator>
