import { ComponentSettings, FieldTypes } from "../settings/settings";

export const creditsFields = {
  user_id: {
    type: FieldTypes.value,
    text: {
      de: "Erstellt von",
      en: "Created by",
      nl: "Gemaakt door",
    },
    search: false,
  },
  create_time: {
    text: {
      de: "Erstellt am",
      en: "Created on",
      nl: "Gemaakt op",
    },
    type: FieldTypes.timestamp,
    search: false,
  },
  update_user: {
    type: FieldTypes.value,
    text: {
      de: "Letzte Änderung von",
      en: "Last edited by",
      nl: "Laatst gewijzigd door",
    },
    search: false,
  },
  update_time: {
    text: {
      de: "Letzte Änderung am",
      en: "Last edited on",
      nl: "Laatst gewijzigd op",
    },
    type: FieldTypes.timestamp,
    search: false,
  },
};

export const settings = {
  permission: {},
  text: {
    created: { de: "Erstellt", en: "Created", nl: "Gemaakt" },
    updated: {
      de: "Zuletzt geändert",
      en: "Last edited",
      nl: "Laatst gewijzigd",
    },
    commented: {
      de: "Zuletzt kommentiert",
      en: "Last commented",
      nl: "Commentaar gewijzigd",
    },
    completed: { de: "Abgeschlossen", en: "Completed", nl: "Afgerond" },
    statusWhenCreated: {
      en: "Case status when created:",
      de: "Fallstatus beim Erstellen:",
      nl: "Issue status bij het aanmaken:",
    },
    post_update: {
      de: "Zuletzt aktualisiert",
      en: "Last updated",
      nl: "Laatst bijgewerkt",
    },
    by: { de: "von", en: "by", nl: "door" },
    on: { de: "am", en: "on", nl: "op" },
    at: { de: "um", en: "at", nl: "om" },
    unknown: { de: "Unbekannt", en: "Unknown", nl: "Onbekend" },
    open: {
      de: "Profil öffnen",
      en: "Open profile page",
      nl: "Profiel pagina openen",
    },
  },
  list: {},
  url: {},
  field: {},
};

export const credits: ComponentSettings<typeof settings> = settings;
