import { paragraphDef, TableDef } from "pdfmake/build/pdfmake";
import { getApp } from "../../../app/app";
import { AppType } from "../../app.type";

export interface AdditionalField {
  name: string;
  value: string;
}
export interface Credits {
  create: string;
  update: string;
}

export interface PostData {
  [key: string]: string | AdditionalField[] | Credits | boolean | undefined;
  title: string;
  content: string;
  taskDescription?: string;
  taskDueDate?: any;
  taskResponsible?: string;
  taskNote?: string;
  taskCompleted?: boolean;
  additionalFields: AdditionalField[];
  credits: Credits;
}

export const getPostDef = (data: PostData): TableDef => {
  const app = getApp();
  let itemDef: TableDef;
  const title = getPostTitleDef(data.title);
  const content = getContentDef(data.content);
  const taskDescription = getTaskDescriptionDef(app, data.taskDescription);
  const taskDueDate = getTaskDueDateDef(app, data.taskDueDate);
  const taskResponsible = getTaskResponsibleDef(app, data.taskResponsible);
  const taskNote = getTaskNoteDef(app, data.taskNote);
  const taskCompleted = getTaskCompletedDef(app, data.taskCompleted);
  const additionalFields = getAdditionalFieldsDef(data.additionalFields);
  const credits = getCreditsDef(data.credits);

  itemDef = {
    table: {
      widths: ["*"],
      body: [],
    },
    margin: [0, 0, 0, 8],
  };
  if (data.taskResponsible) {
    itemDef.table.body.push([taskResponsible]);
    itemDef.table.body.push([taskDueDate]);
    if (data.taskCompleted === true) {
      itemDef.table.body.push([taskNote]);
    }
    itemDef.table.body.push([taskDescription]);
    itemDef.table.body.push([taskCompleted]);
  } else {
    itemDef.table.body.push([title]);
    if (additionalFields) {
      itemDef.table.body.push([additionalFields]);
    }
    itemDef.table.body.push([content]);
  }
  itemDef.table.body.push([credits]);

  return itemDef;
};

function getPostTitleDef(title: string): paragraphDef {
  let def: paragraphDef;
  def = {
    text: title || "",
    bold: true,
    margin: [0, 0, 0, 0],
    border: [true, true, true, false],
    fontSize: 12,
  };
  return def;
}

function getAdditionalFieldsDef(fields: AdditionalField[]): TableDef | null {
  let def: TableDef;
  const app = getApp();
  if (fields.length === 0) {
    return null;
  }
  def = {
    margin: [0, 0, 0, 0],
    border: [true, false, true, false],

    table: {
      widths: ["auto", "*"],
      body: [],
    },
    layout: {
      paddingLeft: () => 0,
    },
  };
  fields.forEach((field) => {
    //used for displaying accepted solution in the pdf
    if (field.name === app.field.getLabel(app.fieldId.post.acceptedSolution)) {
      if (field.value) {
        field.value = "Yes";
      } else {
        field.value = "No";
      }
    }
    def.table.body.push([
      { text: field.name + ":", border: [false], fontSize: 11 },
      { text: field.value, border: [false], fontSize: 11 },
    ]);
  });
  return def;
}

function getContentDef(content: string): paragraphDef {
  return {
    text: content,
    border: [true, false, true, false],
    italics: true,
    fontSize: 10,
  };
}

function getTaskResponsibleDef(
  app: AppType,
  descr: string | undefined
): paragraphDef {
  let def: paragraphDef;
  let label = app.field.getLabel(app.fieldId.post.taskResponsible) + ": ";
  def = {
    text: label + descr || "",
    bold: false,
    margin: [0, 0, 0, 0],
    border: [true, true, true, false],
    fontSize: 12,
  };
  return def;
}

function getTaskDueDateDef(
  app: AppType,
  dueDate: string | undefined
): paragraphDef {
  let def: paragraphDef;
  let label = app.field.getLabel(app.fieldId.post.taskDueDate) + ": ";
  def = {
    text: label + dueDate || "",
    bold: false,
    margin: [0, 0, 0, 0],
    border: [true, false, true, false],
    fontSize: 12,
  };
  return def;
}

function getTaskNoteDef(app: AppType, note: string | undefined): paragraphDef {
  let def: paragraphDef;
  let label = app.field.getLabel(app.fieldId.post.taskNote) + ": ";
  def = {
    text: note != null ? label + note : label + "",
    bold: false,
    margin: [0, 0, 0, 0],
    border: [true, false, true, false],
    fontSize: 12,
  };
  return def;
}

function getTaskCompletedDef(
  app: AppType,
  completed: boolean | undefined
): paragraphDef {
  let def: paragraphDef;
  let label = app.field.getLabel(app.fieldId.post.taskCompleted) + ": ";
  def = {
    text: completed ? label + ": " + "Yes" : label + ": " + "No",
    bold: false,
    margin: [0, 0, 0, 0],
    border: [true, false, true, false],
    fontSize: 12,
  };
  return def;
}

function getTaskDescriptionDef(
  app: AppType,
  descr: string | undefined
): paragraphDef {
  let def: paragraphDef;
  def = {
    text: descr != null ? descr : "",
    bold: false,
    margin: [0, 0, 0, 0],
    border: [true, false, true, false],
    fontSize: 12,
  };
  return def;
}

function getCreditsDef(credits: Credits): paragraphDef {
  const def: paragraphDef = {
    text: [credits.create],
    alignment: "right",
    fontSize: 10,
    border: [true, false, true, true],
  };
  if (credits.update) {
    def.text.push("\n" + credits.update);
  }
  return def;
}
