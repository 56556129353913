import { paragraphDef, TableDef } from "pdfmake/build/pdfmake";
import { AppType } from "../../app.type";

export interface AttachmentData {
  [key: string]: string | string[];
  name: string;
  tags: string[];
}

export const getAttachmentDef = (
  data: AttachmentData,
  app: AppType
): TableDef => {
  let itemDef: TableDef;
  const name = getContentDef(data.name);
  const tags = getTags(data.tags, app);

  itemDef = {
    table: {
      widths: ["*"],
      body: [],
    },
    margin: [0, 0, 0, 8],
  };

  itemDef.table.body.push([name]);
  if (tags) {
    itemDef.table.body.push([tags]);
  }

  return itemDef;
};

function getTags(tags: string[], app: AppType): TableDef | null {
  let def: TableDef;
  if (tags[0].length === 0) {
    return null;
  }
  def = {
    margin: [0, 0, 0, 0],
    border: [false],

    table: {
      widths: ["*"],
      body: [],
    },
  };
  tags.forEach((tag) => {
    def.table.body.push([
      {
        text: `${app.text.pdf.tags}: ${tag} `,
        border: [false],
        fontSize: 11,
        italics: true,
      },
    ]);
  });
  return def;
}

function getContentDef(content: string): paragraphDef {
  return {
    text: content,
    border: [false],
    fontSize: 10,
  };
}
