import { paragraphDef, TableDef } from "pdfmake/build/pdfmake";
import { getApp } from "../../../app/app";
import { Impact } from "../../../../../shared/models/impact";

const getImpactsTableBody = (impacts: Impact[]): paragraphDef[][] => {
  const app = getApp();
  const body = [] as paragraphDef[][];
  impacts.forEach((impact) => {
    const row: paragraphDef[] = app.list.impact.pdfColumns.map((field) =>
      impact[field.split(".")[1]] != null ? impact[field.split(".")[1]] : ""
    );
    body.push(row);
  });
  return body;
};

export const getImpactsTableDefinition = (impacts: Impact[]): TableDef => {
  const app = getApp();
  return {
    layout: "lightHorizontalLines",
    table: {
      widths: ["*", "*", "*", "*"],
      body: [
        app.list.impact.pdfColumns.map((field) => app.field.getLabel(field)),
        ...getImpactsTableBody(impacts),
      ],
    },
  };
};
