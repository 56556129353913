import { Component, OnInit } from "@angular/core";
import { Subscription } from "rxjs";
import { getApp } from "../app";
import { VehicleResponsible } from "../../../../shared/models/vehicleResponsible";
import { deleteVehicle } from "../api.service";
import { Customer } from "../../../../shared/types/customers";

@Component({
  selector: "app-vehicle-responsible-list",
  templateUrl: "./vehicle-responsible-list.component.html",
  styleUrls: ["./vehicle-responsible-list.component.scss"],
})
export class VehicleResponsibleListComponent implements OnInit {
  vehiclesColumns: string[] = [];
  selectedFilters: { columnName: string; value: string }[] = [];
  filterOpen: boolean = false;
  listSubscription = new Subscription();

  app = getApp((app) => {
    this.app = app;
  });

  columns: string[] = [];
  columnsShort: string[] = [];

  constructor() {}

  async ngOnInit() {
    await this.initFunctions();
    /** reinitialize documents on add/delete */
    this.listSubscription =
      this.app.vehicleResponsible.vResponsibleSubject.subscribe(
        async (value) => {
          if (value) {
            await this.initFunctions();
          }
        }
      );
    this.listSubscription =
      this.app.vehicleResponsible.vResponsibleDeleted.subscribe(
        async (value) => {
          if (value) {
            await this.initFunctions();
          }
        }
      );
    /** used for displaying the list of user options in the filtering dropdwons */
    await this.app.users.getUsersWithRoles();

    this.columns = this.app.list.vehicleResponsible.vehicleResponsible;
    this.columnsShort = this.columns.map(
      (column: string) => column.split(".")[1]
    );
    if (this.app.customers.expectCurrent !== Customer.DB) {
      this.columns.push("actions");
    }
  }

  async deleteVehicle(doc: VehicleResponsible) {
    const customer = this.app.customers.expectCurrent;
    let vehicleDoc = doc;
    vehicleDoc._deleted = true;
    await deleteVehicle(customer, vehicleDoc);
    await this.app.vehicleResponsible.getVehicleDocuments();
  }

  async initFunctions() {
    this.app.type = "vehicleResponsible";
    await this.app.vehicleResponsible.getVehicleDocuments();
    if (this.app.state.filterByPage.length !== 0) {
      this.filterOpen = false;
      this.app.filterList.open = false;
      this.app.filterFields.createFilterObject();
    }
  }

  ngOnDestroy(): void {
    if (this.listSubscription) {
      this.listSubscription.unsubscribe();
    }
  }
}
