import { ActivatedRoute } from "@angular/router";
import { Component, OnInit } from "@angular/core";
import { getApp } from "../app";
import { Part } from "../../../../shared/models/part";
import { Manufacturer, SeMatch } from "../../../../shared/models/manufacturer";

@Component({
  selector: "app-manufacturer-matching",
  templateUrl: "./manufacturer-matching.component.html",
  styleUrls: ["./manufacturer-matching.component.scss"],
})
export class ManufacturerMatchingComponent implements OnInit {
  partNumber: string | null = null;
  manufacturerId: string | null = null;
  manufacturer = new Manufacturer();
  part = {} as Part;
  columns: string[] = [];
  columnRows: string[] = [];
  manufacturers: Manufacturer[] = [];
  columnFilters: string[] = [];
  matchesCopy: SeMatch[] = [];
  matches: SeMatch[] = [];
  currentMatch: SeMatch[] = [];

  constructor(private route: ActivatedRoute) {}
  app = getApp((app) => {
    this.app = app;
  });

  async ngOnInit() {
    this.manufacturerId = this.route.snapshot.paramMap.get("manufacturer");
    this.partNumber = this.route.snapshot.paramMap.get("part");
    this.columns = this.app.list.matchingManufacturer.columns;
    this.columnRows = this.app.list.matchingManufacturer.columnRows;
    if (this.manufacturerId == null) {
      return;
    }
    this.app.manufacturerMatching.manufacturer =
      await this.app.manufacturerMatching.getCurrentManufacturer(
        this.manufacturerId
      );
    this.manufacturer = this.app.manufacturerMatching.manufacturer;

    if (
      this.app.manufacturer.getMatchingStatusOfManufacturer(
        this.manufacturer
      ) === "manual" ||
      this.app.manufacturer.getMatchingStatusOfManufacturer(
        this.manufacturer
      ) === "unmatched"
    ) {
      this.manufacturer.manufacturerPartNumber = "";
      this.manufacturer.name = "";
      this.manufacturer.manufacturerPartDescription = "";
      this.manufacturer.datasheet = "";
      this.manufacturer.obsolescenceStatus = "";
    }

    if (this.partNumber == null) {
      return;
    }
    this.part = await this.app.manufacturerMatching.getCurrentPart(
      this.partNumber
    );
    // this.app.header.isRM = true;

    if (
      this.app.manufacturer.getMatchingStatusOfManufacturer(
        this.manufacturer
      ) === "matched" ||
      this.app.manufacturer.getMatchingStatusOfManufacturer(
        this.manufacturer
      ) === "manualAssignment"
    ) {
      this.app.manufacturerMatching.updateCurrentMatchIfChangesExist(
        this.manufacturer,
        this.matches
      );
    }

    // used only because the app.view is updated after the header is loadead
    this.app.header.getHeaderTitle();
  }

  // used only for getting the last rev number
  async deleteMatch(match: SeMatch) {
    await this.app.manufacturerMatching.deleteMatch(this.manufacturer, match);
    if (this.manufacturerId == null) {
      return;
    }
    this.manufacturer =
      await this.app.manufacturerMatching.getCurrentManufacturer(
        this.manufacturerId
      );
  }

  displayUnmatched() {
    //  display the unmatch button only when there are values saved on the manufacturer and need to be clean
    let matchingStatus = this.app.manufacturer.getMatchingStatusOfManufacturer(
      this.manufacturer
    );

    if (
      matchingStatus === "override" ||
      matchingStatus === "unmatched" ||
      matchingStatus === "manual"
    ) {
      return false;
    }
    return true;
  }
}
