import { paragraphDef, TableDef } from "pdfmake/build/pdfmake";

export class PdfTable {
  private readonly defaults: TableDef = {
    table: {
      body: [],
    },
  };

  private tableDef: TableDef;

  constructor(tableDef: Partial<TableDef>) {
    this.tableDef = { ...this.defaults, ...tableDef };
  }

  get(): TableDef {
    return this.tableDef;
  }

  addRow(row: paragraphDef[]): PdfTable {
    this.tableDef.table.body.push(row);
    return this;
  }
}
