import { Settings } from "../components";
import { _ComponentSettings } from "../settings/settings";

export type ModelProxy = {
  [name in keyof Settings]: ComponentModelProxy<Settings[name]["field"]>;
};

export type ComponentModelProxy<T> = {
  [name in keyof T]: any;
};

export function getModelProxy(settings: Settings, model: any) {
  const proxy: any = {};
  Object.keys(settings).forEach((name) => {
    Object.defineProperty(proxy, name, {
      get: () => {
        const component: _ComponentSettings = (<any>settings)[name];
        return getComponentModelProxy(component, name, model);
      },
    });
  });

  return <ModelProxy>proxy;
}

function getComponentModelProxy<T extends _ComponentSettings>(
  component: T,
  componentName: string,
  model: any
) {
  const proxy: any = {};
  const { field } = component;
  Object.keys(field).forEach((name) => {
    const id = componentName + "." + name;
    Object.defineProperty(proxy, name, {
      get: () => {
        return model[id];
      },
      set: (value: any) => {
        model[id] = value;
      },
    });
  });
  return <ComponentModelProxy<T>>proxy;
}
