<app-modal
  #deleteAttachmentModal
  [modalMessage]="app.text.attachments.deleteQuestion"
  [modalTitle]="app.text.attachments.modalTitle"
  (ok)="app.file.delete(fileLink)"
></app-modal>
<app-modal
  #unmarkAttachmentModal
  [modalMessage]="app.text.attachments.unmarkQuestion"
  [modalTitle]="app.text.attachments.modalTitle"
  (ok)="app.attachments.markAttachment(fileName, fileDetails, fileLink, false)"
></app-modal>

<div class="col-md-12 ps-0">
  <!-- <div *ngIf="!app.attachments.sortingMode"> -->
  <span *ngFor="let tag of getTags()">
    <span class="badge selected attBadge" style="margin-right: 5px"
      >{{ tag }}
      <i
        class="fa fa-times fa-small ms-1"
        *ngIf="hasPermissionToDeleteTag(fileDetails)"
        (click)="app.attachment.deleteTag(fileDetails, tag, fileLink)"
      ></i
    ></span>
  </span>
  <!-- </div> -->
</div>
<!-- attachment name & attachment details -->
<div class="col-md-12 p-1">
  <span *ngIf="safetyEvaluationFileNS">*</span>

  <button
    class="btn attBtn"
    *ngIf="
      fileDetails &&
      fileDetails.fziAttachment === true &&
      editPermission &&
      app.permission.attachment.markAttAsSensitive
    "
    [ngbTooltip]="app.text.attachments.markAsSensitive"
    (click)="unmarkAttachmentModal.open()"
  >
    <i class="fas fa-user-lock"></i>
  </button>
  <button
    *ngIf="
      fileDetails &&
      fileDetails.fziAttachment !== true &&
      checkPermission(fileName) &&
      app.permission.attachment.markAttAsSensitive
    "
    (click)="
      app.attachments.markAttachment(fileName, fileDetails, fileLink, true)
    "
    class="btn attBtn"
    [ngbTooltip]="app.text.attachments.unmarkAsSensitive"
  >
    <i class="fad fa-user-unlock" id="fa-red"></i>
  </button>
  <span *ngIf="!url">
    <a
      class="attachment pointer"
      [ngClass]="{ 'attachment-oc-sm': app.view === 'oc-home' }"
      (click)="downloadAttachment(fileLink)"
    >
      <span class="attachmentName">
        {{ fileName.split(".")[0] }}
      </span>
      <span class="attachmentExtension">
        {{ fileExtension }}
      </span>
    </a>
  </span>
  <span *ngIf="url">
    <a
      class="attachment pointer"
      [ngClass]="{ 'attachment-oc-sm': app.view === 'oc-home' }"
      data-bs-toggle="dropdown"
    >
      <span class="attachmentName">
        {{ fileName.split(".")[0] }}
      </span>
      <span class="attachmentExtension">
        {{ fileExtension }}
      </span>
    </a>
    <div class="dropdown-menu m-0 p-1">
      <a
        class="dropdown-item attDropdown m-0 p-1"
        (click)="downloadAttachment(fileLink)"
      >
        <i class="fa fa-download"></i>&nbsp; {{ app.text.attachments.download }}
      </a>
      <a class="dropdown-item attDropdown m-0 p-1" [href]="url" target="_blank">
        <i class="fa fa-external-link"></i>&nbsp;
        {{ app.text.attachments.openLink }}
      </a>
    </div>
  </span>
  <button
    *ngIf="checkPermission(fileName)"
    class="btn attBtn"
    (click)="showTagField = !showTagField"
    [title]="app.text.attachments.addTag"
  >
    <i class="fa fa-tag"></i>
  </button>
  <button
    *ngIf="checkPermission(fileName)"
    class="btn attBtn"
    (click)="deleteAttachmentModal.open()"
    [title]="app.text.attachments.delete"
  >
    <i class="fa fa-trash"></i>
  </button>

  <div class="float-end">
    <small
      *ngIf="fileDetails != null && app.view != 'oc-home'"
      class="attachmentDetails"
    >
      {{ fileDetails.uploadTime | date : "yyyy-MM-dd HH:mm" }}</small
    >
    <small
      *ngIf="fileDetails != null && app.view == 'oc-home'"
      class="attachmentDetails"
    >
      {{ app.OCHome.getTimeInfo(fileDetails.uploadTime) }}</small
    >
  </div>
</div>
<!-- input for the add new tag -->
<div class="col-md-12">
  <div class="attachment-new-tag" *ngIf="showTagField">
    <div class="col-sm-9">
      <app-field
        [model]="tagModel"
        [field]="app.fieldId.attachment.tag"
      ></app-field>
    </div>
    <div>
      <button class="btn" (click)="addTag()" [disabled]="isDisabled()">
        <i class="fa fa-check"> </i>
      </button>
      <button class="btn" (click)="showTagField = false">
        <i class="fa fa-times"></i>
      </button>
    </div>
  </div>
</div>
