import { ComponentSettings, FieldTypes, empty } from "../settings/settings";

const field = {
  session: {
    type: FieldTypes.data,
    text: {
      de: "Session",
      en: "session",
    },
  },
  time: {
    type: FieldTypes.timestamp,
    text: {
      de: "Zeit",
      en: "Time",
    },
  },
  timeISO: {
    type: FieldTypes.value,
    text: {
      de: "Zeit (ISO)",
      en: "Zeit (ISO)",
    },
  },
  element: {
    type: FieldTypes.value,
    text: {
      de: "Element",
      en: "Element",
    },
  },
  action: {
    type: FieldTypes.value,
    text: {
      de: "Aktion",
      en: "Action",
    },
  },
  error: {
    type: FieldTypes.data,
    text: {
      de: "Fehler",
      en: "Error",
    },
  },
  doc: {
    type: FieldTypes.data,
    text: {
      de: "Dokument",
      en: "Document",
    },
  },
  app: {
    type: FieldTypes.data,
    text: {
      de: "App",
      en: "App",
    },
  },
};

const settings = {
  permission: {},
  text: {
    error: { de: "Fehlermeldung", en: "Error Message" },
    errors: { de: "Fehlermeldungen", en: "Error Messages" },
  },
  url: {},
  field,
  list: {
    columns: empty,
  },
};

export const error: ComponentSettings<typeof settings> = settings;
