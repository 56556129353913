import { AppType, APP_TYPE } from "../app.type";
import { LeftNavServiceType } from "./left-nav.service.type";
import { Inject, Injectable } from "@angular/core";
import { TRANSPORT_CLIENT } from "../customers/customers.service";
import { Customer } from "../../../../shared/types/customers";
import { StringUtils } from "../../../../shared/utils/string.utils";

@Injectable()
export class LeftNavService implements LeftNavServiceType {
  constructor(@Inject(APP_TYPE) private app: AppType) {}

  get selectedBox() {
    return this.app.state.selectedBox;
  }

  set selectedBox(selectedBox: string | null) {
    this.app.state.next({ selectedBox });
  }

  async deleteImage(docType: string, file: string) {
    let fileLink = file;
    switch (docType) {
      case "manufacturer":
        if (
          StringUtils.isNullOrEmpty(
            this.app.manufacturer.currentManufacturer["manufacturer.image"]
          )
        ) {
          await this.app.file.delete(fileLink);
        } else {
          /* if the current manufacturer contains image from SE **/
          this.app.manufacturer.currentManufacturer["manufacturer.image"] = "";
          await this.app.manufacturer.save();
        }

        this.app.manufacturer.getImageExternal();
        this.app.manufacturer.hasDeleteImagePermission();
        break;
      case "part":
        await this.app.file.delete(fileLink);
        this.app.part.getImageExternal();
        // this.app.part.hasDeleteImagePermission();
        break;
      // OC Post
      case "ocPost":
        await this.app.file.delete(fileLink);
        this.app.OCPost.getImageExternal();
        break;
      case "thread":
        if (
          StringUtils.isNullOrEmpty(
            this.app.thread.thread["thread.externalImageSource"]
          )
        ) {
          await this.app.file.delete(fileLink);
        } else {
          this.app.thread.thread["thread.externalImageSource"] = "";
          await this.app.thread.save(this.app.thread.thread);
        }

        this.app.thread.getImageExternal();
        this.app.thread.hasDeleteImagePermission();
    }
  }

  async selectedBoxInit(box: string) {
    if (
      this.app.customers.expectCurrent === Customer.OMP &&
      box === this.app.listId.thread.impact
    ) {
      await this.app.applicationArea.getApplicationArea();
    }

    if (this.app.customers.getCustomerType() === TRANSPORT_CLIENT) {
      switch (box) {
        case this.app.listId.thread.materialSection:
          await this.app.commodityGroupResponsible.getCommodityIds();
          break;
        case this.app.listId.thread.involved:
          await this.app.dinCodeResponsible.getDinCodeDocuments();
          break;
        case this.app.listId.thread.impacts:
          await this.app.vehicleResponsible.getVehicleDocuments();
          break;
        case this.app.listId.thread.impact:
          await this.app.thread.getCasesByPartNumbers();
          break;
        case this.app.listId.thread.layer:
          await this.app.commodityGroupResponsible.getCommodityIds();
          await this.app.person.getPersons();
          break;
      }
    }
  }

  // all page components are in a container-fluid
  // in order to be displyed correctly "container-fluid" class styles are reseted in the next function
  // resetContainer() {
  //   // let container = document.getElementsByClassName(
  //   //   "container-fluid"
  //   // )[0] as HTMLElement;
  //   // container.classList.add("default-container-fluid");

  //   //remove the extra padding form the top of the footer
  //   let appContent = document.getElementsByClassName(
  //     "app-content"
  //   )[0] as HTMLElement;
  //   let footer = document.getElementsByClassName(
  //     "app-footer"
  //   )[0] as HTMLElement;

  //   // let footerHeight = footer.offsetHeight.toString();
  //   // appContent.style.paddingBottom = footerHeight + "px";
  //   this.app.field.inputSubject.next(true);
  // }
}
