<app-modal
  #deletePcnModal
  [modalMessage]="app.text.pcn.deleteQuestion"
  [modalTitle]="app.text.pcn.modalTitle"
  (ok)="app.table.updateDocs(); app.routing.navigateMail('thread')"
>
</app-modal>
<div class="row no-padding">
  <div class="col-12 no-padding">
    <div class="d-flex flex-column sub-title">
      <div class="d-flex control-bar">
        <button
          class="btn btn-control me-auto p-2"
          (click)="
            app.pcn.id = null;
            app.table.selected.clear();
            app.routing.navigateMail(app.mail.tab)
          "
          [title]="app.text.pcn.back"
        >
          <i class="far fa-arrow-left"></i> &nbsp;
          {{ app.text.pcn.backToNotif }}
        </button>
        <app-next
          class="pull-left"
          [current]="pcnDocument['pcn._id']"
          [array]="ids"
          (navigate)="app.routing.navigatePcn($event)"
        ></app-next>
      </div>
      <div class="pt-3 px-3 d-flex">
        <app-mail></app-mail>
      </div>
      <div class="d-flex pcn-controls px-3">
        <div class="me-auto p-2 heading">
          {{ app.getText(pcnDocument["pcn.title"]) }}
        </div>
        <button
          class="btn btn-sm m-1"
          [title]="app.text.pcn.delete"
          (click)="app.pcn.prepareDelete(pcnDocument); deletePcnModal.open()"
        >
          <i class="fa fa-trash"></i> &nbsp; {{ app.text.pcn.delete }}
        </button>
        <button
          class="btn btn-sm btn-default-red my-1"
          [title]="app.text.pcn.save"
          (click)="app.routing.navigateNewThread()"
        >
          <i class="fas fa-folder-plus pointer"> </i> &nbsp;
          {{ app.text.pcn.save }}
        </button>
      </div>
    </div>
  </div>

  <div class="container-fluid">
    <div class="row">
      <div class="col-sm-12 col-md-12 col-lg-9 col-xl-9">
        <div class="p-0">
          <app-left-nav
            [listBoxes]="app.list.pcn.boxesLeft"
            [showImage]="false"
          >
          </app-left-nav>
        </div>
        <div class="mb-3" *ngIf="pcnDocument != null">
          <app-box
            [box]="app.leftNav.selectedBox"
            [model]="pcnDocument"
            [unlock]="true"
            [showHeader]="false"
          >
          </app-box>
        </div>
      </div>

      <div class="col-sm-12 col-md-12 col-lg-3 col-xl-3">
        <div class="card box-file my-3">
          <div class="card-header d-flex justify-content-between">
            <span>{{ app.text.pcn.files }}</span>
            <i class="fal fa-file-alt icon-orange"></i>
          </div>
          <div *ngIf="pcnDocument['pcn._attachments'] != null">
            <app-attachments
              [id]="pcnDocument['pcn._id']"
              type="pcn"
              [editPermission]="false"
              [files]="pcnDocument['pcn._attachments']"
            >
            </app-attachments>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
