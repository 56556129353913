import { Settings } from "../components";
import { _ComponentSettings } from "../settings/settings";
import { Language } from "../settings/settings";

export type TextProxy = {
  [name in keyof Settings]: ComponentTextProxy<Settings[name]["text"]>;
};

export type ComponentTextProxy<T> = {
  [name in keyof T]: string;
};

export function getTextProxy(settings: Settings, language: Language) {
  const proxy: any = {};
  Object.keys(settings).forEach((name) => {
    Object.defineProperty(proxy, name, {
      get: () => {
        const component: _ComponentSettings = (<any>settings)[name];
        return getElementTextProxy(component, language);
      },
    });
  });

  return <TextProxy>proxy;
}

function getElementTextProxy<T extends _ComponentSettings>(
  component: T,
  language: Language
) {
  const proxy: any = {};
  const { text } = component;
  Object.keys(text).forEach((name) => {
    Object.defineProperty(proxy, name, {
      get: () => text[name][language],
    });
  });
  return <ComponentTextProxy<T>>proxy;
}
