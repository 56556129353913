import { Injectable, Optional, SkipSelf } from "@angular/core";
import { ISpinnerState, SpinnerServiceType } from "./spinner.service.type";
import { NgxSpinnerService } from "ngx-spinner";
import { BehaviorSubject, Observable } from "rxjs";

@Injectable()
export class SpinnerService implements SpinnerServiceType {
  public spinnerState: Observable<ISpinnerState>;

  private spinnerSubject = new BehaviorSubject<ISpinnerState>({ show: false });

  constructor(
    @Optional() @SkipSelf() prior: SpinnerService,
    private spinner: NgxSpinnerService
  ) {
    this.spinnerState = this.spinnerSubject.asObservable();

    if (prior) {
      return prior;
    }
  }

  public showSpinner(): void {
    this.spinnerSubject.next({ show: true } as ISpinnerState);
    this.spinner.show();
  }

  public hideSpinner(): void {
    setTimeout(() => {
      this.spinnerSubject.next({ show: false } as ISpinnerState);
      this.spinner.hide();
    }, 500);
  }
}
