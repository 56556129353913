import {
  Component,
  Type,
  PipeTransform,
  Pipe,
  ViewChild,
  OnInit,
  Input,
  OnDestroy,
} from "@angular/core";
import { getApp } from "../app";
import { HistoryDoc } from "./history";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";

@Pipe({
  name: "bold",
})
export class BoldPipe implements PipeTransform {
  transform(textValue: string, subTextValue: string): string {
    return textValue.replace(
      new RegExp(`(${subTextValue})`, "gi"),
      "<b>$1</b>"
    );
  }
}

@Component({
  selector: "app-history",
  templateUrl: "./history.component.html",
  styleUrls: ["./history.component.scss"],
})
export class HistoryComponent implements OnInit {
  app = getApp(async (app) => {
    this.app = app;
  });
  @ViewChild("content", { static: false }) content: any;
  @Input() historyModalTitle: string = "";
  historyDocs: HistoryDoc[] = [];

  constructor(private modalService: NgbModal) {}

  async ngOnInit() {}

  async open() {
    try {
      await this.app.history.getHistory();

      this.modalService.open(this.content, {
        windowClass: "xlModal",
      });
    } catch (err) {
      // NOP
    }
  }

  closeModal() {
    this.modalService.dismissAll();
  }
}
