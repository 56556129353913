import { Responsibles } from "../models/responsibles";

// hardcoded arrays ONLY FOR THE DB for the Rollen and Prozessschritzuweisung file
export function getVehicleResponsibleRoles(omfStatus: string) {
  const roles = new Set<keyof Responsibles>();
  const data: VehicleStatusResponsible[] = [
    {
      name: "bearbeiter",
      status: ["10", "14", "20", "40", "60", "80", "90b", "110"],
    },
    {
      name: "komponentenManager",
      status: ["12"],
    },
    {
      name: "bavFv",
      status: ["30", "70", "90", "100"],
    },
    {
      name: "mawiFvVertreter",
      status: ["50", "70", "90a", "100"],
    },
    {
      name: "bavFvVertreter",
      status: ["30", "70", "90", "100"],
    },
    {
      name: "mawiFv",
      status: ["50", "70", "90a", "100"],
    },
    {
      name: "bavRegio",
      status: ["30", "70", "90", "100"],
    },
    {
      name: "bavRegioVertreter",
      status: ["30", "70", "90", "100"],
    },
    {
      name: "mawiRegio",
      status: ["50", "70", "90a", "100"],
    },
    {
      name: "mawiRegioVertreter",
      status: ["50", "70", "90a", "100"],
    },
    {
      name: "bavCargo",
      status: ["30", "70", "90", "100"],
    },
    {
      name: "bavCargoVertreter",
      status: ["30", "70", "90", "100"],
    },
    {
      name: "mawiCargo",
      status: ["50", "70", "90a", "100"],
    },
    {
      name: "mawiCargoVertreter",
      status: ["50", "70", "90a", "100"],
    },
    {
      name: "bavFzi",
      status: ["30", "70", "90", "100"],
    },
    {
      name: "bavFziVertreter",
      status: ["30", "70", "90", "100"],
    },
    {
      name: "mawiFzi",
      status: ["50", "70", "90a", "100"],
    },
    {
      name: "mawiFziVertreter",
      status: ["50", "70", "90a", "100"],
    },
    {
      name: "bavNetz",
      status: ["30", "70", "90", "100"],
    },
    {
      name: "bavNetzVertreter",
      status: ["30", "70", "90", "100"],
    },
    {
      name: "mawiNetz",
      status: ["50", "70", "90a", "100"],
    },
    {
      name: "mawiNetzVertreter",
      status: ["50", "70", "90a", "100"],
    },
  ];
  for (const { name, status } of data) {
    if (new Set(status).has(omfStatus)) {
      roles.add(name);
    }
  }
  return roles;
}

export class VehicleStatusResponsible {
  name: keyof Responsibles = "bearbeiter";
  status: string[] = [];
}
