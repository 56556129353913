import { Inject, Injectable } from "@angular/core";
import { AppType, APP_TYPE } from "../../../src/app/app.type";
import { Pcn } from "../../../../shared/models/pcn";
import { getPcns, getUnreadPcns } from "../api.service";
import { PcnsServiceType } from "./pcns.service.type";

@Injectable()
export class PcnsService implements PcnsServiceType {
  constructor(@Inject(APP_TYPE) private app: AppType) {}
  pcns: Pcn[] = [];
  cleanPcns: Pcn[] = [];
  unreadPcns: Pcn[] = [];
  pcnId: string = "";

  async getAllPcns(): Promise<void> {
    this.pcns = await getPcns(this.app.customers.expectCurrent);
    this.cleanPcns = this.pcns;
    this.unreadPcns = this.pcns.filter(
      (pcn) => pcn.seen === false || pcn.seen === undefined
    );

    // this.updateCounter(this.unreadPcns.length);

    this.pcns.forEach((pcn) => {
      if (pcn.changeClasses) {
        pcn.criticality = this.app.mail.setCriticalityBasedOnType(pcn);
      }
    });
  }

  async getUnreadPcns(): Promise<void> {
    if (this.app.user == null) {
      return;
    }

    const unreadPcns = await getUnreadPcns(this.app.customers.expectCurrent);

    this.updateCounter(unreadPcns.numberOfPcns);
  }

  private updateCounter(count: number): void {
    this.app.state.next({ numberOfUnreadPcns: count });
  }
}
