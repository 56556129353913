export class TotalCostDoc {
  [key: string]: any;
  totals: TotalCost[] = [];
}

export class TotalCost {
  name: string = "";
  series: Series[] = [];
}

class Series {
  name: string = "";
  value?: number = undefined;
}
