import { ComponentSettings, FieldTypes } from "../settings/settings";

const field = {
  SEName: {
    type: FieldTypes.value,
    text: {
      de: "SE Name",
      en: "SE Name",
    },
  },
  names: {
    type: FieldTypes.options,
    text: {
      de: "Names",
      en: "Names",
    },
  },
};

const settings = {
  permission: {},
  text: {},
  url: {},
  field,
  list: {},
};

export const manufacturerName: ComponentSettings<typeof settings> = settings;
