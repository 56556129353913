import { ComponentSettings } from "../settings/settings";

const settings = {
  permission: {},
  text: {
    multiselect: {
      de: "Mehrfachauswahl",
      en: "Multiselect",
      nl: "Multiselect",
    },
    required: { de: "Pflichtfeld", en: "Required", nl: "Verplicht" },
    fillThreadFieldsAutomatically: {
      de: "Folgende Daten werden automatisch ausgefüllt: ",
      en: "The following data will be filled in automatically: ",
    },
    requiredIcon: {
      de: "Bitte füllen Sie alle erforderlichen Felder aus",
      en: "Please complete all the required fields",
      nl: "Vul alle verplichte velden in",
    },
    emailFormat: {
      de: "Email format is not valid",
      en: "Email format is not valid",
      nl: "Email format is not valid",
    },
    whereUsedPartNumber: {
      de: "Diese CPN wird in den folgenden Baugruppen verwendet: ",
      en: "This CPN is used in the following assemblies: ",
      nl: "Deze CPN wordt gebruikt in de volgende assemblages: ",
    },
  },
  list: {},
  url: {},
  field: {},
};

export const field: ComponentSettings<typeof settings> = settings;
