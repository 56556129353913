import { Component, Input, Output, EventEmitter } from "@angular/core";
import { getApp } from "../app";
@Component({
  selector: "app-table",
  templateUrl: "./table.component.html",
  styleUrls: ["./table.component.scss"],
})
export class TableComponent {
  app = getApp((app) => {
    this.app = app;
  });
  @Input() columns: string[] = [];
  @Input() ids: string[] = [];
  @Input() showId: boolean = false;
  @Input() showCheck: boolean = true;
  @Input() showCount: boolean = false;
  @Input() showPaginator: boolean = true;
  @Output() selectRow = new EventEmitter<string>();
  selected: Set<string> = new Set();
  constructor() {}
  selectAll() {
    this.ids.forEach((id) => this.selected.add(id));
  }
  toggleSelect(id: any) {
    const { selected } = this;
    if (selected.has(id)) {
      selected.delete(id);
    } else {
      selected.add(id);
    }
  }
  isAllSelected() {
    return new Set(this.ids).size === this.selected.size;
  }
  unselectAll() {
    this.selected = new Set();
  }
}
