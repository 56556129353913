import { Credits } from "./credits";
import { Responsibles } from "./responsibles";

export interface ImpactRelative extends Impact {
  index?: string;
  children?: string[];
  parent?: string[];
}

export interface ImpactElement {
  count: number;
  impact: Impact;
}
export class Impact extends Credits {
  [key: string]: any;
  type: string = "impact";
  impactType?: string = "";
  omfVehicleName: string = "";
  omfVehicleFleet?: string = "";
  omfVehicleClass?: string = "";
  omfCommodityRespName?: string = "";
  omfVehicleRespDep?: string = "";
  omfVehicleRespEmail?: string = "";
  omfVehicleCnt?: number | null = null;
  omfNumber: string = "";
  artNumber?: string = "";
  level?: string = "";
  vehicleGroup?: string = "";
  impacts?: ImpactElement[] = [];
  productGroup?: string = "";
  actions?: string = "";
  responsibles?: Responsibles = {};
  designType?: string = "";
  sapStatus?: string = "";
  stockRange?: string = "";
  project?: string = "";
  projectNumber?: string = "";
  projectResponsible?: string = "";
  obsolescenceManagement?: string = "";
  verwa?: string = "";
}
