<ng-template #content let-modal>
  <div class="modal-header">
    <div>
      <span *ngIf="step === 'openItemDetail'" class="modal-title">{{
        modalTitle
      }}</span>
      <span *ngIf="step === 'discardItem'" class="modal-title">{{
        app.text.modal.modalTitle
      }}</span>
    </div>
    <button class="btn pull-right" (click)="cleanData(); discardChanges()">
      <i class="fa fa-times"></i>
    </button>
  </div>
  <div class="modal-body">
    <div *ngIf="type === 'discard'">
      <span *ngIf="step === 'discardItem'">
        {{ app.text.modal.discard }}
      </span>
      <div *ngIf="showItemFields && step === 'openItemDetail'">
        <app-field
          *ngFor="let field of fields; index as index"
          [model]="modelDoc"
          [field]="field"
          [index]="index"
        ></app-field>
      </div>
    </div>
  </div>
  <div *ngIf="showButtons" class="modal-footer pt-0">
    <button
      class="btn btn-ok text-center m-1"
      (click)="modal.close('OK'); app.pcnGenerator.createItem(modelDoc)"
      [disabled]="
        app.pcnGenerator.hasInvalidFieldsByBox(app.leftNav.selectedBox)
      "
      type="submit"
    >
      {{ app.text.app.save }}
    </button>
    <!-- <button
      class="btn btn-ok text-center m-1 mx-5"
      (click)="modal.close('OK'); app.pcnGenerator.createItem(modelDoc)"
      *ngIf="step === 'openItemDetail'"
      [disabled]="
        app.pcnGenerator.hasInvalidFieldsByBox(app.leftNav.selectedBox)
      "
    >
      {{ app.text.modal.ok }}
    </button>
    <button
      class="btn btn-ok text-center m-1"
      (click)="goToNext(step, 'save')"
      *ngIf="step === 'discardItem'"
    >
      {{ app.text.modal.ok }}
    </button>
    <button
      class="btn btn-cancel text-center m-1"
      (click)="goToNext(step, 'dismiss')"
    >
      {{ app.text.modal.cancel }}
    </button> -->
  </div>
</ng-template>
