import { Component, Input } from "@angular/core";
import { getApp } from "../app";
import { Type } from "../../../../shared/components";
import { FieldTypes } from "../../../../shared/settings/settings";

@Component({
  selector: "app-filter",
  templateUrl: "./filter-list.component.html",
  styleUrls: ["./filter-list.component.scss"],
})
export class FilterListComponent {
  @Input() types: Type[] = [];
  searchText: string = "";

  app = getApp((app) => {
    this.app = app;
  });
  fieldList: string[] = [];
  boxes: string[] = [];
  text: string = "";
  fieldType: string = "";
  constructor() {}

  ngOnInit() {
    this.app.state.next({ partNumbers: [] });

    this.getFieldsList();

    this.app.filterList.getTypeaheadOptionsForFilters();
    this.fieldList.forEach((fieldId: string) => {
      this.app.field.changeFieldSettings(fieldId, FieldTypes.typeahead, false);
    });
  }

  getFieldsList() {
    switch (this.app.type) {
      case "thread":
        this.fieldList = this.app.list.thread.fieldsOnFilter;
        this.text = this.app.text.thread.thread;
        this.boxes = this.app.list.thread.filterBoxesHome;
        break;
      case "post":
        this.fieldList = this.app.list.post.featureFields;
        this.text = this.app.text.post.post;
        break;
      case "user":
        this.text = this.app.text.user.user;
        this.boxes = this.app.list.user.filterBoxesUser;
        this.fieldList = this.app.list.user.fieldsOnFilter;
        break;
      case "vehicleResponsible":
        this.text = this.app.text.vehicleResponsible.vehicleResponsible;
        this.boxes = this.app.list.vehicleResponsible.filterBoxesVehicle;
        this.fieldList = this.app.list.vehicleResponsible.fieldsOnFilter;
        break;
      case "personProfile":
        this.text = this.app.text.profile.profile;
        this.boxes = this.app.list.profile.boxesOnFilter;
        this.fieldList = this.app.list.profile.fieldsOnFilter;
    }
  }

  async setField(field: string) {
    this.app.filterList.field = field;
    this.app.filterList.open = true;
    this.app.model[field] = "";
    if (
      (field === "internalItem.partNumber" ||
        field === "internalItem.crtNumber") &&
      this.app.internalItems.allItems.length === 0
    ) {
      this.app.spinner.showSpinner();
      await this.app.internalItems.getAllInternalItems();
      this.app.spinner.hideSpinner();
    } else {
      return;
    }
  }
  getHeaderText(type: string) {
    type = type.split(".")[0];
    switch (type) {
      case "impact":
        return this.app.text.thread.impacts;
      case "train":
        return this.app.text.thread.trainseries;
      case "internalItem":
        return this.app.text.thread.internalItems;
      default:
        return (<any>this.app.text)[type][type];
    }
  }

  isFilterSelected(field?: string) {
    if (this.app.state.filterByPage.length === 0) {
      return false;
    }
    if (this.app.filterList.getValues(field).length > 0) {
      return true;
    } else {
      return false;
    }
  }

  ngOnDestroy(): void {
    this.fieldList.forEach((fieldId: string) => {
      this.app.field.resetFieldType(fieldId);
    });
  }
}
