<span *ngIf="app.type !== 'thread'">
  <button
    *ngIf="app.table.sortDirection === 1"
    class="btn"
    (click)="app.table.sortDirection = -1"
  >
    <i class="fa fa-arrow-up"></i>
  </button>

  <button
    *ngIf="app.table.sortDirection === -1"
    class="btn"
    (click)="app.table.sortDirection = 1"
  >
    <i class="fa fa-arrow-down"></i>
  </button>
</span>

<span class="form-group">
  <select
    #sortSelect
    [value]="app.table.sortField"
    class="sort-select form-control m-0 p-0"
    (change)="app.table.sortField = sortSelect.value"
  >
    <option *ngFor="let column of columns" [value]="column">
      {{ app.field.getLabel(column) }} {{ sortSelect.value }}
    </option>
  </select>
</span>
