export class ManufacturerName {
  SEName: string = "";
  names: string[] = [];
}

export class ManufacturerNames {
  _id?: string;
  _rev?: string;
  type?: string = "manufacturerNames";
  names: ManufacturerName[] = [];
}
