import { AbstractGraph } from "../abstract-graph";
import { AppType } from "../../app.type";
import { GraphConfig } from "../graph.type";
import { StatusGraphData } from "./status-graph-data";

export class StatusGraph extends AbstractGraph<StatusGraphData> {
  history: any[] = [];
  get config(): GraphConfig {
    return {
      type: "bar-vertical-2d",
      showXAxis: true,
      showYAxis: true,
      showLegend: true,
      xAxisLabel: null,
      yAxisLabel: null,
      colorScheme: {
        domain: ["#4faf5d", "#e51616", "#1833cc", "#d69637"],
      },
    };
  }

  constructor(protected app: AppType) {
    super(app);
  }

  protected getSortFunction() {
    return (a: StatusGraphData, b: StatusGraphData) =>
      a.getTime() - b.getTime();
  }

  protected async getMap(map: Map<Date, StatusGraphData>) {
    let date: Date;
    for (date of this.generateDates()) {
      map.set(date, new StatusGraphData(date, this.app));
    }
    const threads = this.app.graphs.allThreads;
    const statusChartHistory = this.app.graphs.statusHistory;

    map.forEach((data) => {
      data.addThreads(threads, statusChartHistory);
    });
  }
}
