<!-- <div class="controls" *ngIf="app.permission.train.canAddNewTrain">
  <button
    class="btn"
    [ngbTooltip]="app.text.train.add"
    (click)="app.routing.navigateTrainsList(app.thread.thread['thread._id'])"
  >
    <i class="fa fa-plus"></i>
  </button>
</div> -->
<div class="row justify-content-between">
  <div
    class="col-sm-7 col-md-7 col-lg-7 col-xl-7 ps-1 ms-3"
    *ngIf="app.permission.train.canAddTrainToCase"
  >
    <app-field
      [field]="app.fieldId.train.trainName"
      [model]="app.model"
      (selected)="addTrain(app.model['train.trainName'])"
      [labelInside]="true"
      [orangeField]="true"
      [showLabel]="false"
    ></app-field>
  </div>
  <!-- Trainseries controls -->
  <div
    *ngIf="
      app.leftNav.selectedBox === 'thread.trainseries' &&
      app.permission.train.canAddNewTrain
    "
    class="col-sm-4 col-md-4 col-lg-4 col-xl-4 mx-2 ps-1 pt-1"
  >
    <button
      class="btn btn-sm btn-default-orange pull-right"
      (click)="app.routing.navigateTrainsList(app.thread.thread['thread._id'])"
    >
      <i class="fa fa-plus"></i>&nbsp;&nbsp; {{ app.text.app.add }}
    </button>
  </div>
</div>
<div class="tableFixHead">
  <table class="table table-striped">
    <thead>
      <tr>
        <th
          class="p-1 text-center"
          *ngFor="let column of app.list.train.columns"
        >
          {{ app.field.getLabel(column) }}
        </th>
      </tr>
    </thead>
    <tbody>
      <tr *ngFor="let train of impactedTrains">
        <td *ngFor="let column of app.list.train.columns" class="text-center">
          <span *ngIf="column !== 'impact.actions'">
            {{ train[column.split(".")[1]] }}
          </span>
          <span
            class="pointer"
            *ngIf="
              column === 'impact.actions' &&
              app.permission.train.canAddTrainToCase
            "
            (click)="deleteTrainFromCase(train)"
          >
            <i class="fa fa-trash"></i>
          </span>
        </td>
      </tr>
      <tr *ngIf="impactedTrains.length === 0">
        <td colspan="99" class="text-center">
          {{ app.text.matchingManufacturer.noResults }}
        </td>
      </tr>
    </tbody>
  </table>
  <div class="col-md-12">
    <div
      *ngIf="showTrainError && app.model['train.trainName'] != ''"
      class="error-notification errorMessage text-center"
    >
      <div
        class="close-btn pull-right pe-1"
        (click)="showTrainError = false; app.model['train.trainName'] = ''"
      >
        <i class="fa fa-times"></i>
      </div>
      {{ app.text.train.trainAlreadyInCase }}
    </div>
  </div>
</div>
