import {
  ChangeDetectorRef,
  Component,
  Input,
  OnDestroy,
  OnInit,
} from "@angular/core";
import { getApp } from "../app";
import { Type } from "../../../../shared/components";
import { Subscription } from "rxjs";
import {
  Attachment,
  AttachmentHistoryInfo,
} from "../../../../shared/models/attachment";
import { Customer } from "../../../../shared/types/customers";
import { BILD_IMAGE } from "../file/file.service";

@Component({
  selector: "app-attachments",
  templateUrl: "./attachments.component.html",
  styleUrls: ["./attachments.component.scss"],
})
export class AttachmentsComponent implements OnInit, OnDestroy {
  @Input() id: string = "";
  @Input() type: Type = "thread";
  @Input() editPermission = true;
  @Input() files: any = {} as Attachment;
  @Input() filesDetails: any = {} as AttachmentHistoryInfo;
  attachments: string[] = [];
  attachmentsSubscription: Subscription = new Subscription();
  attachmentsTagSubscription: Subscription = new Subscription();
  groupingByTagSubscription: Subscription = new Subscription();
  tagDeletedSubscription: Subscription = new Subscription();

  ocAttachmentsSubscription: Subscription = new Subscription();

  app = getApp((app) => {
    this.app = app;
  });
  tagsReady = false;

  constructor(private _cdRef: ChangeDetectorRef) {}

  ngOnInit() {
    this.attachmentsSubscription = this.app.file.attachmentsInfo.subscribe(
      (result: { attachedFiles: any; docId: string }) => {
        if (result.docId != null && result.docId === this.id) {
          if (result.attachedFiles && result.attachedFiles.length !== 0) {
            //used for updating the list of attachments after operation as delete/upload
            this.attachments = Object.keys(result.attachedFiles);
          } else {
            //used for updating the list after all attachments were deleted
            this.attachments = [];
          }
        } else if (this.files != undefined && this.files.length !== 0) {
          //get the list of attachments directly from the document (eg.: this.files = thread._attachments)
          this.attachments = Object.keys(this.files);
        } else {
          this.attachments = [];
        }
        if (this.app.customers.expectCurrent !== Customer.OC) {
          this.app.attachments.getAllAttachments();
        } else {
          this.app.attachments.getAllOCAttachments();
        }
      }
    );
    // TODO: Sub
    this.tagDeletedSubscription =
      this.app.attachment.deletedTagSubject.subscribe((value) => {
        if (value) {
          if (this.app.customers.expectCurrent !== Customer.OC) {
            this.app.attachments.getAllAttachments();
          }
        }
      });

    // get comments and solutions attachments list when accessing the Attachments section
    if (this.type === "thread") {
      this.app.attachments.getAllAttachments();

      this.attachmentsTagSubscription =
        this.app.attachments.caseTagsSubject.subscribe((value) => {
          if (value) {
            this.tagsReady = true;
            this.app.attachments.getAttachmentsByTag("allAttachments");
            this._cdRef.detectChanges();
            this.groupingByTagSubscription =
              this.app.attachments.groupingByTagSubject.subscribe((value) => {
                this.attachments = value;
              });
          }
        });
    }

    if (this.type === "ocPost") {
      this.ocAttachmentsSubscription =
        this.app.attachments.OCAttachmentsSubject.subscribe((value) => {
          if (value) {
            this.app.attachments.getAllOCAttachments();
            if (this.attachments.includes(BILD_IMAGE)) {
              this.app.attachments.updateBildImage.next(true);
            }
            this.app.attachments.updateOCPostAttachments.subscribe((value) => {
              this._cdRef.detectChanges();
              this.attachments = value;
            });
            if (this.files != undefined && this.files.length !== 0) {
              this.attachments = Object.keys(this.files);
            } else {
              this.attachments = [];
            }
          }
        });
    }

    if (this.type === "ocComment") {
      this.ocAttachmentsSubscription =
        this.app.attachments.OCAttachmentsSubject.subscribe((value) => {
          if (value != null) {
            if (this.files != undefined && this.files.length !== 0) {
              this.attachments = Object.keys(this.files);
            } else {
              this.attachments = [];
            }
          }
        });
    }
  }

  ngOnDestroy(): void {
    if (this.attachmentsSubscription) {
      this.attachmentsSubscription.unsubscribe();
    }
    if (this.attachmentsTagSubscription) {
      this.attachmentsTagSubscription.unsubscribe();
    }
    if (this.groupingByTagSubscription) {
      this.groupingByTagSubscription.unsubscribe();
    }
    if (this.tagDeletedSubscription) {
      this.tagDeletedSubscription.unsubscribe();
    }
    if (this.ocAttachmentsSubscription) {
      this.ocAttachmentsSubscription.unsubscribe();
    }
  }
}
