import { Settings, defaultSettings } from "../components";
import { _ComponentSettings } from "../settings/settings";

export type ListIdProxy = {
  [name in keyof Settings]: ComponentListIdProxy<Settings[name]["list"]>;
};

type ComponentListIdProxy<T> = {
  [name in keyof T]: string;
};

export function getListIdProxy() {
  const proxy: any = {};
  Object.keys(defaultSettings).forEach((name) => {
    Object.defineProperty(proxy, name, {
      get: () =>
        getComponentListIdProxy(defaultSettings[<keyof Settings>name], name),
    });
  });

  return <ListIdProxy>proxy;
}

function getComponentListIdProxy<T extends _ComponentSettings>(
  component: T,
  componentName: string
) {
  const proxy: any = {};
  const { list } = component;
  Object.keys(list).forEach((name) => {
    Object.defineProperty(proxy, name, {
      get: () => componentName + "." + name,
    });
  });
  return <ComponentListIdProxy<T>>proxy;
}
