import { ComponentSettings, empty } from "../settings/settings";

const settings = {
  permission: {
    changeStatus: false,
    changeStatusToClosed: false,
  },
  text: {
    modalTitle: {
      de: "Bestätigung",
      en: "Confirm",
      nl: "Bevestigen",
    },
    confirmNext: {
      de: "Bitte bestätigen Sie, dass Sie den Fall in den Status $var schalten möchten",
      en: "Please confirm to move the case to the state $var!",
      nl: "Bevestig dat het Issue naar de status $var kan.",
    },
    confirmPrevious: {
      de: "Bitte bestätigen Sie, dass Sie den Fall in den Status $var schalten möchten",
      en: "Please confirm to move the case to the state $var!",
      nl: "Bevestig dat het Issue naar de status $var kan.",
    },
    confirmFirst: {
      de: "Bitte bestätigen Sie, dass Sie den Fall in den Status $var schalten möchten",
      en: "Please confirm to move the case to the state $var!",
      nl: "Bevestig dat het Issue naar de status $var kan.",
    },
    responsibleMessage: {
      de: "Der WF-Schritt kann erst abgeschlossen werden, nachdem die Teilnehmer/Vertreter des WF-Schrittes ihr Feedback abgegeben haben. Verbleibende Verantwortliche: ",
      en: "The WF step can be completed only after WF step participants/representatives give their feedback. Responsibles left:",
    },
    responsible: {
      de: "Verantwortlich",
      en: "Responsible",
      nl: "Verantwoordelijke",
    },
    changeDueDate: {
      de: "Wählen Sie ein neues Fälligkeitsdatum",
      en: "Select a new Due Date",
    },
    previous: {
      de: "Vorheriger Status",
      en: "Previous Status",
      nl: "Vorige Status",
    },
    next: {
      de: "Nächster Status",
      en: "Next Status",
      nl: "Volgende Status",
    },
    omfStatus: {
      de: "Status",
      en: "Status",
      nl: "Status",
    },
    uncompletedTasksModal: {
      de: "Dieser Fall kann nicht geschlossen werden. Es gibt mindestens eine offene Aufgabe, die diesem Fall zugeordnet ist. Bitte lösen oder schließen Sie alle offenen Aufgaben, bevor Sie versuchen, den Fall zu schließen.",
      en: "This case cannot be closed. There is at least one open task associated with this case. Please resolve or close all open tasks before attempting to close the case.",
      nl: "Deze zaak kan niet worden gesloten. Er is minstens één openstaande taak toegewezen aan deze zaak. Los alle openstaande taken op of sluit ze af voordat u probeert de zaak te sluiten.",
    },
  },
  list: {
    steps: empty,
  },
  url: {},
  field: {},
};

export const stepper: ComponentSettings<typeof settings> = settings;
