import { Component, Input, OnDestroy, OnInit } from "@angular/core";
import { Subscription } from "rxjs";
import { doc2Model } from "../../app/api.service";
import { getApp } from "../../app/app";
import { SVHCItem } from "../../../../shared/models/manufacturer";
import { DocModel } from "../state/state";

@Component({
  selector: "app-svhc-items",
  templateUrl: "./SVHC-items.component.html",
  styleUrls: ["./SVHC-items.component.scss"],
})

/** Substances of Very High Concern */
export class SVHCItemsComponent implements OnInit, OnDestroy {
  @Input() columns: string[] = [];

  itemChangedSubscription: Subscription = new Subscription();

  model: DocModel = doc2Model("SVHCItems", {} as SVHCItem);

  /**used for adding a new item */
  newItem: boolean = false;

  app = getApp((app) => {
    this.app = app;
  });

  /**if the manual override is true, the user should be able to create/delete a substance */
  manualOverride =
    this.app.manufacturer.currentManufacturer[
      "manufacturer.obsolescenceStatusOverride"
    ];

  constructor() {}

  ngOnInit() {
    this.app.SVHCItems.substances = this.app.SVHCItems.getItemsNotEmpty();

    /** set a copy of the current manufacturer - before changes - used for history */
    this.app.SVHCItems.manufacturer = Object.assign(
      {},
      this.app.manufacturer.currentManufacturer
    );

    this.itemChangedSubscription = this.app.SVHCItems.itemSubject.subscribe(
      async (value) => {
        if (value && this.app.manufacturer.id != null) {
          /** if a new substance has been added, take the updated list of items from the manufacturer */

          this.app.SVHCItems.substances =
            this.app.manufacturer.currentManufacturer["manufacturer.svhcItems"];
          this.model = {};
          this.newItem = false;
        }
      }
    );
  }

  ngOnDestroy(): void {
    if (this.itemChangedSubscription) {
      this.itemChangedSubscription.unsubscribe();
    }
  }
}
