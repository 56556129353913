import { Component } from "@angular/core";
import { getApp } from "../app";
import { Router } from "@angular/router";
import { environment } from "../../environments/environment";

@Component({
  selector: "app-footer",
  templateUrl: "./footer.component.html",
  styleUrls: ["./footer.component.scss"],
})
export class FooterComponent {
  app = getApp((app) => {
    this.app = app;
  });
  year: number = 0;
  version: string = "";

  ngOnInit() {
    const date = new Date();
    this.year = date.getFullYear();
    this.version = environment.version;
  }

  constructor(private router: Router) {}
}
