<!-- <app-modal
  #deleteNotificationsModal
  [modalMessage]="app.text.table.deleteNotification"
  [modalTitle]="app.text.table.modalTitle"
  (ok)="delete()"
>
</app-modal> -->
<app-paginator
  *ngIf="showPaginator"
  [length]="ids.length"
  [index]="app.paginator.focus"
  (focus)="app.paginator.focus = $event"
></app-paginator>
<div (click)="$event.stopPropagation()">
  <div class="table-responsive">
    <table class="table table-striped">
      <thead>
        <tr>
          <!--th
            *ngIf="showCheck && checked.size === 0"
            class="p-1"
            (click)="selectAll()"
          >
            <span>{{ app.text.table.delete }}</span>
            <div [ngbTooltip]="app.text.table.selectAll">
              <i class="far fa-square"></i>
            </div>
          </th-->
          <th *ngIf="showCount" class="p-1 text-center">No.</th>
          <th class="p-1 text-center" *ngIf="showCheck">
            <span>{{ app.text.table.delete }}</span>
            <div
              [ngbTooltip]="app.text.table.selectAll"
              *ngIf="selected.size === 0"
              (click)="selectAll()"
            >
              <i class="far fa-square"></i>
            </div>
            <div
              [ngbTooltip]="app.text.table.unselectAll"
              *ngIf="selected.size > 0"
              (click)="unselectAll()"
            >
              <i class="fa fa-check-square"></i>
            </div>
            <div
              (click)="deleteNotificationsModal.open()"
              *ngIf="selected.size > 0"
            >
              <i class="fa fa-trash"></i>
            </div>
          </th>
          <th *ngIf="showId">
            {{ app.text.table.id }}
          </th>
          <th
            class="p-1 text-center"
            *ngFor="let column of columns"
            (click)="app.table.toggleSortField(column)"
          >
            {{ app.field.getLabel(column) }}
            <span *ngIf="column === app.table.sortField">
              <i
                *ngIf="app.table.sortDirection === 1"
                class="fa fa-chevron-up"
              ></i>
              <i
                *ngIf="app.table.sortDirection === -1"
                class="fa fa-chevron-down"
              ></i>
            </span>
          </th>
        </tr>
      </thead>
      <tbody>
        <tr
          *ngFor="
            let id of showPaginator ? app.paginator.getPage(ids) : ids;
            let i = index
          "
          [ngClass]="{ isSelected: app.isSelected(id) }"
        >
          <td *ngIf="showCount" class="p-1 text-center">{{ i + 1 }}</td>
          <td
            *ngIf="!selected.has(id) && showCheck"
            class="p-1 text-center"
            (click)="toggleSelect(id); $event.stopPropagation()"
          >
            <div [ngbTooltip]="app.text.table.select">
              <i class="far fa-square"></i>
            </div>
          </td>
          <td
            *ngIf="selected.has(id) && showCheck"
            class="p-1 text-center"
            (click)="toggleSelect(id); $event.stopPropagation()"
          >
            <div [ngbTooltip]="app.text.table.unselect">
              <i class="far fa-check-square"></i>
            </div>
          </td>
          <td *ngIf="showId">
            {{ id }}
          </td>
          <ng-container *ngFor="let column of columns">
            <td
              *ngIf="column.split('.')[1] == 'seen'"
              class="pointer text-center"
              (click)="markAsSeenOrUnseen(id, column)"
            >
              <app-cell [field]="column" [doc]="id"></app-cell>
            </td>
            <td
              *ngIf="column.split('.')[1] !== 'seen'"
              class="p-1 text-center"
              (click)="selectRow.next(id)"
            >
              <app-cell [field]="column" [doc]="id"></app-cell>
            </td>
          </ng-container>
        </tr>
        <tr *ngIf="ids.length === 0" colspan="99" class="text-center">
          <td>
            {{ app.text.app.noResults }}
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</div>
<app-paginator
  *ngIf="showPaginator"
  [length]="ids.length"
  [index]="app.paginator.focus"
  (focus)="app.paginator.focus = $event"
></app-paginator>
