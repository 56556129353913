<app-create-part-modal
  #createPart
  [modalMessage]="app.text.part.discard"
  [modalTitle]="app.text.part.modalTitle"
  (ok)="createPart()"
></app-create-part-modal>
<div class="row">
  <div
    class="col-sm-9 col-md-10 col-xl-10"
    [ngClass]="{ 'col-sm-12 col-md-12 col-xl-12': filterOpen === false }"
  >
    <div *ngIf="app.RM.displayRMHeader">
      <!-- First line -->
      <div class="row pt-3" *ngIf="!app.RM.fromRMSearch">
        <!-- Left side -->
        <!-- Switch view buttons -->
        <div class="col-lg-6 col-lg-6 col-sm-6">
          <div class="btn-group" role="group">
            <button
              type="button"
              class="btn btn-toggle btn-rounded btn-sm mx-0"
              (click)="app.RM.navigateRMComponents('tree')"
              [ngClass]="{
                'btn-default-blue': app.RM.mode === 'tree'
              }"
            >
              <i class="fas fa-sitemap"></i>&nbsp;&nbsp;{{
                app.text.RM.treeView
              }}
            </button>
            <button
              type="button"
              class="btn btn-toggle btn-sm me-0"
              (click)="
                app.RM.navigateRMComponents('manufacturers/all-manufacturers')
              "
              [ngClass]="{ 'btn-default-blue': app.RM.isManufacturer }"
            >
              <i class="fas fa-industry-alt"></i>&nbsp;&nbsp;{{
                app.text.RM.manufacturerView
              }}
            </button>
            <button
              type="button"
              class="btn btn-toggle btn-rounded btn-sm me-0"
              (click)="app.RM.navigateRMComponents('parts/all-parts')"
              [ngClass]="{ 'btn-default-blue': app.RM.isPart }"
            >
              <i class="fas fa-box"></i>&nbsp;&nbsp;{{ app.text.RM.partView }}
            </button>
          </div>
        </div>

        <!-- Right side -->
        <div class="col-lg-6 col-lg-6 col-sm-6">
          <div class="d-flex justify-content-end">
            <!-- Export Buttons -->
            <div *ngIf="app.RM.mode !== 'tree'">
              <button
                class="btn btn-sm btn-clean"
                *ngIf="app.RM.isPart && app.permission.part.addItem"
                (click)="
                  setFieldTypeOnCreatePart();
                  createPart.open();
                  app.table.selected.clear()
                "
              >
                <i class="fas fa-plus"></i>&nbsp;
                {{ app.text.part.createPart }}
              </button>
              <button
                class="btn btn-sm btn-clean"
                *ngIf="app.RM.isManufacturer && app.permission.RM.canExport"
                (click)="
                  app.RM.export('manufacturer'); app.table.selected.clear()
                "
              >
                <i class="fa fa-download"></i>&nbsp;
                {{ app.text.manufacturer.export }}
              </button>
              <button
                class="btn btn-sm btn-clean"
                *ngIf="app.RM.isPart && app.permission.RM.canExport"
                (click)="app.RM.export('part'); app.table.selected.clear()"
              >
                <i class="fa fa-download"></i>&nbsp;
                {{ app.text.manufacturer.export }}
              </button>
              <button
                class="btn btn-sm btn-clean"
                *ngIf="app.RM.isManufacturer"
                (click)="
                  app.RM.export('manufacturer', 'substances');
                  app.table.selected.clear()
                "
              >
                <i class="fas fa-flask"></i>&nbsp;
                {{ app.text.RM.exportReach }}
              </button>
            </div>
            <!-- Filters -->
            <div *ngIf="app.type === 'part' && app.RM.mode === 'tree'">
              <button
                class="btn btn-sm btn-default-blue"
                data-toggle="collapse"
                data-target="#collapseFilter"
                (click)="app.RM.filterOpen = !app.RM.filterOpen"
                [ngClass]="{ btnRMFilterOpen: app.RM.filterOpen }"
              >
                <i class="fas fa-filter"></i>
                {{ app.text.home.filters }}
                <i *ngIf="!app.RM.filterOpen" class="fal fa-angle-right"></i>
                <i *ngIf="app.RM.filterOpen" class="far fa-times ms-1"></i>
              </button>
            </div>
          </div>
        </div>
      </div>

      <!-- Second line -->
      <div class="row bottom-line py-3">
        <!-- Manufactures buttons -->
        <div *ngIf="app.RM.isManufacturer" class="col-12">
          <button
            class="btn btn-sm"
            (click)="
              app.RM.navigateRMComponents('manufacturers/all-manufacturers')
            "
            [ngClass]="{
              'btn-default-blue': app.RM.mode === 'all-manufacturers'
            }"
          >
            <i class="fal fa-table"></i>&nbsp;&nbsp;{{ app.text.RM.table }}
          </button>
          <button
            class="btn btn-sm"
            (click)="app.RM.navigateRMComponents('manufacturers/matched')"
            [ngClass]="{
              'btn-default-blue': app.RM.mode === 'matched'
            }"
          >
            <i class="far fa-link"></i>&nbsp;&nbsp;{{ app.text.RM.matched }}
          </button>
          <button
            class="btn btn-sm"
            (click)="app.RM.navigateRMComponents('manufacturers/unmatched')"
            [ngClass]="{
              'btn-default-blue': app.RM.mode === 'unmatched'
            }"
          >
            <i class="far fa-unlink"></i>&nbsp;&nbsp;{{ app.text.RM.unmatched }}
          </button>
          <button
            class="btn btn-sm"
            (click)="app.RM.navigateRMComponents('manufacturers/manual')"
            [ngClass]="{
              'btn-default-blue': app.RM.mode === 'manual'
            }"
          >
            <i class="fa fa-user-circle"></i>&nbsp;&nbsp;{{
              app.text.RM.manual
            }}
          </button>
          <button
            class="btn btn-sm"
            (click)="
              app.RM.navigateRMComponents('manufacturers/manualAssignment')
            "
            [ngClass]="{
              'btn-default-blue': app.RM.mode === 'manualAssignment'
            }"
          >
            <i class="fas fa-user-edit"></i>&nbsp;&nbsp;{{
              app.text.matchingManufacturer.edited
            }}
          </button>
          <button
            class="btn btn-sm"
            (click)="app.RM.navigateRMComponents('manufacturers/override')"
            [ngClass]="{
              'btn-default-blue': app.RM.mode === 'override'
            }"
          >
            <i class="far fa-hand-paper"></i>&nbsp;&nbsp;{{
              app.text.RM.override
            }}
          </button>
          <button
            class="btn btn-sm"
            (click)="
              app.RM.navigateRMComponents('manufacturers/linkedToDeletedCPN')
            "
            [ngClass]="{
              'btn-default-blue': app.RM.mode === 'linkedToDeletedCPN'
            }"
          >
            <i class="far fa-hand-paper"></i>&nbsp;&nbsp;{{
              app.text.RM.linkedToDeletedCPN
            }}
          </button>
        </div>
        <!-- Parts buttons -->
        <div *ngIf="app.RM.isPart" class="col-12">
          <button
            class="btn btn-sm"
            (click)="app.RM.navigateRMComponents('parts/all-parts')"
            [ngClass]="{
              'btn-default-blue': app.RM.mode === 'all-parts'
            }"
            [title]="app.text.part.partsList"
          >
            <i class="far fa-clipboard-list"></i>&nbsp;&nbsp;{{
              app.text.part.partsList
            }}
          </button>
          <button
            class="btn btn-sm"
            (click)="app.RM.navigateRMComponents('parts/without-manufacturers')"
            [ngClass]="{
              'btn-default-blue': app.RM.mode === 'without-manufacturers'
            }"
            [title]="app.text.part.partsWithoutMan"
          >
            <i class="far fa-handshake-slash"></i>&nbsp;&nbsp;{{
              app.text.part.partsWithoutMan
            }}
          </button>
          <button
            class="btn btn-sm"
            (click)="app.RM.navigateRMComponents('parts/not-used-assemblies')"
            [ngClass]="{
              'btn-default-blue': app.RM.mode === 'not-used-assemblies'
            }"
            [title]="app.text.part.partsNotUsed"
          >
            <i class="far fa-question-square"></i>&nbsp;&nbsp;{{
              app.text.part.partsNotUsed
            }}
          </button>
          <button
            class="btn btn-sm"
            (click)="app.RM.navigateRMComponents('parts/parts-to-be-deleted')"
            [ngClass]="{
              'btn-default-blue': app.RM.mode === 'parts-to-be-deleted'
            }"
            [title]="app.text.part.deletePartsView"
          >
            <i class="fa fa-trash"></i>&nbsp;&nbsp;{{
              app.text.part.deletePartsView
            }}
          </button>
          <!-- view added only for MTU -->
          <button
            *ngIf="showInventoryMonitoringTable"
            class="btn btn-sm"
            (click)="app.RM.navigateRMComponents('parts/parts-status')"
            [ngClass]="{
              'btn-default-blue': app.RM.mode === 'parts-status'
            }"
            [title]="app.text.part.partsStatus"
          >
            <i class="fa-solid fa-flag-pennant"></i>&nbsp;&nbsp;{{
              app.text.part.partsStatus
            }}
          </button>
        </div>
      </div>
    </div>
    <div>
      <router-outlet></router-outlet>
    </div>
  </div>
</div>
