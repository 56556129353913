import { ComponentSettings, empty } from "../settings/settings";

const settings = {
  text: {
    currentMatch: {
      de: "Die derzeitige Verbindung wird gelöst. Wollen Sie dies wirklich tun?",
      en: "This will unmatch the current selection. Please confirm.",
      nl: "De huidige verbinding wordt verbroken. Wil je dit echt doen?",
    },
    keepCurrentMatch: {
      en: "Do you want to keep the existing data and manage it manually?",
      de: "Möchten Sie die vorhandenen Daten behalten und basierend darauf die Einheit manuell verwalten?",
      nl: "Wilt u de bestaande gegevens bewaren en handmatig beheren?",
    },
    discardCurrentMatch: {
      en: "This will delete all the existing data which is stored to item.",
      de: "Dies löscht alle vorhandenen Daten, die im Item gespeichert sind.",
      nl: "Hierdoor worden alle bestaande gegevens gewist.",
    },
    selectItem: {
      de: "Möchten Sie diese Einheit wirklich auswählen?",
      en: "Do you really want to select this item?",
      nl: "Wilt u echt dit artikel selecteren?",
    },
    loadedData: {
      de: "Die zugehörigen Daten werden geladen und die Risikobewertung wird mit der nächsten Aktualisierung der Datenbank durchgeführt.",
      en: "Corresponding data will be loaded and risk assessment will be executed with the next database update.",
      nl: "Corresponderende gegevens zullen worden geladen en de risicoanalyse zal worden uitgevoerd bij de volgende update van de database.",
    },
    keepData: {
      en: "Confirm",
      de: "Bestätigen",
      nl: "Bevestigen",
    },
    savedSuccesfully: {
      de: "Erfolgreich gespeichert!",
      en: "Succesfully saved",
      nl: "Succesvol gered",
    },
    abort: {
      en: "Abort",
      de: "Abbrechen",
      nl: "Annuleren",
    },
    yes: {
      en: "Yes",
      de: "Ja",
      nl: "Ja",
    },
    no: {
      en: "No",
      de: "Nein",
      nl: "Nee",
    },
    confirm: {
      en: "Confirm",
      de: "Bestätigen",
      nl: "Bevestigen",
    },
    deleteData: {
      de: "Daten löschen",
      en: "Delete data",
      nl: "Verwijder data",
    },
    saveData: {
      en: "Keep Data",
      de: "Daten behalten",
      nl: "Gegevens opslaan",
    },
  },
  field: {},
  url: {},
  permission: {},
  list: {
    columns: empty,
    columnRows: empty,
  },
};

export const matchingManufacturerModal: ComponentSettings<typeof settings> =
  settings;
