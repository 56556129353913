<app-modal
  #dueDateMaquetCardio
  [modalType]="'dueDateField'"
  [modalTitle]="app.text.stepper.changeDueDate"
  [disabled]="
    app.thread.thread[app.fieldId.thread.dueDate] === '' ||
    app.thread.thread[app.fieldId.thread.dueDate] == null
  "
  (ok)="setStatus()"
></app-modal>
<app-modal
  #uncompletedTasks
  [modalTitle]="app.text.stepper.modalTitle"
  [modalMessage]="app.text.stepper.uncompletedTasksModal"
  [showOnlyOkBtn]="true"
  [showButtons]="false"
></app-modal>
<app-modal
  #nextStep
  [modalMessage]="
    app.stepper.textWithVariable(
      app.text.stepper.confirmNext,
      app.field.getOptionText(
        field,
        app.stepper.steps[app.stepper.stepIndex + 1]
      )
    )
  "
  (ok)="
    app.stepper.isStepDisabled(
      app.stepper.steps[app.stepper.stepIndex + 1],
      app.post.solutions
    );
    select.next(app.stepper.steps[app.stepper.stepIndex + 1])
  "
>
</app-modal>
<app-modal
  #previousStep
  [modalMessage]="
    app.stepper.textWithVariable(
      app.text.stepper.confirmPrevious,
      app.field.getOptionText(
        field,
        app.stepper.steps[app.stepper.stepIndex - 1]
      )
    )
  "
  [modalTitle]="app.text.stepper.modalTitle"
  (ok)="
    app.stepper.isStepDisabled(
      app.stepper.steps[app.stepper.stepIndex - 1],
      app.post.solutions
    );
    select.next(app.stepper.steps[app.stepper.stepIndex - 1])
  "
>
</app-modal>
<app-modal
  #previousStepDB
  [modalMessage]="
    app.stepper.textWithVariable(
      app.text.stepper.confirmPrevious,
      app.field.getOptionText(
        field,
        app.stepper.steps[app.stepper.stepIndex - 1]
      )
    )
  "
  [modalTitle]="app.text.stepper.modalTitle"
  (ok)="select.next(app.stepper.steps[app.stepper.stepIndex - 1])"
>
</app-modal>
<app-modal
  #jumpToFirst
  [modalMessage]="
    app.stepper.textWithVariable(
      app.text.stepper.confirmPrevious,
      app.field.getOptionText(
        field,
        app.stepper.steps[
          app.stepper.stepIndex - (app.stepper.steps.length - 1)
        ]
      )
    )
  "
  [modalTitle]="app.text.stepper.modalTitle"
  (dismiss)="app.model[this.field] = app.thread.thread['thread.dueDate']"
  (ok)="
    select.next(
      app.stepper.steps[app.stepper.stepIndex - (app.stepper.steps.length - 1)]
    )
  "
>
</app-modal>

<div class="card my-3">
  <div>
    <div class="card-header d-flex justify-content-between py-2">
      <span class="me-auto" style="line-height: 30px">{{
        app.text.stepper.omfStatus
      }}</span>
      <div *ngIf="app.stepper.stepIndex - 1 >= 0 && app.customer !== 'db'">
        <button
          *ngIf="app.unlockedId == null"
          class="btn btn-sm btn-default-orange"
          (click)="getModal(previous)"
          [disabled]="
            !app.permission.stepper.changeStatus ||
            app.stepper.previousStatusDisabled
          "
        >
          {{ app.text.stepper.previous }}
        </button>
      </div>
      <div
        *ngIf="
          app.stepper.stepIndex + 1 < app.stepper.steps.length &&
          app.customer !== 'db'
        "
      >
        <button
          *ngIf="app.unlockedId == null"
          class="btn btn btn-sm btn-default-orange"
          (click)="getModal(next)"
          [disabled]="
            !app.permission.stepper.changeStatus ||
            app.stepper.nextStatusDisabled
          "
        >
          {{ app.text.stepper.next }}
        </button>
      </div>
    </div>
  </div>

  <div
    class="stepper-wrapper border-top pt-3"
    [ngClass]="{ 'px-4': app.customer === 'db' }"
  >
    <div
      *ngFor="let step of app.stepper.steps; let i = index"
      class="stepper-item pt-2"
      [ngClass]="{
        complete: i < app.stepper.stepIndex,
        active: i === app.stepper.stepIndex,
        disabled: i > app.stepper.stepIndex
      }"
      [ngStyle]="{ width: 100 / app.stepper.steps.length + '%' }"
    >
      <div
        [title]="app.field.getOptionText(app.fieldId.thread.omfStatus, step)"
        *ngIf="app.customer === 'db'"
        class="step-counter"
      >
        {{ step }}
      </div>
      <div *ngIf="app.customer !== 'db'" class="step-counter">
        {{ i + 1 }}
      </div>
      <div
        *ngIf="app.customer !== 'db'"
        class="step-name text-center"
        [ngClass]="{
          complete: i < app.stepper.stepIndex,
          active: i === app.stepper.stepIndex
        }"
      >
        {{ app.field.getOptionText(app.fieldId.thread.omfStatus, step) }}
      </div>
      <div
        *ngIf="i === app.stepper.stepIndex && app.customer === 'db'"
        class="step-name text-center"
      >
        {{ app.field.getOptionText(app.fieldId.thread.omfStatus, step) }}
      </div>
    </div>
  </div>
</div>

<!-- DB stepper workflow NEXT and PREVIOUS buttons -->
<div
  style="margin-bottom: 50px"
  *ngIf="app.unlockedId == null && app.customer === 'db'"
>
  <div *ngIf="app.stepper.stepIndex - 1 >= 0" style="float: left">
    <button
      *ngIf="app.permission.stepper.changeStatus"
      class="btn btn-sm m-0 m-0"
      (click)="previousStepDB.open()"
      [disabled]="!app.stepper.hasChangeStatusPermission(previous)"
    >
      <i class="fa fa-angle-double-left float-left p-1 status-icon"></i>
      {{
        app.field.getOptionText(
          app.fieldId.thread.omfStatus,
          app.stepper.steps[app.stepper.stepIndex - 1]
        )
      }}
    </button>
  </div>
  <div
    *ngIf="app.stepper.stepIndex === app.stepper.steps.length - 1"
    style="float: right"
  >
    <button
      *ngIf="app.permission.stepper.changeStatus"
      class="btn btn-sm m-0"
      (click)="jumpToFirst.open()"
      [disabled]="!app.stepper.hasChangeStatusPermission(jumpToFirstStep)"
    >
      <i class="fa fa-angle-double-left float-left p-1 status-icon"></i>
      {{
        app.field.getOptionText(
          app.fieldId.thread.omfStatus,
          app.stepper.steps[
            app.stepper.stepIndex - (app.stepper.steps.length - 1)
          ]
        )
      }}
    </button>
  </div>
  <div
    *ngIf="app.stepper.stepIndex + 1 < app.stepper.steps.length"
    style="float: right"
  >
    <button
      *ngIf="app.permission.stepper.changeStatus"
      class="btn btn-sm m-0 m-0"
      (click)="nextStep.open()"
      [disabled]="!app.stepper.hasChangeStatusPermission(next)"
    >
      {{
        app.field.getOptionText(
          app.fieldId.thread.omfStatus,
          app.stepper.steps[app.stepper.stepIndex + 1]
        )
      }}
      <i class="fa fa-angle-double-right"></i>
    </button>
  </div>
</div>

<div class="row mx-1" *ngIf="app.customer === 'db'">
  <div class="col-md-6 alert alert-warning bg-yellow text-center" role="alert">
    {{ app.text.stepper.responsibleMessage }}
    {{ app.stepper.numberOfResponsibleLeft }}
  </div>
  <div class="col-md-6 alert status-description" role="alert">
    <div class="col-md-12">{{ app.stepper.statusDescription }}</div>
    <div class="col-md-12">
      <b
        >{{ app.text.stepper.responsible }} :
        {{ app.stepper.statusResponsible }}</b
      >
    </div>
  </div>
</div>
