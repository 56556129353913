import { DepartmentGraphData } from "./department-graph-data";
import { AbstractGraph } from "../abstract-graph";
import { AppType } from "../../app.type";
import { GraphConfig } from "../graph.type";

export class DepartmentGraph extends AbstractGraph<DepartmentGraphData> {
  get config(): GraphConfig {
    return {
      type: "bar-vertical-2d",
      showXAxis: true,
      showYAxis: true,
      showLegend: true,
      xAxisLabel: null,
      yAxisLabel: null,
      colorScheme: {
        domain: ["#5AA454", "#A10A28", "#C7B42C", "#AAAAAA"],
      },
    };
  }

  constructor(protected app: AppType) {
    super(app);
  }

  protected getSortFunction() {
    return (a: DepartmentGraphData, b: DepartmentGraphData) =>
      b.total - a.total;
  }

  protected async getMap(map: Map<string, DepartmentGraphData>) {
    this.app.graphs.allThreads.forEach((thread: any) => {
      if (!thread.dinCodeRespDep) return;
      const dept = thread.dinCodeRespDep.toLowerCase();
      const item = map.get(dept);
      if (item == null) {
        const newItem = new DepartmentGraphData(thread, this.app);
        map.set(dept, newItem);
        newItem.add(thread);
      } else {
        item.add(thread);
      }
    });
  }
}
