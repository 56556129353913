<app-modal
  #deleteMessageModal
  [modalMessage]="app.text.message.deleteQuestion"
  [modalTitle]="app.text.message.modalTitle"
  (ok)="app.message.deleteMessage(message)"
></app-modal>

<div *ngIf="app.unlockedId === message._id">
  <app-message [id]="message._id"> </app-message>
</div>

<div class="m-3">
  <!-- <div class="p-1 clear" (click)="togglePost()">
    <div class="row justify-content-between px-3">
      <div class="credits">
        <app-credits
          *ngIf="message != null"
          type="message"
          [inputDoc]="message"
          [isCreated]="true"
        ></app-credits>
      </div>
      <div class="message-icon">
        <span *ngIf="showMessages" class="icon pull-right">
          <i class="fa fa-chevron-up"></i>&nbsp;
        </span>
        <span *ngIf="!showMessages" class="icon pull-right">
          <i class="fa fa-chevron-down"></i>&nbsp;
        </span>
      </div>
    </div>
  </div> -->
  <div *ngIf="showMessages">
    <div class="row px-3">
      <div class="large-col no-padding">
        <div class="box-item">
          <app-field
            [field]="app.fieldId.message.message"
            [model]="app.message.createMessageModel(message)"
            [disabled]="true"
            [itemsInputValue]="true"
            [showLabel]="false"
          ></app-field>
        </div>
        <div class="float-end">
          <app-credits
            *ngIf="message != null"
            type="message"
            [inputDoc]="message"
            [isCreated]="true"
          ></app-credits>
          <div class="clear">
            <app-credits
              *ngIf="message != null"
              type="message"
              [inputDoc]="message"
              [isEdited]="true"
            ></app-credits>
          </div>
        </div>
      </div>

      <div class="col-1">
        <div *ngIf="app.unlockedId === null" class="controls">
          <app-unlock
            [id]="message._id"
            [permission]="app.message.hasEditPermission(message)"
            [hasText]="false"
            (unlock)="
              app.message.model = app.message.getModelClean(message);
              app.message.showContent()
            "
            [title]="app.text.message.editComment"
          >
          </app-unlock>

          <!-- <app-attachments-controls
                  *ngIf="app.post.hasEditPermission(message)"
                  [id]="message._id"
                  type="message"
                ></app-attachments-controls> -->
          <button
            *ngIf="app.message.hasEditPermission(message)"
            class="btn m-0"
            (click)="deleteMessageModal.open()"
            [title]="app.text.message.delete"
          >
            <i class="fa fa-trash"></i>
          </button>
        </div>
      </div>
    </div>
  </div>
</div>
