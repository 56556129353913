import {
  changeXmlTemplate,
  dataXmlTemplate,
  itemsXmlTemplate,
} from "./thread-xml";
import { Language } from "../../../../shared/settings/settings";
import { Thread } from "../../../../shared/models/thread";

const head = `<?xml version="1.0" encoding="utf-8"?>
<PCNbody xmlns:xsd="http://www.w3.org/2001/XMLSchema" xmlns="http://www.smartpcn.org/images/files/VDMA24903Schema/PCNbody">`;
const foot = `</PCNbody>`;
export function getDataXml(
  data: Thread,
  itemValue: Thread[],
  locale: Language
) {
  return `${head}
        ${dataXmlTemplate(data)}
        ${changeXmlTemplate(data)}
        ${itemsXmlTemplate(itemValue, locale)}
        ${foot}
    `;
}
