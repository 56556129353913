import { SolutionsCurrentYear } from "./solutions-current-year-graph-data";
import { AbstractGraph } from "../abstract-graph";
import { AppType } from "../../app.type";
import { GraphConfig } from "../graph.type";
import { Post } from "../../../../../shared/models/post";

export class SolutionsCurrentYearGraph extends AbstractGraph<SolutionsCurrentYear> {
  get config(): GraphConfig {
    return {
      type: "bar-vertical-stacked",
      showXAxis: true,
      showYAxis: true,
      showLegend: true,
      xAxisLabel: null,
      yAxisLabel: null,
      colorScheme: {
        domain: ["#757575"],
      },
    };
  }

  constructor(protected app: AppType) {
    super(app);
  }

  protected getMap(map: Map<string, SolutionsCurrentYear>) {
    let solutions = this.app.field.getOptions(
      this.app.fieldId.post.resolveClass
    );
    solutions.forEach((solution) => {
      map.set(solution, new SolutionsCurrentYear(solution, this.app));
    });

    this.app.graphs.solutions.forEach((solution: Post) => {
      if (solution.resolveClass == null || solution.create_time == null) {
        return;
      }
      if (
        new Date(solution.create_time).getFullYear() < new Date().getFullYear()
      ) {
        return;
      }
      const item = map.get(solutions[solutions.indexOf(solution.resolveClass)]);
      if (item == null) {
        return;
      }
      item.add(solution);
    });
  }
}
