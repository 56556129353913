export const clientMapping: { [key: string]: string | null } = {
  pcnTitle: "title",
  pcnType: "creator_class",
  pcnCompany: "creator",
  pcnMfrName: "mfrhighlevel",
  pcnEmail: "crtContact",
  pcnStreet: null,
  pcnZipCode: null,
  pcnCity: null,
  pcnState: null,
  pcnCountry: null,
  pcnNumber: "pcnID",
  pcnIssueDate: "oDate",
  pcnRevision: "pcnRevision",
  company: "company",
  itemMfrNumber: "crtNumber",
  itemMfrTypeIdent: "drawNumber",
  itemMfrName: "omfShortDescr",
  itemCategory: "productCategory",
  itemRev: "typeIdNum",
  itemSubData: "itemSubData",
  type: "changeClasses",
  itemMfrReplNumber: "replmanfItemnumber",
  itemSOP: "itemSOP",
  itemEOPeffDate: "itemEOP",
  itemEOS: "itemEOS",
  itemLTD: "itemLTD",
  itemEOSR: "itemEOSR",
  pcnChangeDetail: "descr",
  pcnChangeIdentificationMethod: "identificationMethod",
  omfStatus: "omfStatus",
  artNumber: "artNumber",
  omfNumber: "omfNumber",
  entryDate: "entryDate",
  entryCheckbox: "entryCheckbox",
  businessArea: "businessArea",
  deputy: "deputy",
  deputyDepartment: "deputyDepartment",
  deputyEmail: "deputyEmail",
  dinCode: "dinCode",
  dinText: "dinText",
  instructions: "instructions",
};
