import { Inject, Injectable } from "@angular/core";
import {
  getPartsCompressed,
  getPartsNotUsedInAssemblies,
  getPartsWithoutManufacturers,
  getPartsToBeDeleted,
  getPartsByStatus,
} from "../../api.service";
import { AppType, APP_TYPE } from "../../app.type";
import { Part } from "../../../../../shared/models/part";
import { PartsFilterTableServiceType } from "./parts-filter-table.service.type";
import { bulkDocsByType } from "../../api.service";

@Injectable()
export class PartsFilterTableService implements PartsFilterTableServiceType {
  size: number = 0;
  constructor(@Inject(APP_TYPE) private app: AppType) {}

  parts: Partial<Part>[] = [];
  cleanParts: Partial<Part>[] = [];
  partIds: string[] = [];
  selectedPart: Partial<Part> = {};
  partsTableColumns: string[] = [];

  async getParts(mode: string, page?: string) {
    page = page != null ? page : "1";
    this.app.spinner.showSpinner();
    let response: { parts: Partial<Part>[]; size: number } = {
      parts: [],
      size: 0,
    };
    switch (mode) {
      case "all-parts":
        response = await getPartsCompressed(
          this.app.customers.expectCurrent,
          page,
          this.app.paginator.pageSize.toString()
        );
        break;
      case "without-manufacturers":
        response = await getPartsWithoutManufacturers(
          this.app.customers.expectCurrent,
          page,
          this.app.paginator.pageSize.toString()
        );
        break;
      case "not-used-assemblies":
        response = await getPartsNotUsedInAssemblies(
          this.app.customers.expectCurrent,
          page,
          this.app.paginator.pageSize.toString()
        );
        break;
      case "parts-to-be-deleted":
        response = await getPartsToBeDeleted(
          this.app.customers.expectCurrent,
          page,
          this.app.paginator.pageSize.toString()
        );
        break;
      case "parts-status":
        response = await getPartsByStatus(
          this.app.customers.expectCurrent,
          page,
          this.app.paginator.pageSize.toString()
        );
        break;
    }
    this.parts = response.parts;
    this.size = response.size;
    this.cleanParts = this.parts;
    this.partIds = response.parts.map((p: any) => p.partNumber);
    this.app.spinner.hideSpinner();
    return this.parts;
  }

  async getPartIds(page: string) {
    page = page != null ? page : "1";
    let response: { parts: Partial<Part>[]; size: number } = {
      parts: [],
      size: 0,
    };
    switch (this.app.RM.mode) {
      case "all-parts":
        response = await getPartsCompressed(
          this.app.customers.expectCurrent,
          page,
          this.app.paginator.pageSize.toString()
        );
        break;
      case "without-manufacturers":
        response = await getPartsWithoutManufacturers(
          this.app.customers.expectCurrent,
          page,
          this.app.paginator.pageSize.toString()
        );
        break;
      case "not-used-assemblies":
        response = await getPartsNotUsedInAssemblies(
          this.app.customers.expectCurrent,
          page,
          this.app.paginator.pageSize.toString()
        );
        break;
      case "parts-to-be-deleted":
        response = await getPartsToBeDeleted(
          this.app.customers.expectCurrent,
          page,
          this.app.paginator.pageSize.toString()
        );
    }
    this.partIds = response.parts.map((p: any) => p.partNumber);
  }

  async getFilterResultBasedOnView(page: string) {
    this.app.spinner.showSpinner();
    if (Object.keys(this.app.filterHeaderTable.filterValues).length > 0) {
      await this.app.partsTable.getPartIds("1");

      await this.app.filterHeaderTable.getPartSearchResult(page);
    } else {
      await this.getParts(this.app.RM.mode, page);
    }
    this.app.spinner.hideSpinner();
  }

  getPartsTableColumns(mode: string) {
    // defualt columns list - for parts table we have to types of columns list
    const partsColumns: string[] = this.app.list.RM.partsColumns.map(
      (field: string) => field.split(".")[1]
    );

    // this is a new type of parts table used only for MTU Client
    const statusColumns: string[] = this.app.list.RM.partsStatusColumns.map(
      (field: string) => field.split(".")[1]
    );

    switch (mode) {
      case "parts-status":
        // last note column is a special field which is not defined on parts properties
        this.partsTableColumns = statusColumns;
        break;
      case "all-parts":
        // see open cases column needs to be added only on List of Parts table
        this.partsTableColumns = partsColumns;
      case "parts-to-be-deleted":
      case "not-used-assemblies":
      case "without-manufacturers":
        this.partsTableColumns = partsColumns;
        break;
    }

    return this.partsTableColumns;
  }

  getPartsHeaderLabels(id: string) {
    switch (id) {
      case "partsToVehicles":
        return "<i class='fas fa-sitemap fa-rotate-270' aria-hidden='true'>";
      case this.app.fieldId.part.lastNote:
        return this.app.text.message.message;
      case "threadCase":
        if (this.app.permission.RM.isAnalyst || this.app.auth.isRMAdmin) {
          return "";
        }
        return "<i class='fas fa-folders' aria-hidden='true'></i>";
      default:
        return this.app.field.getTableLabel("part." + id);
    }
  }

  async deletePart() {
    this.app.spinner.showSpinner();
    this.app.partsTable.selectedPart._deleted = true;
    await bulkDocsByType(this.app.customers.expectCurrent, "part", [
      this.app.partsTable.selectedPart,
    ]);
    this.app.partsTable.getParts("parts-to-be-deleted");
    this.app.spinner.hideSpinner();
  }
}
