<app-modal
  #deleteTrainModal
  [modalMessage]="app.text.train.deleteMessage"
  [modalTitle]="app.text.train.modalTitle"
  (ok)="delete()"
></app-modal>
<div class="row">
  <div class="col-md-12">
    <div class="controls">
      <span *ngIf="!app.train.isNew && trainId !== undefined">
        <app-unlock [id]="trainId">
          <span
            *ngIf="
              app.train.modelHasChanges || app.train.isNew;
              then thenBlock;
              else elseBlock
            "
          ></span>
          <ng-template #thenBlock>
            <button
              class="btn pull-right"
              [title]="app.text.impact.discard"
              (click)="discardChanges()"
            >
              <i class="fa fa-times"></i>
            </button>
            <button
              class="btn pull-right"
              *ngIf="
                app.field.getInvalidFields('train').length < 1 &&
                app.train.modelHasChanges
              "
              (click)="app.train.save()"
              [title]="app.text.impact.save"
            >
              <i class="fa fa-check"></i>
            </button>
          </ng-template>

          <ng-template #elseBlock>
            <button
              class="btn pull-right"
              (click)="discardChanges()"
              [title]="app.text.impact.close"
            >
              <i class="fa fa-times"></i>
            </button>
          </ng-template>
        </app-unlock>
      </span>
      <span *ngIf="app.unlockedId == null">
        <span *ngIf="!app.train.isNew">
          <button
            class="btn"
            (click)="navigateToListView()"
            [title]="app.text.impact.close"
          >
            <i class="fa fa-times"></i>
          </button>
          <button
            class="btn"
            (click)="deleteTrainModal.open()"
            [title]="app.text.train.deleteTrain"
          >
            <i class="fa fa-trash"></i>
          </button>
        </span>
      </span>
      <div class="col-md-12" *ngIf="app.train.isNew">
        <span *ngIf="app.field.getInvalidFields('train').length === 0">
          <button
            class="btn"
            (click)="app.train.save()"
            [title]="app.text.impact.save"
          >
            <i class="fa fa-check"></i>
          </button>
        </span>
        <button
          class="btn"
          (click)="navigateToListView()"
          [title]="app.text.impact.close"
        >
          <i class="fa fa-times"></i>
        </button>
      </div>
    </div>
    <div class="col-md-12 mt-5">
      <app-box
        [box]="app.listId.train.trains"
        [unlock]="trainId"
        [model]="app.model"
      ></app-box>
    </div>
  </div>
</div>
