<button
  class="btn btn-sm btn-light"
  (click)="$event.stopPropagation(); openModal()"
>
  <i class="fas fa-chart-bar"></i>
</button>
<ng-template #stockGraphicModal let-modal>
  <div class="modal-header">
    <div class="col-md-12">
      <span class="modal-title">
        <span class="font-weight-bold">
          {{ app.text.stock.modalTitle }}
        </span>
        <span *ngIf="cpn !== '' || mpn !== ''">-</span>
        <span *ngIf="cpn !== ''">
          {{ app.field.getLabel("thread.artNumber") }} :
          {{ app.thread.thread["thread.artNumber"] }}
        </span>
        <span *ngIf="mpn !== ''">
          {{ app.field.getLabel("thread.crtNumber") }} :
          {{ app.thread.thread["thread.crtNumber"] }}
        </span>
      </span>
    </div>
  </div>
  <div class="modal-body">
    <div *ngIf="noData" class="col-md-12 text-center font-weight-bold">
      {{ app.text.stock.noData }}
    </div>
    <div *ngIf="noCpn" class="col-md-12 text-center font-weight-bold">
      {{ app.text.stock.noCPN }}
    </div>
    <div *ngIf="!noCpn && !noData" class="chart-container">
      <ngx-charts-bar-vertical-2d
        [results]="results"
        [xAxis]="true"
        [yAxis]="true"
        [legend]="true"
        [showXAxisLabel]="false"
        [showYAxisLabel]="false"
        [animations]="false"
        [barPadding]="2"
        [groupPadding]="5"
      ></ngx-charts-bar-vertical-2d>
    </div>
  </div>
  <div class="modal-footer">
    <button class="btn btn-cancel text-center m-1" (click)="closeModal()">
      {{ app.text.stock.close }}
    </button>
  </div>
</ng-template>
