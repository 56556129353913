<td *ngFor="let column of tableColumns" class="cell" [ngClass]="{'description-cell': column === 'description'}">
  <span class="d-flex" [ngSwitch]="getColumnType(column)">
    <span *ngIf="column === 'partNumber'">
      <span *ngFor="let p of counter(row.level)"
        >&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span
      >
    </span>
    <span *ngSwitchCase="'normal'">
      <span *ngIf="isPart">
        <div
          [ngClass]="{
            'width-partNumber': column === 'partNumber'
          }"
        >
          <span *ngIf="column !== 'stockRange'">
            {{ row.node.part[column] }}
          </span>

          <span
            *ngIf="column === 'stockRange' && row.node.part.stockRange > -1"
          >
            {{ row.node.part[column] }}
          </span>
        </div>
      </span>
      <span *ngIf="isManufacturer">
        <div [ngClass]="{ 'description-cell': column === 'name' }">
          <span *ngIf="column != 'stockRange'">
            {{ row.manufacturer[column] }}
          </span>
          <span
            *ngIf="column === 'stockRange' && row.manufacturer.stockRange > -1"
          >
            {{ row.manufacturer[column] }}
          </span>
        </div>
        <div *ngIf="column === 'description'">
          {{ row.manufacturer.name }}
        </div>
      </span>
    </span>

    <span *ngSwitchCase="'navigateAction'">
      <span *ngIf="isPart">
        <span
          *ngIf="!row.node.part.isOpen && !row.node.part.isLeaf"
          (click)="app.tree.toggleTreeRow(row)"
          ><i class="far fa-plus icon-gray pointer p-1"></i>
        </span>
        <span *ngIf="row.node.part.isOpen" (click)="app.tree.toggleTreeRow(row)"
          ><i class="far fa-minus icon-gray pointer p-1"></i
        ></span>
        <a
          class="partNumber text-center"
          (click)="app.treeRow.navigateToDetailPage(row)"
        >
          <span
            *ngIf="row.node.part.isLeaf"
            (click)="app.tree.toggleTreeRow(row)"
            ><i class="fa fa-minus icon-gray pointer p-1"></i
          ></span>
          {{ row.node.part[column] }}
        </a>
      </span>
      <span *ngIf="isManufacturer">
        <span *ngIf="column === 'partNumber'">
          <a class="partNumber" (click)="app.treeRow.navigateToDetailPage(row)">
            <i class="fa fa-angle-right icon-gray"></i>
            {{ row.manufacturer.manufacturerPartNumber }}
          </a>
        </span>
        <sapn *ngIf="column !== 'partNumber'">
          <a class="partNumber" (click)="app.treeRow.navigateToDetailPage(row)">
            <i class="fa fa-angle-right icon-gray"></i>
            {{ row.manufacturer[column] }}
          </a>
        </sapn>
      </span>
    </span>

    <span *ngSwitchCase="'quantity'">
      <span *ngIf="isPart">{{ row.quantity ? row.quantity : 1 }}</span>
      <span *ngIf="isManufacturer"></span>
    </span>

    <span *ngSwitchCase="'optionText'">
      <!-- For type optionText the values that are displayed, are calculated in the getStatusTextAndColor method -->
      <span *ngIf="isPart">
        {{ row.node.part[column] }}
      </span>
      <span *ngIf="isManufacturer">
        {{ row.manufacturer[column] }}
      </span>
    </span>

    <span *ngSwitchCase="'where-used'">
      <span
        *ngIf="isPart && row.level !== 0"
        (click)="openWhereUsedModal(row.node.part.partNumber)"
      >
        <i class="fas fa-sitemap fa-rotate-270 pointer icon-blue"></i>
      </span>
    </span>

    <span *ngSwitchCase="'radio'">
      <span *ngIf="isPart">
        <i
          class="far fa-dot-circle"
          *ngIf="row.node.part[column] === 'true'"
        ></i>
        <i class="fal fa-circle" *ngIf="row.node.part[column] === 'false'"></i>
      </span>
      <span *ngIf="isManufacturer">
        <i
          class="fas fa-star-of-life"
          *ngIf="row.manufacturer[active] === true"
        ></i>
      </span>
    </span>

    <span *ngSwitchCase="'actions'">
      <span *ngIf="app.permission.RM.createCase">
        <i
          class="far fa-folder-plus icon-blue pointer p-1"
          *ngIf="
            !getCase &&
            isManufacturer &&
            row.manufacturer.manufacturerPartNumber !== ''
          "
          (click)="app.treeRow.newCase(row); app.treeRow.currentRow = row"
        ></i>
        <i
          class="fas fa-folder-open icon-blue pointer p-1"
          *ngIf="getCase"
          (click)="
            seeOpenedCasesModal.open();
            app.treeRow.getCasesByPartNumber(row);
            app.treeRow.currentRow = row
          "
        ></i>
      </span>
    </span>

    <span *ngSwitchCase="'matchedSystem'">
      <a
        *ngIf="isManufacturer"
        (click)="app.treeRow.navigateToMatchingPage(row)"
      >
        <i
          class="far fa-link icon-blue pointer p-1"
          *ngIf="manufacturerStatus === 'matched'"
        ></i>
        <i
          class="fa fa-user-circle icon-blue pointer p-1"
          *ngIf="manufacturerStatus === 'manual'"
        ></i>
        <i
          class="far fa-unlink icon-blue pointer p-1"
          *ngIf="manufacturerStatus === 'unmatched'"
        ></i>
        <i
          class="far fa-hand-paper icon-blue"
          *ngIf="manufacturerStatus === 'override'"
        ></i>
        <i
          class="fas fa-user-edit icon-blue pointer"
          *ngIf="manufacturerStatus === 'manualAssignment'"
        ></i>
      </a>
    </span>

    <span *ngSwitchCase="'badge'">
      <span
        *ngIf="isPart && row.node.part[column]"
        class="badge"
        [ngStyle]="row.node.part[column].color"
      >
        {{ row.node.part[column].label }}
      </span>
      <span
        *ngIf="isManufacturer && row.manufacturer[column]"
        class="badge"
        [ngStyle]="row.manufacturer[column].color"
      >
        {{ row.manufacturer[column].label }}
      </span>
    </span>

    <span *ngSwitchCase="'link'">
      <span *ngIf="isPart"></span>
      <span *ngIf="isManufacturer">
        <a
          *ngIf="row.manufacturer[column]"
          [href]="row.manufacturer[column]"
          target="_blank"
        >
          <i class="fas fa-file icon-blue"></i>
        </a>
      </span>
    </span>

    <span *ngSwitchCase="'statusAlerts'">
      <span
        *ngIf="statusAlertInfo.showIcon"
        class="hoverIconArea"
        [ngbTooltip]="statusAlertInfo.tooltipText"
      >
        <i class="fa-regular fa-message-exclamation p-1"></i>
      </span>
    </span>

    <span *ngSwitchCase="'pending'">
      <span *ngIf="isPart"></span>
      <span *ngIf="isManufacturer">
        <span *ngIf="row.manufacturer[column]">
          {{ app.text.manufacturer.pending }}
        </span>
      </span>
    </span>

    <span *ngSwitchCase="'activeMPNs'">
      <span *ngIf="isPart">
        <span *ngIf="activeMPNs === 0">
          {{ row.node.part["activeMPNs"] }}
        </span>
        <span *ngIf="activeMPNs > 0">
          {{ app.text.part.notApplicableAssembly }}
        </span>
      </span>
      <span *ngIf="isManufacturer"></span>
    </span>
  </span>
</td>

<app-modal
  #seeStructureModal
  [modalTitle]="app.text.part.whereUsed"
  [modalType]="'showWhereUsed'"
  [showButtons]="false"
>
</app-modal>

<app-modal
  #seeOpenedCasesModal
  [modalTitle]="app.text.thread.threads"
  [modalType]="'showListOfCases'"
  [showButtons]="false"
>
</app-modal>
