import {
  User,
  UserCompressed,
  UserOptions,
} from "../../../../shared/models/user";

export const JOBTITLE_OM: any = "om";
export const JOBTITLE_KM: any = "km";
export const JOBTITLE_USER: any = "user";

export interface UsersServiceType {
  /** Current loaded users */
  users: UserCompressed[];

  /** Current loaded clean users */
  cleanUsers: UserCompressed[];

  /** The current loaded users mapped into an array of ids */
  userIds: string[];

  /** Current selected user */
  currentUser: User;

  /** The user model (an object used for editing) */
  currentUserModel: User;

  /** The user clean model (an object used for editing) */
  cleanUserModel: User;

  /** The selected user's id */
  currentId: string;

  /** The user that has been chosen from the list view */
  currentUserName: string;

  /** Is the currently selected user a new document which has not been saved yet? */
  isNew: boolean;

  /** List of all users that have the displayAsComponent property set to true  - used in thread.componentResponsibles*/
  componentResponsibles: string[];

  /** List of users and their roles - to be used in the dropdowns as options*/
  /** retrieves only the active & users that don't have the analyst role */
  userRoles: UserOptions[];

  /** Does the user model have changes?  */
  modelHasChanges(): boolean;

  /** Gets the list of users */
  getListOfUsers(): Promise<void>;

  /** Returns a user by the userName */
  getUserById(userId: string): Promise<User>;

  /** Get the list of all users that have the displayAsComponent property set to true */
  getComponentResponsibles(): Promise<void>;

  /** Get the list of the users along with their roles */
  getUsersWithRoles(): Promise<void>;

  /** Get the roles of a user which is sent as a param */
  getUserRoles(user: string): string[];

  /** Get the list of users with specific role */
  getOptions(role?: string, jobTitle?: string): string[];

  /** Checks password policy before saving */
  checkPasswordPolicy(model: User): any;

  /** Save/update of an user */
  save(): Promise<void>;

  /** Set next expire date for the password */
  setPasswordExpireDate(): void;

  /** Set language for a user */
  setLanguage(language: string): Promise<void>;

  /** Set the property active to false */
  deactivateUser(): Promise<void>;

  /** Delete a user */
  delete(): Promise<void>;

  /** Checks if the save button should be disabled */
  isDisabled(): boolean;

  /** Does the currently logegd in user have the right to edit a user document with the given id */
  hasEditPermission(): boolean;

  /** Set the fields that are required for create/edit user */
  setRequiredFields(): void;

  /** Export users as csv */
  exportData(): Promise<void>;
}
