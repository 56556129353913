<app-modal
  #syncCaseFromPortal
  [modalMessage]="app.text.sync.syncCaseFromPortal"
  (ok)="app.sync.prepareSyncInfo(type, id, customer)"
>
</app-modal>
<app-modal
  #syncCaseFromClient
  [modalMessage]="app.text.sync.syncCaseFromClient"
  (ok)="app.sync.prepareSyncInfo(type, id, customer)"
>
</app-modal>
<app-modal
  #syncCommentFromPortal
  [modalMessage]="app.text.sync.syncCommentFromPortal"
  (ok)="app.sync.prepareSyncInfo(type, id, customer)"
>
</app-modal>
<app-modal
  #syncCommentFromClient
  [modalMessage]="app.text.sync.syncCommentFromClient"
  (ok)="app.sync.prepareSyncInfo(type, id, customer)"
>
</app-modal>

<span *ngIf="app.customers.hasPortal()">
  <span *ngFor="let syncCustomer of app.customers.getSyncCustomers()">
    <!-- display sync buttons for cpmments/solutions -->
    <button
      *ngIf="displayPostSync && app.sync.isSynced('thread', id, syncCustomer)"
      class="btn btn-sm btn-clean"
      [title]="app.text.sync.sync + ': ' + app.customers.getText(syncCustomer)"
      [ngClass]="{
        selected: app.sync.isSynced(type, id, syncCustomer)
      }"
      (click)="
        syncCustomer === 'omp'
          ? syncCommentFromClient.open()
          : syncCommentFromPortal.open();
        customer = syncCustomer
      "
      [disabled]="app.sync.isSynced(type, id, syncCustomer)"
    >
      <i class="fas fa-sync-alt"></i>
      <span *ngIf="hasText">{{ app.text.sync.syncText }}</span>
    </button>

    <!-- display sync buttons for thread -->
    <button
      *ngIf="!displayPostSync"
      class="btn btn-sm btn-clean btn-sync"
      [title]="app.text.sync.sync + ': ' + app.customers.getText(syncCustomer)"
      [ngClass]="{
        selected: app.sync.isSynced(type, id, syncCustomer),
        lightText: app.thread.changeHeadlineColor()
      }"
      (click)="
        syncCustomer === 'omp'
          ? syncCaseFromClient.open()
          : syncCaseFromPortal.open();
        customer = syncCustomer
      "
      [disabled]="app.sync.isSynced(type, id, syncCustomer)"
    >
      <i class="fas fa-sync-alt"></i>
      {{ app.text.sync.syncText }}
    </button>
  </span>
</span>
