import { AbstractGraphData } from "../abstract-graph-data";
import { AppType } from "../../app.type";
import { StringUtils } from "../../../../../shared/utils/string.utils";

export class OMApproachesPerYear extends AbstractGraphData {
  reactive = 0;
  proactive = 0;
  unknown = 0;

  constructor(private year: string, app: AppType) {
    super(app);
  }

  add(thread: any) {
    if (thread.omApproach === "proactive") {
      this.proactive++;
    }
    if (thread.omApproach === "reactive") {
      this.reactive++;
    }
    if (StringUtils.isNullOrEmpty(thread.omApproach)) {
      this.unknown++;
    }
  }

  get data() {
    return {
      name: this.year,
      series: [
        {
          name: this.app.field.getOptionText(
            this.app.fieldId.thread.omApproach,
            "reactive"
          ),
          value: this.reactive,
        },
        {
          name: this.app.field.getOptionText(
            this.app.fieldId.thread.omApproach,
            "proactive"
          ),
          value: this.proactive,
        },
        { name: this.app.text.graphs.unknown, value: this.unknown },
      ],
    };
  }
}
