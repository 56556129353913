import {
  Component,
  Input,
  Output,
  EventEmitter,
  OnInit,
  OnDestroy,
  Type,
  ViewChild,
} from "@angular/core";
import { Subscription } from "rxjs";
import { getThreadsCompressed } from "../../api.service";
import { getApp } from "../../../app/app";
import { Part } from "../../../../../shared/models/part";
import { StringUtils } from "../../../../../shared/utils/string.utils";
@Component({
  selector: "app-detail-table",
  templateUrl: "./detail-table.component.html",
  styleUrls: ["./detail-table.component.scss"],
})
export class DetailTableComponent implements OnInit, OnDestroy {
  @Input() columns: string[] = [];
  @Input() showPaginator = true;
  @Input() showNumberingColumn = false;
  @Input() type: "part" | "manufacturer" = "part";
  @Output() selectRow = new EventEmitter<string>();

  newManufacturerSubscription: Subscription = new Subscription();
  selectedFilters: { columnName: string; value: string }[] = [];
  docType = "";

  app = getApp((app) => {
    this.app = app;
  });
  cleanColumns: string[] = [];
  copyOfColumns: string[] = [];
  loading: boolean = false;

  async ngOnInit() {
    this.loading = true;
    /** Threads compressed request was used to display the list of open cases
     */
    this.app.type = "manufacturer";
    this.app.thread.threadsCompressed = await getThreadsCompressed(
      this.app.customers.expectCurrent,
      "thread"
    );
    this.docType = `${this.type}.`;
    await this.app.detailTable.getDocsByType(this.type);
    this.newManufacturerSubscription =
      this.app.manufacturer.manufacturerAction.subscribe(async (value) => {
        if (value) {
          await this.app.detailTable.getDocsByType(this.type);
        }
      });
    this.getColumns();
    this.loading = false;
  }

  getColumns() {
    this.copyOfColumns = this.columns;

    this.columns.forEach((c: string) => {
      let field = c.split(".");
      if (field[1]) {
        this.cleanColumns.push(field[1]);
      } else {
        this.cleanColumns.push(field[0]);
      }
    });

    if (this.app.permission.RM.createCase && this.type === "manufacturer") {
      this.copyOfColumns.push("createCase");
      this.cleanColumns.push("createCase");
    }
    if (
      (this.type === "part" && this.app.permission.part.deleteItem) ||
      (this.type === "manufacturer" &&
        this.app.permission.manufacturer.deleteManufacturer)
    ) {
      this.copyOfColumns.push("remove");
      this.cleanColumns.push("remove");
    }
  }

  async navigate(doc: Part, column?: string) {
    /** do not redirect to the view if the column clicked is delete */
    let url = "";
    if (column === "delete" || column === "createCase") {
      return;
    } else {
      if (this.app.leftNav.selectedBox === "part.billOfMaterialSection") {
        const part = StringUtils.encode(doc._id);
        url = "/" + this.app.customers.current + "/part/" + part;
      } else {
        const mpn = StringUtils.encode(doc._id);
        url = "/" + this.app.customers.current + "/manufacturer/" + mpn;
      }
      this.app.routing.openInNewTab(url);
    }
  }

  ngOnDestroy(): void {
    if (this.newManufacturerSubscription) {
      this.newManufacturerSubscription.unsubscribe();
    }
    this.app.table.selected.clear();
  }
}
