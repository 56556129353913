import { ComponentSettings, FieldTypes, empty } from "../settings/settings";

const field = {
  docType: {
    type: FieldTypes.value,
    text: {
      de: "Typ",
      en: "Type",
      nl: "Type",
    },
  },
  docId: {
    type: FieldTypes.value,
    text: {
      de: "Dokument",
      en: "Document",
      nl: "Document",
    },
  },
  threadId: {
    type: FieldTypes.value,
    text: {
      de: "Fallnummer",
      en: "Case number",
      nl: "Issue nummer",
    },
  },
  fieldId: {
    type: FieldTypes.value,
    text: {
      de: "Feld",
      en: "Field",
      nl: "Veld",
    },
  },
  time: {
    text: {
      de: "Geändert am",
      en: "Edited on",
      nl: "Gewijzigd op",
    },
    type: FieldTypes.timestamp,
  },
  user: {
    type: FieldTypes.value,
    text: {
      de: "Geändert von",
      en: "Edited by",
      nl: "Gewijzigd door",
    },
  },
  newValue: {
    type: FieldTypes.data,
    text: {
      de: "Wert",
      en: "Value",
      nl: "Waarde",
    },
  },
  oldValue: {
    type: FieldTypes.data,
    text: {
      de: "Wert",
      en: "Value",
      nl: "Waarde",
    },
  },
  docTrainName: {
    type: FieldTypes.data,
    text: {
      de: "Train dokument name",
      en: "Train document Name",
      nl: "Train document Name",
    },
  },
  docImpactName: {
    type: FieldTypes.data,
    text: {
      de: "Impact dokument name",
      en: "Impact document Name",
      nl: "Impact document Name",
    },
  },
};

const settings = {
  permission: {},
  text: {
    history: { de: "Historie", en: "History", nl: "Historie" },
    title: { de: "Historie", en: "History", nl: "Historie" },

    threadHistory: {
      de: "Fall Historie",
      en: "Case History",
      nl: "Issue Historie",
    },
    manufacturerHistory: {
      de: "Hersteller Historie",
      en: "Manufacturer History",
      nl: "Fabrikant Historie",
    },
    partHistory: {
      de: "Teil Historie",
      en: "Part History",
      nl: "Artikel Historie",
    },

    on: { de: "am ", en: "on ", nl: "op " },

    //Thread
    threadImageCreated: {
      de: "hat ein Foto eines Falls hochgeladen ",
      en: "uploaded a case photo ",
      nl: "heeft een foto van een issue geupload ",
    },
    threadImageDeleted: {
      de: "hat ein Foto eines Falls gelöscht ",
      en: "deleted the case photo ",
      nl: "heeft een foto van een issue verwijderd ",
    },
    threadCreated: {
      de: "hat den Fall eröffnet ",
      en: "created the case ",
      nl: "heeft het Issue aangemaakt ",
    },
    threadEdited: {
      de: "Der Fall wurde wie folgt bearbeitet:",
      en: "This case was edited as follows:",
      nl: "De issue werd als volgt bewerkt:",
    },

    //Comment
    commentCreated: {
      de: "hat einen Kommentar hinzugefügt ",
      en: "has added a new comment ",
      nl: "heeft een commentaar toegevoegd ",
    },
    commentEdited: {
      de: "Ein Kommentar wurde wie folgt bearbeitet:",
      en: "A comment was edited as follows:",
      nl: "Een commentaar werd als volgt bewerkt:",
    },
    commentDeleted: {
      de: "einen Kommentar gelöscht ",
      en: "deleted a comment ",
      nl: "verwijderde een commentaar ",
    },
    //Solution

    solutionCreated: {
      de: "hat die Lösung $ hinzugefügt ",
      en: "has added solution $ ",
      nl: "heeft afhandelstrategie $ toegevoegd ",
    },
    solutionEdited: {
      de: "Eine Lösung wurde wie folgt bearbeitet:",
      en: "A solution was edited as follows:",
      nl: "Een afhandelstrategie werd als volgt bewerkt:",
    },
    solutionDeleted: {
      de: "einen Kommentar gelöscht $ ",
      en: "deleted solution $ ",
      nl: "verwijderde een commentaar $",
    },
    solutionFinalField: {
      de: "hat die Lösung $ als final markiert ",
      en: "has marked solution $ as final ",
      nl: "heeft de afhandelstrategie $ geverifieerd ",
    },
    solutionNotFinal: {
      de: "hat die Lösung $ als nicht final markiert ",
      en: "has marked solution $ as not final ",
      nl: "heeft de verificatie van de afhandelstrategie $ geannuleerd ",
    },

    //Task
    taskCreated: {
      de: "hat eine neue Aufgabe hinzugefügt ",
      en: "has added a new task ",
      nl: "heeft een nieuwe taak toegevoegd ",
    },
    taskEdited: {
      de: "Eine Aufgabe wurde wie folgt bearbeitet:",
      en: "A task was edited as follows:",
      nl: "Een taak werd als volgt bewerkt:",
    },
    taskDeleted: {
      de: "hat eine Aufgabe gelösch ",
      en: "deleted a task ",
      nl: "heeft een taak verwijderd ",
    },
    taskCompletedWithNote: {
      de: "hat eineAufgabe mit der folgenden Notiz abgeschlossen: $, ",
      en: "completed a task with the following note: $, ",
      nl: "heeft een taak voltooid met de volgende aantekening: $, ",
    },
    taskCompletedWithoutNote: {
      de: "hat eine Aufgabe erledigt ",
      en: "completed a task ",
      nl: "heeft een taak voltooid ",
    },
    taskUncompleted: {
      de: "hat eine Aufgabe wieder eröffne ",
      en: "uncompleted a task ",
      nl: "heeft een taak heropen ",
    },

    //Internal Item

    internalItemCreated: {
      de: "hat einen internen Artikel hinzugefügt: $",
      en: "has created a new internal item: $",
      nl: "has created a new internal item: $",
    },
    internalItemEdited: {
      de: "Eine internen Artikel wurde wie folgt bearbeitet:",
      en: "An internal item was edited as follows:",
      nl: "An internal item was edited as follows:",
    },
    internalItemDeleted: {
      de: "gelöscht internen Artikel ",
      en: "deleted an internal item ",
      nl: "deleted an internal item ",
    },

    //Impact

    impactCreated: {
      de: "hat eine Auswirkung $ hinzugefügt ",
      en: "has added impact $ to the case ",
      nl: "heeft impact $ toegevoegd aan de zaak ",
    },
    impactEdited: {
      de: "Eine Auswirkung wurde wie folgt bearbeitet:",
      en: "An impact was edited as follows:",
      nl: "Een impact werd als volgt bewerkt:",
    },
    impactDeleted: {
      de: "gestrichene Auswirkungen $ aus dem Fall ",
      en: "deleted impact $ from the case ",
      nl: "schrapte impact $ van de zaak ",
    },
    assemblyDeleted: {
      de: "hat die Zuordnung von $ vom Fall aufgehoben.",
      en: "has unassigned $ to the case.",
      nl: "heeft $ uit het Issue verwijderd.",
    },
    assemblyAdded: {
      de: "hat $ zum Fall zugeordnet.",
      en: "has assigned $ to the case.",
      nl: "heeft $ toegewezen aan het Issue.",
    },

    //Train
    trainCreated: {
      de: "hat den Zug $ zum Fall zugeordnet ",
      en: "has assigned $ to the case ",
      nl: "heeft materieelserie $ toegewezen aan het Issue ",
    },
    trainDeleted: {
      de: "hat die Zuordnung des Zuges $ vom Fall aufgehoben ",
      en: "has unassigned $ from the case ",
      nl: "heeft de toewijzing van de materieelserie $ uit het Issue verwijderd ",
    },

    //Part
    partCreated: {
      de: "erstellte den Artikel ",
      en: "created the item ",
      nl: "creëerde het item ",
    },
    partEdited: {
      de: "This item was edited as follows:",
      en: "This item was edited as follows:",
      nl: "This item was edited as follows:",
    },

    //Manufacturer
    manufacturerCreated: {
      de: "hat einen Hersteller erstellt ",
      en: "created the manufacturer ",
      nl: "heeft een fabrikant aangemaakt ",
    },
    manufacturerEdited: {
      de: "Dieser Hersteller wurde wie folgt bearbeitet:",
      en: "This manufacturer was edited as follows:",
      nl: "Deze fabrikant is als volgt bewerkt:",
    },
    manufacturerAdded: {
      de: "hat den neuen Hersteller hinzugefügt $ ",
      en: "added the new manufacturer $ ",
      nl: "heeft een nieuwe fabrikant $ toegevoegd ",
    },

    manufacturerRemoved: {
      de: "hat den Hersteller $ gelöscht ",
      en: "deleted the manufacturer $ ",
      nl: "heeft de $ verwijderd ",
    },

    //SVHC Item added
    svhcItemAdded: {
      de: "einen neuen svhc-Artikel hinzugefügt ",
      en: "added a new svhc item ",
      nl: "een nieuw svhc item toegevoegd ",
    },
    svhcItemDeleted: {
      de: "einen svhc-Artikel gelöscht ",
      en: "deleted a svhc item ",
      nl: "een svhc item verwijderd ",
    },

    partDeleted: {
      de: "einen Artikel aus der Stückliste gelöscht",
      en: "deleted item $ from BOM",
      nl: "een item uit de BOM verwijderd",
    },

    partAdded: {
      de: "einen Einheit zur Stückliste hinzugefügt",
      en: "added an item to BOM",
      nl: "added an item to BOM",
    },

    //RM Message
    messageCreated: {
      de: "hat eine neue Notiz hinzugefügt ",
      en: "added a new note ",
      nl: "heeft een nieuw notitie toegevoegd ",
    },
    messageEdited: {
      de: "Eine Notiz wurde wie folgt bearbeitet:",
      en: "A note was edited as follows:",
      nl: "Een notitie werd als volgt bewerkt:",
    },
    messageDeleted: {
      de: "eine Notiz gelöscht ",
      en: "deleted a note ",
      nl: "verwijderde een notitie ",
    },

    //Attachment
    attachemnetAdded: {
      de: "eine neue Anlage hinzugefügt ",
      en: "added a new attachment ",
      nl: "een nieuwe bijlage toegevoegd ",
    },
    attachemnetDeleted: {
      de: "eine Anlage gelöscht ",
      en: "deleted an attachment ",
      nl: "verwijderde een bijlage ",
    },
    attachemnetAddedOnField: {
      de: "eine neue Anlage zum Feld $ hinzugefügt ",
      en: "added a new attachment on field $ ",
      nl: "een nieuwe bijlage toegevoegd op veld $ ",
    },
    attachemnetDeletedFromField: {
      de: "einen Anhang aus dem Feld $ gelöscht ",
      en: "deleted an attachment from field $ ",
      nl: "een bijlage uit veld $ verwijderd  ",
    },
    tagAdded: {
      de: "hat die Beschriftung $ zum Anhang & ",
      en: "added tag $ for attachment & ",
      nl: "heeft het labe $ toegevoegd aan de bijlage & ",
    },
    tagDeleted: {
      de: "hat die Beschriftung $ vom Anhang &",
      en: "deleted tag $ from attachment &",
      nl: "heeft het label $ verwijderd uit de bijlag &",
    },

    //History table columns
    date: { de: "Datum", en: "Date", nl: "Datum" },
    userName: { de: "Benutzername", en: "User Name", nl: "Gebruikersnaam" },
    fieldName: { de: "Feldname:", en: "Field Name:", nl: "Veldnaam:" },
    oldValue: { de: "Alter Wert: ", en: "Old value: ", nl: "Vorige waarde:" },
    newValue: { de: "Neuer Wert: ", en: "New value: ", nl: "Nieuwe waarde:" },

    // DB History signing
    // caseSigned,caseSignedAndStatusChanged, impactSigned & impactSignedAndStatusChanged are needed for the history records created before march 2024
    caseSigned: {
      de: "den Fall unterschrieben",
      en: "signed the case",
      nl: "ondertekende de zaak",
    },
    caseSignedAndStatusChanged: {
      de: "unterzeichnete den Fall und der Status wurde von $ auf & geändert",
      en: "signed the case and status was changed from $ to &",
      nl: "ondertekende de zaak en de status werd veranderd van $ in &",
    },
    impactSigned: {
      de: "unterzeichnete die Fahrzeuge für $",
      en: "signed the vehicles for $",
      nl: "ondertekende de voertuigen voor $",
    },
    impactSignedAndStatusChanged: {
      de: "unterzeichnete die Fahrzeuge für $ und der Fallstatus wurde von & auf # geändert",
      en: "signed the vehicles for $ and case status was changed from & to #",
      nl: "tekende de voertuigen voor $ en de status werd veranderd van & naar #",
    },
  },
  list: {
    columns: empty,
  },
  url: {},
  field,
};

export const history: ComponentSettings<typeof settings> = settings;
