<div class="col-md-12 px-1">
  <div class="d-flex justify-content-center">
    <button
      class="btn btn-reset col text-center mt-4 mb-2"
      (click)="
        app.filterList.clear();
        app.filterList.open = true;
        app.filterFields.addQuickFilter('showAllCases')
      "
      [disabled]="app.filterList.fields.length <= 1"
    >
      <i class="fas fa-redo-alt"></i>
      {{ app.text.RM.resetFilter }}
    </button>
  </div>

  <app-frame
    *ngFor="let box of boxes"
    [title]="getHeaderText(box)"
    [changeHeader]="true"
    [filterBox]="true"
  >
    <ul class="list p-2">
      <li
        *ngFor="let field of app.filterList.getBoxFields(box)"
        class="pb-2 d-flex"
      >
        <i
          *ngIf="!isFilterSelected(field) && app.filterList.field != field"
          class="fal fa-circle icon-size p-1 pointer"
          (click)="setField(field)"
        ></i>
        <i
          *ngIf="isFilterSelected(field) || app.filterList.field == field"
          class="far fa-dot-circle icon-size selectedFilter p-1"
        ></i>
        <a
          (click)="setField(field)"
          class="margin-top-3"
          [ngClass]="{
            selectedFilter: isFilterSelected(field),
            unSelected: !isFilterSelected(field)
          }"
          style="cursor: pointer"
        >
          {{ app.field.getLabel(field) }}
        </a>
      </li>
    </ul>
  </app-frame>
</div>
