<input
  #fileInput
  type="file"
  style="display: none"
  (change)="uploadDocOnField()"
  multiple
/>
<app-modal
  #deleteAttachmentModal
  [modalMessage]="app.text.attachments.deleteQuestion"
  [modalTitle]="app.text.attachments.modalTitle"
  (ok)="app.field.deleteDocAttachedToField()"
></app-modal>
<div
  [ngClass]="{
    odd: (index % 2) + 1 === 1 && showLabel && !noStriped,
    lightGrey: lightOddField
  }"
  (click)="onFieldClicked($event)"
>
  <div
    class="row app-field"
    [ngClass]="{ 'no-gutters': shadowInput || isFinalSolutin }"
  >
    <div
      *ngIf="showLabel"
      [title]="app.field.getTooltip(field)"
      [ngClass]="getLabelStyle()"
    >
      <div class="fieldName">
        {{ app.field.getLabel(field) }}
        <span *ngIf="app.field.getHelperText(field) !== ''" class="helperText">
          {{ app.field.getHelperText(field) }}</span
        >
        <!-- <span
          *ngIf="showMandatory || (!disabled && app.field.isRequired(field))"
          class="highlight-text"
          ><b>*</b></span
        > -->

        <!-- <span
          *ngIf="publicFieldName != null"
          (click)="togglePublicFieldName()"
          class="publicFieldMark"
        >
          <span *ngIf="isPublic()"><i class="fas fa-check-square"></i></span>
          <span *ngIf="!isPublic()"><i class="far fa-square"></i></span>
        </span> -->
      </div>
      <div *ngIf="!disabled" class="float-end">
        <div *ngIf="app.field.isMultiple(field)" class="highlight-text">
          {{ app.text.field.multiselect }}
        </div>
      </div>
    </div>
    <div
      [ngClass]="getInputStyle()"
      [ngSwitch]="app.field.getType(field)"
      (click)="click(field); app.field.current = field"
      id="{{ field }}"
    >
      <div
        *ngIf="labelInside"
        class="fieldLabel custom-label"
        [title]="app.field.getTooltip(field)"
        [hidden]="hideLabel(model[field])"
      >
        <div
          class="custom-field"
          [ngClass]="{
            fieldName: !orangeField,
            orangeLabel: orangeField
          }"
        >
          {{ app.field.getLabel(field) }}
          <!-- <span
            *ngIf="showMandatory || (!disabled && app.field.isRequired(field))"
            class="highlight-text"
            ><b>*</b></span
          > -->
        </div>
        <div *ngIf="!disabled" class="multiselect">
          <div *ngIf="app.field.isMultiple(field)" class="highlight-text">
            {{ app.text.field.multiselect }}
          </div>
        </div>
      </div>

      <div
        class="fieldValue"
        [ngClass]="createClass()"
        [title]="app.field.getTooltip(field)"
      >
        <!-- DATE -->
        <div
          *ngSwitchCase="'date'"
          class="lineHeight dateContainer"
          [ngClass]="{
            underline: showUnderline(),
            'underline-red': isMandatory()
          }"
        >
          <form class="form-inline dateField">
            <div class="form-group">
              <div class="input-group">
                <input
                  *ngIf="!isDisabled()"
                  class="form-control input-position"
                  name="d"
                  #c2="ngModel"
                  [(ngModel)]="model[field]"
                  ngbDatepicker
                  #d="ngbDatepicker"
                  (blur)="isValidDate(model[field])"
                />
              </div>

              <span
                class="dateBtn dateInput"
                *ngIf="model[field] !== '' && model[field] != null"
              >
                <button
                  *ngIf="!isDisabled()"
                  class="btn btn-sm"
                  (click)="clearModel(field)"
                >
                  {{ model[field] }}&nbsp;
                  <i class="fas fa-times-circle app-subtle displayIcon"> </i>
                </button>
                <button
                  *ngIf="isDisabled()"
                  class="btn btn-sm"
                  [ngStyle]="app.field.getDateColor(field, model)"
                >
                  {{ model[field] }}
                </button>
              </span>
            </div>
          </form>
          <div class="clickable-area">
            <button
              class="btn btn-sm m-0 m-0 pull-right expand-clickable-area"
              *ngIf="!isDisabled()"
              (click)="d.toggle(); setMultipleFocus(field)"
            >
              <i class="fa fa-calendar pull-right"></i>
            </button>
          </div>
          <button
            *ngIf="
              differentDisplay && model[field] != null && model[field] !== ''
            "
            class="btn btn-sm m-0 m-0 pull-right"
            type="button"
            (click)="app.filterList.add()"
          >
            <i class="fa fa-search"></i>
          </button>
          <span class="invalidspan me-4" *ngIf="isMandatory()"
            ><i
              class="fal fa-exclamation-circle"
              tooltipClass="customTooltip"
              [ngbTooltip]="app.text.field.requiredIcon"
            ></i
          ></span>
        </div>
        <!-- TIMESTAMP -->
        <div *ngSwitchCase="'timestamp'">
          <span *ngIf="model[field] != ''">
            {{ model[field] | date : "yyyy-MM-dd HH:mm:ss" }}
          </span>
        </div>
        <!-- DATA -->
        <span *ngSwitchCase="'data'"> {{ model[field] | json }} </span>
        <!-- CHECKBOX -->
        <span *ngSwitchCase="'checkbox'" [title]="app.field.getTooltip(field)">
          <i
            [ngClass]="{ finalSol: isFinalSolutin }"
            *ngIf="model[field] === true"
            class="far fa-check-square mt-2"
          ></i>
          <i *ngIf="model[field] !== true" class="far fa-square mt-2"></i>
        </span>
        <!-- TEXT -->
        <div
          class="no-scroll"
          *ngSwitchCase="'text'"
          [ngClass]="{
            content: !differentDisplay
          }"
        >
          <div class="wrapper">
            <textarea
              *ngIf="isEnabled() && !differentDisplay"
              class="form-control m-0"
              [name]="field"
              [ngClass]="{
                underline: showUnderline(),
                'underline-red': isMandatory(),
                'maxHeight-30': getTextrareaRows(field) === 1,
                'minHeight-85': getTextrareaRows(field) === 4
              }"
              [(ngModel)]="model[field]"
              [disabled]="isDisabled()"
              [maxLength]="calculateMaxLength(field)"
              (keyup)="app.productCategory.searchWhileTyping(model[field])"
              [rows]="getTextrareaRows(field)"
              (focus)="setMultipleFocus(field)"
              autosize
            ></textarea>
            <span class="invalidspan" *ngIf="isMandatory()"
              ><i
                class="fal fa-exclamation-circle"
                tooltipClass="customTooltip"
                [ngbTooltip]="app.text.field.requiredIcon"
              ></i
            ></span>
          </div>
          <slot>
            <div
              *ngIf="!isEnabled()"
              class="fake-textarea"
              [innerHtml]="app.field.highlightLinks(model[field])"
            ></div>
          </slot>
          <div class="input-group" *ngIf="differentDisplay">
            <div class="input-group-prepend">
              <button
                class="btn btn-sm selected selectedFilter"
                (click)="
                  app.filterList.field = null; app.filterList.open = true
                "
                *ngIf="app.filterList.field != null"
                type="button"
              >
                {{ app.field.getLabel(app.filterList.field) }}
                <i class="fa fa-times app-subtle"></i>
              </button>
            </div>
            <input
              [maxLength]="calculateMaxLength(field)"
              (focus)="searchFocus = true"
              (blur)="blur()"
              class="form-control m-0 lineHeight py-2 border"
              [ngClass]="{
                'underline-red': isMandatory(),
                underline: showUnderline()
              }"
              type="text"
              [name]="field"
              [(ngModel)]="model[field]"
              [disabled]="isDisabled()"
            />
            <span class="input-group-append" *ngIf="differentDisplay">
              <button
                class="btn btn-outline-secondary border-left-0 border"
                type="button"
                (click)="app.filterList.add()"
              >
                <i class="fa fa-search"></i>
                <span>&nbsp;{{ app.text.app.search }}</span>
              </button>
            </span>
          </div>
        </div>
        <!-- PASSWORD -->
        <div *ngSwitchCase="'password'">
          <div class="wrapper">
            <input
              class="form-control m-0"
              [ngClass]="{
                underline: showUnderline()
              }"
              type="password"
              [name]="field"
              [(ngModel)]="model[field]"
              [disabled]="isDisabled()"
            />
            <span class="invalidspan" *ngIf="isMandatory()"
              ><i
                class="fal fa-exclamation-circle"
                tooltipClass="customTooltip"
                [ngbTooltip]="app.text.field.requiredIcon"
              ></i
            ></span>
          </div>
        </div>
        <!-- OPTIONS -->
        <div
          *ngSwitchCase="'options'"
          [ngClass]="{
            underline: showUnderline(),
            'underline-red': isMandatory()
          }"
          class="lineHeight"
        >
          <div *ngIf="!searchFocus || app.field.isMultipleSelected">
            <button
              class="btn btn-sm selected selectedFilter"
              (click)="app.filterList.field = null; app.filterList.open = true"
              *ngIf="app.filterList.field != null && differentDisplay"
              type="button"
            >
              {{ app.field.getLabel(app.filterList.field) }}
              <i class="fa fa-times app-subtle"></i>
            </button>
            <button
              *ngIf="!isDisabled() && showSearchButton"
              class="btn btn-sm pull-right"
              (click)="startSearch()"
            >
              <i *ngIf="!nonSearchableDropdowns" class="fa fa-search"></i>
              <span *ngIf="differentDisplay"
                >&nbsp;{{ app.text.app.search }}</span
              >
            </button>
            <button
              class="btn btn-sm btn-light pull-right show-all-btn"
              *ngIf="
                app.customer === 'coop' &&
                field === 'thread.productCategory' &&
                app.unlockedId === app.thread.id
              "
              (click)="app.productCategory.showAllCategories()"
            >
              Show all
            </button>
            <span *ngFor="let option of app.field.getOptions(field)">
              <span
                class="dateBtn"
                *ngIf="app.field.isOptionSelected(field, option, model)"
                [ngClass]="{ noEvents: !app.thread.isEditMode() }"
              >
                <button
                  *ngIf="!isDisabled()"
                  class="btn btn-sm"
                  id="{{ field }}"
                  (click)="
                    $event.stopPropagation();
                    app.field.toggleOption(field, option, model)
                  "
                >
                  {{ app.field.getOptionText(field, option) }}
                  <i
                    *ngIf="!isDisabled()"
                    class="fas fa-times-circle app-subtle displayIcon"
                    (click)="clearModel(field, option)"
                  ></i>
                </button>
                <button
                  *ngIf="isDisabled() && !getTreeStyle(field)"
                  class="btn btn-sm"
                  id="{{ field }}"
                  [ngStyle]="null"
                >
                  {{ app.field.getOptionText(field, option) }}
                </button>
                <span
                  *ngIf="isDisabled() && getTreeStyle(field)"
                  class="badge text-center fieldValue-smaller"
                  [ngStyle]="app.field.getColor(field, option)"
                >
                  {{ app.field.getOptionText(field, option) }}
                </span>
                <button
                  *ngIf="getFieldType(field, model[field]) === responsible"
                  [ngbTooltip]="app.text.impact.wfResponsible"
                  class="btn responsible-bullet"
                  id="{{ field }}"
                >
                  <i
                    class="fad fa-user-times"
                    *ngIf="
                      app.impact.displayBullet(field, red) &&
                      model[field] !== '' &&
                      app.unlockedId !== app.thread.id
                    "
                  ></i>
                  <i
                    class="fad fa-user-times fa-deputy"
                    *ngIf="
                      app.impact.displayBullet(
                        app.fieldId.thread.dinCodeRespName,
                        red
                      ) &&
                      field === app.fieldId.thread.deputy &&
                      model[app.fieldId.thread.deputy] !== '' &&
                      app.unlockedId !== app.thread.id
                    "
                  >
                  </i>
                </button>
              </span>
            </span>
            <!-- Show model[field] (value saved in database) if the options array does not contain that value anymore -->
            <span
              *ngIf="displayValue(field, model[field])"
              class="lineHeight-28"
            >
              {{ model[field] }}
            </span>
          </div>
          <div
            class="card m-0 p-0"
            [ngStyle]="{
              display:
                searchFocus && !app.field.isMultipleSelected ? 'block' : 'none'
            }"
          >
            <input
              autocomplete="off"
              #searchField
              class="form-control m-0"
              [ngClass]="{
                underline: showUnderline(),
                'underline-red': isMandatory()
              }"
              name="search"
              [(ngModel)]="search"
              (focus)="searchFocus = true"
              [disabled]="
                (searchFocus === true && nonSearchableDropdowns) ||
                app.field.isMultipleSelected
              "
              (blur)="blur()"
            />
          </div>
          <span
            class="invalidspan"
            *ngIf="
              !app.field.isValid(field, model[field]) &&
              app.field.isRequired(field) &&
              app.filterList.field == null &&
              !searchFocus
            "
            ><i
              class="fal fa-exclamation-circle"
              tooltipClass="customTooltip"
              [ngbTooltip]="app.text.field.requiredIcon"
            ></i>
          </span>
          <div *ngIf="searchFocus && !isMultipleField(field)">
            <app-field-options
              [field]="field"
              [model]="model"
              [searchText]="searchField.value"
              (selected)="onOptionSelected()"
            ></app-field-options>
          </div>
        </div>
        <div *ngIf="field === 'thread.applicationArea' && app.unlockedId">
          <app-application-area></app-application-area>
        </div>
        <!-- RADIO -->
        <div *ngSwitchCase="'radio'" class="radio_input">
          <div
            *ngFor="let option of app.field.getOptions(field)"
            class="radio_input_value"
          >
            <span *ngIf="!differentDisplay">
              <input
                type="radio"
                [name]="field"
                value="{{ option }}"
                [(ngModel)]="model[field]"
                [disabled]="isDisabled() || hasPermissionToEdit()"
              />
            </span>
            <span *ngIf="differentDisplay">
              <input
                type="radio"
                [name]="field"
                value="{{ option }}"
                [(ngModel)]="model[field]"
                (click)="onItemSelected(option, field)"
                [disabled]="isDisabled() || hasPermissionToEdit()"
              />
            </span>
            <label>{{ app.field.getOptionText(field, option) }}</label>
          </div>
          <span class="invalidspan" *ngIf="isMandatory()"
            ><i
              class="fal fa-exclamation-circle"
              tooltipClass="customTooltip"
              [ngbTooltip]="app.text.field.requiredIcon"
            ></i
          ></span>
        </div>

        <!-- TYPE NUMBER  -->

        <div *ngSwitchCase="'number'">
          <span
            *ngIf="
              !showLabel && app.field.isRequired(field) && !differentDisplay
            "
            class="pull-left changeColor"
            >*
          </span>
          <span *ngIf="!differentDisplay">
            <input
              [maxlength]="this.app.field.getFieldSettings(field).maxLength"
              (focus)="searchFocus = true; setMultipleFocus(field)"
              (blur)="blur()"
              class="form-control m-0 lineHeight"
              [ngClass]="{
                underline: showUnderline(),
                'underline-red': isMandatory()
              }"
              type="number"
              [name]="field"
              [(ngModel)]="model[field]"
              [disabled]="isDisabled()"
            />
            <span
              *ngIf="this.app.field.getFieldSettings(field).unit"
              class="fieldUnit"
              >{{
                this.app.field.getFieldSettings(field).unit[this.app.language]
              }}</span
            >
          </span>
          <div class="input-group" *ngIf="differentDisplay">
            <input
              [maxlength]="this.app.field.getFieldSettings(field).maxLength"
              (focus)="searchFocus = true; setMultipleFocus(field)"
              (blur)="blur()"
              class="form-control m-0 lineHeight border-light"
              [ngClass]="{
                underline: showUnderline(),
                'underline-red': isMandatory()
              }"
              type="number"
              [name]="field"
              [(ngModel)]="model[field]"
              [disabled]="isDisabled()"
            />
            <span class="input-group-append">
              <button
                *ngIf="differentDisplay"
                class="btn btn-outline-secondary border-left-0 border"
                type="button"
                (click)="app.filterList.add()"
              >
                <i class="fa fa-search"></i>
                <span>&nbsp;{{ app.text.app.search }}</span>
              </button>
            </span>
          </div>
          <span
            class="invalidspan"
            *ngIf="isMandatory() && !differentDisplay && showLabel"
            ><i
              class="fal fa-exclamation-circle"
              tooltipClass="customTooltip"
              [ngbTooltip]="app.text.field.requiredIcon"
            ></i
          ></span>
        </div>
        <div *ngSwitchCase="'typeahead'">
          <div class="searchContainer" *ngIf="differentDisplay">
            <button
              class="btn btn-sm selected selectedFilter"
              (click)="app.filterList.field = null; app.filterList.open = true"
              *ngIf="app.filterList.field != null"
              type="button"
            >
              {{ app.field.getLabel(app.filterList.field) }}
              <i class="fa fa-times app-subtle"></i>
            </button>
            <app-typeahead-basic
              class="typeahead"
              [field]="field"
              [docModel]="model"
              [maxLength]="calculateMaxLength(field)"
              [placeholder]="typeaheadPlaceholder"
            ></app-typeahead-basic>
          </div>
          <div *ngIf="!differentDisplay">
            <app-typeahead-basic
              [field]="field"
              [docModel]="model"
              [maxLength]="calculateMaxLength(field)"
              [placeholder]="typeaheadPlaceholder"
            ></app-typeahead-basic>
          </div>
        </div>

        <!-- DEFAULT / VALUE -->
        <div *ngSwitchDefault>
          <span
            *ngIf="
              !showLabel &&
              !labelInside &&
              app.field.isRequired(field) &&
              !differentDisplay
            "
            class="pull-left changeColor"
            >*</span
          >
          <!-- <div
            *ngIf="
              app.unlockedId != null && app.fieldId.thread.cageCode === field
            "
          >
            <span
              class="badge badge-light"
              *ngFor="let code of app.field.manufacturerCodes"
              ><a
                (click)="app.field.setManufacturerCode(code)"
                class="pointer dark-text sm-text"
              >
                {{ code }} &nbsp;
              </a></span
            >
          </div> -->
          <div
            class="input-group mb-3"
            *ngIf="
              getFieldType(field, model[field]) === specialCharacter &&
              !differentDisplay
            "
          >
            <div class="input-group-prepend hashTagSign">#</div>
            <input
              [maxLength]="calculateMaxLength(field)"
              (focus)="searchFocus = true; setMultipleFocus(field)"
              (blur)="blur()"
              class="form-control m-0 lineHeight"
              [ngClass]="{
                underline: showUnderline(),
                'underline-red': isMandatory()
              }"
              type="text"
              [name]="field"
              [(ngModel)]="model[field]"
              [disabled]="isDisabled()"
            />
          </div>
          <div class="input-group" *ngIf="differentDisplay">
            <div class="input-group-prepend">
              <button
                class="btn btn-sm selected selectedFilter"
                (click)="
                  app.filterList.field = null; app.filterList.open = true
                "
                *ngIf="app.filterList.field != null"
                type="button"
              >
                {{ app.field.getLabel(app.filterList.field) }}
                <i class="fa fa-times app-subtle"></i>
              </button>
            </div>
            <input
              [maxLength]="calculateMaxLength(field)"
              (focus)="searchFocus = true; setMultipleFocus(field)"
              (blur)="blur()"
              class="form-control m-0 lineHeight py-2 border"
              [ngClass]="{
                underline: showUnderline(),
                'underline-red': isMandatory()
              }"
              type="text"
              [name]="field"
              [(ngModel)]="model[field]"
              [disabled]="isDisabled()"
            />
            <span class="input-group-append">
              <button
                *ngIf="differentDisplay"
                class="btn btn-outline-secondary border-left-0 border"
                type="button"
                (click)="app.filterList.add()"
              >
                <i class="fa fa-search"></i>
                <span>&nbsp;{{ app.text.app.search }}</span>
              </button>
            </span>
          </div>
          <div class="wrapper">
            <!-- <button
              *ngIf="
                app.fieldId.thread.cageCode === field &&
                app.field.manufacturerCodes.length > 1
              "
              class="btn btn-sm"
              (click)="clearModel(field)"
            >
              {{ model[field] }}&nbsp;
              <i class="fas fa-times-circle app-subtle displayIcon"> </i>
            </button> -->
            <input
              *ngIf="
                getFieldType(field, model[field]) === normal &&
                !differentDisplay &&
                !app.field.isHyperLink(field, model[field])
              "
              [maxLength]="calculateMaxLength(field)"
              (focus)="searchFocus = true; setMultipleFocus(field)"
              (blur)="blur()"
              (keyup)="app.field.isValidRegex(field, model[field])"
              class="form-control m-0 lineHeight"
              [ngClass]="{
                underline: showUnderline(),
                'underline-red': isMandatory(),
                orangeInput: orangeField
              }"
              type="text"
              [name]="field"
              [(ngModel)]="model[field]"
              [disabled]="isDisabled()"
            />
            <span
              class="invalidspan"
              *ngIf="isMandatory() && !orangeField && showLabel"
              ><i
                class="fal fa-exclamation-circle"
                tooltipClass="customTooltip"
                [ngbTooltip]="app.text.field.requiredIcon"
              ></i
            ></span>
          </div>
          <div
            *ngIf="
              app.field.hasRegex(field) &&
              !app.field.isValidRegex(field, model[field])
            "
            class="highlight-text"
          >
            {{ app.text.field.emailFormat }}
          </div>
          <a
            class="lineHeight-26 link"
            *ngIf="app.field.isHyperLink(field, model[field])"
            (click)="app.routing.openInNewTab(null, field, model[field])"
            >{{ model[field] }}</a
          >
          <a
            *ngIf="getFieldType(field, model[field]) === externalFile"
            class="externalFile-icon"
            target="_blank"
            [href]="model[field]"
          >
            <i class="fas fa-file pt-2"></i>
          </a>
          <button
            *ngIf="getFieldType(field, model[field]) === file"
            class="externalFile-icon downloadFile"
            (click)="downloadFile(field)"
          >
            <i class="fas fa-file pt-2"></i>
        </button>
          <span
            *ngIf="getFieldType(field, model[field]) === upload"
            class="btn p-0"
            [ngClass]="{ noEvents: app.unlockedId != null }"
            (click)="fileInput.click()"
          >
            <i class="fa fa-upload"></i>
          </span>
          <div
            *ngIf="getFieldType(field, model[field]) === attachment"
            class="btn p-0 col-md-12"
          >
            <a
              *ngIf="app.field.isLink(model[field])"
              class="attachment-link pull-left"
              target="_blank"
              [href]="model[field]"
            >
              {{ app.attachment.getDocNameByFieldId(field) }}
            </a>
            <span
              *ngIf="!app.field.isLink(model[field])"
              class="attachment-link pull-left"
              (click)="downloadDocAttachedToField(field)"
            >
              {{ app.attachment.getDocNameByFieldId(field) }}
            </span>
            <span class="float-end pe-1" (click)="deleteAttachmentModal.open()">
              <i class="fa fa-trash"></i>
            </span>
          </div>
          <span
            *ngIf="getFieldType(field, model[field]) === modal"
            class="btn p-0"
          >
            <i class="fas fa-clone"></i>
          </span>
          <span
            *ngIf="this.app.field.getFieldSettings(field).unit && model[field]"
            class="fieldUnit"
            >{{
              this.app.field.getFieldSettings(field).unit[this.app.language]
            }}
          </span>
          <app-field-options
            *ngIf="searchFocus"
            [field]="field"
            [model]="model"
            [searchText]="model[field]"
            (selected)="selectOption($event)"
          ></app-field-options>
        </div>
      </div>
    </div>
    <!-- display message if there are cases created from the current selected cpn/mpn currently available for NS only -->
    <span class="col-md-12 d-flex existingCasesText" *ngIf="
            app.fieldId.thread.artNumber === field &&
            app.field.displayCassesPerCpn &&
            !differentDisplay &&
            model[field]
          ">
      <small class="p-1 ps-1 ms-2" *ngIf="app.thread.existingCasesPerCpn.length !== 0"
        [ngClass]="{ 'show-less': isShortened }">
        {{ app.text.thread.casesCreatedByCPN }}
        <span *ngFor="let case of app.thread.existingCasesPerCpn" class="badge badge-sm pointer">
          &nbsp;<a class="font-weight-bold navLink" (click)="app.routing.navigateThread(case.id)"
            [routerLink]="navigateToCase(case.id)">{{ case.omfNumber }}</a>&nbsp;
        </span>
      </small>
      <button *ngIf="app.thread.existingCasesPerCpn.length > 6" class="btn btn-sm isText" type="button"
        (click)="showMore(); $event.stopPropagation()" [title]="app.text.thread.showMore">
        <i *ngIf="isShortened" class="fas fa-chevron-down"></i>
        <i *ngIf="!isShortened" class="fas fa-chevron-up"></i>
      </button>
    </span>
    <span class="col-md-12 existingCasesText" *ngIf="
            app.fieldId.thread.crtNumber === field &&
            app.field.displayCassesPerMpn &&
            !differentDisplay &&
            model[field]
          ">
      <small class="p-1 ps-1 ms-2" *ngIf="app.thread.existingCasesPerMpn.length !== 0"
        [ngClass]="{ 'show-less': isShortened }">
        {{ app.text.thread.duplicateCrtNumber }}
        <span *ngFor="let case of app.thread.existingCasesPerMpn" class="badge badge-sm pointer">
          &nbsp;<a class="font-weight-bold navLink" (click)="app.routing.navigateThread(case.id)"
            [routerLink]="navigateToCase(case.id)">{{ case.omfNumber }}</a>&nbsp;
        </span>
      </small>
      <button *ngIf="app.thread.existingCasesPerMpn.length > 6" class="btn btn-sm isText" type="button"
        (click)="showMore(); $event.stopPropagation()" [title]="app.text.thread.showMore">
        <i *ngIf="isShortened" class="fas fa-chevron-down icon-style"></i>
        <i *ngIf="!isShortened" class="fas fa-chevron-up icon-style"></i>
      </button>
    </span>
    <span class="col-md-12 existingCasesText" *ngIf="
            app.fieldId.thread.pcnID === field &&
            app.field.displayCassesPerPcnId &&
            !differentDisplay &&
            model[field]
          ">
      <small class="p-1 ps-1 ms-2" *ngIf="app.thread.existingCasesPerPcnId.length !== 0"
        [ngClass]="{ 'show-less': isShortened }">
        {{ app.text.thread.duplicatePcnId }}
        <span *ngFor="let case of app.thread.existingCasesPerPcnId" class="badge badge-sm pointer">
          &nbsp;<a class="font-weight-bold navLink" (click)="app.routing.navigateThread(case.id)"
            [routerLink]="navigateToCase(case.id)">{{ case.omfNumber }}</a>&nbsp;
        </span>
      </small>
      <button *ngIf="app.thread.existingCasesPerPcnId.length > 6" class="btn btn-sm px-3 isText" type="button"
        (click)="showMore(); $event.stopPropagation()" [title]="app.text.thread.showMore">
        <i *ngIf="isShortened" class="fas fa-chevron-down icon-style"></i>
        <i *ngIf="!isShortened" class="fas fa-chevron-up icon-style"></i>
      </button>
    </span>
    <!-- end -->
  </div>
</div>
