import * as countries from "i18n-iso-countries";
import { Options } from "../settings/settings";

export function getCountries(): Options {
  countries.registerLocale(require("i18n-iso-countries/langs/en.json"));
  countries.registerLocale(require("i18n-iso-countries/langs/de.json"));
  countries.registerLocale(require("i18n-iso-countries/langs/nl.json"));
  const result: any = {} as Options;
  const countryCode = countries.getAlpha2Codes();

  for (const code in countryCode) {
    const de = countries.getName(code, "de");
    const en = countries.getName(code, "en");
    const nl = countries.getName(code, "nl");
    const { text } = {
      text: {
        de: `${code} - ${de}`,
        en: `${code} - ${en}`,
        nl: `${code} - ${nl}`,
      },
    };
    result[code] = { text };
  }
  return result;
}
