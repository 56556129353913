import { PaginatorServiceType } from "./paginator.service.type";
import { AppType, APP_TYPE } from "../app.type";
import { Injectable, Inject } from "@angular/core";
import { BehaviorSubject } from "rxjs";

@Injectable()
export class PaginatorService implements PaginatorServiceType {
  updatePage: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  constructor(@Inject(APP_TYPE) private app: AppType) {}

  set focus(focus: number) {
    /** set the focus of the page based on the type of the document */
    let index = this.app.state.focusView.findIndex(
      (i) => i.type === this.app.type
    );

    if (index !== -1) {
      this.app.state.focusView[index].value = focus;
    } else {
      this.app.state.focusView.push({ type: this.app.type, value: focus });
    }
    this.app.state.next({ focus });
  }

  get focus() {
    let type = this.app.type != null ? this.app.type : "thread";
    /** get the focus of the page based on the type of the document & the previous focus */
    let index = this.app.state.focusView.findIndex((i) => i.type === type);
    if (index !== -1) {
      return this.app.state.focusView[index].value;
    } else {
      return 0;
    }
  }

  get pageSize() {
    return this.app.state.pageSize;
  }

  get pageSizes() {
    return [10, 25, 50, 100, 250];
  }

  set pageSize(pageSize: number) {
    this.app.state.next({ pageSize });
  }

  async setSize(size: number) {
    this.pageSize = size;
    let filters = this.app.filterHeaderTable.getCurrentFilters();

    switch (this.app.type) {
      case "manufacturer":
        if (filters.length > 0) {
          await this.app.filterHeaderTable.getManufacturerSearchResult();
        } else {
          if (this.app.RM.fromRMSearch) {
            this.app.manufacturersFilter.getFilterResultBasedOnView("1");
          } else {
            await this.app.manufacturersFilter.getManufacturers(
              this.app.RM.mode,
              this.app.state.page
            );
          }
        }
        break;
      case "part":
        if (this.app.RM.mode !== "tree") {
          if (filters.length > 0) {
            await this.app.filterHeaderTable.getPartSearchResult();
          } else {
            await this.app.partsTable.getParts(this.app.RM.mode);
          }
        } else {
          if (Object.keys(this.app.filterTree.filterValues).length > 0) {
            await this.app.filterTree.getTreeSearchResult();
          } else {
            await this.app.tree.getItems(this.app.state.page);
          }
        }
        break;
      default:
        return;
    }
  }

  getPage<T>(elements: T[]) {
    const { pageSize, focus } = this;
    const { length } = elements;
    const page = Math.floor(focus / pageSize);
    const start = page * pageSize;
    const end = Math.min(length, (page + 1) * pageSize);
    return elements.slice(start, end);
  }

  getPageWithDoc() {
    let type = this.app.type;

    let ids: string[] = [];

    let elements: any[] = [];
    switch (type) {
      case "thread":
        ids = this.app.paginator.getPage(this.app.thread.ids);
        ids.forEach((id) => {
          elements.push(
            this.app.thread.threadsCompressed.find((t) => t._id === id)
          );
        });
        return elements;
      case "user":
        if (this.app.users.users.length > 0) {
          ids = this.app.paginator.getPage(this.app.users.userIds);
          ids.forEach((id) => {
            elements.push(this.app.users.users.find((u) => u.id === id));
          });
          if (this.app.table.sortField !== null) {
            elements = this.app.table.sort(elements);
          }
          return elements;
        }
      case "vehicleResponsible":
        ids = [];
        ids = this.app.paginator.getPage(this.app.vehicleResponsible.ids);
        ids.forEach((id) => {
          elements.push(
            this.app.vehicleResponsible.vehicles.find((v) => v._id === id)
          );
        });
        if (this.app.table.sortField !== null) {
          elements = this.app.table.sort(elements);
        }
        return elements;
      case "personProfile":
        ids = this.app.paginator.getPage(this.app.profile.ids);
        ids.forEach((id) => {
          elements.push(this.app.profile.profiles.find((p) => p.id === id));
        });
        if (this.app.table.sortField !== null) {
          elements = this.app.table.sort(elements);
        }
        return elements;
      case "impact":
        ids = [];
        ids = this.app.paginator.getPage(this.app.impacts.impactIds);
        ids.forEach((id) => {
          elements.push(this.app.impacts.impacts.find((i) => i._id === id));
        });
        if (this.app.table.sortField !== null) {
          elements = this.app.table.sort(elements);
        }
        return elements;
      case "pcnData":
        ids = this.app.paginator.getPage(this.app.manufacturerUtils.pcnIds);
        ids.forEach((id) => {
          elements.push(
            this.app.manufacturerUtils.pcnHistoryDocs.find(
              (i) => i._id === id && id !== undefined
            )
          );
        });
        return elements;
      default:
        return elements;
    }
  }

  resetPagination() {
    this.app.paginator.focus = 0;
  }
}
