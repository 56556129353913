import { Inject, Injectable } from "@angular/core";
import { AppType, APP_TYPE } from "../../../app/app.type";
import { Part } from "../../../../../shared/models/part";
import { DetailTableServiceType } from "./detail-table.service.type";

@Injectable()
export class DetailTableService implements DetailTableServiceType {
  docs: any[] = [];
  cleanDocs: any[] = [];
  selectedPartNumber: string = "";

  constructor(@Inject(APP_TYPE) private app: AppType) {}

  async getDocsByType(type: string) {
    this.docs = [];
    this.app.spinner.showSpinner();
    switch (type) {
      case "part":
        this.docs = await this.app.part.getBomParts(
          this.app.part.cleanPart[this.app.fieldId.part.parts]
        );
        this.docs.forEach((part: Part) => {
          part.quantity = String(this.getQuantity(part));
        });
        this.cleanDocs = this.docs;
        this.app.part.currentAssemblies = this.cleanDocs;
        break;
      case "manufacturer":
        const partId = this.app.part.id;
        if (partId == null) {
          return;
        }
        this.docs = await this.app.manufacturer.getManufacturersByPartNumber(
          partId,
          true
        );
        this.cleanDocs = this.docs;
        break;
      default:
        this.docs = [];
    }
    this.app.spinner.hideSpinner();
  }

  getQuantity(doc: Part) {
    if (this.app.part.cleanPart[this.app.fieldId.part.parts][doc._id] != null) {
      return this.app.part.cleanPart[this.app.fieldId.part.parts][doc._id];
    } else {
      return 1;
    }
  }

  async deleteBOMItem(docType: string) {
    let items: any = this.app.table.selected;
    let docIds: string[] = [];
    items.forEach((item: any) => {
      docIds.push(item["_id"]);
    });

    if (this.app.leftNav.selectedBox == "part.billOfMaterialSection") {
      let editedPart: Part = this.app.part.cleanPart;
      docIds.forEach(
        (id: string) => delete editedPart[this.app.fieldId.part.parts][id]
      );
      await this.app.part.save(this.app.part.cleanPart, editedPart);
      this.app.successText = this.app.text.operations.deleteDocsDescription;
      setTimeout(() => {
        this.app.state.next({ hasSuccess: false });
      }, 6000);
    } else {
      await this.app.operations.triggerWork("delete-docs", docType, docIds);
    }
    //build bom
    await this.app.operations.triggerWork("build-bom");
    //generate tree
    await this.app.tree.generateTree();

    // TODO: after the documents have been deleted the table needs to be updated
    //   await this.getDocsByType("part");
    //   this.app.filterTable.resetAllFilters.next(true);
  }
}
