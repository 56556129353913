import * as JSZip from "jszip";
import { typeCategories } from "../../../../shared/typeCategories";
import { StringUtils } from "../../../../shared/utils/string.utils";
import { AppType } from "../app.type";
import { pcnMapping } from "../pcn-generator/pcn-mapping";
import { typesMap } from "../pcn-generator/smart-pcn-mapping";
import { DocModel } from "../state/state";

class PCNBody {
  [key: string]: any;
  masterData: MasterData = new MasterData();
  difference: Difference = new Difference();
  itemNumbers: ItemNumbers = new ItemNumbers();
  pcnLifeCycleData: PcnLifeCycleData = new PcnLifeCycleData();
  pcnCauseOfChanges: PcnCauseOfChanges = new PcnCauseOfChanges();
  pcnSubStructures: PcnSubStructures = new PcnSubStructures();
}

class MasterData {
  pcnNumber: string = "";
  pcnTitle: string = "";
  pcnType: any = null;
  pcnMfrName: any = null;
  pcnRevision: string = "";
  pcnEmail: string = "";
  pcnCompany: string = "";
  pcnState: string = "";
  pcnStreet: string = "";
  pcnZipCode: string = "";
  pcnCity: string = "";
  pcnCountry: string = "";
  pcnIssueDate: string = "";
}

class Difference {
  pcnChangeTitle: string = "";
  pcnChangeIdentificationMethod: string = "";
  pcnChangeDetail: string = "";
}

class ItemNumber {
  [key: string]: any;
  itemMfrNumber: string = "";
  itemMfrTypeIdent: string = "";
  itemMfrName: string = "";
  itemRev: string = "";
  itemCategory: string = "";
  itemSubData: string = "";
  dinCode: string = "";
  dinText: string = "";
  artNumber: string = "";
  itemChangeText: string = "";
  itemChangeType: string[] = [];
  itemMfrReplNumber: string = "";
  itemSOP: string = "";
  itemEOPeffDate: string = "";
  itemEOS: string = "";
  itemLTD: string = "";
  itemEOSR: string = "";
}

class ItemChange {
  itemChangeText: string = "";
  itemChangeType: string = "";
}

class LifeCycle {
  itemSOP: string = "";
  itemEOS: string = "";
  itemEOPeffDate: string = "";
  itemLTD: string = "";
  itemEOSR: string = "";
  itemLCI: string = "";
}

class ItemNumbers {
  itemNumbers: ItemNumber[] = [];
}

class PcnLifeCycleData {
  pcnSOP: string = "";
  pcnEOS: string = "";
  pcnEOPeffDate: string = "";
  pcnLTD: string = "";
  pcnEOSR: string = "";
}

class PcnCauseOfChanges {
  pcnChangeTypes: PcnChange[] = [];
}
class PcnChange {
  pcnChangeType: string = "";
  pcnChangeDescription: string = "";
}

class PcnSubStructure {
  pcnSubRevision: string = "";
  pcnSubData: string = "";
  pcnSubType: string = "";
}
class PcnSubStructures {
  pcnSubStructures: PcnSubStructure[] = [];
}

class AttObject {
  [key: string]: any;
}

export const fieldNames = Object.keys(pcnMapping);

export function parseXML(xml: Document): DocModel {
  let pcnBody = new PCNBody();

  let sectionName: Element = {} as Element;

  /** possible sections found in the xml file */
  let xmlSections: string[] = [];
  let childNodes = xml.documentElement.childNodes;
  childNodes.forEach((child) => xmlSections.push(child.nodeName));

  /** for each section get the xml-section name */
  xmlSections.forEach((s: string) => {
    if (pcnBody[s] != null) {
      /** get the list of fields for the current section (masterData | difference | itemNumbers) */
      let currentKeys = Object.keys(pcnBody[s]);

      /** get the name of the section from the xml file in order to be able to set the value from it */
      sectionName = getSectionName(xml, s);

      /** get the values for each property within the current section */
      /**ex: for the master data, pass through all the fields and get only the values that exist in pcnBody.masterData object */
      fieldNames.forEach((fieldName: string) => {
        if (currentKeys.includes(fieldName)) {
          const textContent: string | null = getTextContent(
            sectionName,
            fieldName
          );
          pcnBody[s][fieldName] = textContent;
        }
      });
    }
  });

  /** set the values from the pcnBody object to a new document of type docModel-> pcn */
  let pcn = createPcnFromXml(xml, pcnBody, xmlSections);
  return pcn;
}

function createPcnFromXml(
  xml: Document,
  pcnBody: PCNBody,
  xmlSections: string[]
) {
  let pcn: DocModel = {};
  xmlSections.forEach((section) => {
    if (section === "masterData") {
      Object.keys(pcnBody["masterData"]).forEach((k) => {
        pcn[k] = (pcnBody["masterData"] as any)[k];
      });
      Object.keys(pcnBody["difference"]).forEach((k) => {
        pcn[k] = (pcnBody["difference"] as any)[k];
      });
    }
    if (section === "ItemNumbers") {
      /** get item numbers as a whole with the elements contained */
      let itemNumbersElement = xml.getElementsByTagName("ItemNumbers")[0];
      /** get all elements of type ItemNumber */
      let itemsContained =
        itemNumbersElement.getElementsByTagName("ItemNumber");
      /** set the result into the pcn.itemNumbers array */
      pcn["ItemNumbers"] = getItems(Array.from(itemsContained));
    }
    if (section === "pcnLifeCycleData") {
      Object.keys(pcnBody["pcnLifeCycleData"]).forEach((k) => {
        pcn[k] = (pcnBody["pcnLifeCycleData"] as any)[k];
      });
    }
    if (section === "pcnSubstructures") {
      let pcnSubStructuresElement =
        xml.getElementsByTagName("pcnSubStructures")[0];
      let pcnSubContained =
        pcnSubStructuresElement.getElementsByTagName("pcnSubStructure");
      pcn["pcnSubStructures"] = getSubStructures(Array.from(pcnSubContained));
    }

    if (section === "pcnCauseOfChanges") {
      let pcnChangeElement = xml.getElementsByTagName("pcnCauseOfChanges")[0];
      let pcnChangeContained =
        pcnChangeElement.getElementsByTagName("pcnChange");
      pcn["pcnCauseOfChanges"] = getChanges(Array.from(pcnChangeContained));
    }
  });
  return pcn;
}

function getSectionName(xml: Document, s: string) {
  switch (s) {
    case "masterData":
      return xml.getElementsByTagName("masterData")[0];
    case "difference":
      return xml.getElementsByTagName("difference")[0];
    case "items":
      return xml.getElementsByTagName("ItemNumbers")[0];
    case "pcnLifeCycleData":
      return xml.getElementsByTagName("pcnLifeCycleData")[0];
    case "pcnSubStructures":
      return xml.getElementsByTagName("pcnSubStructures")[0];
    case "pcnCauseOfChanges":
      return xml.getElementsByTagName("pcnCauseOfChanges")[0];
    default:
      return xml.getElementsByTagName("masterData")[0];
  }
}

function getTextContent(
  xml: Element | Document,
  tagName: string
): string | null {
  try {
    const elements: HTMLCollectionOf<Element> =
      xml.getElementsByTagName(tagName);
    return elements[0].textContent;
  } catch (err) {
    return null;
  }
}

function getItems(items: Element[]) {
  /** used only for getting the keys from the class */
  let itemNumberFields = new ItemNumber();
  /** keys from the itemNumberFields class */
  let currentKeys = Object.keys(itemNumberFields);

  /** create an empty array */
  let itemsArray: ItemNumber[] = [];
  // let subItems: AttObject[] = [];
  items.forEach((item) => {
    let attributes = getNodeAttributesForItem("itemLifeCycleData", item);
    let changeAttributes = getNodeAttributesForChanges("itemChange", item);

    /** for each item in items create a new ItemNumber in order to set its property - values */
    let itemNumber = new ItemNumber();
    fieldNames.forEach((fieldName) => {
      if (currentKeys.includes(fieldName)) {
        const textContent: string | null = getTextContent(item, fieldName);
        itemNumber[fieldName] = textContent;
      }
      if (Object.keys(attributes).includes(fieldName)) {
        itemNumber[fieldName] = attributes[fieldName];
      }
      if (Object.keys(changeAttributes).includes(fieldName)) {
        itemNumber[fieldName] = changeAttributes[fieldName];
      }
    });
    /** add each item to the newly created array */
    itemsArray.push(itemNumber);
  });
  /**return the array so that it can be saved on the pcnBody.itemNumbers[] */
  return itemsArray;
}

function getSubStructures(subStructures: Element[]) {
  let subStructuresFields = new PcnSubStructure();
  let currentKeys = Object.keys(subStructuresFields);
  let subStructureArray: PcnSubStructure[] = [];

  subStructures.forEach((item) => {
    let attributesObjects = item.attributes;

    let subStructure = new PcnSubStructure();
    fieldNames.forEach((fieldName: string) => {
      if (currentKeys.includes(fieldName)) {
        const textContent: string | null = getTextContent(item, fieldName);
        (subStructure as any)[fieldName] = textContent;
      }
      if (fieldName === "pcnSubType" || fieldName === "pcnSubRevision") {
        const fieldAttribute = Array.from(attributesObjects).filter(
          (attr) => attr.name === fieldName
        );
        subStructure[fieldName] = fieldAttribute[0].value;
      }
    });
    subStructureArray.push(subStructure);
  });
  return subStructureArray;
}

function getChanges(causeOfChanges: Element[]) {
  let causeOfChangesFields = new PcnChange();
  let currentKeys = Object.keys(causeOfChangesFields);
  let changeArray: PcnChange[] = [];

  causeOfChanges.forEach((item) => {
    let attributesObjects = item.attributes;

    let change = new PcnChange();
    fieldNames.forEach((fieldName: string) => {
      if (currentKeys.includes(fieldName)) {
        const textContent: string | null = getTextContent(item, fieldName);
        (change as any)[fieldName] = textContent;
      }
      if (fieldName === "pcnChangeType") {
        const fieldAttribute = Array.from(attributesObjects).filter(
          (attr) => attr.name === fieldName
        );
        change[fieldName] = fieldAttribute[0].value;
      }
    });
    changeArray.push(change);
  });
  return changeArray;
}

function getNodeAttributesForItem(nodeName: string, item: Element) {
  let lifeCycleData = new LifeCycle();
  let itemChange = new ItemChange();
  let attObject = new AttObject();
  let attributes = item.getElementsByTagName(nodeName)[0].attributes;
  Object.keys(lifeCycleData).forEach((field) => {
    const fieldAttribute = Array.from(attributes).filter(
      (attr) => attr.name === field
    );
    if (fieldAttribute.length > 0) {
      attObject[field] = fieldAttribute[0].value;
    } else {
      attObject[field] = "";
    }
  });
  Object.keys(itemChange).forEach((field) => {
    const fieldAttribute = Array.from(attributes).filter(
      (attr) => attr.name === field
    );
    if (fieldAttribute.length > 0) {
      attObject[field] = [fieldAttribute[0].value];
    } else {
      attObject[field] = "";
    }
  });
  return attObject;
}

function getNodeAttributesForChanges(nodeName: string, item: Element) {
  let itemChange = new ItemChange();
  let attObject = new AttObject();
  let itemNodes = item.getElementsByTagName(nodeName);
  let attribute: NamedNodeMap;
  let attributes: NamedNodeMap[] = [];
  /** for each item in itemNodes create a new attribute in order to add its values to attributes array */
  Array.from(itemNodes).forEach((item: any) => {
    if (item != null) {
      attribute = item.attributes;
    }
    attributes.push(attribute);
  });

  /** keys from the itemChange class */
  Object.keys(itemChange).forEach((field) => {
    if (attribute != null) {
      const fieldAttribute = Array.from(attribute).filter(
        (attr) => attr.name === field
      );
      /** create a new array in order to set all values from the attribute to the itemChangeType property */
      let fieldValues: string[] = [];
      if (fieldAttribute.length > 0) {
        attributes.forEach((attr) => {
          let value = attr[0].value;
          fieldValues.push(value);
        });
        attObject[field] = fieldValues;
      } else {
        attObject[field] = "";
      }
    }
    /** create a new types array in order to have the new categories names mapped to VMDA standard */
    let optionTypes: string[] = [];
    if (!StringUtils.isNullOrEmpty(attObject[field])) {
      const VDMATypes: string[] = Object.keys(typeCategories);
      attObject[field].forEach((attr: string) => {
        if (VDMATypes.includes(attr)) {
          optionTypes.push(attr);
        } else {
          let smartType = mapSmartPcnChangeTypes(attr);
          optionTypes.push(smartType);
        }
        attObject[field] = optionTypes;
      });
    }
  });
  return attObject;
}

/** map SmartPCN Types to VDMA Types  */
function mapSmartPcnChangeTypes(val: string) {
  const smartPcnTypes: string[] = Object.keys(typesMap);
  if (smartPcnTypes.indexOf(val) === -1) return "";
  return typesMap[val];
}

export async function getAttachments(file: File, app: AppType): Promise<void> {
  var zipFile = new JSZip();

  await zipFile.loadAsync(file).then(function (zip) {
    /** create a new attachment object in order to have the name of the attachment as a refference */
    let attachments: {
      name: string;
      attachment: Blob;
      xmlFile: true;
      data: any;
    }[] = [];

    /** create an index in order to count the number of attachments so that we know when we have to emit the subject */
    let i = 0;

    delete zip.files["Attachments/"];

    Object.values(zip.files).forEach(async (file) => {
      let currentFile = file.name;
      let fileType = currentFile.split(".").pop();
      if (fileType == null) {
        return;
      }
      let fileExtension = fileType.toLowerCase();
      let acceptedFileFormats = [
        "png",
        "jpg",
        "jpeg",
        "svg",
        "gif",
        "pdf",
        "rtf",
        "txt",
        "xml",
        "xls",
        "xlsx",
        "doc",
        "docx",
        "ppt",
        "pptx",
        "odp",
        "msg",
        "xlsm",
        "xsd",
      ];

      if ("PCNbody.xml" in zip.files) {
        app.pcnGenerator.isValidZip = true;
      } else {
        app.pcnGenerator.isValidZip = false;
      }

      let attachmentIncluded: boolean = false;
      if (currentFile.includes("Attachments")) {
        attachmentIncluded = true;
      } else {
        attachmentIncluded = false;
      }

      /** length will be -2 because we need to take out the pcnBody.xml file & attachments.xml file */
      let length = Object.keys(zip.files).length - 2;

      if (fileExtension === "xml") {
        /** read xml file */

        await zip.files[currentFile].async("text").then(function (data) {
          data = data;
          var parser = new DOMParser();
          var doc = parser.parseFromString(data, "text/xml");
          app.importXml.parse(doc, currentFile);

          if (!attachmentIncluded) {
            app.pcnGenerator.attachments = [];
            app.pcnGenerator.createPcnDoc(app.importXml.result);
          }
        });
      }

      if (
        acceptedFileFormats.includes(fileExtension) &&
        fileExtension != "xml"
      ) {
        /** read pdf-files and store them */

        zip.files[currentFile].async("blob").then(async function (data) {
          data = data;
          let file = new Blob([data], { type: `application/${fileExtension}` });

          attachments.push({
            name: currentFile.split("/")[1],
            attachment: file,
            xmlFile: true,
            data: "",
          });

          i++;

          if (i === length) {
            /** when the length of iterations through the attachments is the same as length emit an subject
            * so that we know that we have the end result of the attachments and store them in the service
            The subscription for the subject is done in pcn-generator.component.ts and also the setting of the attachments
            */
            app.pcnGenerator.attachmentSubject.next(attachments);
          }
        });
      }
    });
  });
}
