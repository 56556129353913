import { Component, Input, OnInit } from "@angular/core";
import { type } from "os";
import { Customer } from "../../../../shared/types/customers";
import { StringUtils } from "../../../../shared/utils/string.utils";
import { doc2Model } from "../api.service";
import { getApp } from "../app";
import { BILD_IMAGE } from "../file/file.service";
import { Thread } from "../graphs/graphs.service.type";
import { DocModel } from "../state/state";

@Component({
  selector: "app-thread-item",
  templateUrl: "./thread-item.component.html",
  styleUrls: ["./thread-item.component.scss"],
})
export class ThreadItemComponent implements OnInit {
  @Input() id: string = "";
  thread = {} as Thread;
  doc: any;
  solutionCount: number = 0;
  commentCount: number = 0;
  taskCount: number = 0;
  completedTasksCount: number = 0;
  model: DocModel | null = null;
  isFavorite: boolean = false;
  bildImage: string = BILD_IMAGE;

  app = getApp((app) => {
    this.app = app;
  });
  finalSolution: string = "";

  headerColor: string = "";
  textColor: string = "";
  iconColor: string = "";

  constructor() {}

  ngOnInit() {
    this.app.docs.docsSubject.subscribe(async (value) => {
      this.doc = this.app.thread.threadsCompressed.find(
        (doc) => doc._id === this.id
      );

      if (this.doc) {
        this.model = doc2Model("thread", this.doc);
        if (this.doc) {
          this.app.thread.thread = this.doc;
          this.isFavorite = this.app.thread.isFav(this.doc._id);

          this.app.thread.favSubject.subscribe((value: string) => {
            if (this.doc != null && value === this.doc._id) {
              this.isFavorite = this.app.thread.isFav(this.doc._id);
            }
          });
        }
        if (this.doc.postCount) {
          this.commentCount = this.doc.postCount.commentsCount;
          this.solutionCount = this.doc.postCount.solutionsCount;
          this.taskCount = Number(this.doc.postCount.tasksCount);
          this.finalSolution =
            this.doc.postCount.finalSolution != null
              ? this.doc.postCount.finalSolution
              : "";
          this.completedTasksCount =
            this.doc.postCount.completedTasks != null &&
            this.doc.postCount.completedTasks.length
              ? this.doc.postCount.completedTasks.length
              : 0;
        }
      } else {
        this.model = null;
      }
    });

    if (this.app.customers.expectCurrent === Customer.COOP) {
      this.changeCardHeaderColor();
    }
  }

  /** When any image is loaded, increment the loaded image count to be intercepted in the Home component */
  updateLoadedImageCounter(): void {
    this.app.file.loadedImageCount++;
    this.app.file.loadedImageSubject.next(this.app.file.loadedImageCount);
  }

  showUploadedImage(doc: any) {
    let showImage = false;
    if (doc._attachments != null && doc._attachments[this.bildImage]) {
      showImage = true;
    }
    return showImage;
  }

  setFav(fav: boolean): void {
    this.app.thread.setFav(this.doc._id, fav);
  }

  changeCardHeaderColor() {
    let typeOfPost = this.app.thread.thread["typeOfPost"];
    if (!StringUtils.isNullOrEmpty(typeOfPost)) {
      this.textColor = "lightText";
      this.iconColor = "lightIcon";
      if (typeOfPost === "seek") {
        this.headerColor = "header-color-red";
      }
      if (typeOfPost === "offer") {
        this.headerColor = "header-color-green";
      }
    }
  }

  getStatusStyle(status: string) {
    if (status === "120") {
      return "caseClosed";
    }
    return "textColor";
  }
}
