import { Component, Input, Output } from "@angular/core";
import { getApp } from "../app";
import { Subject } from "rxjs";
import { Customer } from "../../../../shared/types/customers";

@Component({
  selector: "app-unlock",
  templateUrl: "./unlock.component.html",
  styleUrls: ["./unlock.component.scss"],
})
export class UnlockComponent {
  app = getApp((app) => {
    this.app = app;
  });

  @Input() id: string = "";
  @Input() field: string = "";
  @Input() permission = true;
  @Input() hasText = true;
  @Input() isDescr = false;
  @Output() unlock: Subject<void> = new Subject();

  constructor() {}

  edit() {
    this.app.unlockedId = this.id;
    this.unlock.next();
    //used only for coop
    //search in the description field when user click on edit thread button
    if (this.app.customers.expectCurrent === Customer.COOP) {
      if (this.id === this.app.thread.id) {
        this.app.productCategory.searchInEntireString(
          this.app.thread.thread["thread.descr"]
        );
      }
    }
  }
}
