export class Train {
  [key: string]: any;
  // _id?: string = "";
  type: string = "train";
  trainName: string = "";
  omfNumber?: string = "";
  trainResponsible: string = "";
  _deleted?: boolean = false;
  trainRemoved?: boolean = false;
}
