import { APP_TYPE, AppType } from "../app.type";
import { AuthService } from "./auth.service";
import { Inject, Injectable } from "@angular/core";
import { REDIRECT_TO } from "../routing/routing.service";
import {
  ActivatedRouteSnapshot,
  CanActivate,
  Router,
  RouterStateSnapshot,
} from "@angular/router";

@Injectable({ providedIn: "root" })
export class AuthGuard implements CanActivate {
  constructor(
    @Inject(APP_TYPE) private app: AppType,
    private authService: AuthService,
    private router: Router
  ) {}

  public async canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ) {
    if (state.url.includes("pcn")) {
      return true;
    }

    const authenticated = await this.authService.checkAuth();

    const segments = state.url.split("/").filter((s) => s !== "");

    if (typeof Storage !== "undefined") {
      sessionStorage.setItem(REDIRECT_TO, JSON.stringify(segments));
    }

    if (!authenticated) {
      return this.router.createUrlTree(["/login"]);
    } else {
      return true;
    }
  }
}
