const stringFormat = /[!^()\=\[\]{};':"\\|<>\/?#$%*]+/;

export class StringUtils {
  public static isNullOrEmpty(text: string | undefined): boolean {
    if (text == null || text === "" || text == undefined) {
      return true;
    }

    return false;
  }

  public static isUndefined(text: string | undefined): boolean {
    if (text == undefined) {
      return true;
    }

    return false;
  }

  public static containsSpecialCharacter(value: string, f?: RegExp) {
    let format = f != null ? f : stringFormat;
    if (format.test(value)) {
      return true;
    }
    return false;
  }

  public static encode(val: string) {
    //used replace function to manually replace the special character because the encodeURIComponent does not encode these characters
    return encodeURIComponent(val)
      .replace(/\(/g, "%28")
      .replace(/\)/g, "%29")
      .replace(/\!/g, "%21")
      .replace(/\*/g, "%2A")
      .replace(/\'/g, "%27")
      .replace(/\~/g, "%7E");
  }
}
