import { ChangeDetectorRef, Component, OnInit } from "@angular/core";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { Subscription } from "rxjs";
import { Customer } from "../../../../shared/types/customers";
import { Responsibles } from "../../../../shared/models/responsibles";
import { VehicleResponsible } from "../../../../shared/models/vehicleResponsible";
import { doc2Model } from "../api.service";
import { getApp } from "../app";
import { departments } from "../impact/wf-automation/wf-automation.component";
@Component({
  selector: "app-vehicle-responsible",
  templateUrl: "./vehicle-responsible.component.html",
  styleUrls: ["./vehicle-responsible.component.scss"],
})
export class VehicleResponsibleComponent implements OnInit {
  private _routeSubscription: Subscription = new Subscription();
  departments = departments;
  saveDisabled = false;
  size: number = 0;
  private _sizeSubscription: Subscription = new Subscription();
  private _vResponsibleSubscription: Subscription = new Subscription();

  app = getApp((app) => {
    this.app = app;
  });
  loading: boolean = true;

  constructor(
    private _cdRef: ChangeDetectorRef,
    private modalService: NgbModal
  ) {}

  async ngOnInit() {
    await this.createModel();
    this.size = this.app.vehicleResponsible.invalidFields.size;
    this.app.type = "vehicleResponsible";

    /** if the current set of fields has a field that is not valid the save button has to hidden */
    this._sizeSubscription =
      this.app.vehicleResponsible.newInvalidField.subscribe((value: number) => {
        if (this.size !== value) {
          this.size = value;
          this.saveDisabled = this.isDisabled();
          this._cdRef.detectChanges();
        }
      });

    /** subscribe to the value on add/edit */
    this._vResponsibleSubscription =
      this.app.vehicleResponsible.vResponsibleSubject.subscribe(
        async (value: boolean) => {
          if (value) {
            await this.createModel();
          }
        }
      );
  }

  async createModel() {
    /** check if the current impact is new or not */
    if (
      this.app.state.vehicleId != null &&
      this.app.state.vehicleId.split("_")[0] !== "NEW"
    ) {
      this.app.model = {};
      this.app.vehicleResponsible.isNew = false;
      this.app.vehicleResponsible.id = this.app.state.vehicleId;
      await this.app.vehicleResponsible.getVehicleById(
        this.app.state.vehicleId
      );
    } else {
      const vehicleModel: any = doc2Model(
        "vehicleResponsible",
        new VehicleResponsible()
      );
      this.app.vehicleResponsible.isNew = true;
      this.app.vehicleResponsible.model = vehicleModel;
      if (this.app.customers.expectCurrent === Customer.DB) {
        this.app.vehicleResponsible.model["vehicleResponsible.responsibles"] =
          doc2Model("responsibles", new Responsibles());
      }
    }
    this.loading = false;
  }

  display(field: string) {
    if (
      this.app.vehicleResponsible.model &&
      this.app.vehicleResponsible.model["vehicleResponsible.responsibles"] !=
        null &&
      this.app.vehicleResponsible.model["vehicleResponsible.responsibles"][
        field
      ] != null
    ) {
      if (
        this.app.vehicleResponsible.model["vehicleResponsible.responsibles"][
          field
        ] !== ""
      ) {
        return true;
      } else {
        return false;
      }
    }
  }

  hasInvalidFields() {
    if (
      this.app.field.getInvalidFields(
        "vehicleResponsible",
        this.app.vehicleResponsible.model
      ).length < 1 &&
      this.app.vehicleResponsible.modelHasChanges
    ) {
      return false;
    } else {
      return true;
    }
  }

  isDisabled() {
    if (this.size === 0) {
      return false;
    } else {
      return true;
    }
  }

  async discardChanges() {
    this.app.vehicleResponsible.id = this.app.state.vehicleId;

    this.app.unlockedId = null;
    if (this.app.vehicleResponsible.isNew) {
      this.app.vehicleResponsible.id = null;
      this.app.vehicleResponsible.isNew = false;
    }
    this.app.model = {};
    this.app.typeAheadBasic.model = {};
    await this.createModel();
  }

  async deleteVehicle() {
    await this.app.vehicleResponsible.delete();
    this.modalService.dismissAll();
  }

  ngOnDestroy(): void {
    if (this._routeSubscription) {
      this._routeSubscription.unsubscribe();
    }
    if (this._sizeSubscription) {
      this._sizeSubscription.unsubscribe();
    }
    if (this._vResponsibleSubscription) {
      this._vResponsibleSubscription.unsubscribe();
    }
  }
}
