import { Options } from "../settings/settings";

export interface DinCodes {
  [code: string]: {
    de: string;
    en?: string;
    nl?: string;
  };
}

export const dinCodes: DinCodes = {
  A: {
    de: "Übergeordnete Elemente/Systeme (Zug)",
  },
  AA: {
    de: "Zug (Komposition, Triebzug) ",
    en: "Train (composition)",
    nl: "Trein (samenstelling)",
  },
  AA01: {
    de: "Zugelement (Wagen & Triebzug)",
    en: "Train element (wagon & unit)",
    nl: "Treinelement (rijtuig & stel)",
  },
  AB: {
    de: "Übergreifende Systemdokumentation (Zug)",
    en: "General System Documentation (Train)",
    nl: "Algemene systeemdocumentatie (trein)",
  },
  AB01: {
    de: "Typenbild / Layout (übergeordnet)",
  },
  AB02: {
    de: "Anstrichzeichnung (Aussendesign)",
  },
  AB03: {
    de: "Aerodynamik",
  },
  AB04: {
    de: "Druckschutz/-dichtheit übergreifend",
  },
  AB05: {
    de: "Elektroschema (Fahrzeug)",
  },
  AB06: {
    de: "Einschränkungsberechnung",
  },
  AB07: {
    de: "Akustik",
  },
  AB08: {
    de: "Brandsicherheit/Rettung (übergeordnet)",
  },
  AB09: {
    de: "Umweltverträglichkeit/Recycling (übergeordnet)",
  },
  AB10: {
    de: "Schliess-/Verschlusskonzept (übergeordnet)",
  },
  AC: {
    de: "Verbindungssysteme mechn. (übergeordnet)",
    en: "Mechanical connection systems",
    nl: "Mechanische verbindingen",
  },
  AC01: {
    de: "Schraubverbindung (übergeordnet)",
  },
  AC02: {
    de: "Nietverbindung (übergeordnet)",
  },
  AC03: {
    de: "Sonstige mechan. Verbindungen (übergeordnet)",
  },
  AD: {
    de: "Verbindungssysteme elektrisch (übergeordnet)",
  },
  AE: {
    de: "Kleben und Dichten (übergeordnet)",
    en: "Adhesion and Sealings",
    nl: "Verlijmen en afdichten",
  },
  B: {
    de: "Fahrzeugkasten (Personen- und Güterwagen)",
  },
  BA: {
    de: "Fahrzeugkasten",
    en: "Coach",
    nl: "Voertuigcarrosserie",
  },
  BA02: {
    de: "Kessel (Güterwagen)",
  },
  BA03: {
    de: "Haube (Güterwagen)",
  },
  BA04: {
    de: "Kastenrohbau (Fahrzeugrohbau)",
  },
  BA05: {
    de: "Fördereinrichtungen (Güterwagen)",
  },
  BA06: {
    de: "Hebelift (Güterwagen)",
  },
  BB: {
    de: "Untergestell (Fahrzeugkasten)",
    en: "Base Frame",
    nl: "Onderstel (voertuigcarrosserie)",
  },
  BB01: {
    de: "Bodenauflage (Autozug)",
  },
  BB02: {
    de: "Struktur-Boden (Rohbau)",
  },
  BB03: {
    de: "Kopfstück",
  },
  BB04: {
    de: "Kupplungsträger",
  },
  BB06: {
    de: "Bodenblech (Untergestell)",
  },
  BB08: {
    de: "Untergestell-Vorbau",
  },
  BB09: {
    de: "Bodenprofile/Gerippe (Untergestell)",
  },
  BB10: {
    de: "Langträger (Untergestell)",
  },
  BB11: {
    de: "Hauptquerträger (Untergestell)",
  },
  BB12: {
    de: "Fahrwerk-Befestig. Kasten Untergest.",
  },
  BB13: {
    de: "Querbügel (Untergestell)",
  },
  BB14: {
    de: "Crash-Element (Untergestell)",
  },
  BB15: {
    de: "mechanische Verbindungen (Untergestell)",
  },
  BB16: {
    de: "Radsatzhalter (Güterwagen)",
  },
  BB17: {
    de: "Tragfederbock",
  },
  BB18: {
    de: "Bodenwanne",
  },
  BB19: {
    de: "Kopfträger",
  },
  BB20: {
    de: "Lademulde (Güterwagen)",
  },
  BC: {
    de: "Längswände (Fahrzeugkasten)",
    en: "Longitudinal Wall (coach)",
    nl: "Zijwanden (voertuigcarrosserie)",
  },
  BC02: {
    de: "Seitenwandblech",
  },
  BC03: {
    de: "Seitenwand Profil/Gerippestruktur",
  },
  BC04: {
    de: "Seitenwandgurt",
  },
  BC06: {
    de: "Türsäule, Türportal (Seitenwand)",
  },
  BC07: {
    de: "mechanische Verbindungen (Seitenwand)",
  },
  BC08: {
    de: "Schiebewand (Güterwagen)",
  },
  BC09: {
    de: "Seitenboard, Seitenrunge  (Güterwagen)",
  },
  BC10: {
    de: "Mittelsäule (Güterwagen)",
  },
  BD: {
    de: "Dach (Fahrzeugkasten)",
    en: "Roof (coach)",
    nl: "Dak (voertuigcarrosserie)",
  },
  BD01: {
    de: "Dachblech",
  },
  BD02: {
    de: "Dachprofile/Gerippestruktur",
  },
  BD05: {
    de: "Dach Obergurt",
  },
  BD06: {
    de: "Dach Crash-Element",
  },
  BD07: {
    de: "mechanische Verbindungen (Dach)",
  },
  BD08: {
    de: "Dachelemente (Schiebe- /Schwenkdach)",
  },
  BD09: {
    de: "Teleskophaube (Güterwagen)",
  },
  BD10: {
    de: "Dachplane (Güterwagen)",
  },
  BE: {
    de: "Fahrzeugkopf (Fahrzeugkasten)",
    en: "Head (coach)",
  },
  BE03: {
    de: "Gerippe (Fahrzeugkopf)",
  },
  BE04: {
    de: "Kunststoff-Kabine/-Front (FzKopf)",
  },
  BE05: {
    de: "Rammbalken (Fahrzeugkopf)",
  },
  BE06: {
    de: "Crash-Element (Fahrzeugkopf)",
  },
  BE07: {
    de: "Mechanische Verbindungen (Fahrzeugkopf)",
  },
  BF: {
    de: "Stirnwand (Fahrzeugkasten)",
    en: "Front Wall (coach)",
    nl: "Voorwand (voertuigcarrosserie)",
  },
  BF01: {
    de: "Stirnwandblech",
  },
  BF02: {
    de: "Stirnwandgerippe",
  },
  BF03: {
    de: "Rammsäule (Stirnwand)",
  },
  BF04: {
    de: "Crash-Element (Stirnwand)",
  },
  BF05: {
    de: "mechanische Verbindungen (Stirnwand)",
  },
  BF06: {
    de: "Stirnboard, Stirnrungen ...",
  },
  BG: {
    de: "Anschweiss- Anbauteile (FzKasten)",
    en: "Welding / Mounting Part (coach)",
    nl: "Laswerk (voertuigcarrosserie)",
  },
  BG03: {
    de: "Anschlag",
  },
  BG04: {
    de: "Auflager",
  },
  BG05: {
    de: "Bodendurchführung",
  },
  BG06: {
    de: "Bodenklappe",
  },
  BG10: {
    de: "Halter, Support (Anschweiss- Anbauteil)",
  },
  BG11: {
    de: "Hilfsstruktur (Anschweiss- Anbauteil)",
  },
  BG12: {
    de: "Mechanische Verbindungen Anschweissteile",
  },
  BG13: {
    de: "Türverriegelung (Güterwagen)",
  },
  BG14: {
    de: "Tragzapfen (Güterwagen)",
  },
  BG15: {
    de: "Radschutz (Güterwagen)",
  },
  BH: {
    de: "Zwischenboden (Fahrzeugkasten)",
    en: "Intermediate shelf (coach)",
    nl: "Tussenvloer (voertuigcarrosserie)",
  },
  BH01: {
    de: "Profile/Gerippestruktur (Zwischenboden)",
  },
  BH02: {
    de: "Blech (Zwischenboden)",
  },
  BH03: {
    de: "Sandwichplatte (Zwischenboden)",
  },
  BH04: {
    de: "Mechn. Verbindungen (Zwischenboden)",
  },
  BJ: {
    de: "Zwischenwände (tragend)",
    en: "Interior Wall (load-bearing)",
    nl: "Scheidingswanden",
  },
  BJ01: {
    de: "Profile/Gerippestruktur (Kastenstruktur)",
  },
  BJ02: {
    de: "Blech (tragende Zwischenwände)",
  },
  BJ03: {
    de: "mechn. Verbindungen (Zwischenwände)",
  },
  C: {
    de: "Fahrzeugausbau",
  },
  CA: {
    de: "Fahrzeugausbau",
    en: "Fitout (coach)",
  },
  CA01: {
    de: "Abteil",
  },
  CA02: {
    de: "Businessabteil",
  },
  CA03: {
    de: "Einstiegsraum",
  },
  CA04: {
    de: "Fahrgastraum",
  },
  CA05: {
    de: "Führerraum",
  },
  CA06: {
    de: "Gepäckraum",
  },
  CA07: {
    de: "Großraum",
  },
  CA09: {
    de: "Küche",
  },
  CA10: {
    de: "Lift/Kleingüteraufzug",
  },
  CA11: {
    de: "Maschinenraum",
  },
  CA12: {
    de: "Multifunktionsraum",
  },
  CA13: {
    de: "Restaurant",
  },
  CA14: {
    de: "Sonderabteile",
  },
  CA15: {
    de: "Toilettenraum/Waschraum",
  },
  CA16: {
    de: "Vorraum",
  },
  CA18: {
    de: "Zugpersonalabteil",
  },
  CA19: {
    de: "Ausrüstungen (Güter- und Dienstwagen)",
  },
  CB: {
    de: "Aussenfenster",
    en: "Outside Windows",
    nl: "Buitenramen",
  },
  CB01: {
    de: "Frontscheibe (Führerraum)",
  },
  CB02: {
    de: "Klappfenster (Seitenfenster)",
  },
  CB03: {
    de: "Kommunikationsklappe",
  },
  CB04: {
    de: "Notausstiegfenster (Seitenfenster)",
  },
  CB05: {
    de: "Schiebefenster (Seitenfenster)",
  },
  CB06: {
    de: "Seitenfenster fest",
  },
  CB07: {
    de: "Türfenster (Einstiegstür)",
  },
  CB08: {
    de: "Türfenster (Übergangstür)",
  },
  CB09: {
    de: "Neben-/Eckfenster (Führerraum)",
  },
  CB10: {
    de: "Senkfenster (Seitenfenster)",
  },
  CB11: {
    de: "Glasscheibe für Zielanzeige (Front)",
  },
  CB12: {
    de: "Glasabdeckung für Stirnbeleuchtung",
  },
  CC: {
    de: "Fussboden",
  },
  CC01: {
    de: "Blendleiste (Fussboden)",
  },
  CC02: {
    de: "Platte (Fussboden)",
  },
  CC03: {
    de: "Belag / Teppich (Fussboden)",
  },
  CC04: {
    de: "Unterbau (Fussboden)",
  },
  CC05: {
    de: "Laderaum (Fussboden)",
  },
  CC06: {
    de: "Schwelle (Fussboden)",
  },
  CC08: {
    de: "Treppe (innen)",
  },
  CC09: {
    de: "Trittblende innen",
  },
  CD: {
    de: "Innenverkleidung",
    en: "Interior Trim",
    nl: "Interieur inrichting",
  },
  CD01: {
    de: "Deckenklappe (Innenverkleidung)",
  },
  CD02: {
    de: "Decke (Innenverkleidung)",
  },
  CD03: {
    de: "Führerraum (Innenverkleidung)",
  },
  CD04: {
    de: "Heizkörperverkleidung",
  },
  CD06: {
    de: "Reinigungsklappe (Innenverkleidung)",
  },
  CD07: {
    de: "Rückwand (Innenverkleidung)",
  },
  CD08: {
    de: "Schrank (Innenverkleidung)",
  },
  CD09: {
    de: "Seitenwand (Innenverkleidung)",
  },
  CD10: {
    de: "Stirnwand (Innenverkleidung)",
  },
  CD11: {
    de: "Stirnwandklap.(Innenverkleid.)",
  },
  CE: {
    de: "Trennwand",
    en: "Partition",
    nl: "Scheidingswanden",
  },
  CE01: {
    de: "Abteiltrennwand",
  },
  CE02: {
    de: "Glastrennwand",
  },
  CE03: {
    de: "Gepäckregal (Trennwand)",
  },
  CE04: {
    de: "Längstrennwand",
  },
  CE05: {
    de: "Quertrennwand",
  },
  CE06: {
    de: "Führerraumrückwand",
  },
  CE07: {
    de: "Glasschutzfolie (innen)",
  },
  CF: {
    de: "Aussenanbauten",
    en: "External Attachments",
  },
  CF01: {
    de: "Aussenverkleidung",
  },
  CF02: {
    de: "Bahnräumer",
  },
  CF03: {
    de: "Dachverkleidung/Dachschürze",
  },
  CF06: {
    de: "Kupplungsklappe/Bugklappe (aussen)",
  },
  CF13: {
    de: "Aussenklappe/Aussenschürze",
  },
  CF14: {
    de: "Kran (Baudienstfahrzeuge)",
  },
  CF15: {
    de: "Arbeitsbühne (Baudienstfahrzeuge)",
  },
  CF16: {
    de: "Ladebrücke (Baudienstfahrzeuge)",
  },
  CF17: {
    de: "Bodenwannen-Gerippe  (Aussenanbauten)",
  },
  CF18: {
    de: "Dach-Haube (Aussenanbauten)",
  },
  CF19: {
    de: "Bodenluke, Wandklappe  (Aussenanbauten)",
  },
  CF20: {
    de: "Schneeschleuder und Aggregate",
  },
  CF21: {
    de: "Haltestangen & Griffe (Güterwagen)",
  },
  CF22: {
    de: "Trittbrett (Güterwagen)",
  },
  CF23: {
    de: "Seilhaken (Güterwagen)",
  },
  CF24: {
    de: "Abweiser (Güterwagen)",
  },
  CF25: {
    de: "Seitenklappe (Güterwagen)",
  },
  CF26: {
    de: "Stirnklappe (Güterwagen)",
  },
  CF27: {
    de: "Kippeinrichtung (Güterwagen)",
  },
  CF28: {
    de: "Entladevorrichtung (Güterwagen)",
  },
  CF29: {
    de: "Signalstütze (Güterwagen)",
  },
  CF30: {
    de: "Zettelhalter (Güterwagen)",
  },
  CF31: {
    de: "Runge (Güterwagen)",
  },
  CG: {
    de: "Aussenbeschichtung",
    en: "External Coating",
    nl: "Uitwendige coating",
  },
  CG01: {
    de: "Deckanstrich (Aussenbeschichtung)",
  },
  CG02: {
    de: "Klebefolie (Aussenbeschichtung)",
  },
  CG03: {
    de: "Grundierung (Aussenbeschichtung)",
  },
  CG04: {
    de: "Füller/Spachtel (Aussenbeschichtung)",
  },
  CG05: {
    de: "Innenlackierung / -beschichtung",
  },
  CG06: {
    de: "Klebefolie (Innen)",
  },
  CG07: {
    de: "Pulverlack",
  },
  CG08: {
    de: "Schutzbeschichtung",
  },
  CH: {
    de: "Isolierung und Klebstoffe",
    en: "Isolation and Adhesives",
    nl: "Isolatie en lijmen",
  },
  CH03: {
    de: "Spritzisolierung aussen und innen",
  },
  CH04: {
    de: "Trockenisolierung aussen",
  },
  CH05: {
    de: "Dicht- und Klebstoffe aussen",
  },
  D: {
    de: "Fahrzeuginneneinrichtung",
  },
  DA: {
    de: "Fahrzeuginneneinrichtung",
    en: "Interior Equipment (coach)",
    nl: "Voertuiginterieuruitrusting",
  },
  DA01: {
    de: "Innenausstattung",
  },
  DB: {
    de: "Haltestangen, Griffe (Innen)",
    en: "Handrails, Grips",
    nl: "Leuningen, Handvatten",
  },
  DB01: {
    de: "Geländer (innen)",
  },
  DB02: {
    de: "Griffe (innen)",
  },
  DB03: {
    de: "Handlauf (innen)",
  },
  DB04: {
    de: "Handstange (innen)",
  },
  DC: {
    de: "Sitze, Liegen, Tische",
    en: "Seats, Couches, Tables",
    nl: "Zitplaatsen, banken, tafels",
  },
  DC01: {
    de: "Begleitersitz (alle Elemente)",
  },
  DC02: {
    de: "Abteiltisch",
  },
  DC03: {
    de: "Bett / Liege",
  },
  DC04: {
    de: "Eckbank",
  },
  DC05: {
    de: "Fahrgastsitz fest",
  },
  DC06: {
    de: "Führersitz (alle Elemente)",
  },
  DC07: {
    de: "Fahrgastsitz klappbar",
  },
  DC08: {
    de: "Klapptisch",
  },
  DC09: {
    de: "Leiter",
  },
  DC11: {
    de: "Seitenwandtisch",
  },
  DC12: {
    de: "Sitzgestell/-struktur (nur Fahrgastsitz)",
  },
  DC13: {
    de: "Stehhilfe",
  },
  DC14: {
    de: "Tisch",
  },
  DC15: {
    de: "Wickeltisch",
  },
  DC16: {
    de: "Armlehne (nur Fahrgastsitz)",
  },
  DC17: {
    de: "Kopfpolster (nur Fahrgastsitz)",
  },
  DC18: {
    de: "Rückenpolster (nur Fahrgastsitz)",
  },
  DC19: {
    de: "Sitzpolster (nur Fahrgastsitz)",
  },
  DC20: {
    de: "Stuhl / Sessel (lose)",
  },
  DC21: {
    de: "Sitzschale (nur Fahrgastsitz)",
  },
  DC22: {
    de: "Bezugsmaterial Textil",
  },
  DC23: {
    de: "Bezugsmaterial Kunstleder",
  },
  DC24: {
    de: "Bezugsmaterial Leder",
  },
  DC25: {
    de: "Brandschutzvlies",
  },
  DC26: {
    de: "Kopfschutztuch / -kissen",
  },
  DD: {
    de: "Sanitäreinrichtungen",
    en: "Sanitary Facilities",
    nl: "Sanitaire voorzieningen",
  },
  DD01: {
    de: "Fäkalientank (WC)",
  },
  DD02: {
    de: "Abwasserverrohrung (WC, Lavabo)",
  },
  DD03: {
    de: "Entkalkungsanlage (Frischwasser)",
  },
  DD04: {
    de: "Entkeimungsanlage (Frischwasser)",
  },
  DD05: {
    de: "Fäkalientankheizung",
  },
  DD06: {
    de: "Wasserbehälter (Frischwasser)",
  },
  DD07: {
    de: "Füllstandsanzeige (Frischwasser)",
  },
  DD08: {
    de: "Füllstutzen (Frischwasser)",
  },
  DD09: {
    de: "Fussbetätigung (WC, Lavabo)",
  },
  DD10: {
    de: "Händetrockner",
  },
  DD11: {
    de: "Mischbatterie, Mischdüsen (Lavabo)",
  },
  DD12: {
    de: "Inneneinrichtung (WC)",
  },
  DD13: {
    de: "Seifenspender (WC, Lavabo)",
  },
  DD14: {
    de: "Spiegel",
  },
  DD15: {
    de: "Spüleinrichtung (WC, Lavabo)",
  },
  DD16: {
    de: "Toilettenpapierspender (WC)",
  },
  DD17: {
    de: "Geschlossenes WC (Vakuumtoilette)",
  },
  DD18: {
    de: "Warmwasseraufbereiter",
  },
  DD19: {
    de: "Waschbecken (WC, Lavabo)",
  },
  DD20: {
    de: "Waschschrank (WC)",
  },
  DD21: {
    de: "Verrohrung (Frischwasser)",
  },
  DD22: {
    de: "Filter (Frischwasser)",
  },
  DD23: {
    de: "Pumpe (Frischwasser)",
  },
  DD24: {
    de: "Ventil, Hahn (Frischwasser)",
  },
  DD25: {
    de: "Toilettenbecken (WC)",
  },
  DD26: {
    de: "WC - Anlage",
  },
  DD27: {
    de: "Bedien- Anzeigesysteme (WC)",
  },
  DD28: {
    de: "Sanitärsteuerung (WC)",
  },
  DD29: {
    de: "Bioreaktor (WC)",
  },
  DD30: {
    de: "Bioreaktor Hygienisierungseinheit (WC)",
  },
  DD31: {
    de: "Bioreaktor, Luftversorgung, Biologie (WC)",
  },
  DD32: {
    de: "Bioreaktor, Füllstandsonden (WC)",
  },
  DD33: {
    de: "Bioreaktor, Steuerung (WC)",
  },
  DD34: {
    de: "Papierhandtuchspender (WC, Lavabo)",
  },
  DD35: {
    de: "Grauwasseranlage (WC)",
  },
  DE: {
    de: "Gepäckablage",
    en: "Baggage Compartment",
    nl: "Bagagerek",
  },
  DE03: {
    de: "Gepäckträger",
  },
  DE04: {
    de: "Gepäck, Schliessfach",
  },
  DE05: {
    de: "Gepäck, Kofferablage",
  },
  DF: {
    de: "Zusätzliche Einrichtungen (Innen)",
    en: "Additional Facilities (indoor)",
  },
  DF01: {
    de: "Bistro-Spezialitätenmaschine",
  },
  DF02: {
    de: "Fahrradhalter",
  },
  DF03: {
    de: "Feuerlöscher",
  },
  DF04: {
    de: "Garderobe",
  },
  DF05: {
    de: "Garderobenschrank",
  },
  DF07: {
    de: "Inventar",
  },
  DF08: {
    de: "Kaffeemat /-maschine",
  },
  DF09: {
    de: "Kleiderhaken",
  },
  DF10: {
    de: "Kochfeld",
  },
  DF12: {
    de: "Küchenschrank",
  },
  DF13: {
    de: "Kühlschrank",
  },
  DF14: {
    de: "Salamander",
  },
  DF16: {
    de: "Skihalter",
  },
  DF17: {
    de: "Geschirr - Spülmaschine",
  },
  DF18: {
    de: "Steamer",
  },
  DF19: {
    de: "Tellerspender, Tellerschrank",
  },
  DF20: {
    de: "Theke",
  },
  DF21: {
    de: "Tiefkühlschrank",
  },
  DF22: {
    de: "Trolley-Schrank",
  },
  DF23: {
    de: "Abfallbehälter",
  },
  DF25: {
    de: "Railboydepot",
  },
  E: {
    de: "Fahrwerk",
  },
  EA: {
    de: "Fahrwerk",
    en: "Chassis",
    nl: "Chassis",
  },
  EA01: {
    de: "Lauffahrwerk",
  },
  EA02: {
    de: "Triebfahrwerk",
  },
  EA03: {
    de: "Drehgestell (komplett)",
  },
  EB: {
    de: "Tragende Strukturen, Fahrwerk",
    en: "Supporting structures (chassis)",
    nl: "Ondersteunende structuren, chassis",
  },
  EB01: {
    de: "Bremstraverse (Fahrwerk)",
  },
  EB02: {
    de: "Drehgestellrahmen  (Fahrwerk)",
  },
  EB03: {
    de: "Federträger  (Fahrwerk)",
  },
  EB04: {
    de: "Federtraverse  (Fahrwerk)",
  },
  EB05: {
    de: "Luftfedertraverse  (Fahrwerk)",
  },
  EB06: {
    de: "Schwanenhalsträger, Längsträger  (Fahrwerk)",
  },
  EB07: {
    de: "Wiege  (Fahrwerk)",
  },
  EB08: {
    de: "Wiegenlängslenker  (Fahrwerk)",
  },
  EB09: {
    de: "Zugsicherungsträger",
  },
  EC: {
    de: "Radsatz, Lager, Führung, Rad",
    en: "Wheelset, Bearing, Duct, Wheel",
    nl: "Wielstel, lager, geleiding, wiel",
  },
  EC01: {
    de: "Radsatz (komplett) Laufradsatz",
  },
  EC02: {
    de: "Radlagerung",
  },
  EC03: {
    de: "Radreifen",
  },
  EC04: {
    de: "Radsatzführung",
  },
  EC05: {
    de: "Radsatzlager",
  },
  EC06: {
    de: "Radsatzwelle",
  },
  EC07: {
    de: "Vollrad",
  },
  EC08: {
    de: "Triebradsatz (komplett)",
  },
  ED: {
    de: "Federung, Dämpfung, Ausgleich",
    en: "Suspension, damping, compensation",
    nl: "Ophanging, demping",
  },
  ED01: {
    de: "Achslenker",
  },
  ED02: {
    de: "Achslenkerlager",
  },
  ED03: {
    de: "Achsquerfeder",
  },
  ED04: {
    de: "Dämpfer",
  },
  ED05: {
    de: "Kalotte (Flexicoilfeder)",
  },
  ED06: {
    de: "Kastenabstützung seitliche",
  },
  ED07: {
    de: "Luftfederung",
  },
  ED08: {
    de: "Niveauregelung",
  },
  ED09: {
    de: "Notfeder",
  },
  ED10: {
    de: "Primärdämpfer vertikal",
  },
  ED11: {
    de: "Primärfeder",
  },
  ED12: {
    de: "Querfederrolle",
  },
  ED13: {
    de: "Querpuffer (elast. Anschlag)",
  },
  ED14: {
    de: "Reibungsdämpfer primär",
  },
  ED15: {
    de: "Reibungsdämpfer sekundär",
  },
  ED16: {
    de: "Schake, Pendelverbindung",
  },
  ED17: {
    de: "Schakenstein",
  },
  ED18: {
    de: "Schlinger-, Längsdämpfer",
  },
  ED19: {
    de: "Sekundärdämpfer quer",
  },
  ED20: {
    de: "Sekundärdämpfer vertikal",
  },
  ED21: {
    de: "Sekundärfeder, Flexicoilfeder",
  },
  ED22: {
    de: "Torsionsfeder",
  },
  ED23: {
    de: "Tiefzugvorrichtung (Triebfahrzeug)",
  },
  ED24: {
    de: "Achsdruckausgleichvorrichtung",
  },
  EE: {
    de: "Lenkungssysteme (aktiv)",
    en: "Steering system (active)",
    nl: "Stuursystemen (actief)",
  },
  EE01: {
    de: "Aktive Querfederung",
  },
  EE02: {
    de: "Cursator",
  },
  EE03: {
    de: "Navigator",
  },
  EE04: {
    de: "Neigetechnik / WAKO",
  },
  EE05: {
    de: "Neigetechnik-Bauteile im DG / WAKO",
  },
  EE06: {
    de: "Radsatzlenker",
  },
  EE07: {
    de: "Winkellenker zu Längsmithnahme",
  },
  EF: {
    de: "Anlenkungssysteme (passiv)",
    en: "Linking systems (passive)",
    nl: "Koppelingssystemen (passief)",
  },
  EF01: {
    de: "Abhebesicherung",
  },
  EF02: {
    de: "Drehzapfen",
  },
  EF03: {
    de: "Fahrmotoraufhängung,Nasenlager",
  },
  EF04: {
    de: "Lemniskate",
  },
  EF05: {
    de: "Mitnehmerstange Längsmitnahme",
  },
  EF06: {
    de: "Querwegbegrenzung, -anschlag",
  },
  EF07: {
    de: "Wankstütze",
  },
  EF08: {
    de: "Zug-, Druckstange",
  },
  EF09: {
    de: "Drehgestelldeichsel",
  },
  EG: {
    de: "Schutzanbauteile, Fahrwerk",
    en: "Protection attachments (chassis)",
  },
  EG01: {
    de: "Fangbügel",
  },
  EG02: {
    de: "Fangseile",
  },
  EG03: {
    de: "Schienenräumer",
  },
  F: {
    de: "Energieanlage, Antriebsanlage",
  },
  FA: {
    de: "Energieanlage, Antriebsanlage",
  },
  FA01: {
    de: "Hauptstromkreis",
  },
  FB: {
    de: "Energiezuführung",
    en: "Energy Supply",
    nl: "Energievoorziening",
  },
  FB01: {
    de: "Dachinstallation (Energie)",
  },
  FB02: {
    de: "Dachleitung (Energie)",
  },
  FB03: {
    de: "Erdungsbürste",
  },
  FB04: {
    de: "Erdungsschalter",
  },
  FB05: {
    de: "Hauptschalter",
  },
  FB06: {
    de: "Hauptsicherung",
  },
  FB07: {
    de: "Traktionstransformator",
  },
  FB08: {
    de: "Hochspannungskabel",
  },
  FB09: {
    de: "Hochspannungskupplung",
  },
  FB10: {
    de: "Hochspannungswandler",
  },
  FB11: {
    de: "Hochspannungs - Isolatoren",
  },
  FB12: {
    de: "Neigetechnik Stromabnehmer",
  },
  FB13: {
    de: "Stromabnehmer",
  },
  FB14: {
    de: "Systemumschalter",
  },
  FB15: {
    de: "Trenn-, Erdungseinrichtung",
  },
  FB16: {
    de: "Trennmesser",
  },
  FB17: {
    de: "Überspannungsableiter",
  },
  FB18: {
    de: "Hochspannungsverriegelungen",
  },
  FB19: {
    de: "Schleifstück",
  },
  FB20: {
    de: "Wippe (Stromabnehmer)",
  },
  FC: {
    de: "Energieerzeugung",
    en: "Energy Generation",
    nl: "Energieopwekking",
  },
  FC01: {
    de: "Anlasser",
  },
  FC02: {
    de: "Generator / Alternator",
  },
  FC03: {
    de: "Verbrennungsmotor (VKM)",
  },
  FC04: {
    de: "Auffangwanne",
  },
  FC05: {
    de: "Aufladegruppe (Ladeluftkompressoren, Turbolader)",
  },
  FC06: {
    de: "Motordrehzahlregler",
  },
  FC07: {
    de: "Zylinderkopf (VKM)",
  },
  FC08: {
    de: "Ventiltrieb (VKM)",
  },
  FC09: {
    de: "Zylinder (VKM)",
  },
  FC10: {
    de: "Motorblock (VKM)",
  },
  FC11: {
    de: "Steuertrieb / Zahnradtrieb (VKM)",
  },
  FC12: {
    de: "Fremdzündung (OTTO)",
  },
  FC13: {
    de: "Gemischaufbereitung (Einspritzung, Vergaser)",
  },
  FC14: {
    de: "Kurbeltrieb (VKM)",
  },
  FC15: {
    de: "Drehschwingungsdämpfer (VKM)",
  },
  FC16: {
    de: "Elektronik und Diagnose (VKM)",
  },
  FC17: {
    de: "Sensoren (VKM)",
  },
  FC18: {
    de: "Kühlanlage Wasserkreis Thermostat (VKM)",
  },
  FC19: {
    de: "Kühlanlage Lüfter (VKM)",
  },
  FC20: {
    de: "Hydraulische Hilfsbetriebe (VKM)",
  },
  FC21: {
    de: "Kurbelgehäuse (VKM)",
  },
  FC22: {
    de: "Verrohrung / Absperrorgane",
  },
  FC23: {
    de: "Generatorgruppe (VKM)",
  },
  FC24: {
    de: "Schmierölanlage (VKM)",
  },
  FC25: {
    de: "El Kabel, El-Leitungssystem Kontakte Crimp (VKM)",
  },
  FD: {
    de: "Energieumsetzung",
    en: "Energy Transmission",
    nl: "Energieomzetting",
  },
  FD01: {
    de: "Bremschopper",
  },
  FD02: {
    de: "Bremswiderstand",
  },
  FD03: {
    de: "Fahrmotor",
  },
  FD04: {
    de: "Getriebe",
  },
  FD05: {
    de: "Lastschalter (elektropneum.)",
  },
  FD06: {
    de: "Kardanwelle",
  },
  FD07: {
    de: "Lastschalter (elektrisch)",
  },
  FD09: {
    de: "Relais",
  },
  FD10: {
    de: "Schalter",
  },
  FD11: {
    de: "Schaltelemente (Energie)",
  },
  FD12: {
    de: "Spannungswähleinrichtung",
  },
  FD13: {
    de: "Traktionsstromrichter",
  },
  FD14: {
    de: "Stufenschalter",
  },
  FD15: {
    de: "Wendeschalter",
  },
  FD17: {
    de: "Kupplung (Energieumsetzung)",
  },
  FD18: {
    de: "Wandler (hydrostatisch)",
  },
  FD19: {
    de: "ungültig => M-FD13",
  },
  FD20: {
    de: "Mechanische Kupplungen inkl. Betätigung",
  },
  FD21: {
    de: "Hydraulische Kupplung",
  },
  FD22: {
    de: "Kardanantrieb inkl. Gehäuse",
  },
  FD23: {
    de: "Kettenantriebe",
  },
  FD24: {
    de: "Getriebelager, Hohlwellen",
  },
  FD25: {
    de: "Gelenk-, und Federkupplung",
  },
  FD26: {
    de: "Motorabstützungen",
  },
  FD27: {
    de: "Trieb- und Kuppelstangen inkl. Lager",
  },
  FE: {
    de: "Energieabführung",
    en: "Energy Dissipation",
    nl: "Energie Transmissie",
  },
  FE01: {
    de: "Kurzschließer",
  },
  FE02: {
    de: "Rückstromwandler",
  },
  FE03: {
    de: "Abgasanlage",
  },
  FE04: {
    de: "Partikelfilter (Emissionsausrüstung)",
  },
  FF: {
    de: "Energiespeicherung",
  },
  FF01: {
    de: "Batterie Verbrennungsmotor",
  },
  FF02: {
    de: "Förderpumpe (Kraftstoffanlage)",
  },
  FF03: {
    de: "Kraftstoffanlage",
  },
  G: {
    de: "Steuerungsanlage (Fahrbetrieb)",
  },
  GA: {
    de: "Steuerungsanlage (Fahrbetrieb)",
    en: "Steering system (during operation)",
  },
  GB: {
    de: "Steuerung Energiezuführung",
    en: "Energy Supply Control",
    nl: "Energievoorziening",
  },
  GB01: {
    de: "Wagenleitgerät (WLG)",
  },
  GC: {
    de: "Steuerung Fahren und Bremsen",
    en: "Control Ride and Brake",
    nl: "Rij- en rembediening",
  },
  GC01: {
    de: "Antriebsleitgerät (ALG)",
  },
  GC02: {
    de: "Bremsrechner (Bremssteuergerät)",
  },
  GC03: {
    de: "Drucktaster",
  },
  GC04: {
    de: "Fahrschalter",
  },
  GC05: {
    de: "Fahrzeugleitgerät (FLG)",
  },
  GC06: {
    de: "Schleuderschutz",
  },
  GC08: {
    de: "Sollwertumsetzer (Sollwertgeber)",
  },
  GC09: {
    de: "Wagenneigerechner",
  },
  GC10: {
    de: "Zugneigerechner",
  },
  GC11: {
    de: "Antriebssteuergerät (ASG)",
  },
  GC12: {
    de: "Fahr-& Bremssteuerung (AFB)",
  },
  GC13: {
    de: "Zugsteuerleitgerät (ZSG)",
  },
  GC14: {
    de: "Steuerkasten, Steuerschalter",
  },
  GD: {
    de: "Steuerung Energieerzeugung",
    en: "Control Energy Generation",
  },
  GD01: {
    de: "Verbrennungsmotorsteuerung",
  },
  GE: {
    de: "Systemerfassung",
    en: "System registration",
  },
  GE01: {
    de: "Accelerometer",
  },
  GE02: {
    de: "Beschl.sensor Neigetechnik",
  },
  GE03: {
    de: "Wegimpulsgeber",
  },
  GE04: {
    de: "Gleit-/Schleuderschutzgeber",
  },
  GE05: {
    de: "Radar",
  },
  GE06: {
    de: "Spannungswandler",
  },
  GE07: {
    de: "Strom-/Spannungswandler",
  },
  GE08: {
    de: "Stromwandler",
  },
  GE09: {
    de: "Temperaturerfassung",
  },
  GE10: {
    de: "Energiezähler",
  },
  GE11: {
    de: "Geschwindigkeitsgeber",
  },
  GE12: {
    de: "Hilfsschalter und Anbauteile",
  },
  GF: {
    de: "Steuerung Energiespeicherung",
    en: "Control Energy Storage",
  },
  GF01: {
    de: "Fahrbatterieüberwachung",
  },
  GF02: {
    de: "Tankanzeige",
  },
  H: {
    de: "Hilfsbetriebsanlage",
  },
  HA: {
    de: "Hilfsbetriebsanlage",
    en: "Auxiliary operation unit",
  },
  HA01: {
    de: "Batteriestromkreis",
  },
  HA03: {
    de: "Hilfsbetriebestromkreis",
  },
  HB: {
    de: "Umformereinrichtung",
    en: "Converter Equipment",
  },
  HB01: {
    de: "Batteriehauptschalter",
  },
  HB02: {
    de: "Bordnetzumrichter/Hilfsbetriebeumrichter",
  },
  HB05: {
    de: "Hilfstransformator",
  },
  HC: {
    de: "Batterieeinrichtung",
    en: "Battery Unit",
  },
  HC01: {
    de: "Batterie",
  },
  HC02: {
    de: "Batterieladegerät",
  },
  HC03: {
    de: "Batteriesicherung",
  },
  HC05: {
    de: "Batterieschütz",
  },
  HD: {
    de: "Bordnetz",
    en: "On-Board Power Supply",
  },
  HD01: {
    de: "Bordnetz 50Hz",
  },
  HD02: {
    de: "Steckdose",
  },
  HD03: {
    de: "Zugsammelschiene",
  },
  HD04: {
    de: "Fremdeinspeisung",
  },
  HE: {
    de: "Kühlung Energie-, Antriebsanl.",
    en: "Cooling Energy and Propulsion",
  },
  HE01: {
    de: "Fahrmotorlüfter",
  },
  HE02: {
    de: "Luftansaugung.",
  },
  HE03: {
    de: "Ölkühlanlage",
  },
  HE04: {
    de: "Wärmetauscher",
  },
  HE05: {
    de: "Wasserkühlanlage",
  },
  HE06: {
    de: "Stromrichterkühlanlage",
  },
  HE07: {
    de: "Trafokühlanlage",
  },
  HE08: {
    de: "Kühlwasservorwärmanlage",
  },
  HE09: {
    de: "Kühlanlage",
  },
  HE10: {
    de: "Kühlwasserpumpe und Filter",
  },
  HF: {
    de: "Notstromerzeugungseinrichtung",
  },
  J: {
    de: "Überwachung und Sicherheit",
  },
  JA: {
    de: "Überwachungs-,Sicherheitseinricht. APFZ",
    en: "Surveillance and Security System",
  },
  JA01: {
    de: "Überwachungs Anlage, Video",
  },
  JA02: {
    de: "Notsprechstelle (Sicherheitseinricht.)",
  },
  JA03: {
    de: "Videokamera (Innen)",
  },
  JA04: {
    de: "Überwachungsstromkreis",
  },
  JB: {
    de: "Mess- und Schutzeinrichtungen",
    en: "Measuring and Safety Devices",
  },
  JB01: {
    de: "Drehzahlbegrenzung",
  },
  JB02: {
    de: "Drehzahlgeber",
  },
  JB03: {
    de: "Druckmesser",
  },
  JB04: {
    de: "Druckwächter",
  },
  JB05: {
    de: "Erdschlussüberwachung",
  },
  JB06: {
    de: "Fehlerstromschutzschalter",
  },
  JB07: {
    de: "Geschwindigkeitsmesser",
  },
  JB08: {
    de: "Stromüberwachung",
  },
  JB09: {
    de: "Schutzschalter/Schaltautom.(CMC)",
  },
  JB10: {
    de: "Temperaturüberwachung",
  },
  JB11: {
    de: "Thermoauslöser",
  },
  JB12: {
    de: "Überspannungsschutz",
  },
  JB14: {
    de: "Wasserstandsanzeige",
  },
  JB15: {
    de: "Lokschutz",
  },
  JB16: {
    de: "Minimalspannungsrelais (Schutzeinricht.)",
  },
  JB17: {
    de: "Brandmeldeanlage, Schutzeinrichtungen",
  },
  JB18: {
    de: "Druckschalter",
  },
  JB19: {
    de: "Kühlwasserstandsanzeige",
  },
  JB20: {
    de: "Kombinierte Sicherheits- und Thermostatventile",
  },
  JC: {
    de: "Melden,Registrieren,Anzeigen",
    en: "Report, Register, Display",
  },
  JC01: {
    de: "Bremsanzeigeeinrichtung",
  },
  JC02: {
    de: "Diagnoseeinrichtung",
  },
  JC03: {
    de: "Fahrtschreiber",
  },
  JC04: {
    de: "Geschwindigkeitsanzeige",
  },
  JC05: {
    de: "Kilometerzähler",
  },
  JC06: {
    de: "Luftfederanzeige",
  },
  JC07: {
    de: "Spannungsmesser",
  },
  JC08: {
    de: "Strommesser",
  },
  JC09: {
    de: "Zentralsteuergerät (ZSG)",
  },
  JC10: {
    de: "Kraftstofffüllstandsanzeiger",
  },
  JC11: {
    de: "Betriebsstundenzähler",
  },
  JC12: {
    de: "Batterieladezustandsanzeige",
  },
  JC13: {
    de: "Bedien- u. Diagnoseterminal (BuD)",
  },
  JD: {
    de: "Sicherheitseinrichtungen",
    en: "Safety Devices",
  },
  JD01: {
    de: "ETCS (Zugsicherungssystem)",
  },
  JD02: {
    de: "Fahrsperre",
  },
  JD03: {
    de: "Brandlöschanlage",
  },
  JD04: {
    de: "Indusi (ATP) (Zugsicherungssystem)",
  },
  JD05: {
    de: "Makrofon",
  },
  JD06: {
    de: "SCMT (FS) (Zugsicherungssystem)",
  },
  JD07: {
    de: "Sicherheitssteuerung",
  },
  JD08: {
    de: "Signalpfeife",
  },
  JD09: {
    de: "Thyphon",
  },
  JD10: {
    de: "Totmannpedal",
  },
  JD11: {
    de: "ZUB (Zugsicherungssystem)",
  },
  JD12: {
    de: "Zugsicherungseinrichtung",
  },
  JD13: {
    de: "Signum (Zugsicherungssystem)",
  },
  JD14: {
    de: "ETM (Zugsicherungssystem)",
  },
  JD15: {
    de: "LZB (Zugsicherungssystem)",
  },
  JD16: {
    de: "KVB (SNCF, Zugsicherungssystem)",
  },
  JD17: {
    de: "R.S.M (Zugsicherungssystem)",
  },
  JD18: {
    de: "Crocodile (Zugsicherungssystem)",
  },
  JE: {
    de: "Datenübertragsungseinrichtung",
    en: "Data transmission device",
  },
  JE01: {
    de: "AFI",
  },
  JE02: {
    de: "Antennen",
  },
  JE03: {
    de: "Balisen",
  },
  JE04: {
    de: "Bus und Komm. Steuerleitungen",
  },
  JE05: {
    de: "EP-Leitung (Steuerleitungen)",
  },
  JE06: {
    de: "Funk - Fernsteuerungsanlage",
  },
  JE07: {
    de: "UIC-Leitung (Steuerleitungen)",
  },
  JE08: {
    de: "Vielfachsteuerleitung VST III",
  },
  JE09: {
    de: "Vielfachsteuerung ZWS/ZDS/ZMS",
  },
  JE10: {
    de: "Bus und Komm. Echtzeit-/Steuerungsnetzwerk",
  },
  JE11: {
    de: "Bus und Komm. Multimedianetzwerk (Betreiber)",
  },
  JE12: {
    de: "Bus und Komm. Fahrgastnetzwerk (Internet/Email)",
  },
  JF: {
    de: "Kommunikationseinrichtungen",
    en: "Communication Devices",
  },
  JF01: {
    de: "Zug - Funkanlage",
  },
  JF03: {
    de: "Sprechstelle Zugbegleiter",
  },
  JF04: {
    de: "Rangierfunk",
  },
  JF05: {
    de: "Mobil - Repeater (GSM, GPRS, UMTS, LTE)",
  },
  JF06: {
    de: "GSM-Modul (GSM, GPRS, UMTS, LTE, WLAN)",
  },
  JF07: {
    de: "Ortungsmodul (GPS, RFID)",
  },
  JG: {
    de: "Zusätzliche Ausstattungen",
    en: "Additional Equipment",
  },
  JG01: {
    de: "Aussenspiegel",
  },
  JG02: {
    de: "Erste-Hilfe-Kasten",
  },
  JG03: {
    de: "Rollo",
  },
  JG04: {
    de: "Scheibenheizungsanlage",
  },
  JG05: {
    de: "Scheibenreinigungsanlage",
  },
  JG06: {
    de: "Spiegelsteuerung",
  },
  JG07: {
    de: "Selbstretter/Fluchthaube",
  },
  JG08: {
    de: "Notsignallampe",
  },
  JG09: {
    de: "Videokamera(Aussenbeobachtung)",
  },
  JG10: {
    de: "Fahrplanhalter/ LEA Halter",
  },
  JG11: {
    de: "LEA Ladegerät",
  },
  JG12: {
    de: "Wischerantrieb ",
  },
  JG13: {
    de: "Wischerarm",
  },
  JG14: {
    de: "Wischerblatt ",
  },
  JG15: {
    de: "Scheibenwaschmittelbehälter",
  },
  JG16: {
    de: "Scheibenwaschmittelpumpe",
  },
  JG17: {
    de: "Steuerung Wisch-/ Wasch-Anlage",
  },
  K: {
    de: "Beleuchtung",
  },
  KA: {
    de: "Beleuchtung",
    en: "Lighting Equipment",
  },
  KB: {
    de: "Aussenbeleuchtungseinrichtung",
    en: "Outdoor Lighting Equipment",
  },
  KB01: {
    de: "Scheinwerfer (Beleuchtung)",
  },
  KB02: {
    de: "Signalleuchte (Beleuchtung)",
  },
  KB03: {
    de: "Stirnbeleuchtung",
  },
  KB04: {
    de: "Zugschluss (Beleuchtung)",
  },
  KB05: {
    de: "Beleuchtungssteuerung",
  },
  KB06: {
    de: "Beleuchtungsgenerator (Systeme)",
  },
  KC: {
    de: "Innenbeleuchtungseinrichtung",
    en: "Indoor Lighting Equipment",
  },
  KC01: {
    de: "Dienstbeleuchtung",
  },
  KC02: {
    de: "Führerraumbeleuchtung",
  },
  KC03: {
    de: "Innenraumbeleuchtung",
  },
  KC04: {
    de: "Instrumentenbeleuchtung",
  },
  KC05: {
    de: "Leseleuchte (Beleuchtung)",
  },
  KC06: {
    de: "Maschinenraumbeleuchtung",
  },
  KC07: {
    de: "Notbeleuchtung",
  },
  KC08: {
    de: "Pultbeleuchtung",
  },
  KC09: {
    de: "Trittstufenbeleuchtung",
  },
  L: {
    de: "Klimatisierung (HLK)",
  },
  LA: {
    de: "Klimatisierung (HLK)",
    en: "Air Conditioning",
  },
  LA01: {
    de: "Konvektoren, Flächen-, Boden-, Warmluftheizung (HLK)",
  },
  LA02: {
    de: "Klimaanlage (HLK)",
  },
  LA03: {
    de: "Kälteanlage, VVA (HLK)",
  },
  LA04: {
    de: "Lüftung (HLK)",
  },
  LA05: {
    de: "Führerstandsheizung (elektrisch / thermisch)",
  },
  LB: {
    de: "Ansaugung, Entlüftung (HLK)",
    en: "Suction, Ventilation",
  },
  LB01: {
    de: "Belüftung (HLK)",
  },
  LB02: {
    de: "Dachlüfter (HLK)",
  },
  LB03: {
    de: "Führerraumbelüftung",
  },
  LB04: {
    de: "Luftansaugung (HLK, Aussenluft, Abluft, Raum)",
  },
  LB05: {
    de: "Luftauslass (HLK, Zuluft, Fortluft)",
  },
  LB06: {
    de: "Aussen-, Ab-, Zu-, Fortluftventilator",
  },
  LC: {
    de: "Aufbereitung (Klimatisierung)",
    en: "Air Treatment",
  },
  LC01: {
    de: "Entwässerungseinrichtung (HLK)",
  },
  LC02: {
    de: "Heizband",
  },
  LC03: {
    de: "Nachheizer, Einstiegs-, Umluft-, Portalheizer (HLK)",
  },
  LC04: {
    de: "Kompaktklimagerät (HLK, KKG)",
  },
  LC05: {
    de: "Luftaufbereitungsaggregat (HLK, LAA)",
  },
  LC06: {
    de: "Luftfilter (HLK)",
  },
  LC07: {
    de: "Verdichter (HLK, Kältemittel)",
  },
  LC08: {
    de: "Verflüssiger HLK, Wärmetauscher aussen",
  },
  LC09: {
    de: "Kältemittel R134a (HLK, Verbrauch)",
  },
  LC11: {
    de: "Kältemittel andere (HLK, Verbrauch)",
  },
  LC12: {
    de: "Verdampfer HLK, Wärmetauscher innen",
  },
  LC13: {
    de: "Hoch-/Niederdruckschalter, Expansions-, Magnetventile, Schauglas",
  },
  LD: {
    de: "Verteilung (HLK)",
    en: "Air Distribution",
  },
  LD01: {
    de: "Luftkanal, Flex.-kanal, Balg, Wärmedämmung (HLK)",
  },
  LD02: {
    de: "Lüftungsgitter, Luftdüse, Wetterschutz (HLK)",
  },
  LD03: {
    de: "Lüftungsklappe, Stellmotor, Druckwellenventil (HLK)",
  },
  LD04: {
    de: "Ventilator, Zuluft, Abluft, Fortluft HLK",
  },
  LE: {
    de: "Regelung, Überwachung (HLK)",
    en: "Air Control and Monitoring",
  },
  LE01: {
    de: "Bedien-, Anzeigeeinrichtung (HLK)",
  },
  LE02: {
    de: "Druckauswerteeinheit",
  },
  LE03: {
    de: "Sollwertgeber, Raumlufttemperatursensor (HLK)",
  },
  LE04: {
    de: "Steuerung, Regler, Leittechnik (HLK)",
  },
  LE05: {
    de: "Übertemperaturschalter, -begrenzer (Kurzschliesser) (HLK)",
  },
  LE06: {
    de: "Sensor, Temperatur-, Druck-, CO2-Sensor (HLK)",
  },
  M: {
    de: "Nebenbetriebsanlage",
  },
  MA: {
    de: "Nebenbetriebsanlage",
    en: "Auxiliary Operation unit",
  },
  MA01: {
    de: "Fahrzeugplattform (IT) APFZ",
  },
  MB: {
    de: "Sander",
    en: "Sander",
  },
  MB01: {
    de: "Sandbehälter",
  },
  MB03: {
    de: "Sanderdeckel",
  },
  MB04: {
    de: "Sanderrohr",
  },
  MC: {
    de: "Spurkranzschmierung",
    en: "Flange Oilers",
  },
  MC01: {
    de: "Leitung (Spurkranzschmierung)",
  },
  MC02: {
    de: "Spritzdüse (Spurkranzschmierung)",
  },
  MC04: {
    de: "Vorratsbehälter (Spurkranzschmierung)",
  },
  MC05: {
    de: "Steuerung (Spurkranzschmierung)",
  },
  MD: {
    de: "Weichensteuerung.",
    en: "Switches control units",
  },
  ME: {
    de: "Fahrausweiseinrichtungen.",
    en: "Ticket systems",
  },
  MF: {
    de: "Fahrgastzähleinrichtung AFZ",
    en: "Passenger Counting System",
  },
  MF01: {
    de: "Lichtschranke",
  },
  N: {
    de: "Türen, Einstiege",
  },
  NA: {
    de: "Türen, Einstiege",
    en: "Doors, Entries",
  },
  NB: {
    de: "Aussentüren",
    en: "Outer Door",
  },
  NB01: {
    de: "Ladetor (Cateringtor, Gepäcktor)",
  },
  NB02: {
    de: "Einklemmschutz Überwachung (Einstiegstür",
  },
  NB03: {
    de: "Einstiegstür",
  },
  NB04: {
    de: "Führerraumtür aussen",
  },
  NB06: {
    de: "Notöffnung (Einstiegstür)",
  },
  NB09: {
    de: "Türantrieb  (Einstiegstür)",
  },
  NB10: {
    de: "Betätigung/Steuerung (Einstiegstür)",
  },
  NB11: {
    de: "Türmechanik (Einstiegstür)",
  },
  NB14: {
    de: "Übergangstür (alle Elemente)",
  },
  NB15: {
    de: "Vorbautüre",
  },
  NB16: {
    de: "Ladetor (Güterwagen)",
  },
  NC: {
    de: "Innentüren",
    en: "Inner Doors",
  },
  NC01: {
    de: "Brandschutztür  (alle Elemente)",
  },
  NC02: {
    de: "Abteiltrenntür",
  },
  NC03: {
    de: "Abteiltür",
  },
  NC04: {
    de: "Führerraumtür innen  (alle Elemente)",
  },
  NC06: {
    de: "Maschinenraumtür",
  },
  NC07: {
    de: "Türantrieb (Abteiltür)",
  },
  NC08: {
    de: "Betätigung, Steuerung (Abteiltür)",
  },
  NC10: {
    de: "WC Tür (alle Elemente)",
  },
  ND: {
    de: "Einstiege, Aufstiege (aussen)",
    en: "Entrances, Ascents (outside)",
  },
  ND01: {
    de: "Aufstieg",
  },
  ND02: {
    de: "Klapptritt",
  },
  ND03: {
    de: "Schiebetritt, Klapptritt",
  },
  ND04: {
    de: "Festtritt aussen",
  },
  ND05: {
    de: "Schiebetrittsteuerung",
  },
  NE: {
    de: "Behinderteneinstiege",
    en: "Handicapped Access",
  },
  NE01: {
    de: "Behindertenlift",
  },
  NE02: {
    de: "Behindertenrampe",
  },
  P: {
    de: "Informationseinrichtungen",
  },
  PA: {
    de: "Informationseinrichtungen",
  },
  PB: {
    de: "Kundeninformationssystem KIS",
    en: "Passenger Information System",
  },
  PB01: {
    de: "Kundeninformationssystem KIS",
  },
  PB02: {
    de: "Aussenanzeigen (KIS)",
  },
  PB03: {
    de: "Innenanzeigen (Matrix, KIS)",
  },
  PB04: {
    de: "Bildschirm (KIS)",
  },
  PB05: {
    de: "Zentralgerät (KIS)",
  },
  PB06: {
    de: "MMI (KIS)",
  },
  PC: {
    de: "Optische Informationselemente",
    en: "Optical Information Elements",
  },
  PC01: {
    de: "Anschriften (aussen , innen)",
  },
  PC02: {
    de: "Piktogramme",
  },
  PD: {
    de: "Unterhaltung",
    en: "Entertainment Systems",
  },
  PD01: {
    de: "Audio - Video - Anlage",
  },
  PE: {
    de: "Werbung",
    en: "Advertisements",
  },
  PE01: {
    de: "Poster, Plakate",
  },
  PF: {
    de: "akust. Informationseinrichtung",
  },
  PF01: {
    de: "Lautsprecheranlage",
  },
  PF02: {
    de: "Fernsprechanlage (alle Elemente)",
  },
  Q: {
    de: "Pneumatik/Hydraulik",
  },
  QA: {
    de: "Pneumatik/Hydraulik",
    en: "Pneumatics/Hydraulics",
  },
  QA01: {
    de: "Druckluftanlage",
  },
  QA02: {
    de: "Hydraulikanlage",
  },
  QA03: {
    de: "Hydraulikverbr. Neigetechnik",
  },
  QB: {
    de: "Erzeugung (Pneumatik/Hydraulik)",
    en: "Generation (pneumatics/hydraulics)",
  },
  QB01: {
    de: "Kompressor, Luftverdichter",
  },
  QB02: {
    de: "Hilfskompressor",
  },
  QC: {
    de: "Aufbereitung (Pneumatik / Hydraulik)",
    en: "Processing (pneumatics/hydraulics)",
  },
  QC01: {
    de: "Lufttrocknungsanlage",
  },
  QC02: {
    de: "Wasserabscheider",
  },
  QC03: {
    de: "Ölabscheider",
  },
  QC04: {
    de: "Luftfilter Pneumatik",
  },
  QC05: {
    de: "Luftkühler, Oelkühler",
  },
  QD: {
    de: "Bevorratung (Pneumatik/Hydraulik)",
    en: "Stocking (pneumatics/hydraulics)",
  },
  QD01: {
    de: "Luftbehälter",
  },
  QD02: {
    de: "Ölbehälter",
  },
  QE: {
    de: "Verteilung (Pneumatik/Hydraulik)",
    en: "Distribution (pneumatics/hydraulics)",
  },
  QE01: {
    de: "Druckbegrenzer",
  },
  QE02: {
    de: "Druckluftschlauch, Bremskupplungshahn",
  },
  QE03: {
    de: "Gleitschutzeinrichtung",
  },
  QE04: {
    de: "Löseeinrichtung",
  },
  QE05: {
    de: "Notlöseeinrichtung",
  },
  QE06: {
    de: "Rohrverlegung",
  },
  R: {
    de: "Bremse",
  },
  RA: {
    de: "Bremse",
    en: "Braking System",
  },
  RA01: {
    de: "Bremseinheit",
  },
  RA02: {
    de: "Feststellbremse",
  },
  RA03: {
    de: "Handbremse",
  },
  RA04: {
    de: "Klotzbremse",
  },
  RA05: {
    de: "Magnetschienenbremse",
  },
  RA06: {
    de: "Notbremse",
  },
  RA07: {
    de: "Pneumatische Bremse",
  },
  RA08: {
    de: "Scheibenbremse",
  },
  RA09: {
    de: "Bremse elektrisch",
  },
  RB: {
    de: "Bremselemente",
    en: "Brake Elements",
  },
  RB02: {
    de: "Bremsartenwechsel (RIC,GP...)",
  },
  RB03: {
    de: "Bremsabsperrhahn",
  },
  RB04: {
    de: "Bremsbelag, Halter",
  },
  RB05: {
    de: "Bremsdreieck",
  },
  RB06: {
    de: "Bremsgestängesteller",
  },
  RB07: {
    de: "Bremshängeeisen",
  },
  RB08: {
    de: "Bremsscheibe",
  },
  RB09: {
    de: "Bremssohle",
  },
  RB10: {
    de: "Bremssohlenhalter",
  },
  RB11: {
    de: "Bremsspindel",
  },
  RB13: {
    de: "Bremszange",
  },
  RB14: {
    de: "Bremszylinder",
  },
  RB15: {
    de: "Führerbremsventil",
  },
  RB17: {
    de: "Notbremsventil",
  },
  RB18: {
    de: "Notbremszug",
  },
  RB21: {
    de: "Schnellbremsbeschleuniger",
  },
  RB23: {
    de: "Rangierbremsventil",
  },
  RB25: {
    de: "Steuerventil",
  },
  RB26: {
    de: "Pneumatikventil (weitere)",
  },
  RB27: {
    de: "EP-Compact",
  },
  RB28: {
    de: "Bremsanzeige",
  },
  RB29: {
    de: "Schnellbremsventil ",
  },
  RB30: {
    de: "Auslösevorrichtung (Bremse)",
  },
  RB31: {
    de: "Wiegeventil (Güterwagen)",
  },
  RC: {
    de: "Mech. Bremskraftübertragung",
    en: "Brake Force Transmission (mechanical)",
  },
  RC01: {
    de: "Bremsgestänge",
  },
  RC02: {
    de: "Federspeicherzylinder",
  },
  RC03: {
    de: "Mittenbremsgestänge",
  },
  S: {
    de: "Fahrzeugverbindungseinrichtungen",
  },
  SA: {
    de: "Fahrzeugverbindungseinrichtung",
    en: "Connecting device (couches)",
  },
  SA01: {
    de: "feste Fahrzeug-Gelenkverbindung",
  },
  SB: {
    de: "Autom. Kupplungseinrichtung",
    en: "Automatic Coupling device",
  },
  SB01: {
    de: "Autom. Frontkupplung",
  },
  SB02: {
    de: "Elektrokupplung",
  },
  SB03: {
    de: "Halbautom. Zwischenkupplung (Wagen)",
  },
  SB04: {
    de: "Kupplungsheizung",
  },
  SB05: {
    de: "Kupplungskopf",
  },
  SB06: {
    de: "Kupplungssteuerung",
  },
  SB07: {
    de: "Vorschub-/Ausfahreinrichtung(autom. Kupplung)",
  },
  SB08: {
    de: "Energieaufnahmeelem.reversibel(autom. Kupplung)",
  },
  SB09: {
    de: "Mittenzentrierung (autom. Kupplung)",
  },
  SB10: {
    de: "Höhenabstützung (autom. Kupplung)",
  },
  SC: {
    de: "Manuelle Zugeinrichtung",
    en: "Manual Train setup devices",
  },
  SC01: {
    de: "Entkupplungseinrichtung",
  },
  SC02: {
    de: "Kurzkupplung (Wagen)",
  },
  SC03: {
    de: "Zughaken",
  },
  SC04: {
    de: "Energieaufnahmeelement (manuelle Kupplung)",
  },
  SC05: {
    de: "Rangierkupplung",
  },
  SC06: {
    de: "Schraubenkupplung",
  },
  SD: {
    de: "Stoss-, Puffereinrichtungen",
    en: "Bumpers, Buffer devices",
  },
  SD01: {
    de: "Hilfspuffer (automatische Kupplung)",
  },
  SD02: {
    de: "Puffer",
  },
  SE: {
    de: "Abschleppkupplung (automatische Kupplung)",
  },
  SE01: {
    de: "Hilfskupplung",
  },
  SF: {
    de: "Übergänge/Übergangseinrichtung",
    en: "Transitions/Transition devices",
  },
  SF01: {
    de: "Personenübergang (PUG)",
  },
  SF03: {
    de: "Faltenbalg/Wellenbalg (PUG)",
  },
  SF04: {
    de: "Gummiwulst (an Einzelwagen, laut UIC)",
  },
  SF05: {
    de: "Übergangsbrücke",
  },
  SF06: {
    de: "Innenverkleidung zu Personenübergang (PUG)",
  },
  SG: {
    de: "Schnittstelle Fz-Fz",
    en: "Interfaces Coach-Coach",
  },
  SG01: {
    de: "Druckluftverbindung",
  },
  SG02: {
    de: "elektrische Steckkupplungen",
  },
  SG03: {
    de: "elektr. Vielfachsteuerkupplung (ep)",
  },
  SG04: {
    de: "elektrische UIC Steckkupplung",
  },
  SG05: {
    de: "Zugsammelschiene Steckkupplung",
  },
  T: {
    de: "Tragsystem, Umschliessungen",
  },
  TA: {
    de: "Silo (Güterwagen)",
    en: "Silo (freight wagon)",
  },
  TA01: {
    de: "Siloauslauf (Güterwagen)",
  },
  TA02: {
    de: "Luke (Güterwagen)",
  },
  TB: {
    de: "Schränke, Behälter, Container",
    en: "Cabinets, Boxes, Containers",
  },
  TB01: {
    de: "Apparatekasten",
  },
  TB02: {
    de: "Batteriekasten",
  },
  TB03: {
    de: "Sicherungskasten",
  },
  TB04: {
    de: "Elektronikapparatekasten",
  },
  TB05: {
    de: "Garderobeschrank",
  },
  TB06: {
    de: "Hochspannungs - Apparatekasten",
  },
  TB07: {
    de: "Klemmenkasten",
  },
  TB08: {
    de: "Dokumentenschrank",
  },
  TC: {
    de: "Gerüste, Tafeln",
    en: "Scaffolds, Boards",
  },
  TC01: {
    de: "Bremsgerätetafel",
  },
  TC02: {
    de: "Pneumatikgerüst",
  },
  TC03: {
    de: "Schalttafel (Gerüst)",
  },
  TC04: {
    de: "Sicherungstafel",
  },
  TD: {
    de: "Bedien- und Anzeigeeinheiten",
    en: "Operating and displaying Units",
  },
  TD01: {
    de: "Bedien - Schalttafel (S1)",
  },
  TD02: {
    de: "Bedien-/Diagnosedisplay",
  },
  TD03: {
    de: "Führerpult (Bedienpult)",
  },
  TD04: {
    de: "Elektron. Buchfahrplan EbuLa",
  },
  TE: {
    de: "Elektronische Aufbausysteme",
    en: "Electronic assembly systems",
  },
  TE01: {
    de: "Baugruppenträger",
  },
  U: {
    de: "Elektrische Leitungsverlegung",
  },
  UA: {
    de: "Elektrische Leitungsverlegung",
    en: "Electric cable routing",
  },
  UA01: {
    de: "Verkabelung",
  },
  UB: {
    de: "Kabel, Leitungen und Schienen",
    en: "Cables, Wires, Rails",
  },
  UB01: {
    de: "Erdungsleitung",
  },
  UB02: {
    de: "Lichtwellenleiter",
  },
  UC: {
    de: "Bezeichnungs-, Anschlussmat.",
    en: "Designation, Connection materials",
  },
  UD: {
    de: "Verbindungsmaterial (elektrisch)",
    en: "Connection materials",
  },
  UD01: {
    de: "Klemme, Klemmenleiste",
  },
  UD02: {
    de: "Steckverbinder",
  },
  UE: {
    de: "Durchführungen (elektrisch)",
    en: "Grommets (electrical)",
  },
  UE01: {
    de: "Durchführungsisolator",
  },
  UF: {
    de: "Leitungskanäle, -röhre",
    en: "Bus duct",
  },
  UF01: {
    de: "Kabelkanal",
  },
  UF02: {
    de: "Kabelrohr",
  },
  UF03: {
    de: "Schutzschlauch",
  },
};

function formatCode(code: string) {
  switch (code.length) {
    case 2:
      return code + "00";
    case 4:
      return code;
    default:
      return null;
  }
}

export function getDinCodeOptions(
  format: (code: string, text: string) => string = (code, text) =>
    code + " -- " + text
): Options {
  const result: Options = {};
  for (const raw in dinCodes) {
    let clean = formatCode(raw);
    if (clean == null) {
      continue;
    }
    let { de } = dinCodes[raw];
    let { nl } = dinCodes[raw];
    let { en } = dinCodes[raw];
    // const en = de;
    if (!en) {
      en = de;
    }
    if (!nl) {
      nl = en;
    }
    result[clean] = {
      text: {
        de: format(clean, de),
        en: format(clean, en),
        nl: format(clean, nl),
      },
    };
  }
  return result;
}
