import { RMSubmenuServiceType, RmSection } from "./rm-submenu.service.type";
import { AppType, APP_TYPE } from "../app.type";
import { Inject, Injectable } from "@angular/core";
import { BehaviorSubject } from "rxjs";

@Injectable()
export class RMSubmenuService implements RMSubmenuServiceType {
  section: RmSection = "status";
  sectionSubject: BehaviorSubject<RmSection> = new BehaviorSubject<RmSection>(
    "status"
  );

  constructor(@Inject(APP_TYPE) private app: AppType) {}

  setSection(selectedSection: RmSection) {
    this.section = selectedSection;
    this.sectionSubject.next(selectedSection);
  }

  getSectionLabel(selectedSection: RmSection) {
    switch (selectedSection) {
      case "status":
        return this.app.field.getLabel(
          this.app.fieldId.part.obsolescenceStatus
        );
      case "risk":
        return this.app.field.getLabel(this.app.fieldId.part.totalRisk);
      case "likelihood":
        return this.app.field.getLabel(this.app.fieldId.part.likelihood);
      case "impact":
        return this.app.field.getLabel(this.app.fieldId.part.impact);
      case "rohs":
        return this.app.text.RM.rohsColumns;
      case "reach":
        return this.app.text.RM.reachColumns;
      case "lead":
        return this.app.text.RM.leadColumns;
    }
  }
}
