import {
  ComponentSettings,
  FieldTypes,
  FilterTypes,
  empty,
} from "../settings/settings";
import { checkBoxOptions } from "./manufacturer.settings";

const field = {
  entryCheckbox: {
    type: FieldTypes.checkbox,
    text: {
      de: "KLÜ-Eintrag",
      en: "CRT-Entry",
      nl: "CRT-Entry",
    },
    checkboxLabels: checkBoxOptions,
  },
  omfCommodityId: {
    type: FieldTypes.value,
    text: {
      de: "Warengruppe",
      en: "Commodity",
      nl: "Productgroep",
    },
  },
  crtNumber: {
    type: FieldTypes.value,
    xml: "itemMfrNumber",
    text: {
      de: "Herstellerteilenummer",
      en: "Manufacturer Part Number",
      nl: "Fabrikant Artikel Nummer",
    },
    unique: false,
    maxLength: 128,
    filter: FilterTypes.startsWith,
  },
  partNumber: {
    type: FieldTypes.value,
    xml: "artNumber",
    text: {
      de: "SAP Nummer",
      en: "Customer Part Number",
      nl: "Artikel Nummer",
    },
    required: true,
    filter: FilterTypes.startsWith,
  },
  werk: {
    type: FieldTypes.text,
    text: {
      de: "Werk",
      en: "Plant",
      nl: "Productielocatie",
    },
    maxLength: 128,
  },
  omfShortDescr: {
    type: FieldTypes.text,
    xml: "itemMfrName",
    text: {
      de: "SAP Kurztext",
      en: "Designation of the Item (Name/short text)",
      nl: "Item omschrijving",
    },
    required: true,
    maxLength: 128,
  },
};

const settings = {
  permission: {},
  text: {
    itemExists: {
      de: "Artikel ist bereits vorhanden",
      en: "Item already exists",
      nl: "Item bestaat al.",
    },
    itemSavedSuccesfully: {
      de: "Artikel erfolgreich gespeichert!",
      en: "Item saved succesfully!",
      nl: "Item succesvol opgeslagen!",
    },
    discard: {
      de: "Änderungen verwerfen",
      en: "Discard Changes",
      nl: "Wijzigingen negeren",
    },
    deleteInternalItem: {
      de: "Sind Sie sicher dass Sie diesen Eintrag löschen möchten?",
      en: "Are you sure you want to delete this Entry?",
      nl: "Weet u zeker dat u deze Afbeelding wilt verwijderen?",
    },
  },
  url: {},
  field,
  list: {
    columns: empty,
    fieldsOnFilter: empty,
  },
};

export const internalItem: ComponentSettings<typeof settings> = settings;
