<app-modal
  #unFavModal
  [modalMessage]="app.text.thread.unFavCaseMessage"
  [modalTitle]="app.text.thread.modalTitle"
  (ok)="setFav(false)"
>
</app-modal>
<div class="card mx-1 mb-3 mt-1" style="cursor: pointer">
  <!-- <a [routerLink]="app.thread.getRouterURL(doc._id)"> -->
  <div class="card-header p-0 m-0" [ngClass]="headerColor">
    <span
      class="btn btn-sm thread-detail status ms-3"
      *ngIf="model !== null"
      [ngClass]="getStatusStyle(model[app.fieldId.thread.omfStatus])"
    >
      Status:&nbsp;
      {{
        app.field.getFieldValueAsText(
          app.fieldId.thread.omfStatus,
          model["thread.omfStatus"]
        )
      }}
      &nbsp;
      <i
        class="fa fa-lock"
        *ngIf="model[app.fieldId.thread.omfStatus] === '120'"
      ></i>
    </span>
    <span class="float-end">
      <button class="btn btn-sm">
        <i 
          class="fas fa-comment icon" 
          [ngClass]="iconColor"
        ></i
        >&nbsp;
        <span 
          *ngIf="commentCount > 0" 
          class="thread-detail" 
          [ngClass]="textColor"
          >{{ commentCount }}&nbsp;{{ app.text.thread.comment }}</span
        >
        <span 
          *ngIf="commentCount == 0" 
          class="thread-detail" 
          [ngClass]="textColor">
          0&nbsp;{{ app.text.thread.comment }}</span
        >
      </button>
      <button *ngIf="app.permission.post.hasTasks" class="btn btn-sm">
        <i
          class="fas fa-tasks icon"
          [ngClass]="{
            'green-icon': completedTasksCount === taskCount && taskCount > 0
          }"
        ></i
        >&nbsp;
        <span class="thread-detail" *ngIf="taskCount > 0">
          {{ completedTasksCount | json }} / {{ taskCount | json }}&nbsp;{{
            app.text.thread.task
          }}</span
        >
        <span class="thread-detail" *ngIf="taskCount == 0">
          0&nbsp;{{ app.text.thread.task }}</span
        >
      </button>
      <button class="btn btn-sm">
        <i
          class="fas fa-lightbulb icon"
          [ngClass]="{ 'green-icon': finalSolution !== '' }"
        ></i
        >&nbsp;
        <span
          *ngIf="solutionCount > 0"
          class="thread-detail"
          [ngClass]="textColor"
          >{{ solutionCount }}&nbsp;{{ app.text.thread.solution }}</span
        >
        <span
          *ngIf="solutionCount == 0"
          class="thread-detail"
          [ngClass]="textColor"
        >
          0&nbsp;{{ app.text.thread.solution }}</span
        >
      </button>
      <button
        *ngIf="isFavorite"
        class="btn btn-sm"
        (click)="
          $event.stopPropagation(); $event.preventDefault(); unFavModal.open()
        "
        [title]="app.text.thread.unfav"
      >
        <i class="fas fa-star icon mx-1 px-1" [ngClass]="iconColor"></i>
      </button>
      <button
        *ngIf="!isFavorite"
        class="btn btn-sm"
        (click)="
          $event.stopPropagation();
          $event.preventDefault();
          app.thread.thread = model;
          setFav(true)
        "
        [title]="app.text.thread.fav"
      >
        <i class="far fa-star icon mx-1 px-1" [ngClass]="iconColor"></i>
      </button>
    </span>
  </div>
  <!-- (click)="app.routing.navigateThread(doc._id)" -->
  <div class="row col-no-mg card-content">
    <div class="col-md-4" [ngStyle]="{ 'background-color': '#ffffff' }">
      <div class="btn btn-sm card-title mt-1" *ngIf="model !== null">
        <b>{{ model["thread.title"] }}</b>
      </div>
      <div>
        <img
          *ngIf="showUploadedImage(doc)"
          class="image"
          [src]="app.file.getUrl(this.app.thread.getImage(doc._id))"
          loading="lazy"
          (load)="updateLoadedImageCounter()"
          width="150px"
          height="150px"
        />
        <img
          *ngIf="
            doc.externalImageSource !== '' && doc.externalImageSource != null
          "
          class="image"
          [src]="doc.externalImageSource"
          loading="lazy"
          (load)="updateLoadedImageCounter()"
          width="150px"
          height="150px"
        />
      </div>
    </div>
    <div class="col-md-8 mt-1">
      <div *ngFor="let field of app.list.threadItem.featureFields">
        <div class="row app-field" *ngIf="model !== null && model[field]">
          <div class="fieldLabel col-md-4">
            <div class="fieldName text-md-left">
              {{ app.field.getLabel(field) }}
            </div>
          </div>
          <div class="col-md-7">
            <div
              class="fieldValue text-md-left"
              *ngIf="
                field !== 'thread.omfStatus' &&
                field !== 'thread.descr' &&
                field !== 'thread.statusRisk' &&
                field !== 'thread.typeOfPost'
              "
            >
              {{ model[field] }}
            </div>
            <div
              class="fieldValue text-md-left"
              *ngIf="
                field === 'thread.statusRisk' || field === 'thread.typeOfPost'
              "
            >
              {{ app.field.getOptionText(field, model[field]) }}
            </div>
            <div
              class="fieldValue text-md-left"
              *ngIf="field === 'thread.descr'"
              (click)="$event.stopPropagation(); $event.preventDefault()"
            >
              <app-limit [textInput]="model[field]" limit="50"></app-limit>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- </a> -->
  <div class="card-footer p-0 m-0">
    <div class="float-end me-3">
      <span>
        <app-credits
          class="last-comment"
          *ngIf="model != null"
          type="thread"
          [inputDoc]="doc"
          [isEdited]="true"
        ></app-credits>
      </span>
      <span>
        <app-credits
          class="last-comment credits ms-2"
          *ngIf="model != null"
          type="thread"
          [inputDoc]="doc"
          [isCreated]="true"
        ></app-credits>
      </span>
    </div>
  </div>
</div>
