<div class="row mt-4">
  <div class="col-md-12">
    <h3>{{ app.getText("operations.actionsTitle") }}</h3>
  </div>
</div>

<div class="row mt-2">
  <div class="col-md-12">
    <div class="card">
      <div class="card-header bg-white text-center">
        <ul class="nav nav-tabs card-header-tabs">
          <li class="nav-item">
            <a
              *ngIf="app.permission.operations.seeMatchParts"
              class="nav-link pointer-link"
              role="button"
              [ngClass]="{ active: workType === 'match' }"
              (click)="workType = 'match'"
              >{{ app.getText("operations.matchParts") }}</a
            >
          </li>
          <li class="nav-item">
            <a
              *ngIf="app.permission.operations.seeRequestDetails"
              class="nav-link pointer-link"
              role="button"
              [ngClass]="{ active: workType === 'request-details' }"
              (click)="workType = 'request-details'"
              >{{ app.getText("operations.requestDetails") }}</a
            >
          </li>
          <li class="nav-item">
            <a
              *ngIf="app.permission.operations.seeUpdatesDetails"
              class="nav-link pointer-link"
              role="button"
              [ngClass]="{ active: workType === 'update-details' }"
              (click)="workType = 'update-details'"
              >{{ app.getText("operations.updateDetails") }}</a
            >
          </li>
          <li class="nav-item">
            <a
              *ngIf="app.permission.operations.seeGenerateTree"
              class="nav-link pointer-link"
              role="button"
              [ngClass]="{ active: workType === 'generate-tree' }"
              (click)="workType = 'generate-tree'"
              >{{ app.getText("operations.generateTree") }}</a
            >
          </li>
          <li class="nav-item">
            <a
              *ngIf="app.permission.operations.seeBuildBOM"
              class="nav-link pointer-link"
              role="button"
              [ngClass]="{ active: workType === 'build-bom' }"
              (click)="workType = 'build-bom'"
              >{{ app.getText("operations.buildBOM") }}</a
            >
          </li>
          <li class="nav-item">
            <a
              *ngIf="app.permission.operations.seeUpdatesImpacts"
              class="nav-link pointer-link"
              role="button"
              [ngClass]="{ active: workType === 'update-impacts' }"
              (click)="workType = 'update-impacts'"
              >{{ app.getText("operations.updateImpacts") }}</a
            >
          </li>
          <li class="nav-item">
            <a
              *ngIf="app.permission.operations.seeGenerateAlerts"
              class="nav-link pointer-link"
              role="button"
              [ngClass]="{ active: workType === 'generate-alerts' }"
              (click)="workType = 'generate-alerts'"
              >{{ app.getText("operations.generateAlerts") }}</a
            >
          </li>
        </ul>
      </div>
      <div class="card-body">
        <div class="row">
          <div class="col-md-6">
            <div [ngSwitch]="workType">
              <small *ngSwitchCase="'match'" class="text-muted"
                ><i class="fa fa-info-circle"></i>&nbsp;{{
                  app.getText("operations.matchPartsDescription")
                }}</small
              >
              <small *ngSwitchCase="'request-details'" class="text-muted"
                ><i class="fa fa-info-circle"></i>&nbsp;{{
                  app.getText("operations.requestDetailsDescription")
                }}</small
              >
              <small *ngSwitchCase="'update-details'" class="text-muted"
                ><i class="fa fa-info-circle"></i>&nbsp;{{
                  app.getText("operations.updateDetailsDescription")
                }}</small
              >
              <small *ngSwitchCase="'generate-tree'" class="text-muted"
                ><i class="fa fa-info-circle"></i>&nbsp;{{
                  app.getText("operations.generateTreeDescription")
                }}</small
              >
              <small *ngSwitchCase="'build-bom'" class="text-muted"
                ><i class="fa fa-info-circle"></i>&nbsp;{{
                  app.getText("operations.buildBOMDescription")
                }}</small
              >
              <small *ngSwitchCase="'update-impacts'" class="text-muted"
                ><i class="fa fa-info-circle"></i>&nbsp;{{
                  app.getText("operations.updateImpactsDescription")
                }}</small
              >
              <small *ngSwitchCase="'generate-alerts'" class="text-muted"
                ><i class="fa fa-info-circle"></i>&nbsp;{{
                  app.getText("operations.generateAlerts")
                }}</small
              >
            </div>
          </div>
          <div class="col-md-6 align-self-center">
            <div
              class="card"
              [ngClass]="{
                'no-border':
                  count == null ||
                  workType === 'update-details' ||
                  workType === 'generate-tree' ||
                  workType === 'update-impacts' ||
                  workType === 'generate-alerts'
              }"
            >
              <div class="card-body">
                <div class="row">
                  <div
                    *ngIf="
                      count != null &&
                      workType !== 'update-details' &&
                      workType !== 'generate-tree' &&
                      workType !== 'update-impacts' &&
                      workType !== 'generate-alerts'
                    "
                    class="col-md-4 text-danger text-center align-self-center"
                  >
                    <strong>{{ count.usedCount }}</strong>
                    {{ app.getText("operations.usedCount") }}
                  </div>
                  <div
                    *ngIf="
                      count != null &&
                      workType !== 'update-details' &&
                      workType !== 'generate-tree' &&
                      workType !== 'update-impacts' &&
                      workType !== 'generate-alerts'
                    "
                    class="col-md-4 text-success text-center align-self-center"
                  >
                    <strong>{{ count.remainingCount }}</strong>
                    {{ app.getText("operations.remainingCount") }}
                  </div>
                  <div
                    class="col-md-4"
                    [ngClass]="{
                      'col-md-12':
                        count == null ||
                        workType === 'update-details' ||
                        workType === 'generate-tree' ||
                        workType === 'update-impacts' ||
                        workType === 'generate-alerts',
                      'text-center':
                        workType !== 'update-details' &&
                        workType !== 'generate-tree' &&
                        workType !== 'update-impacts' &&
                        workType !== 'generate-alerts'
                    }"
                  >
                    <div
                      class="d-flex pt-1"
                      *ngIf="
                        workType === 'generate-alerts' && app.customer === 'mtu'
                      "
                    >
                      <div class="d-flex">
                        <label class="label">{{
                          app.field.getLabel(app.fieldId.part.standardRange)
                        }}</label>
                        <input
                          class="form-control searchInput"
                          type="number"
                          [(ngModel)]="parameterModel.standardRange"
                        />
                      </div>
                      <div>
                        <button
                          class="btn btn-sm btn-save"
                          (click)="saveStandardRange()"
                          [disabled]="isDisabled(true)"
                        >
                          <i class="fa fa-check"></i>
                          {{ app.text.operations.saveData }}
                        </button>
                      </div>
                    </div>
                    <button
                      type="button"
                      class="btn btn-outline-primary btn-sm float-right"
                      (click)="runWork()"
                      [disabled]="loading || isDisabled(false)"
                    >
                      <i
                        class="fa"
                        [ngClass]="{
                          'fa-spinner fa-spin': loading,
                          'fa-play-circle': !loading
                        }"
                      ></i
                      >&nbsp; {{ app.getText("operations.run") }}
                      {{ app.operations.getTypeText(workType) }}
                    </button>
                    <button
                      *ngIf="
                        workType === 'update-impacts' && app.customer === 'db'
                      "
                      type="button"
                      class="btn btn-outline-primary btn-sm float-right"
                      (click)="runUpdateTaskResponsibles()"
                      [disabled]="loading"
                    >
                      <i
                        class="fa"
                        [ngClass]="{
                          'fa-spinner fa-spin': loading,
                          'fa-play-circle': !loading
                        }"
                      ></i
                      >&nbsp; {{ app.getText("operations.run") }}
                      {{ app.text.operations.updateTaskResponsibles }}
                    </button>
                  </div>
                  <div
                    class="row"
                    *ngIf="
                      count != null &&
                      workType !== 'update-details' &&
                      workType !== 'generate-tree' &&
                      workType !== 'update-impacts' &&
                      workType !== 'generate-alerts'
                    "
                  >
                    <div
                      class="col-md-4 text-center align-self-center text-muted info"
                    >
                      {{ app.getText("operations.limitCount") }}
                      <strong>{{ count.limitCount }}</strong>
                    </div>
                    <div
                      class="col-md-4 text-center align-self-center text-muted info"
                    >
                      {{ app.getText("operations.expirationDate") }}
                      <strong>{{ count.expirationDate }}</strong>
                    </div>
                    <div
                      class="col-md-4 text-center align-self-center text-muted info"
                    >
                      {{ app.getText("operations.createDate") }}
                      <strong>{{ count.createDate }}</strong>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div *ngIf="app.permission.operations.seeOperationsHistory">
    <div class="row mt-4">
      <div class="col-md-12">
        <h3>{{ app.getText("operations.historyTitle") }}</h3>
      </div>
    </div>

    <app-paginator
      *ngIf="showPaginator"
      [length]="history.length"
      [index]="app.paginator.focus"
      (focus)="app.paginator.focus = $event"
    ></app-paginator>

    <div class="table-responsive">
      <table class="table table-striped table-font-size">
        <thead>
          <tr>
            <th
              class="table-header"
              *ngFor="let column of app.operations.getOperationsColumns()"
            >
              <span class="pointer">{{
                app.field.getLabel("operations." + column)
              }}</span>
            </th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let item of app.paginator.getPage(history)">
            <td
              class="p-1"
              *ngFor="let column of app.operations.getOperationsColumns()"
            >
              <span
                *ngIf="column === 'create_time' || column === 'complete_time'"
              >
                {{ item[column] | date : "yyyy-MM-dd HH:mm:ss" }}
              </span>
              <span *ngIf="column === 'work_type'">
                {{ app.operations.getTypeText(item[column]) }}
              </span>
              <span *ngIf="column === 'work_log'">
                <button
                  type="button"
                  class="btn btn-link p-0"
                  (click)="downloadLog(item)"
                >
                  <i class="fa fa-file-download"></i>
                </button>
              </span>
              <span *ngIf="!app.operations.isSpecialColumn(column)">
                {{ item[column] }}
              </span>
            </td>
          </tr>
          <tr *ngIf="history.length === 0">
            <td colspan="99" class="p-1 text-center">
              No history available yet
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</div>
