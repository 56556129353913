export const typeCategories = {
  ALERT: {
    text: {
      de: "Alarm",
      en: "Alarm",
      nl: "Waarschuwing",
    },
    impact: "high",
  },
  PPROC: {
    text: {
      de: "Produktionsprozess",
      en: "Production process",
      nl: "Productieproces",
    },
    impact: "low",
  },
  PSITE: {
    text: {
      de: "Produktionsstandort",
      en: "Production site",
      nl: "ProductieLocatie",
    },
    impact: "low",
  },
  CHARA: {
    text: {
      de: "Charakteristika",
      en: "Characteristics",
      nl: "Kenmerken",
    },
    impact: "high",
  },
  DOCUM: {
    text: {
      de: "Dokumentation",
      en: "Documentation",
      nl: "Documentatie",
    },
    impact: "medium",
  },
  CORR: {
    text: {
      de: "Korrektur",
      en: "Correction",
      nl: "Correctie",
    },
    impact: "medium",
  },
  FIT: {
    text: {
      de: "Fit",
      en: "Fit",
      nl: "Fit",
    },
    impact: "high",
  },
  FORM: {
    text: {
      de: "Form und Erscheinung",
      en: "Form and appearance",
      nl: "Vorm en uitstraling",
    },
    impact: "high",
  },
  FUNCT: {
    text: {
      de: "Funktion",
      en: "Function",
      nl: "Functie",
    },
    impact: "high",
  },
  LABEL: {
    text: {
      de: "Beschriftung",
      en: "Labelling",
      nl: "Etikettering",
    },
    impact: "low",
  },
  MATER: {
    text: {
      de: "Material",
      en: "Material",
      nl: "Materiaal",
    },
    impact: "high",
  },
  PRODS: {
    text: {
      de: "Produktionsanlauf",
      en: "Production start",
      nl: "Productiestart",
    },
    impact: "high",
  },
  NRND: {
    text: {
      de: "Einschränkung der Verwendungsempfehlung",
      en: "Restrictions regarding the use recommendation",
      nl: "Beperking van het aanbevolen gebruik",
    },
    impact: "very high",
  },
  PDN: {
    text: {
      de: "Abkündigung",
      en: "Discontinuance",
      nl: "Beëindiging",
    },
    impact: "very high",
  },
  CANCN: {
    text: {
      de: "Rücknahme PCN",
      en: "Cancellation PCN",
      nl: "Intrekking PCN",
    },
    impact: "high",
  },
  CANDN: {
    text: {
      de: "Rücknahme PDN",
      en: "Cancellation PDN",
      nl: "Intrekking PDN",
    },
    impact: "very high",
  },
  PACKA: {
    text: {
      de: "Verpackung",
      en: "Packaging",
      nl: "Verpakking",
    },
    impact: "medium",
  },
  ORCOD: {
    text: {
      de: "Typenschlüssel",
      en: "Type code",
      nl: "Type code",
    },
    impact: "medium",
  },
  RECA: {
    text: {
      de: "Rückruf",
      en: "Recall",
      nl: "Recall",
    },
    impact: "very high",
  },
  SHIP: {
    text: {
      de: "Lieferung",
      en: "Shipping",
      nl: "Levering",
    },
    impact: "medium",
  },
  SOFTW: {
    text: {
      de: "Änderung der Software",
      en: "Change of software",
      nl: "Softwarewijziging",
    },
    impact: "high",
  },
  TESTP: {
    text: {
      de: "Testprozess",
      en: "Test process",
      nl: "Testprocedure",
    },
    impact: "high",
  },
  TESTS: {
    text: {
      de: "Teststandort",
      en: "Test location",
      nl: "Testlocatie",
    },
    impact: "low",
  },
  MANAQ: {
    text: {
      de: "Akquise",
      en: "Acquisition",
      nl: "Aankoop",
    },
    impact: "medium",
  },
  INSOL: {
    text: {
      de: "Insolvenz",
      en: "Insolvency",
      nl: "Insolventie",
    },
    impact: "very high",
  },
  UNKNOWN: {
    text: {
      de: "Unknown",
      en: "Unknown",
      nl: "Unknown",
    },
  },
};
