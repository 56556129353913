import { Component, OnDestroy, OnInit, Output, ViewChild } from "@angular/core";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { Subject } from "rxjs";
import { getApp } from "../app";
import { StringUtils } from "../../../../shared/utils/string.utils";
import { DocModel } from "../state/state";
import { doc2Model } from "../api.service";
import { Part } from "../../../../shared/models/part";
import { FieldTypes } from "../../../../shared/settings/settings";

@Component({
  selector: "app-add-bom-item-modal",
  templateUrl: "./add-bom-item-modal.component.html",
  styleUrls: ["./add-bom-item-modal.component.scss"],
})
export class AddBomItemModalComponent implements OnInit {
  @ViewChild("content", { static: false }) content: any;
  @Output() ok = new Subject<any>();
  // model: DocModel = new Part();
  model: DocModel = doc2Model("part", new Part());

  app = getApp((app) => {
    this.app = app;
  });

  constructor(private modalService: NgbModal) {}

  ngOnInit() {
    this.app.part.getListOfPartNumbers();
  }

  async open() {
    this.app.field.changeFieldSettings(
      this.app.fieldId.part.partNumber,
      FieldTypes.typeahead
    );
    this.app.model[this.app.fieldId.part.partNumber] = "";
    this.app.model[this.app.fieldId.part.quantity] = "";
    this.app.field.getFieldSettings(this.app.fieldId.part.partNumber).mutable =
      true;
    this.app.part.addBOMTypeaheadOptions();

    try {
      const result = await this.modalService.open(this.content, {
        windowClass: "mediumModal",
        backdrop: "static",
      }).result;
      this.ok.next(result);
    } catch (err) {
      // NOP
    }
  }

  hasRequiredFields() {
    let result: boolean = true;
    if (
      !StringUtils.isNullOrEmpty(
        this.app.model[this.app.fieldId.part.partNumber]
      ) &&
      !StringUtils.isNullOrEmpty(this.app.model[this.app.fieldId.part.quantity])
    ) {
      result = false;
    } else {
      result = true;
    }
    return result;
  }

  async save() {
    //insert the part into parts object of the current part
    let cpn = this.app.model[this.app.fieldId.part.partNumber];
    let quantity = this.app.model[this.app.fieldId.part.quantity];
    this.app.part.part["part.parts"][cpn] = quantity;
    // save current part
    await this.app.part.save(this.app.part.cleanPart, this.app.part.part);
    //build bom
    await this.app.operations.triggerWork("build-bom");
    //get bom items
    await this.app.part.getBomParts(this.app.part.part["part.parts"]);
    //generate tree
    await this.app.tree.generateTree();
    //close modal
    this.dismiss();
    this.app.model[this.app.fieldId.part.partNumber] = "";
    this.app.model[this.app.fieldId.part.quantity] = "";
    await this.app.detailTable.getDocsByType("part");
  }

  dismiss() {
    this.app.model[this.app.fieldId.part.partNumber] = "";
    this.app.model[this.app.fieldId.part.quantity] = "";
    this.app.field.resetFieldType(this.app.fieldId.part.partNumber);
    this.app.field.defaultSettings = [];
    this.modalService.dismissAll();
    this.app.state.createFromPart = false;
  }
}
