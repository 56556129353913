export class ItemNumber {
  [key: string]: any;
  id: string = "";
  crtNumber: string = "";
  drawNumber: string = "";
  omfShortDescr: string = "";
  typeIdNum: string = "";
  productCategory: string = "";
  itemSubData: string = "";
  dinCode: string = "";
  dinText: string = "";
  artNumber: string = "";
  changeClasses: string[] = [];
  replmanfItemnumber: string = "";
  itemSOP: string = "";
  itemEOP: string = "";
  itemEOS: string = "";
  itemLTD: string = "";
  itemEOSR: string = "";
}
