<app-modal
  #deletePostModal
  [modalMessage]="deleteQuestion"
  [modalTitle]="app.text.post.modalTitle"
  (ok)="app.post.delete(post)"
></app-modal>
<app-task-completed-note
  #taskCompletedModal
  [currentTask]="post"
></app-task-completed-note>

<div>
  <!-- <div *ngIf="app.unlockedId === post._id">
    <app-post
      *ngIf="post.taskResponsible"
      [id]="post._id"
      [type]="'task'"
    ></app-post>
    <app-post
      *ngIf="post.resolveClass"
      [id]="post._id"
      [type]="'solution'"
    ></app-post>
    <app-post
      *ngIf="!post.resolveClass && !post.taskResponsible"
      [id]="post._id"
      [type]="'comment'"
    ></app-post>
  </div> -->

  <div class="row m-0">
    <!-- show mesasge for ns client if a certain solution type is chosen -->
    <div class="col-12" *ngIf="post.resolveClass && app.customer === 'ns'">
      <div *ngIf="currentSolutionNeedsEvaluation">
        {{ app.text.post.safetyEvaluationRequired }}
      </div>
    </div>
    <div class="col-md-12 pe-0 ps-4" *ngIf="showContent">
      <div class="col-md-11 col-lg-11 col-xl-11 col-sm-11 float-start">
        <div
          class="frame-post-item px-3 p-2"
          [ngClass]="{
            'frame-task-complete': post.taskCompleted,
            'frame-task-incomplete': incompleteTask,
            'frame-final-solution': finalSolution,
            'frame-solution': post.typeOfPost === 'solution'
          }"
        >
          <div
            *ngIf="post.typeOfPost === solution"
            class="px-1 mx-1 title-info"
          >
            <b>
              {{
                app.field.getFieldValueAsText(
                  app.fieldId.post.resolveClass,
                  post.resolveClass
                )
              }}
            </b>
          </div>
          <div class="ps-1 ms-1 title-info" *ngIf="post.typeOfPost === comment">
            <b>{{ post.title_comment }}</b>
          </div>
          <!-- Role field for comment -- used for db -->
          <div
            class="align-field"
            *ngIf="post.responsibleRole && app.customer === 'db'"
          >
            <app-field
              [model]="postModel"
              [field]="app.fieldId.post.responsibleRole"
              [disabled]="true"
              [showLabel]="false"
              [itemsInputValue]="true"
            ></app-field>
          </div>
          <!-- show the next fields for solution and comment -->
          <div *ngIf="post.typeOfPost !== task">
            <app-field
              [field]="app.fieldId.post.content"
              [model]="postModel"
              [disabled]="true"
              [itemsInputValue]="true"
              [showLabel]="false"
            >
            </app-field>
            <div class="itemFields px-3">
              <!-- Display fields with their values based on the lists defined in settings file -->
              <div
                *ngFor="
                  let postField of app.post.getSolutionFieldsList(
                    post.resolveClass
                  )
                "
              >
                <div class="row" *ngIf="hasValue(postField)">
                  <span class="col">
                    {{ app.field.getLabel(postField) }}
                  </span>
                  <span
                    class="col-6"
                    *ngIf="app.field.getType(postField) !== 'options'"
                    [innerHtml]="
                      app.field.highlightLinks(getPostFieldValue(postField))
                    "
                    class="col-6"
                    [ngClass]="{
                      shorterLink: app.field.isLink(
                        getPostFieldValue(postField)
                      )
                    }"
                  >
                    {{ getPostFieldValue(postField) }}
                  </span>
                  <span
                    class="col-6"
                    *ngIf="app.field.getType(postField) === 'options'"
                  >
                    {{
                      app.field.getFieldValueAsText(
                        postField,
                        getPostFieldValue(postField)
                      )
                    }}
                  </span>
                </div>
              </div>
            </div>
            <!-- Display values from the extraSolutionFields list -->
            <div *ngIf="app.list.post.extraSolutionFields.length > 0">
              <div
                class="itemFields px-3"
                *ngFor="let postField of app.list.post.extraSolutionFields"
              >
                <div class="row" *ngIf="hasValue(postField)">
                  <!-- Display field label -->
                  <span class="col">
                    {{ app.field.getLabel(postField) }}
                  </span>
                  <!-- Display field value as it is saved in db -->
                  <span class="col">
                    {{ getPostFieldValue(postField) }}
                  </span>
                </div>
              </div>
            </div>
          </div>

          <!-- show the next fields for task -->
          <div *ngIf="app.post.sectionType === stream && app.customer !== 'db'">
            <div
              class="itemFields px-3"
              *ngFor="let postField of app.list.post.taskFieldsList"
            >
              <div class="row" *ngIf="hasValue(postField)">
                <app-field
                  [field]="postField"
                  [model]="postModel"
                  [disabled]="true"
                  [taskLabel]="true"
                  [taskInput]="true"
                  [lightOddField]="true"
                ></app-field>
              </div>
            </div>
          </div>
          <div
            *ngIf="
              app.post.sectionType === stream &&
              post.typeOfPost === task &&
              app.customer === 'db'
            "
          >
            <div
              class="task-content-db"
              (click)="app.tasks.openTaskPanel(post)"
            >
              <span
                class="p-2"
                (click)="$event.stopPropagation()"
                [ngbTooltip]="app.text.post.markTaskCompleted"
              >
                <button
                  class="btn btn-sm p-0"
                  *ngIf="showCompleteIcon"
                  (click)="taskCompletedModal.openModal()"
                >
                  <i class="far fa-check-circle"></i>
                </button>
              </span>
              <span>{{ post.taskDescription }}</span
              >&nbsp;&nbsp;&nbsp; <span>{{ post.taskResponsible }}</span
              >&nbsp;&nbsp;&nbsp;
              <span
                [ngStyle]="
                  app.tasks.getTaskDueDateColor(
                    post.taskDueDate,
                    post.taskCompleted
                  )
                "
                >{{ post.taskDueDate }}</span
              >&nbsp;&nbsp;&nbsp;
              <span>{{ post.taskNote }}</span>
            </div>
          </div>
          <div
            [ngClass]="{
              'row d-flex justify-content-between px-2': app.unlockedId == null
            }"
          >
            <!-- mark task as completed -->
            <span
              *ngIf="app.unlockedId == null && app.customer !== 'db'"
              class="p-2"
            >
              <div>
                <button
                  class="btn btn-sm btn-incomplete"
                  *ngIf="showCompleteIcon"
                  (click)="taskCompletedModal.openModal()"
                  [disabled]="post.cannotCloseTask"
                >
                  <i class="fal fa-check"></i>
                  &nbsp;&nbsp;{{ app.text.post.markCompleted }}
                </button>
                <button
                  class="btn btn-sm btn-complete"
                  *ngIf="showReopenIcon"
                  (click)="
                    app.taskCompleted.changeTaskCompletion(app.customer, post)
                  "
                >
                  <i class="fal fa-check"></i>
                  &nbsp;&nbsp;{{ app.text.post.completed }}
                </button>
              </div>
            </span>

            <!-- show case status for comments -->
            <div
              class="bottom-info"
              *ngIf="
                post.typeOfPost === comment ||
                (post.typeOfPost === task &&
                  app.customer !== 'db' &&
                  app.permission.post.seeRoles)
              "
            >
              <div class="solution-name">
                <span
                  class="badge selected pull-right"
                  [ngClass]="{ 'badge-color': app.permission.post.seeRoles }"
                >
                  {{ threadStatus }}
                </span>
              </div>
            </div>
          </div>
          <div
            [ngClass]="{
              'row d-flex justify-content-between ps-4':
                app.unlockedId == null && showFinalSolutionCheckbox
            }"
          >
            <!-- show final solution checkbox only for the clients which have the permission and for comments, solutions -->
            <div *ngIf="app.unlockedId == null && showFinalSolutionCheckbox">
              <app-field
                [model]="postModel"
                [field]="app.fieldId.post.acceptedSolution"
                (click)="app.post.setFinalSolution(post)"
                [isFinalSolutin]="true"
                [lightOddField]="true"
              >
              </app-field>
            </div>

            <!-- show case status for solutions -->
            <div class="bottom-info" *ngIf="post.typeOfPost === solution">
              <div class="stock-graphic" *ngIf="showGraphicsButton">
                <app-stock-graphic-modal></app-stock-graphic-modal>
              </div>
              <div class="solution-name">
                <span class="float-end me-1">
                  <span
                    class="badge selected"
                    [ngClass]="{
                      'badge-color': app.permission.post.seeRoles
                    }"
                  >
                    {{ threadStatus }}
                  </span>
                </span>
              </div>
            </div>
          </div>
          <app-attachments
            *ngIf="app.unlockedId === null"
            [id]="post._id"
            type="post"
            [editPermission]="app.post.hasEditPermission(post)"
            [files]="post._attachments"
            [filesDetails]="post.attachmentHistory"
          ></app-attachments>
        </div>
        <!-- task completed credtis -->
        <div
          *ngIf="post.taskCompleted && completedBy && app.customer !== 'db'"
          class="taskCompleted pull-right"
        >
          {{ app.text.credits.completed }} {{ app.text.credits.by }}
          <app-user-link [name]="completedBy" [type]="'post'"></app-user-link>
          {{ app.text.credits.on }}
          {{ completedAt }}
        </div>
        <div
          *ngIf="post.typeOfPost !== task || app.customer !== 'db'"
          class="float-end clear"
        >
          <app-credits
            *ngIf="post != null"
            class="me-1 mb-2"
            type="post"
            [inputDoc]="post"
            [isCreated]="true"
          >
          </app-credits>
          <div class="float-end">
            <app-credits
              *ngIf="post != null"
              class="credits"
              type="post"
              [inputDoc]="post"
              [isEdited]="true"
            ></app-credits>
          </div>
        </div>
      </div>

      <!-- show controls -->
      <div class="col-md-1 col-lg-1 col-xl-1 col-sm-1 float-end ps-2 pe-2">
        <div *ngIf="app.unlockedId === null">
          <app-unlock
            [id]="post._id"
            [permission]="canEditCurrentPost"
            [hasText]="false"
            (unlock)="preparePostForEditMode()"
            [title]="app.text.post.edit"
          >
          </app-unlock>

          <app-attachments-controls
            *ngIf="hasPermissionToAddAttachment()"
            [id]="post._id"
            type="post"
          ></app-attachments-controls>
          <div>
            <button
              *ngIf="canEditCurrentPost"
              class="btn m-0"
              (click)="getDeleteQuestionText(post); deletePostModal.open()"
              [title]="app.text.post.delete"
            >
              <i class="fa fa-trash"></i>
            </button>
          </div>
          <!-- show the sync button only for comments/solutions -->
          <app-sync
            [displayPostSync]="true"
            *ngIf="!post.taskResponsible"
            [id]="post._id"
            type="post"
            [disabled]="app.unlockedId != null"
            [hasText]="false"
          ></app-sync>
        </div>
      </div>
    </div>
  </div>
</div>
