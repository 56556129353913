import { Component, Input } from "@angular/core";
import { getApp } from "../app";

@Component({
  selector: "app-sort-by-field",
  templateUrl: "./sort-by-field.component.html",
  styleUrls: ["./sort-by-field.component.scss"]
})
export class SortByFieldComponent {
  app = getApp(app => {
    this.app = app;
  });

  @Input() columns: string[] = ["A", "B"];

  constructor() {}
}
