export class Credits {
  /** created by user */
  user_id?: string;
  /** created (timestamp) */
  create_time?: number;
  /** last modified by user */
  update_user?: string;
  /** last modified (timestamp) */
  update_time?: number;
  /** seen by a relevant user */
  seen?: boolean;
  /** get the name of the last person that left a comment/post/taks */
  lastCommentedBy?: string;
}

export class ViewCredits {
  /** created by user */
  user_id?: string;
  /** created (timestamp) */
  create_time?: string;
  /** last modified by user */
  update_user?: string;
  /** last modified (timestamp) */
  update_time?: string;
  /** seen by a relevant user */
  seen?: boolean;
  /** get the name of the last person that left a comment/post/taks */
  lastCommentedBy?: string;
}
