<div
  *ngIf="app.field.getOptions(field).length > 0"
  style="max-height: 250px; overflow-y: auto"
>
  <div *ngFor="let option of app.field.getOptions(field); let i = index">
    <button
      *ngIf="isMatch(searchText, app.field.getOptionText(field, option))"
      class="btn btn-sm"
      [ngClass]="{
        selected:
          app.field.isOptionSelected(field, option, model) &&
          !app.field.isMultiple(field)
      }"
      (click)="
        $event.stopPropagation();
        app.field.toggleOption(field, option, model);
        selected.next(option)
      "
    >
      <input
        *ngIf="app.field.isMultiple(field) && app.filterList.field == null"
        [checked]="app.field.isOptionSelected(field, option, model)"
        type="checkbox"
      />
      {{ app.field.getOptionText(field, option) }}
    </button>
  </div>
</div>
