<button
  class="btn btn-control p-2"
  [disabled]="app.table.getPrevious(current, array) == null"
  (click)="
    navigate.next(app.table.getPrevious(current, array));
    app.paginator.focus = 0
  "
>
  <i class="far fa-arrow-left"></i> &nbsp; {{ app.text.next.previous }}
</button>
<button
  class="btn btn-control p-2"
  [disabled]="app.table.getNext(current, array) == null"
  (click)="
    navigate.next(app.table.getNext(current, array)); app.paginator.focus = 0
  "
>
  {{ app.text.next.next }} &nbsp; <i class="far fa-arrow-right"></i>
</button>
