import { Injector, Type } from "@angular/core";

export class ServiceHelper {
  private static _injector: Injector | null = null;

  static set injector(injector: Injector) {
    ServiceHelper._injector = injector;
  }

  static get injector() {
    const injector = ServiceHelper._injector;
    if (injector == null) {
      throw new Error("Injector has not been set!");
    }
    return injector;
  }

  static get<T>(token: Type<T>) {
    return ServiceHelper.injector.get<T>(token);
  }
}
