import { Component, OnInit } from "@angular/core";
import { getApp } from "../app";
import { Train } from "../../../../shared/models/train";
import { Impact } from "../../../../shared/models/impact";

@Component({
  selector: "app-trainseries",
  templateUrl: "./trainseries.component.html",
  styleUrls: ["./trainseries.component.scss"],
})
export class TrainseriesComponent implements OnInit {
  app = getApp((app) => {
    this.app = app;
  });

  trains: Train[] = [];
  impactedTrains: Impact[] = [];
  showTrainError: boolean = false;

  constructor() {}

  async ngOnInit() {
    this.trains = await this.app.train.getTrains();
    await this.getImpactedTrains();
  }

  private async getImpactedTrains() {
    if (this.app.thread.id !== null) {
      let impacts = await this.app.impacts.getImpacts(this.app.thread.id);
      this.impactedTrains = impacts.filter(
        (impact: Impact) => impact.impactType && impact.impactType === "train"
      );
      this.app.table.sort(this.impactedTrains, "train");
    }
  }

  async addTrain(trainName: string) {
    let train: any = null;
    let trainAlreadyAdded: boolean = false;
    //get the train doc from trains array base on the trainName
    train = this.trains.find((t) => t.trainName === trainName);

    if (this.impactedTrains.length > 0) {
      //find if the train you are trying to add to the case is already added
      let a = this.impactedTrains.find(
        (impact) => impact.omfVehicleName === trainName
      );
      if (a != null) {
        trainAlreadyAdded = true;
        this.showTrainError = true;
      } else {
        await this.app.train.addTrainToCase(train);
      }
    } else {
      await this.app.train.addTrainToCase(train);
    }
    this.app.thread.getThread(this.app.thread.thread["thread._id"]);
    if (!trainAlreadyAdded) {
      await this.getImpactedTrains();
      this.app.model["train.trainName"] = "";
    }
  }

  async deleteTrainFromCase(impactedTrain: Impact) {
    await this.app.train.removeTrainFromCase(impactedTrain);
    await this.getImpactedTrains();
  }
}
