import { APP_TYPE, AppType } from "../app.type";
import {
  bulkDocsByType,
  doc2Model,
  getAssemblies,
  getBomParts,
  getHistory,
  getManufacturersByPartNumber,
  getPartNumbers,
  getParts,
  getPartsWithoutCPNs,
  model2Doc,
  updatePart,
  getAssembliesCompressed,
} from "../api.service";
import { Docs } from "../../../../shared/components";
import { Part, Parts } from "../../../../shared/models/part";
import { hasChanges, userEditedFields } from "../utils/app.utils";
import { Inject, Injectable } from "@angular/core";
import { Manufacturer } from "../../../../shared/models/manufacturer";
import { PartServiceType } from "./part.service.type";
import { productCategoryTypes } from "../../../../shared/components/part.settings";
import { Impact, ImpactElement } from "../../../../shared/models/impact";
import { Customer } from "../../../../shared/types/customers";
import { convertYearsLeftToDate } from "../utils/date.util";
import { BILD_IMAGE } from "../file/file.service";
import { StringUtils } from "../../../../shared/utils/string.utils";
import {
  ObsolescenceStatusForecast,
  ObsolescencelifeCycleRisk,
} from "../../../../shared/models/rm-common-properties";
import { MILLISECONDS_IN_YEAR, formatDate } from "../utils/date.util";
import { setCreateTimeAndUpdateTime } from "../import/import.utils";
import { EncodingUtils } from "../../../../shared/utils/encoding.utils";

interface ImpactData {
  part: Part;
  impacts: {
    count: number;
    impact: any;
  }[];
}

export const LOW = "low";
export const MEDIUM = "medium";
export const HIGH = "high";
export const UNKNOWN = "unknown";
export const ITEM_TYPE_SLIJT = "SLIJT";
export const ITEM_TYPE_WISSEL_INTERN_NEWBUY = "WISSEL_INTERN_NEWBUY";
export const ITEM_TYPE_WISSEL_INTERN_REPAIR = "WISSEL_INTERN_REPAIR";
export const ITEM_TYPE_WISSEL_EXTERN_NEWBUY = "WISSEL_EXTERN_NEWBUY";
export const ITEM_TYPE_WISSEL_EXTERN_REPAIR = "WISSEL_EXTERN_REPAIR";
export const ITEM_TYPE_FAC = "FAC";
const POS_TYPE_L = "L";
const MAT_TYPE_YKFT = "YKFT";
const MAT_TYPE_YFRE = "YFRE";

@Injectable()
export class PartService implements PartServiceType {
  part = {} as Part;
  parts: Part[] = [];
  cleanPart: any = {} as Part;
  assemblies: Part[] = [];
  currentAssemblies: Part[] = [];
  partNumbers: string[] = [];
  partsWithoutCpn: string[] = [];
  image: string = "";
  externalImage: string = "";
  bomTypeaheadOptions: string[] = [];
  allAssemblies: Partial<Part>[] = [];
  upperLevelAssemblies: string[] = [];

  constructor(@Inject(APP_TYPE) private app: AppType) {}

  get id() {
    return this.app.state.partNumber;
  }

  set id(partNumber: string | null) {
    this.app.state.next({ partNumber });
  }

  modelHasChanges(): boolean {
    return hasChanges(this.cleanPart, this.part);
  }

  hasEditPermission() {
    const { permission } = this.app;
    if (permission.part.edit) {
      if (
        this.app.unlockedId !== null &&
        this.app.leftNav.selectedBox == "part.history"
      ) {
        this.app.leftNav.selectedBox = "part.itemDetailSection";
      }
      return true;
    }
    return false;
  }

  navigateToPartDetails(id: string) {
    //partNumber should be encoded since it could contain '/' sign
    const partNumber = StringUtils.encode(id);
    const url = "/" + this.app.customers.current + "/part/" + partNumber;
    this.app.routing.openInNewTab(url);
  }

  async save(cleanPart: Part, part: Part) {
    const customer = this.app.customers.expectCurrent;

    /* below line of code is used in the beginning of save function is to get the fields that are edietd by the user which would beneeded to display in history and to avoid displaying dependent fields in history section*/
    const fields = userEditedFields(cleanPart, part);

    const partEditedFields = fields.filter(
      (val: any) => val != undefined && val != "partEditedFields"
    );
    part["part.partEditedFields"] = partEditedFields;

    /** if the current part has a change in the following 3 properties, then the manufacturers containing this CPN have to also be updated */
    let updateManufacturer: boolean = false;
    if (
      fields.findIndex(
        (field: string) =>
          field === "designType" ||
          field === "sapStatus" ||
          field === "stockRange" ||
          field === "itemType"
      ) !== -1
    ) {
      updateManufacturer = true;
    }

    //set part fields
    this.setObsolescenceManagement(part);

    if (this.app.customers.expectCurrent === Customer.MTU) {
      this.setPartGlobalStock(part);
      await this.setPartStockRangeUntil(part);
    }

    part = this.app.field.setTwoDecimalsOnNumberFields(part);

    let partDoc = model2Doc("part", part) as Part;
    partDoc.impact = this.shoudCalculateImpact()
      ? this.setImpact(partDoc)
      : partDoc.impact;
    partDoc.totalRisk = this.setTotalRisk(partDoc);

    const result = await updatePart(customer, partDoc);

    if (updateManufacturer) {
      await this.setManufacturerFieldsFromPart(partDoc);
    }

    this.app.state.hasSuccess = true;
    setTimeout(() => {
      this.app.state.next({ hasSuccess: false });
    }, 2000);

    this.app.state.successText = this.app.text.part.savedSuccessfully;

    this.cleanPart = doc2Model("part", result);
    this.part = doc2Model("part", result) as Part; // doc2Model function will be removed once model won't have "part."
    this.app.unlockedId = null;
  }

  setPartGlobalStock(part: Part) {
    let stockPS3 =
      part[this.app.fieldId.part.actualStock] != null
        ? parseFloat(part[this.app.fieldId.part.actualStock])
        : 0;
    let stockPAS =
      part[this.app.fieldId.part.stockPAS] != null
        ? parseFloat(part[this.app.fieldId.part.stockPAS])
        : 0;
    let stockPS2 =
      part[this.app.fieldId.part.stockPS2] != null
        ? parseFloat(part[this.app.fieldId.part.stockPS2])
        : 0;
    let stockMS5 =
      part[this.app.fieldId.part.stockMS5] != null
        ? parseFloat(part[this.app.fieldId.part.stockMS5])
        : 0;

    let globalStock = stockPS3 + stockPAS + stockPS2 + stockMS5;
    part[this.app.fieldId.part.globalStock] = globalStock.toString();

    return part;
  }

  async setPartStockRangeUntil(part: Part) {
    part[this.app.fieldId.part.stockRangeUntil] = "";
    if (part[this.app.fieldId.part.stockRange] <= 0) {
      return;
    }

    let importDate: number = 0;
    if (part[this.app.fieldId.part.lastImportTime] != null) {
      importDate = part[this.app.fieldId.part.lastImportTime];
    }

    if (
      part[this.app.fieldId.part.stockRange] != null &&
      part[this.app.fieldId.part.stockRange] > 0
    ) {
      let calculatedMiliseconds =
        importDate +
        part[this.app.fieldId.part.stockRange] * MILLISECONDS_IN_YEAR;
      let calculatedDate = new Date(calculatedMiliseconds);
      let date = formatDate(calculatedDate);
      part[this.app.fieldId.part.stockRangeUntil] = date;
    }

    return part;
  }

  async setManufacturerFieldsFromPart(partDoc: Part) {
    if (this.app.part.id == null) {
      return;
    }
    let manufacturers = await getManufacturersByPartNumber(
      this.app.customers.expectCurrent,
      this.app.part.id,
      true
    );
    manufacturers.forEach((manufacturer: Manufacturer) => {
      manufacturer.designType = partDoc.designType;
      manufacturer.sapStatus = partDoc.sapStatus;
      manufacturer.stockRange = partDoc.stockRange;
      manufacturer.totalRisk = partDoc.totalRisk;
    });
    await bulkDocsByType(
      this.app.customers.expectCurrent,
      "manufacturer",
      manufacturers
    );
  }
  //set the obsolescenceManagement field
  setObsolescenceManagement(part: Part) {
    if (this.app.customers.expectCurrent === Customer.NS) {
      //set the obsolescenceManagement field based on the proactivelyCriteria field
      if (
        part[this.app.fieldId.part.proactivelyCriteria] &&
        part[this.app.fieldId.part.proactivelyCriteria].length > 0
      ) {
        part[this.app.fieldId.part.obsolescenceManagement] = "false";
      } else {
        part[this.app.fieldId.part.obsolescenceManagement] = "true";
      }
    }
    if (this.app.customers.expectCurrent === Customer.MTU) {
      //set the obsolescenceManagement field based on the posTyep and matType fields
      if (
        part[this.app.fieldId.part.posType] === POS_TYPE_L &&
        (part[this.app.fieldId.part.matType] === MAT_TYPE_YKFT ||
          part[this.app.fieldId.part.matType] === MAT_TYPE_YFRE)
      ) {
        part[this.app.fieldId.part.obsolescenceManagement] = "true";
      } else {
        part[this.app.fieldId.part.obsolescenceManagement] = "false";
      }
    }
  }

  static getObsolescenceStatus(
    obsolescenceDate: string,
    inYears = 0
  ): ObsolescenceStatusForecast {
    if (obsolescenceDate == null || obsolescenceDate === "") return "";
    return convertYearsLeftToDate(inYears) < obsolescenceDate
      ? "active"
      : "obsolete";
  }

  async getPartHistory() {
    let partId = this.part["part._id"];
    if (partId == null || partId === "") {
      return [];
    }

    return await getHistory(partId, this.app.customers.expectCurrent);
  }

  async getBomParts(parts: Parts): Promise<Part[]> {
    let result: Part[] = [];
    if (parts != null) {
      const partNumbers = Object.keys(parts);

      if (partNumbers != null && partNumbers.length > 0) {
        result = await getBomParts(
          this.app.customers.expectCurrent,
          partNumbers
        );
      }
    }

    return result;
  }

  getPartByPartNumber(partNumber: string) {
    if (partNumber !== null && partNumber !== undefined) {
      const part = this.parts.find((p) => p.partNumber === partNumber);
      if (part == null) {
        return null;
      }

      return part;
    }
  }

  async getPartByPartNumberWithoutBuffer(partNumber: string): Promise<Part> {
    if (partNumber !== null && partNumber !== undefined) {
      const part = await getParts(this.app.customers.expectCurrent, [
        partNumber,
      ]);
      if (part == null) {
        return {} as Part;
      }

      return part[0];
    }

    return {} as Part;
  }

  setImpact(part: any): ObsolescencelifeCycleRisk {
    let customer = this.app.customers.expectCurrent;
    switch (customer) {
      case Customer.NS:
        // When part is not managed proactively, the impact should be calculated as LOW
        if (part.obsolescenceManagement === "false") {
          return LOW;
        }
        // When part is managed proactively, the impact should be calculated based on itemType
        return this.setInternalImpact(part);
      case Customer.MRCE:
      case Customer.KNDS:
      case Customer.SICK:
      case Customer.BELIMO:
      case Customer.DEMO:
      case Customer.DEMOZ2DATA:
      case Customer.HAGENUK:
      case Customer.TRIAL1:
      case Customer.TRIAL2:
      case Customer.TRIAL3:
      case Customer.TRIAL4:
      case Customer.HAGENUK:
        return this.setCurrentImpact(customer, part);
      default:
        return this.setImpactRisk(part);
    }
  }

  setImpactRisk(part: Part | Docs["part"]): ObsolescencelifeCycleRisk {
    if (part.productCategory !== "") {
      return this.getOptionImpact("part.productCategory", part.productCategory);
    } else if (part.impact != null && part.impact !== "") {
      return part.impact;
    }
    return UNKNOWN;
  }

  setCurrentImpact(
    customer: string,
    part: Part | Docs["part"]
  ): ObsolescencelifeCycleRisk {
    switch (customer) {
      case Customer.MRCE:
        return this.setMRCEImpact(part);
      case Customer.KNDS:
        return this.setKNDSImpact(part);
      case Customer.BELIMO:
        return this.impactByActiveMPNs(part.activeMPNs);
      case Customer.SICK:
      case Customer.DEMO:
      case Customer.DEMOZ2DATA:
      case Customer.HAGENUK:
      case Customer.TRIAL1:
      case Customer.TRIAL2:
      case Customer.TRIAL3:
      case Customer.TRIAL4:
      case Customer.HAGENUK:
        return this.setCustomerImpact(part, customer);
      default:
        return UNKNOWN;
    }
  }

  setMRCEImpact(part: Part | Docs["part"]): ObsolescencelifeCycleRisk {
    if (StringUtils.isNullOrEmpty(part.totalLifeCycleUsage)) {
      return UNKNOWN;
    } else {
      if (part.totalLifeCycleUsage > 12) {
        return LOW;
      }
      if (part.totalLifeCycleUsage >= 8 && part.totalLifeCycleUsage <= 12) {
        return MEDIUM;
      }
      if (part.totalLifeCycleUsage < 8) {
        return HIGH;
      }
    }
    return UNKNOWN;
  }

  setKNDSImpact(part: Part | Docs["part"]): ObsolescencelifeCycleRisk {
    let A = (part.hazardousSubstance || "").toLowerCase();
    let B = (part.productSafety || "").toLowerCase();
    let C = (part.extent || "").toLowerCase();
    let D = (part.componentApproval || "").toLowerCase();
    if (
      A === "x" ||
      B === "c" ||
      C === "d" ||
      C === "d0" ||
      D === "a" ||
      D === "z" ||
      D === "v" ||
      D === "t"
    ) {
      return HIGH;
    }
    if (
      A === "k" &&
      B === "n" &&
      (StringUtils.isNullOrEmpty(C) || C === "n") &&
      D === "k"
    ) {
      return MEDIUM;
    }

    return UNKNOWN;
  }

  impactByCategory(category: string): ObsolescencelifeCycleRisk {
    switch (category) {
      case "a":
        return HIGH;
      case "b":
        return MEDIUM;
      case "c":
        return LOW;
      default:
        return UNKNOWN;
    }
  }

  impactByActiveMPNs(activeMPNs: number): ObsolescencelifeCycleRisk {
    if (activeMPNs === 0 || activeMPNs === 1) {
      return HIGH;
    }
    if (activeMPNs === 2) {
      return MEDIUM;
    }
    if (activeMPNs > 2) {
      return LOW;
    }

    return UNKNOWN;
  }

  setCustomerImpact(
    part: Part | Docs["part"],
    customer: string
  ): ObsolescencelifeCycleRisk {
    let A: string = "";
    if (customer === Customer.SICK) {
      A = this.impactByCategory(part.category);
    } else {
      A = this.getOptionImpact("part.productCategory", part.productCategory);
    }
    let B = this.impactByActiveMPNs(part.activeMPNs);

    if (A !== UNKNOWN || !StringUtils.isNullOrEmpty(A)) {
      if (A === B) {
        return A;
      }

      if ((A === HIGH && B === MEDIUM) || (A === MEDIUM && B === HIGH)) {
        return HIGH;
      }

      if ((A === HIGH && B === LOW) || (A === LOW && B === HIGH)) {
        return MEDIUM;
      }

      if ((A === MEDIUM && B === LOW) || (A === LOW && B === MEDIUM)) {
        return LOW;
      }
    } else {
      return B;
    }

    return UNKNOWN;
  }

  getOptionImpact(id: string, value: string): ObsolescencelifeCycleRisk {
    try {
      const { options } = this.app.field.getFieldSettings(id);
      if (options == null) {
        throw new Error("no options");
      }
      const impactValue = options[value].impact;
      if (impactValue == null) {
        return "";
      }

      return impactValue as ObsolescencelifeCycleRisk;
    } catch (err) {
      return "";
    }
  }

  setInternalImpact(part: Part | Docs["part"]) {
    const impactType = part.itemType;

    switch (impactType) {
      case ITEM_TYPE_SLIJT:
        return this.determineInternalImpact(
          part.actualStock,
          part.totalForecast2Years,
          part.totalLifeCycleUsage,
          1
        );
      case ITEM_TYPE_WISSEL_INTERN_NEWBUY:
      case ITEM_TYPE_WISSEL_EXTERN_NEWBUY:
        return this.determineInternalImpact(
          part.forecastEOLStock,
          "1",
          part.safetyStock,
          2
        );
      case ITEM_TYPE_WISSEL_EXTERN_REPAIR:
        return this.determineInternalImpact(
          part.readilyAvailableStock,
          part.totalForecast2Years,
          part.totalLifeCycleUsage,
          3
        );
      case ITEM_TYPE_WISSEL_INTERN_REPAIR:
      case ITEM_TYPE_FAC:
        return UNKNOWN;
      default:
        return UNKNOWN;
    }
  }

  determineInternalImpact(
    a: string,
    b: string,
    c: string,
    method: number
  ): ObsolescencelifeCycleRisk {
    let A: number = a == null || a == "" ? 0 : Number(a);
    let B: number = b == null || b == "" ? 0 : Number(b);
    let C: number = c == null || c == "" ? 0 : Number(c);

    if (A > C && method == 2) {
      return LOW;
    }

    if (A < B) {
      return HIGH;
    }

    if (A > C && (method == 1 || method == 3)) {
      return LOW;
    }

    return MEDIUM;

    // if (A > C) {
    //   return LOW;
    // }

    // if (A > B && (method == 1 || method == 3)) {
    //   return LOW;
    // }

    // if (A >= B && A <= C && (method == 1 || method == 2)) {
    //   return MEDIUM;
    // }

    // if ((A == B || A == C) && method == 3) {
    //   return MEDIUM;
    // }

    // if (A < B) {
    //   return HIGH;
    // }

    // if (A < C && (method == 1 || method == 3)) {
    //   return HIGH;
    // }

    // return UNKNOWN;
  }

  setTotalRisk(part: Part | Docs["part"]): ObsolescencelifeCycleRisk {
    if (part.likelihood == null || part.impact == null) {
      return UNKNOWN;
    }
    const likelihood: number = this.getLifeCycleRiskValue(part.likelihood);
    const impact: number = this.getLifeCycleRiskValue(part.impact);

    if (likelihood > 0 && impact > 0) {
      return this.determineTotalRisk(likelihood + impact);
    } else if (likelihood > 0) {
      return part.likelihood;
    } else if (impact > 0) {
      return part.impact;
    } else {
      return UNKNOWN;
    }
  }

  getLifeCycleRiskValue(value: string): number {
    switch (value) {
      case UNKNOWN:
        return 0;
      case LOW:
        return 1;
      case MEDIUM:
        return 2;
      case HIGH:
        return 3;
      default:
        return 0;
    }
  }

  getLifeCycleRiskName(value: number): ObsolescencelifeCycleRisk {
    switch (value) {
      case 1:
        return LOW;
      case 2:
        return MEDIUM;
      case 3:
        return HIGH;
      default:
        return UNKNOWN;
    }
  }

  determineTotalRisk(value: number): ObsolescencelifeCycleRisk {
    if (value <= 3) {
      return LOW;
    } else if (value === 4) {
      return MEDIUM;
    } else if (5 <= value) {
      return HIGH;
    }
    return UNKNOWN;
  }

  async getImpactsFlat(part: Part): Promise<Impact[]> {
    const impactData = this.getImpactData(this.assemblies, part);
    return this.flatten(impactData, 1).map((e) => e.impact);
  }

  flatten(data: ImpactData, factor: number) {
    const impacts: ImpactElement[] = [];
    data.impacts.forEach((data) => {
      const count = data.count;
      if (data.impact == null) {
        return;
      }
      const part = data.impact.part;
      const impact: Impact = {
        type: "impact",
        omfVehicleName: part.description,
        omfVehicleFleet: "",
        omfVehicleClass: "",
        omfCommodityRespName: "",
        omfVehicleRespDep: "",
        omfVehicleRespEmail: "",
        omfVehicleCnt: null,
        omfNumber: "",
        artNumber: part.partNumber,
        level: part.level,
        vehicleGroup: "",
        impacts: this.flatten(data.impact, count * factor),
        productGroup: "",
        actions: "",
        user_id: "",
        designType: part.designType,
        sapStatus: part.sapStatus,
        stockRange: part.stockRange,
        verwa: part.verwa,
      };
      impacts.push({ count, impact });
    });
    impacts.forEach((doc) => {
      if (doc !== undefined && doc !== null) {
        if (
          doc.impact.impacts != null &&
          // doc.impact.impacts[0] !== undefined &&
          doc.count != undefined &&
          doc.count !== 0
        ) {
          doc.impact.omfVehicleCnt = doc.count;
        } else {
          doc.impact.omfVehicleCnt = 1;
        }
      }
    });
    return impacts;
  }

  getImpactData(
    assemblies: Partial<Part>[],
    part: Part,
    map: Map<string, ImpactData> = new Map(),
    set: Set<string> = new Set()
  ): any {
    const partNumber = part.partNumber;
    if (set.has(partNumber)) {
      return null;
    }
    set.add(partNumber);
    const parts: Partial<Part>[] = assemblies.filter(
      (assembly) =>
        assembly.parts != null &&
        Object.keys(assembly.parts).indexOf(partNumber) !== -1
    );

    const partsArray: Partial<Part> = [
      ...Object.keys(parts).map(
        (pNumber: string) => parts[pNumber as keyof typeof parts]
      ),
    ];
    return {
      part,
      impacts: partsArray.map((assembly: Part) => {
        const count = assembly.parts[partNumber];
        let impact: any;
        if (map.has(assembly.partNumber)) {
          const impactData = map.get(assembly.partNumber);
          if (impactData !== undefined) {
            impact = impactData;
          }
        } else {
          impact = this.getImpactData(assemblies, assembly, map, set);
          if (impact !== null) {
            map.set(assembly.partNumber, impact);
          }
        }
        return { count, impact };
      }),
    };
  }

  setProductCategory(part: Part, manufacturers: Manufacturer[]) {
    if (Object.keys(part.parts).length > 0) {
      part.productCategory = "ASSY";
    } else {
      let result = this.setBestTaxonomy(manufacturers);

      let categoryType = productCategoryTypes.find((c) =>
        c.subcategories.includes(result)
      );
      if (categoryType !== undefined) {
        part.productCategory = categoryType.categoryName;
      } else {
        part.productCategory = "OTHR";
      }
    }
    return part.productCategory;
  }

  setBestTaxonomy(manufacturers: Manufacturer[]) {
    let taxonomy = "OTHR";

    if (manufacturers.length > 0) {
      let possibleTaxonomy = manufacturers.find(
        (m) => !StringUtils.isNullOrEmpty(m.taxonomy)
      );
      if (
        possibleTaxonomy != undefined &&
        possibleTaxonomy.taxonomy != undefined
      ) {
        taxonomy = possibleTaxonomy.taxonomy;

        let sameCategory = manufacturers
          .filter((m) => !StringUtils.isNullOrEmpty(m.taxonomy))
          .every((m) => m.taxonomy === taxonomy);

        if (!sameCategory) {
          return "OTHR";
        }
      }
    }

    return taxonomy;
  }

  async getListOfPartNumbers(viewType?: string) {
    this.partNumbers = (await getPartNumbers(
      this.app.customers.expectCurrent,
      viewType
    )) as string[];
  }

  async getPartsWithoutCpns() {
    this.partsWithoutCpn = await getPartsWithoutCPNs(
      this.app.customers.expectCurrent
    );
  }

  async generateParts(docs: any[]) {
    let partDocs: any[] = [];
    this.parts = await getParts(this.app.customers.expectCurrent);
    let emptyPart = docs.findIndex((p) => Object.keys(p).length === 0);

    if (emptyPart !== -1) {
      docs.splice(emptyPart, 1);
    }

    for (let i = 0; i < docs.length; i++) {
      let partDoc = new Part();
      let test: boolean = this.testDoc(docs[i]);
      if (test) {
        let existingDoc = await this.checkIfExists(docs[i]);
        if (existingDoc != null) {
          partDoc = this.updateFieldsFromImport(docs[i], existingDoc);
        } else {
          this.app.import.props.forEach((field) => {
            if (docs[i][field.key] != null) {
              partDoc[field.key] = docs[i][field.key].toString();
              partDoc._id = docs[i]["partNumber"].toString().trim();
              partDoc.partNumber = docs[i]["partNumber"].toString().trim();

              // Modify import value for all fields of type options
              if (field.type === "options" && field.multiple) {
                partDoc[field.key] = docs[i][field.key]
                  .split(",")
                  .map((item: string) => item.trim());
              }
              // Modify import value for all fields of type number to only keep two decimals
              if (field.type === "number") {
                partDoc[field.key] = this.app.field.formatNumberWithDecimals(
                  partDoc[field.key],
                  2
                );
              }
            }
            partDoc.type = "part";
          });
        }
        partDoc = setCreateTimeAndUpdateTime(partDoc);
        partDocs.push(partDoc);
      } else {
        console.log("failed test", docs[i]);
      }
    }
    // this.docs = this.sortAscending(partDocs, "id")
    docs = partDocs;
    if (this.app.import.type === "assembly") {
      this.generateAssembliesFromParts(docs);
    } else {
      this.app.import.stepper = "reviewList";
      this.app.import.step = "third";
    }
    this.app.import.docs = docs;
    (docs as []).forEach((doc: Part) => {
      this.app.import.selected.add(doc.partNumber);
    });

    return partDocs;
  }

  async generatePartsForJsonImport(docs: any) {
    let partDocs: any[] = [];
    this.parts = await getParts(this.app.customers.expectCurrent);

    docs.forEach(async (doc: any) => {
      let partDoc = new Part();
      let existingDoc = await this.checkIfExists(doc);

      if (existingDoc !== undefined) {
        partDoc = this.updateFieldsFromImport(doc, existingDoc);
      } else {
        partDoc = this.updateFieldsFromImport(doc, partDoc, true);
      }
      partDoc = setCreateTimeAndUpdateTime(partDoc);
      partDocs.push(partDoc);
    });

    if (this.app.import.type === "all-config") {
      this.app.state.importBuffer.docs.data.push(partDocs);
    } else {
      this.app.state.importBuffer.docs.data = partDocs;
    }

    return partDocs;
  }

  testDoc(doc: any) {
    if (StringUtils.isNullOrEmpty(doc.partNumber)) {
      return false;
    }
    return true;
  }

  async generateAssembliesFromParts(docs: any[]) {
    let assemblies: Part[] = [];

    let uniqueAssemblies = new Set(docs.map((d) => d.usedInPartNumber));
    let existingParts: Part[] = await getAssemblies(
      this.app.customers.expectCurrent,
      Array.from(uniqueAssemblies)
    );

    let duplicated = new Set<string>();

    let missingParts: Part[] = [];

    if (uniqueAssemblies.size !== existingParts.length) {
      let assemblies = Array.from(uniqueAssemblies);
      let nonExistingParts = assemblies.filter(
        (u) => existingParts.findIndex((p) => p.doc.partNumber === u) === -1
      );
      nonExistingParts.forEach((partNumber) => {
        if (partNumber !== undefined) {
          let part = {} as Part;
          part.partNumber = partNumber;
          part._id = partNumber;
          missingParts.push(part);
        }
      });

      await bulkDocsByType(
        this.app.customers.expectCurrent,
        "part",
        missingParts
      );
    }

    assemblies = existingParts.map((p) => p.doc);
    assemblies = [...assemblies, ...missingParts];

    assemblies.forEach((part) => {
      let subParts = docs.filter((d) => d.usedInPartNumber === part.partNumber);
      if (part.parts != null && part.parts != undefined) {
        subParts.forEach((subPart) => {
          if (part.partNumber === subPart.partNumber) {
            duplicated.add(subPart.partNumber);
          }
          if (
            !Object.keys(part.parts).includes(subPart.partNumber) &&
            part.partNumber !== subPart.partNumber
          ) {
            part.parts[subPart.partNumber] =
              subPart.usedInQuantity != null
                ? Number(subPart.usedInQuantity)
                : 1;
          } else {
            part.parts[subPart.partNumber] =
              subPart.usedInQuantity != null
                ? Number(subPart.usedInQuantity)
                : 1;
          }
        });
      } else {
        part.parts = {};
        subParts.forEach((subPart) => {
          part.parts[subPart.partNumber] =
            subPart.usedInQuantity != null ? Number(subPart.usedInQuantity) : 1;
        });
      }

      part.bom = Object.keys(part.parts);
    });
    this.app.import.docs = assemblies;
    (docs as []).forEach((doc: Part) => {
      this.app.import.selected.add(doc.partNumber);
    });

    this.app.import.step = "third";
    this.app.import.duplicatedItems = Array.from(duplicated);
    return assemblies;
  }

  private async checkIfExists(doc: Part) {
    let part = this.parts.find((part) => part.partNumber === doc.partNumber);
    return part;
  }

  private updateFieldsFromImport(
    doc: Part,
    existingDoc: Part,
    newDoc?: boolean
  ) {
    Object.keys(doc).forEach((key: string) => {
      // Create a new document with the properties coming from import
      if (newDoc) {
        existingDoc[key] = StringUtils.isNullOrEmpty(doc[key])
          ? existingDoc[key]
          : doc[key];
      } else {
        // Update the existing properties only if the the new value is different than null or empty and has the same type with the existing value
        if (
          StringUtils.isNullOrEmpty(doc[key]) &&
          typeof existingDoc[key] !== "string"
        ) {
          return;
        } else {
          existingDoc[key] = doc[key];
        }
      }

      // Special cases create & update docs
      // Modify import value for the obsolescenceManagement field to keep a string of true/false since there is a radio button
      if (key === "obsolescenceManagement") {
        const value = doc[key];
        if (StringUtils.isNullOrEmpty(value)) {
          existingDoc[key] = "false";
        } else {
          existingDoc[key] = value != null ? value.toString() : "false";
        }
      }

      // Modify import value for all fields of type number to only keep two decimals
      if (
        this.app.field.getFieldSettings("part." + key) != undefined &&
        this.app.field.getFieldSettings("part." + key).type === "number"
      ) {
        existingDoc[key] = this.app.field.formatNumberWithDecimals(
          existingDoc[key],
          2
        );
      }
    });

    return existingDoc;
  }

  getImage(id = this.app.part.id, customer = this.app.customers.expectCurrent) {
    let encodedId = "";
    if (id != null) {
      // encodedId = encodeURIComponent(id);
      encodedId = EncodingUtils.encodeBase64(id);
    }
    this.image = [customer, "part", encodedId, BILD_IMAGE].join("/");
    return this.image;
  }

  getImageExternal(): void {
    const { app } = this;
    const partId = this.app.part.id;

    if (partId !== null) {
      const fileLink = this.getImage();
      const part = model2Doc("part", this.app.part.part);
      const imageExists = app.file.exists(part);
      if (imageExists) {
        this.externalImage = app.file.getUrl(fileLink);
      } else {
        this.externalImage = "";
      }
    }
  }

  async addBOMTypeaheadOptions() {
    this.allAssemblies = await getAssembliesCompressed(
      this.app.customers.expectCurrent
    );

    if (this.app.part.id != null && this.upperLevelAssemblies.length === 0) {
      await this.getUpperLevelAssemblies([this.app.part.id]);
    }

    let currrentPartNumbers: string[] = [];

    this.currentAssemblies.forEach((item: any) => {
      let partNumber = item._id;
      currrentPartNumbers.push(partNumber);
    });

    if (this.app.part.id != null) {
      currrentPartNumbers.push(this.app.part.id);
    }

    // list of partNumbers containing parts that are already in the structure of the current part or that are upper levels for it
    let toBeRemovedItem = [
      ...currrentPartNumbers,
      ...this.upperLevelAssemblies,
    ];

    this.bomTypeaheadOptions = this.partNumbers.filter(
      (item) => !toBeRemovedItem.includes(item)
    );
  }

  async getUpperLevelAssemblies(partNumbers: string[]) {
    let lastParentsFound: string[] = [];
    // search through all assemelies and check for each assembly if the current part is his child
    this.allAssemblies.forEach((assemblie: any) => {
      partNumbers.forEach((parent: string) => {
        if (assemblie.parts.hasOwnProperty(parent)) {
          // if the current partNumber is contained in the parts object => that the assembly is his parent/upper level
          this.upperLevelAssemblies.push(assemblie.partNumber);
          lastParentsFound.push(assemblie.partNumber);
        }
      });
    });

    // if there is at least one parent for current part we need to find if this assembly has other parents/upper levels
    if (lastParentsFound.length > 0) {
      // this function is recursively called in order to find all to upper levels for the current part
      this.getUpperLevelAssemblies(lastParentsFound);
    }

    return this.upperLevelAssemblies;
  }

  //for the next customers the impact should not be calculated
  private shoudCalculateImpact(): boolean {
    if (
      this.app.customers.expectCurrent === Customer.CAE ||
      this.app.customers.expectCurrent === Customer.IAV
    ) {
      return false;
    }
    return true;
  }
}
