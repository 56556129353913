<!-- Table with manufacturer views  -->
<app-paginator
  [length]="app.manufacturersFilter.pages"
  [index]="app.paginator.focus"
  (focus)="app.paginator.focus = $event"
  [type]="'manufacturer-filter'"
></app-paginator>
<app-modal
  #deleteManufacturerModal
  [modalMessage]="app.text.manufacturer.deleteModal"
  [modalTitle]="app.text.manufacturer.deleteManufacturer"
  (ok)="app.detailTable.deleteBOMItem('manufacturer')"
></app-modal>

<!-- Navigation on the manufacturers sections -->
<rm-submenu
  *ngIf="app.RM.mode === 'all-manufacturers'"
  [sections]="['status', 'likelihood', 'rohs', 'reach', 'lead']"
></rm-submenu>

<div>
  <button
    class="btn btn-sm btn-default-orange pull-right my-2"
    *ngIf="app.table.selected.size > 0"
    (click)="deleteManufacturerModal.open()"
  >
    <i class="fas fa-trash"></i> &nbsp;
    {{ app.text.app.delete }}
  </button>
</div>
<div
  class="tableFixHead clear"
  [ngClass]="{ tableHeight: app.filterTable.isDropdownOpen }"
>
  <table class="table table-striped spacing-table">
    <thead>
      <tr>
        <th
          class="p-1"
          *ngFor="
            let column of app.manufacturersFilter.getManufacturersColumns()
          "
          [title]="app.table.setHeaderTooltip('manufacturer.' + column)"
        >
          <div class="d-inline-flex">
            <div
              [ngClass]="{
                'resize-table': app.table.canColumnBeResized(column)
              }"
            >
              <div
                *ngIf="app.table.getColumnType(column) === 'create'"
                (click)="$event.stopPropagation()"
              >
                <div
                  *ngIf="!app.permission.RM.isAnalyst && !app.auth.isRMAdmin"
                >
                  <i class="fas fa-folders"></i>
                </div>
              </div>
              <div *ngIf="column === 'partsToVehicles'">
                <i class="fas fa-sitemap fa-rotate-270 pointer"></i>
              </div>
              <div
                *ngIf="app.table.getColumnType(column) === 'filterType'"
                (click)="$event.stopPropagation()"
              >
                <i class="fas fa-database"></i>
              </div>
              <div *ngIf="app.table.getColumnType(column) === 'link'">
                {{ app.text.manufacturer.link }}
              </div>

              <div
                *ngIf="getSortTableColumns(column)"
                class="pointer"
                (click)="
                  app.table.toggleSortDirection('manufacturer.' + column);
                  app.table.sort(app.manufacturersFilter.manufacturers)
                "
                [ngClass]="app.table.getSizeOfColumn(column)"
              >
                {{ app.field.getTableLabel("manufacturer." + column) }}
              </div>
            </div>
            &nbsp;
            <span *ngIf="'manufacturer.' + column === app.table.sortField">
              <i
                *ngIf="app.table.sortDirection === 1"
                class="fa fa-chevron-up"
              ></i>
              <i
                *ngIf="app.table.sortDirection === -1"
                class="fa fa-chevron-down"
              ></i>
            </span>
          </div>
          <div *ngIf="app.filterTable.getFilterTableColumns(column)">
            <app-filter-header-table
              [column]="column"
              [docType]="'manufacturer'"
            >
            </app-filter-header-table>
          </div>

          <!-- <div *ngIf="app.filterTable.getFilterTableColumns(column)">
            <app-filter-table
              [column]="column"
              [columns]="app.manufacturersFilter.getManufacturersColumns()"
              [docType]="'manufacturer'"
              [cleanDocs]="app.manufacturersFilter.cleanManufacturers"
              [selectedFilters]="selectedFilters"
              (resultedDocs)="
                app.filterTable.getFilterResults($event, 'manufacturer')
              "
              [resetComponent]="resetFilterInputsObservable"
            >
            </app-filter-table>
          </div> -->
        </th>
        <th class="p-1" *ngIf="app.RM.mode === 'linkedToDeletedCPN'">
          {{ app.text.app.delete }}
          <div>
            <i
              class="far fa-square"
              *ngIf="app.table.selected.size === 0"
              (click)="
                app.table.selectAll(app.manufacturersFilter.manufacturers)
              "
            ></i>
            <i
              class="far fa-check-square"
              *ngIf="app.table.selected.size > 0"
              (click)="app.table.clearSelection()"
            ></i>
          </div>
        </th>
      </tr>
    </thead>
    <tbody>
      <tr *ngFor="let id of app.manufacturersFilter.manufacturers">
        <td
          class="p-1"
          *ngFor="
            let column of app.manufacturersFilter.getManufacturersColumns()
          "
        >
          <span *ngIf="app.table.getColumnType(column) === 'normal'">{{
            id[column]
          }}</span>
          <div
            *ngIf="app.table.getColumnType(column) === 'textCenter'"
            class="text-center"
          >
            {{ id[column] }}
          </div>
          <div
            *ngIf="app.table.getColumnType(column) === 'smallCell'"
            class="smallCell"
          >
            {{ id[column] }}
          </div>
          <div
            *ngIf="app.table.getColumnType(column) === 'stockRange'"
            class="smallCell"
          >
            <span *ngIf="id[column] > -1">
              {{ app.field.formatNumberWithDecimals(id[column], 2) }}
            </span>
          </div>

          <a
            *ngIf="app.table.getColumnType(column) === 'partNumber'"
            class="partNumber"
            (click)="app.part.navigateToPartDetails(id[column])"
          >
            {{ id[column] }}
          </a>
          <span
            *ngIf="column === 'partsToVehicles'"
            (click)="
              seeStructureModal.open();
              app.treeRow.generateImpactsFromParts(id.partNumber)
            "
            [title]="app.field.getLabel(app.fieldId.part.partsToVehicles)"
          >
            <i class="fas fa-sitemap fa-rotate-270 pointer icon-blue"></i>
          </span>
          <a
            *ngIf="app.table.getColumnType(column) === 'manufacturerPartNumber'"
            class="partNumber"
            (click)="app.manufacturer.navigateToManufacturerDetails(id._id)"
          >
            {{ id[column] }}
          </a>

          <div *ngIf="app.table.getColumnType(column) === 'preferred'">
            <i *ngIf="id[column] === 'true'" class="fas fa-star-of-life"></i>
          </div>
          <span *ngIf="app.table.getColumnType(column) === 'pending'">
            <span *ngIf="id[column] === true">
              {{ app.text.manufacturer.pending }}</span
            >
          </span>
          <span
            *ngIf="
              app.table.getColumnType(column) === 'create' &&
              app.permission.RM.createCase
            "
          >
            <i
              class="far fa-folder-plus icon-blue pointer p-1"
              *ngIf="
                !app.thread.getCase(id) && id.manufacturerPartNumber !== ''
              "
              (click)="
                app.manufacturersFilter.currentManufacturer = id;
                app.thread.newCase(id)
              "
            ></i>
            <i
              class="fas fa-folder-open icon-blue pointer p-1"
              *ngIf="app.thread.getCase(id)"
              (click)="
                seeOpenedCasesModal.open();
                app.manufacturersFilter.currentManufacturer = id;
                app.thread.getCasesByPartNumber(id)
              "
            ></i>
          </span>
          <span *ngIf="app.table.getColumnType(column) === 'filterType'">
            <a (click)="app.treeRow.navigateToMatchingPage(null, id)">
              <i
                class="far fa-link icon-blue pointer p-1"
                *ngIf="
                  app.manufacturer.getMatchingStatusOfManufacturer(id) ===
                  'matched'
                "
              ></i>
              <i
                class="fa fa-user-circle icon-blue pointer p-1"
                *ngIf="
                  app.manufacturer.getMatchingStatusOfManufacturer(id) ===
                  'manual'
                "
              ></i>
              <i
                class="far fa-unlink icon-blue pointer p-1"
                *ngIf="
                  app.manufacturer.getMatchingStatusOfManufacturer(id) ===
                  'unmatched'
                "
              ></i>
              <i
                class="far fa-hand-paper icon-blue pointer"
                *ngIf="
                  app.manufacturer.getMatchingStatusOfManufacturer(id) ===
                  'override'
                "
              ></i>
              <i
                *ngIf="
                  app.manufacturer.getMatchingStatusOfManufacturer(id) ===
                  'manualAssignment'
                "
                class="fas fa-user-edit icon-blue p-1 pointer"
              ></i>
            </a>
          </span>
          <span
            *ngIf="app.table.getColumnType(column) === 'badge'"
            class="badge text-center"
            [ngStyle]="app.field.getColor('manufacturer.' + column, id[column])"
          >
            {{ app.field.getOptionText("manufacturer." + column, id[column]) }}
          </span>
          <span
            *ngIf="app.table.getColumnType(column) === 'link' && id[column]"
          >
            <a [href]="id[column]" target="_blank">
              <i class="fas fa-file icon-blue"></i>
            </a>
          </span>
        </td>
        <td class="p-1" *ngIf="app.RM.mode === 'linkedToDeletedCPN'">
          <button
            class="btn btn-hidden pt-0 ps-0"
            (click)="app.table.toggleSelect(id); $event.stopPropagation()"
          >
            <i
              class="far fa-check-square"
              *ngIf="app.table.selected.has(id)"
            ></i>
            <i class="far fa-square" *ngIf="!app.table.selected.has(id)"></i>
          </button>
        </td>
      </tr>
      <tr>
        <td *ngIf="loading" colspan="99" class="text-center">
          {{ this.app.text.core.loading }}
        </td>
        <td
          *ngIf="!loading && app.manufacturersFilter.manufacturers.length === 0"
          colspan="99"
          class="text-center"
        >
          {{ this.app.text.core.noResults }}
        </td>
      </tr>
    </tbody>
  </table>
</div>
<app-paginator
  [length]="app.manufacturersFilter.pages"
  [index]="app.paginator.focus"
  (focus)="app.paginator.focus = $event"
  [type]="'manufacturer-filter'"
></app-paginator>

<app-modal
  #seeStructureModal
  [modalTitle]="app.text.part.whereUsed"
  [showButtons]="false"
  [modalType]="'showWhereUsed'"
  [modalTitle]="app.text.part.whereUsed"
>
</app-modal>
<app-modal
  #seeOpenedCasesModal
  [modalTitle]="app.text.thread.threads"
  [modalType]="'showListOfCases'"
  [showButtons]="false"
>
</app-modal>
