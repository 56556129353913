import { FieldTypes, ComponentSettings } from "../settings/settings";
import { creditsFields } from "./credits.settings";

const field = {
  message: {
    type: FieldTypes.text,
    text: { de: "Notiz", en: "Note", nl: "Notities" },
    required: true,
    maxLength: 4096,
  },
  entityId: {
    type: FieldTypes.value,
    text: {
      de: "Kundenteilenummer (KTN)",
      en: "Customer Part Number (CPN)",
      nl: "Artikel Nummer (CPN)",
    },
    search: false,
  },
  ...creditsFields,
};

const settings = {
  text: {
    message: { de: "Notiz", en: "Note", nl: "Notitie" },
    messages: { de: "Notizen", en: "Notes", nl: "Notities" },
    delete: {
      de: "Notiz löschen",
      en: "Delete Note",
      nl: "Noot verwijderen",
    },
    editComment: {
      de: "Notiz bearbeiten",
      en: "Edit Note",
      nl: "Noot bewerken",
    },
    deleteQuestion: {
      de: "Möchten Sie diese Notiz löschen?",
      en: "Would you like to delete this note?",
      nl: "Wilt u deze notitie verwijderen?",
    },
    modalTitle: {
      de: "Bestätigung",
      en: "Confirm",
      nl: "Bevestigen",
    },
    leaveNote: {
      de: "Hinterlassen Sie eine Notiz.",
      en: "Leave a note below.",
      nl: "Laat een notitie achter.",
    },
    cancel: { de: "Abbrechen", en: "Cancel", nl: "Afbreken" },
    add: {
      de: "Notiz hinzufügen",
      en: "Add note",
      nl: "Notitie toevoegen",
    },
    note: { de: "Notiz", en: "Note", nl: "Notitie" },
  },
  field,
  url: {},
  permission: {
    edit: false,
    editOwn: false,
  },
  list: {},
};

export const message: ComponentSettings<typeof settings> = settings;
