import { Settings } from "../components";
import { _ComponentSettings } from "../settings/settings";

export type ListProxy = {
  [name in keyof Settings]: ComponentListProxy<Settings[name]["list"]>;
};

type ComponentListProxy<T> = {
  [name in keyof T]: string[];
};

export function getListProxy(settings: Settings) {
  const proxy: any = {};
  Object.keys(settings).forEach((name) => {
    Object.defineProperty(proxy, name, {
      get: () => {
        const component: _ComponentSettings = (<any>settings)[name];
        return getComponentListProxy(component);
      },
    });
  });

  return <ListProxy>proxy;
}

function getComponentListProxy<T extends _ComponentSettings>(component: T) {
  const proxy: any = {};
  const { list } = component;
  Object.keys(list).forEach((name) => {
    Object.defineProperty(proxy, name, {
      get: () => [...component.list[name]],
    });
  });
  return <ComponentListProxy<T>>proxy;
}
