import { Component, Input } from "@angular/core";
import { getApp } from "../app";
import { Message } from "../../../../shared/models/message";
import { ActivatedRoute } from "@angular/router";

@Component({
  selector: "app-message-item",
  templateUrl: "./message-item.component.html",
  styleUrls: ["./message-item.component.scss"],
})
export class MessageItemComponent {
  app = getApp((app) => {
    this.app = app;
  });

  @Input() showMessages = true;
  @Input() message = {} as Message;
  @Input() type: string = "";

  togglePost() {
    this.showMessages = !this.showMessages;
  }
}
