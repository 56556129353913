import { Component, Input } from "@angular/core";
import { getApp } from "../app";

@Component({
  selector: "rm-submenu",
  templateUrl: "./rm-submenu.component.html",
  styleUrls: ["./rm-submenu.component.scss"],
})
export class RMSubmenuComponent {
  @Input() sections: string[] = [];

  app = getApp((app) => {
    this.app = app;
  });

  constructor() {}
}
