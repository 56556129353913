import { defaultSettings, Settings } from "../../../../../shared/components";
import {
  FieldIdProxy,
  getFieldIdProxy,
} from "../../../../../shared/proxies/field-id.proxy";
import {
  getListIdProxy,
  ListIdProxy,
} from "../../../../../shared/proxies/list-id.proxy";
import {
  getListProxy,
  ListProxy,
} from "../../../../../shared/proxies/list.proxy";
import {
  getPermissionProxy,
  PermissionProxy,
} from "../../../../../shared/proxies/permission.proxy";
import {
  getTextProxy,
  TextProxy,
} from "../../../../../shared/proxies/text.proxy";
import { getUrlProxy, UrlProxy } from "../../../../../shared/proxies/url.proxy";
import { Injectable } from "@angular/core";
import { Language } from "../../../../../shared/settings/settings";
import { ProxyServiceType } from "./proxy.service.type";

@Injectable()
export class ProxyService implements ProxyServiceType {
  settings: Settings = defaultSettings;

  getTextProxy(language: Language): TextProxy {
    return getTextProxy(this.settings, language);
  }

  getPermissionProxy(): PermissionProxy {
    return getPermissionProxy(this.settings);
  }

  getFieldIdProxy(): FieldIdProxy {
    return getFieldIdProxy();
  }

  getListProxy(): ListProxy {
    return getListProxy(this.settings);
  }

  getListIdProxy(): ListIdProxy {
    return getListIdProxy();
  }

  getUrlProxy(): UrlProxy {
    return getUrlProxy(this.settings);
  }
}
