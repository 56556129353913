<div *ngIf="app != null">
  <div [ngSwitch]="box">
    <div class="mb-2" *ngSwitchCase="app.listId.thread.comments">
      <div class="card my-3">
        <app-post></app-post>

        <!-- stream frame -->
        <div
          class="post-card"
          *ngIf="app.post.posts.length > 0 && app.post.sectionType === 'stream'"
        >
          <div *ngFor="let post of app.post.cleanPosts; let i = index">
            <app-post-item [post]="post"></app-post-item>
          </div>
        </div>

        <!-- solutions frame -->
        <div
          class="post-card"
          *ngIf="
            app.post.numberOfSolutions > 0 &&
            app.post.sectionType === 'solution'
          "
        >
          <div *ngFor="let solution of app.post.solutions; let i = index">
            <app-post-item [post]="solution"></app-post-item>
          </div>
        </div>

        <!-- comments frame -->
        <div
          class="post-card"
          *ngIf="
            app.post.numberOfComments > 0 && app.post.sectionType === 'comment'
          "
        >
          <div *ngFor="let comment of app.post.comments; let i = index">
            <app-post-item [post]="comment"></app-post-item>
          </div>
        </div>

        <!-- tasks frame -->
        <div
          class="post-card"
          *ngIf="app.post.numberOfTasks > 0 && app.post.sectionType === 'task'"
        >
          <div>
            <app-task-items></app-task-items>
          </div>
        </div>
      </div>
    </div>
    <div class="border-top" *ngSwitchCase="app.listId.thread.files">
      <app-frame
        *ngIf="!app.thread.isNew && app.unlockedId == null"
        [title]="app.text.thread.files"
        [showHeader]="showHeader"
      >
        <div class="controls p-1">
          <app-attachments-controls
            *ngIf="app.thread.hasPermissionToEdit"
            [id]="app.thread.thread['thread._id']"
            type="thread"
          ></app-attachments-controls>
        </div>
        <div *ngIf="app.permission.attachments.seeAttachments">
          <app-attachments
            [id]="app.thread.thread['thread._id']"
            type="thread"
            [editPermission]="app.thread.hasPermissionToEdit"
            [files]="app.thread.thread['thread._attachments']"
            [filesDetails]="app.thread.thread['thread.attachmentHistory']"
          ></app-attachments>
        </div>
      </app-frame>
    </div>
    <!-- cam be remove after moving fields to modal/ impacts.html is not used -->
    <div class="border-top" *ngSwitchCase="app.listId.thread.impacts">
      <app-frame [title]="app.text.impact.impacts" [showHeader]="showHeader">
        <app-impacts></app-impacts>
      </app-frame>
    </div>
    <div class="border-top" *ngSwitchCase="app.listId.thread.trainseries">
      <app-frame
        *ngIf="app.unlockedId == null"
        [title]="app.text.train.trains"
        [showHeader]="showHeader"
      >
        <app-trainseries></app-trainseries>
      </app-frame>
    </div>
    <div class="border-top" *ngSwitchCase="app.listId.thread.internalItems">
      <app-frame
        *ngIf="app.unlockedId == null"
        [title]="app.text.thread.internalItems"
        [showHeader]="showHeader"
      >
        <app-internal-items></app-internal-items>
      </app-frame>
    </div>
    <div class="card" *ngSwitchCase="app.listId.thread.history">
      <app-history></app-history>
    </div>
    <div class="card" *ngSwitchCase="app.listId.part.history">
      <app-history></app-history>
    </div>
    <div class="card my-3" *ngSwitchCase="'rm-messages'">
      <div class="card-header p-1">
        <span class="text-header ps-3">{{ app.text.message.note }}</span>
      </div>
      <app-message
        *ngIf="
          app.unlockedId == null ||
          app.unlockedId == app.manufacturer.id ||
          app.unlockedId == app.part.id
        "
      ></app-message>
      <div class="message-card">
        <div *ngFor="let message of app.message.messages; let i = index">
          <app-message-item [message]="message"></app-message-item>
        </div>
      </div>
    </div>
    <div class="border-top" *ngSwitchCase="app.listId.thread.description">
      <app-frame [showHeader]="showHeader">
        <div class="m-1 px-3 py-2">
          <app-field
            [model]="app.thread.thread"
            [field]="app.fieldId.thread.descr"
            [disabled]="app.isLocked(unlock)"
            [largeInput]="true"
            [showLabel]="false"
          ></app-field>
        </div>
      </app-frame>
    </div>
    <div *ngSwitchCase="app.listId.manufacturer.supplyChainSection">
      <app-frame [fieldTableBox]="true">
        <app-field
          *ngFor="let field of app.getList(box); index as index"
          [model]="model"
          [field]="field"
          [index]="index"
          [disabled]="app.isLocked(unlock)"
        ></app-field>
        <div
          *ngIf="!app.manufacturer.checkManualOverride"
          class="table-bkgd clear"
        >
          <app-supply-chain-table></app-supply-chain-table>
        </div>
      </app-frame>
    </div>
    <app-frame
      *ngSwitchDefault
      [title]="app.getText(box)"
      [showHeader]="showHeader"
    >
      <div *ngIf="box != null">
        <app-field
          *ngFor="let field of app.getList(box); index as index"
          [model]="model"
          [field]="field"
          [index]="index"
          [publicFieldName]="publicFieldName"
          [disabled]="app.isLocked(unlock)"
        ></app-field>
      </div>
    </app-frame>
  </div>
</div>
