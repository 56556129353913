import { Component, OnDestroy, OnInit, ViewChild } from "@angular/core";
import { Subscription } from "rxjs";
import { Pcn, FileData } from "../../../../shared/models/pcn";
import { doc2Model } from "../api.service";
import { getApp } from "../app";
import { getAttachments } from "../import-xml/pcn-generator-import.util";
import { PdfExport } from "../pdf/export";
import { Definition } from "../pdf/definition";
import { saveAs } from "file-saver-es";
import { ItemNumber } from "../../../../shared/models/item-number";
import { StringUtils } from "../../../../shared/utils/string.utils";

@Component({
  selector: "app-pcn-generator",
  templateUrl: "./pcn-generator.component.html",
  styleUrls: ["./pcn-generator.component.scss"],
})
export class PcnGeneratorComponent implements OnInit, OnDestroy {
  @ViewChild("importFileInput", { static: true }) importFileInput: any;
  @ViewChild("fileInput", { static: false }) fileInput: any;
  @ViewChild("invalidFileType", { static: false }) invalidFileType: any;
  @ViewChild("invalidFileFormat", { static: false }) invalidFileFormat: any;

  /** used to determine witch content should be displayed in the page
   * if the user import a document, after the import is completed
   * the div that contains the stepper with the 4 sections should be displayed,
   * but the selected button remains import
   */
  pcn: Pcn = new Pcn();

  file: any = {} as File;

  private _attachmentSubscription: Subscription = new Subscription();

  app = getApp((app) => {
    this.app = app;
  });

  constructor() {}

  ngOnInit() {
    this.app.leftNav.selectedBox = this.app.listId.pcnGenerator.mainBox;
    this.app.pcnGenerator.pcnModel = doc2Model("pcnGenerator", this.pcn);
    this.app.pcnGenerator.attachmentSubject.subscribe((value) => {
      /**subscription to the attachments subject as soon as it was emited */
      this.app.pcnGenerator.attachments = value;
      /** as long as there are the attachments we can create the pncModel */
      this.app.pcnGenerator.createPcnDoc(this.app.importXml.result);
    });
  }

  async uploadPcnZipFile() {
    const input = this.importFileInput.nativeElement.files;
    const file = input[0];
    if (!file.name.endsWith(".zip")) {
      this.invalidFileType.open();
    }
    await getAttachments(file, this.app);
    if (this.app.pcnGenerator.isValidZip) {
      this.app.pcnGenerator.switchContent = true;
    } else {
      this.invalidFileType.open();
    }
  }

  /** Upload Attachment */
  uploadAttachment() {
    this.file = this.fileInput.nativeElement.files[0];
    let name: string = this.file.name;

    if (!this.app.file.fileFormatAccepted(this.file)) {
      this.invalidFileFormat.open();
      return;
    }
    if (StringUtils.containsSpecialCharacter(name)) {
      //check file name
      this.app.state.hasError = true;
      this.app.state.errorText = "File name not accepted!";
      return;
    }
    if (this.app.pcnGenerator.pcnModel["pcnGenerator._attachments"] == null) {
      this.app.pcnGenerator.pcnModel["pcnGenerator._attachments"] = [];
    }
    this.app.pcnGenerator.pcnModel["pcnGenerator._attachments"].push(this.file);
  }

  deleteAttachment(index: number) {
    if (index !== -1) {
      this.app.pcnGenerator.pcnModel["pcnGenerator._attachments"].splice(
        index,
        1
      );
    }
  }

  /** Download Attachments */
  /** method to be used for downloading the files from the pcnModel.attachments  */
  downloadAttachment(file: File | any) {
    if (file.xmlFile === true && file.xmlFile != null) {
      saveAs(file.attachment, file.name);
    } else {
      const blob = new Blob([file], { type: file.type });
      saveAs(blob, file.name);
    }
  }

  generatePdf(emailContent: { name: string; email: string }) {
    const pdf = this.createPDF();

    const content = pdf.pdf.getBase64((data: string) => {
      let pdfFile = new FileData();
      pdfFile.fileContent = data;
      pdfFile.fileName = "PCN";
      this.app.pcnGenerator.save(
        this.app.customers.expectCurrent,
        emailContent,
        pdfFile
      );
    });
    return content;
  }

  createPDF() {
    const definition = new Definition(
      {
        pageSize: "A4",
        pageOrientation: "landscape",
      },
      this.app
    );
    definition.addVdmaStandardClientDefPcn(
      Definition.mapClientFieldsPcn(),
      "pcngen"
    );
    let pdf = new PdfExport(definition.get());
    // pdf.open();
    return pdf;
  }

  ngOnDestroy() {
    if (this._attachmentSubscription) {
      this._attachmentSubscription.unsubscribe();
    }
  }
}
