<span class="credits ms-1" *ngIf="credits !== undefined">
  <ul
    *ngIf="app.view !== 'home' && !isCreated && !isEdited"
    class="creditsList"
    [ngClass]="app.thread.changeHeadlineColor()"
  >
    <li>
      {{ app.text.credits.created }} {{ app.text.credits.by }}
      <app-user-link
        class="text-credits-user"
        [name]="credits.user_id"
        [type]="type"
      ></app-user-link>
      <span *ngIf="credits.create_time != null && credits.create_time !== ''">
        {{ app.text.credits.on }} {{ credits.create_time }}</span
      >
    </li>
    <li *ngIf="credits.update_user != null && credits.update_user !== ''">
      <span
        *ngIf="
          credits.user_id !== credits.update_user ||
          credits.create_time !== credits.update_time
        "
      >
        {{ app.text.credits.updated }}
        <span *ngIf="credits.user_id !== credits.update_user">
          {{ app.text.credits.by }}
          <app-user-link
            class="text-credits-user"
            [name]="credits.update_user"
          ></app-user-link>
        </span>
        <span *ngIf="credits.create_time !== credits.update_time">
          {{ app.text.credits.on }} {{ credits.update_time }}
        </span>
      </span>
    </li>
  </ul>

  <span *ngIf="isEdited && credits.create_time !== credits.update_time">
    {{ app.text.credits.updated }}
    <span>
      {{ app.text.credits.by }}
      <app-user-link
        class="text-credits-user"
        [name]="credits.update_user"
      ></app-user-link>
    </span>
    <span> {{ app.text.credits.on }} {{ credits.update_time }} </span>
  </span>

  <span *ngIf="isCreated">
    {{ app.text.credits.created }} {{ app.text.credits.by }}
    <app-user-link
      class="text-credits-user"
      [name]="credits.user_id"
      [type]="type"
    ></app-user-link>
    <span *ngIf="credits.create_time != null && credits.create_time !== ''">
      {{ app.text.credits.on }} {{ credits.create_time }}
    </span>
  </span>
</span>
