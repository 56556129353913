export type ComponentSettings<T extends _ComponentSettings> = T;

export type _ComponentSettings = {
  permission: Permissions;
  text: Texts;
  list: Lists;
  url: Urls;
  field: Fields;
};

// PERMISSION

export interface Permissions {
  [name: string]: boolean;
}

// TEXT

export interface Texts {
  [name: string]: Text;
}

export interface Languages<T> {
  de: T;
  en: T;
  nl?: T;
}

export function getTranslatedText(text: Text, language: Language): string {
  const result = text[language];
  if (result == null) {
    return text.de;
  }
  return result;
}

export type Language = keyof Languages<any>;

export type Text = Languages<string>;

// URL

export interface Urls {
  [name: string]: string;
}

// LIST

export interface Lists {
  [name: string]: List;
}

export type List = string[];

export const empty: List = [];

// FIELD

export type Fields = { [name: string]: Field };

export interface Field {
  text: Text;
  type: FieldType;
  xml?: string;
  csv?: string;
  options?: Options;
  required?: boolean;
  mutable?: boolean;
  multiple?: boolean;
  validators?: any;
  maxLength?: number;
  search?: boolean;
  format?: CodeFormat;
  value?: string;
  filter?: FilterType;
  sort?: boolean;
  unit?: Text;
  unique?: boolean;
  tooltip?: Text;
  checkboxLabels?: any;
  helperText?: Text;
  rows?: number;
  regex?: string;
}

export type FieldType =
  | "value"
  | "text"
  | "number"
  | "date"
  | "options"
  | "radio"
  | "checkbox"
  | "timestamp"
  | "password"
  | "data"
  | "typeahead";

export function isTextSearchType(type: FieldType) {
  switch (type) {
    case "value":
    case "text":
    case "date":
      return true;
    default:
      return false;
  }
}

export class FieldTypes {
  static readonly value: FieldType = "value";
  static readonly text: FieldType = "text";
  static readonly number: FieldType = "number";
  static readonly date: FieldType = "date";
  static readonly options: FieldType = "options";
  static readonly radio: FieldType = "radio";
  static readonly checkbox: FieldType = "checkbox";
  static readonly timestamp: FieldType = "timestamp";
  static readonly password: FieldType = "password";
  static readonly data: FieldType = "data";
  static readonly typeahead: FieldType = "typeahead";
}

export interface ValueFormat {
  at: number;
  digits?: number;
  length?: number;
  fixed?: boolean;
}

export interface CodeFormat {
  text: string;
  values: { [property: string]: ValueFormat };
  regex?: string;
  textInFilter?: string;
}

export type FilterType = "contains" | "startsWith";

export class FilterTypes {
  static readonly contains: FilterType = "contains";
  static readonly startsWith: FilterType = "startsWith";
}

export type Options = {
  [value: string]: Option;
};

export interface Option {
  text: Text;
  color?: string;
  impact?: string; //used for productCatgory impact value on part
  name?: string;
  value?: string;
}
