import { Component, OnInit } from "@angular/core";
import { getApp } from "../app";

@Component({
  selector: "app-not-found",
  templateUrl: "./not-found.component.html",
  styleUrls: ["./not-found.component.scss"],
})
export class NotFoundComponent implements OnInit {
  app = getApp((app) => {
    this.app = app;
  });

  constructor() {}

  ngOnInit() {}
}
