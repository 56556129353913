import { Inject, Injectable } from "@angular/core";
import {
  ProductCategory,
  ProductCategoryItem,
} from "../../../../shared/models/productCategory";
import { bulkDocsByType, getDocsByType } from "../api.service";
import { AppType, APP_TYPE } from "../app.type";
import { ProductCategoryServiceType } from "./product-category.service.type";

@Injectable()
export class ProductCategoryService implements ProductCategoryServiceType {
  categories: ProductCategoryItem[] = [];
  categoriesInDropdown: string[] = [];
  reset: boolean = false;
  wordMinLength = 3;

  constructor(@Inject(APP_TYPE) private app: AppType) {}

  searchWhileTyping(value: string) {
    let productCategoryOptions = this.app.field.getFieldSettings(
      this.app.fieldId.thread.productCategory
    ).options;

    if (productCategoryOptions == null) {
      return;
    }
    if (value.length > 0 && value.indexOf(" ") >= 0) {
      // let lastTypedWord = value.split(" ")[this._spaceCounter];
      let wordsArray = value.split(" ");
      let lastTypedWord = wordsArray[wordsArray.length - 2];

      if (lastTypedWord.length > this.wordMinLength) {
        //search in product categories items
        this.categories.forEach((element: ProductCategoryItem) => {
          if (element != null && element.item.includes(lastTypedWord)) {
            if (this.reset) {
              this.categoriesInDropdown = [];
              this.reset = false;
            }
            this.categoriesInDropdown.push(element.category);
          }
        });
      }
    }
    if (value.length == 0) {
      //if there is no text in value parameter return all options
      this.categoriesInDropdown = Object.keys(productCategoryOptions);
      this.reset = true;
    }
  }

  searchInEntireString(value: string) {
    let words = value.split(" ");
    words.forEach((word) => {
      if (word != "" && word.length > this.wordMinLength) {
        this.categories.forEach((element: ProductCategoryItem) => {
          if (element != null && element.item.includes(word)) {
            this.categoriesInDropdown.push(element.category);
          }
        });
      }
    });
  }

  showAllCategories() {
    let categoriesOptions = this.app.field.getFieldSettings(
      this.app.fieldId.thread.productCategory
    ).options;
    if (categoriesOptions == null) {
      return;
    }
    this.categoriesInDropdown = Object.keys(categoriesOptions);
    this.reset = true;
  }

  async getProductCategories() {
    let productCat: any = await getDocsByType(
      this.app.customers.expectCurrent,
      "productCategory"
    );
    if (productCat.length > 0) {
      this.categories = productCat[0].categories;
    }
  }

  async saveProductCategoryDoc(doc: ProductCategory) {
    let docs = [doc];
    let oldProductCategories = await getDocsByType(
      this.app.customers.expectCurrent,
      "productCategory"
    );
    //delete old product categories doc before saving new one
    if (oldProductCategories.length > 0) {
      oldProductCategories[0]._deleted = true;
      await bulkDocsByType(
        this.app.customers.expectCurrent,
        "productCategory",
        oldProductCategories
      );
    }
    //save new doc
    await bulkDocsByType(
      this.app.customers.expectCurrent,
      "productCategory",
      docs
    );
  }
}
