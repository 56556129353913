export class AttachmentTimeDocs {
  docs: AttachmentTime[] = [];
}
export class AttachmentTime {
  name: string = "";
  uploadTime: number = 0;
}

export class TagsObject {
  tags: string[] = [];
  attachmentsByTag: AttachmentsByTag[] = [];
}

export class AttachmentsByTag {
  name: string = "";
  tagNames: string[] = [];
}

export class AllAttachments {
  caseAttachments: any[] = [];
  commentsAttachments: any[] = [];
  solutionAttachments: any[] = [];
}

export class Attachment {
  content_type: string = "";
  revpos: number = 0;
  digest: string = "";
  length: number = 0;
  stub: boolean = false;
}
