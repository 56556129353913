export function getMNames() {
  return [
    {
      SEName: "KYOCERA AVX Components Corporation",
      names: [
        "AVX Corporation",
        "AVX CORP",
        "AVX",
        "AVX CER.",
        "AVX CERAMICS DIV OF AVX C",
        "AVX CERAMICS GMBH",
        "AVX CORP",
        "AVX GMBH",
        "AVX HQ",
        "AVX Thomson",
        "KYOC",
        "KYOCERA",
        "Kyocera AVX",
        "KYOCERA CORP",
        "AVX",
        "AVX CER.",
        "AVX Corp",
        "AVX ELCO",
        "KYOCERAAVX",
        "AVX Kyocera",
        "AVX SA",
      ],
    },
    {
      SEName: "Yageo",
      names: [
        "Yageo Corporation",
        "YAGEO CORP",
        "YAG",
        "YAGEO (Phycomp)",
        "Yageo / Phycomp",
        "YAGEO CORPORATION",
        "YAGEO Europe",
        "YAGEO PHICOM",
        "Yageo Phycomp",
        "Yageo-Phycom",
        "YAGEO",
        "YAGEO EUROPE GMBH",
        "YAGEO/Phicomp",
        "YAGEO AMERICA CORPORATION",
        "YAGEO/Phicomp",
      ],
    },
    {
      SEName: "Murata Manufacturing",
      names: [
        "MURATA-ELEKTRONIK",
        "MURATA MANUFACTURING CO LTD",
        "MURATA POWER SOLUTIONS INC",
        "Murata Power Solutions",
        "MURATA",
        "murata",
        "Murata",
        "MURATA ELE",
        "Murata Electronics",
        "Murata Electronics Europe",
        "Murata Electronics North America",
        "MURATA ELEKTRONIK GMBH",
        "MURATA MANUFACTURING",
        "MURATA MFG CO LTD",
        "Murata Power Solutions Inc.",
        "MURATA PS",
        "MURATAPS",
        "MURATA MANUFACTURING CO.,",
        "MURATA MANUFACTURING CO., LTD.",
      ],
    },
    {
      SEName: "TDK",
      names: [
        "TDK Corporation",
        "TDK CORP",
        "EPCOS",
        "EPCOS (TDK)",
        "EPCOS AG",
        "EPCOS Inc",
        "EPCOS/TDK",
        "TDK / EPCOS",
        "TDK EPCOS",
        "TDK Group",
        "TDK SEMICONDUCTOR CORP",
        "TDK-EPC",
        "TDK*",
        "TDK/EPCOS",
        "TDK ELECTRONICS",
        "TDK/EPCOS",
        "TDK Europe",
        "EPCOS (DE-HQ TDK)",
        "Epcos",
      ],
    },
    {
      SEName: "Analog Devices",
      names: [
        "ANALOG-DEVICES GMBH",
        "ANALOG DEVICES INC",
        "LINEAR TECHNOLOGY CORP",
        "ADI",
        "Analog",
        "ANALOG DEV",
        "ANALOG DEVICES",
        "ANALOG DEVICES INC",
        "Analog Devices Inc.",
        "ANALOG NMA",
        "ANALOG-DEVICES",
        "LINEAR ",
        "ANALOG Devices",
        "DALLAS SEMICONDUCTOR CORPORATION DBA MAXIM DALLAS DIRECT",
        "Linear Technology",
        "LinearTechnology",
        "LINEAR TECHNOLOGY",
        "Linear Tech.",
        "Linear Tech",
        "Linear Technology Corporation",
        "Analog Devic",
        "MAXIM INTEGRATED PRODUCTS INC",
        "MAXIM",
        "MAXIM INTEGRATED PRODUCTS",
        "MAXIM INTEGRATED PRODUCTS INC",
        "MAXIM I.P.",
        "Maxim Integrated",
        "Analog Device",
        "MAXIM INTEGRATED PRODUCTS, INC.",
        "Analog Devices / Maxim Integrated",
      ],
    },
    {
      SEName: "KEMET Corporation",
      names: [
        "Kemet Electronic",
        "KEMET ELECTRONICS CORP",
        "KEMET",
        "KEMET CORPORATION",
        "KEMET ELECTRONIC CORPORATION",
        "KEMET ELECTRONICS CORP",
        "Kemet Electronics Corporation",
        "KEMET SC",
        "KEMET ELECTRONICCORPORATION",
        "Kemet",
      ],
    },
    {
      SEName: "TE Connectivity",
      names: [
        "TE CONNECTIVITY SOLUTIONS GMBH",
        "TE CONNECTIVITY",
        "TE CONNECTIVITY LTD",
        "TYCO ELECTRONICS CORPORATION",
        "AMP/Tyco",
        "TYCO ELECTRONICS CORPORATION DIV RAYCHEM",
        "TYCO ELECTRONICS RAYCHEM GMBH",
        "TYCO ELECTRONICS UK LTD",
        "TE",
        "TE / AMP",
        "TE AMP",
        "TE CONN.",
        "TE CONNECT",
        "TE CONNECTIVITY / AMP",
        "TE Connectivity / Holsworthy",
        "TE Connectivity AMP",
        "TE Connectivity AMP Connectors",
        "TE CONNECTIVITY CORPORATION",
        "TE CONNECTIVITY LTD",
        "TE Connectivity Ltd.",
        "TE Connectivity Passive Product",
        "TE connectivity Tyco Electronics Corporation",
        "TE-Connectivity",
        "TE(TYCO)",
        "TYCO",
        "Tyco / AMP",
        "Tyco AMP",
        "TYCO AXI.",
        "Tyco El",
        "Tyco Electronics",
        "Tyco Electronics (AMP)",
        "Tyco Electronics (CGS)",
        "Tyco Electronics (HOLSWORTHY)",
        "Tyco Electronics (SIGMA)",
        "TYCO ELECTRONICS AMP GMBH",
        "Tyco Elektroniks Logistics AG",
        "TYCO/TE",
        "TYCO/TE ?",
        "TYCO ELEC.-AMP",
        "TYCO ELEC.",
        "TYCO ELECTRONICS AMP",
        "ERNI ELECTRONICS INC",
        "ERNI",
        "ERNI ELEKTROAPPARATE GMBH",
        "ERNI GMBH",
        "CGS-TE Connectivity",
        "TE Connectivity / Axicom",
      ],
    },
    {
      SEName: "Bourns",
      names: [
        "BOURNS GMBH",
        "BOURNS INC",
        "BOURNS GMB",
        "BOURNS",
        "BOURNS INSTRUMENTS INC",
        "BURNS",
        "BOURNS INC TRIMPOT DIV",
        "Bourns Inc.",
        "Bourns Benelux",
      ],
    },
    {
      SEName: "Infineon Technologies AG",
      names: [
        "Infineon",
        "INFINEON TECHNOLOGIES AG",
        "INFINEON",
        "Infineon Inc",
        "Infineon Technologies",
        "Infineon technologies ",
        "INFINEON TECHNOLOGIES AG",
        "Cypress Semi- conductor Corporation",
        "CYPRESS",
        "I.RECTIF.",
        "INTERNATIONAL RECTIFIER",
        "INFINEON TECHN",
        "INFINEON TECHNOLOGIES AG ZENTRAL",
        "INTERNATIONAL RECTIFIER CORPORATION",
        "lnt. Rectifier",
        "INTERNATIONAL RECTIFIER CORP",
        "CYPRESS SEMICONDUCTOR",
        "INFINEON TECHNOLOGIES",
        "Infineon IR Hirel Products, Inc.",
      ],
    },
    {
      SEName: "PHOENIX CONTACT",
      names: [
        "PHOENIX CONTACT DEUTSCHLAND GMBH",
        "PHOENIX CONTACT GMBH & CO KG",
        "Phoenix Contact GmbH & Co.KG",
        "PHOENIX SYSTEMELEMENTE UND MESSTECHNIK VERTRIEBS GMBH",
        "Pheonix Contact",
        "PHOENIX",
        "PHOENIX CO",
        "Phönix Contact",
        "Phoenix Con",
        "Phoenix",
        "Poenix Contact",
      ],
    },
    {
      SEName: "Fischer Elektronik GmbH & Co. KG",
      names: [
        "FISCHER-ELEKTRONIK GMBH & CO. KG",
        "FISCHER",
        "FISCHER EL",
        "Fischer Elect.",
        "fischer electronic",
        "Fischer Elektronik",
        "Fischer Elektronik GmbH",
        "FISCHER-EL",
        "FischerElektronik",
        "fischer-elektronik",
        "Fischer Elektroniks",
      ],
    },
    {
      SEName: "HARTING Technology Group",
      names: [
        "HARTING DEUTSCHLAND GMBH & CO. KG",
        "HARTING",
        "HARTING ELEKTRONIK GMBH",
        "Harting GmbH",
        "Harting Elec",
        "HARTING Technology",
        "Harting",
      ],
    },
    {
      SEName: "WAGO GmbH & Co. KG",
      names: [
        "WAGO-KONTAKTTECHNIK GMBH",
        "WAGO CORP",
        "Wago",
        "WAGO Kontakttechnik",
        "WAGO",
        "Wago Kontakttechnik GmbH",
        "WAGO Kontakttechnik GmbH & Co. KG",
      ],
    },
    {
      SEName: "Weidmueller GmbH & Co. KG",
      names: [
        "WEIDMUELLER GMBH U. CO. KG",
        "WEIDMUELLER GMBH & CO KG",
        "WEIDMUELL.",
        "WEIDMUELLE",
        "Weidmueller",
        "WEIDMÜLLER",
        "Weidmüller",
        "Weidmüller GmbH & Co",
      ],
    },
    {
      SEName: "Macronix International",
      names: ["Macronix", "MACRONIX", "MACRONIX INTERNATIONAL CO LTD"],
    },
    {
      SEName: "Bosch",
      names: [
        "ROBERT BOSCH GMBH",
        "BOSCH",
        "Bosch Rexroth",
        "Bosch Robert AG",
        "Bosch Rexroth AG",
        "BOSCH SEN",
      ],
    },
    {
      SEName: "Lapp Group",
      names: [
        "U.I. Lapp GmbH",
        "Lapp Kabel GmbH",
        "Lapp U.I. GmbH, Stuttgart,",
      ],
    },
    {
      SEName: "Maxim Integrated",
      names: [
        "MAXIM INTEGRATED PRODUCTS INC",
        "MAXIM",
        "MAXIM INTEGRATED PRODUCTS",
        "MAXIM INTEGRATED PRODUCTS INC",
        "MAXIM INTEGRATED PRODUCTS, INC.",
      ],
    },
    {
      SEName: "Vishay",
      names: [
        "VISHAY INTERTECHNOLOGY INC",
        "VISHAY SEMICONDUCTORS",
        "VISHAY TELEFUNKEN",
        "VISHAY SILICONIX",
        "VISHAY BC COMPONENTS",
        "VISHAY VITRAMON",
        "VISHAY ELECTRONIC GMBH",
        "BC Beyschlag Components",
        "VISHAY",
        "BEYSCHLAG",
        "Draloric",
        "DRALORIC E",
        "DraloricBeyschlag",
        "Roederstein",
        "VISHAY (Bccomponents)",
        "VISHAY (Dale)",
        "VISHAY (Draloric)",
        "VISHAY (Roederstein)",
        "VISHAY (Siliconix)",
        "VISHAY (Sprague)",
        "Vishay / Dale",
        "Vishay / Draloric",
        "Vishay / Vitramon",
        "Vishay BC Components",
        "vishay beyschlag",
        "VISHAY D.",
        "Vishay Dale",
        "Vishay Draloric",
        "VISHAY ELECTRONIC",
        "VISHAY ELECTRONIC, BC COMP.",
        "Vishay General Semiconductor",
        "VISHAY GROUP",
        "Vishay Intertechnology ",
        "VISHAY INTERTECHNOLOGY INC",
        "Vishay Roederstein",
        "VISHAY SEM",
        "Vishay Semi",
        "Vishay Semiconductor",
        "Vishay Semiconductor Diodes Division",
        "Vishay Semiconductor Opto Division",
        "VISHAY Semiconductors",
        "Vishay Siliconix",
        "VISHAY VITRAMON",
        "VISHAY,IRC",
        "VISHAY/DALE",
        "Vishay Intertechnology, Inc.",
        "VISHAY-PASSIVE COMPONENTS",
        "VISHAY-SEMI (GENERAL-SEMI)",
        "VISHAY-SEMI (TELEFUNKEN)",
        "Dale",
        "SPRAGUE ELECTRIC CO WORLD HQS",
        "Vishay Dale Electronics, LLC",
        "SPRAGUE ELEKTRONIK GMBH",
        "SLICINIX",
        "VISHAY ELECTRONIC GMBH",
        "VISHAY DALE ELECTRONICS INC.!DIV VISHAY DALE ELECTRONICS",
        "VISHAY DALE ELECTRONICS INC.!DIV VISHAY DALE ELECTRONICS",
        "Vishay Dale Electronics, LLC",
        "VISHAY ELECTRONIC GMBH",
        "VISHAY ELECTRONIC GMBH GESCHAEFTSBEREICH ROEDERSTEIN ESTA UND HYBRIDE",
        "VISHAY INTERTECHNOLOGY INC.",
        "VISHAY SEMICONDUCTOR GMBH VISHAY INTERTECHNOLOGY",
        "VISHAY-SEMICONDUCTORS",
        "Vishay / BC Components",
        "Vishay / Draloric ",
        "Vishay / Beyschlag",
        "Vishay / Sprague",
      ],
    },
    {
      SEName: "Nexperia",
      names: [
        "NEXPERIA",
        "NEXPERIA B.V.",
        "Nexperia USA Inc.",
        "NXE",
        "PHIL/SIGN",
        "PHILIPS SC",
        "PHILIPS SE",
      ],
    },
    {
      SEName: "onsemi",
      names: [
        "ONSEMI",
        "ON Semiconductor",
        "FAIRCHILD SEMICONDUCTOR CORP",
        "Fairchild Semiconductor",
        "FAIRCHILD",
        "FAIRCH.SEM",
        "FAIRCHILD SEMICORP",
        "FSC",
        "ON",
        "ON SEMI",
        "ON Semi.",
        "ON SEMICONDUCTOR",
        "ON Semiconductor / Fairchild",
        "ON-Semi",
        "ON-Semiconductor",
        "ONSEM",
        "Onsemi ",
        "Fairchild",
        "FAIRCHILD SEM.CORP.",
        "NATIO.SC",
        "NATIO.SC D",
        "ON Semi- conductor",
        "ONSEMI",
        "On Semi",
        "ONS",
        "Onsemi/Fairchild",
      ],
    },
    {
      SEName: "Texas Instruments",
      names: [
        "TEXAS INSTRUMENTS INC",
        "NATIONAL SEMICONDUCTOR CORP",
        "National Semiconductor",
        "NATIO.SC",
        "National / TI",
        "NSC",
        "NSC/TI",
        "TEXAS",
        "Texas Ins",
        "Texas Inst",
        "Texas Inst.",
        "Texas Instrument",
        "TEXAS INSTRUMENTS",
        "TEXAS INSTRUMENTS GMBH",
        "TI",
        "Ti",
        "TI ?",
        "TI D",
        "TI National Semiconductor",
        "TI SEMIC.",
        "Texas Instru",
        "TEXAS-INSTRUMENTS DTL. GM",
        "TEXAS-INSTRUMENTS DTL. GMBH",
        "Nat. Semicon.",
        "NATIONAL SEMICONDUCTOR",
        "NATIONAL SEMICONDUCTOR (UK) LTD",
        "NATIONAL SEMICONDUCTOR CORPORATION",
        "TEXAS INST.",
        "TEXAS INSTR",
        "TEXAS INSTR.",
        "Texas Instruments EMEA Sales G",
        "TEXAS INSTRUMENTS INCORPORATED",
        "Texas Instruments LTD",
        "Texax Instr.",
        "Texas Instrements",
        "Texas Instruments, Inc.",
      ],
    },
    {
      SEName: "ITT Inc",
      names: [
        "ITT-CANNON GMBH",
        "ITT-INDUSTRIES CANNON",
        "CANNON",
        "ITT",
        "ITT CAN.D",
        "ITT CANNON",
        "ITT CORP ITT CANNON DIV",
        "ITT Cannon GmbH-Weinstadt",
      ],
    },
    {
      SEName: "Littelfuse",
      names: [
        "LITTELFUSE INC",
        "LITTELFUSE",
        "Littelfuse Inc.",
        "Littelfuse, Inc.",
        "LITTLEFUSE INC.",
      ],
    },
    {
      SEName: "Broadcom Inc",
      names: [
        "BROADCOM INC",
        "AVAGO",
        "Avago",
        "Avago Technologies",
        "AVAGO TECHNOLOGIES U.S. INC.",
        "BROADCOM",
        "Broadcom / Avago",
        "Broadcom Limited",
        "BROADCOM LTD",
        "AVAGOTECH",
        "Broadcom",
        "BROADCOM-NMO-6785",
      ],
    },
    {
      SEName: "STMicroelectronics",
      names: [
        "STMICROELECTRONICS",
        "ST",
        "ST MICRO",
        "ST Microelectronics",
        "ST Microelectronics Ltd",
        "ST-Microelectronic",
        "STM",
        "STMICRO",
        "STM",
        "STM MICROE",
        "STMICRO",
        "STMICROELD",
        "STMICROELECTRONICS GMBH",
        "STMicroelect",
        "STMicroelectronic",
        "ST micro",
        "ST Microelectr.",
        "ST_Microelectr",
        "ST-MICROELEC.",
        "ST. Microelectronics",
      ],
    },
    {
      SEName: "KOA Speer Electronics",
      names: [
        "KOA SPEER ELECTRONICS INC",
        "KOA",
        "KOA SPEER",
        "KOA Speer",
        "KOA SPEER ELECTRONICS",
        "KOA SPEER ELECTRONICS, INC.",
      ],
    },
    {
      SEName: "Motorola",
      names: ["MOTOROLA INC"],
    },
    {
      SEName: "Jauch Quartz GmbH",
      names: ["JAUCH QUARTZ GMBH", "Jauch", "JAUCH HC", "JAUCH HERB", "JAUCH"],
    },
    {
      SEName: "Panasonic",
      names: [
        "PANASONIC CORP",
        "PANAS.COR.",
        "PANASONIC",
        "Panasonic Austria Handelsware Panasonic Austria Handelsware",
        "PANASONIC CORP",
        "PANASONIC DEUTSCHLAND GMBH",
        "Panasonic Electric Works",
        "Panasonic Electronic Components",
        "Panasonic Headquarter",
        "Panasonoc",
        "Panasonic Industries",
        "Panasonic Industrial Devices",
      ],
    },
    {
      SEName: "Renesas Electronics",
      names: [
        "RENESAS ELECTRONICS CORP",
        "DIALOG SEM",
        "DIALOG SEMICONDUCTOR GMBH",
        "IDT",
        "INTEGR.DEV",
        "INTEGRATED DEVICE TECHNOLOGY INC",
        "RENESAS",
        "RENESAS D",
        "Renesas Electronics Europe",
        "Intersil",
        "INTERSIL",
        "INTERSIL CORPORATION",
        "INTERSIL CORP",
        "Renesas",
        "RENESAS ELECTRONICS AMERICA INC.",
        "Renesas Electronics Corporation",
        "Renesas / Intersil",
      ],
    },
    {
      SEName: "Diotec Semiconductor AG",
      names: [
        "DIOTEC SEMICONDUCTOR AG",
        "DIOTEC",
        "Diotec Semi",
        "Diotec Semiconductor",
        "DIOTEC SEMICONDUCTOR GMBH",
        "DiotecSemiconductor",
      ],
    },
    {
      SEName: "ams OSRAM",
      names: ["OSRAM OPTO SEMICONDUCTORS GMBH", "ams Osram Group"],
    },
    {
      SEName: "NXP Semiconductors",
      names: [
        "FREESCALE SEMICONDUCTOR INC",
        "NXP",
        "NXP SEMICONDUCTORS",
        "NXP USA Inc.",
        "NXP B.V.(PHIL.SEM)",
        "NXP Semiconductor",
      ],
    },
    {
      SEName: "Microchip Technology",
      names: [
        "MICROCHIP TECHNOLOGY INC",
        "ATMEL CORP",
        "Atmel Corp.",
        "Atmel",
        "ACTEL CORPORATION",
        "MICROSEMI CORP",
        "MICROCHIP",
        "Microchio",
        "MICROCHIP",
        "MICROCHIP (SMSC)",
        "MICROCHIP TECHNOLOGY",
        "Microchip Technology Inc.",
        "ATMEL CORPORATION",
        "Micrel",
        "Microchip",
        "MICROCHIP",
        "MICROSE.CA",
        "MICROSEMI",
        "MICROSEMI CORP. - ANALOG MIXED SIGNAL GROUP",
        "MICROSEMI CORP.-SCOTTSDALE",
        "MICROSEMI CORPORATION",
        "MICROSEMIC",
        "MICROCHIP TECHNOLOGY INC",
      ],
    },
    {
      SEName: "Wuerth Elektronik GmbH & Co. KG",
      names: [
        "WUERTH ELEKTRONIK GMBH & CO KG",
        "WE",
        "WUERTH",
        "wuerth electronic",
        "Wuerth Electronics Inc",
        "WUERTH ELEKTRONIK STELVIO KONTEK S.P.A.",
        "Wuerth Group",
        "Wuerth-Electronic",
        "WURTH",
        "WÜRTH",
        "Würth",
        "würth electronic",
        "Würth Electronic GmbH",
        "Wurth Electronics",
        "Wurth Electronics Inc.",
        "Wurth Elektronik",
        "Würth Elektronik",
        "Wuerth Elektronik ",
        "Würth Elektronik (Wuerth Elektronik)",
        "WÜRTH-ELEKTRONIK",
        "WUERTH ELEKTRONIK",
        "Würth Industrie Serv",
        "Wuerth-Elektronik",
        "Wurth",
        "Würth Elektr.",
        "Würth-IND",
        "Fa. Würth",
        "Würth Elektronk",
        "WURTH-NMO-6842",
      ],
    },
    {
      SEName: "APITech",
      names: ["API TECHNOLOGIES CORP"],
    },
    {
      SEName: "API Delevan",
      names: [
        "API DELEVAN",
        "API DELE",
        "API Delevan Inc.",
        "API TECHNOLOGIES CORP",
        "DELEVAN",
      ],
    },
    {
      SEName: "Integrated Silicon Solution Inc",
      names: [
        "INTEGRATED SILICON SOLUTION INC",
        "Integrated Silicon Solution, Inc.",
        "ISSI",
        "ISSI Integrated Silicon Soluti",
        "ISSI, Integrated Silicon Solution Inc",
      ],
    },
    {
      SEName: "Semtech",
      names: [
        "SEMTECH CORP",
        "SEMTECH",
        "semtech",
        "Semtech Central Europe",
        "Semtech Corporation",
        "Semtech Electronics",
        "SEMTECH LI",
      ],
    },
    {
      SEName: "Samtec",
      names: ["SAMTEC INC", "SAMTEC", "samtec ", "Samtec Inc."],
    },
    {
      SEName: "ISABELLENHUETTE HEUSLER GMBH & CO. KG",
      names: [
        "ISABELLENHUETTE HEUSLER GMBH & CO KG",
        "Isabellenhuette",
        "Isabellenhütte",
        "Isabellenhütte Häusler GmbH",
      ],
    },
    {
      SEName: "Pulse Electronics",
      names: [
        "PULSE ELECTRONICS CORP",
        "PULSE",
        "PULSE ?",
        "Pulse Electronic",
        "Pulse Electronics Corporation",
        "Pulse Electronics Network",
        "Pulse",
        "PULSE ENGINEERING INC",
        "Pulse Engineering Inc.",
      ],
    },
    {
      SEName: "Fagor Electronica",
      names: ["FAGOR ELECTRONICA S COOP"],
    },
    {
      SEName: "Kingbright Company, LLC",
      names: [
        "KINGBRIGHT ELECTRONIC CO LTD",
        "KINGBR.EBT",
        "KINGBRIGHT",
        "KINGBRIGHT CORP",
        "Kingbright",
        "Kingbright Elec",
        "Kingbright Electronic",
        "Kingsbright",
      ],
    },
    {
      SEName: "Intel",
      names: [
        "INTEL CORP",
        "INTEL",
        "INTEL CORP SALES OFFICE",
        "INTEL CORPORATION",
        "INTEL CORPORATION (UK) LTD",
        "INTEL DEUTSCHLAND GMBH",
        "Intel PSG",
      ],
    },
    {
      SEName: "Lattice Semiconductor",
      names: [
        "LATTICE SEMICONDUCTOR CORP",
        "LATTICE",
        "LATTICE ?",
        "Lattice Semi",
      ],
    },
    {
      SEName: "3M",
      names: [
        "3M ELECTRONIC PRODUCTS DIVISION",
        "3M Österreich GmbH ",
        "3M CO ADHESIVES DIVISION/SALES",
        "3M FRANCE",
        "3M DEUTSCHLAND",
        "3M-D",
        "3M Electronic Solutions Division",
      ],
    },
    {
      SEName: "Abracon",
      names: [
        "ABRACON LLC",
        "ABRACOM",
        "ABRACON",
        "IQD",
        "ABRACON CORPORATION",
        "Abracon Corporation",
      ],
    },
    {
      SEName: "Central Semiconductor",
      names: [
        "CENTRAL SEMICONDUCTOR CORP",
        "CENT.SEMI.",
        "CENTRAL SE",
        "Central Semi",
        "CENTRAL SEMICONDUCTOR",
        "Central Semiconductor Corp",
        "CentralSemi ",
        "CentralSemiconductor",
      ],
    },
    {
      SEName: "Coilcraft",
      names: [
        "COILCRAFT INC",
        "COILCRAFT",
        "Coilcraft Europe, Ltd.",
        "Coilcraft",
        "COILCRAFT (Distri. IMPACT MEMEC)",
      ],
    },
    {
      SEName: "Diodes Incorporated",
      names: [
        "DIODES INC",
        "DIODES",
        "Diodes Inc.",
        "DIODES INCORPORATED",
        "Diodes",
        "DIODES ZETEX SEMICONDUCTORS LIMITED",
        "PERICOM",
        "Diodes, Inc",
      ],
    },
    {
      SEName: "Molex",
      names: [
        "MOLEX LLC",
        "MOL",
        "MOLEX",
        "MOLEX ?",
        "Molex Connector",
        "MOLEX Deutschland GmbH",
        "MOLEX MUEN",
        "FCT",
        "FCT-ELECTRONIC GMBH",
        "MOL",
      ],
    },
    {
      SEName: "Micron Technology",
      names: [
        "MICRON TECHNOLOGY",
        "MICRON TECHNOLOGY INC",
        "MICRON",
        "Micron ",
        "MICRON ?",
        "Micron Semiconductor",
        "MICRON TEC",
        "Micron Technology Inc.",
        "MICRON TECHNOLOGY, INC.",
        "MICT",
      ],
    },
    {
      SEName: "SOURIAU",
      names: ["SOURIAU-SUNBANK CONNECTION TECHNOLOGIES"],
    },
    {
      SEName: "WIMA",
      names: ["WIMA GMBH & CO KG", "Wima", "WIMA"],
    },
    {
      SEName: "MaxLinear",
      names: ["EXAR CORP", "MAXLINEAR"],
    },
    {
      SEName: "J.S.T.MFG.CO.,LTD",
      names: [
        "J S T MFG CO LTD",
        "JST",
        "JST Manufacturing",
        "JST Sales America Inc.",
        "JST Mfg",
        "J.S.T. Deutschland GmbH",
      ],
    },
    {
      SEName: "MENTOR GmbH & Co. Praezisions-Bauteile KG",
      names: [
        "MENTOR GMBH & CO PRAZISIONS BAUTEILE KG",
        "MENTOR GMBH & CO.",
        "MENTOR",
        "MENTOR GMB",
        "MENTOR GMBH & CO",
      ],
    },
    {
      SEName: "Schaffner Holding AG",
      names: ["Schaffner"],
    },
    {
      SEName: "Schurter",
      names: [
        "SCHURTER AG",
        "SCHURTER",
        "SCHURTER A",
        "SCHURTER D",
        "Schurter Electronics",
        "Schurter GmbH",
        "Fa.Schurter",
        "Schurter Inc.",
        "SCHURTER-NMO-6842",
      ],
    },
    {
      SEName: "SEMITEC Corporation",
      names: ["SEMITEC CORP", "Semitec"],
    },
    {
      SEName: "Sharp",
      names: ["SHARP CORP", "SHARP"],
    },
    {
      SEName: "SUMIDA CORPORATION",
      names: ["Sumida"],
    },
    {
      SEName: "Teledyne e2v",
      names: ["TELEDYNE E2V (UK) LTD", "TELEDYN.CA"],
    },
    {
      SEName: "Aavid Thermalloy",
      names: [
        "Aavid",
        "AAVID THERMALLOY",
        "AAVID THERMALLOY LLC",
        "Aavid Kunze",
        "Aavid Therma",
      ],
    },
    {
      SEName: "Toshiba",
      names: [
        "TOSHIBA CORP",
        "TOSHIBA",
        "TOSHIBA D",
        "TOSHIBA HO",
        "Toshiba Semiconductor and Storage",
      ],
    },
    {
      SEName: "TT Electronics",
      names: [
        "TT ELECTRONICS PLC",
        "TT ELECTR.",
        "TT ELECTRONICS",
        "TT Electronics/ OPTEK",
        "TT electronics Europe, Welwyn Compo",
      ],
    },
    {
      SEName: "ALPS ALPINE CO., LTD",
      names: [
        "ALPS",
        "ALPS EL",
        "ALPS ELECTRIC CO LTD",
        "ALPS Electric",
        "ALPS ELECTRIC EUROPA GMBH",
      ],
    },
    {
      SEName: "Assmann WSW components, Inc",
      names: [
        "ASSMAN",
        "Assmann",
        "ASSMANN EL",
        "ASSMANN WSW",
        "ASSMANN WSW COMPONENTS GMBH",
        "ASSMANN WSW COMPONENTS, INC",
      ],
    },
    {
      SEName: "Bel Fuse Inc",
      names: [
        "BEL FUSE",
        "BEL FUSE INC",
        "Bel Fuse Inc.",
        "bel Magnetic Solutions",
        "Bel Fuse",
        "Belfuse",
      ],
    },
    {
      SEName: "Allegro MicroSystems",
      names: [
        "ALLEGRO",
        "ALLEGRO ?",
        "ALLEGRO MCROSYSTEMS INC.",
        "Allegro microsystems",
        "Allegro Microsystems --tbd--",
        "Allegro Microsystems",
      ],
    },
    {
      SEName: "Alliance Memory",
      names: ["ALLIANCE", "ALLIANCE M", "ALLIANCE MEMORY INC", "ALLIANCE S"],
    },
    {
      SEName: "Alpha and Omega Semiconductor",
      names: [
        "ALPHA",
        "ALPHA & OMEGA SEMICONDUCTOR LTD",
        "ALPHA&OMEGA",
        "Alpha & Omega",
      ],
    },
    {
      SEName: "ASJ Pte Ltd",
      names: ["ASJ", "ASJ COMPONENTS"],
    },
    {
      SEName: "Beckhoff Automation",
      names: ["BECKHOFF", "BECKHOFF AUTOMATION GMBH"],
    },
    {
      SEName: "Brady Corporation",
      names: ["BRADY", "Brady-GmbH", "BRADY GMBH, SETON DIVISION"],
    },
    {
      SEName: "Bulgin Limited",
      names: ["BULGIN", "BULGIN COMPONENTS PLC", "BULGIN COMPOENENTS"],
    },
    {
      SEName: "C&K",
      names: [
        "C & K COMP",
        "C & K COMPONENTS INC",
        "C AND K",
        "C&K Components",
        "C&K Switches",
        "C AND K COMPONENTS INC",
        "CK",
      ],
    },
    {
      SEName: "Cardinal Components",
      names: ["CARDINAL C", "CARDINAL"],
    },
    {
      SEName: "CHEMI-CON",
      names: [
        "Europe Chemi-Con",
        "NCC",
        "NIPPON CHEMI-CON",
        "NIPPON CHEMI-CON CORP",
        "United Chemi-Con",
        "Nippon Chemi-Con",
      ],
    },
    {
      SEName: "Chilisin",
      names: ["CHILISIN", "Chilisin Electronics Corp."],
    },
    {
      SEName: "Cirrus Logic",
      names: ["CIRRUS LOGIC INC", "Cirrus Logic Inc."],
    },
    {
      SEName: "Cisco Systems",
      names: ["CISCO", "CISCO SYSTEMS INC."],
    },
    {
      SEName: "CITIZEN ELECTRONICS CO., LTD",
      names: [
        "CITIZEN",
        "Citizen Electronic Co.Ltd",
        "CITIZEN FINEDEVICE CO LTD",
      ],
    },
    {
      SEName: "Components Corporation",
      names: ["Components Corp", "COMPONENTSCORP"],
    },
    {
      SEName: "Continental Device India Limited",
      names: ["CDIL", "CONTINENTAL DEVICE INDIA LTD"],
    },
    {
      SEName: "Cornell Dubilier Electronics",
      names: ["CDE", "Cornell Dubilier", "CORNELL-DU", "Cornell Dubi"],
    },
    {
      SEName: "CTS Corporation",
      names: ["CTS", "CTS CORP", "CTS Electrocomponents", "CTS KNIGHT"],
    },
    {
      SEName: "CUI Devices",
      names: ["CUI", "CUI DEVICE", "CUI INC"],
    },
    {
      SEName: "Data Delay Devices",
      names: ["DATA DELAY", "data delay devices"],
    },
    {
      SEName: "DATA MODUL AG",
      names: ["DATA-MODUL", "DATAMODUL"],
    },
    {
      SEName: "Dell",
      names: [
        "DELL ASIA PACIFIC SDN",
        "DELL CANADA",
        "DELL COMPUTER CORPORATION LTD",
        "DELL GMBH",
        "DELL TECHNOLOGIES INC.",
        "DELL",
        "DELL COMPUTER CORP",
        "DELL MARKETING L.P.",
      ],
    },
    {
      SEName: "Dialight",
      names: ["DIALIGHT", "DIALIGHT G"],
    },
    {
      SEName: "E-tec Interconnect",
      names: ["E-TEC", "E-TEC INTERCONNECT LTD"],
    },
    {
      SEName: "Eaton",
      names: [
        "BUSSMANN",
        "EATON",
        "EATON CORP",
        "EATON ELECTRIC GMBH -ELECTRICAL SECTOR-",
        "EATON OH",
        "COOPER BUSSMANN INC",
        "Eaton/Bussmann",
      ],
    },
    {
      SEName: "ECS, Inc. International",
      names: ["ECS", "ECS INC", "ECS INC INTERNATIONAL", "ECS Inc."],
    },
    {
      SEName: "EIC Semiconductor",
      names: ["EIC SEMI", "EIC Semi", "EIC SEMICONDUCTOR CO LTD"],
    },
    {
      SEName: "Elma Electronic",
      names: ["ELMA EL.CH", "ELMA ELECTRONIC INC"],
    },
    {
      SEName: "EM Microelectronic",
      names: ["EM MICRO", "EM Microelectr."],
    },
    {
      SEName: "Epson America, Inc",
      names: [
        "Epson",
        "EPSON America Inc",
        "Epson Toyocom",
        "Epson-Seiko",
        "SEIKO",
        "SEIKO EPSO",
        "SEIKO EPSON CORP",
        "SEIKO EPSON CORPORATION",
        "EPSON",
        "Epson America, Inc",
        "Epson Toyocom",
        "Seiko Epson",
      ],
    },
    {
      SEName: "Essentra Components",
      names: [
        "ESSENTRA",
        "ESSENTRA D",
        "Essentra Components GmbH",
        "ESSENTRA-NMO-6946",
        "Fa. Essentra",
      ],
    },
    {
      SEName: "ETTINGER GmbH",
      names: ["Ettinger", "ETTINGER G", "ETTINGER GMBH", "Ettinger GmbH"],
    },
    {
      SEName: "Euroquartz Ltd",
      names: ["EUROQUARTZ", "EUROQUARTZ LTD"],
    },
    {
      SEName: "EVERLIGHT Electronics Co., Ltd",
      names: [
        "EVERLIGHT",
        "EVERLIGHT ELECTRONICS",
        "Everlight Electronics Co Ltd",
        "Everlight",
        "Everlight Electronics",
        "Everlight Americas, Inc.",
      ],
    },
    {
      SEName: "Fair-Rite Products Corp",
      names: [
        "FAIR-RITE",
        "Fair-Rite Products Cor",
        "FAIR-RITE PRODUCTS CORP",
        "Fair-Rite Products Corp.",
        "FAIRRITE",
      ],
    },
    {
      SEName: "Festo AG & Co. KG",
      names: [
        "Festo",
        "Festo AG & Co. KG",
        "Festo AG",
        "Festo AG &",
        "Festo AG & CO",
        "Festo AG & CO.",
      ],
    },
    {
      SEName: "Finder",
      names: ["FINDER", "FINDER REL"],
    },
    {
      SEName: "Fox Electronics",
      names: ["FOX ELECTR", "FOX ELECTRONICS"],
    },
    {
      SEName: "FTDI Chip",
      names: ["FTDI", "FTDI-Chip"],
    },
    {
      SEName: "FUJITSU",
      names: ["Fujitsu", "Fujitsu Semiconductor", "FUJITSU DEUTSCHLAND GMBH"],
    },
    {
      SEName: "Glenair",
      names: ["GLENAIR", "GLENAIR D", "Glenair Electric GmbH"],
    },
    {
      SEName: "Greenconn Corporation",
      names: ["GREENCONN", "GREENCONN CORPORATION"],
    },
    {
      SEName: "Gustav Klauke GmbH",
      names: [
        "KLAUKE",
        "Klauke Group",
        "KLAUKE GUS",
        "Klauke",
        "GUSTAV KLAUKE GMBH",
      ],
    },
    {
      SEName: "HALO Electronics, Inc",
      names: ["HALO", "Halo Electronics", "HALO-NMO-18932", "HALO-NMO-6785"],
    },
    {
      SEName: "Hamamatsu Photonics K.K.",
      names: ["HAMA", "HAMAMATSU"],
    },
    {
      SEName: "Harwin",
      names: ["HARWIN ", "Harwin Inc.", "HARWIN PLC", "HARWIN-NMO-6842"],
    },
    {
      SEName: "HellermannTyton",
      names: [
        "HELLERMANN",
        "HELLERMANN TYTON",
        "HELLERMANN TYTON GMBH",
        "Hellermann",
        "Hellermann Tyton GmbH",
      ],
    },
    {
      SEName: "Heraeus Holding GmbH",
      names: ["HERAEUS", "HERAEUS NO", "HERAEUS SE"],
    },
    {
      SEName: "Hirose Electric",
      names: [
        "HIRO",
        "HIROSE",
        "Hirose",
        "HIROSE ELECTRIC CO LTD",
        "HIROSE ELECTRIC GMBH",
        "HRC (Hirose)",
        "HIROSE ELECTRIC",
        "HIROSE-NMO-6842",
      ],
    },
    {
      SEName: "Holy Stone Enterprise",
      names: ["HOLY STONE ENTERPRISE", "HOLYSTONE", "Holy Stone"],
    },
    {
      SEName: "Honeywell",
      names: ["HONEYWELL", "Honeywell ACS"],
    },
    {
      SEName: "HUBER+SUHNER",
      names: [
        "HUBER + S.",
        "HUBER + SUHNER GMBH",
        "HUBER&SU.D",
        "Huber & Suhner",
      ],
    },
    {
      SEName: "ILSI America",
      names: ["ILSI", "ILSI AMERICA"],
    },
    {
      SEName: "Isocom Ltd",
      names: ["ISOCOM", "ISOCOM COMPONENTS", "ISOCOM LTD"],
    },
    {
      SEName: "Johanson Dielectrics",
      names: [
        "JOHANSON",
        "Johanson",
        "JOHANSON DIELECTRICS",
        "JOHANSON DIELECTRICS INC",
        "Johanson Dielectronics",
        "JOHANSON TECHNOLOGY INC",
        "JOHANSON TECHNOLOGY, INC.",
      ],
    },
    {
      SEName: "Kingston Technology",
      names: [
        "KINGSTON",
        "KINGSTON TECHNOLOGY COMPANY, INC.",
        "KINGSTON TECHNOLOGY DEUTSCHLAND GMBH",
        "KINGSTON TECHNOLOGY EUROPE LTD",
        "Kingston",
        "Kingston TECH",
      ],
    },
    {
      SEName: "knitter-switch",
      names: [
        "KNITTER",
        "KNITTER-SW",
        "Knitter Switch",
        "KNITTER-SWITCH ELEKTROTECHNISCHE ERZEUGNISSE KNITTER & CO. KG",
        "KNITTER-SWITCH",
      ],
    },
    {
      SEName: "Knowles Precision Devices",
      names: [
        "KNOWLES",
        "Knowles",
        "KNOWLES CAPACITOR",
        "Knowles Precision Devices",
        "Knowles Syfer",
        "SYFER",
        "NOVACAP",
        "SYFER TECH",
      ],
    },
    {
      SEName: "Laird Performance Materials",
      names: [
        "LAIRD",
        "LAIRD TECH",
        "Laird Techn.",
        "Laird TECHNOLOGIES",
        "LAIRD TECHNOLOGIES INC",
        "LAIRDTECH",
        "LAIRD-NMO-6785",
      ],
    },
    {
      SEName: "Laser Components",
      names: ["LASER 2000", "LASER COMP", "LASER COMPONENTS"],
    },
    {
      SEName: "Lite-On Technology",
      names: [
        "LITE-ON",
        "Lite-On",
        "Lite-On Inc.",
        "Lite-On Semiconductor",
        "Lite On Technology",
        "LITE-ON TECHNOLOGY CORP",
        "LITEON",
        "LITEON INC",
        "Lite ON TECH",
      ],
    },
    {
      SEName: "Maxwell Technologies",
      names: ["Maxwell", "MAXWELL TECHNOLOGIES", "Maxwell Tech"],
    },
    {
      SEName: "Mean Well Enterprises",
      names: [
        "Mean Well",
        "MEAN WELL USA, INC.",
        "MEANWELL",
        "MEAN WELL ENTERPRISES CO LTD",
        "MEAN WELL USA",
      ],
    },
    {
      SEName: "Memory Protection Devices, Inc",
      names: ["Memory Protection Devices", "Memory Prote", "MemoryPD"],
    },
    {
      SEName: "MERCURY Electronic Ind Co., Ltd",
      names: ["MERCURY", "MERCURY ELECTRONIC INDUSTRIAL CO LTD"],
    },
    {
      SEName: "METZ CONNECT",
      names: ["Metz", "MH CONNECT", "MH CONNECTORS", "METZ CONN"],
    },
    {
      SEName: "Micro Commercial Components",
      names: [
        "MCC",
        "MCC SEMI",
        "Micro Commercial",
        "Micro Commercial Components MC",
        "MICRO COMMERCIAL (MCC)",
        "MICRO COMMERCIAL COMP.",
        "MICRO COMMERCIAL COMPONENTS",
        "Micro Commercial Co",
      ],
    },
    {
      SEName: "Micro Crystal AG",
      names: ["Microcrystal", "Micro Crystal"],
    },
    {
      SEName: "Monolithic Power Systems",
      names: [
        "Monolithic Power Systems",
        "MONOLITHIC POWER SYSTEMS INC",
        "Monolothic Power Systems",
        "MPS MONO",
        "MONOLITHIC POWER SYSTEMS",
        "Monolithic Pwr",
        "MPS Monolithic Power Systems",
        "MPS",
      ],
    },
    {
      SEName: "Multicomp Pro",
      names: [
        "MULTICOMP",
        "Multicomp Company",
        "MULTICOMP COPMANY",
        "Multicomp",
        "MULTICOMP PRO",
      ],
    },
    {
      SEName: "NIC Components Corp",
      names: [
        "NIC",
        "NIC COMP",
        "NIC COMPON",
        "NIC COMPONENTS",
        "NIC Components Corporation",
        "NICCOMPONENTS",
        "NIC Components",
        "NIC Componen",
      ],
    },
    {
      SEName: "NICHIA CORPORATION",
      names: ["NICHIA", "NICHIA COR", "Nichia", "Nichia Corp."],
    },
    {
      SEName: "Nichicon",
      names: ["NICHICON CORP", "NICHICON", "NICHICON EUROPE LTD."],
    },
    {
      SEName: "NIDEC COMPONENTS CORPORATION",
      names: [
        "COPAL",
        "Copal (Nidek) Electronics GmbH",
        "COPAL ELECTRONICS",
        "COPAL ELECTRONICS INC.",
        "NIDEC COPAL ELECTRONICS",
        "NIDEC COPAL ELECTRONICS CORP",
        "Nidec Copal",
      ],
    },
    {
      SEName: "NVE Corporation",
      names: ["NVE", "NVE CORPORATION"],
    },
    {
      SEName: "ODU GmbH & Co. KG",
      names: ["ODU", "ODU-KONTAK"],
    },
    {
      SEName: "Ohmite Mfg Co",
      names: ["OHMITE", "OHMITE MANUFACTURING CO", "Ohmyte"],
    },
    {
      SEName: "Omron",
      names: [
        "OMRON",
        "OMRON CORP",
        "OMRON ELEC",
        "Omron Electronics",
        "OMRON Electronics GmbH",
        "OMRON ELECTRONICS",
      ],
    },
    {
      SEName: "Panduit",
      names: [
        "PANDUIT",
        "PANDUIT D",
        "PANDUIT GM",
        "PANDUIT CORP.!DBA PANDUIT",
        "PANDUIT EEIG",
      ],
    },
    {
      SEName: "PANJIT International Inc.",
      names: [
        "PAN JIT",
        "PAN JIT INTERNATIONAL INC.",
        "PANJIT",
        "PANJIT INTERNATIONAL",
        "PJT",
      ],
    },
    {
      SEName: "PRECI-DIP SA",
      names: ["PRECI DIP", "Preci-Dip", "PRECI-DIP DURTAL SA"],
    },
    {
      SEName: "ProTek Devices",
      names: ["PROTEK", "PROTEK DEVICES", "Protek Devices / Elwet"],
    },
    {
      SEName: "PTR HARTMANN GmbH",
      names: ["HARTMANN", "HARTMANN C", "PTR HARTMANN"],
    },
    {
      SEName: "PUI Audio, Inc",
      names: ["PUI AUDIO", "PUI Audio, Inc", "PUI Audio, Inc."],
    },
    {
      SEName: "ABLIC Inc",
      names: ["Ablic", "ABLIC INC."],
    },
    {
      SEName: "Advanced Thermal Solutions",
      names: ["ATS", "Advanced Thermal Sol"],
    },
    {
      SEName: "Aker Technology",
      names: ["AKER", "AKER TECHNOLOGY CO LTD"],
    },
    {
      SEName: "APEM",
      names: ["APEM COMPONENTS INC", "APEM D", "Apem", "Apem Inc."],
    },
    {
      SEName: "Keystone Electronics Corp",
      names: ["KEYSTONE", "Keystone Electronics"],
    },
    {
      SEName: "Radiall",
      names: ["RADIALL", "RADIALL GM", "RADIALL S A"],
    },
    {
      SEName: "RAFI GmbH & Co. KG",
      names: ["RAFI", "RAFI GMBH & CO KG"],
    },
    {
      SEName: "RCD Components",
      names: ["RCD COMPONENTS", "RCD COMPONENTS INC", "RCD-COMPONENTS"],
    },
    {
      SEName: "RECOM Power GmbH",
      names: ["RECOM", "RECOM ELEC", "RECOM Power"],
    },
    {
      SEName: "Renata SA",
      names: ["Renata", "RENATA AG"],
    },
    {
      SEName: "Rittal GmbH & Co. KG",
      names: [
        "Rittal",
        "RITTAL GMBH & CO. KG",
        "RITTAL-WER",
        "RITTAL GMBH & CO. KG",
        "Rittal GmbH & Co. KG, Herborn",
      ],
    },
    {
      SEName: "ROHM Semiconductor",
      names: [
        "ROHM",
        "ROHM ?",
        "ROHM CO LTD",
        "ROHM SEMICONDUCTOR",
        "ROHM Semiconductor GmbH",
        "ROHM WILL.",
        "R-OHM ELECTRONICS GMBH",
        "Rohm",
        "ROHM-NMO-18907",
        "ROHM-NMO-6946",
      ],
    },
    {
      SEName: "Rubycon Corporation",
      names: [
        "RUBYCON",
        "RUBYCON CORP",
        "RUBYCON CORPORATION",
        "Rubycon",
        "Rubicon",
      ],
    },
    {
      SEName: "Siemens",
      names: [
        "SIEMENS AG",
        "SIEMENS CO",
        "SIEMENS",
        "Siemens AG",
        "SIEMENS AG",
        "SIEMENS AG - I&S IS SC NO -",
        "SIEMENS AG - K PN VS 3 -",
        "SIEMENS AG ATD IS 3",
        "SIEMENS AG DF CS OM DM SUB",
        "SIEMENS ENERGY GLOBAL GMBH & CO. KG",
        "SIEMENS ENERGY GLOBAL GMBH & CO. KG SE",
        "Siemens AG RD I MO West RS IS Essen",
        "Siemens Erlangen I MORSLCSPBA",
      ],
    },
    {
      SEName: "Silicon Labs",
      names: [
        "SILICON L.",
        "SILICON LA",
        "SILICON LABORATORIES",
        "SILICON LABORATORIES INC",
      ],
    },
    {
      SEName: "SiTime",
      names: ["Sitime", "SITIME COR", "SITIME CORP", "SiTime Corp."],
    },
    {
      SEName: "SMC Diode Solutions Co. LTD",
      names: ["SMC", "SMC CORPORATION OF AMERICA", "SMC DIODE"],
    },
    {
      SEName: "Stackpole Electronics, Inc",
      names: [
        "SEI",
        "SEI Elek",
        "STACKPOLE",
        "Stackpole (SEI)",
        "STACKPOLE ELECTRONIC",
        "Stackpole Electronics",
        "STACKPOLE ELECTRONICS INC",
        "Stackpole Electronics Inc.",
        "STACKPOLE ELECTRONICS, INC",
        "Stackpole Electronics, Inc.",
        "SEI Electronics",
        "SEI Stackpole Electronics",
        "Stackpole El",
        "STACKPOLE",
      ],
    },
    {
      SEName: "STOCKO CONTACT GmbH & Co. KG",
      names: [
        "STOCKO",
        "Stocko Contact GmbH & Co. KG",
        "STOCKO MET",
        "STOCKO CONTACT",
      ],
    },
    {
      SEName: "Taiwan Semiconductor",
      names: [
        "TAIWAN SEM",
        "Taiwan Semi",
        "TAIWAN SEMICONDUCTOR (TSC)",
        "TAIWAN SEMICONDUCTOR CO LTD",
        "TAIWAN SEMICONDUCTOR Co. , Ltd",
        "Taiwan Semiconductors",
        "TAIWANSEMI",
        "Taiwan Semic",
        "TSC Taiwan Semiconductor",
        "TSC (Taiwan Semiconductors)",
      ],
    },
    {
      SEName: "Taiyo Yuden",
      names: [
        "TAIY",
        "TAIYO YUD.",
        "TAIYO YUDE",
        "TAIYO YUDEN CO LTD",
        "TAIYO-Y.,TDK",
        "taiyo-yuden",
        "TAIYO-YUDEN ",
        "TAIYOYUDEN",
        "TAIYOYUDEN,TDK",
        "TAYU",
        "Taiyo Yuden Co.,Ltd.",
        "TAIYO-NMO-6946",
      ],
    },
    {
      SEName: "TDK-Lambda",
      names: [
        "TDK / Lambda",
        "TDK LAMBDA",
        "TDK-LAMBDA",
        "TDK-LAMBDA AMERICAS INC.",
        "Lambda Electronics",
        "TDK-LAMBDA GERMANY GMBH",
      ],
    },
    {
      SEName: "Thin Film Technology",
      names: ["Thin Film Technology Corp.", "THINFILM"],
    },
    {
      SEName: "Traco Power",
      names: [
        "TRACO",
        "Traco ",
        "Traco Electronic",
        "TRACO ELECTRONIC AG",
        "TRACO POWER",
        "TRACOPOWER",
      ],
    },
    {
      SEName: "TXC Corporation",
      names: ["TXC", "TXC Corporat"],
    },
    {
      SEName: "Unisonic Technologies Company Limited",
      names: [
        "UNISONIC TECHNOLOGIES CO., LTD",
        "UNISONIC",
        "Unisonic Technologies",
        "Unisonic",
      ],
    },
    {
      SEName: "Viking Tech Corporation",
      names: [
        "VIKING",
        "Viking Tech",
        "VIKING TECH CORP",
        "VIKING TECH CORPORATION",
        "VIKING TW",
        "Viking Techn",
        "VIKING INTERWORKS",
      ],
    },
    {
      SEName: "W+P PRODUCTS GmbH",
      names: ["W+P", "W+P PRODUC", "W+P PRODUCTS GmbH"],
    },
    {
      SEName: "Walsin Technology",
      names: [
        "Walsin",
        "WALSIN ",
        "WALSIN TEC",
        "WALSIN TECHNOLOGY",
        "WALSIN TECHNOLOGY CORP",
        "Walsin Techn",
        "Walsin Technologies",
        "WALSIN TECHNOLOGY CORP.",
        "Walsin Technology Corporation",
      ],
    },
    {
      SEName: "Western Digital",
      names: ["WESTERN DI", "WESTERNDIG", "WESTERN DIGITAL CORP"],
    },
    {
      SEName: "Wieland Electric GmbH",
      names: ["WIELAND", "WIELAND EL", "Wieland"],
    },
    {
      SEName: "Winbond Electronics",
      names: ["WINBOND"],
    },
    {
      SEName: "Parker Hannifin",
      names: ["PARKER HANNIFIN CORP"],
    },
    {
      SEName: "Advanced Linear Devices",
      names: ["ADVANCED LINEAR DEVICES"],
    },
    {
      SEName: "Buerklin GmbH & Co. KG",
      names: ["BÜRKLIN"],
    },
    {
      SEName: "Diptronics Manufacturing Inc",
      names: ["DIPTRONICS"],
    },
    {
      SEName: "GigaDevice Semiconductor Inc",
      names: ["GIGADEVICE"],
    },
    {
      SEName: "HUBER+SUHNER",
      names: ["HUBER + S.", "HUBER&SU.D"],
    },
    {
      SEName: "IXYS",
      names: ["IXYS CORP"],
    },
    {
      SEName: "ABB",
      names: ["ABB AUTOMATION SYSTEMS GMBH", "ABB STOTZ-KONTAKT GMBH"],
    },
    {
      SEName: "Advanced Micro Devices, Inc.",
      names: ["AMD CA", "XILINX", "AMD", "Xilinx"],
    },
    {
      SEName: "AEM, Inc",
      names: ["AEM Comp. LTD", "AEM Components Co, Ltd"],
    },
    {
      SEName: "Aerospace Industries Association",
      names: [
        "National Aerospace Standards",
        "NATIONAL AEROSPACE STANDARDS COMMITTEE AEROSPACE INDUSTRIES ASS",
        "NATIONAL AEROSPACE STANDARDS COMMITTEE AEROSPACE INDUSTRIES ASSOCIATION OF AMERICA INC NOT FOR RFQ OR SOLICITATION USE",
      ],
    },
    {
      SEName: "Aten International Co., Ltd",
      names: ["ATEN INTERNATIONAL", "ATEN TECHNOLOGY", "ATEN TECHNOLOGY INC"],
    },
    {
      SEName: "Cambion Electronics Ltd",
      names: [
        "CAMBION",
        "Wearnes Cambion LTD",
        "WEARNES CAMTION LTD",
        "Wearness Cambion",
      ],
    },
    {
      SEName: "Cinch Connectivity Solutions",
      names: ["CINCH", "CINCH CONNECTORS INC."],
    },
    {
      SEName: "Comchip Technology",
      names: ["COMCHIP", "Comchip"],
    },
    {
      SEName: "Dow",
      names: [
        "DOW CORNING",
        "DOW SILICONES",
        "CORPORATION",
        "Dow Silicones Corporation",
        "DOW SILICONES DEUTSCHLAND GMBH",
      ],
    },
    {
      SEName: "EAO",
      names: ["EAO GMBH", "LUMITAS"],
    },
    {
      SEName: "ebm-papst",
      names: [
        "EBM-PAPST MULFINGEN GMBH & CO. KG",
        "EBM-PAPST ST. GEORGEN GMBH & CO. KG",
        "PAPST",
        "PAPST-MOTO",
        "ebm papst",
        "Fa. Papst",
      ],
    },
    {
      SEName: "Grayhill",
      names: ["GRAYHILL", "GRAYHILL, INC"],
    },
    {
      SEName: "Henkel",
      names: [
        "Henkel AG & Co KGAA",
        "HENKEL AG & CO. KGAA",
        "HENKEL KGAA-MUEN.",
      ],
    },
    {
      SEName: "Holt Integrated Circuits",
      names: ["HOLT Inc.", "HOLT INTEGRATED CIRCUITS"],
    },
    {
      SEName: "Leach International Corporation",
      names: ["LEACH", "LEACH NOER", "LEACH INT.,EUROPE"],
    },
    {
      SEName: "Reliance Precision Limited",
      names: ["RELIANCE PRECISION MECHATRONICS LLP"],
    },
    {
      SEName: "Lumberg Holding GmbH & Co. KG",
      names: ["Lumberg", "LUMBERG"],
    },
    {
      SEName: "Mill-Max",
      names: ["MIll-Max", "MILL-MAX MFG CORP"],
    },
    {
      SEName: "NETGEAR",
      names: ["NETGEAR DEUTSCHLAND GMBH", "NETGEAR INC", "NETGEAR, INC."],
    },
    {
      SEName: "Ohmite Mfg Co",
      names: ["Ohmite", "OHMITE"],
    },
    {
      SEName: "Otto Ganter GmbH & Co. KG",
      names: ["OTTO GANTER GMBH & CO. KG", "Otto Ganter GmbH & Co.KG"],
    },
    {
      SEName: "PTR HARTMANN GmbH",
      names: ["PTR", "PTR Hartmann GmbH"],
    },
    {
      SEName: "Radiall",
      names: [
        "RADIALL",
        "RADIALL GMBH",
        "Radiall GmbH",
        "RADIALL SA DIVISION COAX",
      ],
    },
    {
      SEName: "RS Components",
      names: [
        "RS Components GmbH",
        "RS-COMP.",
        "RS Components GmbH, Frankfurt am Main",
      ],
    },
    {
      SEName: "Seagate Technology",
      names: [
        "SEAGATE TECHNOLOGY INTERNATIONAL",
        "SEAGATE TECHNOLOGY LLC",
        "SEAGATE TECHNOLOGY SAS",
      ],
    },
    {
      SEName: "Sun Electronic Industries",
      names: ["SUN Elec", "SUN ELECTRONIC"],
    },
    {
      SEName: "Super micro Computer, Inc",
      names: ["SUPER MICRO COMPUTER, INC.", "SUPERMICRO COMPUTER"],
    },
    {
      SEName: "Vogt AG Verbindungstechnik",
      names: ["Vogt", "VOGT AG", "VOGT EL.AG"],
    },
    {
      SEName: "Alutronic Kuehlkoerper GmbH & Co. KG",
      names: ["ALUTRONIC", "ALUTRONIK KUEHLKOERPER GMBH & CO KG"],
    },
    {
      SEName: "Associated Components Technology",
      names: ["ACOMPTECH", "ACT"],
    },
    {
      SEName: "Bossard Group",
      names: ["Bossard", "Bosssard"],
    },
    {
      SEName: "DeLOCK",
      names: ["Delock", "Fa. Delock"],
    },
    {
      SEName: "FIXTEST Prufmittelbau GmbH",
      names: ["Fixtest", "Fixtest GmbH"],
    },
    {
      SEName: "Gutekunst + Co.KG",
      names: [
        "Fa. Gutekunst",
        "Fa.Gutekunst",
        "Gutekunst & Co. Federn-Herstellung",
        "Gutekunst Federn",
      ],
    },
    {
      SEName: "HAPPICH GmbH",
      names: ["HAPPICH", "Happich GmbH, Lieferwerk: CZ-Bor"],
    },
    {
      SEName: "Matsuo Electric Co.,Ltd",
      names: ["MATSUO", "MATSUO-NMO-18646"],
    },
    {
      SEName: "Nexans",
      names: ["NEXANS", "Nexans Deutschland Industries"],
    },
    {
      SEName: "SMC Corporation",
      names: ["Fa. SMC Pneumatic", "Fa.SMC", "SMC"],
    },
    {
      SEName: "Cologne Chip AG",
      names: ["Cologne Chip"],
    },
    {
      SEName: "California Eastern Laboratories",
      names: ["CEL"],
    },
    {
      SEName: "FASTRON GmbH",
      names: ["Fastron"],
    },
    {
      SEName: "APM HEXSEAL",
      names: ["APM HEXSEAL CORP"],
    },
    {
      SEName: "Yamaichi Electronics",
      names: ["YAMAICHI"],
    },
    {
      SEName: "Dow",
      names: ["DOW CORNING LTD", "DOW CORNING CORP"],
    },
    {
      SEName: "CONEC Elektronische Bauelemente GmbH",
      names: ["CONEC"],
    },
    {
      SEName: "Richtek",
      names: ["Richtek Technology Corporation"],
    },
  ];
}
