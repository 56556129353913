import { Credits } from "./credits";
import { Thread } from "./thread";

export class Post extends Credits {
  [key: string]: any;
  type: string = "post";
  omfNumber?: string = "";
  partNumber?: string = "";
  manufacturerPartNumber?: string = "";
  title_comment: string = "";
  resolveClass?: string = "";
  newContact: string = "";
  newCrtNumber: string = "";
  newManufacturer: string = "";
  newSupplier: string = "";
  newInternalNumber: string = "";
  solutionSource: string = "";
  altCrtContact: string = "";
  altCrtNumber: string = "";
  content: string = "";
  acceptedSolution: boolean = false;
  resolveCost?: number | null = null;
  resolveCostType?: string = "";
  disNumber?: string = "";
  cost?: number | null = null;
  effort?: string = "";
  snapshot?: {
    thread?: Partial<Thread>;
  };
  costForStorage?: number | null = null;
  yearsOfStorage?: number | null = null;
  noOfItems?: number | null = null;
  pricePerItem?: number | null = null;
  totalAmount?: number | null = null;
  solutionImpacts?: string[] = [];
  responsibleRole?: string = "";
  taskResponsible: string = "";
  taskDueDate?: string = "";
  reminderNewSent?: string = "";
  reminderSent?: string = "";
  reminder2Sent?: string = "";
  taskDescription: string = "";
  taskCompleted: boolean = false;
  taskNote: string = "";
  completedBy: string = "";
  completedAt?: number | null = null;
  typeOfPost?: string = "";
  stockingType?: string[] = [];
  leadTime?: number | null = null;
  vehicleNames?: string[] = [];
  deputy?: string = "";
  projectNumber?: string = "";
  hours?: number | null = null;
  hourlyRate?: number | null = null;
  nonRecurrentCost?: number | null = null;
  designChangeCost?: number | null = null;
}
