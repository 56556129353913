import { Component, OnInit } from "@angular/core";
import { getApp } from "../app";
import { Customer } from "../../../../shared/types/customers";

@Component({
  selector: "app-documentation",
  templateUrl: "./documentation.component.html",
  styleUrls: ["./documentation.component.scss"],
})
export class DocumentationComponent implements OnInit {
  links: string[] = [];

  app = getApp((app) => {
    this.app = app;
  });
  constructor() {}

  async ngOnInit() {
    this.links = this.getDocumentationURLs();
  }

  // MOVE TO SERVICE.TYPE
  // getDocumentationURLs(): string[];
  // getOMPDocumentationURLs(url: string): string;

  /** retrieve documentation links based on the language and permissions  */
  getDocumentationURLs() {
    let links: any[] = [];
    switch (this.app.language) {
      case "de":
        links = [];
        if (this.app.customers.getRMCustomers() === "rm-client") {
          links.push(
            {
              OM: "https://lcm-client.com/wp-content/uploads/Bedienungsanleitung-OM_Module.pdf",
            },
            {
              RM: "https://lcm-client.com/wp-content/uploads/Bedienungsanleitung-RM_Module.pdf",
            }
          );
        }
        if (
          this.app.customers.getRMCustomers() === "non-rm-client" &&
          this.app.customers.expectCurrent !== Customer.OMP
        ) {
          links.push({
            OM: "https://lcm-client.com/wp-content/uploads/Bedienungsanleitung-OM_Module.pdf",
          });
        }
        if (this.app.permission.RM.isAnalyst) {
          links = [];
          links.push({
            RM: "https://lcm-client.com/wp-content/uploads/Bedienungsanleitung-RM_Module.pdf",
          });
        }
        break;

      case "en":
        links = [];
        if (this.app.customers.getRMCustomers() === "rm-client") {
          links.push(
            {
              OM: "https://lcm-client.com/wp-content/uploads/UserManual-OM_Module.pdf",
            },
            {
              RM: "https://lcm-client.com/wp-content/uploads/UserManual-RM_Module.pdf",
            }
          );
        }
        if (
          this.app.customers.getRMCustomers() === "non-rm-client" &&
          this.app.customers.expectCurrent !== Customer.OMP
        ) {
          links.push({
            OM: "https://lcm-client.com/wp-content/uploads/UserManual-OM_Module.pdf",
          });
        }
        if (this.app.permission.RM.isAnalyst) {
          links = [];
          links.push({
            RM: "https://lcm-client.com/wp-content/uploads/UserManual-RM_Module.pdf",
          });
        }

        break;
      case "nl":
        links = [];
        if (this.app.customers.getRMCustomers() === "rm-client") {
          links.push(
            {
              OM: "https://lcm-client.com/wp-content/uploads/UserManual-OM_Module.pdf",
            },
            {
              RM: "https://lcm-client.com/wp-content/uploads/UserManual-RM_Module.pdf",
            }
          );
        }
        if (
          this.app.customers.getRMCustomers() === "non-rm-client" &&
          this.app.customers.expectCurrent !== Customer.OMP
        ) {
          links.push({
            OM: "https://lcm-client.com/wp-content/uploads/UserManual-OM_Module.pdf",
          });
        }
        if (this.app.permission.RM.isAnalyst) {
          links = [];
          links.push({
            RM: "https://lcm-client.com/wp-content/uploads/UserManual-RM_Module.pdf",
          });
        }
        break;
    }
    return links;
  }

  getOMPDocumentationURLs(url: string) {
    if (url === "profile.documentation") {
      switch (this.app.language) {
        case "de":
          return "https://www.obsolescence-management.net/wp-content/uploads/Schulungsunterlagen-V3.2.pdf";
        case "en":
          return "https://www.obsolescence-management.net/wp-content/uploads/UserManual-OM_Portal.pdf";
      }
    }
    // This function is used to take the urls directly from profile.settings
    return this.app.getUrl(url);
  }
}
