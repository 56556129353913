<div *ngIf="app.elements.length > 0" class="card m-1 p-1">
  <div>
    <button
      class="btn"
      *ngFor="let element of app.elements"
      [ngClass]="{ selected: element === app.element }"
      (click)="app.element = element"
      [ngbTooltip]="element"
    >
      {{ element }}
    </button>
  </div>
  <div *ngIf="app.element != null">
    <div class="controls">
      <button
        *ngIf="app.types.indexOf(app.element) !== -1"
        class="btn"
        (click)="app.type = app.element; app.routing.navigateDebug()"
        title="Docs"
      >
        {{ app.getTypeText(app.element) }}
        <!-- <span
          *ngIf="app.docs.countDocs(app.element) > 0"
          class="badge selected"
          >{{ app.docs.countDocs(app.element) }}</span -->
        >
      </button>
    </div>
  </div>
</div>

<div *ngIf="app.element != null">
  <div *ngIf="app.getSettings('permission').length > 0" class="card m-1 p-1">
    <h2>{{ app.text.settings.permissions }}</h2>
    <div>
      <button
        class="btn"
        *ngFor="let permission of app.getSettings('permission')"
        [ngbTooltip]="app.element + '.' + permission"
      >
        {{ permission }}
      </button>
    </div>
  </div>

  <div *ngIf="app.getSettings('text').length > 0" class="card m-1 p-1">
    <h2>{{ app.text.settings.text }}</h2>
    <div>
      <button
        class="btn"
        *ngFor="let text of app.getSettings('text')"
        [ngbTooltip]="app.element + '.' + text"
      >
        {{ app.getText(app.element + "." + text) }}
      </button>
    </div>
  </div>

  <div *ngIf="app.getSettings('list').length > 0" class="card m-1 p-1">
    <h2>{{ app.text.settings.lists }}</h2>
    <div *ngFor="let list of app.getSettings('list')" class="m-1 p-1">
      <b>{{ list }}</b>
      <div>
        <button
          *ngFor="
            let item of app.getList(app.element + '.' + list);
            index as index
          "
          class="btn btn-sm"
          [ngbTooltip]="index + 1"
        >
          {{ item }}
        </button>
      </div>
    </div>
  </div>

  <div *ngIf="app.getSettings('url').length > 0" class="card m-1 p-1">
    <h2>{{ app.text.settings.urls }}</h2>
    <div *ngFor="let entry of app.getSettings('url')" class="m-1 p-1">
      <b>{{ entry }}</b
      >:
      <a [href]="app.url[app.element][entry]" target="_blank">{{
        app.url[app.element][entry]
      }}</a>
    </div>
  </div>
</div>
