import { Settings, defaultSettings } from "../components";
import { _ComponentSettings } from "../settings/settings";

export type FieldIdProxy = {
  [name in keyof Settings]: ComponentFieldIdProxy<Settings[name]["field"]>;
};

export type ComponentFieldIdProxy<T> = {
  [name in keyof T]: string;
};

export function getFieldIdProxy() {
  const proxy: any = {};
  Object.keys(defaultSettings).forEach((name) => {
    Object.defineProperty(proxy, name, {
      get: () =>
        getComponentFieldIdProxy(defaultSettings[<keyof Settings>name], name),
    });
  });

  return <FieldIdProxy>proxy;
}

function getComponentFieldIdProxy<T extends _ComponentSettings>(
  component: T,
  componentName: string
) {
  const proxy: any = {};
  const { field } = component;
  Object.keys(field).forEach((name) => {
    Object.defineProperty(proxy, name, {
      get: () => componentName + "." + name,
    });
  });
  return <ComponentFieldIdProxy<T>>proxy;
}

export function fieldExists(fieldId: string) {
  const [componentName, fieldName] = fieldId.split(".");
  const component = defaultSettings[<keyof Settings>componentName];
  if (component == null) {
    return false;
  }
  return fieldName in component.field;
}
