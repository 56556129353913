<!-- <app-modal
  #discardChangesModal
  [modalMessage]="app.text.impact.discard"
  (ok)="discardChanges(); app.impact.impactNameExist = false"
>
</app-modal> -->

<ng-template #content let-modal>
  <div class="modal-header">
    <div>
      <span class="modal-title">{{ modalTitle }}</span>
    </div>
    <button class="btn pull-right" (click)="modal.dismiss('CANCEL')">
      <i class="fa fa-times"></i>
    </button>
  </div>
  <div class="modal-body">
    <div>
      <app-field
        *ngFor="let field of fields; index as index"
        [model]="app.vehicleResponsible.model"
        [field]="field"
        [index]="index"
      ></app-field>
    </div>
  </div>
  <div class="modal-footer">
    <button
      class="btn btn-ok text-center m-1"
      (click)="
        app.vehicleResponsible.save(app.vehicleResponsible.model); closeModal()
      "
      [disabled]="!showSaveButton()"
    >
      {{ app.text.app.save }}
    </button>
  </div>
</ng-template>
