import { NgbDateAdapter, NgbDateStruct } from "@ng-bootstrap/ng-bootstrap";
import { Injectable } from "@angular/core";

@Injectable()
export class AppDateAdapter extends NgbDateAdapter<string> {
  fromModel(value: string) {
    if (value == null) {
      return <any>null;
    }
    const [year, month, day] = value.split(".").map(s => Number.parseInt(s));
    return { year, month, day };
  }

  toModel(date: NgbDateStruct) {
    if (date == null) {
      return "";
    }
    const format = (n: number) => ("00" + n).slice(-2);
    return [date.year, format(date.month), format(date.day)].join("-");
  }
}
