import { AbstractGraphData } from "../abstract-graph-data";
import { Thread, GraphDataComplex } from "../graphs.service.type";
import { AppType } from "../../app.type";

export class DinCodeGraphData extends AbstractGraphData {
  vehicles = 0;
  cases = 0;
  omfNumbers = new Set<string>();

  constructor(public dinCode: string, app: AppType) {
    super(app);
  }

  add(thread: Thread) {
    this.cases++;
    this.omfNumbers.add(thread.omfNumber);
  }

  addVehicles() {
    this.vehicles = this.app.graphs.allImpacts.filter((impact) =>
      this.omfNumbers.has(impact.omfNumber)
    ).length;
  }

  get data(): GraphDataComplex {
    return {
      name: this.dinCode,
      series: [
        {
          name: this.app.text.thread.threads,
          value: this.cases,
        },
        {
          name: this.app.text.impact.impacts,
          value: this.vehicles,
        },
      ],
    };
  }
}
