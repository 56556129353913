import { ComponentSettings } from "../settings/settings";

const settings = {
  permission: {
    view: false,
  },
  text: {
    import: {
      de: "Daten importieren",
      en: "Import Data",
      nl: "Gegevens importeren",
    },
    importFile: { de: "Import now", en: "Import now", nl: "Import now" },
    selectType: {
      de: "Bitte wählen Sie den Datentyp, den Sie importieren möchten",
      en: "Please select data type that you would like to import",
      nl: "Selecteer het gegevenstype dat u wilt importeren.",
    },
    next: { de: "Nächster Schritt", en: "Next step", nl: "Volgende stap" },
    previous: {
      de: "Vorheriger Schritt",
      en: "Previous step",
      nl: "Vorige stap",
    },
    selectFile: {
      de: "Bitte wählen Sie die Datei aus, die Sie hochladen möchten",
      en: "Please select the file you would like to upload",
      nl: "Selecteer het bestand dat u wilt uploaden",
    },
    summary: {
      de: "Zusammenfassung",
      en: "Summary",
      nl: "Samenvatting",
    },
    random: {
      de: "Testdaten erzeugen",
      en: "Create test data",
      nl: "Maak testgegevens aan",
    },
    upload: {
      de: "Datei hochladen",
      en: "Upload file",
      nl: "Bestand uploaden",
    },
    save: {
      de: "Importdaten speichern",
      en: "Save",
      nl: "Import data bewaren",
    },
    clear: {
      de: "Clear",
      en: "Clear",
      nl: "Clear",
    },
    discard: {
      de: "Importdaten verwerfen",
      en: "Discard import data",
      nl: "Import data verwijderen",
    },
    deleteAllDocs: {
      de: "Sind Sie sicher, dass Sie alle Dokumente löschen möchten?",
      en: "Are you sure you want to delete all documents?",
      nl: "Weet u zeker dat u alle documenten wilt verwijderen? ",
    },
    deleteAllDocsType: {
      de: "Alle Dokumente löschen",
      en: "Delete all documents",
      nl: "Alle documenten verwijderen",
    },
    deleteTitle: {
      de: "Dokumente löschen",
      en: "Delete documents",
      nl: "Verwijder documenten",
    },
    documentsSavedSuccesfully: {
      de: "Dokumente erfolgreich gespeichert",
      en: "Documents saved succesfully",
      nl: "Succesvol opgeslagen documenten",
    },
    documentsDeletedSuccesfully: {
      de: "Dokumente erfolgreich gelöscht",
      en: "Documents deleted succesfully",
      nl: "Documenten succesvol verwijderd",
    },
    structure: {
      de: "Baugruppe",
      en: "Assembly",
      nl: "Samenstelling",
    },
    fieldsMissing: {
      de: "Die folgenden Felder konnten nicht mit einer vorhandenen Eigenschaft abgeglichen werden",
      en: "The following fields could not be matched to an existing property",
      nl: "De volgende velden konden niet worden gekoppeld aan een bestaande eigenschap",
    },
    tryAgain: {
      de: " Bitte korrigieren Sie die Überschriften und versuchen Sie es erneut!",
      en: "Please correct the headings and try again!",
      nl: "Corrigeer de koppen en probeer het opnieuw!",
    },
    column: {
      de: "Spalte:",
      en: "Column:",
      nl: "Kolom",
    },
    docExists: {
      de: "In der Datenbank gefundenes Dokument",
      en: "Document found in the database",
      nl: "Document gevonden in de database",
    },
    addItem: {
      de: "Element auswählen",
      en: "Select item",
      nl: "Selecteer item",
    },
    removeItem: {
      de: "Element abwählen",
      en: "Deselect item",
      nl: "Deselecteer item",
    },
    downloadSample: {
      de: "Beispiel herunterladen",
      en: "Download sample",
      nl: "Download voorbeeld",
    },
    options: {
      de: "Optionen",
      en: "Options",
      nl: "Opties",
    },
    choose: {
      de: "Wählen Sie eine der Optione...",
      en: "Choose one of the options...",
      nl: "Wählen Sie eine der Optione...",
    },
    select: {
      de: "Select type",
      en: "Select type",
      nl: "Select type",
    },
    review: {
      de: "Review List",
      en: "Review List",
      nl: "Review List",
    },
    manufacturerName: {
      de: "Name des Herstellers",
      en: "Manufacturer Name",
      nl: "Manufacturer Name",
    },
    importVehiclesDuplicatesError: {
      de: "There are duplicates of vehicles in the file. Duplicates: ",
      en: "There are duplicates of vehicles in the file. Duplicates: ",
      nl: "There are duplicates of vehicles in the file. Duplicates: ",
    },
  },
  list: {},
  url: {},
  field: {},
};

export const _import: ComponentSettings<typeof settings> = settings;
