import { AlertsComponent } from "./alerts/alerts.component";
import { AlertsService } from "./alerts/alerts.service";
import { App } from "./app";
import { APP_TYPE } from "./app.type";
import { AppComponent } from "./app/app.component";
import { AppDateAdapter } from "./app-date-adapter";
import { ApplicationAreaComponent } from "./application-area/application-area.component";
import { ApplicationAreaService } from "./application-area/application-area.service";
import { AssignedToComponent } from "./assigned-to/assigned-to.component";
import { AttachmentComponent } from "./attachment/attachment.component";
import { AttachmentsComponent } from "./attachments/attachments.component";
import { AttachmentsControlsComponent } from "./attachments-controls/attachments-controls.component";
import { AttachmentService } from "./attachment/attachment.service";
import { AttachmentsService } from "./attachments/attachments.service";
import { AuthComponent } from "./auth/auth.component";
import { AuthImagePipe } from "./core/pipe/auth-image.pipe";
import { AuthService } from "./auth/auth.service";
import { BoldPipe, HistoryComponent } from "./history/history.component";
import { BoxComponent } from "./box/box.component";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { BrowserModule } from "@angular/platform-browser";
import { CaseControlsComponent } from "./case-controls/case-controls.component";
import { CellComponent } from "./cell/cell.component";
import { ChangesComponent } from "./changes/changes.component";
import { ChangesService } from "./changes/changes.service";
import { CommodityGroupResponsibleService } from "./commodity-group-responsible/commodity-group-responsible.service";
import { CreateManufacturerModalComponent } from "./create-manufacturer-modal/create-manufacturer-modal.component";
import { CreateManufacturerService } from "./create-manufacturer-modal/create-manufacturer-modal.service";
import { CreditsComponent } from "./credits/credits.component";
import { CUSTOM_ELEMENTS_SCHEMA, Injector, NgModule } from "@angular/core";
import { CustomersService } from "./customers/customers.service";
import { DebugComponent } from "./debug/debug.component";
import { DetailTableComponent } from "./part/detail-table/detail-table.component";
import { DetailTableService } from "./part/detail-table/detail-table.service";
import { DinCodeResponsibleService } from "./din-code-responsible/din-code-responsible.service";
import { DocsService } from "./docs/docs.service";
import { DocumentationComponent } from "./documentationURLs/documentation.component";
import { ExampleComponent } from "./example/example.component";
import { FieldComponent } from "./field/field.component";
import { FieldOptionsComponent } from "./field-options/field-options.component";
import { FieldService } from "./field/field.service";
import { FileService } from "./file/file.service";
import { FilterFieldsComponent } from "./filter-fields/filter-fields.component";
import { FilterFieldsService } from "./filter-fields/filter-fields.service";
import { FilterListComponent } from "./filter-list/filter-list.component";
import { FilterListService } from "./filter-list/filter-list.service";
import { FilterTableComponent } from "./filter-table/filter-table.component";
import { FilterTableService } from "./filter-table/filter-table.service";
import { FilterHeaderTableComponent } from "./filter-header-table/filter-header-table.component";
import { FilterHeaderTableService } from "./filter-header-table/filter-header-table.service";
import { FilterTreeComponent } from "./filter-tree/filter-tree.component";
import { FilterTreeService } from "./filter-tree/filter-tree.service";
import { FooterComponent } from "./footer/footer.component";
import { FormsModule } from "@angular/forms";
import { FrameComponent } from "./frame/frame.component";
import { GraphsComponent } from "./graphs/graphs.component";
import { GraphsService } from "./graphs/graphs.service";
import { HeaderComponent } from "./header/header.component";
import { HeaderService } from "./header/header.service";
import { HistoryService } from "./history/history.service";
import { HomeComponent } from "./home/home.component";
import { HomeService } from "./home/home.service";
import { HTTP_INTERCEPTORS, HttpClientModule } from "@angular/common/http";
import { ImpactComponent } from "./impact/impact.component";
import { ImpactModalComponent } from "./impact-modal/impact-modal.component";
import { ImpactsComponent } from "./impacts/impacts.component";
import { ImpactService } from "./impact/impact.service";
import { ImpactsService } from "./impacts/impacts.service";
import { ImportComponent } from "./import/import.component";
import { ImportService } from "./import/import.service";
import { ImportStepperComponent } from "./import/import-stepper/import-stepper.component";
import { ImportXmlService } from "./import-xml/import-xml.service";
import { InternalItemsComponent } from "./internal-items/internal-items.component";
import { InternalItemsService } from "./internal-items/internal-items.service";
import { LeftNavComponent } from "./left-nav-component/left-nav.component";
import { LeftNavService } from "./left-nav-component/left-nav.service";
import { LimitComponent } from "./field/limit/limit.component";
import { ListOpenedCasesComponent } from "./thread/list-opened-cases/list-opened-cases.component";
import { MailComponent } from "./mail/mail.component";
import { MailService } from "./mail/mail.service";
import { ManufacturerComponent } from "./manufacturer/manufacturer.component";
import { ManufacturerMatchingComponent } from "./manufacturer-matching/manufacturer-matching.component";
import { ManufacturerMatchingModalComponent } from "./manufacturer-matching-modal/manufacturer-matching-modal.component";
import { ManufacturerMatchingService } from "./manufacturer-matching/manufacturer-matching.service";
import { ManufacturerNameService } from "./manufacturer/manufacturer-name/manufacturer-name.service";
import { ManufacturerService } from "./manufacturer/manufacturer.service";
import { ManufacturersFilterTableComponent } from "./RM/manufacturers-filter-table/manufacturers-filter-table.component";
import { ManufacturersFilterTableService } from "./RM/manufacturers-filter-table/manufacturers-filter-table.service";
import { ManufacturerUtilsService } from "./manufacturer/manufacturer-utils.service";
import { MessageComponent } from "./message/message.component";
import { MessageItemComponent } from "./message-item/message-item.component";
import { MessageService } from "./message/message.service";
import { ModalComponent } from "./modal/modal.component";
import { NextComponent } from "./next/next.component";
import { NgbDateAdapter, NgbModule } from "@ng-bootstrap/ng-bootstrap";
import { NgxChartsModule } from "@swimlane/ngx-charts";
import { NgxSpinnerModule } from "ngx-spinner";
import { NoopAnimationsModule } from "@angular/platform-browser/animations";
import { NotFoundComponent } from "./not-found/not-found.component";
import { OperationsComponent } from "./operations/operations.component";
import { OperationsService } from "./operations/operations.service";
import { OverrideMpnFieldsModalComponent } from "./override-mpnFields-modal/override-mpnFields-modal.component";
import { OverrideMpnFieldsService } from "./override-mpnFields-modal/override-mpnFields.service";
import { PaginatorComponent } from "./paginator/paginator.component";
import { PaginatorService } from "./paginator/paginator.service";
import { PartComponent } from "./part/part.component";
import { PartService } from "./part/part.service";
import { PredefinedTasksComponent } from "./predefined-tasks/predefined-tasks.component";
import { RMComponent } from "./RM/RM.component";
import { RMService } from "./RM/RM.service";
import { RMSearchComponent } from "./rm-search/rm-search.component";
import { RMSearchService } from "./rm-search/rm-search.service";
import { PartsFilterTableComponent } from "./RM/parts-filter-table/parts-filter-table.component";
import { PartsFilterTableService } from "./RM/parts-filter-table/parts-filter-table.service";
import { PasswordComponent } from "./password/password.component";
import { PcnComponent } from "./pcn/pcn.component";
import { PcnGeneratorComponent } from "./pcn-generator/pcn-generator.component";
import { PcnGeneratorModalComponent } from "./pcn-generator/pcn-generator-modal/pcn-generator-modal.component";
import { PcnGeneratorService } from "./pcn-generator/pcn-generator.service";
import { PcnGeneratorStepBarComponent } from "./pcn-generator/pcn-generator-step-bar/pcn-generator-step-bar.component";
import { PcnHitoryComponent } from "./manufacturer/pcn-history-table/pcn-history-table.component";
import { PcnsComponent } from "./pcns/pcns.component";
import { PcnService } from "./pcn/pcn.service";
import { PcnsService } from "./pcns/pcns.service";
import { PersonService } from "./person/person.service";
import { PostComponent } from "./post/post.component";
import { PostItemComponent } from "./post-item/post-item.component";
import { PostService } from "./post/post.service";
import { ProductCategoryComponent } from "./product-category/product-category.component";
import { ProductCategoryService } from "./product-category/product-category.service";
import { ProfileComponent } from "./profile/profile.component";
import { ProfileService } from "./profile/profile.service";
import { ProxyService } from "./core/proxy/proxy.service";
import { ReplacementsTableComponent } from "./manufacturer/replacements-table/replacements-table.component";
import { RMSubmenuComponent } from "./rm-submenu/rm-submenu.component";
import { RMSubmenuService } from "./rm-submenu/rm-submenu.service";
import { ResetPasswordComponent } from "./reset-password/reset-password.component";
import { RouterModule } from "@angular/router";
import { routes } from "./routing/routes";
import { RoutingComponent } from "./routing/routing.component";
import { RoutingService } from "./routing/routing.service";
import { ExternalDataFilterComponent } from "./external-data-filter/external-data-filter.component";
import { ExternalDataFilterService } from "./external-data-filter/external-data-filter.service";
import { ServiceHelper } from "./injector";
import { SettingsComponent } from "./settings/settings.component";
import { SidebarComponent } from "./sidebar/sidebar.component";
import { SortByFieldComponent } from "./sort-by-field/sort-by-field.component";
import { SpinnerService } from "./core/spinner/spinner.service";
import { StateService } from "./state/state.service";
import { StepperComponent } from "./stepper/stepper.component";
import { StepperService } from "./stepper/stepper.service";
import { StockGraphicModalComponent } from "./stock-graphic-modal/stock-graphic-modal.component";
import { SupplyChainComponent } from "./manufacturer/supply-chain-table/supply-chain-table.component";
import { SVHCItemsComponent } from "./SVHC-items/SVHC-items.component";
import { SVHCItemsService } from "./SVHC-items/SVHC-items.service";
import { SyncComponent } from "./sync/sync.component";
import { SyncService } from "./sync/sync.service";
import { TableComponent } from "./table/table.component";
import { TableService } from "./table/table.service";
import { TaskCompletedNoteComponent } from "./task-completed-note/task-completed-note.component";
import { TaskCompletedService } from "./task-completed-note/task-completed.service";
import { TasksComponent } from "./tasks/tasks.component";
import { TasksService } from "./tasks/tasks.service";
import { ThreadComponent } from "./thread/thread.component";
import { ThreadItemComponent } from "./thread-item/thread-item.component";
import { ThreadService } from "./thread/thread.service";
import { TokenInterceptor } from "./auth/token.interceptor";
import { TrainComponent } from "./train/train.component";
import { TrainseriesComponent } from "./trainseries/trainseries.component";
import { TrainService } from "./train/train.service";
import { TrainsListComponent } from "./trains-list/trains-list.component";
import { TrainsModalComponent } from "./trains-modal/trains-modal.component";
import { TreeCellComponent } from "./tree-cell/tree-cell.component";
import { TreeCellService } from "./tree-cell/tree-cell.service";
import { TreeComponent } from "./tree/tree.component";
import { TreeService } from "./tree/tree.service";
import { TypeAheadBasicComponent } from "./field/type-ahead/typeahead-basic.component";
import { UnlockComponent } from "./unlock/unlock.component";
import { UserComponent } from "./user/user.component";
import { UserLinkComponent } from "./user-link/user-link.component";
import { UserProfileListComponent } from "./user-profile-list/user-profile-list.component";
import { UsersComponent } from "./users/users.component";
import { UserSettingsComponent } from "./user-settings/user-settings.component";
import { UserSettingsService } from "./user-settings/user-settings.service";
import { UsersService } from "./users/users.service";
import { VehicleResponsibleComponent } from "./vehicle-responsible/vehicle-responsible.component";
import { VehicleResponsibleListComponent } from "./vehicle-responsible-list/vehicle-responsible-list.component";
import { VehicleResponsibleModalComponent } from "./vehicle-responsible-modal/vehicle-responsible-modal.component";
import { VehicleResponsibleService } from "./vehicle-responsible/vehicle-responsible.service";
import { WhereUsedInComponent } from "./impacts/where-used-in/where-used-in.component";
import { WorkflowAutomationComponent } from "./impact/wf-automation/wf-automation.component";
import { AutosizeModule } from "ngx-autosize";
import { CreatePartModalComponent } from "./create-part-modal/create-part-modal.component";
import { AddBomItemModalComponent } from "./add-bom-item-modal/add-bom-item-modal.component";
import { TreeRowComponent } from "./tree-row/tree-row.component";
import { TreeRowService } from "./tree-row/tree-row.service";
import { FilterService } from "./filter/filter.service";
import { TaskItemsComponent } from "./task-items/task-items.component";
import { PanelComponent } from "./panel/panel.component";
import { PanelService } from "./panel/panel.service";
import { TypeAheadBasicService } from "./field/type-ahead/typeahead-basic.service";
import { OCHomeComponent } from "./obsolescence-community/OC-home/OC-home.component";
import { OCHomeService } from "./obsolescence-community/OC-home/OC-home.service";
import { OCPostComponent } from "./obsolescence-community/OC-post/OC-post.component";
import { OCPostService } from "./obsolescence-community/OC-post/OC-post.service";
import { OCCommentComponent } from "./obsolescence-community/OC-comment/OC-comment.component";
import { OCCommentService } from "./obsolescence-community/OC-comment/OC-comment.service";
import { OCPostItemComponent } from "./obsolescence-community/OC-post-item/OC-post-item.component";
import { OCPostItemService } from "./obsolescence-community/OC-post-item/OC-post-item.service";
import { OCPostModalComponent } from "./obsolescence-community/OC-post-modal/OC-post-modal.component";
import { MFACompleteComponent } from "./MFA-complete/MFA-complete.component";

@NgModule({
  declarations: [
    AppComponent,
    HeaderComponent,
    SidebarComponent,
    PanelComponent,
    FooterComponent,
    DebugComponent,
    HomeComponent,
    SettingsComponent,
    ProfileComponent,
    MailComponent,
    UsersComponent,
    ThreadItemComponent,
    ThreadComponent,
    BoxComponent,
    PostItemComponent,
    PostComponent,
    CellComponent,
    TableComponent,
    UserComponent,
    FrameComponent,
    PaginatorComponent,
    TreeCellComponent,
    ImportComponent,
    ImportStepperComponent,
    FilterListComponent,
    FieldComponent,
    LimitComponent,
    PasswordComponent,
    NextComponent,
    ImpactsComponent,
    AttachmentsComponent,
    AttachmentComponent,
    AuthComponent,
    MFACompleteComponent,
    ModalComponent,
    TreeComponent,
    AlertsComponent,
    StepperComponent,
    CreditsComponent,
    ImpactComponent,
    WhereUsedInComponent,
    WorkflowAutomationComponent,
    ListOpenedCasesComponent,
    UserLinkComponent,
    AttachmentsControlsComponent,
    CaseControlsComponent,
    FieldOptionsComponent,
    SyncComponent,
    UnlockComponent,
    HistoryComponent,
    VehicleResponsibleListComponent,
    VehicleResponsibleComponent,
    RoutingComponent,
    ExampleComponent,
    GraphsComponent,
    SortByFieldComponent,
    PcnComponent,
    InternalItemsComponent,
    PartComponent,
    DetailTableComponent,
    RMComponent,
    RMSearchComponent,
    OperationsComponent,
    ManufacturerComponent,
    ManufacturerMatchingComponent,
    ManufacturerMatchingModalComponent,
    CreateManufacturerModalComponent,
    OverrideMpnFieldsModalComponent,
    ReplacementsTableComponent,
    PcnHitoryComponent,
    ExternalDataFilterComponent,
    LeftNavComponent,
    MessageComponent,
    MessageItemComponent,
    UserProfileListComponent,
    ManufacturersFilterTableComponent,
    RMSubmenuComponent,
    TrainsListComponent,
    TrainComponent,
    TrainseriesComponent,
    BoldPipe,
    FilterTableComponent,
    FilterHeaderTableComponent,
    AuthImagePipe,
    TasksComponent,
    PcnsComponent,
    ChangesComponent,
    FilterTreeComponent,
    FilterFieldsComponent,
    ApplicationAreaComponent,
    TaskCompletedNoteComponent,
    AttachmentComponent,
    PcnGeneratorComponent,
    PcnGeneratorModalComponent,
    PcnGeneratorStepBarComponent,
    StockGraphicModalComponent,
    ResetPasswordComponent,
    SVHCItemsComponent,
    TypeAheadBasicComponent,
    ImpactModalComponent,
    TrainsModalComponent,
    VehicleResponsibleModalComponent,
    NotFoundComponent,
    DocumentationComponent,
    ProductCategoryComponent,
    PartsFilterTableComponent,
    PredefinedTasksComponent,
    UserSettingsComponent,
    CreatePartModalComponent,
    AddBomItemModalComponent,
    TreeRowComponent,
    AssignedToComponent,
    TaskItemsComponent,
    SupplyChainComponent,
    OCHomeComponent,
    OCPostComponent,
    OCCommentComponent,
    OCPostItemComponent,
    OCPostModalComponent,
  ],
  exports: [AuthImagePipe],
  imports: [
    NgxSpinnerModule,
    BrowserModule,
    BrowserAnimationsModule,
    FormsModule,
    NoopAnimationsModule,
    HttpClientModule,
    NgxChartsModule,
    RouterModule.forRoot(routes),
    NgbModule,
    AutosizeModule,
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  providers: [
    {
      provide: NgbDateAdapter,
      useClass: AppDateAdapter,
    },
    {
      provide: APP_TYPE,
      useClass: App,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: TokenInterceptor,
      multi: true,
    },
    AuthService,
    DocsService,
    FieldService,
    HomeService,
    ImpactService,
    ImpactsService,
    UsersService,
    UserSettingsService,
    ProfileService,
    TableService,
    PaginatorService,
    MailService,
    RoutingService,
    CustomersService,
    StateService,
    FileService,
    DinCodeResponsibleService,
    CommodityGroupResponsibleService,
    ThreadService,
    PcnService,
    VehicleResponsibleService,
    PostService,
    PartService,
    RMService,
    RMSearchService,
    OperationsService,
    ManufacturerService,
    CreateManufacturerService,
    ManufacturerMatchingService,
    TreeService,
    TreeCellService,
    TreeRowService,
    LeftNavService,
    MessageService,
    AlertsService,
    ManufacturersFilterTableService,
    PartsFilterTableService,
    RMSubmenuService,
    ImportService,
    GraphsService,
    FilterListService,
    PersonService,
    TrainService,
    FilterTableService,
    FilterHeaderTableService,
    ProxyService,
    SpinnerService,
    TasksService,
    FilterTreeService,
    FilterFieldsService,
    SyncService,
    StepperService,
    PcnsService,
    InternalItemsService,
    ExternalDataFilterService,
    ApplicationAreaService,
    TaskCompletedService,
    AttachmentService,
    PcnGeneratorService,
    AttachmentsService,
    HeaderService,
    HistoryService,
    OverrideMpnFieldsService,
    ManufacturerUtilsService,
    SVHCItemsService,
    ImportXmlService,
    ChangesService,
    DetailTableService,
    ProductCategoryService,
    ManufacturerNameService,
    FilterService,
    PanelService,
    TypeAheadBasicService,
    OCHomeService,
    OCPostService,
    OCCommentService,
    OCPostItemService,
  ],
  bootstrap: [RoutingComponent],
})
export class AppModule {
  constructor(injector: Injector) {
    ServiceHelper.injector = injector;
  }
}
