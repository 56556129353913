import { ResponsibleGraphData } from "./responsible-graph-data";
import { AbstractGraph } from "../abstract-graph";
import { AppType } from "../../app.type";
import { GraphConfig } from "../graph.type";

export class ResponsibleGraph extends AbstractGraph<ResponsibleGraphData> {
  get config(): GraphConfig {
    return {
      type: "bar-horizontal-2d",
      showXAxis: true,
      showYAxis: true,
      showLegend: true,
      xAxisLabel: null,
      yAxisLabel: null,
      colorScheme: {
        domain: ["#5AA454", "#A10A28", "#C7B42C", "#AAAAAA"],
      },
      filter: 10,
    };
  }

  constructor(protected app: AppType) {
    super(app);
  }

  protected getSortFunction() {
    return (a: ResponsibleGraphData, b: ResponsibleGraphData) =>
      b.total - a.total;
  }

  protected getFilterFunction() {
    const min = this.config.filter as number;
    return (item: ResponsibleGraphData) => item.total > min;
  }

  protected async getMap(map: Map<string, ResponsibleGraphData>) {
    this.app.graphs.allThreads.forEach((thread: any) => {
      let resp: string;
      if (!thread.dinCodeRespName) return;
      resp = thread.dinCodeRespName.toLowerCase();
      const item = map.get(resp);
      if (item == null) {
        const itemNew = new ResponsibleGraphData(thread, this.app);
        map.set(resp, itemNew);
        itemNew.add(thread);
      } else {
        item.add(thread);
      }
    });
  }
}
