import { Inject, Injectable } from "@angular/core";
import { Type } from "../../../../shared/components";
import { AppType, APP_TYPE } from "../app.type";
import { formatDatetime } from "../utils/date.util";
import { HistoryDoc } from "./history";
import { HistoryServiceType } from "./history.service.type";
import { StringUtils } from "../../../../shared/utils/string.utils";
import { COMMENT, SOLUTION, TASK } from "../post/post.service.type";

@Injectable()
export class HistoryService implements HistoryServiceType {
  historyDocs: HistoryDoc[] = [];

  //properties for which a message will be displayed in the history
  //delete, solution marked as final/not final, task completed/uncompleted
  ACCEPTED_SOLUTION = "/acceptedSolution";
  TASK_COMPLETED = "/taskCompleted";
  IMPACT_SIGNED = "impact_signed";

  constructor(@Inject(APP_TYPE) private app: AppType) {}

  async getHistory() {
    let history = null;
    switch (this.app.view) {
      case "thread":
        history = await this.app.thread.getHistory();
        this.historyDocs = this.filterHistoryDocs(history);
        break;
      case "part":
        history = await this.app.part.getPartHistory();
        this.historyDocs = this.filterHistoryDocs(history);
        break;
      case "manufacturer":
        history = await this.app.manufacturer.getManufacturerHistory();
        this.historyDocs = this.filterHistoryDocs(history);
        break;
      default:
        return;
    }
  }

  /**Remove opertions which doesn't have to be displayed in the table */
  private filterHistoryDocs(historyDocs: HistoryDoc[]) {
    let docs: HistoryDoc[] = [];
    historyDocs.forEach((doc) => {
      let op: any[] = [];
      if (doc.action === "edit" && doc.data != null) {
        doc.data.operations.forEach((operation) => {
          if (this.displayRecord(doc, operation)) {
            op.push(operation);
          }
        });
        doc.data.operations = op;
      }
      docs.push(doc);
    });
    return docs;
  }

  //Display the record only if we have a label for the property that was changed (name of the property is defined in the .settings file for that particular docType)
  displayRecord(historyItem: any, changedField: any) {
    let display: boolean = false;
    let docType = historyItem.docType;
    let type = docType as Type;
    //get the keys from .settings file
    let existingFields: any = new Set(
      Object.keys(this.app.settings[type].field)
    );
    if (existingFields.has(changedField.property.split("/")[1])) {
      display = true;
    }

    //display record for DB impacts - when a responsible is changed
    if (docType === "impact") {
      let id = changedField.property.substring(1);
      if (docType === "impact" && id.includes("responsibles")) {
        display = true;
      }
    }
    if (
      (changedField.oldValue === "" && changedField.newValue == null) ||
      (changedField.oldValue == null && changedField.newValue === "") ||
      (changedField.oldValue == null && changedField.newValue == null) ||
      (changedField.oldValue == null &&
        Array.isArray(changedField.newValue) &&
        changedField.newValue.length === 0) ||
      (changedField.oldValue == null &&
        typeof changedField.newValue === "object" &&
        Object.keys(changedField.newValue).length === 0)
    ) {
      display = false;
    }
    return display;
  }

  // USED FOR DISPLAYNG DIFFERENT TEXT WHEN DOCSUBTYPE IS IMPACT_SIGNED AND FOR ACCEPTED SOLUTION, TASK COMPLETED
  // IMPACT_SIGNED is needed for the history records created before march 2024

  displayEditDefaultMessage(historyItem: any) {
    let defaultMessage: boolean = true;

    switch (historyItem.docSubType) {
      case this.IMPACT_SIGNED:
        defaultMessage = false;
        break;
      default:
        historyItem.data.operations.forEach((operation: any) => {
          if (
            operation.property === this.ACCEPTED_SOLUTION ||
            operation.property === this.TASK_COMPLETED
          ) {
            defaultMessage = false;
          }
        });
    }

    return defaultMessage;
  }

  displayCreateMessage(historyItem: HistoryDoc) {
    switch (historyItem.docType) {
      case "thread":
        return this.app.text.history.threadCreated;
      case "post":
        switch (historyItem.docSubType) {
          case COMMENT:
            return this.app.text.history.commentCreated;
          case SOLUTION:
            return this.solutionAddedTextReplace(historyItem.descriptor);
          case TASK:
            return this.app.text.history.taskCreated;
        }
      case "impact":
        switch (historyItem.docSubType) {
          case "train":
            return this.trainAddedTextReplace(historyItem.descriptor);
          default:
            return this.impactAddedTextReplace(historyItem.descriptor);
        }
      case "internalItem":
        return this.internalItemAddedTextReplace(historyItem.descriptor);
      case "part":
        switch (historyItem.docSubType) {
          case "parts":
            return this.app.text.history.partAdded;
          case "manufacturer":
            return this.manufacturerAddedTextReplace(historyItem.descriptor);
          default:
            return this.app.text.history.partCreated;
        }
      case "manufacturer":
        switch (historyItem.docSubType) {
          case "svhc_item":
            return this.app.text.history.svhcItemAdded;
          default:
            return this.app.text.history.manufacturerCreated;
        }
      case "message":
        return this.app.text.history.messageCreated;
      case "file":
        switch (historyItem.docSubType) {
          case "thread_photo":
            return this.app.text.history.threadImageCreated;
          case "manufacturer":
          case "part":
            if (!StringUtils.isNullOrEmpty(historyItem.descriptor)) {
              return this.fileAddedOnFieldTextReplace(historyItem);
            } else {
              this.app.text.history.attachemnetAdded;
            }
          default:
            return this.app.text.history.attachemnetAdded;
        }
      case "tag":
        return this.tagAddedTextReplace(historyItem);
      default:
        return "";
    }
  }

  displayEditMessage(historyItem: HistoryDoc) {
    switch (historyItem.docType) {
      case "thread":
        switch (historyItem.docSubType) {
          case "impact_signed":
            return this.impactSignedTextReplace(historyItem);
          default:
            return this.app.text.history.threadEdited;
        }
      // return this.app.text.history.threadEdited;
      case "post":
        let defaultEditPostMessage =
          this.app.history.displayEditDefaultMessage(historyItem);
        switch (historyItem.docSubType) {
          case COMMENT:
            return this.app.text.history.commentEdited;
          case SOLUTION:
            if (!defaultEditPostMessage) {
              if (!historyItem.data) {
                return "";
              }
              if (historyItem.data.operations[0].newValue === true) {
                return this.solutionFinalTexReplace(historyItem.descriptor);
              } else {
                return this.solutionNotFinalTexReplace(historyItem.descriptor);
              }
            } else {
              return this.app.text.history.solutionEdited;
            }
          case TASK:
            if (!defaultEditPostMessage) {
              if (this.taskCompleted(historyItem)) {
                if (historyItem.descriptor != "") {
                  return this.taskCompletedTextReplace(historyItem);
                } else {
                  return this.app.text.history.taskCompletedWithoutNote;
                }
              } else {
                return this.app.text.history.taskUncompleted;
              }
            } else {
              return this.app.text.history.taskEdited;
            }
        }
      case "impact":
        return this.app.text.history.impactEdited;
      case "internalItem":
        return this.app.text.history.internalItemEdited;
      case "part":
        return this.app.text.history.partEdited;
      case "manufacturer":
        return this.app.text.history.manufacturerEdited;
      case "message":
        return this.app.text.history.messageEdited;
      default:
        return "";
    }
  }

  private taskCompleted(historyItem: HistoryDoc) {
    let taskCompleted = false;
    if (historyItem.docSubType === TASK) {
      if (historyItem.data == null) {
        return;
      }
      historyItem.data.operations.forEach((operation) => {
        if (operation.property === this.TASK_COMPLETED) {
          if (operation.newValue === true) {
            taskCompleted = true;
          }
        }
      });
    }
    return taskCompleted;
  }

  deleteDocText(historyItem: HistoryDoc) {
    if (historyItem.docSubType != "") {
      switch (historyItem.docSubType) {
        case "train":
          return this.trainDeletdTextReplace(historyItem.descriptor);
        case COMMENT:
          return this.app.text.history.commentDeleted;
        case SOLUTION:
          return this.solutionDeletedTextReplace(historyItem.descriptor);
        case TASK:
          return this.app.text.history.taskDeleted;
        case "thread_photo":
          return this.app.text.history.threadImageDeleted;
        case "svhc_item":
          return this.app.text.history.svhcItemDeleted;
        case "parts":
          return this.partDeletedTextReplace(historyItem.descriptor);
        // return this.app.text.history.partDeleted;
        case "manufacturer":
          if (historyItem.docType === "file") {
            return this.app.text.history.attachemnetDeleted;
          }
          return this.manufacturerRemovedTextReplace(historyItem.descriptor);
        case "part":
          if (
            historyItem.docType === "file" &&
            !StringUtils.isNullOrEmpty(historyItem.descriptor)
          ) {
            return this.fileDeletedFromFieldTextReplace(historyItem);
          } else {
            return this.app.text.history.attachemnetDeleted;
          }
        default:
          return "";
      }
    } else {
      switch (historyItem.docType) {
        case "impact":
          return this.impactDeletedTextReplace(historyItem.descriptor);
        case "internalItem":
          return this.app.text.history.internalItemDeleted;
        case "message":
          return this.app.text.history.messageDeleted;
        case "file":
          return this.app.text.history.attachemnetDeleted;
        case "tag":
          return this.tagDeletedTextReplace(historyItem);
        default:
          return "";
      }
    }
  }

  getDateformat(date: any): string {
    return formatDatetime(date);
  }

  getFieldName(docType: string, property: string) {
    let id = property.substring(1);
    if (docType === "impact" && id.includes("responsibles")) {
      // used for DB - responsbiles on the impacts docs, //ex: docType: impact ,property: /responsibles/bavFv
      return this.app.field.getLabel("responsibles." + id.split("/")[1]);
    }
    if (docType === "part" && id.includes("parts")) {
      return this.app.field.getLabel("part.parts");
    }
    return this.app.field.getLabel(docType + "." + id);
  }

  private getFieldType(docType: string, property: string) {
    let field = docType + "." + property.split("/")[1];
    return this.app.field.getType(field);
  }

  getText(docType: string, property: string, value: any) {
    let fieldId = "";
    let fieldType = "";

    if (docType === "impact" && property.includes("responsibles")) {
      //used for Db - responsbiles on the impacts docs, //ex: docType: impact ,property: /responsibles/bavFv
      fieldId = "responsibles." + property.split("/")[2];
      fieldType = this.getFieldType("responsibles", property.split("/")[2]);
    } else {
      fieldId = docType + "." + property.split("/")[1];
      fieldType = this.getFieldType(docType, property);
    }

    let isFieldMultiple = this.app.field.isMultiple(fieldId);

    switch (fieldType) {
      case "options":
      case "radio":
        if (isFieldMultiple) {
          //prevent error for old records when oldValue was saved as "" even if it was a multiple filed
          if (value === "" || value == null) {
            value = [];
          }
          return value
            .map((value: string) =>
              this.app.field.getOptionText(fieldId, value)
            )
            .sort()
            .join(", ");
        }
        return this.app.field.getOptionText(fieldId, value);
      case "checkbox":
        return this.app.field.getCheckboxLabelText(fieldId, value);
      default:
        //if the saved data is an array concat the values with "," and empty space
        if (Array.isArray(value)) {
          return value.join(", ");
        }
        return value;
    }
  }

  //TEXT CHANGE

  //FILE ON A CERTAION FIELD
  fileAddedOnFieldTextReplace(historyItem: HistoryDoc): string {
    return this.app.text.history.attachemnetAddedOnField.replace(
      "$",
      this.app.field.getLabel(
        historyItem.docSubType + "." + historyItem.descriptor
      )
    );
  }

  fileDeletedFromFieldTextReplace(historyItem: HistoryDoc): string {
    return this.app.text.history.attachemnetDeletedFromField.replace(
      "$",
      this.app.field.getLabel(
        historyItem.docSubType + "." + historyItem.descriptor
      )
    );
  }

  //SOLUTION
  solutionAddedTextReplace(solutionName: string): string {
    return this.app.text.history.solutionCreated.replace(
      "$",
      this.app.field.getFieldValueAsText(
        this.app.fieldId.post.resolveClass,
        solutionName
      )
    );
  }

  solutionDeletedTextReplace(solutionName: string): string {
    return this.app.text.history.solutionDeleted.replace(
      "$",
      this.app.field.getFieldValueAsText(
        this.app.fieldId.post.resolveClass,
        solutionName
      )
    );
  }

  solutionFinalTexReplace(solutionName: string) {
    return this.app.text.history.solutionFinalField.replace(
      "$",
      this.app.field.getFieldValueAsText(
        this.app.fieldId.post.resolveClass,
        solutionName
      )
    );
  }

  solutionNotFinalTexReplace(solutionName: string) {
    return this.app.text.history.solutionNotFinal.replace(
      "$",
      this.app.field.getFieldValueAsText(
        this.app.fieldId.post.resolveClass,
        solutionName
      )
    );
  }

  //TASK
  taskCompletedTextReplace(historyItem: any) {
    return this.app.text.history.taskCompletedWithNote.replace(
      "$",
      historyItem.descriptor
    );
  }

  //INTERNAL ITEM
  internalItemAddedTextReplace(internalItemName: string): string {
    return this.app.text.history.internalItemCreated.replace(
      "$",
      internalItemName
    );
  }

  //IMPACT
  impactAddedTextReplace(impactName: string) {
    if (StringUtils.isNullOrEmpty(impactName)) {
      return this.app.text.history.impactCreated.replace("$", "");
    } else {
      return this.app.text.history.impactCreated.replace("$", impactName);
    }
  }
  impactDeletedTextReplace(impactName: string) {
    return this.app.text.history.impactDeleted.replace("$", impactName);
  }

  //TRAIN
  trainAddedTextReplace(trainInfo: string): string {
    return this.app.text.history.trainCreated.replace("$", trainInfo);
  }

  trainDeletdTextReplace(trainInfo: string): string {
    return this.app.text.history.trainDeleted.replace("$", trainInfo);
  }

  //TAG
  tagAddedTextReplace(historyItem: any): string {
    return this.app.text.history.tagAdded
      .replace("$", historyItem.descriptor.split(",")[0])
      .replace("&", historyItem.descriptor.split(",")[1]);
  }

  tagDeletedTextReplace(historyItem: any): string {
    return this.app.text.history.tagDeleted
      .replace("$", historyItem.descriptor.split(",")[0])
      .replace("&", historyItem.descriptor.split(",")[1]);
  }

  //MANUFACTURER
  manufacturerAddedTextReplace(name: string): string {
    return this.app.text.history.manufacturerAdded.replace("$", name);
  }

  manufacturerRemovedTextReplace(name: string): string {
    return this.app.text.history.manufacturerRemoved.replace("$", name);
  }

  //PART
  partDeletedTextReplace(item: string) {
    return this.app.text.history.partDeleted.replace("$", item);
  }

  //IMPACT SIGNED
  // ImpactSignedTextReplace is needed for the history records created before march 2024
  impactSignedTextReplace(historyItem: HistoryDoc): string {
    let newStatus = "";
    let oldStatus = "";
    let responsibleName = historyItem.user;
    if (historyItem.data == null) {
      return "";
    }
    historyItem.data.operations.forEach((item) => {
      switch (item.property) {
        case "/omfStatus":
          newStatus = item.newValue;
          oldStatus = item.oldValue;
          break;
        case "/responsibleCount/signed":
          const newValue = item.newValue;
          const oldValue = item.oldValue;
          if (newValue.length > 0) {
            responsibleName = newValue.find(
              (x: string) => !oldValue.includes(x)
            );
          }
          break;
      }
    });
    if (historyItem.user === responsibleName) {
      if (newStatus !== "" && oldStatus !== "") {
        //A responsibles has signed for the impacts he was assigned for and status was also changed
        return this.app.text.history.caseSignedAndStatusChanged
          .replace("$", oldStatus)
          .replace("&", newStatus);
      } else {
        //A responsibles has signed for the impacts he was assigned for
        return this.app.text.history.caseSigned;
      }
    } else {
      if (newStatus !== "" && oldStatus !== "") {
        //A team has signed the impact for a responsible and status was also changed
        return this.app.text.history.impactSignedAndStatusChanged
          .replace("$", responsibleName)
          .replace("&", oldStatus)
          .replace("#", newStatus);
      } else {
        //A team has signed the impact for a responsible
        return this.app.text.history.impactSigned.replace("$", responsibleName);
      }
    }
  }
}
