import { ComponentSettings } from "../settings/settings";

const settings = {
  permission: {},
  url: {},
  list: {},
  text: {
    title: {
      de: "Formular für Produktänderungs- und Produktabkündigungsmitteilungen [(PCN) und (PDN)]",
      en: "Form for product change notices and product discontinuance notices [(PCN) and (PDN)]",
      nl: "PDN en PCN meldingen",
    },
    VDMA: {
      de: "PDF VDMA 24903",
      en: "PDF VDMA 24903",
      nl: "PDF VDMA 24903",
    },
    pdfAllData: {
      de: "PDF alle Daten",
      en: "PDF all data",
      nl: "PDF alle data",
    },
    coreDate: {
      de: "Stammdaten des Herstellers",
      en: "Master data of the manufacturer",
      nl: "Stamdata Fabrikant",
    },
    pcnId: {
      de: "Identifikationsschlüssel (ID)\nder PCN / PDN:",
      en: "Identification key (ID)\nof the PCN / PDN:",
      nl: "Identificatie (ID)\nvan de PCN / PDN:",
    },
    pcnType: {
      de: "Art der Mitteilung",
      en: "Type of notice",
      nl: "Type melding",
    },
    issueDate: {
      de: "Datum der Veröffentlichung",
      en: "Date of publication",
      nl: "Publicatiedatum",
    },
    pcnRevision: {
      de: "Revisionsnummer",
      en: "Revision number",
      nl: "Revisie nummer",
    },
    mfrName: {
      de: "Firmenbezeichnung:",
      en: "Company name:",
      nl: "Bedrijfsnaam volledig:",
    },
    mfrShortName: {
      de: "Gängige Firmenkurzbezeichnung:",
      en: "Commonly used trade name\n of the company:",
      nl: "Bedrijfsnaam verkort:",
    },
    email: {
      de: "E-Mail:",
      en: "E-mail address:",
      nl: "E-mail:",
    },
    street: {
      de: "Strasse:",
      en: "Street:",
      nl: "Adres:",
    },
    zipCode: {
      de: "Postleitzahl:",
      en: "Postal code:",
      nl: "Postcode:",
    },
    city: {
      de: "Stadt:",
      en: "City:",
      nl: "Stad:",
    },
    state: {
      de: "Bundesland:",
      en: "State:",
      nl: "Provincie:",
    },
    country: {
      de: "Ländercode nach ISO 3166-1:",
      en: "Country Code in accordance\n with ISO 3166-1:",
      nl: "Landcode volgens ISO 3166-1:",
    },
    affectedItems: {
      de: "Betroffene Einheiten des Herstellers",
      en: "Manufacturer's Affected Items",
      nl: "MPN nummers in deze melding",
    },
    manufacturerItemNumber: {
      de: "Herstellerteilenummer",
      en: "Manufacturer part number",
      nl: "Fabrikant Artikel Nummer",
    },
    typeIdent: {
      de: "Typenbez. oder Zeichnung",
      en: "Type designation or \ndrawing",
      nl: "Tekening nummer",
    },
    itemName: {
      de: "Bezeichnung (Name/Kurztext)",
      en: "Designation (Name/short text) ",
      nl: "Omschrijving (kort)",
    },
    itemProductCategory: {
      de: "Kategorie der Einheit",
      en: "Category of the Item",
      nl: "Categorie van het item",
    },
    itemRev: {
      de: "Revisionsstand",
      en: "Revision status",
      nl: "Revisie status",
    },
    type: {
      de: "Kategorie der Art der PCN/PDN",
      en: "Category of the type of PCN/PDN",
      nl: "Categorie type PCN/PDN",
    },
    replmanfItemnumber: {
      de: "Vorgeschlagene Ersatz-Herstellerteilenummer ",
      en: "Manufacturer part number of the proposed \nreplacement",
      nl: "Fabrikant Artikel Nummer van de \nvoorgestelde vervanger",
    },
    itemSubData: {
      de: "Weitere identifizierende Merkmale ",
      en: "Other identififying characteristics ",
      nl: "Overige karakteristieken ",
    },
    changeIdentificationMethod: {
      de: "Identifikation der geänderten Einheit(en)/des Produkts ",
      en: "Identification of the changed item(s)/product ",
      nl: "Identificatie van het gewijzigde item ",
    },
    alertImpact: {
      de: "Technische Beschreibung:",
      en: "Technical Description:",
      nl: "Technische omschrijving:",
    },
    description: {
      de: "Beschreibung durch den Hersteller",
      en: "Description given by the manufacturer",
      nl: "Omschrijving door de Fabrikant",
    },
    ifApplicable: {
      de: "(wenn anwendbar)",
      en: "(if applicable)",
      nl: "(indien aanwezig)",
    },
    omfStatus: {
      de: "Status",
      en: "Status",
      nl: "Status",
    },
    artNumber: {
      de: "Kundenteilenummer (KTN)",
      en: "Customer Part Number (CPN)",
      nl: "Artikel Nummer (CPN)",
    },
    omfNumber: {
      de: "Fall-Nummer",
      en: "Case Number",
      nl: "Issue nummer",
    },
    comments: {
      de: "Kommentare",
      en: "Comments",
      nl: "Commentaar",
    },
    solutions: {
      de: "Lösungen",
      en: "Solutions",
      nl: "Oplossingen",
    },
    tasks: {
      de: "Aufgaben",
      en: "Tasks",
      nl: "Taken",
    },
    attachments: {
      de: "Anhänge, die zu diesem Fall hinzugefügt wurden",
      en: "Attachments added to this case",
      nl: "Bijlagen toegevoegd aan dit Issue",
    },
    tags: {
      de: "Tags",
      en: "Tags",
      nl: "Tags",
    },
    signatureA: {
      de: "Unterschrift Abteilungsleiter",
      en: "Signature Head of Department",
      nl: "Handtekening inhoudsverantwoordelijke",
    },
    signatureB: {
      de: "Unterschrift Bereichsleiter mit aufnehmen (gem. Freigabeprozedere)",
      en: "Signature Divisional Director",
      nl: "Handtekening vrijgave",
    },
    sectionSignature: {
      de: "Unterschriften",
      en: "Signatures",
      nl: "Handtekeningen",
    },
    startOfProduction: {
      de: "Beginn der Produktion",
      en: "Start of Production",
      nl: "Start of Production",
    },
    endOfProduction: {
      de: "End Of Production",
      en: "End Of Production",
      nl: "End Of Production",
    },
    effectiveDate: {
      de: "Effective Date",
      en: "Effective Date",
      nl: "Ingangsdatum",
    },
    endOfSale: {
      de: "End Of Sale",
      en: "End Of Sale",
      nl: "End Of Sale",
    },
    lastTimeDelivery: {
      de: "Last Time Delivery",
      en: "Last Time Delivery",
      nl: "Last Time Delivery",
    },
    endOfService: {
      de: "End Of Service & Repair",
      en: "End Of Service & Repair",
      nl: "End Of Service & Repair",
    },
    dinCode: {
      de: "DIN Code (15380-2)",
      en: "DIN Code (15380-2)",
      nl: "DIN Code (15380-2)",
    },
    dinText: {
      de: "DIN Text (15380-2)",
      en: "DIN Text (15380-2)",
      nl: "DIN Tekst (15380-2)",
    },
    creditsOnDay: { de: "am", en: "on", nl: "op" },
  },
  field: {},
};

export const pdf: ComponentSettings<typeof settings> = settings;
