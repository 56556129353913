import { APP_TYPE, AppType } from "../app.type";
import { Customer } from "../../../../shared/types/customers";
import { CustomerName } from "../../../../shared/config/customers";
import { doc2Model } from "../api.service";
import { filterInitialState } from "../filter-list/filter-list.state";
import { GraphName } from "../../../../shared/types/graphName";
import { InboxType, State } from "../state/state";
import { Inject, Injectable } from "@angular/core";
import { Language } from "../../../../shared/settings/settings";
import { loadModel } from "../utils/app.utils";
import { Pcn } from "../../../../shared/models/pcn";
import { RouteData } from "../../../../shared/models/route-data";
import { RouteSegments, RoutingServiceType } from "./routing.service.type";
import { StringUtils } from "../../../../shared/utils/string.utils";
import { Thread } from "../../../../shared/models/thread";
import { Title } from "@angular/platform-browser";
import { View } from "../../../../shared/models/view";
import { Location } from "@angular/common";
import { EncodingUtils } from "../../../../shared/utils/encoding.utils";

// TODO: MOVE THIS TO SHARED!!!
import {
  ActivatedRoute,
  ActivatedRouteSnapshot,
  Router,
} from "@angular/router";
import { HomeMode } from "../home/home.service";
import { User } from "../../../../shared/models/user";

export const NAVIGATION_HISTORY = "app_nav_history";
export const REDIRECT_TO = "app_redirect_to";
export const contactPageUrl = "https://lcm-client.com/#contact";

/** Extract relevant routing information from a snapshot */
function extractData(snapshot: ActivatedRouteSnapshot): RouteData {
  const data: RouteData = { ...snapshot.params, ...snapshot.data };
  return data;
}

/** Extract routing segments from a snapshot */
function extractSegments(snapshot: ActivatedRouteSnapshot): RouteSegments {
  return snapshot.url.map((segment) => segment.path);
}

/** Check if string contains URI encoded values such as umlaut, space, etc. */
function containsEncodedComponents(x: string) {
  return x !== decodeURIComponent(x);
}

@Injectable()
export class RoutingService implements RoutingServiceType {
  SNAPSHOTS: ActivatedRouteSnapshot[] = [];

  get navigationHistory(): RouteSegments[] {
    if (typeof Storage !== "undefined") {
      const sessionHistory = sessionStorage.getItem(NAVIGATION_HISTORY);

      if (sessionHistory !== null) {
        return JSON.parse(EncodingUtils.decodeBase64(sessionHistory));
      }
    }

    return [];
  }

  set navigationHistory(history: RouteSegments[]) {
    if (typeof Storage !== "undefined") {
      const sessionHistory = sessionStorage.getItem(NAVIGATION_HISTORY);

      if (sessionHistory !== null) {
        sessionStorage.removeItem(sessionHistory);
      }

      sessionStorage.setItem(
        NAVIGATION_HISTORY,
        EncodingUtils.encodeBase64(JSON.stringify(history))
      );
    }
  }

  constructor(
    @Inject(APP_TYPE) private app: AppType,
    private router: Router,
    private titleService: Title,
    private location: Location
  ) {}

  async navigate(...segments: RouteSegments) {
    //(used in se filter comp)remove session storage item in order to not display the old search result if the user comes from a different page (not from thread-new page)
    if (
      segments[segments.length - 1] !== "thread-new" &&
      segments[segments.length - 1] !== "external-data-filter"
    ) {
      if (sessionStorage.getItem("se_filter_cpn") != null) {
        sessionStorage.removeItem("se_filter_cpn");
      }
    }
    await this.router.navigate(segments);
  }

  async navigateBack() {
    // console.log("NAVIGATE BACK", index);
    let index = this.navigationHistory.length;

    // After a refresh, navigate to Home
    if (index === 1) {
      if (this.app.state.isAnalyst || this.app.state.isRMAdmin) {
        await this.navigateRM();
        return;
      }

      await this.navigateHome();
      return;
    }

    // Get the current route from the URL
    const currentRoute = this.extractSegmentsFromUrl(this.router.url);

    // Find the last different route
    while (index > 0) {
      if (
        JSON.stringify(currentRoute) !==
        JSON.stringify(this.navigationHistory[--index])
      ) {
        break;
      }
    }

    let previousRoute = this.navigationHistory[index];

    //if the currentRoute is to the not found page redirect the user with 2 pages back
    if (currentRoute[currentRoute.length - 1] === "not-found") {
      previousRoute = this.navigationHistory[this.navigationHistory.length - 3];

      if (this.navigationHistory.length < 1) {
        this.navigateHome();
      }
    }

    this.navigationHistory = this.navigationHistory.slice(0, index);
    this.navigate(...previousRoute);
  }

  async decodeUrlSegments(url: string, route: ActivatedRoute): Promise<void> {
    const segments = url.split("/");

    (route.snapshot.data as any).view = segments[2];

    if (segments[3] !== undefined && containsEncodedComponents(segments[3])) {
      segments[3] = decodeURIComponent(segments[3]);
    }

    switch (segments[2]) {
      case "thread":
        (route.snapshot.data as any).threadId = segments[3];
        (route.snapshot.data as any).newThread = false;
        break;
      case "thread-new":
        (route.snapshot.data as any).view = "thread";
        (route.snapshot.data as any).newThread = true;
        break;
      case "part":
        (route.snapshot.data as any).part = segments[3];
        break;
      case "manufacturer":
        (route.snapshot.data as any).manufacturer = segments[3];
        break;
      case "user-new":
        (route.snapshot.data as any).view = "user";
        (route.snapshot.data as any).newUser = true;
        break;
      case "user":
      case "profile":
        (route.snapshot.data as any).user = segments[3];
        (route.snapshot.data as any).newUser = false;
        break;
      case "impact":
        (route.snapshot.data as any).impact = segments[3];
        break;
      case "impact-new":
        (route.snapshot.data as any).view = "impact";
        (route.snapshot.data as any).newImpact = true;
        break;
      case "vehicleResponsibles":
        (route.snapshot.data as any).view = "vehicleResponsible";
        break;
      case "vehicleResponsible":
        (route.snapshot.data as any).view = "vehicleResponsible";
        if (segments[3] === "vehicleResponsible-new") {
          (route.snapshot.data as any).view = "vehicleResponsible";
          (route.snapshot.data as any).newVehicleResponsible = true;
        } else {
          (route.snapshot.data as any).newVehicleResponsible = false;
        }
        break;
      case "train":
        (route.snapshot.data as any).view = "train";
        break;
      case "mail":
        if (segments[3] === "thread") {
          (route.snapshot.data as any).pcnId = segments[4];
        }
        break;
      case "rm":
        (route.snapshot.data as any).view = "RM";
        if (segments[3] === "search") {
          (route.snapshot.data as any).view = "RMSearch";
        }
        break;
    }

    await this.setRouteSnapshot(route.snapshot);
  }

  async setRouteSnapshot(snapshot: ActivatedRouteSnapshot) {
    // Extract segments and push them into the navigation history
    const segments = this.extractSegmentsFromUrl(this.router.url);
    this.insertSegments(segments);

    // Extract Route Data for loading appropriate items from buffer
    const data = extractData(snapshot);

    // Remove view-refresh if the view is thread - only used for 'thread-new'!!!
    if (data.view !== "thread") {
      if (sessionStorage.getItem("viewRefresh") != null) {
        sessionStorage.removeItem("viewRefresh");
      }
    }

    this.SNAPSHOTS.push(snapshot);

    await this.setRouteData(data);
  }

  async setRouteData(data: RouteData, load = true) {
    this.app.lock();
    const newCustomer = data.customer;
    const currentCustomer = this.app.customers.current;

    const { view, threadId, user, impact, part, manufacturer, pcnId } = data;

    let customer: CustomerName | undefined = newCustomer;

    // Prevent navigation to a customer that the user doesn't have permission to view
    if (
      view !== "pcn" &&
      currentCustomer != null &&
      customer != null &&
      !this.app.customers.customers.includes(customer)
    ) {
      await this.navigateCustomer(currentCustomer);
      return;
    }

    if (customer == null) {
      customer = currentCustomer;
    }

    if (load && view) {
      if (view !== "pcn") {
        try {
          await this.app.customers.update(newCustomer);
        } catch (err) {
          // user session might have timed out or connection was lost for some other reason
          // -> the login page should appear
          this.app.state.next({ user: null });
        }
      } else {
        await this.app.customers.update(newCustomer, true);
      }
    }

    // Get current user profile
    this.app.profile.ownProfile = await this.app.profile.getProfile();

    if (this.app.user != null) {
      this.app.users.currentUser = await this.app.users.getUserById(
        this.app.user
      );
    }

    // Trigger update of unreadCount to get the number in the Header
    this.app.mail.updateUnreadCount.next(true);

    if (this.app.permission.post.hasTasks) {
      this.app.tasks.uncompletedTasks();
    }

    // Reset loaded image counter to 0
    this.app.file.loadedImageCount = 0;
    this.app.file.loadedImageSubject.next(this.app.file.loadedImageCount);

    const next: Partial<State> = { ...filterInitialState, error: false };

    this.app.filterHeaderTable.filterValues = {};
    switch (view) {
      case "home":
        const sortDirection = this.app.table.sortDirection;
        const sortField = this.app.state.sortField;
        this.app.manufacturer.correspondingMpns = [];
        this.app.manufacturer.correspondingCpns = [];
        next.sortField =
          sortField != null ? sortField : this.app.fieldId.thread.update_time;
        next.sortDirection = sortDirection != null ? sortDirection : -1;
        next.omfNumber = null;
        next.viewUser = null;
        next.model = {};
        break;
      case "thread":
        if (data.newThread) {
          this.app.thread.isInCreateMode = true;
          let id = "OMF";
          const newThread = new Thread();
          const model: any = doc2Model("thread", newThread);
          this.app.thread.thread = model;
          const omfNumber = await this.app.thread.generateNewOmfNumber(
            this.app.customers.expectCurrent
          );

          id = omfNumber;
          this.app.thread.id = omfNumber;
          this.app.thread.thread["thread.omfNumber"] = id;
          this.app.thread.thread["thread.omfStatus"] =
            this.app.thread.setStatusBasedOnCustomer();

          this.app.thread.thread["thread.caseSource"] = "intern";
          this.app.thread.thread["thread.buyer"] =
            this.app.thread.setBuyerBasedOnCustomer();

          //used for all pcnDocuments and all other new cases, besides syncronization & duplicate of a case
          if (
            this.app.sync.syncInfo.syncedFromCustomer == null &&
            this.app.thread.isCaseDuplicated === false
          ) {
            this.app.thread.thread["thread.dinCode"] = "AA00";
            this.app.thread.thread["thread.dinText"] = "AA00";
          }

          if (this.app.thread.isCaseDuplicated) {
            await this.app.thread.copyDocToThread(this.app.thread.cleanModel);
            this.app.thread.isCaseDuplicated = false;
          }

          if (this.app.sync.syncInfo.syncedFromCustomer != null) {
            await this.app.thread.copyDocToThread(
              this.app.sync.syncInfo.syncedDoc,
              this.app.sync.syncInfo.syncedFromCustomer
            );
          }

          if (this.app.pcn.pcn != null && this.app.pcn.id != null) {
            await this.app.thread.copyDocToThread(this.app.pcn.pcn);
            this.app.thread.thread["thread.caseSource"] = "extern";
            // if model from state would not be set several fields would be deleted (eg: title)
            this.app.state.next({ model: this.app.thread.thread });
          }

          if (this.app.customer === Customer.NS) {
            this.app.thread.thread["thread.reporterName"] = this.app.state.user;
            this.app.thread.thread["thread.projectStatus"] = true;
            //used to get all the cases by cpn and display a message if the choosen cpn/mpn is used in any other thread
            this.app.thread.getCasesByPartNumbers();
          }

          if (this.app.customer === Customer.DB) {
            this.app.thread.thread["thread.priority"] = "medium";
            this.app.thread.thread["thread.dueDate"] =
              this.app.thread.setDefaultDueDate("medium");
          }

          next.omfNumber = id;
          next.model = this.app.thread.thread;
          next.unlockedId = id;

          if (
            this.app.customer === Customer.COOP ||
            this.app.customer === Customer.KOMAX
          ) {
            next.selectedBox = this.app.listId.thread.main;
          } else {
            next.selectedBox = this.app.list.thread.boxesLeftCreateCase[0];
          }

          //set boxes left
          this.app.thread.setNewBoxesLeft();
        } else if (threadId != null) {
          const selectedBox: string =
            this.app.leftNav.selectedBox != null
              ? this.app.leftNav.selectedBox
              : "";
          this.app.leftNav.selectedBox =
            this.app.list.thread.boxesLeft.indexOf(selectedBox) === -1
              ? "thread.main"
              : this.app.leftNav.selectedBox;

          next.omfNumber = threadId;
          next.model = this.app.thread.thread;
        }
        break;
      case "user":
        if (data.newUser) {
          const id = this.app.createId();
          next.unlockedId = id;
          this.app.users.currentId = id;
          this.app.users.isNew = true;
          const newUser = new User();
          this.app.users.currentUserModel = doc2Model("user", newUser) as User;
          this.app.users.setPasswordExpireDate();
        } else if (user != null) {
          this.app.users.isNew = false;
          next.viewUser = user;
        }
        break;
      case "profile":
        if (user !== null) {
          next.view = "profile";
          next.profileUser = user;
        }
        break;
      case "vehicleResponsible":
        if (data.newVehicleResponsible) {
          const id = this.app.createId();
          this.app.vehicleResponsible.id = id;
          next.unlockedId = id;
        }
        break;
      case "impact":
        if (data.newImpact) {
          const id = this.app.impact.create();
          next.model = {};
          next.unlockedId = id;
          next.impactId = id;
        } else if (impact != null) {
          next.impactId = impact;
          const model = loadModel(
            "impact",
            this.app.docs.getDoc(impact, "impact"),
            this.app.model
          );
          next.model = model;
          next.omfNumber = impact.split("/")[0];
        }
        break;
      case "graphs":
        const { graph } = data;
        if (graph != null) {
          next.graphName = graph;
        }
        break;
      case "mail":
        next.sortField = this.app.fieldId.change.change_time;
        next.sortDirection = -1;
        next.pcnId = pcnId;
        break;
      case "part":
        if (part != null) {
          //partNumber should be decoded since it could contain ascii characters for the '/' sign
          const partNumber = decodeURIComponent(part);
          next.partNumber = partNumber;
        }
        break;
      case "manufacturer":
        if (manufacturer != null) {
          next.manufacturerId = manufacturer;
        }
        break;
      case "pcn":
        const newPcn = new Pcn();
        const model: any = doc2Model("pcn", newPcn);
        next.model = model;
      case "reset-password":
        next.view = view;
        break;
      default:
      // NOP
    }
    if (next.view == null && view !== "profile") {
      next.view = view;
    }
    if (next.customer == null) {
      next.customer = customer;
    }
    if (next.profileUser == null) {
      next.profileUser = null;
    }
    if (next.impactId == null) {
      next.impactId = null;
    }
    if (next.viewUser == null) {
      next.viewUser = null;
    }
    if (this.app.thread.isNew) {
    }
    if (
      next.language == null &&
      this.app.users.currentUser != null &&
      !StringUtils.isNullOrEmpty(this.app.users.currentUser.language) &&
      customer !== Customer.OC
    ) {
      next.language = this.app.users.currentUser.language as Language;
    } else {
      next.language = "en";
    }
    if (next.unlockedId === undefined) {
      // close currently editable item before loading different view
      next.unlockedId = null;
    }
    if (data.landing === true) {
      const { customer } = data;
      if (customer != null) {
        next.landing = data.customer;
      }
    }

    this.app.state.next(next);
    this.app.unlock();
  }

  get customer() {
    return this.app.customers.expectCurrent;
  }

  async navigateOCHome() {
    if (this.app.customer !== "oc") {
      return;
    }
    await this.navigate("oc", "oc-home");
  }

  async navigateOCPost(postId?: any, fromAlert?: boolean) {
    if (this.app.customer !== "oc") {
      return;
    }
    if (fromAlert) {
      await this.navigate("oc", "oc-home", postId);
    }
    const newUrl = `/oc/oc-home/${postId}`;
    this.location.go(newUrl);
  }

  async navigatePcnGenerator() {
    await this.navigate(this.customer, "pcn");
  }

  async navigateHome() {
    this.app.state.next({ selectedBox: null });
    if (this.app.customer == null) {
      return;
    }
    await this.navigate(this.app.customer, "home");
  }

  async navigateLogin() {
    await this.navigate("/");
  }

  async navigateResetPassword() {
    await this.navigate("reset-password");
  }

  async navigateThread(threadId: string, showHistory = false) {
    // this.app.state.next({selectedBox: 'thread.main'})
    await this.navigate(
      this.customer,
      showHistory ? "thread-history" : "thread",
      threadId
    );
  }

  async navigateNewThread(syncCustomer: string) {
    const customer = syncCustomer != null ? syncCustomer : this.customer;
    // this.app.state.next({selectedBox: null});
    await this.navigate(customer, "thread-new");
  }

  async navigateUsers() {
    this.app.state.next({ selectedBox: null });
    await this.navigate(this.customer, "users");
  }

  async navigateUserProfiles() {
    this.app.state.next({ selectedBox: null });
    await this.navigate(this.customer, "user-profile-list");
  }

  async navigateUser(user: string) {
    await this.navigate(this.customer, "user", user);
  }

  async navigateNewUser() {
    await this.navigate(this.customer, "user-new");
  }

  async navigateMail(mode: InboxType) {
    this.app.state.next({ selectedBox: null, inboxType: "change" });
    // if the logged in user has analyst role redirect to alerts notifications
    if (this.app.state.isAnalyst || this.app.state.isRMAdmin) {
      mode = mode.toLowerCase().includes("alert") ? mode : "alert";
    }
    this.app.mail.tab = mode;
    if (mode === "changeAlert") {
      await this.navigate(this.customer, "mail", "change-alert");
    }
    if (mode === "leadTimeAlert") {
      await this.navigate(this.customer, "mail", "lead-alert");
    }
    if (mode === "inventoryMonitoringAlert") {
      await this.navigate(this.customer, "mail", "inventory-monitoring");
    }
    if (
      mode !== "changeAlert" &&
      mode !== "leadTimeAlert" &&
      mode !== "inventoryMonitoringAlert"
    ) {
      await this.navigate(this.customer, "mail", mode.toString());
    }
  }

  async navigateProfile(user: string) {
    this.app.state.next({ selectedBox: null });
    if (this.app.panel.isOpen) {
      this.app.panel.navigateProfileFromPanel(user);
    } else {
      await this.navigate(this.customer, "profile", user);
    }
  }

  async navigateVehicleResponsibles() {
    await this.navigate(this.customer, "vehicleResponsibles");
  }

  async navigateNewVehicleResponsible() {
    await this.navigate(
      this.customer,
      "vehicleResponsible",
      "vehicleResponsible-new"
    );
  }

  async navigateVehicleResponsible(vehicleResponsible: string) {
    await this.navigate(
      this.customer,
      "vehicleResponsible",
      vehicleResponsible
    );
  }

  async navigateTrainsList(threadId: string) {
    await this.navigate(this.customer, "train", threadId, "list");
  }

  async navigateTrain(threadId: string, train: string) {
    await this.navigate(this.customer, "train", threadId, train);
  }

  async navigateNewTrain(threadId: string) {
    await this.navigate(this.customer, "train", threadId, "new");
  }

  async navigatePcn(pcnId: string) {
    await this.navigate(this.customer, "mail", "thread", pcnId);
  }

  async navigateImpact(threadId: string, impact: string) {
    if (this.customer === Customer.DB) {
      await this.navigate(
        this.customer,
        "thread",
        threadId,
        "impact-wf",
        impact
      );
    } else {
      await this.navigate(this.customer, "thread", threadId, "impact", impact);
    }
  }

  async navigateNewImpact(threadId: string) {
    await this.navigate(this.customer, "thread", threadId, "impact-new");
  }

  async navigateSettings() {
    this.app.state.next({ selectedBox: null });
    await this.navigate(this.customer, "settings");
  }

  async navigateGraphs(name: GraphName) {
    this.app.state.next({ selectedBox: null });
    this.app.graphs.name = name;
    await this.navigate(this.customer, "graphs", name);
  }
  async navigateExternalDataFilter() {
    this.app.state.next({ selectedBox: null });
    await this.navigate(this.customer, "external-data-filter");
  }

  async navigateDebug() {
    this.app.state.next({ selectedBox: null });
    await this.navigate(this.customer, "debug");
  }

  async navigateImport() {
    this.app.state.next({ selectedBox: null });
    await this.navigate(this.customer, "import");
  }

  async navigateProfilePassword() {
    await this.navigate(this.customer, "profile-password");
  }

  async navigateCustomer(customer: CustomerName) {
    if (this.app.state.isAnalyst || this.app.state.isRMAdmin) {
      return;
    }
    this.app.state.next({
      ...filterInitialState,
      numberOfUnreadAlerts: undefined,
      filters: {},
      filterByPage: [],
      selectedBox: null,
    });
    this.app.filterFields.quickFilterSelected = "showAllCases";

    await this.app.customers.update(customer);

    // if the customer is oc (Obsolescence Community) navigation need to done to oc-home
    if (customer != Customer.OC) {
      await this.navigate(customer, "home");
    } else {
      await this.navigateOCHome();
    }
    // Reset the homeMode according to each client
    this.app.home.homeMode = this.app.list.app.homeMode[0] as HomeMode;
  }

  async navigatePart(partNumber: string) {
    await this.navigate(this.customer, "part", partNumber);
  }

  async navigateRM() {
    this.app.RM.fromRMSearch = false;
    await this.navigate(this.customer, "rm", "tree");
  }

  async navigateRMSearch() {
    await this.navigate(this.customer, "rm", "search");
  }

  async navigateOperations() {
    await this.navigate(this.customer, "operations");
  }

  async navigateManufacturer(manufacturerId: string) {
    await this.navigate(this.customer, "manufacturer", manufacturerId);
  }

  async navigateManufacturerMatching(part: string, manufacturer: string) {
    if (StringUtils.isNullOrEmpty(part)) {
      await this.navigate(this.customer, "matching", manufacturer);
    } else {
      await this.navigate(this.customer, "matching", part, manufacturer);
    }
  }

  async navigateTasks() {
    await this.navigate(this.customer, "tasks");
  }

  async navigateNotFound() {
    await this.navigate(this.customer, "not-found");
  }

  async navigateContactPage() {
    window.location.href = contactPageUrl;
  }

  async reloadCurrentPage(defaultCustomer: CustomerName | null) {
    const index = this.SNAPSHOTS.length - 1;
    if (index < 0) {
      return;
    }
    const snapshot = this.SNAPSHOTS[index];
    const data = extractData(snapshot);
    extractSegments(snapshot);

    // load settings
    const customer = data.customer;
    if (customer == null) {
      if (defaultCustomer != null) {
        await this.app.routing.navigateCustomer(defaultCustomer);
      }
      return;
    }

    // load settings
    await this.app.customers.update(customer);

    await this.app.routing.setRouteData(data, false);
  }

  /** compose url for being able to redirect
   * the user
   *  to the cpn/mpn view by mpn/cpn from the case level*/
  getNavigationURL(field: string, value: string) {
    let url: string = "";
    switch (field) {
      case "thread.artNumber":
        //partNumber should be encoded since it could contain '/' sign
        const partNumber = StringUtils.encode(value);
        url = "/" + this.app.customers.expectCurrent + "/part/" + partNumber;
        return url;
      case "thread.crtNumber":
        url =
          "/" +
          this.app.customers.expectCurrent +
          "/manufacturer/" +
          this.app.manufacturer.id;
        return url;
      case "impact.manufacturers":
        if (StringUtils.isNullOrEmpty(value)) {
          return null;
        }
        url = "/" + this.app.customers.expectCurrent + "/manufacturer/" + value;
        return url;
      case "thread.pcnLink":
        return value;

      default:
        return url;
    }
  }

  setTabTitle(title: string) {
    this.titleService.setTitle(title);
  }

  openInNewTab(
    url?: string | null,
    field?: string | null,
    value?: string | null
  ) {
    /** if the url is provided the opening will happen on the exact url*/
    let currentUrl: string | null = url != null ? url : "";

    /** if we cannot decide based on the value to which id to route too
     *  we need first to find out the id of the document
     */

    if (field != null && value != null) {
      currentUrl = this.getNavigationURL(field, value);
    }

    if (currentUrl !== null) {
      window.open(currentUrl, "_blank");
    }
  }

  closeTab() {
    window.close();
  }

  private insertSegments(segments: RouteSegments): void {
    if (segments.some((segment: any) => segment === "home")) {
      this.navigationHistory = [];
    }

    if (JSON.stringify(segments).includes("new")) {
      return;
    }

    if (
      JSON.stringify(
        this.navigationHistory[this.navigationHistory.length - 1]
      ) !== JSON.stringify(segments)
    ) {
      const navigationHistory: RouteSegments[] = this.navigationHistory;
      navigationHistory.push(segments);
      this.navigationHistory = navigationHistory;
    }
  }

  private extractSegmentsFromUrl(url: string): RouteSegments {
    let segments = url.split("/");

    // Remove the first segment as it will be empty
    segments = segments.slice(1);

    let customerNames = Object.values(Customer);

    if (!customerNames.includes(segments[0]) && url.includes("pcn")) {
      this.app.routing.navigateContactPage();
    }

    return segments;
  }
}
