<app-reset-password
  #resetPasswordModal
  [modalMessage]=""
  [modalTitle]=""
></app-reset-password>
<div
  *ngIf="app.error && !app.hasError"
  class="top-bar error-notification"
  style="cursor: pointer"
>
  <div class="container">
    <div class="p-1">
      <i class="fas fa-exclamation-triangle"></i>
      {{ app.text.app.error }}
      <div class="close-btn pull-right" (click)="app.error = false">
        <i class="fa fa-times"></i>
      </div>
    </div>
  </div>
</div>
<div
  *ngIf="app.hasError"
  class="top-bar error-notification"
  style="cursor: pointer"
>
  <div class="container">
    <div class="p-1">
      <i class="fas fa-exclamation-triangle"></i>
      {{ app.errorText }}
      <div class="close-btn pull-right" (click)="app.hasError = false">
        <i class="fa fa-times"></i>
      </div>
    </div>
  </div>
</div>

<!-- SUCCESS -->
<div
  *ngIf="app.hasSuccess"
  class="top-bar success success-notification"
  style="cursor: pointer"
>
  <div class="container">
    <div class="p-1">
      <i class="fa fa-check"></i>
      {{ app.successText }}
      <div class="close-btn pull-right" (click)="app.hasSuccess = false">
        <i class="fa fa-times"></i>
      </div>
    </div>
  </div>
</div>
<div class="app-container offset-md-3 col-md-6">
  <div class="row">
    <div class="col-md-12">
      <div class="text-center p-lg-4">
        <img
          *ngIf="app.auth.landing == null"
          class="p-3 m-3 login-image"
          [src]="app.url.auth.logo"
        />
        <img
          *ngIf="app.auth.landing != null"
          class="p-3 m-2 login-image-omp"
          [src]="app.url.auth.landingLogo"
        />
      </div>
    </div>
  </div>
  <div class="row">
    <div
      class="card-login offset-lg-2 offset-md-1 offset-sm-2 col-lg-8 col-md-12 col-sm-8 mb-5"
    >
      <div class="m-1 p-1">
        <div class="m-3 p-3">
          <h2 class="heading" class="text-center">{{ app.text.auth.login }}</h2>
        </div>
        <div class="m-2 p-2">
          <app-field
            [model]="model"
            field="user.name"
            [noStriped]="true"
            [shadowInput]="true"
          ></app-field>
          <app-field
            [model]="model"
            field="user.password"
            (keydown.enter)="app.auth.loginToHomePage(this.details)"
            [noStriped]="true"
            [shadowInput]="true"
          >
          </app-field>
          <!-- <div class="col-md-12">
            <a class="float-end fg-pass" (click)="openModal()"
              >{{ app.text.resetPassword.forgotPassword }} &nbsp;</a
            >
          </div> -->
          <!-- (click)="app.routing.navigateResetPassword()" -->
          <!-- <app-field [model]="model" field="user.remember"></app-field> -->
        </div>
        <div class="text-center m-2 p-1" *ngIf="app.auth.landing != null">
          <span class="p-1">
            {{ app.text.auth.accept }}
            <a
              class="terms fg-pass"
              target="_blank"
              [href]="app.url.footer.terms"
            >
              {{ app.text.footer.terms }}
            </a>
          </span>
        </div>
        <div class="controls text-center my-4">
          <button
            id="login-button"
            class="btn btn-login text-center"
            (click)="attemptLogin()"
          >
            {{ app.text.auth.loginButton }}
          </button>
        </div>
        <div class="text-center m-1 p-1">
          <a class="fg-pass" (click)="openModal()"
            >{{ app.text.resetPassword.forgotPassword }} &nbsp;</a
          >
        </div>
        <div class="text-center m-1 p-1">
          <a class="fg-pass" [href]="getContactFormURL()" target="_blank">{{
            app.text.auth.help
          }}</a>
        </div>
      </div>
    </div>
  </div>
</div>

<div class="app-footer">
  <app-footer></app-footer>
</div>
