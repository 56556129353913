import { Credits } from "../credits";

export class OCComment extends Credits {
  message: string = "";
  avatar: string = "";
  userFirstName: string = "";
  userLastName: string = "";
  entityId: string = "";
  type?: string = "ocComment";
}
