import { DinCodeGraphData } from "./din-code-graph-data";
import { AbstractGraph } from "../abstract-graph";
import { AppType } from "../../app.type";
import { GraphConfig } from "../graph.type";

export const dinCodesCategories = [
  "M-A",
  "M-B",
  "M-C",
  "M-D",
  "M-E",
  "M-F",
  "M-G",
  "M-H",
  "M-J",
  "M-K",
  "M-L",
  "M-M",
  "M-N",
  "M-P",
  "M-Q",
  "M-R",
  "M-S",
  "M-T",
  "M-U",
];

function getDinCodeClass(dinCode: string): string {
  try {
    return "M-" + dinCode.slice(0, 1);
  } catch (err) {
    throw new Error("invalid din code " + JSON.stringify(dinCode));
  }
}

export class DinCodeGraph extends AbstractGraph<DinCodeGraphData> {
  get config(): GraphConfig {
    return {
      type: "bar-vertical-2d",
      showXAxis: true,
      showYAxis: true,
      showLegend: true,
      xAxisLabel: null,
      yAxisLabel: null,
      colorScheme: {
        domain: ["#fffa00", "#757575"],
      },
      sortBy: "alphabetic",
    };
  }

  constructor(protected app: AppType) {
    super(app);
  }

  get sortOptions() {
    return ["alphabetic", "impacts"];
  }

  protected getSortFunction() {
    switch (this.app.graphs.sortBy) {
      case "impacts":
        return sortByImpacts;
      case "alphabetic":
        return sortAlphabetically;
      default:
        return sortAlphabetically;
    }
  }

  protected async getMap(map: Map<string, DinCodeGraphData>) {
    dinCodesCategories.forEach((dinCode) => {
      map.set(dinCode, new DinCodeGraphData(dinCode, this.app));
    });

    this.app.graphs.allThreads.forEach((thread: any) => {
      if (!thread.dinCode) {
        return;
      }
      const item = map.get(getDinCodeClass(thread.dinCode));
      if (item == null) {
        return;
      }
      item.add(thread);
    });
    map.forEach((item) => {
      item.addVehicles();
    });
  }
}

function sortAlphabetically(a: DinCodeGraphData, b: DinCodeGraphData) {
  return a.dinCode > b.dinCode ? 1 : -1;
}

function sortByImpacts(a: DinCodeGraphData, b: DinCodeGraphData) {
  return b.vehicles - a.vehicles;
}
