import { Inject, Injectable } from "@angular/core";
import { AppType, APP_TYPE } from "../../app.type";
import { OCHomeServiceType } from "./OC-home.service.type";
import { OCPost } from "../../../../../shared/models/obsolescence-community/OC-Post";
import { ocPosts } from "../../../app/api.service";
import { BehaviorSubject } from "rxjs";
import { getFilteredOCPosts } from "../../api.service";
import { StringUtils } from "../../../../../shared/utils/string.utils";

export const TODAY = "Today";
export const YESTERDAY = "Yesterday";

@Injectable()
export class OCHomeService implements OCHomeServiceType {
  postDoc: OCPost = new OCPost();
  posts: OCPost[] = [];
  tags: string[] = [];
  badges: any[] = [];
  isLiked: boolean = false;
  editedId: string = "";
  sortingOrder: string = "descending";
  selectedValue: string = "";
  isFilterSelected: boolean = false;
  selectedType: string = "title";
  searchWords: any = {};

  updatePostsSubject: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(
    false
  );

  constructor(@Inject(APP_TYPE) private app: AppType) {}

  async ocPosts() {
    this.posts = await ocPosts(this.app.customers.expectCurrent);
    this.app.table.sort(this.posts, "ocPost");
    if (this.posts.length > 0) {
      this.app.OCPost.selectedPost = this.posts[0];
    }
  }

  hasEditPermission(selectedItem: any) {
    if (selectedItem == null) {
      return false;
    }
    const { permission, user } = this.app;
    if (permission.ocPost.edit) {
      return true;
    }
    if (permission.ocPost.editOwn) {
      const createUser = selectedItem.user_id;
      if (createUser === user) {
        return true;
      }
    }
    return false;
  }

  createCleanModel(selectedItem: any) {
    if (selectedItem.type === "ocPost") {
      this.app.OCPost.cleanPost = { ...selectedItem };
      return this.app.OCPost.cleanPost;
    } else {
      this.app.OCComment.cleanComment = { ...selectedItem };
      return this.app.OCComment.cleanComment;
    }
  }

  sortPosts(posts: OCPost[], order: string) {
    posts = this.posts;

    let sorted = posts.sort((a: any, b: any) => {
      let postA = this.getUpdateTime(a);
      let postB = this.getUpdateTime(b);
      if (order === "ascending") {
        return postA - postB;
      } else {
        return postB - postA;
      }
    });

    return sorted;
  }

  getUpdateTime(post: OCPost) {
    const { create_time, update_time } = post;
    return Math.max(
      create_time == null ? 0 : create_time,
      update_time == null ? 0 : update_time
    );
  }

  async filterOCPosts(type: string, value: string) {
    this.app.spinner.showSpinner();
    this.posts = await getFilteredOCPosts(
      this.app.customers.expectCurrent,
      type,
      value
    );
    this.app.table.sort(this.posts, "ocPost");
    this.app.OCPost.selectedPost = this.posts[0];
    await this.app.OCComment.ocCommentsByPostId();
    this.app.OCPost.updatePostSubject.next(true);

    this.app.spinner.hideSpinner();
    return this.posts;
  }

  updateTag(selectedTag: any) {
    this.postDoc.tag = selectedTag;
  }

  async setBadgeSystem() {
    this.badges = [];
    let options = this.app.field.getFieldSettings(
      this.app.fieldId.personProfile.Management
    ).options;

    if (this.app.profile.profile.sections == null) {
      return;
    }

    const myBadges = this.app.profile.profile.sections.pers.Management;
    if (!StringUtils.isNullOrEmpty(myBadges)) {
      myBadges.forEach((badge: any) => {
        if (options![badge] != null) {
          this.badges.push(options![badge]);
        }
      });
    }
  }

  setBadgeColor(badge: string) {
    let options = this.app.field.getFieldSettings(
      this.app.fieldId.personProfile.Management
    ).options;

    if (options![badge] != null) {
      return options![badge].color;
    }
  }

  setBadgeTooltip(badge: string) {
    let options = this.app.field.getFieldSettings(
      this.app.fieldId.personProfile.Management
    ).options;

    if (options![badge] != null) {
      return options![badge].text.en;
    }
  }

  getTimeInfo(createTime: any) {
    const date = new Date(createTime);
    const today = new Date();
    const yesterday = new Date(today);
    yesterday.setDate(today.getDate() - 1);

    if (date.toDateString() === today.toDateString()) {
      return TODAY;
    } else if (date.toDateString() === yesterday.toDateString()) {
      return YESTERDAY;
    } else {
      const day = String(date.getDate()).padStart(2, "0");
      const month = String(date.getMonth() + 1).padStart(2, "0");
      const year = String(date.getFullYear());
      return `${day}-${month}-${year}`;
    }
  }

  editDoc(docId: any) {
    this.app.OCHome.editedId = docId;
  }
}
