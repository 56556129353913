import { AbstractGraph } from "../abstract-graph";
import { VehicleGraphData } from "./vehicle-graph-data";
import { AppType } from "../../app.type";
import { GraphConfig, GraphDimensions } from "../graph.type";
import { Customer } from "../../../../../shared/types/customers";

export class VehicleGraph extends AbstractGraph<VehicleGraphData> {
  get config(): GraphConfig {
    return {
      type: "bar-horizontal",
      showXAxis: true,
      showYAxis: true,
      showLegend: false,
      xAxisLabel: this.app.text.graphs.quantity,
      yAxisLabel: this.app.text.impact.impacts,
      colorScheme: {
        domain: ["#757575"],
      },
      filter: 0,
    };
  }

  constructor(protected app: AppType) {
    super(app);
  }

  get dimensions(): GraphDimensions {
    if (!this.data || this.data.length === 0) {
      return {
        "min-height.px": 900,
      };
    }
    const calculatedHeight = this.data.length * 30 + 50;
    return {
      "height.px": calculatedHeight < 300 ? 300 : calculatedHeight,
    };
  }

  protected getFilterFunction() {
    const min = this.config.filter as number;
    return (item: VehicleGraphData) => item.count > min;
  }

  protected getSortFunction() {
    return (a: VehicleGraphData, b: VehicleGraphData) => b.count - a.count;
  }

  protected getMap(map: Map<string, VehicleGraphData>) {
    this.app.graphs.allImpacts.forEach((impact) => {
      let name: any = "";
      if (
        this.app.customer === Customer.CEOTRONICS ||
        this.app.customer === Customer.LRE
      ) {
        if (impact.artNumber === "Artikel Gesamt") {
          name = "99 999 99";
        } else {
          name = impact.artNumber;
        }
      } else {
        name = impact.omfVehicleName;
      }
      if (name == null || name === "") {
        return;
      }
      const key = name.trim().toLowerCase();
      const item = map.get(key);
      if (item != null) {
        item.add();
      } else {
        // TODO: Call increment on this new item as well?
        map.set(key, new VehicleGraphData(name, this.app));
      }
    });
  }
}
