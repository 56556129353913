import {
  Component,
  ViewChild,
  Output,
  Input,
  EventEmitter,
  OnInit,
} from "@angular/core";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { Subject } from "rxjs";
import { getApp } from "../app";
import { Manufacturer } from "../../../../shared/models/manufacturer";
import { SeMatchingResult } from "../../../../shared/models/manufacturer";

@Component({
  selector: "app-manufacturer-matching-modal",
  templateUrl: "./manufacturer-matching-modal.component.html",
  styleUrls: ["./manufacturer-matching-modal.component.scss"],
})
export class ManufacturerMatchingModalComponent implements OnInit {
  @ViewChild("content", { static: false }) content: any;
  @Output() ok = new Subject<any>();
  @Output() searchResult = new EventEmitter<SeMatchingResult[]>();

  @Input() match: any;
  @Input() manufacturer: Manufacturer | null = null;
  @Input() type: "discard" | "save" = "discard";

  step:
    | "currentMatch"
    | "keepCurrentMatch"
    | "discardCurrentMatch"
    | "selectItem"
    | "loadData" = "currentMatch";
  app = getApp((app) => {
    this.app = app;
  });
  cleanupFields: Set<any> = new Set();
  constructor(private modalService: NgbModal) {}

  ngOnInit() {
    const trimBoxes = [
      "manufacturer.matchingSection",
      "manufacturer.obsolescenceSection",
      "manufacturer.environmentalCompliance",
      "manufacturer.tehnicalSection",
    ];
    const fields: string[] = [];
    trimBoxes.forEach((box) => {
      fields.push(...this.app.getList(box));
    });
    this.cleanupFields = new Set(fields);
    if (this.type === "save") {
      this.step = "selectItem";
    }
  }

  async open() {
    try {
      const result = await this.modalService.open(this.content, {
        windowClass: "mediumModal",
      }).result;
      this.ok.next(result);
    } catch (err) {
      // NOP
    }
  }

  goToNext(
    step:
      | "currentMatch"
      | "keepCurrentMatch"
      | "discardCurrentMatch"
      | "selectItem"
      | "loadData",
    action: "save" | "dismiss"
  ) {
    if (action === "dismiss") {
      switch (step) {
        case "currentMatch":
        case "discardCurrentMatch":
          this.modalService.dismissAll();
          this.step = "currentMatch";
          break;
        case "keepCurrentMatch":
          this.step = "discardCurrentMatch";
          break;
        case "selectItem":
        case "loadData":
          this.modalService.dismissAll();
          this.step = "selectItem";
          break;
      }
    }

    if (action === "save") {
      switch (step) {
        case "currentMatch":
          this.step = "keepCurrentMatch";
          break;
        case "keepCurrentMatch":
          this.save(this.match);
          break;
        case "discardCurrentMatch":
          this.cleanData();
          break;
        case "selectItem":
          this.step = "loadData";
          break;
        case "loadData":
          this.loadEntireData();
          break;
      }
    }
  }

  async save(manufacturer: Manufacturer) {
    const manufacturerDoc = new Manufacturer();
    for (const key of Object.keys(manufacturer)) {
      manufacturerDoc[key] = manufacturer[key];
      if (key === "obsolescenceStatusOverride") {
        manufacturerDoc["obsolescenceStatusOverride"] = true;
      }
      if (manufacturerDoc.edited) {
        manufacturerDoc.edited = false;
      }
      if (manufacturerDoc.seId) {
        manufacturerDoc.seId = "";
      }
      if (manufacturerDoc.z2DataId) {
        manufacturerDoc.z2DataId = "";
      }
    }
    await this.app.manufacturerMatching.updateManufacturer(
      manufacturerDoc,
      manufacturer
    );
    this.step = "currentMatch";
    this.modalService.dismissAll();
    location.reload();
  }

  async cleanData() {
    await this.app.manufacturerMatching.cleanUpData(
      this.match,
      this.cleanupFields
    );
    if (this.match.matches.length === 0) {
      sessionStorage.setItem(
        "redoSearch",
        this.match.manufacturerPartNumberRaw
      );
    }
    this.step = "currentMatch";
    this.modalService.dismissAll();
    location.reload();
  }

  async loadEntireData() {
    if (this.manufacturer == null || this.match == null) {
      return;
    }
    await this.app.manufacturerMatching.createMatch(
      this.manufacturer,
      this.match
    );
    this.manufacturer = new Manufacturer();
    this.match = {};
    this.step = "selectItem";
    this.modalService.dismissAll();
    location.reload();
  }

  getText(step: string, action: string) {
    if (
      (step === "loadData" && action === "save") ||
      (step === "discardCurrentMatch" && action === "save")
    ) {
      return this.app.text.matchingManufacturerModal.confirm;
    }
    if (
      (step === "loadData" && action === "dismiss") ||
      (step === "discardCurrentMatch" && action === "dismiss")
    ) {
      return this.app.text.matchingManufacturerModal.abort;
    }
    if (step === "keepCurrentMatch" && action === "dismiss") {
      return this.app.text.matchingManufacturerModal.deleteData;
    }
    if (step === "keepCurrentMatch" && action === "save") {
      return this.app.text.matchingManufacturerModal.saveData;
    } else {
      if (action === "dismiss") {
        return this.app.text.matchingManufacturerModal.no;
      } else {
        return this.app.text.matchingManufacturerModal.yes;
      }
    }
  }
}
