import { Component, ViewChild } from "@angular/core";
import { getApp } from "../app";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";

@Component({
  selector: "app-application-area",
  templateUrl: "./application-area.component.html",
  styleUrls: ["./application-area.component.scss"],
})
export class ApplicationAreaComponent {
  applicationAreaOption: string = "";

  app = getApp((app) => {
    this.app = app;
  });
  @ViewChild("newApplicationAreaOptionModal", { static: false })
  newApplicationAreaOptionModal: any;

  constructor(private modalService: NgbModal) {}

  openModal() {
    this.applicationAreaOption = "";
    this.modalService.open(this.newApplicationAreaOptionModal, {
      windowClass: "mediumModal",
    }).result;
  }

  closeModal() {
    this.modalService.dismissAll();
  }

  saveApplicationArea(option: string) {
    this.app.applicationArea.updateApplicationAreaOptions([option]);
    this.closeModal();
    if (
      this.app.thread.thread["thread.applicationArea"] == null ||
      this.app.thread.thread["thread.applicationArea"].length == 0
    ) {
      this.app.thread.thread["thread.applicationArea"] = [option];
    } else {
      this.app.thread.thread["thread.applicationArea"].push(option);
    }
    this.app.thread.externalChanges = true;
  }
}
