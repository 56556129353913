import { Responsibles } from "./responsibles";

export class VehicleResponsible {
  [key: string]: any;
  _id?: string = "";
  type: string = "vehicleResponsible";
  vehiclePartNumber: string = "";
  vehicleName: string = "";
  vehicleClass: string = "";
  vehicleFleet: string = "";
  vehicleGroup: string = "";
  vehicleStatus: string = "";
  responsibleFirstName: string = "";
  responsibleLastName: string = "";
  responsibleDepartment: string = "";
  responsibleEmail: string = "";
  responsibleTelephone: string = "";
  responsibles?: Responsibles = {};
}
