import { Component, ViewChild, Output, Input } from "@angular/core";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { Subject } from "rxjs";
import { getApp } from "../../app";
import { model2Doc } from "../../api.service";

@Component({
  selector: "app-pcn-generator-modal",
  templateUrl: "./pcn-generator-modal.component.html",
  styleUrls: ["./pcn-generator-modal.component.scss"],
})
export class PcnGeneratorModalComponent {
  @ViewChild("content", { static: false }) content: any;
  @Output() ok = new Subject<any>();

  @Input() modalTitle = "";
  @Input() showItemFields = "";
  @Input() modelDoc = "";
  @Input() fields = "";
  @Input() showButtons = true;
  @Input("type") type: "discard" | "save" = "discard";
  step: "discardItem" | "openItemDetail" | "discardItem" = "openItemDetail";
  app = getApp((app) => {
    this.app = app;
  });
  cleanupFields: Set<any> = new Set();
  constructor(private modalService: NgbModal) {}

  ngOnInit() {
    let fields: string[] = [];
    this.cleanupFields = new Set(fields);
    if (this.type === "save") {
      this.step = "openItemDetail";
    }
  }

  async open() {
    try {
      let modalSize = this.getSizeOfModal();
      let result = await this.modalService.open(this.content, {
        windowClass: modalSize,
        backdrop: "static",
      }).result;
      this.ok.next(result);
    } catch (err) {
      // NOP
    }
  }

  getSizeOfModal() {
    let scrWidth = window.innerWidth;
    if (scrWidth < 500) {
      return "smallModal";
    } else {
      return "normalModal";
    }
  }

  goToNext(step: "discardItem" | "openItemDetail", action: "save" | "dismiss") {
    if (action === "dismiss") {
      switch (step) {
        case "openItemDetail":
          this.step = "discardItem";
          break;
        case "discardItem":
          this.step = "openItemDetail";
      }
    }
    if (action === "save") {
      switch (step) {
        case "discardItem":
          this.cleanData();
      }
    }
  }

  discardChanges() {
    this.app.pcnGenerator.pcnModel["pcnGenerator.itemNumbers"][
      this.app.pcnGenerator.itemIndex as number
    ] = this.app.pcnGenerator.cleanItemModel;
  }

  async cleanData() {
    this.step = "openItemDetail";
    this.modalService.dismissAll();
  }
}
