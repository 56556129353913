import { getManufacturerNames, setManufacturerNames } from "../../api.service";
import { ManufacturerNameServiceType } from "./manufacturer-name.service.type";
import { getMNames } from "./manufacturer-names";
import {
  ManufacturerName,
  ManufacturerNames,
} from "../../../../../shared/models/manufacturerName";
import { StringUtils } from "../../../../../shared/utils/string.utils";

export class ManufacturerNameService implements ManufacturerNameServiceType {
  names = new ManufacturerNames();
  notFound = new Set<string>();

  async getManufacturerNames() {
    this.names = await getManufacturerNames();
  }

  async setNames() {
    let manufacturerNames = new ManufacturerNames();
    let currentNames = this.names.names;
    manufacturerNames.names = getMNames();
    if (
      manufacturerNames.names.length != currentNames.length ||
      currentNames.length === 0
    ) {
      await setManufacturerNames(manufacturerNames);
    } else {
      return;
    }
  }

  setManufaturerName(doc: any) {
    /** if the name is missing -> set it from the clean or from the raw*/
    doc["name"] = !StringUtils.isNullOrEmpty(doc["nameClean"])
      ? doc["nameClean"]
      : doc["nameRaw"];

    /** first try to find the name of the cuurent manufacturer in the list of possible names */
    let nameIndex = this.names.names.findIndex((name: ManufacturerName) => {
      if (name.names.includes(doc["name"])) {
        doc["name"] = name.SEName;
      }
    });
    let SEIndex = -1;
    /** if the name wasn't found try to match it to the SEname */
    if (nameIndex === -1) {
      SEIndex = this.names.names.findIndex(
        (name: ManufacturerName) =>
          name.SEName.includes(doc["name"]) || doc.SEName == doc["name"]
      );
      if (SEIndex !== -1) {
        doc["name"] = this.names.names[SEIndex].SEName;
      } else {
        this.notFound.add(doc["name"]);
      }
    }
    return doc["name"];
  }
}
