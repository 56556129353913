<app-modal
  #discardChangesModal
  [modalMessage]="app.text.thread.discard"
  [modalTitle]="app.text.thread.modalTitle"
  (ok)="discardChanges()"
>
</app-modal>

<div class="d-flex flex-row-reverse">
  <!-- editMode -->
  <div *ngIf="app.view === 'thread'">
    <app-unlock
      class="me-2"
      [id]="app.thread.id"
      [permission]="app.thread.hasPermissionToEdit"
      (click)="app.thread.resetSeletectBox()"
    >
      <span
        *ngIf="
          app.thread.modelHasChanges ||
            app.thread.externalChanges ||
            app.thread.isNew;
          then thenBlock;
          else elseBlock
        "
      >
      </span>
      <!-- #then block - edit thread and there are changes -->
      <ng-template #thenBlock>
        <button
          class="btn btn-save btn-sm"
          [ngClass]="{ 'btn-save-disabled': disabledSaveButton() }"
          (click)="
            app.thread.save(app.thread.thread);
            app.field.inputSubject.next(true)
          "
          [title]="app.text.thread.save"
          [disabled]="app.thread.disabledSaveButton || disabledSaveButton()"
        >
          <i class="fa fa-check"></i> {{ app.text.app.save }}
        </button>
        <button
          class="btn btn-sm btn-clean"
          (click)="
            discardChangesModal.open();
            removeSessionSettings();
            app.field.inputSubject.next(true)
          "
          [title]="app.text.thread.discard"
        >
          <i class="fas fa-times close-icon"></i>&nbsp;
          {{ app.text.post.cancel }}
        </button>
      </ng-template>

      <!-- #else block - edit thread and there are no changs -->
      <ng-template #elseBlock>
        <div class="btn-group btn-group-sm" role="group">
          <button class="btn btn-save-disabled btn-group-right">
            <i class="fa fa-check"></i> {{ app.text.app.save }}
          </button>
          <button
            class="btn btn-clean"
            (click)="app.unlockedId = null; app.field.inputSubject.next(true)"
            [title]="app.text.thread.close"
          >
            <i class="fas fa-times close-icon"></i>&nbsp;
            {{ app.text.post.cancel }}
          </button>
        </div>
      </ng-template>
    </app-unlock>
  </div>
</div>
