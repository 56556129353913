import {
  ComponentSettings,
  FieldType,
  empty,
  FieldTypes,
} from "../settings/settings";

import { omfStatuses } from "./thread.settings";

const field = {
  change_user: {
    type: FieldTypes.value,
    text: { de: "Geändert von", en: "Changed by", nl: "Gewijzigd door" },
  },
  change_time: {
    text: { de: "Datum & Zeit", en: "Date & Time", nl: "Datum & Tijd" },
    type: <FieldType>"timestamp",
  },
  change_type: {
    type: FieldTypes.options,
    text: { de: "Art", en: "Type", nl: "Type" },
    options: {
      case: { text: { de: "Fall", en: "Case", nl: "Issue" } },
      comment: { text: { de: "Kommentar", en: "Comment", nl: "Commentaar" } },
      solution: {
        text: { de: "Lösung", en: "Solution", nl: "Afhandelstrategie" },
      },
    },
  },
  title: {
    type: FieldTypes.value,
    text: {
      de: "Titel",
      en: "Title",
      nl: "Titel",
    },
  },
  omfNumber: {
    type: FieldTypes.value,
    text: { de: "Fall-Nummer", en: "Case Number", nl: "Issue nummer" },
  },
  docType: {
    type: FieldTypes.value,
    text: { de: "Dokumenten-Typ", en: "Document-Type", nl: "Documenttype" },
  },
  resolveClass: {
    type: FieldTypes.value,
    text: { de: "Lösungsklasse", en: "Resolve class", nl: "Oplossingsklasse" },
  },
  artNumber: {
    type: FieldTypes.value,
    text: {
      de: "Interne Artikelnummer",
      en: "Customer Part Number",
      nl: "Item nummer",
    },
  },
  omfShortDescr: {
    type: FieldTypes.text,
    text: {
      de: "Bezeichnung der Einheit",
      en: "Designation of the Item",
      nl: "Item omschrijving",
    },
  },
  dinCodeRespName: {
    type: FieldTypes.value,
    text: { de: "Bearbeiter OM", en: "Responsible", nl: "OM contactpersoon" },
  },
  omfStatus: {
    type: FieldTypes.options,
    text: {
      de: "Status",
      en: "Status",
      nl: "Status",
    },
    options: omfStatuses,
  },
  docId: {
    type: FieldTypes.value,
    text: { de: "Dokumenten ID", en: "Document ID", nl: "Document-ID" },
  },
  content: {
    type: FieldTypes.value,
    text: { de: "Inhalt", en: "Content", nl: "Inhoud" },
  },
  user_id: {
    type: FieldTypes.value,
    text: { de: "Nutzer", en: "User", nl: "Gebruiker" },
  },
  to: {
    type: FieldTypes.value,
    text: { de: "Empfänger", en: "Recipient", nl: "Ontvanger" },
  },
  threadId: {
    type: FieldTypes.value,
    text: { de: "Thread", en: "Thread", nl: "Discussie" },
  },
  seen: {
    type: FieldTypes.checkbox,
    text: { de: "Gelesen", en: "Seen", nl: "Gelezen" },
  },
};

const settings = {
  permission: {
    seeNotifications: false,
  },
  text: {
    change: { de: "Benachrichtigung", en: "Notification", nl: "Notificatie" },
    changes: {
      de: "Benachrichtigungen",
      en: "Notifications",
      nl: "Notificaties",
    },
    unread: { de: "ungelesen", en: "unread", nl: "ongelezen" },
    markAsSeen: {
      de: "Markieren wie gesehen",
      en: "Mark as seen",
      nl: "Merk als gezien",
    },
  },
  url: {},
  field,
  list: {
    columns: empty,
  },
};

export const change: ComponentSettings<typeof settings> = settings;
