import { Component } from "@angular/core";

@Component({
  selector: "app-root",
  templateUrl: "./routing.component.html",
  styleUrls: ["./routing.component.scss"]
})
export class RoutingComponent {
  constructor() {}
}
