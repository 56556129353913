import { CodeFormat, ValueFormat } from "../../../../shared/settings/settings";

export class CodeGenerator {
  constructor(private _codeFormat: CodeFormat) {}

  protected getValueFormat(name: string) {
    return this._codeFormat.values[name];
  }

  protected getValueNames() {
    return Object.keys(this._codeFormat.values).sort((a, b) => {
      return this.getValueFormat(a).at - this.getValueFormat(b).at;
    });
  }

  generate(args: { [property: string]: string | number }) {
    let text = this._codeFormat.text;

    let insertedChars = 0;
    this.getValueNames().forEach((name) => {
      if (!(name in args)) {
        throw new Error(
          `no value for expected property ${JSON.stringify(name)}`
        );
      }
      const arg = args[name];

      const valueFormat = this.getValueFormat(name);
      const value = this.prepareValue(arg, valueFormat);

      const index = valueFormat.at + insertedChars;
      text = text.slice(0, index) + value + text.slice(index);
      insertedChars += value.length;
    });

    return text;
  }

  protected prepareValue(value: string | number, valueFormat: ValueFormat) {
    let text = "";
    if (typeof value === "number") {
      let n = value;

      const digits = valueFormat.digits;
      if (digits == null) {
        return `${n}`;
      }

      const base = 10;
      let i = 0;

      for (; i < digits; i++) {
        text = (n % base).toString() + text;
        n = Math.floor(n / base);
      }

      const fixed = "fixed" in valueFormat && valueFormat.fixed;
      if (!fixed) {
        for (; n > 0; i++) {
          text = (n % base).toString() + text;
          n = Math.floor(n / base);
        }
      }
    }
    if (typeof value === "string") {
      text = value;
    }
    return text;
  }
}
