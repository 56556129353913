import { ComponentSettings } from "../settings/settings";

const settings = {
  permission: {},
  list: {},
  url: {},
  text: {
    cancel: { de: "Abbrechen", en: "Cancel", nl: "Afbreken" },
    ok: { de: "Ok", en: "Ok", nl: "Ok" },
    modalTitle: {
      de: "Bestätigung",
      en: "Confirm",
      nl: "Bevestigen",
    },
    discard: {
      de: "Änderungen verwerfen",
      en: "Discard changes",
      nl: "Negeer de wijzigingen",
    },
    discardChanges: {
      de: "Sind Sie sicher, dass Sie die aktuellen Änderungen verwerfen wollen?",
      en: "Are you sure you want to discard the current changes?",
      nl: "Weet u zeker dat u de huidige wijzigingen wilt negeren?",
    },
  },
  field: {},
};
export const modal: ComponentSettings<typeof settings> = settings;
