import { ComponentSettings } from "../settings/settings";

const settings = {
  permission: {},
  text: {
    next: { de: "Nächster", en: "Next", nl: "Volgende" },
    previous: { de: "Vorheriger", en: "Previous", nl: "Vorige" },
  },
  list: {},
  url: {},
  field: {},
};

export const next: ComponentSettings<typeof settings> = settings;
