import { Component } from "@angular/core";
import { getApp } from "../app";

@Component({
  selector: "app-example",
  templateUrl: "./example.component.html",
  styleUrls: ["./example.component.scss"]
})
export class ExampleComponent {
  app = getApp(app => {
    this.app = app;
  });

  constructor() {}
}
