import { AbstractGraphData } from "../abstract-graph-data";
import { Thread, GraphDataComplex } from "../graphs.service.type";
import { AppType } from "../../app.type";

export class ResponsibleGraphData extends AbstractGraphData {
  responsible: string;
  open = 0;
  closed = 0;
  total = 0;

  constructor(thread: Thread, app: AppType) {
    super(app);
    this.responsible = thread.dinCodeRespName;
  }

  add(thread: Thread) {
    if (thread.omfStatus === "Geschlossen") {
      this.closed++;
    } else {
      this.open++;
    }
    this.total++;
  }

  get data(): GraphDataComplex {
    return {
      name: this.responsible,
      series: [
        {
          name: this.app.text.graphs.closed,
          value: this.closed
        },
        {
          name: this.app.text.graphs.open,
          value: this.open
        }
      ]
    };
  }
}
