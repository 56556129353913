import { Component, OnDestroy, OnInit, ViewChild } from "@angular/core";
import { getApp } from "../../app";
import { Subscription } from "rxjs";
@Component({
  selector: "app-oc-post",
  templateUrl: "./OC-post.component.html",
  styleUrls: ["./OC-post.component.scss"],
})
export class OCPostComponent implements OnInit, OnDestroy {
  updatePostSubscription: Subscription = new Subscription();
  @ViewChild("fileInput", { static: false }) fileInput: any;

  app = getApp((app) => {
    this.app = app;
  });

  constructor() {}

  async ngOnInit() {
    this.updatePostSubscription = this.app.OCPost.updatePostSubject.subscribe(
      async (value) => {
        if (value) {
          await this.app.OCComment.ocCommentsByPostId();
          this.app.OCPost.getImageExternal();
          this.app.attachments.OCAttachmentsSubject.next(true);
        }
      }
    );
  }

  async onUpload() {
    const file: File = this.fileInput.nativeElement.files[0];
    if (file.type.indexOf("image/") !== 0 && file != null) {
      this.app.hasError = true;
      this.app.errorText = this.app.text.thread.uploadImageTypeError;
    } else {
      this.app.hasError = false;
      this.app.errorText = "";
      const fileLink = this.app.OCPost.getImage();

      await this.app.file.upload(file, fileLink);
      this.app.OCPost.getImageExternal();
    }
  }

  updateTag(selectedTag: any) {
    this.app.OCPost.cleanPost.tag = selectedTag;
  }

  ngOnDestroy() {
    if (this.updatePostSubscription) {
      this.updatePostSubscription.unsubscribe();
    }
  }
}
