import { Inject, Injectable } from "@angular/core";
import { Change } from "../../../../shared/models/change";
import { getChanges, getUnreadChanges } from "../api.service";
import { AppType, APP_TYPE } from "../app.type";
import { ChangesServiceType } from "./changes.service.type";

@Injectable()
export class ChangesService implements ChangesServiceType {
  changes: Change[] = [];
  cleanChanges: Change[] = [];
  unreadMails = 0;
  postId: string = "";
  fromAlert: boolean = false;

  constructor(@Inject(APP_TYPE) private app: AppType) {}

  async getAllChanges(): Promise<void> {
    if (this.app.user == null) {
      return;
    }
    this.changes = await getChanges(
      this.app.customers.expectCurrent,
      this.app.user
    );
    this.cleanChanges = this.changes;
    this.unreadMails = this.changes.filter(
      (change) => change.seen === false
    ).length;

    this.updateCounter(this.unreadMails);
  }

  async getUnreadChanges(): Promise<void> {
    if (this.app.user == null) {
      return;
    }

    const unreadChanges = await getUnreadChanges(
      this.app.customers.expectCurrent,
      this.app.user
    );

    this.updateCounter(unreadChanges.numberOfChanges);
  }

  private updateCounter(count: number): void {
    this.app.state.next({ numberOfChanges: count });
    this.unreadMails = count;
  }
}
