<app-modal
  #deleteImpactModal
  [modalMessage]="app.text.impact.deleteMessage"
  [modalTitle]="app.text.impact.modalTitle"
  (ok)="app.impact.delete(app.impact.currentImpact)"
></app-modal>
<app-modal
  #discardChangesModal
  [modalMessage]="app.text.impact.discard"
  (ok)="
    discardChanges();
    app.impact.impactNameExist = false;
    discardChangesModal.close()
  "
>
</app-modal>

<div class="controls">
  <app-unlock
    [id]="app.impact.id"
    [permission]="
      app.impact.hasEditPermission(app.impact.id) && !app.impact.isNew
    "
    [title]="app.text.impact.editImpact"
  >
    <span
      *ngIf="
        app.impact.modelHasChanges || app.impact.isNew;
        then thenBlock;
        else elseBlock
      "
    >
    </span>
    <ng-template #thenBlock>
      <button
        class="btn"
        *ngIf="
          app.field.getInvalidFields('impact', app.impact.currentImpact)
            .length < 1 && app.impact.modelHasChanges
        "
        (click)="app.impact.save()"
        [title]="app.text.impact.save"
      >
        <i class="fa fa-check"></i>
      </button>
      <button
        class="btn"
        (click)="discardChangesModal.open()"
        [title]="app.text.impact.discard"
      >
        <i class="fa fa-times"></i>
      </button>
    </ng-template>

    <ng-template #elseBlock>
      <button
        class="btn"
        (click)="
          app.routing.navigateThread(app.thread.thread['thread._id']);
          app.unlockedId = null
        "
        [title]="app.text.impact.close"
      >
        <i class="fa fa-times"></i>
      </button>
    </ng-template>
  </app-unlock>
  <span *ngIf="app.unlockedId == null">
    <span *ngIf="!app.impact.isNew">
      <button
        class="btn"
        (click)="deleteImpactModal.open()"
        *ngIf="app.impact.hasEditPermission(app.impact.id)"
        [title]="app.text.impact.deleteImpact"
      >
        <i class="fa fa-trash"></i>
      </button>
      <button
        class="btn"
        (click)="discardChanges()"
        [title]="app.text.impact.close"
      >
        <i class="fa fa-times"></i>
      </button>
    </span>
  </span>
</div>
<div
  *ngIf="
    app.field.getInvalidFields('impact', app.impact.currentImpact).length > 0 ||
    app.impact.impactNameExist
  "
  class="row error-message"
>
  <span *ngIf="app.unlockedId !== null">
    {{ app.text.impact.messageInvalidForm }}
    <ul>
      <li
        *ngFor="
          let invalidField of app.field.getInvalidFields(
            'impact',
            app.impact.currentImpact
          )
        "
      >
        {{ app.field.getLabel(invalidField) }}
      </li>
    </ul>
    <ul *ngIf="app.impact.impactNameExist">
      <li>
        {{ app.text.impact.impactNameExist }}
      </li>
    </ul>
  </span>
</div>

<!-- ALL clients vehicles design, except DB  -->
<app-box
  *ngIf="app.customers.expectCurrent !== 'db'"
  [box]="app.listId.impact.fields"
  [model]="app.impact.currentImpact"
  [unlock]="app.impact.id"
>
  {{ app.impact.id }}
</app-box>
