import { Inject, Injectable } from "@angular/core";
import {
  Manufacturer,
  Multi,
  SeMatch,
} from "../../../../shared/models/manufacturer";
import { Z2DataMatch } from "../../../../shared/models/dataProviders/z2data";
import { doc2Model } from "../api.service";
import { AppType, APP_TYPE } from "../app.type";
import { ManufacturerService } from "../manufacturer/manufacturer.service";
import { ExternalDataFilterServiceType } from "./external-data-filter.service.type";
import { BehaviorSubject } from "rxjs";
import { DataProvider } from "../../../../shared/constants/dataProvider";

@Injectable()
export class ExternalDataFilterService
  implements ExternalDataFilterServiceType
{
  currentMatch: SeMatch[] | Z2DataMatch[] = [];
  selectedMatch: SeMatch | Z2DataMatch = new SeMatch();
  matchesCopy: SeMatch[] | Z2DataMatch[] = [];
  matches: SeMatch[] | Z2DataMatch[] = [];
  searchedPartNumber: string = "";
  isAdded: boolean = false;
  manufacturers: Manufacturer[] = [];
  redoSearch: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  constructor(@Inject(APP_TYPE) private app: AppType) {}

  async getSeSearchResults(results: any[]) {
    this.app.manufacturerMatching.manufacturer.matches = [];
    this.matches = [];
    let manufacturer = this.app.manufacturerMatching.manufacturer;
    results.forEach((result) => {
      this.asArray(result.PartList).forEach(({ PartDto }) => {
        this.asArray(PartDto).forEach((match) => {
          if (!manufacturer.matches) {
            manufacturer.matches = [];
          }
          match.Lifecycle = ManufacturerService.parseStatus(match.Lifecycle);
          match.RoHS = ManufacturerService.parseRoHS(match.RoHS);
          manufacturer.matches.push(match);
          manufacturer.searchResults = true;
          this.matches = manufacturer.matches;
          this.matchesCopy = manufacturer.matches;
        });
      });
    });
  }

  asArray<T>(multi: Multi<T>) {
    if (multi == null) {
      return [];
    }
    if (Array.isArray(multi)) {
      return <T[]>multi;
    }
    return [<T>multi];
  }

  async getZ2DataSearchResults(results: any) {
    this.app.manufacturerMatching.manufacturer.matches = [];
    this.matches = [];
    let manufacturer = this.app.manufacturerMatching.manufacturer;
    if (results.results && results.results.PartSearch.Result.length > 0) {
      results.results.PartSearch.Result.forEach((match: any) => {
        if (!manufacturer.matches) {
          manufacturer.matches = [];
        }
        manufacturer.matches.push(match);
        match.LifecycleStatus = ManufacturerService.parseStatus(
          match.LifecycleStatus
        );
        match.RoHSStatus = ManufacturerService.parseRoHS(match.RoHSStatus);
        manufacturer.searchResults = true;
        this.matches = manufacturer.matches;
        this.matchesCopy = manufacturer.matches;
      });
    }
  }

  newCase(doc: SeMatch | Z2DataMatch, searchedCpn: string) {
    this.selectedMatch = doc;
    this.selectedMatch.type =
      this.app.customers.dataProvider === DataProvider.Z2DATA
        ? "z2DataMatch"
        : "seMatch";
    sessionStorage.setItem("se_filter_cpn", searchedCpn);
    //clear all variables that could have fields info about a thread
    this.app.pcn.pcn = null;
    this.app.pcn.id = null;
    this.app.routing.navigateNewThread();
  }

  setFields() {
    if (this.app.customers.dataProvider === DataProvider.Z2DATA) {
      return this.setZ2DataFields();
    }
    return this.setSeFields();
  }

  setSeFields() {
    let model = doc2Model("manufacturer", {} as Manufacturer);
    let selectedManufacturer = new SeMatch();
    Object.keys(this.app.manufacturer.selectedManufacturer).forEach((key) => {
      selectedManufacturer[key] =
        this.app.manufacturer.selectedManufacturer[key];
    });
    model["manufacturer.name"] = selectedManufacturer.Manufacturer;
    model["manufacturer.nameRaw"] = selectedManufacturer.Manufacturer;
    model["manufacturer.manufacturerPartNumber"] =
      selectedManufacturer.PartNumber;
    model["manufacturer.manufacturerPartNumberRaw"] =
      selectedManufacturer.PartNumber;
    model["manufacturer.manufacturerPartDescription"] =
      selectedManufacturer.Description;
    model["manufacturer.manufacturerPartDescriptionRaw"] =
      selectedManufacturer.Description;
    model["manufacturer.seId"] = selectedManufacturer.ComID;
    model["manufacturer.obsolescenceStatus"] = selectedManufacturer.Lifecycle;
    model["manufacturer.datasheet"] = selectedManufacturer.Datasheet;
    model["manufacturer.euRoHS"] = selectedManufacturer.RoHS;
    if (selectedManufacturer.YEOL != "Unknown") {
      model["manufacturer.estimatedYearsToEOL"] = Number(
        selectedManufacturer.YEOL
      );
    }
    model["manufacturer.partNumber"] =
      this.app.model["manufacturer.partNumber"];
    return model;
  }

  setZ2DataFields() {
    let model = doc2Model("manufacturer", {} as Manufacturer);
    let selectedManufacturer = new Z2DataMatch();
    Object.keys(this.app.manufacturer.selectedManufacturer).forEach((key) => {
      selectedManufacturer[key] =
        this.app.manufacturer.selectedManufacturer[key];
    });
    model["manufacturer.name"] = selectedManufacturer.Manufacturer;
    model["manufacturer.nameRaw"] = selectedManufacturer.Manufacturer;
    model["manufacturer.manufacturerPartNumber"] = selectedManufacturer.MPN;
    model["manufacturer.manufacturerPartNumberRaw"] = selectedManufacturer.MPN;
    model["manufacturer.manufacturerPartDescription"] =
      selectedManufacturer.Description;
    model["manufacturer.manufacturerPartDescriptionRaw"] =
      selectedManufacturer.Description;
    model["manufacturer.z2DataId"] = selectedManufacturer.PartID.toString();
    model["manufacturer.datasheet"] = selectedManufacturer.Datasheet;
    model["manufacturer.partNumber"] =
      this.app.model["manufacturer.partNumber"];
    model["manufacturer.obsolescenceStatus"] =
      selectedManufacturer.LifecycleStatus;
    model["manufacturer.euRoHS"] = selectedManufacturer.RoHS;
    model["manufacturer.estimatedYearsToEOL"] = Number(
      selectedManufacturer.EstimatedYearsToEOL
    );
    return model;
  }
}
