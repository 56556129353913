import { StringUtils } from "../../../../shared/utils/string.utils";

export const MILLISECONDS_IN_YEAR = 31536000000;

/**
 * Returns date as string in a format "YYYY-MM-DD, HH:MM:SS"
 */
export function formatDatetime(date: any): string {
  if (date === "") return "";

  const time = new Date(date);
  let year = time.getFullYear();
  let month = time.getMonth() + 1 + "";
  if (month.length === 1) month = "0" + month;

  let day = time.getDate() + "";
  if (day.length === 1) day = "0" + day;

  let hour = ("0" + time.getHours()).slice(-2) + "";
  let minutes = ("0" + time.getMinutes()).slice(-2) + "";
  let seconds = ("0" + time.getSeconds()).slice(-2) + "";

  let formatDate =
    year +
    "-" +
    month +
    "-" +
    day +
    ", " +
    hour +
    ":" +
    minutes +
    ":" +
    seconds;
  return formatDate;
}

/**
 * Returns date as string in a format "YYYY-MM-DD"
 */
export function formatDate(date: any, format?: string): string {
  if (date === "") return "";
  if (date === undefined) return "";
  if (
    (typeof date === "string" || date instanceof String) &&
    date.includes("+")
  ) {
    return "";
  }
  const time = new Date(date);
  let year = time.getFullYear();
  let month = time.getMonth() + 1 + "";
  if (month.length === 1) month = "0" + month;

  let day = time.getDate() + "";
  if (day.length === 1) day = "0" + day;

  switch (format) {
    case "YYYYMMDD":
      return year + month + day;
    default: //YYYY-MM-DD
      return year + "-" + month + "-" + day;
  }
}

export function addDays(s: string, days: number) {
  const date = new Date(s);
  const result = toDateString(
    new Date(date.getTime() + days * 24 * 60 * 60 * 1000)
  );
  return result;
}

export function toDateString(d: Date) {
  if (d == null) {
    return "";
  }
  try {
    return new Date(Date.UTC(d.getFullYear(), d.getMonth(), d.getDate()))
      .toISOString()
      .split("T")[0];
  } catch (err) {
    return "";
  }
}

/**
 * Convert a number to a date "YYYY-MM-DD"
 */
export function convertYearsLeftToDate(years: any) {
  if (!StringUtils.isNullOrEmpty(years)) {
    let yearsLeft = parseFloat(years);
    let currentDate = Date.now();
    let calculatedMiliseconds = currentDate + yearsLeft * MILLISECONDS_IN_YEAR;
    let calculatedDate = new Date(calculatedMiliseconds);
    return formatDate(calculatedDate);
  } else {
    return "";
  }
}

/**
 * Convert a date "YYYY-MM-DD" to a number
 */
export function convertDateToYearsLeft(date: string): string {
  if (!date) return "";

  let setDate = new Date(date).getTime();
  if (setDate < 0) return "";

  let currentDate = Date.now();
  let miliseconds = setDate - currentDate;
  if (miliseconds < 0) return "0";

  const yearsLeft = miliseconds / MILLISECONDS_IN_YEAR;

  // If yearsLeft is small than 0.1 it means that there are still a couple of days left from a year to be displayed
  // If yearsLeft equals 0.0005 means that we are already in the current date
  if (yearsLeft < 0.1 && yearsLeft > 0.0005) {
    return yearsLeft.toFixed(4);
  } else {
    return yearsLeft.toFixed(1);
  }
}
