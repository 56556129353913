//commodityMap const is used just for mrce client

const e1 = "ES64F4";
const e2 = "ES64U2"
const x = "X4E";
const b1 = "BR_185";
const b2 = "BR_484";
const b3 = "BR_145";
const o = "other";


export const commodityMap: Record<string, string[]>= {
    "0": [o],
    "01": [o],
    "1": [o],
    "2": [e2],
    "3": [o],
    "4": [e1, e2, x, b1, b2, b3],
    "5": [e2],
    "6": [o],
    "7": [e1, e2],
    "8": [o],
    "9": [e1, e2],
    "10": [e1, e2, x, b1, b2, b3],
    "11": [e1],
    "12": [e1],
    "14": [o],
    "18": [o],
    "19": [o],
    "20": [o],
    "21": [o],
    "27": [b1],
    "29": [o],
    "32": [b3],
    "33": [e1],
    "34": [e1],
    "35": [e2],
    "36": [b1],
    "37": [e2],
    "38": [e1],
    "39": [e2],
    "40": [e2],
    "41": [e1, b1],
    "42": [e1, b1],
    "43": [e1, e2, b1],
    "44": [e2, b1],
    "45": [b1],
    "46": [x],
    "47": [x],
    "48": [e1, e2, x],
    "49": [b2],
    "50": [x],
    "51": [e2, x],
    "52": [e1, x],
    "53": [e1, x],
    "54": [e1, e2, b1],
    "55": [e1, e2, x, b1],
    "56": [e1, x, b1, b2],
    "57": [e2, b1],
    "58": [e1, e2, b1],
    "59": [x, b1],
    "60": [e2, x],
    "61": [x],
    "62": [e1, e2, x],
    "63": [e1, e2],
    "64": [b1, b2],
    "65": [b2],
    "66": [e1],
    "67": [o],
    "68": [e1, x, b1],
    "69": [b1],
    "70": [e1, b1],
    "71": [e1, x, b1, b2],
    "FBD": [o]
}