import { Component, OnInit, OnDestroy, ChangeDetectorRef } from "@angular/core";
import { getApp } from "../app";
import { Customer } from "../../../../shared/types/customers";
import { Router } from "@angular/router";
import { FieldTypes } from "../../../../shared/settings/settings";

@Component({
  selector: "RM",
  templateUrl: "./RM.component.html",
  styleUrls: ["./RM.component.scss"],
})
export class RMComponent implements OnInit, OnDestroy {
  filterOpen: boolean = false;
  activeFilter: boolean = false;
  selectedButton: string = "";

  showInventoryMonitoringTable: boolean = false;

  app = getApp((app) => {
    this.app = app;
  });

  constructor(private router: Router, private _cdRef: ChangeDetectorRef) {}

  ngOnInit(): void {
    // Detect html changes to avoid getting an error on app.RM.displayRMHeader when changing the view
    this._cdRef.detectChanges();
    if (this.app.filterTree.filterValues.length > 0) {
      this.filterOpen = true;
    }

    this.setActivBtnByMode();
    this.setTablePermission();
  }

  setActivBtnByMode() {
    switch (this.app.RM.mode) {
      case "tree":
        this.app.RM.isTree = true;
        this.app.RM.isPart = false;
        this.app.RM.isManufacturer = false;
        break;
      case "all-manufacturers":
      case "matched":
      case "unmatched":
      case "manual":
      case "override":
      case "manualAssignment":
      case "linkedToDeletedCPN":
        this.app.RM.isManufacturer = true;
        this.app.RM.isPart = false;
        this.app.RM.isTree = false;
        break;
      case "all-parts":
      case "without-manufacturers":
      case "not-used-assemblies":
      case "parts-to-be-deleted":
      case "parts-status":
        this.app.RM.isPart = true;
        this.app.RM.isManufacturer = false;
        this.app.RM.isTree = false;
        break;
      default:
        this.app.RM.isTree = true;
        this.app.RM.isPart = false;
        this.app.RM.isManufacturer = false;
    }
  }

  setTablePermission() {
    if (this.app.customers.expectCurrent === Customer.MTU) {
      if (this.app.auth.isTeam || this.app.auth.isAdmin) {
        this.showInventoryMonitoringTable = true;
      } else {
        // if the user (user, guest, analyst) accesses the link for parts-status view, should be redirected to the all-parts table
        let url = this.router.url.split("/").splice(-1)[0];
        if (url === "parts-status") {
          this.app.RM.navigateRMComponents("parts/all-parts");
        }
      }
    } else {
      this.showInventoryMonitoringTable = false;
    }
  }

  setFieldTypeOnCreatePart(){
        this.app.field.getFieldSettings(this.app.fieldId.part.description).type =
      FieldTypes.text;
  }

  ngOnDestroy(): void {
    this.app.RM.filterOpen = false;
  }
}
