<!-- DB vehicles design-->
<app-modal
  #deleteImpactModal
  [modalMessage]="app.text.impact.deleteMessage"
  [modalTitle]="app.text.impact.modalTitle"
  (ok)="deleteImpact()"
></app-modal>
<app-modal
  #discardChangesModal
  [modalMessage]="app.text.impact.discard"
  (ok)="discardChanges(); app.impact.impactNameExist = false"
>
</app-modal>
<div class="controls">
  <app-unlock
    [id]="app.impact.id"
    [permission]="
      app.impact.hasEditPermission(app.impact.id) && !app.impact.isNew
    "
    [title]="app.text.impact.editImpact"
    [hasText]="false"
  >
    <span
      *ngIf="
        app.impact.modelHasChanges || app.impact.isNew;
        then thenBlock;
        else elseBlock
      "
    >
    </span>
    <ng-template #thenBlock>
      <button
        class="btn"
        *ngIf="!hasInvalidFields() && !saveDisabled"
        (click)="save()"
        [title]="app.text.impact.save"
      >
        <i class="fa fa-check"></i>
      </button>
      <button
        class="btn"
        *ngIf="app.impact.modelHasChanges"
        (click)="discardChangesModal.open()"
        [title]="app.text.impact.discard"
      >
        <i class="fa fa-times"></i>
      </button>
    </ng-template>

    <ng-template #elseBlock>
      <button
        class="btn"
        (click)="discardChanges()"
        [title]="app.text.impact.close"
      >
        <i class="fa fa-times"></i>
      </button>
    </ng-template>
  </app-unlock>
  <span *ngIf="app.unlockedId == null">
    <span *ngIf="!app.impact.isNew">
      <button
        class="btn"
        (click)="deleteImpactModal.open()"
        *ngIf="app.impact.hasEditPermission(app.impact.id)"
        [title]="app.text.impact.deleteImpact"
      >
        <i class="fa fa-trash"></i>
      </button>
    </span>
  </span>
</div>

<div
  *ngIf="
    app.field.getInvalidFields('impact', app.impact.currentImpact).length > 0 ||
    app.impact.impactNameExist
  "
  class="row error-message"
>
  <span *ngIf="app.unlockedId !== null">
    {{ app.text.impact.messageInvalidForm }}
    <ul>
      <li
        *ngFor="
          let invalidField of app.field.getInvalidFields(
            'impact',
            app.impact.currentImpact
          )
        "
      >
        {{ app.field.getLabel(invalidField) }}
      </li>
    </ul>
    <ul *ngIf="app.impact.impactNameExist">
      <li>
        {{ app.text.impact.impactNameExist }}
      </li>
    </ul>
  </span>
</div>
<div class="descriptionList" *ngIf="view === 'detailView'">
  <div *ngIf="app.isLocked(app.impact.id)" class="col-md-12">
    <div class="row">
      <div class="col-md-6">
        <div class="flex">
          <div class="field-label" id="impact.omfVehicleName">
            {{ app.field.getLabel("impact.omfVehicleName") }}:&nbsp;
          </div>
          <div class="lineHeight" id="impact.omfVehicleName">
            {{ app.impact.currentImpact["impact.omfVehicleName"] }}
          </div>
        </div>
        <div class="flex">
          <div class="field-label" id="impact.omfVehicleFleet">
            {{ app.field.getLabel("impact.omfVehicleFleet") }}:&nbsp;
          </div>
          <div class="lineHeight" id="impact.omfVehicleFleet">
            {{ app.impact.currentImpact["impact.omfVehicleFleet"] }}
          </div>
        </div>
        <div class="flex">
          <div class="field-label" id="thread.omfStatus">
            {{ app.field.getLabel("thread.omfStatus") }}:&nbsp;
          </div>
          <div class="lineHeight" id="thread.omfStatus">
            {{ app.thread.thread["thread.omfStatus"] }}
          </div>
        </div>
      </div>
      <div class="col-md-6">
        <ul class="legend-list col-md-6 pull-right">
          <li>
            <i class="fad fa-user-check"></i>&nbsp;
            <span class="lineHeight">{{ app.text.impact.greenBullet }}</span>
          </li>
          <li>
            <i class="fad fa-user-times"></i>&nbsp;
            <span class="lineHeight">{{ app.text.impact.redBullet }}</span>
          </li>
        </ul>
      </div>
    </div>
  </div>
  <div *ngIf="!app.isLocked(app.impact.id)" class="col-md-12">
    <app-field
      [model]="app.impact.currentImpact"
      [field]="app.fieldId.impact.omfVehicleName"
      [disabled]="app.isLocked(app.impact.id)"
      (selected)="
        app.vehicleResponsible.setImpactDetails(
          app.impact.currentImpact[app.fieldId.impact.omfVehicleName]
        )
      "
    ></app-field>
    <app-field
      [model]="app.impact.currentImpact"
      [field]="app.fieldId.impact.omfVehicleFleet"
      [disabled]="app.isLocked(app.impact.id)"
    ></app-field>
  </div>

  <!-- RESPONSIBLES FIELDS -->
  <div class="col-md-12">
    <div class="tableFixHead">
      <table class="table table-striped">
        <thead>
          <tr class="text-center">
            <th class="p-1">{{ app.text.responsibles.department }}</th>
            <th class="p-1">BAV</th>
            <th class="p-1"></th>
            <th class="p-1">MaWI</th>
            <th class="p-1"></th>
          </tr>
        </thead>
        <tbody
          *ngIf="
            app.impact.currentImpact &&
            app.impact.currentImpact['impact.responsibles']
          "
        >
          <tr
            *ngFor="let department of departments; let i = index"
            id="{{ department.name }}"
          >
            <!-- DEPARTMENT NAME -->

            <td class="p-1 text-center" id="{{ department.name }}">
              <span class="department-name">{{ department.name }}</span>
            </td>

            <!-- BAV + BAV VERTRETER FIELDS -->
            <td class="p-1" *ngIf="!hasDifferentDisplay(department.name)">
              <div
                class="col-md-12 text-center"
                id="{{ department.fields.bav }}"
              >
                <!-- BAV FIELDS / READ MODE & EDIT MODE-->
                <div *ngIf="app.isLocked(app.impact.id)">
                  {{
                    app.impact.currentImpact["impact.responsibles"][
                      department.fields.bav
                    ]
                  }}
                </div>
                <div *ngIf="!app.isLocked(app.impact.id)">
                  <app-field
                    [model]="app.impact.currentImpact['impact.responsibles']"
                    [field]="department.fields.bav"
                    [disabled]="!app.isLocked(app.impact.id)"
                    [showLabel]="false"
                  ></app-field>
                </div>

                <!-- VERTRETER FIELDS / READ MODE-->
                <div
                  class="text-muted font-weight-light font-italic"
                  id="{{ department.fields.bavVertreter }}"
                  *ngIf="
                    !hasDifferentDisplay(department.name) &&
                    app.isLocked(app.impact.id)
                  "
                >
                  <small
                    class="text-muted font-weight-light font-italic"
                    *ngIf="display(department.fields.bavVertreter)"
                    >{{ app.text.impact.deputy }}:
                    {{
                      app.impact.currentImpact["impact.responsibles"][
                        department.fields.bavVertreter
                      ]
                    }}
                  </small>
                </div>

                <!-- VERTRETER FIELDS ON CREATE/EDIT MODE -->
                <span
                  *ngIf="!app.isLocked(app.impact.id)"
                  id="{{ department.fields.bavVertreter }}"
                >
                  <app-field
                    [model]="app.impact.currentImpact['impact.responsibles']"
                    [field]="department.fields.bavVertreter"
                    [disabled]="app.isLocked(app.impact.id)"
                    [showLabel]="true"
                    [threadComments]="true"
                  ></app-field>
                </span>
              </div>
            </td>

            <!-- SPECIAL CASE FOR STATUS 90 - BAV CARGO -->
            <td
              class="p-1"
              *ngIf="hasDifferentDisplay(department.name)"
              id="{{ department.fields.bavVertreter }}"
            >
              <div class="col-md-12 text-center">
                <!-- BAV FIELDS / READ MODE & EDIT MODE-->
                <div *ngIf="app.isLocked(app.impact.id)">
                  {{
                    app.impact.currentImpact["impact.responsibles"][
                      department.fields.bavVertreter
                    ]
                  }}
                </div>
                <div *ngIf="!app.isLocked(app.impact.id)">
                  <app-field
                    [model]="app.impact.currentImpact['impact.responsibles']"
                    [field]="department.fields.bavVertreter"
                    [disabled]="app.isLocked(app.impact.id)"
                    [showLabel]="false"
                  ></app-field>
                </div>

                <!-- VERTRETER FIELDS / READ MODE-->
                <div
                  class="text-muted font-weight-light font-italic"
                  *ngIf="
                    hasDifferentDisplay(department.name) &&
                    app.isLocked(app.impact.id)
                  "
                >
                  <small
                    class="text-muted font-weight-light font-italic"
                    *ngIf="display(department.fields.bav)"
                    id="{{ department.fields.bav }}"
                    >{{ app.text.impact.deputy }}:
                    {{
                      app.impact.currentImpact["impact.responsibles"][
                        department.fields.bav
                      ]
                    }}
                  </small>
                </div>

                <!-- VERTRETER FIELDS ON CREATE/EDIT MODE -->
                <span
                  *ngIf="!app.isLocked(app.impact.id)"
                  id="{{ department.fields.bav }}"
                >
                  <app-field
                    [model]="app.impact.currentImpact['impact.responsibles']"
                    [field]="department.fields.bav"
                    [disabled]="app.isLocked(app.impact.id)"
                    [showLabel]="true"
                  ></app-field>
                </span>
              </div>
            </td>

            <!-- RESPONSIBILTY STATUS  GREEN / RED FOR BAV FIELDS-->
            <td class="p-1" *ngIf="!hasDifferentDisplay(department.name)">
              <button class="btn">
                <i
                  class="fad fa-user-check"
                  *ngIf="
                    app.impact.displayBullet(
                      department.fields.bav,
                      green,
                      app.impact.currentImpact['impact.responsibles'],
                      app.impact.currentImpact['impact.omfVehicleName']
                    ) && app.unlockedId !== app.impact.id
                  "
                ></i>
                <i
                  class="fad fa-user-times"
                  *ngIf="
                    app.impact.displayBullet(
                      department.fields.bav,
                      red,
                      app.impact.currentImpact['impact.responsibles'],
                      app.impact.currentImpact['impact.omfVehicleName']
                    ) && app.unlockedId !== app.impact.id
                  "
                ></i>
              </button>
            </td>

            <!-- RESPONSIBILITY ON STATUS 90 / DEPARTMENT CARGO -->
            <td class="p-1" *ngIf="hasDifferentDisplay(department.name)">
              <button class="btn">
                <i
                  class="fad fa-user-check"
                  *ngIf="
                    app.impact.displayBullet(
                      department.fields.bavVertreter,
                      green,
                      app.impact.currentImpact['impact.responsibles'],
                      app.impact.currentImpact['impact.omfVehicleName']
                    ) && app.unlockedId !== app.impact.id
                  "
                ></i>
                <i
                  class="fad fa-user-times"
                  *ngIf="
                    app.impact.displayBullet(
                      department.fields.bavVertreter,
                      red,
                      app.impact.currentImpact['impact.responsibles'],
                      app.impact.currentImpact['impact.omfVehicleName']
                    ) && app.unlockedId !== app.impact.id
                  "
                ></i>
              </button>
            </td>

            <!-- MAWI + MAWI VERTRETER FIELDS -->
            <td class="p-1">
              <div
                class="col-md-12 text-center"
                id="{{ department.fields.mawi }}"
              >
                <!-- MAWI FIELDS ON READ & EDIT MODE -->
                <div *ngIf="app.isLocked(app.impact.id)">
                  {{
                    app.impact.currentImpact["impact.responsibles"][
                      department.fields.mawi
                    ]
                  }}
                </div>

                <div *ngIf="!app.isLocked(app.impact.id)">
                  <app-field
                    [model]="app.impact.currentImpact['impact.responsibles']"
                    [field]="department.fields.mawi"
                    [disabled]="app.isLocked(app.impact.id)"
                    [showLabel]="false"
                  ></app-field>
                </div>

                <!-- MAWI  VERTRETER  FIELDS ON READ MODE -->

                <div
                  *ngIf="app.isLocked(app.impact.id)"
                  class="text-muted font-weight-light font-italic"
                  id="{{ department.fields.mawiVertreter }}"
                >
                  <small
                    class="text-muted font-weight-light font-italic"
                    *ngIf="display(department.fields.mawiVertreter)"
                    >{{ app.text.impact.deputy }}:
                    {{
                      app.impact.currentImpact["impact.responsibles"][
                        department.fields.mawiVertreter
                      ]
                    }}
                  </small>
                </div>

                <span
                  *ngIf="!app.isLocked(app.impact.id)"
                  id="{{ department.fields.mawiVertreter }}"
                >
                  <div *ngIf="app.isLocked(app.impact.id)">
                    {{
                      app.impact.currentImpact["impact.responsibles"][
                        department.fields.mawiVertreter
                      ]
                    }}
                  </div>
                  <div *ngIf="!app.isLocked(app.impact.id)">
                    <app-field
                      [model]="app.impact.currentImpact['impact.responsibles']"
                      [field]="department.fields.mawiVertreter"
                      [disabled]="app.isLocked(app.impact.id)"
                      [showLabel]="true"
                    ></app-field>
                  </div>
                </span>
              </div>
            </td>

            <!-- RESPONSIBILTY STATUS  GREEN / RED FOR MAWI-->
            <td class="p-1">
              <button class="btn">
                <i
                  class="fad fa-user-check"
                  *ngIf="
                    app.impact.displayBullet(
                      department.fields.mawi,
                      green,
                      app.impact.currentImpact['impact.responsibles'],
                      app.impact.currentImpact['impact.omfVehicleName']
                    ) && app.unlockedId !== app.impact.id
                  "
                ></i>
                <i
                  class="fad fa-user-times"
                  *ngIf="
                    app.impact.displayBullet(
                      department.fields.mawi,
                      red,
                      app.impact.currentImpact['impact.responsibles'],
                      app.impact.currentImpact['impact.omfVehicleName']
                    ) && app.unlockedId !== app.impact.id
                  "
                ></i>
              </button>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</div>
