import { Component, OnInit, ViewChild } from "@angular/core";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { StockDoc, Stock } from "../../../../shared/models/stock";
import { getGraphStockData } from "../api.service";
import { getApp } from "../app";

@Component({
  selector: "app-stock-graphic-modal",
  templateUrl: "./stock-graphic-modal.component.html",
  styleUrls: ["./stock-graphic-modal.component.scss"],
})
export class StockGraphicModalComponent implements OnInit {
  app = getApp((app) => {
    this.app = app;
  });

  @ViewChild("stockGraphicModal", { static: false })
  stockGraphicModal: any;
  results: Stock[] = [];
  noCpn: boolean = false;
  noData: boolean = false;

  cpn: string = "";
  mpn: string = "";

  constructor(private modalService: NgbModal) {}

  ngOnInit() {
    if (
      this.app.thread.thread["thread.artNumber"] != "" &&
      this.app.thread.thread["thread.artNumber"] != null
    ) {
      this.cpn = this.app.thread.thread["thread.artNumber"];
    }
    if (
      this.app.thread.thread["thread.crtNumber"] != "" &&
      this.app.thread.thread["thread.crtNumber"] != null
    ) {
      this.mpn = this.app.thread.thread["thread.crtNumber"];
    }
  }

  async openModal() {
    await this.getData();
    if (this.noCpn || this.noData) {
      this.modalService.open(this.stockGraphicModal, {
        windowClass: "mediumModal",
      }).result;
    } else {
      this.modalService.open(this.stockGraphicModal, {
        windowClass: "stockGraphicModal",
      }).result;
    }
  }

  //get stock data for graphic
  async getData() {
    let cpn = this.app.thread.thread["thread.artNumber"];
    if (cpn == null || cpn === "") {
      this.noCpn = true;
    } else {
      let data: StockDoc = await getGraphStockData(
        this.app.customers.expectCurrent,
        cpn
      );
      if (data != null) {
        this.results = data.stock;
      } else {
        this.noData = true;
      }
    }
  }

  closeModal() {
    this.modalService.dismissAll();
  }
}
