<span
  *ngIf="name != null && name !== ''"
  class="pointer"
  (click)="
    $event.stopPropagation();
    $event.preventDefault();
    app.routing.navigateProfile(name)
  "
  [title]="app.text.credits.open"
>
  {{ name }}
</span>

<span *ngIf="name === ''">
  {{ app.text.credits.unknown }}
</span>
