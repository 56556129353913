import { Settings } from "../components";
import { _ComponentSettings } from "../settings/settings";

export type PermissionProxy = {
  [name in keyof Settings]: ComponentPermissionProxy<
    Settings[name]["permission"]
  >;
};

export type ComponentPermissionProxy<T> = {
  [name in keyof T]: boolean;
};

export function getPermissionProxy(settings: Settings) {
  const proxy: any = {};
  Object.keys(settings).forEach((name) => {
    Object.defineProperty(proxy, name, {
      get: () => {
        const component: _ComponentSettings = (settings as any)[name];
        return getComponentPermissionProxy(component);
      },
    });
  });

  return proxy as PermissionProxy;
}

function getComponentPermissionProxy<T extends _ComponentSettings>(
  component: T
) {
  const proxy: any = {};
  const { permission } = component;
  Object.keys(permission).forEach((name) => {
    Object.defineProperty(proxy, name, {
      get: () => permission[name],
    });
  });
  return proxy as ComponentPermissionProxy<T>;
}
