<div class="stepper-wrapper">
  <div
    *ngFor="let box of listBoxes; let i = index"
    class="stepper-item"
    [ngClass]="{
      complete: app.pcnGenerator.checkCompleted(box)
    }"
  >
    <button
      *ngIf="showBox(box)"
      class="btn btn-nav p-1"
      (click)="displayBox(box)"
    >
      <div
        class="box-number m-auto"
        [ngClass]="{
          'btn-nav-active': box === app.leftNav.selectedBox,
          'box-number-complete': app.pcnGenerator.checkCompleted(box)
        }"
      >
        <span *ngIf="!app.pcnGenerator.checkCompleted(box)">{{ i + 1 }}</span>
        <i
          *ngIf="app.pcnGenerator.checkCompleted(box)"
          class="fal fa-check"
        ></i>
      </div>
      <div
        class="btn-text text-center"
        [ngClass]="{ 'btn-text-active': box === app.leftNav.selectedBox }"
      >
        {{ app.getText(box) }}
      </div>
    </button>
  </div>
</div>
