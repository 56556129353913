import { APP_TYPE, AppType } from "../app.type";
import { Inject, Injectable } from "@angular/core";
import { writeConfigFile } from "../export/config-export";
import { RMServiceType } from "./RM.service.type";
import { StringUtils } from "../../../../shared/utils/string.utils";

export type TreeMode =
  | "tree"
  | "all-manufacturers"
  | "matched"
  | "unmatched"
  | "manual"
  | "override"
  | "manualAssignment"
  | "linkedToDeletedCPN"
  | "all-parts"
  | "without-manufacturers"
  | "not-used-assemblies"
  | "parts-to-be-deleted"
  | "parts-status";

@Injectable()
export class RMService implements RMServiceType {
  mode: TreeMode = "tree";

  displayRMHeader: boolean = true;
  filterOpen: boolean = false;
  fromRMSearch: boolean = false;

  isTree: boolean = false;
  isManufacturer: boolean = false;
  isPart: boolean = false;

  constructor(@Inject(APP_TYPE) private app: AppType) {}

  async export(docType: string, exportType: string = "") {
    this.app.spinner.showSpinner();
    if (
      (this.app.leftNav.selectedBox === "part.itemDetailSection" ||
        this.app.leftNav.selectedBox === "part.riskAssessmentSection" ||
        this.app.leftNav.selectedBox === "part.internalDataSection") &&
      docType === "part"
    ) {
      await this.app.detailTable.getDocsByType(docType);
    }
    await writeConfigFile(this.app, docType, exportType);
    this.app.spinner.hideSpinner();
  }

  async navigateRMComponents(page: string, target: boolean, id: string) {
    const pageName: string = page === "assembly" ? "tree" : page;
    const customer = this.app.customers.expectCurrent;
    let url: string = "";
    this.app.paginator.resetPagination();

    /** if the target is true, then the url has to be open in a new tab, if not, just regular */
    if (target) {
      switch (pageName) {
        case "tree":
          if (id == null) {
            url = `/${customer}/rm/${pageName}`;
          } else {
            const partNumber = StringUtils.encode(id);
            url = `/${customer}/rm/${pageName}/${partNumber}`;
          }
          break;
        case "part":
          const partNumber = StringUtils.encode(id);
          url = `/${customer}/part/${partNumber}`;
          break;
        case "manufacturer":
          url = `/${customer}/manufacturer/${id}`;
          break;
        default:
          url = `/${customer}/rm/table/${pageName}`;
          break;
      }
      this.app.routing.openInNewTab(url);
    } else {
      if (pageName !== "tree") {
        this.app.filterHeaderTable.resetAllFilters.next(true);
        this.app.RM.mode = pageName.split("/")[1].toString() as any;
        await this.app.routing.navigate(
          customer,
          "rm",
          "table",
          pageName.split("/")[0].toString(),
          pageName.split("/")[1].toString()
        );
      } else {
        await this.app.routing.navigate(customer, "rm", "tree");
      }
    }
  }
}
