import { View } from "../../../../shared/models/view";

export interface Filter {
  values: Set<any>;
}

export interface FilterState {
  filterOpen: boolean;
  filterField: string | null;
  filters: { [fieldId: string]: Filter };
  filter: Filter;
  showExternalCases: boolean;
}

export const filterInitialState: FilterState = {
  filterOpen: false,
  filterField: null,
  filters: {},
  filter: { values: new Set() },
  showExternalCases: false,
};

export interface FilterPage {
  values: any;
  filterView: View;
  filterLabelField: string | null;
}
