import { Thread } from "../../../../shared/models/thread";
import { Post } from "../../../../shared/models/post";

export type StepperDirection = "previous" | "next" | "jumpToFirst";

export const NEXT: StepperDirection = "next";
export const PREVIOUS: StepperDirection = "previous";
export const JUMPTOFIRST: StepperDirection = "jumpToFirst";

export interface StepperServiceType {
  /** Get a list with all values of the stepper */
  getSteps(): void;

  steps: string[];

  /** direction of the action , could be previous or next */
  direction: StepperDirection;

  /** Get the step index for the current status */
  getStepIndex(thread: Thread): void;
  stepIndex: number;

  /** Get the status description for the DB client */
  getStatusDescription(thread: Thread): void;
  statusDescription: string;

  /** Get the status responsible for the DB client */
  getStatusResponsible(thread: Thread): void;
  statusResponsible: string;

  /** Get the number of responsibles for the DB client */
  getNumberOfResponsibleLeft(): void;
  numberOfResponsibleLeft: number;

  /** Get the modals text depending on clients and the action involved (next/previous) */
  textWithVariable(text: string, status: string): String;

  /** Enable/diable next/previous status buttons */
  isStepDisabled(status: string, solutions?: Post[]): void;

  /** Variable for checking if next status button is diabled */
  nextStatusDisabled: boolean;

  /** Variable for checking if previous status button is diabled */
  previousStatusDisabled: boolean;

  /** Does the current user have the permission to change the staus of the thread */
  hasChangeStatusPermission(direction: string): boolean;

  /** Set a new due date and change status based on direction - maquet cardio only */
  setDueDate(): void;
}
