import * as pdfMake from "pdfmake/build/pdfmake";
import * as vfs_fonts from "pdfmake/build/vfs_fonts";
const pdfBuddy = pdfMake;
pdfBuddy.vfs = vfs_fonts.pdfMake.vfs;

export class PdfExport {
  public pdf: pdfMake.TCreatedPdf;
  constructor(docDef: pdfMake.TDocumentDefinitions) {
    this.pdf = pdfBuddy.createPdf(docDef);
  }

  print() {
    this.pdf.print();
  }

  open() {
    this.pdf.open();
  }

  download(fileName?: string) {
    this.pdf.download(fileName);
  }

  get(): pdfMake.TCreatedPdf {
    return this.pdf;
  }
}
