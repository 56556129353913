<app-impact-modal #openImpact [modalTitle]="app.text.impact.impact">
</app-impact-modal>

<app-modal
  #addNewImpact
  [modalTitle]="app.text.impact.impact"
  [modalType]="'wf-automation'"
  [showButtons]="false"
>
</app-modal>
<app-modal
  #seeImpactDetails
  [modalTitle]="app.text.impact.impact"
  [modalType]="'wf-automation'"
  [showButtons]="false"
>
</app-modal>
<app-modal
  #deleteImpactModal
  [modalMessage]="app.text.impact.deleteMessage"
  [modalTitle]="app.text.thread.modalTitle"
  (ok)="
    app.impact.delete(app.impact.currentImpact);
    removeItem(app.impact.currentImpact)
  "
></app-modal>

<app-modal
  #deleteAllImpactsModal
  [modalMessage]="app.text.impact.deleteAllImpacts"
  [modalTitle]="app.text.thread.modalTitle"
  (ok)="app.impacts.deleteAllImpacts()"
></app-modal>

<input
  #fileInput
  type="file"
  accept=".xlsx"
  style="display: none"
  (change)="upload()"
  multiple
/>
<div>
  <div
    *ngIf="app.leftNav.selectedBox === 'thread.impacts'"
    class="controls mx-2 pt-1"
  >
    <button
      *ngIf="showAddImpactBtn && app.customers.expectCurrent !== 'db'"
      class="btn btn-sm btn-default-orange"
      (click)="openImpact.open()"
    >
      <i class="fa fa-plus"></i>&nbsp;&nbsp; {{ app.text.app.add }}
    </button>

    <button
      *ngIf="showAddImpactBtn && app.customers.expectCurrent === 'db'"
      class="btn btn-sm btn-default-orange"
      (click)="app.state.impactId = null; addNewImpact.open()"
    >
      <i class="fa fa-plus"></i>&nbsp;&nbsp; {{ app.text.app.add }}
    </button>

    <button
      *ngIf="app.permission.impact.canImportImpacts"
      class="btn btn-sm btn-default-orange"
      (click)="fileInput.click()"
      [title]="app.text.import.upload"
      [disabled]="showImportImpactBtn"
    >
      <i class="fa fa-upload"></i>
      &nbsp;&nbsp; {{ app.text.impact.import }}
    </button>
  </div>
  <div class="container-fluid">
    <div class="row">
      <app-field
        class="col-md-6 col-lg-6 col-xl-6"
        *ngIf="manualAddVisibile()"
        [field]="app.fieldId.impact.omfVehicleName"
        [model]="vehicleNameModel"
        (selected)="app.impact.addImpact(vehicleNameModel)"
        [labelInside]="true"
        [orangeField]="true"
        [showLabel]="false"
      ></app-field>
      <div class="col controls p-0">
        <div class="m-1">
          <button
            *ngIf="
              app.customers.expectCurrent === 'db' &&
              app.permission.impact.canImportImpacts
            "
            class="btn btn-sm btn-clean"
            (click)="fileInput.click()"
            [title]="app.text.import.upload"
          >
            <i class="fa fa-upload"></i>&nbsp;
            <span>
              {{ app.text.impact.uploadMultiple }}
            </span>
          </button>
          <button
            *ngIf="app.permission.vehicleResponsible.edit"
            class="btn btn-sm btn-clean"
            [title]="app.text.vehicleResponsible.edit"
            (click)="app.routing.navigateVehicleResponsibles()"
          >
            {{ app.text.vehicleResponsible.edit }}
          </button>
          <button
            class="btn btn-sm btn-clean"
            *ngIf="app.permission.impact.canDeleteImpacts"
            (click)="deleteAllImpactsModal.open()"
          >
            {{ app.text.impact.deleteImpacts }}
          </button>
        </div>
      </div>
    </div>
  </div>
  <div
    class="d-flex flex-row-reverse"
    *ngIf="app.customers.expectCurrent === 'db'"
  >
    <ul class="legend-list">
      <li>
        <i class="fad fa-user-check" id="fa-green"></i>&nbsp;
        <span class="lineHeight">{{ app.text.impact.greenBullet }}</span>
      </li>
      <li>
        <i class="fad fa-user-times" id="fa-red"></i>&nbsp;
        <span class="lineHeight">{{ app.text.impact.redBullet }}</span>
      </li>
    </ul>
  </div>
</div>

<!-- ALL clients impacts table design, except DB  -->
<div
  class="tableFixHead"
  *ngIf="
    (app.customers.getRMCustomers() === 'non-rm-client' ||
      app.customers.getSpecialCustomers() === 'special-configuration') &&
    app.customers.expectCurrent !== 'db'
  "
>
  <table class="table table-striped">
    <thead>
      <tr>
        <th class="p-1" *ngFor="let column of app.list.impact.columns">
          {{ app.field.getLabel(column) }}
        </th>
      </tr>
    </thead>

    <tbody>
      <tr
        *ngFor="let impact of impacts; let i = index"
        (click)="openImpact.open(impact)"
      >
        <td *ngFor="let column of app.list.impact.columns" class="pointer p-1">
          <span *ngIf="column !== 'impact.actions'">
            {{ impact[column.split(".")[1]] }}
          </span>
          <button
            class="btn btn-sm"
            *ngIf="
              column === 'impact.actions' &&
              app.permission.impact.canAddImpactToCase
            "
            (click)="
              $event.stopPropagation();
              app.impact.currentImpact = impact;
              deleteImpactModal.open()
            "
          >
            <i class="fa fa-trash text-center"></i>
          </button>
        </td>
      </tr>
      <tr>
        <td
          *ngIf="app.paginator.getPageWithDoc().length === 0"
          colspan="99"
          class="text-center"
        >
          {{ app.text.app.noResults }}
        </td>
      </tr>
    </tbody>
  </table>
</div>

<!-- DB impacts table design -->
<div class="top-line" *ngIf="app.customers.expectCurrent === 'db'">
  <app-paginator
    [length]="app.impacts.impactIds.length"
    [index]="app.paginator.focus"
    (focus)="app.paginator.focus = $event"
  ></app-paginator>
  <div class="tableFixHead">
    <table class="table table-striped">
      <thead class="text-center">
        <tr class="departmentRow">
          <th></th>
          <th></th>
          <th colspan="2" class="departmentNames">FV</th>
          <th colspan="2" class="departmentNames">Regio</th>
          <th colspan="2" class="departmentNames">Cargo</th>
          <th colspan="2" class="departmentNames">FZI</th>
          <th colspan="2" class="departmentNames">Sonstige</th>
          <th *ngIf="app.permission.impact.edit"></th>
        </tr>
        <tr>
          <th>{{ app.field.getLabel("impact.omfVehicleName") }}</th>
          <th>GF</th>

          <th>BAV</th>
          <th>MaWI</th>

          <th>BAV</th>
          <th>MaWI</th>

          <th>BAV</th>
          <th>MaWI</th>

          <th>BAV</th>
          <th>MaWI</th>

          <th>BAV</th>
          <th>MaWI</th>
          <th *ngIf="app.permission.impact.edit">
            {{ app.field.getLabel("impact.actions") }}
          </th>
        </tr>
        <tr>
          <th *ngFor="let column of columnsShort" class="min-width-120">
            <div>
              <app-filter-table
                [column]="column"
                [columns]="columnsShort"
                [docType]="'impact'"
                [cleanDocs]="app.impacts.cleanImpacts"
                [selectedFilters]="selectedFilters"
                (resultedDocs)="
                  app.filterTable.getFilterResults($event, 'impact')
                "
              >
              </app-filter-table>
            </div>
          </th>
        </tr>
      </thead>
      <tbody>
        <tr
          *ngFor="let impact of app.paginator.getPageWithDoc(); let i = index"
          (click)="seeImpactDetails.open(); app.state.impactId = impact._id"
        >
          <td *ngFor="let column of columns" class="pointer" id="{{ column }}">
            <span
              *ngIf="
                (column === 'impact.omfVehicleName' ||
                  'impact.omfVehicleFleet') &&
                impact
              "
            >
              {{ impact[column.split(".")[1]] }}
            </span>
            <div
              class="cell responsiblesCell"
              *ngIf="
                column !== 'impact.omfVehicleName' &&
                column !== 'impact.omfVehicleFleet' &&
                impact
              "
            >
              <span> {{ impact["responsibles"][column.split(".")[1]] }} </span>
              <i
                class="fad fa-user-check pull-right"
                *ngIf="
                  impact['responsibles'][column.split('.')[1]] !== '' &&
                  app.impact.displayBullet(
                    column.split('.')[1],
                    green,
                    impact['responsibles'],
                    impact['omfVehicleName']
                  )
                "
              ></i>
              <i
                class="fad fa-user-times pull-right"
                *ngIf="
                  impact['responsibles'][column.split('.')[1]] !== '' &&
                  app.impact.displayBullet(
                    column.split('.')[1],
                    red,
                    impact['responsibles'],
                    impact['omfVehicleName']
                  )
                "
              ></i>
            </div>
            <span
              *ngIf="column === 'impact.actions' && app.permission.impact.edit"
              (click)="
                $event.stopPropagation();
                app.impact.currentImpact = impact;
                deleteImpactModal.open()
              "
            >
              <i class="fa fa-trash"></i>
            </span>
          </td>
        </tr>
        <tr>
          <td
            *ngIf="app.paginator.getPageWithDoc().length === 0"
            colspan="99"
            class="text-center"
          >
            {{ app.text.app.noResults }}
          </td>
        </tr>
      </tbody>
    </table>
  </div>
  <app-paginator
    *ngIf="app.customers.expectCurrent === 'db'"
    [length]="app.impacts.impactIds.length"
    [index]="app.paginator.focus"
    (focus)="app.paginator.focus = $event"
  ></app-paginator>
</div>
<app-where-used-in
  *ngIf="
    app.customers.hasRM() &&
    app.customers.getSpecialCustomers() === 'usual-configuration'
  "
></app-where-used-in>
