import { AbstractGraphData } from "../abstract-graph-data";
import { AppType } from "../../app.type";
import { GraphDataComplex, Thread } from "../graphs.service.type";

export class StatusGraphData extends AbstractGraphData {
  private monthEnd: Date;
  private status2Quantity = new Map<string, number>();

  constructor(private monthBegin: Date, app: AppType) {
    super(app);
    this.monthEnd = new Date(
      monthBegin.getFullYear(),
      monthBegin.getMonth() + 1
    );
    this.status2Quantity = this.getStatusMap();
  }

  getTime(): number {
    return this.monthBegin.getTime();
  }

  getStatusMap() {
    const { app } = this;
    const map = new Map<string, number>();
    app.field.getOptions(app.fieldId.thread.omfStatus).forEach((status) => {
      map.set(status, 0);
    });
    return map;
  }

  addThreads(threads: Thread[], history: any[]) {
    threads.forEach((thread) => {
      this.add(
        thread,
        history.filter((h) => h.docId === thread.omfNumber)
      );
    });
  }

  add(thread: Thread, history: any) {
    this.getStatusesWithin(thread, history).forEach((status) => {
      this.addStatus(status);
    });
  }

  addStatus(omfStatus: string) {
    const { status2Quantity } = this;
    const quantity = status2Quantity.get(omfStatus);
    if (quantity == null) {
      return;
    }
    status2Quantity.set(omfStatus, quantity + 1);
  }

  get data(): GraphDataComplex {
    const name = this.getDateLabel(this.monthBegin);
    const series: { name: string; value: number }[] = [];
    this.status2Quantity.forEach((quantity, status) => {
      series.push({
        name: this.app.field.getOptionText(
          this.app.fieldId.thread.omfStatus,
          status
        ),
        value: quantity,
      });
    });
    return { name, series };
  }

  private withinTimeframe(time: number) {
    return time > this.monthBegin.getTime() && time < this.monthEnd.getTime();
  }

  protected getStatusesWithin(doc: Thread, history: any) {
    const set = new Set<string>();
    let initialValue = doc.omfStatus;

    for (const record of history) {
      if (!record.oldValue) {
        record.oldValue = null;
      }
      initialValue = record.oldValue;
      break;
    }

    if (this.withinTimeframe(doc.create_time)) {
      set.add(initialValue);
    }

    for (const record of history) {
      if (this.withinTimeframe(record.time)) {
        set.add(record.newValue);
      }
    }

    return set;
  }
}
