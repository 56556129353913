<app-oc-post-modal
  #ocPostModal
  [title]="app.text.history.manufacturerHistory"
></app-oc-post-modal>

<div class="container-fluid">
  <!-- First line -->
  <div class="row">
    <!-- Search Input for filter -->
    <div class="col-md-12 d-flex flex-row p-2">
      <!-- Filter options -->
      <div *ngFor="let type of filterModes">
        <button
          class="btn btn-sm badge m-1"
          [ngClass]="{ 'badge-active': app.OCHome.selectedType === type }"
          type="button"
          (click)="setType(type)"
        >
          <i
            *ngIf="app.OCHome.selectedType !== type"
            class="fa-light fa-circle-dot"
          ></i>
          <i
            *ngIf="app.OCHome.selectedType === type"
            class="fa-regular fa-circle-dot"
          ></i>
          {{ getTextDisplay(type) }}
        </button>
      </div>
      <!-- Filter input -->
      <div class="search flex-grow-1">
        <input
          type="text"
          *ngIf="!app.OCHome.isFilterSelected"
          class="input input-search mt-1"
          placeholder="Search for posts..."
          [(ngModel)]="app.OCHome.searchWords[app.OCHome.selectedType]"
          (keydown.enter)="
            app.OCHome.filterOCPosts(
              app.OCHome.selectedType,
              app.OCHome.searchWords[app.OCHome.selectedType]
            );
            isSelected(app.OCHome.selectedType)
          "
        />
        <button
          class="btn btn-sm search-btn me-0"
          *ngIf="app.OCHome.isFilterSelected"
          type="button"
          (click)="clearFilter()"
        >
          {{ app.OCHome.selectedValue }}
          <i class="fa-light fa-times clear-icon"></i>
        </button>
        <button
          class="btn search-btn mt-1"
          (click)="
            app.OCHome.filterOCPosts(
              app.OCHome.selectedType,
              app.OCHome.searchWords[app.OCHome.selectedType]
            );
            isSelected(app.OCHome.selectedType)
          "
        >
          <i class="fa-solid fa-magnifying-glass"></i>
        </button>
      </div>
      <!-- Sorting -->
      <div class="dropdown">
        <button
          class="btn dropdown-toggle"
          type="button"
          id="sortOptions"
          data-bs-toggle="dropdown"
          aria-haspopup="true"
          aria-expanded="false"
        >
          <div class="icon-center me-1">
            <i class="fas fa-sort-alt menu-icons"></i>
          </div>
          <span>&nbsp;{{ app.text.home.sort }}&nbsp;</span>
          <span
            class="text-danger"
            *ngIf="app.OCHome.sortingOrder === 'descending'"
            >&nbsp;{{ app.text.post.newest }}&nbsp;</span
          >
          <span
            class="text-danger"
            *ngIf="app.OCHome.sortingOrder === 'ascending'"
            >&nbsp;{{ app.text.post.oldest }}&nbsp;</span
          >
        </button>
        <div class="dropdown-menu" aria-labelledby="sortOptions">
          <div class="dropdown-item pointer">
            <i class="fas fa-sort-amount-down text-danger"></i>
            <a
              (click)="
                app.OCHome.sortingOrder = 'descending';
                app.OCHome.sortPosts(app.OCHome.posts, 'descending')
              "
            >
              {{ app.text.ocPost.sortToOldest }}</a
            >
          </div>
          <div class="dropdown-item pointer">
            <i class="fas fa-sort-amount-up text-danger"></i>
            <a
              (click)="
                app.OCHome.sortingOrder = 'ascending';
                app.OCHome.sortPosts(app.OCHome.posts, 'ascending')
              "
            >
              {{ app.text.ocPost.sortToNewest }}
            </a>
          </div>
        </div>
      </div>
    </div>
  </div>

  <!-- Second line -->
  <div class="row mb-2 bottom-line"></div>

  <!-- 3th line - Content -->
  <div class="row">
    <!-- First Section (2/5) -->
    <div class="col-md-4 col-sm-12">
      <div class="mb-2 card d-flex">
        <div class="d-flex align-items-center card-header">
          <img
            *ngIf="app.profile.ownProfilePicture != null"
            class="profile-image"
            [src]="app.file.getUrl(app.profile.ownProfilePicture)"
          />
          <img
            *ngIf="app.profile.ownProfilePicture === null"
            class="profile-image"
            target="_blank"
            [src]="app.url.profile.defaultImage"
          />

          <!-- New post -->
          <button
            class="btn btn-sm input-search btn-new-post flex-grow-1 m-1"
            (click)="ocPostModal.open()"
          >
            New post: {{ app.text.ocPost.postQuestion }}
          </button>
        </div>
      </div>
      <div
        [ngClass]="{ card: app.OCHome.posts.length == 0 }"
        class="scroll mb-2"
      >
        <div
          class="no-results my-5 col-12"
          *ngIf="app.OCHome.posts.length == 0"
        >
          {{ app.text.home.noResults }}
        </div>
        <div *ngFor="let post of app.OCHome.posts">
          <app-oc-post-item [post]="post"></app-oc-post-item>
        </div>
      </div>
    </div>
    <!-- Second Section (2/5) -->
    <div class="col-md-8 col-sm-12">
      <div
        *ngIf="app.OCHome.posts.length === 0"
        class="no-results my-5 col-12"
        [ngClass]="{ card: app.OCHome.posts.length === 0 }"
      >
        {{ app.text.home.noResults }}
      </div>
      <div *ngIf="app.OCHome.posts.length > 0">
        <app-oc-post></app-oc-post>
      </div>
    </div>
    <!-- Third Section (1/5) Attachments and Post Image -->
  </div>
</div>
