/**
 * Basic attachment info.
 */
export class Attachment {
  content_type: string = "";
  revpos: number = 0;
  digest: string = "";
  length: number = 0;
  stub: boolean = false;
}

/**
 * Additional attachment info (meta) with history information.
 */
export class AttachmentHistoryInfo {
  [key: string]: any;
  uploadUser: string = "";
  uploadTime: number = 0;
  uploadSize: string = "";
  tags: string[] = [];
  fieldId?: string = "";
  omfNumber?: string = "";
  fziAttachment?: boolean = false;
}

export type AttachmentsInfo = {
  attachments: Attachment;
  attachmentsHistoryInfo: AttachmentHistoryInfo;
  _rev: string;
};

export interface AttachmentHistoryData {
  uploadUser: string;
  uploadTime: number;
  uploadSize: string;
  tags: any[];
}
