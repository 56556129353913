<app-modal
  #deleteCommentModal
  [modalMessage]="app.text.post.deleteCommentQuestion"
  [modalTitle]="app.text.post.modalTitle"
  (ok)="app.OCComment.deleteComment(app.OCComment.selectedComment)"
></app-modal>
<app-modal
  #discardChangesModal
  [modalMessage]="app.text.app.discardChanges"
  [modalTitle]="app.text.thread.modalTitle"
  (ok)="app.OCComment.discardChanges()"
>
</app-modal>

<div class="row">
  <div class="col-md-2 col-sm-2 col-xl-2"></div>
  <div class="col-md-8 col-sm-8 col-xl-8 mb-2 px-0 comment-box">
    <div class="input-wrapper">
      <textarea
        type="text"
        placeholder="Share your thoughts on this post"
        class="input form-control"
        [(ngModel)]="app.OCComment.commentDoc['message']"
        rows="1"
      ></textarea>
    </div>
    <div class="actions-wrapper">
      <button
        type="button"
        (click)="app.OCComment.saveComment()"
        class="btn btn-sm btn-post float-end"
        [disabled]="
          app.OCComment.commentDoc['message'] === '' ||
          app.OCHome.editedId !== ''
        "
      >
        <i class="fa-solid fa-paper-plane-top"></i>
        {{ app.text.ocPost.comment }}
      </button>
    </div>
  </div>
  <div class="col-md-2 col-sm-2 col-xl-2"></div>
</div>
<div *ngFor="let comment of app.OCComment.comments">
  <div class="row">
    <!-- Image -->
    <div class="col-md-2 col-sm-2 col-xl-2">
      <img
        *ngIf="comment.avatar != ''"
        class="profile-image float-end"
        target="_blank"
        [src]="app.file.getUrl(comment.avatar)"
      />
      <img
        *ngIf="comment.avatar === ''"
        class="profile-image float-end"
        target="_blank"
        [src]="app.url.profile.defaultImage"
      />
    </div>
    <!-- Comment content -->
    <div class="col-md-8 col-sm-8 col-xl-8 px-0 input readMode mb-2">
      <div class="d-flex">
        <span
          *ngIf="comment.userFirstName === '' || comment.userLastName === ''"
          ><b>{{ comment.user_id }}</b></span
        >
        <span
          *ngIf="comment.userFirstName !== '' && comment.userLastName !== ''"
          ><b>{{ comment.userFirstName }} {{ comment.userLastName }}</b></span
        >
        <div class="controls-text ms-auto">
          {{ app.OCHome.getTimeInfo(comment.create_time) }}
        </div>
      </div>
      <div *ngIf="comment._id !== app.OCHome.editedId" class="text">
        <slot>
          <div
            class="fake-textarea"
            [innerHtml]="app.field.highlightLinks(comment.message)"
          ></div>
        </slot>
      </div>
      <textarea
        *ngIf="comment._id === app.OCHome.editedId"
        [(ngModel)]="app.OCComment.cleanComment.message"
        type="text"
        class="input textarea-edit"
      ></textarea>
      <app-attachments
        [id]="comment._id"
        type="ocComment"
        [editPermission]="app.OCHome.hasEditPermission(comment)"
        [files]="comment._attachments"
        [filesDetails]="comment.attachmentHistory"
      ></app-attachments>
    </div>
    <!-- Comment controls -->
    <div class="col-md-2 col-sm-2 col-xl-1">
      <!-- Read mode -->
      <div
        *ngIf="comment._id !== app.OCHome.editedId"
        class="d-flex flex-column"
      >
        <div *ngIf="app.OCHome.hasEditPermission(comment)">
          <button
            class="btn btn-control"
            (click)="
              app.OCComment.selectedComment = comment;
              app.OCHome.editDoc(comment._id);
              app.OCHome.createCleanModel(comment)
            "
          >
            <i class="fa fa-edit"></i>
          </button>
          <button
            class="btn btn-control"
            [title]="app.text.post.delete"
            (click)="
              app.OCComment.selectedComment = comment; deleteCommentModal.open()
            "
          >
            <i class="fa fa-trash"></i>
          </button>
        </div>
        <div *ngIf="app.OCHome.hasEditPermission(comment)" class="ms-1">
          <app-attachments-controls
            [id]="comment._id"
            [isColored]="false"
            type="ocComment"
          ></app-attachments-controls>
        </div>
      </div>
      <!-- Edit mode -->
      <div *ngIf="comment._id === app.OCHome.editedId">
        <button
          class="btn btn-sm btn-control"
          (click)="app.OCComment.updateComment()"
          [title]="app.text.thread.save"
          [disabled]="app.OCComment.modelHasChanges()"
        >
          <i class="fa fa-check save-icon"></i>
        </button>
        <button
          class="btn btn-sm btn-control"
          (click)="discardChangesModal.open()"
        >
          <i class="fas fa-times close-icon"></i>
        </button>
      </div>
    </div>
  </div>
</div>
