<!-- <div class="box">
  <div
    class="box-title p-1"
    [ngClass]="{ 'heading-background': changeHeader }"
    (click)="toggleContent()"
    *ngIf="showHeader"
  >
    {{ title }}
    <span *ngIf="showContent" class="icon pull-right">
      <i class="fa fa-chevron-up"></i>&nbsp;
    </span>
    <span *ngIf="!showContent" class="icon pull-right">
      <i class="fa fa-chevron-down"></i>&nbsp;
    </span>
  </div>
  <div *ngIf="showContent" class="box-content">
    <ng-content></ng-content>
  </div>
</div> -->

<div
  [ngClass]="{
    'filter-card m-2': filterBox,
    card: !filterBox && !noteBox
  }"
>
  <div
    *ngIf="showHeader"
    [ngClass]="{
      'filter-header py-1 px-2': filterBox,
      'card-header': !filterBox && !fieldTableBox,
      'pb-1': noteBox,
      filterOpen: showContent === true && filterBox
    }"
  >
    {{ title }}
    <!-- <span *ngIf="showContent" class="icon pull-right">
      <i class="fa fa-chevron-up"></i>&nbsp;
    </span>
    <span *ngIf="!showContent" class="icon pull-right">
      <i class="fa fa-chevron-down"></i>&nbsp;
    </span> -->
  </div>
  <div *ngIf="showContent">
    <ng-content></ng-content>
  </div>
</div>
