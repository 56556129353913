import { ComponentSettings, FieldTypes } from "../settings/settings";

const field = {
  commodityGroupDinCode: {
    type: FieldTypes.value,
    text: { de: "Din Code", en: "Din code", nl: "DIN-codes" },
  },
  commodityGroupGlobalId: {
    type: FieldTypes.value,
    text: {
      de: "Warengruppennummer",
      en: "Commodity group id",
      nl: "Commodity groep ID",
    },
  },
  commodityGroupDescription: {
    type: FieldTypes.value,
    text: { de: "Beschreibung", en: "Description", nl: "Beschrijving" },
  },
  responsibleFirstName: {
    type: FieldTypes.value,
    text: { de: "Vorname", en: "First name", nl: "Voornaam" },
  },
  responsibleLastName: {
    type: FieldTypes.value,
    text: { de: "Nachname", en: "Last name", nl: "Achternaam" },
  },
  responsibleDepartment: {
    type: FieldTypes.value,
    text: { de: "Abteilung", en: "Department", nl: "Afdeling" },
  },
  responsibleEmail: {
    type: FieldTypes.value,
    text: { de: "Email", en: "Email", nl: "e-mail" },
  },
};

const settings = {
  permission: {},
  text: {
    commodityGroupResponsible: {
      de: "Warengruppenverantwortliche/r",
      en: "Commodity group responsible",
      nl: "Commodity groep verantwoordelijke",
    },
  },
  url: {},
  field,
  list: {},
};

export const commodityGroupResponsible: ComponentSettings<typeof settings> =
  settings;
