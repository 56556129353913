import { User } from "../../../../shared/models/user";
import { TextProxy } from "../../../../shared/proxies/text.proxy";
import { DocModel } from "../state/state";
import { NO_ERROR } from "../users/users.service";

export const MIN_PASSWORD_LENGTH = 8;
export const SPECIAL = new Set([
  "$",
  "%",
  "_",
  "!",
  "&",
  "/",
  "?",
  ".",
  "#",
  "+",
  "-",
  ":",
]);
const DIGITS = new Set(["1", "2", "3", "4", "5", "6", "7", "8", "9", "0"]);

export const passwordPolicy = {
  de: `Das Passwort muss mindestens ${MIN_PASSWORD_LENGTH} Zeichen lang sein, eine Zahl und eines der folgenden Sonderzeichen beinhalten: ${Array.from(
    SPECIAL
  ).join("")}`,
  en: `Please choose a password with ${MIN_PASSWORD_LENGTH} characters or more, at least one digit and one of the following special characters: ${Array.from(
    SPECIAL
  ).join("")}`,
  nl: `Het wachtwoord moet minstens ${MIN_PASSWORD_LENGTH} tekens lang zijn, een cijfer bevatten en één van de volgende speciale tekens: ${Array.from(
    SPECIAL
  ).join("")}`,
};

export function checkPasswordPolicy(text: TextProxy, user: User | DocModel) {
  let password: string = "";
  let retypePassword: string = "";

  password = user.password;
  retypePassword = user.retypePassword;

  if (typeof password !== "string") {
    return text.profilePassword.passwordNotString;
  }

  if (password.length < MIN_PASSWORD_LENGTH) {
    return (
      text.profilePassword.passwordCount1 +
      MIN_PASSWORD_LENGTH +
      text.profilePassword.passwordCount2
    );
  }

  if (countCharacters(password, SPECIAL) < 1) {
    return (
      text.profilePassword.passwodSpecialCharacters +
      Array.from(SPECIAL).join("")
    );
  }

  if (countCharacters(password, DIGITS) < 1) {
    return text.profilePassword.passwordNumber;
  }

  if (password !== retypePassword) {
    return text.profilePassword.errorRetypedPassword;
  }

  return NO_ERROR;
}

function countCharacters(text: string, characters: Set<string>) {
  let count = 0;
  for (const c of text) {
    if (characters.has(c)) {
      count++;
    }
  }
  return count;
}
