import { AppType, APP_TYPE } from "../app.type";
import { CommodityGroupResponsibleServiceType } from "./commodity-group-responsible.service.type";
import { Inject, Injectable } from "@angular/core";
import { getCommodityIds, getDocsByType } from "../api.service";
import { CommodityGroupResponsible } from "../../../../shared/models/commodityGroupResponsible";

@Injectable()
export class CommodityGroupResponsibleService
  implements CommodityGroupResponsibleServiceType
{
  constructor(@Inject(APP_TYPE) private app: AppType) {}

  groupResponsibles: any[] = [];
  commodityIds: string[] = [];

  async getCommodityIds() {
    const customer = this.app.customers.expectCurrent;
    const commodityIds: string[] = await getCommodityIds(customer);
    this.commodityIds = commodityIds;
  }

  set(doc: CommodityGroupResponsible) {
    this.app.thread.thread["thread.omfCommodityId"] =
      doc.commodityGroupGlobalId;
    this.app.thread.thread["thread.omfCommodityDescr"] =
      doc.commodityGroupDescription;
    this.app.thread.thread["thread.omfCommodityRespName"] =
      doc.responsibleFirstName + " " + doc.responsibleLastName;
  }

  async generateCommodityGroupResponsibles(docs: any) {
    this.groupResponsibles = await getDocsByType(
      this.app.customers.expectCurrent,
      "commodityGroupResponsible"
    );

    let responsibles: CommodityGroupResponsible[] = [];

    docs.forEach(async (doc: CommodityGroupResponsible) => {
      let commGroupResponsible = {} as CommodityGroupResponsible;

      let existingDoc = await this.app.import.checkIfExists(
        this.groupResponsibles,
        doc
      );
      if (existingDoc !== undefined) {
        commGroupResponsible = this.updateFieldsFromImport(doc, existingDoc);
      } else {
        this.app.import.props.forEach((field) => {
          commGroupResponsible[field.key] = doc[field.key];
        });
        commGroupResponsible.type = "commodityGroupResponsible";
        delete commGroupResponsible._id;
        responsibles.push(commGroupResponsible);
      }
    });

    docs = responsibles;
    this.app.import.docs = docs;
    (docs as []).forEach((doc: CommodityGroupResponsible) => {
      this.app.import.selected.add(doc.commodityGroupGlobalId);
    });
    this.app.import.stepper = "reviewList";
    this.app.import.step = "third";
    return docs;
  }

  updateFieldsFromImport(
    doc: CommodityGroupResponsible,
    existingDoc: CommodityGroupResponsible
  ) {
    Object.keys(doc).forEach((key) => {
      existingDoc[key] = doc[key];
    });
    return existingDoc;
  }
}
